import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  FrameworkSignalsQuery,
  QueryFrameworkArgs,
  SignalDefinition,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const signalsQuery = graphql(`
  query frameworkSignals($frameworkId: String!) {
    framework(frameworkId: $frameworkId) {
      signals {
        id
        name
        category
        colour
      }
    }
  }
`);

/**
 * Returns the signals for a framework
 * @param req
 * @param options
 * @returns
 */
export function useFrameworkSignals(
  frameworkId: QueryFrameworkArgs["frameworkId"],
  options?: UseQueryOptions<FrameworkSignalsQuery, unknown, FrameworkSignalsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["frameworkSignals", frameworkId],
    signalsQuery,
    [{ frameworkId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  const allSignals: SignalDefinition[] = data?.framework.signals ?? [];

  return {
    data: allSignals,
    ...rest,
  };
}
