import React, { useMemo } from "react";
import { Checkbox, Divider } from "antd5";
import { CheckboxChangeEvent } from "antd5/es/checkbox";
import classNames from "classnames";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import EnterIcon from "lib/icons/EnterIcon";
import Signal from "../../../lib/icons/Signal";

import css from "./KeywordDropdownMenu.module.scss";

type Props = {
  selectedKeywords: string[];
  allKeywords: string[];
  dropdownOpen: boolean;
  searchText: string;
  type: "keywords" | "excludeKeywords";
  onChange: (keyword: string[]) => void;
};

export default function KeywordDropdownMenu({
  selectedKeywords,
  allKeywords,
  dropdownOpen,
  searchText,
  type,
  onChange,
}: Props): JSX.Element | null {
  const filteredOptions = useMemo((): string[] => {
    const cleanedSearchText = searchText.trim().toLowerCase();
    return allKeywords.filter((op) => op.trim().toLowerCase().includes(cleanedSearchText));
  }, [allKeywords, searchText]);

  const checkedList = filteredOptions.filter((s) => selectedKeywords.includes(s));

  const isAllOptionsChecked =
    filteredOptions.length > 1 && filteredOptions.length === checkedList.length;
  const isSomeOptionsChecked =
    checkedList.length > 0 && checkedList.length < filteredOptions.length;

  const numberOfKeywordSignals = filteredOptions.filter(
    (op) => op && allKeywords.includes(op),
  ).length;

  return (
    <div
      className={classNames(css.options, {
        [css.showOptions]: dropdownOpen,
      })}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      aria-label="Keyword dropdown"
    >
      {searchText.length === 0 ? (
        type === "keywords" ? (
          <span className={css.typeAndPressEnter}>
            Type and press enter to add a free text keyword <EnterIcon />
          </span>
        ) : (
          <></>
        )
      ) : (
        <label className={css.typeAndPressEnter}>
          <Checkbox
            aria-label={`${searchText} option`}
            onChange={(e: CheckboxChangeEvent) => {
              if (e.target.checked) {
                onChange([...selectedKeywords, searchText]);
              } else {
                onChange(selectedKeywords.filter((v: string) => v !== searchText));
              }
              e.preventDefault();
            }}
            checked={selectedKeywords.includes(searchText)}
            className={css.label}
          >
            {searchText}
          </Checkbox>
          <span className={css.smallText}>
            Type and press enter <EnterIcon />
          </span>
        </label>
      )}
      {numberOfKeywordSignals > 0 && (
        <span className={css.signalCount}>
          <Signal className={css.icon} size={16} label="signalIcon" /> Keyword signals (
          {numberOfKeywordSignals})
        </span>
      )}

      {filteredOptions.length > 0 && (
        <>
          <label className={css.selectAll} key={"tagsContainer"}>
            <Checkbox
              aria-label="Select all"
              onChange={(e: CheckboxChangeEvent) => {
                if (e.target.checked) {
                  onChange(filteredOptions);
                } else {
                  onChange([]);
                }
              }}
              indeterminate={isSomeOptionsChecked}
              checked={isAllOptionsChecked}
              className={css.label}
            >
              Select all
            </Checkbox>
          </label>
          <Divider className={css.divider} />
          <div className={css.keywordOptions}>
            {filteredOptions.map((optionVal, index) => (
              <KeywordOption
                index={index}
                allKeywords={allKeywords}
                selectedKeywords={selectedKeywords}
                optionVal={optionVal}
                onChange={onChange}
                key={optionVal}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

type OptionProps = {
  index: number;
  allKeywords: string[];
  selectedKeywords: string[];
  optionVal: string;
  onChange: (keyword: string[]) => void;
};

function KeywordOption({
  index,
  allKeywords,
  selectedKeywords,
  optionVal,
  onChange,
}: OptionProps): JSX.Element | null {
  const isSavedKeyword = allKeywords.includes(optionVal);

  return (
    <label className={css.option} key={index}>
      <Checkbox
        key={optionVal}
        value={optionVal}
        name={optionVal}
        checked={selectedKeywords.includes(optionVal)}
        className={css.label}
        onChange={(e) => {
          const isChecked = e.target.checked;

          if (isChecked) {
            onChange([...selectedKeywords, optionVal]);
          } else {
            onChange(selectedKeywords.filter((s: string) => s !== optionVal));
          }
        }}
        aria-label={`Keyword option ${optionVal}`}
      >
        <EllipsisTooltipText fullText={optionVal || ""} textProps={{ className: css.label }} />
      </Checkbox>
      {isSavedKeyword && <Signal className={css.icon} size={16} label="signalIcon" />}
    </label>
  );
}
