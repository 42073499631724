export function getFormDirtyValues<T extends Record<string, unknown>>(
  dirtyFields: Record<string, boolean>,
  formValues: T,
): Partial<T> {
  return Object.entries(dirtyFields).reduce<Partial<T>>((acc, [key, isDirty]) => {
    if (isDirty) {
      acc[key as keyof T] = formValues[key as keyof T];
    }
    return acc;
  }, {});
}
