import React from "react";
import { Button, Modal } from "antd5";

import css from "./SignalSettingsDiscardModal.module.scss";

type SignalSettingsDiscardModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export default function SignalSettingsDiscardModal({
  isOpen,
  onCancel,
  onSubmit,
}: SignalSettingsDiscardModalProps) {
  return (
    <Modal
      open={isOpen}
      centered
      footer={
        <>
          <Button onClick={onCancel}>Return to Editor</Button>
          <Button danger type="primary" onClick={onSubmit}>
            Discard changes
          </Button>
        </>
      }
    >
      <div className={css.modalContent}>
        <div className={css.modalHeader}>
          <p className={css.modalTitle}>Discard Changes</p>
        </div>
        <p className={css.modalSubtitle}>
          Would you like to discard all changes you have made to your Signal Settings? This action
          cannot be undone.
        </p>
      </div>
    </Modal>
  );
}
