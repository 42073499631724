import React from "react";

import { ButtonLink, TextLink } from "components/actions/Links";
import * as tracking from "lib/tracking";

import css from "./GuestUserHeaderActions.module.scss";

export const guestUserCustomHeader: () => JSX.Element = () => {
  return (
    <div className={css.linksContainer}>
      <TextLink to="/users/sign_in" className={css.signInLink}>
        Sign in
      </TextLink>

      <ButtonLink
        to="/get-started"
        type="primary"
        className={css.signUpButton}
        eventName={tracking.EventNames.clickedSignUpCTA}
        eventAttributes={{
          "Context source": "Guestpasses",
        }}
      >
        Sign up for free
      </ButtonLink>
    </div>
  );
};
