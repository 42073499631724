import { useCallback, useEffect, useRef } from "react";

/**
 * A hook for keyboard shortcuts.
 * @param {string[]} keys - Array of keys to listen for (e.g. ['Control', 'F'])
 * @param {function} callback - Function to call when the keys are pressed.
 * @param {boolean} preventDefault - Whether to prevent the default action (optional).
 */

export function useKeyboardShortcut(keys: string[], callback: () => void, preventDefault = false) {
  // Track the key states in a ref to avoid re-renders.
  const pressedKeys = useRef(new Set());

  const handleKeyDown = useCallback(
    (event) => {
      const { key } = event;

      pressedKeys.current.add(key);

      if (keys.every((requiredKey) => pressedKeys.current.has(requiredKey))) {
        if (preventDefault) event.preventDefault();
        callback();
      }
    },
    [keys, callback, preventDefault],
  );

  const handleKeyUp = useCallback((event) => {
    const { key } = event;
    pressedKeys.current.delete(key);
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return null;
}
