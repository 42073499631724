import * as React from "react";
import { Popover, Tooltip } from "antd5";
import classnames from "classnames";

import { convertSignalCategoryToFeatureType } from "components/paywall/paywallUtils";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { Signal } from "lib/generated/app-api";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { tagColourFromSignal } from "lib/tagUtils";
import { SignalCategory } from "lib/types/models";

import "../../styles/tagColours.module.scss";
import css from "./SignalsContainer.module.scss";
import sharedCss from "styles/tag_helpers.module.scss";

type Props = {
  signals: Signal[];
  contextSource?: string;
  redactedSignalCategories?: SignalCategory[];
  requiredDataType?: ProHelperDataTypes;
  maxSignals?: number;
  vertical?: boolean;
  className?: string;
};

export default function SignalsContainer({
  signals,
  contextSource,
  redactedSignalCategories,
  requiredDataType,
  maxSignals,
  vertical,
  className,
}: Props): JSX.Element {
  const visibleSignals = maxSignals ? signals.slice(0, maxSignals) : signals;
  const extraSignals = maxSignals ? signals.slice(maxSignals) : [];

  const pills = React.useMemo(
    () =>
      visibleSignals.map((s, i) => {
        const tagText = s.name;
        const signalCategory = s.category as SignalCategory;
        const isHiddenCat = !!redactedSignalCategories?.includes(signalCategory);

        return (
          <li
            key={i}
            className={classnames(
              `tag-${tagColourFromSignal(s.category) || "default"}`,
              sharedCss.nonTagCellTag,
              css.tagPill,
            )}
          >
            <RedactedWrapper
              requiredDataType={isHiddenCat ? requiredDataType : undefined}
              // Feature type is mostly going to be derived from the signal category (COMPETITOR OR PARTNER), but also it can sometimes be just passed as a prop (for example in find suppliers table,
              // keywords signals are hidden - so we want a supplier paywall modal to appear there)
              featureType={
                isHiddenCat
                  ? convertSignalCategoryToFeatureType(signalCategory, requiredDataType)
                  : undefined
              }
              redactContent={tagText}
              contextSource={contextSource}
              redactedForGuestUser={false}
            >
              <Tooltip title={`${s.category}: ${s.name}`}>{tagText}</Tooltip>
            </RedactedWrapper>
          </li>
        );
      }),
    [visibleSignals, redactedSignalCategories, requiredDataType, contextSource],
  );
  return (
    <ul
      onClick={(e) => e.stopPropagation()}
      className={classnames(
        css.container,
        sharedCss.nonTagCellTagsContainer,
        { [sharedCss.vertical]: vertical },
        className,
      )}
    >
      {pills}
      {extraSignals.length > 0 && (
        <Popover
          placement="rightTop"
          content={
            <SignalsContainer
              signals={extraSignals}
              redactedSignalCategories={redactedSignalCategories}
              requiredDataType={requiredDataType}
              vertical
            />
          }
        >
          <li className={sharedCss.moreSignals}>+ {extraSignals.length} more</li>
        </Popover>
      )}
    </ul>
  );
}
