import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import produce from "immer";
import { groupBy } from "lodash";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import {
  useSupplierSummaryChartGrouped,
  useSupplierSummaryGrouped,
} from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  SpendDataFilters,
  SupplierSummaryChartData,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { EventNames, TrackingEvent, TrackingProvider, useTracking } from "lib/tracking";
import { SpendDataFilters as UiFilters } from "./hooks/useSpendPageState";
import { DateUnitSelector } from "./DateUnitSelector";
import SpendChart, { Dataset } from "./SpendChart";

type PartnerCompetitorProps = {
  filters: SpendDataFilters;
  setFilters: Dispatch<SetStateAction<UiFilters>>;
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: Dispatch<"month" | "quarter" | "year">;
};

function createChartClickedOnEvent(
  filterValue: string,
  filterField: "partners" | "competitors",
): TrackingEvent {
  return {
    name: EventNames.chartClickedOn,
    data: {
      "Action type": "Filter applied",
      "Filter name": `My ${filterField}`,
      "Filter value": filterValue,
    },
  };
}

export function PartnerChart({
  filters,
  setFilters,
  dateUnit,
  setDateUnit,
}: PartnerCompetitorProps) {
  return (
    <TrackingProvider data={{ "Context source": "Partner spending chart" }}>
      <SupplierChartGrouped
        filters={filters}
        setFilters={setFilters}
        filterField="partners"
        dateUnit={dateUnit}
        setDateUnit={setDateUnit}
      />
    </TrackingProvider>
  );
}

export function CompetitorChart({
  filters,
  setFilters,
  dateUnit,
  setDateUnit,
}: PartnerCompetitorProps) {
  return (
    <TrackingProvider data={{ "Context source": "Competitor spending chart" }}>
      <SupplierChartGrouped
        filters={filters}
        setFilters={setFilters}
        filterField="competitors"
        dateUnit={dateUnit}
        setDateUnit={setDateUnit}
      />
    </TrackingProvider>
  );
}

type Props = {
  filters: SpendDataFilters;
  setFilters: Dispatch<SetStateAction<UiFilters>>;
  filterField: "partners" | "competitors";
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: Dispatch<"month" | "quarter" | "year">;
};

function SupplierChartGrouped({ filters, setFilters, filterField, dateUnit, setDateUnit }: Props) {
  const { logEvents } = useTracking();
  const { data: top10Suppliers } = useSupplierSummaryGrouped(
    {
      filters,
      offset: 0,
      limit: 10,
      orderBy: "total",
      sortOrder: "DESC",
    },
    {
      query: {
        enabled: !window.guestUser,
      },
    },
  );
  const { data, isLoading } = useSupplierSummaryChartGrouped(
    {
      filters: {
        ...filters,
        supplierNames: top10Suppliers?.data.map((b) => b.supplier),
      },
      dateUnit,
    },

    { query: { enabled: !!top10Suppliers } },
  );

  // convert to chart data grouped by buyer
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    const dates = Array.from(new Set(data.data.map((d) => d.date).filter((d) => d))).sort();

    const grouped =
      data &&
      groupBy(
        data.data.filter((d) => d.date),
        "supplier",
      );

    return Object.entries(grouped).map(([supplier, data]) => {
      const dateMap = {} as Record<string, SupplierSummaryChartData>;
      for (const d of data) {
        dateMap[d.date] = d;
      }

      return {
        label: supplier,
        id: supplier,
        data: dates.map((date) => {
          const d = dateMap[date] || { date, total: 0 };
          return d;
        }),
      };
    });
  }, [data]);

  const onDatasetsClick = useCallback(
    (datasets: Dataset[]) => {
      const supplierNames = datasets.map((d) => d.label);
      logEvents(createChartClickedOnEvent(supplierNames.join(", "), filterField));
      setFilters(
        produce((draft) => {
          draft[filterField] = supplierNames;
        }),
      );
    },
    [logEvents, filterField, setFilters],
  );

  return (
    <DetailsSection
      title={`Spend over time by ${
        top10Suppliers && top10Suppliers.total > 10 ? "top 10" : ""
      } ${filterField}`}
      action={<DateUnitSelector dateUnit={dateUnit} setDateUnit={setDateUnit} />}
    >
      <SpendChart
        data={chartData}
        dateUnit={dateUnit}
        onDatasetsClick={onDatasetsClick}
        isLoading={isLoading}
      />
    </DetailsSection>
  );
}
