/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentReference } from './DocumentReference';
import {
    DocumentReferenceFromJSON,
    DocumentReferenceFromJSONTyped,
    DocumentReferenceToJSON,
} from './DocumentReference';
import type { ReportData } from './ReportData';
import {
    ReportDataFromJSON,
    ReportDataFromJSONTyped,
    ReportDataToJSON,
} from './ReportData';

/**
 * 
 * @export
 * @interface ReportConfig
 */
export interface ReportConfig {
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    reportType?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    adminNotes?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportConfig
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {Array<DocumentReference>}
     * @memberof ReportConfig
     */
    documents?: Array<DocumentReference>;
    /**
     * 
     * @type {ReportData}
     * @memberof ReportConfig
     */
    reportData: ReportData;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConfig
     */
    isPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    pdfUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportConfig
     */
    coverImageUrl?: string | null;
}

/**
 * Check if a given object implements the ReportConfig interface.
 */
export function instanceOfReportConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "reportData" in value;

    return isInstance;
}

export function ReportConfigFromJSON(json: any): ReportConfig {
    return ReportConfigFromJSONTyped(json, false);
}

export function ReportConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': json['label'],
        'companyId': json['company_id'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
        'creatorId': !exists(json, 'creator_id') ? undefined : json['creator_id'],
        'reportType': !exists(json, 'report_type') ? undefined : json['report_type'],
        'adminNotes': !exists(json, 'admin_notes') ? undefined : json['admin_notes'],
        'publishedAt': !exists(json, 'published_at') ? undefined : (json['published_at'] === null ? null : new Date(json['published_at'])),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(DocumentReferenceFromJSON)),
        'reportData': ReportDataFromJSON(json['report_data']),
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
        'coverImageUrl': !exists(json, 'cover_image_url') ? undefined : json['cover_image_url'],
    };
}

export function ReportConfigToJSON(value?: ReportConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'company_id': value.companyId,
        'team_id': value.teamId,
        'creator_id': value.creatorId,
        'report_type': value.reportType,
        'admin_notes': value.adminNotes,
        'published_at': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(DocumentReferenceToJSON)),
        'report_data': ReportDataToJSON(value.reportData),
        'is_public': value.isPublic,
        'description': value.description,
        'pdf_url': value.pdfUrl,
        'cover_image_url': value.coverImageUrl,
    };
}

