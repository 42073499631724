import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Tabs, Tooltip } from "antd5";
import { isEmpty, omit, pickBy } from "lodash";

import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsHeader, DetailsPage, DetailsSection } from "components/app_layout/DetailsLayout";
import { PageHeader } from "components/app_layout/Typography";
import { BetaWarning } from "components/spend_data/BetaWarning";
import {
  ALL_GROUP_BYS,
  DEFAULT_DATE_RANGE,
  GroupByKey,
  PageView,
  TransactionTableRow,
} from "components/spend_data/config";
import ExportDataButton from "components/spend_data/ExportDataButton";
import { useSpendPageState } from "components/spend_data/hooks/useSpendPageState";
import { DEFAULT_PAGE_SIZE, useSpendTable } from "components/spend_data/hooks/useSpendTable";
import { EmptyState, InitialState } from "components/spend_data/pageStates";
import SpendDataFilters from "components/spend_data/SpendDataFilters";
import ViewButton from "components/spend_data/ViewButton";
import Badge from "lib/core_components/Badge";
import { Table } from "lib/core_components/Table";
import { useDebouncedValue } from "lib/debounce";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import {
  BuyerSummary,
  SupplierGuidSummary,
  SupplierSummary,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useConfirmUserDataSubscription } from "lib/providers/Paywall";
import FilterDrawer from "../../components/filter_form/filter_drawer/FilterDrawer";
import { grey10 } from "../../lib/themes/colors";

import css from "./SpendDataPage.module.scss";

function getExportGroupBy(groupBy: GroupByKey) {
  if (groupBy === "buyers") {
    return "buyer";
  }
  if (groupBy === "allTransactions") {
    return undefined;
  }
  return "supplier";
}

export function SpendDataPage() {
  const [showFilters, setShowFilters] = useState(false);
  const {
    state: { filters, groupBy, viewIndex, dateUnit },
    dispatch,
    apiFilters,
  } = useSpendPageState();

  const isNewSupplierFilterEnabled =
    useVariableValue(NEW_SUPPLIER_FILTER, false) && window.currentUser?.use_supplier_name === false;

  const { data: savedSuppliers } = useSavedSuppliers({
    enabled: isNewSupplierFilterEnabled,
  });

  const ALL_VIEWS: PageView[] = [
    {
      title: "Buyer & Competitor relationships",
      description: "Which buyers are working with my competitors?",
      filters: isNewSupplierFilterEnabled
        ? { supplierGuids: savedSuppliers?.competitorGuids, dateRange: DEFAULT_DATE_RANGE }
        : { competitors: "ALL", dateRange: DEFAULT_DATE_RANGE },
      groupBy: "buyers",
    },
    {
      title: "Competitor rankings",
      description: "Which of my competitors are earning the most in the public sector?",
      filters: isNewSupplierFilterEnabled
        ? { supplierGuids: savedSuppliers?.competitorGuids, dateRange: DEFAULT_DATE_RANGE }
        : { competitors: "ALL", dateRange: DEFAULT_DATE_RANGE },
      groupBy: isNewSupplierFilterEnabled ? "allSuppliers" : "competitors",
    },
    {
      title: "Buyer & Partner relationships",
      description: "Which buyers are working with my partners?",
      filters: isNewSupplierFilterEnabled
        ? { supplierGuids: savedSuppliers?.partnerGuids, dateRange: DEFAULT_DATE_RANGE }
        : { partners: "ALL", dateRange: DEFAULT_DATE_RANGE },
      groupBy: "buyers",
    },
    {
      title: "Partner rankings",
      description: "Which of my partners are earning the most in the public sector?",
      filters: isNewSupplierFilterEnabled
        ? { supplierGuids: savedSuppliers?.partnerGuids, dateRange: DEFAULT_DATE_RANGE }
        : { partners: "ALL", dateRange: DEFAULT_DATE_RANGE },
      groupBy: isNewSupplierFilterEnabled ? "allSuppliers" : "partners",
    },
  ];

  const selectedView = viewIndex === undefined ? undefined : ALL_VIEWS[viewIndex];

  // Runs once upon load, checks whether user is subscribed to spend data or not, if not, then
  // open the paywall modal and upon close will go back to my feed
  useConfirmUserDataSubscription("SUPPLIERS", "SPEND");

  const [debouncedFilters] = useDebouncedValue(apiFilters, 400);

  const filterKeys = Object.keys(pickBy(filters, (val) => !isEmpty(val)));

  const chartComponent = React.useMemo(() => {
    if (!groupBy) return null;

    const chart = ALL_GROUP_BYS[groupBy].components.chart;
    if (!chart) return null;

    const Elem = chart.component;

    return (
      <Elem
        filters={debouncedFilters}
        setFilters={(update) => dispatch({ type: "SET_FILTER", payload: update })}
        dateUnit={dateUnit || "quarter"}
        setDateUnit={(dateUnit) => dispatch({ type: "SET_DATE_UNIT", payload: dateUnit })}
      />
    );
  }, [groupBy, debouncedFilters, dateUnit, dispatch]);

  const {
    data: tableData,
    isLoading: tableIsLoading,
    columns: tableColumns,
    rowKey: tableRowKey,
    pagination: tablePagination,
    onRow: tableOnRow,
    isError,
  } = useSpendTable(debouncedFilters, groupBy);

  const filteredGroupBys = omit(ALL_GROUP_BYS, ["competitors", "partners"]);

  return (
    <DetailsPage>
      <FilterDrawer open={showFilters} onClose={() => setShowFilters(false)}>
        <SpendDataFilters
          onClose={() => setShowFilters(false)}
          filters={filters}
          setFilters={(f) => dispatch({ type: "SET_FILTER", payload: f })}
        />
      </FilterDrawer>
      <DetailsHeader>
        <BetaWarning className={css.betaWarning} />
        <PageHeader>{selectedView ? selectedView.title : "Spending"}</PageHeader>
        <div className={css.viewButtonContainer}>
          {ALL_VIEWS.map((view, index) => (
            <ViewButton
              title={view.title}
              description={view.description}
              isSelected={viewIndex === index}
              onClick={() => {
                dispatch({
                  type: "CHANGE_VIEW",
                  payload: {
                    viewIndex: index,
                    filters: view.filters,
                    groupBy: view.groupBy,
                    viewTitle: view.title,
                  },
                });
              }}
              key={view.title}
            />
          ))}
        </div>
      </DetailsHeader>
      <div className={css.container}>
        <div>
          <Button onClick={() => setShowFilters(true)} className={css.filterButton}>
            <FilterOutlined /> Filters {filterKeys.length > 0 && <Badge>{filterKeys.length}</Badge>}
          </Button>
          {filterKeys.length > 0 && (
            <TextButton onClick={() => dispatch({ type: "CLEAR_FILTERS" })}>Clear all</TextButton>
          )}
        </div>

        {!groupBy ? (
          <InitialState />
        ) : !tableIsLoading && tableData?.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <div className={css.groupByContainer}>
              <span>Grouped by</span>
              <Tabs
                className={css.tabs}
                tabBarStyle={{ paddingLeft: 16, backgroundColor: grey10 }}
                onChange={(tab) => dispatch({ type: "SET_GROUP_BY", payload: tab as GroupByKey })}
              >
                {Object.entries(
                  isNewSupplierFilterEnabled && window.currentUser?.use_supplier_name === false
                    ? filteredGroupBys
                    : ALL_GROUP_BYS,
                ).map(([key, gB]) => (
                  <Tabs.TabPane
                    key={key}
                    disabled={gB.isDisabled(filters)}
                    tab={
                      gB.isDisabled(filters) ? (
                        <Tooltip title={gB.disabledText}>
                          <span>{gB.title}</span>
                        </Tooltip>
                      ) : (
                        <span>{gB.title}</span>
                      )
                    }
                  />
                ))}
              </Tabs>
            </div>
            {chartComponent}
            <DetailsSection
              title={ALL_GROUP_BYS[groupBy].components.table.title}
              action={
                <ExportDataButton
                  filters={apiFilters}
                  totalRows={tablePagination?.total || 0}
                  groupBy={getExportGroupBy(groupBy)}
                />
              }
              contentClassName={css.section}
            >
              <Table<TransactionTableRow | SupplierSummary | BuyerSummary | SupplierGuidSummary>
                columns={tableColumns}
                dataSource={tableData}
                pagination={{
                  defaultPageSize: DEFAULT_PAGE_SIZE,
                  showSizeChanger: true,
                  ...tablePagination,
                }}
                rowKey={tableRowKey}
                loading={tableIsLoading}
                onRow={tableOnRow}
                onChange={(pagination) => {
                  tablePagination?.onChange(pagination.current || 1, pagination.pageSize);
                }}
                isError={isError}
              />
            </DetailsSection>
          </>
        )}
      </div>
    </DetailsPage>
  );
}

export default hot(withAppLayout(SpendDataPage, {}));
