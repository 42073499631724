import React from "react";
import { FieldValues, useController } from "react-hook-form";

import { useTeams } from "../../../lib/hooks/api/teams/useTeams";
import { EM_DASH } from "../../../lib/utils";
import { Select, SelectProps } from "../../form_components/Inputs";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {};

export default function OpportunityAssignedSelect<T extends FieldValues>({
  ...props
}: FieldProps<T>) {
  const { field } = useController(props);
  const { data: teams, isLoading } = useTeams();

  if (field && !field.value) {
    return <>{EM_DASH}</>;
  }

  const OPTIONS =
    teams
      ?.map((team) =>
        team.users.map((user) => ({
          id: user.guid,
          label: `${user.firstName} ${user.lastName}`,
          value: user.guid,
        })),
      )
      .flat() ?? [];

  return <Select {...props} loading={isLoading} options={OPTIONS} />;
}
