import { useCallback, useEffect, useState } from "react";

export type AsyncState<T, E = unknown> =
  | {
      status: "loading";
      value: undefined;
      error: undefined;
    }
  | {
      status: "reloading" | "ready";
      value: T;
      error: undefined;
    }
  | {
      status: "error";
      value: undefined;
      error?: E;
    };

type AsyncReturn<T, E> = { run: () => void } & AsyncState<T, E>;

const useAsync = <T, E = unknown>(asyncFunction: () => Promise<T>): AsyncReturn<T, E> => {
  const [state, setState] = useState<AsyncState<T, E>>({
    status: "loading",
    value: undefined,
    error: undefined,
  });

  const runFunction = useCallback(() => {
    setState((s) =>
      s.status === "ready" || s.status === "reloading"
        ? { ...s, status: "reloading" }
        : { ...s, status: "loading", error: undefined, value: undefined },
    );

    asyncFunction()
      .then((value: T) => {
        setState({ status: "ready", value, error: undefined });
      })
      .catch((error: E) => {
        setState({ status: "error", value: undefined, error });
      });
  }, [asyncFunction]);

  const run = useCallback(() => {
    if (state.status === "loading" || state.status === "reloading") {
      return;
    }
    runFunction();
  }, [runFunction, state.status]);

  useEffect(() => {
    void runFunction();
  }, [runFunction]);

  return { ...state, run };
};

export function checkAsyncErrors<T, E>(state: AsyncState<T, E>): AsyncState<T, E> {
  if (state.status === "error") {
    const { error } = state;
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(`Encountered unknown error: ${error}.`);
  }
  return state;
}

export default useAsync;
