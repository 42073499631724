/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignalsToOrgDecision
 */
export interface SignalsToOrgDecision {
    /**
     * 
     * @type {string}
     * @memberof SignalsToOrgDecision
     */
    qualification: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SignalsToOrgDecision
     */
    signalIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SignalsToOrgDecision
     */
    signalName: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignalsToOrgDecision
     */
    cancelled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SignalsToOrgDecision
     */
    note?: string | null;
}

/**
 * Check if a given object implements the SignalsToOrgDecision interface.
 */
export function instanceOfSignalsToOrgDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "qualification" in value;
    isInstance = isInstance && "signalIds" in value;
    isInstance = isInstance && "signalName" in value;

    return isInstance;
}

export function SignalsToOrgDecisionFromJSON(json: any): SignalsToOrgDecision {
    return SignalsToOrgDecisionFromJSONTyped(json, false);
}

export function SignalsToOrgDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalsToOrgDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qualification': json['qualification'],
        'signalIds': json['signal_ids'],
        'signalName': json['signal_name'],
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function SignalsToOrgDecisionToJSON(value?: SignalsToOrgDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qualification': value.qualification,
        'signal_ids': value.signalIds,
        'signal_name': value.signalName,
        'cancelled': value.cancelled,
        'note': value.note,
    };
}

