import React, { Key, memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Resizable, ResizeCallbackData } from "react-resizable";
import { WarningOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Empty, Skeleton, Table as AntTable } from "antd5";
import { CheckboxChangeEvent } from "antd5/es/checkbox";
import { ColumnsType, TableProps as AntTableProps } from "antd5/es/table";
import { ColumnType as AntColumnType, TablePaginationConfig } from "antd5/es/table/interface";
import classnames from "classnames";
import produce from "immer";

import { createUseDebounce } from "lib/debounce";
import { EventNames, useTracking } from "lib/tracking";
import { HOVER_CONTAINER } from "../ShowOnHover";
import { ColumnType } from "./ColumnTypes";

import css from "./Table.module.scss";

const PAGE_PADDING = 100;
const DEFAULT_COLUMN_WIDTH = 150;
const TABLE_BREAKPOINT_MEDIUM = 1260 - PAGE_PADDING;
const TABLE_BREAKPOINT_LARGE = 1420 - PAGE_PADDING;
const TABLE_BREAKPOINT_X_LARGE = 1920 - PAGE_PADDING;

export const IN_ROW_STAT_LINK = css.inRowStatLink;

export const IN_ROW_STAT = css.inRowStatButton;

type TableSize =
  | "small" // <1280px, optimised for ~800px
  | "medium" // <1440px, optimised for ~1280px
  | "large" //  <1920px, optimised for ~1440px
  | "xlarge"; // >=1920px, optimised for ~1920px

function getTableSize(tableWidth: number | undefined): TableSize {
  if (!tableWidth) {
    return "medium";
  }
  if (tableWidth < TABLE_BREAKPOINT_MEDIUM) {
    return "small";
  } else if (tableWidth < TABLE_BREAKPOINT_LARGE) {
    return "medium";
  } else if (tableWidth < TABLE_BREAKPOINT_X_LARGE) {
    return "large";
  }
  return "xlarge";
}

const useDebounce1000 = createUseDebounce(1000);

const getColumnResizeKey = <T,>(column: AntColumnType<T>) => {
  let key =
    column.key ?? column.dataIndex ?? (typeof column.title === "string" ? column.title : undefined);

  if (!key) {
    throw `A key, dataIndex or title is required to be able to use resizable columns (column: ${JSON.stringify(
      column,
    )})`;
  }

  if (typeof key !== "string" && typeof key !== "number") {
    key = key.join(".");
  }

  return key;
};

const MIN_CONSTRAINTS: [number, number] = [100, 0];

type ResizableTitleProps = React.HTMLAttributes<Element> & {
  onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
  width?: number;
};

type InRowStatTextProps = {
  children: ReactNode;
  className?: string;
};

export function InRowStatText({ children, className }: InRowStatTextProps) {
  return <div className={classnames(css.inRowStatText, className)}>{children}</div>;
}

function ResizableTitle({ onResize, width, ...restProps }: ResizableTitleProps) {
  const onClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  if (width === undefined || width === 0) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      minConstraints={MIN_CONSTRAINTS}
      height={0}
      handle={<span className={css.resizeHandle} onClick={onClick} />}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
}

/**
 * Row selection for tables which maintains checked rows even when you change pages., the top box is checked when any row of the
 * table has been selected. If you click the top box when one of these rows are selected, then it will select all rows in the current page
 * previously selected rows in other pages
 * @param selectedRowKeys
 * @param onSelectedRowKeysChange
 * @returns
 */
export function tableRowSelection<T extends object>(
  selectedRowKeys: string[],
  onSelectedRowKeysChange: (selectedRowKeys: string[]) => void,
  records: T[],
  keyName: keyof T,
): AntTableProps<T>["rowSelection"] {
  return {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      onSelectedRowKeysChange(selectedKeys as string[]);
    },
    columnWidth: "60px",
    columnTitle: () => {
      const checkBoxProps = {
        indeterminate: selectedRowKeys.length > 0,
        checked: records.length > 0 ? selectedRowKeys.length === records.length : false,
        onChange: (e: CheckboxChangeEvent) => {
          const checked = e.target.checked;
          if (checked) {
            // Make sure the same row isn't selected twice
            const newlySelectedRows = records.map((item) => `${item[keyName]}`);
            const totalSelectedRows = new Set([...newlySelectedRows, ...selectedRowKeys]);

            onSelectedRowKeysChange(Array.from(totalSelectedRows));
          } else {
            onSelectedRowKeysChange([]);
          }
        },
      };

      return <Checkbox {...checkBoxProps} />;
    },
  };
}

type TableProps<E> = Omit<AntTableProps<E>, "columns"> & {
  columns: ColumnType<E>[] & ColumnsType<E>;
  tableRef?: React.Ref<HTMLDivElement>;
  containerClassName?: string;
  ariaLabel?: string;
  pagination?: boolean | TablePaginationConfig;
  isError: boolean;
};

const defaultPagination: TablePaginationConfig = {
  position: ["bottomCenter"],
};

function TableComponent<T extends object>({
  columns,
  dataSource,
  rowClassName: rowClassNameProp,
  tableRef,
  rowSelection,
  ariaLabel,
  pagination,
  isError,
  ...tableProps
}: TableProps<T>) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);

  const mergedPagination =
    typeof pagination === "object" && pagination !== null
      ? { ...defaultPagination, ...pagination }
      : false;

  useEffect(() => {
    const updateTableSize = (): void => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setTableWidth(rect.width);
      }
    };

    updateTableSize();
    window.addEventListener("resize", updateTableSize, { passive: true });

    return () => {
      window.removeEventListener("resize", updateTableSize);
    };
  }, []);

  columns = useMemo(
    () =>
      columns?.map((col) => {
        const allSizes: Array<TableSize> = ["xlarge", "large", "medium", "small"];
        const tableSize = getTableSize(tableWidth);
        // find the first size that has a width defined that is smaller than the current table size
        const size = allSizes.find(
          (size) => col.sizeConfig?.[size] && allSizes.indexOf(size) >= allSizes.indexOf(tableSize),
        );
        const width = size ? col?.sizeConfig?.[size] : DEFAULT_COLUMN_WIDTH;
        return {
          ...col,
          width,
        };
      }),
    [columns, tableWidth],
  );

  const [sizeOverides, setSizeOverides] = useState<Record<Key, number>>({});
  const { logEvent } = useTracking();
  const trackColumnResize = useDebounce1000(
    (previousWidth: number, newWidth: number, column: AntColumnType<T>) => {
      const key = getColumnResizeKey(column);
      logEvent(EventNames.resizeTableColumn, {
        "Previous width": previousWidth,
        "New width": newWidth,
        Column: key,
      });
    },
  );

  const handleResize = useCallback(
    (column: AntColumnType<T>) =>
      (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
        if (!columns) {
          return;
        }

        const key = getColumnResizeKey(column);

        setSizeOverides(
          produce((draftSizeOverides) => {
            if (!key) {
              return;
            }
            trackColumnResize(draftSizeOverides[key], size.width, column);
            draftSizeOverides[key] = size.width;
          }),
        );
      },
    [columns, trackColumnResize],
  );

  columns = useMemo(
    () =>
      columns?.map((col) => {
        const key = getColumnResizeKey(col);
        return {
          ...col,
          width:
            key !== undefined && sizeOverides[key] !== undefined ? sizeOverides[key] : col.width,

          onHeaderCell: (column: ColumnsType<T>[number]) => ({
            width:
              key !== undefined && sizeOverides[key] !== undefined
                ? sizeOverides[key]
                : col.width ?? DEFAULT_COLUMN_WIDTH,
            onResize: handleResize(column) as React.ReactEventHandler<Element>,
          }),
        };
      }),
    [columns, handleResize, sizeOverides],
  );

  const rowClassName = useMemo(
    () => (record: T, index: number, indent: number) => {
      const hasOnClick = !!tableProps?.onRow?.(record, index).onClick;
      return classnames(
        typeof rowClassNameProp === "function"
          ? rowClassNameProp(record, index, indent)
          : rowClassNameProp,
        HOVER_CONTAINER,
        css.tableRow,
        {
          [css.clickableRow]: hasOnClick,
        },
      );
    },
    [rowClassNameProp, tableProps],
  );

  const emptyTableContent = useMemo((): React.ReactNode | (() => React.ReactNode) => {
    if (isError) {
      return (
        <Empty
          image={<WarningOutlined style={{ fontSize: 100 }} />}
          description="Sorry something has gone wrong"
        />
      );
    }

    // TODO: This is what's displayed when there is no data BUT the API which
    // provides data to the table is still loading
    if (tableProps.loading) {
      return <Skeleton active />;
    }

    if (tableProps.locale?.emptyText) {
      return tableProps.locale.emptyText;
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nothing to show" />;
  }, [isError, tableProps.loading, tableProps.locale?.emptyText]);

  // TODO: Consider somehow using or wrapping this in SkeletonTable component

  return (
    <div ref={containerRef} className={css.container} aria-label={ariaLabel}>
      <AntTable<T>
        {...tableProps}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{ x: "calc(100% - 4px)", ...tableProps.scroll }}
        // 4px needed to prevent horizontal scroll bar when it should fit without overflow
        rowSelection={rowSelection}
        ref={tableRef}
        columns={columns}
        dataSource={dataSource}
        rowClassName={rowClassName}
        pagination={mergedPagination}
        className={css.tableOverflow}
        locale={{
          emptyText: emptyTableContent,
        }}
      />
    </div>
  );
}

export const Table = memo(TableComponent) as typeof TableComponent;
