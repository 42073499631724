import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type DeleteListParams = { id: string; name: string };
export function useDeleteBuyerList(
  options?: UseMutationOptions<void, unknown, DeleteListParams, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(({ id }: DeleteListParams) => api.deleteBuyerList({ id }), {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["buyerLists"]);
      void queryClient.invalidateQueries(["supplierStats"]);
      void queryClient.invalidateQueries(["buyers", "buyersForSupplier"]);
      logEvent(EventNames.buyerListDeleted, {
        "List id": variables.id,
        "List name": variables.name,
        "User email": window.currentUser?.email,
      });
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
}
