import React from "react";
import { Control, Controller } from "react-hook-form";

import FrameworkStageButtons from "../FrameworkStageButtons";
import { FrameworkFilters } from "../utils";

import css from "./ProcurementStage.module.scss";

export interface ProcurementStageFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
}

function ProcurementStageFilterContent({ control }: ProcurementStageFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Procurement stage filters">
      <Controller
        name="stage"
        control={control}
        render={({ field }) => (
          <div className={css.inputContainer}>
            <FrameworkStageButtons {...field} checkedStages={field.value ?? []} />
          </div>
        )}
      />
    </div>
  );
}

export default ProcurementStageFilterContent;
