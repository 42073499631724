import * as React from "react";

import TextButton from "components/actions/TextButton";
import { performLogout } from "lib/userUtils";

import css from "./CreateAccountCustomHeader.module.scss";

export const customHeader: () => JSX.Element = () => {
  return (
    <div className={css.linksContainer}>
      <TextButton onClick={performLogout} className={css.logOutLink}>
        Log out
      </TextButton>
    </div>
  );
};
