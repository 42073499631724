import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { PersonalSubscriptionContentFrequencyEnum } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type Params = {
  id: string;
  frequency: PersonalSubscriptionContentFrequencyEnum;
};

export function useUpdateLeadSubscriptionContent(
  options?: UseMutationOptions<void, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, frequency }: Params) =>
      api.updateLeadSubscriptionContent({
        id: id,
        updateLeadSubscriptionContentRequest: { frequency },
      }),
    {
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["leadSubscriptionContents"]);
        void queryClient.invalidateQueries(["leadSubscriptions"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
