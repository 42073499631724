import stableJsonStringify from "fast-json-stable-stringify";

import { OutOfOrderResponseError } from "../../lib/search/SequentialRequestHandler";
import StotlesAPI, {
  PreviewRecordQueryRequest,
  PreviewRecordQueryResponse,
  SelfServeQuery,
  Signal,
  SignalCategory,
} from "../../lib/StotlesApi";

export type PreviewSignalFilters = Partial<Record<SignalCategory, string[]>>;

// A map of matches of this filterType and the count of results
// ex. for a couple of keywords it'd be it's {procurement: 5, government: 10, hogwarts: 0}
export type MatchCountMap = Record<string, number>;

export type SignalFilterCategoryData = {
  title: string;
  colour: "grey" | "default" | "pink" | "red" | "brown" | "yellow" | "green" | "blue" | "purple";
  signals: Signal[];
  counts?: MatchCountMap;
};

export enum FeedPreviewPageSources {
  ONBOARDING = "Onboarding",
  FEED_SETTINGS = "Feed settings",
}

export class FeedPreviewProvider {
  private lastRequestId = 0;

  constructor(
    private readonly api: StotlesAPI,
    private readonly companyId?: number,
  ) {}

  async getForQuery(
    query: SelfServeQuery,
    fromDate?: Date,
    toDate?: Date,
  ): Promise<PreviewRecordQueryResponse> {
    const thisRequestId = ++this.lastRequestId;

    const response = await this.getForQueryCached(query, fromDate, toDate);

    if (thisRequestId !== this.lastRequestId) {
      throw new OutOfOrderResponseError();
    }

    return response;
  }

  private cachedResponses = new Map<string, Promise<PreviewRecordQueryResponse>>();
  private async getForQueryCached(
    query: SelfServeQuery,
    fromDate?: Date,
    toDate?: Date,
  ): Promise<PreviewRecordQueryResponse> {
    const payload = {
      query,
      from_date: fromDate?.toString(),
      to_date: toDate?.toString(),
    };
    const hash = stableJsonStringify(payload);
    const cached = this.cachedResponses.get(hash);
    if (cached) {
      return cached;
    }
    const responsePromise = this.fetch(payload).catch((err: Error) => {
      // In case of errors we want to reset the cached promise so we can retry
      // with the same parameters
      if (this.cachedResponses.get(hash) === responsePromise) {
        this.cachedResponses.delete(hash);
      }
      throw err;
    });
    this.cachedResponses.set(hash, responsePromise);
    return responsePromise;
  }

  private fetch(payload: PreviewRecordQueryRequest) {
    if (this.companyId) {
      return this.api.feedPreview(this.companyId, payload);
    } else {
      return this.api.previewRecordQuery(payload);
    }
  }
}

export const HELP_EDITING_YOUR_FEED_URL = "https://help.stotles.com/optimise-your-signal-settings";
