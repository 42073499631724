import * as React from "react";
import { Form } from "antd5";

import CpvCodeSelector from "components/form_components/CpvCodeSelector";

import css from "./CpvCodes.module.scss";

export default function CpvCollapseContent({
  values,
  onValuesChange,
}: {
  values: string[];
  onValuesChange: (newVals: string[] | undefined) => void;
}): JSX.Element {
  return (
    <Form.Item className={css.cpvCodesContainer}>
      <label className={css.settingsLabel} htmlFor="feedSettingsCpvCodesSelector">
        Which CPV codes would you like to track?
      </label>
      <p>
        Notices found via these CPV codes are not filtered down by the keywords you have entered
        above. We recommend using this feature only when choosing highly-specific CPV codes, to
        avoid adding too much noise to your feed.
      </p>
      <CpvCodeSelector
        selectedCodes={values}
        onChange={onValuesChange}
        placeholder="Search for CPV code or description"
        inputId="feedSettingsCpvCodesSelector"
        disableTopLevelCodeCheck
      />
      <p className={css.cpvCodesNote}>
        <em>
          Please note: notices found via CPV codes use the same settings from <b>Keywords</b> for
          the types of notices and look back dates.
        </em>
      </p>
    </Form.Item>
  );
}
