import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { InstallUrlRequestEnvironmentEnum } from "lib/generated/integration-api";
import { InstallUrlResponse } from "lib/generated/integration-api/models/InstallUrlResponse";
import { useIntegrationApi } from "lib/integrationApiContext";

type InstallParams = { providerId: string; environment?: InstallUrlRequestEnvironmentEnum };
export function useInstallProvider(
  options?: UseMutationOptions<InstallUrlResponse, unknown, InstallParams, unknown>,
) {
  const api = useIntegrationApi();
  // We use a mutation here because the flow does eventually change the DB
  return useMutation(
    ({ providerId, environment }: InstallParams) =>
      api.integrationInstallUrl({ installUrlRequest: { environment }, providerId: providerId }),
    {
      ...options,
      onSuccess: (data) => {
        window.location.href = data.authUrl;
      },
    },
  );
}
