import React from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { Button } from "antd5";

import { countryCodes } from "../../../../../lib/data/optionItems";

import css from "./CoreCountriesInput.module.scss";

type CoreCountriesInputProps<T extends FieldValues> = UseControllerProps<T>;

export default function CoreCountriesInput<T extends FieldValues>(
  props: CoreCountriesInputProps<T>,
) {
  const { field } = useController(props);

  // Sort the country options so that "United Kingdom" is first
  const allCountryOptions = countryCodes
    .filter((country) => country.code === "UK" || country.code === "IE")
    .sort((a, b) => b.name.localeCompare(a.name));

  const handleCountryClick = (country: string) => {
    if (field.value.includes(country)) {
      field.onChange(field.value.filter((c: string) => c !== country));
    } else {
      field.onChange([...field.value, country]);
    }
  };

  return (
    <div className={css.container}>
      {allCountryOptions.map((country) => {
        return (
          <Button
            key={country.code}
            onClick={() => handleCountryClick(country.name)}
            type={field.value.includes(country.name) ? "primary" : "default"}
          >
            {country.name}
          </Button>
        );
      })}
    </div>
  );
}
