import * as React from "react";
import { hot } from "react-hot-loader/root";
import { CheckOutlined } from "@ant-design/icons";
import { Button, message } from "antd5";

import { TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import { APIError } from "lib/Api";
import { useOpenApi } from "lib/openApiContext";
import * as tracking from "lib/tracking";
import { sendGa4Event } from "../../lib/ga4Tracking";

import css from "./PreSignupEmailVerificationPage.module.scss";

type Props = {
  status?: string;
};

export function PreSignUpEmailVerificationPage({ status }: Props) {
  const [timerSeconds, setTimerSeconds] = React.useState(0);
  const api = useOpenApi();

  React.useEffect(() => {
    if (timerSeconds === 0) return;
    setTimeout(() => {
      setTimerSeconds((oldTimerSeconds) => oldTimerSeconds - 1);
    }, 1000);
  }, [timerSeconds]);

  React.useEffect(() => {
    if (status && status === "confirmation_failure") {
      void message.error(
        <span>
          Our system hasn't verified your email yet, please double check and reach out to{" "}
          <TextLink className={css.stotlesEmailLink} to="mailto:team@stotles.com">
            team@stotles.com
          </TextLink>{" "}
          if issues persist
        </span>,
        3,
      );
    }
  }, [status]);

  const handleSendVerificationEmail = React.useCallback(async () => {
    try {
      const resp = await api.sendVerificationEmail();
      sendGa4Event({
        event: "resend_verification_email",
        form: {
          id: "signup-form",
          name: "Signup",
        },
      });
      tracking.logEvent(tracking.EventNames.clickedReSendEmail, {
        "Page source": "Onboarding - Verify email",
      });
      void message.success(resp.message);
      setTimerSeconds(30);
    } catch (e) {
      if (e instanceof APIError && e.response) {
        void message.error(e.response.message);
        setTimerSeconds(0);
      } else {
        throw e;
      }
    }
  }, [api]);

  return (
    <div className={css.emailVerificationPage}>
      <div className={css.emailVerificationcontainer}>
        <h1 className={css.headerTitle}>Check your inbox for a verification email from us</h1>
        <p className={css.description}>
          Thanks for signing up! Now we just need to verify your email address.
        </p>
        <div className={css.actionItemsContainer}>
          {timerSeconds > 0 ? (
            <p>
              Verification email sent again
              <CheckOutlined className={css.icon} />
            </p>
          ) : (
            <p>
              Don't see an email?
              <Button
                type="link"
                onClick={handleSendVerificationEmail}
                disabled={timerSeconds > 0}
                className={css.resendEmailButton}
              >
                Resend verification email
              </Button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default withAppLayout(hot(PreSignUpEmailVerificationPage), {
  hideMenuItems: true,
  hideUserItems: true,
  pageName: "Onboarding - Verify email",
});
