import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, TablePaginationConfig } from "antd5";
import isEqual from "lodash.isequal";

import { Input } from "components/form_components/Inputs";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import {
  AllBuyersStatsRequestAllBuyersStatsFilters,
  AllBuyersStatsResponse,
} from "lib/generated/app-api";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { SortState } from "lib/search/types";
import { EventDataTypes, useTracking } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import { FILTER_COLLAPSE_MATCH_STYLING, useVariableValue } from "../../lib/featureFlags";
import { useAllBuyerStats } from "../../lib/hooks/api/useAllBuyerSearch";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import BulkSaveBuyer from "./BulkSaveBuyer";
import { BuyerFilterForm, BuyerFilterSections } from "./BuyerFilterForm";
import { ALL_BUYER_COLUMNS, BuyerColumns, BuyerTable, DEFAULT_PAGINATION } from "./BuyerTable";
import { ExportBuyersButton } from "./ExportBuyersButton";
import { QuickFilterBar } from "./QuickFilterBar";
import { TableContext } from "./types";
import {
  DEFAULT_BUYER_FILTERS,
  DEFAULT_BUYER_SORT,
  useFilterableBuyerTableState,
} from "./useFilterableBuyerTableState";

import css from "./FilterableBuyerTable.module.scss";

export const DEFAULT_BUYER_COLUMNS = [...ALL_BUYER_COLUMNS];

export function FilterableBuyerTable({
  defaultFilters = DEFAULT_BUYER_FILTERS,
  defaultSortOrder = DEFAULT_BUYER_SORT,
  defaultColumns = DEFAULT_BUYER_COLUMNS,
  allColumns = ALL_BUYER_COLUMNS,
  hiddenSections,
  isSupplierRelationship,
}: {
  defaultFilters?: AllBuyersStatsRequestAllBuyersStatsFilters;
  defaultSortOrder?: SortState;
  hiddenSections?: BuyerFilterSections[];
  defaultColumns?: ColumnSetting<BuyerColumns>[];
  allColumns?: ColumnSetting<BuyerColumns>[];
  isSupplierRelationship?: boolean;
}) {
  const tableState = useFilterableBuyerTableState(defaultFilters, defaultSortOrder);
  const { buyerTableFilters, setBuyerTableFilters, buyerSort, setBuyerSort } = tableState;
  // TODO: Consider including in `useFilterableBuyerTableState
  const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_MATCH_STYLING, false);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [selectedBuyers, setSelectedBuyers] = useState<string[]>([]);

  const useDebounce650 = createUseDebounce(650);
  const debouncedSetBuyerTableFilters = useDebounce650((filters) => {
    updateFilters(filters);
  });

  const values = buyerTableFilters;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      buyerName: buyerTableFilters.buyerName,
    },
    mode: "onChange",
    values,
  });

  const { trackingData } = useTracking();
  const filterableBuyerTableTrackingData = { ...trackingData, "CTA actioned": "Filter" };
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(filterableBuyerTableTrackingData);

  const updateSortState = React.useCallback(
    (newSortState: SortState) => {
      setBuyerSort(newSortState);
      // Always reset pagination when sort changes
      setPagination(DEFAULT_PAGINATION);
    },
    [setBuyerSort],
  );

  const updateFilters = (filters: AllBuyersStatsRequestAllBuyersStatsFilters) => {
    const filtersWithoutUndefined = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce(
        (obj, [key, value]) => {
          obj[key as keyof typeof filters] = value;
          return obj;
        },
        {} as typeof filters,
      );

    const filtersHaveChangedBackToDefault = isEqual(filtersWithoutUndefined, DEFAULT_BUYER_FILTERS);
    const filtersHaveChanged =
      !isEqual(filters, defaultFilters) && buyerSort.field === "buyer_name";

    if (filtersHaveChangedBackToDefault) {
      setBuyerTableFilters(filters);
      setBuyerSort({ field: "buyer_name", order: "ASC" });
    } else if (filtersHaveChanged) {
      setBuyerTableFilters(filters);
      setBuyerSort({ field: "total_record_count", order: "DESC" });
    } else {
      setBuyerTableFilters(filters);
    }
    setPagination(DEFAULT_PAGINATION);
  };

  const clearAllFiltersHide = (
    currentFilters: AllBuyersStatsRequestAllBuyersStatsFilters,
    defaultFilters: AllBuyersStatsRequestAllBuyersStatsFilters,
  ) => {
    // Are all filters types selected the same as the default filters?
    if (!isEqual(Object.keys(currentFilters), Object.keys(defaultFilters))) {
      return false;
    }
    // buyersList can be a default filter
    if (
      defaultFilters.buyerLists &&
      !isEqual(currentFilters.buyerLists, defaultFilters.buyerLists)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        debouncedSetBuyerTableFilters({ ...buyerTableFilters, buyerName: data.buyerName });
      })(),
    );
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, debouncedSetBuyerTableFilters, buyerTableFilters]);

  const queryResult = useAllBuyerStats({
    offset: ((pagination.current || 1) - 1) * (pagination.pageSize || DEFAULT_PAGINATION.pageSize),
    limit: pagination.pageSize,
    sort: buyerSort.field,
    sortOrder: buyerSort.order,
    filters: buyerTableFilters,
  });

  // TODO: Use a context provider to make this available to the child components
  // without passing too many props directly
  const tableContext: TableContext<AllBuyersStatsResponse> = {
    queryResult,
    totalCount: queryResult.data?.totalCount,
  };

  return (
    <>
      <div className={css.filterBar}>
        <div className={css.filterItems}>
          <form
            className={css.searchInput}
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              name="buyerName"
              label=""
              control={control}
              allowClear
            />
          </form>
          <Button
            onClick={
              window.guestUser ? openGuestAccessPassPaywall : () => setIsFilterDrawerOpen(true)
            }
          >
            <FilterOutlined className={css.filterIcon} /> Filters
          </Button>
        </div>
        <div className={css.columnsAndExport}>
          <div className={css.clearSelected}>
            {selectedBuyers.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedBuyers.length}
                  onClear={() => setSelectedBuyers([])}
                />
                :
              </>
            )}
          </div>
          {selectedBuyers && selectedBuyers.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={allColumns}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<BuyerColumns>[])])
              }
              dataType={EventDataTypes.buyer}
            />
          )}
          <div className={css.saveBuyers}>
            {selectedBuyers.length > 0 && !window.guestUser && (
              <BulkSaveBuyer buyerGuids={selectedBuyers} />
            )}
          </div>
          <ExportBuyersButton
            filters={buyerTableFilters}
            selectedBuyers={selectedBuyers}
            tableContext={tableContext}
          />
        </div>
      </div>
      <div className={css.viewFilters}>
        <QuickFilterBar
          filters={buyerTableFilters}
          sortState={buyerSort}
          tableContext={tableContext}
          onClearFilter={(key) => updateFilters({ ...buyerTableFilters, [key]: undefined })}
          onFilterClick={() => setIsFilterDrawerOpen(true)}
          hiddenSections={hiddenSections || []}
          clearAllFilters={() => updateFilters(defaultFilters)}
          clearAllFiltersHide={() => clearAllFiltersHide(buyerTableFilters, defaultFilters)}
        />
      </div>
      <BuyerTable
        filters={buyerTableFilters}
        sortOrder={buyerSort}
        tableContext={tableContext}
        onSortChange={updateSortState}
        selectedRows={selectedBuyers}
        onSelectedRowsChange={(rows) => setSelectedBuyers(rows)}
        selectedColumns={selectedColumns.map((column) => column.field)}
        pagination={pagination}
        onPaginationChange={setPagination}
        isSupplierRelationship={isSupplierRelationship}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <BuyerFilterForm
            filters={buyerTableFilters}
            onFiltersChange={debouncedSetBuyerTableFilters}
            onClose={() => setIsFilterDrawerOpen(false)}
            showTitle={isCollapseEnabled}
            options={{
              disabledSections: hiddenSections,
            }}
          />
        )}
      </FilterDrawer>
    </>
  );
}
