/**
 * Validate that the search term is either a single phrase in double quotes
 * e.g. "digital transformation" or it contains multiple words. e.g:
 * council westminster
 * TODO: We currently do no support multiple phrases
 * @param searchTerm
 * @returns
 */
export function IsValidDocumentSearch(searchTerm: string): boolean {
  return !!searchTerm.trim().length;
}

// Extracts search terms e.g. `These are "the best" "search terms" in "the world"`
// returns ["These", "are", "the best", "search terms", "in", "the world"]`

export function extractSearchTerms(searchTerm?: string): string[] {
  if (!searchTerm) return [];
  const matcher = /[^\s"]+|"([^"]+)("|$)/g;
  const searchTerms = [];
  let match;

  while ((match = matcher.exec(searchTerm))) {
    // add quoted terms without the quotes or unquoted words
    searchTerms.push(match[1] || match[0]);
  }

  return searchTerms;
}
