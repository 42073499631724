export const DOMAINS = [
  "gmail.com",
  "hotmail.com",
  "outlook.com",
  "icloud.com",
  "hotmail.co.uk",
  "aol.com",
  "hotmail.fr",
  "msn.com",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.*",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "neuf.fr",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "hotmail.es",
  "charter.net",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "sky.com",
  "yopmail.com",
  "duck.com",
];

export const DISALLOWED_DOMAINS_PATTERN = new RegExp(`^(.+)@(?!${DOMAINS.join("|")}$).*$`, "gi");
