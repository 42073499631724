import * as React from "react";
import { useRoute } from "wouter";

import StatisticsCard, { StatisticsCardProps } from "lib/core_components/StatisticsCard";
import { useOpenApi } from "lib/openApiContext";
import { SELECT_ALL as ALL_SIGNALS } from "lib/types/models";
import { capitalize } from "lib/utils";
import { EventNames, useTracking } from "../../lib/tracking";
import { HandleNavigation, ProfileType, SecondaryTab, SupplierRelationshipFilter } from "./types";

import css from "./HeaderStatisticCards.module.scss";

type HSCProps = {
  handleNavigation: HandleNavigation;
  signalCategory: ProfileType;
  signalName: string;
  onFiltersChange: React.Dispatch<React.SetStateAction<SupplierRelationshipFilter>>;
  filters: SupplierRelationshipFilter;
};

function HeaderStatisticCards({
  handleNavigation,
  signalCategory,
  signalName,
  onFiltersChange,
  filters,
}: HSCProps): JSX.Element {
  const api = useOpenApi();
  const [loading, setLoading] = React.useState(false);
  const [stats, setStats] = React.useState({
    allRecordsCount: 0,
    keywordRecordsCount: 0,
    expiriesCount: 0,
    keyAccountsCount: 0,
  });

  const { logEvent } = useTracking();

  React.useEffect(() => {
    void (async () => {
      setLoading(true);
      const resp = await api.getSupplierSignalStats({
        supplierSignalStatsRequest: {
          signalName: signalName,
          signalCategory: capitalize(signalCategory) + "s",
        },
      });

      if (resp) {
        setStats(resp);
      } else {
        console.error(`No stats found for ${signalName}`);
      }
      setLoading(false);
    })();
  }, [api, signalCategory, signalName]);

  const sendHighlightTrackingEvent = React.useCallback(
    (highlightType: string) => {
      logEvent(EventNames.highlightClicked, {
        "Highlight type": highlightType,
        "Context source": "Header card",
      });
    },
    [logEvent],
  );

  return (
    <div className={css.container}>
      <Card
        value={stats.allRecordsCount}
        loading={loading}
        objectType="Notice"
        description={`Matching this ${signalCategory}`}
        onClick={() => {
          sendHighlightTrackingEvent(`Records matching ${signalCategory}`);
          onFiltersChange({ keywords: [], textSearch: "" });
          handleNavigation(SecondaryTab.ALL_NOTICES);
        }}
        activateOnRoute={SecondaryTab.ALL_NOTICES}
        filters={filters}
      />
      {signalCategory === ProfileType.COMPETITOR ? (
        <Card
          value={stats.expiriesCount}
          loading={loading}
          objectType="Upcoming expiry"
          pluralObjectType="Upcoming expiries"
          description="Matching this competitor"
          onClick={() => {
            sendHighlightTrackingEvent("Upcoming expiries matching competitor");
            onFiltersChange({ keywords: [], textSearch: "" });
            handleNavigation(SecondaryTab.UPCOMING_EXPIRIES);
          }}
          activateOnRoute={SecondaryTab.UPCOMING_EXPIRIES}
          filters={filters}
        />
      ) : (
        <Card
          value={stats.keywordRecordsCount}
          loading={loading}
          objectType="Record"
          description="Matching keywords"
          onClick={() => {
            sendHighlightTrackingEvent("Records matching partner & keyword");
            onFiltersChange({ keywords: [ALL_SIGNALS], textSearch: "" });
            handleNavigation(SecondaryTab.ALL_NOTICES);
          }}
          activateOnRoute={SecondaryTab.ALL_NOTICES}
          activateOnAllKeywords
          filters={filters}
        />
      )}
      <Card
        value={stats.keyAccountsCount}
        loading={loading}
        objectType="Saved buyer"
        description={`Connected to ${signalCategory}`}
        onClick={() => {
          sendHighlightTrackingEvent(`Saved buyers connected to ${signalCategory}`);
          onFiltersChange({ keywords: [], textSearch: "" });
          handleNavigation(SecondaryTab.SAVED_BUYERS);
        }}
        activateOnRoute={SecondaryTab.SAVED_BUYERS}
        filters={filters}
      />
    </div>
  );
}

type CardProps = {
  activateOnRoute: string;
  // For the competitor/partner page the requirement is either ALL keywords or NO keywords
  activateOnAllKeywords?: boolean;
  filters: SupplierRelationshipFilter;
} & Omit<StatisticsCardProps, "active">;

function Card({ activateOnRoute, activateOnAllKeywords, filters, ...rest }: CardProps) {
  const [routeActive] = useRoute(activateOnRoute);
  const active = React.useMemo(() => {
    if (!routeActive || !filters) return false;

    // If "activateOnAllKeywords" is true, then the filters HAVE to include ALL_SIGNALS for it to be active
    if (activateOnAllKeywords) {
      return filters.keywords.includes(ALL_SIGNALS);
      // If "activateOnAllKeywords" is false then there CANNOT be any filters at all for it to be active
    } else {
      return filters.keywords.length === 0;
    }
  }, [routeActive, activateOnAllKeywords, filters]);

  return <StatisticsCard {...rest} active={active} />;
}

export default HeaderStatisticCards;
