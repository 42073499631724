import React from "react";
import { hot } from "react-hot-loader/root";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Menu } from "antd5";
import { Route, Router, Switch, useLocation } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import {
  DetailsContent,
  DetailsContentCard,
  DetailsHeader,
  DetailsPage,
} from "components/app_layout/DetailsLayout";
import BuyerHeader from "components/buyer_details/BuyerHeader";
import ContactsPanel from "components/buyer_details/contacts_panel/ContactsPanel";
import { DocumentSection } from "components/buyer_details/DocumentSection";
import { getTabConfigFromLocation, labelledRoutes } from "components/buyer_details/routingUtils";
import SummaryTab from "components/buyer_details/SummaryTab";
import { SupplierTab } from "components/buyer_details/SupplierTab";
import { PrimaryTab, SecondaryTab } from "components/buyer_details/types";
import UncollectedDocuments, {
  FilterableDocuments,
} from "components/documents/FilterableDocuments";
import {
  convertDocumentFiltersToSearchRequest,
  createDocumentColumns,
  DEFAULT_DOCUMENT_FILTERS,
  INCOMPLETE_COVERAGE_BUYERS,
} from "components/documents/utils";
import { FilterableFrameworks } from "components/framework/FilterableFrameworks";
import { FrameworkColumns } from "components/framework/FrameworkTable";
import { DEFAULT_FILTERS as DEFAULT_FRAMEWORK_FILTERS } from "components/framework/utils";
import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { FilterableNoticeTable } from "components/notices/FilterableNoticeTable";
import { NoticeColumns } from "components/notices/NoticeTable";
import { useFilterableNoticeTableState } from "components/notices/useFilterableNoticeTableState";
import { NoticeFilters } from "components/notices/utils";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import PaywallModal, { PaywallModalProps } from "components/paywall/PaywallModal";
import { BetaIcon } from "lib/core_components/Labels";
import {
  LAYER_CAKE_FILTERS,
  NEW_DOCUMENTS_BUYER_TAB,
  OLD_DOCUMENTS_BUYER_TAB,
} from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useBuyerCategories } from "lib/hooks/api/buyer/useBuyerCategories";
import { useDocuments } from "lib/hooks/api/documents/useDocuments";
import PaywallStar from "lib/icons/PaywallStar";
import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import { SortState } from "lib/search/types";
import { EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { BuyerDetails, ColumnSetting, Status } from "lib/types/models";
import { BuyerSupplierRelationshipPage } from "./SupplierBuyerRelationshipPage";

import css from "./BuyerDetailsPage.module.scss";

type Props = {
  buyer: BuyerDetails;
  represented?: { guid: string };
};

const FRAMEWORK_COLUMNS: ColumnSetting<FrameworkColumns>[] = [
  "signalScore",
  "title",
  "stage",
  "value",
  "suppliers",
  "closeDate",
].map((c) => {
  return {
    field: c,
    visible: true,
  } as ColumnSetting<FrameworkColumns>;
});

function BuyerDetailsPageWrapper(props: Props) {
  return (
    <Router base={`/buyers/${props.buyer.id}`}>
      <Switch>
        {/* buyer supplier relationship page */}
        <Route<{ supplierGuid?: string }> path="/supplier/:supplierGuid">
          {({ supplierGuid }) => (
            <BuyerSupplierRelationshipPage {...props} supplierGuid={supplierGuid} />
          )}
        </Route>
        <Route<{ signalId?: string }> path="/partner/:signalId">
          {({ signalId }) => (
            <BuyerSupplierRelationshipPage {...props} signalId={signalId} isPartner />
          )}
        </Route>
        <Route<{ signalId?: string }> path="/competitor/:signalId">
          {({ signalId }) => (
            <BuyerSupplierRelationshipPage {...props} signalId={signalId} isCompetitor />
          )}
        </Route>
        {/* Default */}
        <Route>
          <BuyerDetailsPage {...props} />
        </Route>
      </Switch>
    </Router>
  );
}

function BuyerDetailsPage({ buyer }: Props) {
  const isNewDocumentsTabEnabled = useVariableValue(NEW_DOCUMENTS_BUYER_TAB, false);
  const isOldDocumentsTabEnabled = useVariableValue(OLD_DOCUMENTS_BUYER_TAB, false);
  const layerCakeEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  const subscription = useSubscription();
  const frameworksEnabled = FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);
  const showFrameworksPaywall = !subscription.hasDataTypes("FRAMEWORKS");
  const showDocumentsPaywallBanner = !subscription.hasDataTypes("DOCUMENTS");
  const dialogManager = useDialogManager();

  const [location, navigate] = useLocation();
  const { setNoticeTableFilters, setNoticeTableColumns } = useFilterableNoticeTableState();
  const setLocation = React.useCallback(
    (route: string) => navigate(`${route}${window.location.search}`),
    [navigate],
  );

  const [tabConfig, setTabConfig] = React.useState(() =>
    getTabConfigFromLocation(location as PrimaryTab | SecondaryTab),
  );

  const activeTabLabel = React.useMemo(() => {
    const conf = getTabConfigFromLocation(location);
    const activeRoute = conf.secondary || conf.primary;
    return labelledRoutes[activeRoute];
  }, [location]);

  const headerClicked = ({ trackingType, route }: { trackingType: string; route: string }) => {
    logEvent(EventNames.highlightClicked, {
      "Context source": "Header card",
      "Highlight type": trackingType,
    });
    setNoticeTableFilters(undefined);
    setNoticeTableColumns(undefined);
    setLocation(route);
  };

  const tabClicked = React.useCallback(
    (loc: PrimaryTab | SecondaryTab) => {
      setLocation(loc);
      const config = getTabConfigFromLocation(loc);
      const activeRoute = config.secondary || config.primary;
      logEvent(EventNames.tabChanged, {
        "Context source": config.secondary ? "Secondary Tab" : "Primary Tab",
        "Tab Selected": labelledRoutes[activeRoute],
      });
    },
    [setLocation],
  );

  const { data: buyerDocumentsCoverage } = useDocuments(
    convertDocumentFiltersToSearchRequest(
      { ...DEFAULT_DOCUMENT_FILTERS, organisationIds: [buyer.guid] },
      { pageSize: 10, current: 1 },
    ),
    { enabled: tabConfig.primary === PrimaryTab.DOCUMENTS_NEW },
  );

  const { data: allCategories } = useBuyerCategories({
    enabled: tabConfig.primary === PrimaryTab.DOCUMENTS_NEW,
  });

  const buyerCategories = allCategories?.filter((category) =>
    buyer.categories.some((cat) => cat.buyer_category_id === category.id),
  );

  const parentCategories = allCategories?.filter((category) =>
    buyerCategories?.some((cat) => cat.parent_category_id === category.id),
  );

  const showDocumentsBanner = parentCategories?.some((category) =>
    INCOMPLETE_COVERAGE_BUYERS.includes(category.name),
  );

  React.useEffect(() => {
    const tc = getTabConfigFromLocation(location);

    // If it's a top-level tab change.... the filters are reset as well as the tab config changing
    if (tc.primary !== tabConfig.primary) {
      setTabConfig(tc);
    } else if (tc.secondary !== tabConfig.secondary) {
      setTabConfig(tc);
    }
  }, [location, tabConfig]);

  const displayedBuyerDetails: JSX.Element = React.useMemo(() => {
    const baseFilter: NoticeFilters = {
      ...EMPTY_FILTERS,
      buyers: [buyer.guid],
    };
    const allMatchingSignalsFilter: NoticeFilters = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
      relevanceScore: layerCakeEnabled ? ["Low"] : [],
    };
    const allMatchingExpiriesFilter: NoticeFilters = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
      relevanceScore: layerCakeEnabled ? ["Low"] : [],
      expiryDate: {
        filter: {
          relativeFrom: "PT0S",
        },
        hideNulls: true,
      },
      closeDate: {
        hideNulls: false,
      },
      stage: ["AWARDED"] as Status[],
      sort: { field: "expiry_date", order: "DESC" } as SortState,
    };
    const allMatchingOpenOpportunitiesFilter: NoticeFilters = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
      relevanceScore: layerCakeEnabled ? ["Low"] : [],
      stage: ["OPEN", "PRE_TENDER"] as Status[],
    };

    const currentSelectedTab = (): {
      filter: NoticeFilters;
      columns: NoticeColumns[];
      paywall: boolean;
    } => {
      switch (activeTabLabel) {
        case "Activity": {
          return {
            filter: baseFilter,
            columns: [
              "relevance_score",
              "title",
              "published_date",
              "expiry_date",
              "value",
              "stage",
            ],
            paywall: false,
          };
        }
        case "Activity: All activity": {
          return {
            filter: allMatchingSignalsFilter,
            columns: [
              "relevance_score",
              "title",
              "published_date",
              "expiry_date",
              "value",
              "stage",
            ],
            paywall: false,
          };
        }
        case "Activity: Upcoming contract expiries": {
          return {
            filter: allMatchingExpiriesFilter,
            columns: ["relevance_score", "title", "expiry_date", "value", "stage", "suppliers"],
            paywall: true,
          };
        }
        case "Activity: Open opportunities": {
          return {
            filter: allMatchingOpenOpportunitiesFilter,
            columns: [
              "relevance_score",
              "title",
              "published_date",
              "expiry_date",
              "value",
              "stage",
            ],
            paywall: false,
          };
        }
        default: {
          return {
            filter: baseFilter,
            columns: [
              "relevance_score",
              "title",
              "published_date",
              "expiry_date",
              "value",
              "stage",
            ],
            paywall: false,
          };
        }
      }
    };

    switch (tabConfig.primary) {
      case PrimaryTab.CONTACTS:
        return (
          <ContactsPanel
            buyer={buyer}
            handleTabNavigation={tabClicked}
            activeTab={tabConfig.secondary}
          />
        );

      case PrimaryTab.PROCUREMENT_ACTIVITY:
        return (
          <FilterableNoticeTable
            defaultFilters={currentSelectedTab().filter}
            defaultColumns={currentSelectedTab().columns}
            hiddenSections={["buyers"]}
            paywallEnabled={currentSelectedTab().paywall}
          />
        );

      case PrimaryTab.FRAMEWORKS:
        return frameworksEnabled && !showFrameworksPaywall ? (
          <FilterableFrameworks
            defaultColumns={FRAMEWORK_COLUMNS}
            defaultFilters={{ ...DEFAULT_FRAMEWORK_FILTERS, buyerIds: [buyer.guid] }}
            hiddenFilterSections={["buyers"]}
          />
        ) : (
          <></>
        );

      case PrimaryTab.SUPPLIERS:
        return <SupplierTab buyer={buyer} />;

      case PrimaryTab.DOCUMENTS:
        return <DocumentSection buyer={buyer} />;

      case PrimaryTab.DOCUMENTS_NEW:
        return (
          <TrackingProvider data={{ "Context source": "Document tab" }}>
            {showDocumentsPaywallBanner && (
              <PaywallBanner featureType="DOCUMENTS" className={css.paywallBanner} />
            )}
            <FilterableDocuments
              defaultColumns={createDocumentColumns({ excludedColumns: ["buyers"] })}
              defaultFilters={{ ...DEFAULT_DOCUMENT_FILTERS, organisationIds: [buyer.guid] }}
              hiddenFilters={["organisationIds"]}
              hiddenSections={["buyers"]}
              emptyState={
                buyerDocumentsCoverage?.totalResults === 0 ? <UncollectedDocuments /> : undefined
              }
              showBanner={showDocumentsBanner}
            />
          </TrackingProvider>
        );

      default:
        return (
          <SummaryTab
            buyerId={buyer.id}
            buyerGuid={buyer.guid}
            showMatchingExpiries={() => {
              tabClicked(SecondaryTab.UPCOMING_EXPIRIES);
            }}
          />
        );
    }
  }, [
    buyer,
    layerCakeEnabled,
    tabConfig.primary,
    tabConfig.secondary,
    activeTabLabel,
    tabClicked,
    frameworksEnabled,
    showFrameworksPaywall,
    showDocumentsPaywallBanner,
    buyerDocumentsCoverage?.totalResults,
    showDocumentsBanner,
  ]);

  return (
    <DetailsPage>
      <DetailsHeader className={css.noPaddingBottom}>
        <TrackingProvider data={{ "Context source": "Header" }}>
          <BuyerHeader buyer={buyer} onHeaderChange={headerClicked} />
        </TrackingProvider>

        <Menu mode="horizontal" selectedKeys={[tabConfig.primary]} className={css.menuTabs}>
          <Menu.Item
            key={PrimaryTab.SUMMARY}
            onClick={() => tabClicked(PrimaryTab.SUMMARY)}
            className={css.menuTabItem}
          >
            Summary
          </Menu.Item>
          <Menu.Item
            key={PrimaryTab.CONTACTS}
            onClick={() => tabClicked(PrimaryTab.CONTACTS)}
            className={css.menuTabItem}
          >
            Contacts
          </Menu.Item>
          <Menu.Item
            key={PrimaryTab.PROCUREMENT_ACTIVITY}
            onClick={() => tabClicked(PrimaryTab.PROCUREMENT_ACTIVITY)}
            className={css.menuTabItem}
          >
            Notices
          </Menu.Item>
          <Menu.Item
            key={PrimaryTab.FRAMEWORKS}
            onClick={() => {
              if (showFrameworksPaywall) {
                void dialogManager.openDialog(PaywallModal, {
                  featureType: "FRAMEWORKS",
                } as Omit<PaywallModalProps, "isOpen" | "onClose">);
              } else {
                tabClicked(PrimaryTab.FRAMEWORKS);
              }
            }}
            className={css.menuTabItem}
          >
            Frameworks{showFrameworksPaywall && <PaywallStar />}
          </Menu.Item>
          {isOldDocumentsTabEnabled && (
            <Menu.Item
              key={PrimaryTab.DOCUMENTS}
              onClick={() => tabClicked(PrimaryTab.DOCUMENTS)}
              className={css.menuTabItem}
            >
              <>
                <span>Documents</span> <BetaIcon />
              </>
            </Menu.Item>
          )}
          {isNewDocumentsTabEnabled && (
            <Menu.Item
              key={PrimaryTab.DOCUMENTS_NEW}
              onClick={() => tabClicked(PrimaryTab.DOCUMENTS_NEW)}
              className={css.menuTabItem}
            >
              <span>Documents</span> {showDocumentsPaywallBanner && <PaywallStar />}
            </Menu.Item>
          )}
          <Menu.Item
            key={PrimaryTab.SUPPLIERS}
            onClick={() => tabClicked(PrimaryTab.SUPPLIERS)}
            className={css.menuTabItem}
          >
            Suppliers
          </Menu.Item>
        </Menu>
      </DetailsHeader>

      {/* Summary and documents content is surrounded by a white background container
       from the outset, whereas the others are not */}
      {tabConfig.primary === PrimaryTab.SUMMARY || location === PrimaryTab.DOCUMENTS ? (
        <DetailsContentCard>
          <TrackingProvider data={{ "Context source": activeTabLabel }}>
            {displayedBuyerDetails}
          </TrackingProvider>
        </DetailsContentCard>
      ) : (
        <DetailsContent>
          <TrackingProvider data={{ "Context source": activeTabLabel }}>
            {displayedBuyerDetails}
          </TrackingProvider>
        </DetailsContent>
      )}
    </DetailsPage>
  );
}

function AdminBarComp({ represented }: Props) {
  return (
    <a href={`/admin/buyer/${represented?.guid}`} target="_blank">
      Edit in AP
    </a>
  );
}

export default hot(
  withAppLayout(BuyerDetailsPageWrapper, (props) => ({
    pageName: "Buyer Profile",
    pageViewProps: { "Buyer name": props.buyer.name, "Buyer ID": props.buyer.id },
    trackingData: { "Buyer name": props.buyer.name },
    AdminBarComp,
  })),
);
