import React from "react";
import { App, Empty, List, Popover, Tooltip } from "antd5"; // sketchy looking popover with andt global classes, perhaps redesign with Phil or Saph, upgrade message to new context

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { ClipboardContent, copyToClipboard } from "lib/clipboard";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, useTracking } from "lib/tracking";
import { BuyerDetails, DataTypeName } from "lib/types/models";
import { linkedinPeopleSearchURL } from "lib/utils";
import { useBuyerContacts } from "../../../lib/hooks/api/buyer/useBuyerContacts";

import css from "./Procurement.module.scss";

export function ProcurementContacts({ buyer }: { buyer: BuyerDetails }): JSX.Element {
  const { logEvent } = useTracking();

  const [contactData, setContactData] = React.useState<ProcurementContact[] | undefined>();

  const { data, isLoading, isError } = useBuyerContacts(buyer.id);

  React.useEffect(() => {
    const formattedContacts: ProcurementContact[] | undefined = data?.contacts.map((contact) => {
      return {
        name: contact.name,
        email: contact.emails.map((c) => c.value),
        last_referenced: contact.last_referenced,
        phone_number: contact.phone_numbers.map((c) => c.value),
      };
    });

    if (formattedContacts) {
      setContactData(formattedContacts);
    }
  }, [data]);

  const PROCUREMENT_COLUMNS: ColumnType<ProcurementContact>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      render: (_, c) => <EmailCell contact={c} />,
    },
    {
      key: "phone_number",
      title: "Phone Number",
      render: (_, c) => <PhoneCell contact={c} />,
    },
    {
      key: "linkedin",
      title: "LinkedIn",
      render: (_, c) => <LinkedInCell contact={c} buyerName={buyer.name} />,
    },
    {
      key: "last_referenced",
      title: "Last Active",
      dataIndex: "last_referenced",
    },
  ];

  return (
    <div>
      <Table<ProcurementContact>
        dataSource={contactData}
        columns={PROCUREMENT_COLUMNS}
        rowKey={(record) => record.name}
        loading={isLoading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No procurement contacts found"
            />
          ),
        }}
        isError={isError}
        pagination={{
          onChange: (pageNumber: number) => {
            logEvent(EventNames.paginationSelected, { Value: pageNumber });
          },
        }}
      />
    </div>
  );
}

type ProcurementContact = {
  name: string;
  email: string[];
  phone_number: string[];
  last_referenced: string;
};

function EmailCell({ contact }: { contact: ProcurementContact }) {
  if (contact.email.length > 1) {
    return (
      <>
        <ContactDetail
          requiredDataSubscription={undefined}
          data={contact.email[0]}
          dataType="email"
        />{" "}
        and{" "}
        <Popover
          trigger="click"
          overlayClassName={css.overflowPopover}
          title={<>Emails {contact.name.length > 0 ? `for ${contact.name}` : ""}</>}
          placement="right"
          content={
            <List>
              {contact.email.map((c, i) => (
                <List.Item key={i}>
                  <ContactDetail data={c} dataType="email" />
                </List.Item>
              ))}
            </List>
          }
        >
          <TextButton>{contact.email.slice(1).length} more</TextButton>
        </Popover>
      </>
    );
  }
  return <ContactDetail data={contact.email[0]} dataType="email" />;
}

function PhoneCell({ contact }: { contact: ProcurementContact }) {
  if (contact.phone_number.length > 1) {
    return (
      <>
        <ContactDetail data={contact.phone_number[0]} dataType="phone" /> and{" "}
        <Popover
          trigger="click"
          overlayClassName={css.overflowPopover}
          title={<>Phone numbers {contact.name.length > 0 ? `for ${contact.name}` : ""}</>}
          placement="left"
          content={
            <List>
              {contact.phone_number.map((c, i) => (
                <List.Item key={i}>
                  <ContactDetail data={c} dataType="phone" />
                </List.Item>
              ))}
            </List>
          }
        >
          <TextButton>{contact.phone_number.slice(1).length} more</TextButton>
        </Popover>
      </>
    );
  }
  return <ContactDetail data={contact.phone_number[0]} dataType="phone" />;
}

function LinkedInCell({ contact, buyerName }: { contact: ProcurementContact; buyerName: string }) {
  return (
    <TextLink
      eventName={EventNames.linkedinClicked}
      to={linkedinPeopleSearchURL(contact.name, buyerName)}
      targetType="external"
    >
      &nbsp;Search on Linkedin
    </TextLink>
  );
}

export function ContactDetail({
  data,
  dataType,
  requiredDataSubscription,
}: {
  data: string;
  dataType: "email" | "phone";
  requiredDataSubscription?: DataTypeName;
}): JSX.Element {
  const { message } = App.useApp();
  const { logEvent } = useTracking();
  const subscription = useSubscription();
  const canViewAdvancedContacts = requiredDataSubscription
    ? subscription.hasDataTypes(requiredDataSubscription)
    : true;

  const handleCopyToClipboard = (contactData: string | null) => {
    if (contactData) {
      switch (dataType) {
        case "email":
          logEvent(EventNames.emailCopied, {
            Value: contactData,
          });
          break;
        case "phone":
          logEvent(EventNames.phoneNumberCopied, {
            Value: contactData,
          });
      }
      const content: ClipboardContent = { plain: contactData };
      copyToClipboard(content);
      void message.success("Copied!", 2);
    }
  };

  return canViewAdvancedContacts ? (
    <Tooltip placement="top" title="Copy to clipboard">
      <span className={css.contactDetail} onClick={() => handleCopyToClipboard(data)}>
        {data}
      </span>
    </Tooltip>
  ) : (
    <span className={css.contactDetail}>
      {dataType === "email" ? (
        <>
          <span className={css.blurred}>{data}</span>@<span className={css.blurred}>{data}</span>
          .gov.uk
        </>
      ) : (
        <>
          +44 <span className={css.blurred}>{data}</span>
        </>
      )}
    </span>
  );
}
