import React from "react";
import { Alert, Empty } from "antd5";

import { BetaIcon } from "lib/core_components/Labels";

import css from "./pageStates.module.scss";

import ARROW from "../../../assets/images/spend/arrow.svg";
import BUYER_ACTIVITY from "../../../assets/images/spend/buyer_activity.svg";
import PARTNER_SPEND from "../../../assets/images/spend/partner_spend.svg";
import SPEND_CHART from "../../../assets/images/spend/spend_chart.svg";

export function InitialState() {
  return (
    <div className={css.initialStateContainer}>
      <div className={css.startHereContainer}>
        <div className={css.startHereArrowContainer}>
          <h3>Start here by selecting a view</h3>
          <img src={ARROW} alt="arrow" className={css.viewArrow} />
        </div>
        <p>View spend data between buyers and suppliers of interest.</p>
      </div>
      <div className={css.mainTitleContainer}>
        <h2>Get started with Spend</h2>
        <p>
          Invoice level spend data helps you to uncover even more relationships, opportunities, &
          insights.
        </p>
      </div>
      <div className={css.infoGrid}>
        <img
          src={BUYER_ACTIVITY}
          alt="Investigate supplier & buyer activity"
          className={css.infoGridImage}
        />
        <img
          src={PARTNER_SPEND}
          alt="Track competitor & partner spend"
          className={css.infoGridImage}
        />
        <img src={SPEND_CHART} alt="Supplier & Buyer Activity" className={css.infoGridImage} />
        <h3>Investigate supplier & buyer activity</h3>
        <h3>Track competitor & partner spend</h3>
        <h3>See spend in your topic</h3>
        <p>See which suppliers are winning the most work, and the buyers they’ve worked with.</p>
        <p>
          At a glance, see what which partners and competitors are most active, and who they have
          relationships with.
        </p>
        <p>
          Identify where spend in your topic area has been flowing, by buyer, buyer type, supplier,
          & more.
        </p>
        <div className={css.topicSpendComingSoon}>COMING SOON</div>
      </div>
    </div>
  );
}

export function EmptyState() {
  return (
    <div className={css.emptyState}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" className={css.emptyStateImage} />
      <div>
        <h3>No results match your current filters</h3>
        <h4>Try removing or adjusting your filters to widen your search.</h4>
      </div>
      <Alert
        message={<BetaIcon dark />}
        description={
          <span>
            We are currently still collecting data for some buyers which could be impacting your
            search. Right now we only cover Central Gov as well as a portion of Local Gov.
          </span>
        }
        type="warning"
        className={css.emptyStateBetaMsg}
      />
    </div>
  );
}
