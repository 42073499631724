/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentSearchQueryFilters } from './DocumentSearchQueryFilters';
import {
    DocumentSearchQueryFiltersFromJSON,
    DocumentSearchQueryFiltersFromJSONTyped,
    DocumentSearchQueryFiltersToJSON,
} from './DocumentSearchQueryFilters';
import type { DocumentSearchQueryScoreFilter } from './DocumentSearchQueryScoreFilter';
import {
    DocumentSearchQueryScoreFilterFromJSON,
    DocumentSearchQueryScoreFilterFromJSONTyped,
    DocumentSearchQueryScoreFilterToJSON,
} from './DocumentSearchQueryScoreFilter';

/**
 * 
 * @export
 * @interface DocumentSearchQuery
 */
export interface DocumentSearchQuery {
    /**
     * The page number to return, starting from 0
     * @type {number}
     * @memberof DocumentSearchQuery
     */
    page: number;
    /**
     * The number of results to return per page
     * @type {number}
     * @memberof DocumentSearchQuery
     */
    size: number;
    /**
     * The field to sort the results by
     * @type {string}
     * @memberof DocumentSearchQuery
     */
    sortBy: DocumentSearchQuerySortByEnum;
    /**
     * The order to sort the results by
     * @type {string}
     * @memberof DocumentSearchQuery
     */
    sortOrder: DocumentSearchQuerySortOrderEnum;
    /**
     * The minimum score to return. In the range of 10 - 30 at the moment
     * @type {number}
     * @memberof DocumentSearchQuery
     */
    minScore?: number;
    /**
     * The language codes to be used for the search. 
     *         Internally we currently support en, de, es, fr, and nl. 
     *         Other languages will fall back to the default analyzer.
     * @type {Array<string>}
     * @memberof DocumentSearchQuery
     */
    languages: Array<string>;
    /**
     * Query identifier, used for logging & debugging
     * @type {string}
     * @memberof DocumentSearchQuery
     */
    id: string;
    /**
     * 
     * @type {DocumentSearchQueryScoreFilter}
     * @memberof DocumentSearchQuery
     */
    scoreFilter?: DocumentSearchQueryScoreFilter;
    /**
     * 
     * @type {DocumentSearchQueryFilters}
     * @memberof DocumentSearchQuery
     */
    filters: DocumentSearchQueryFilters;
}


/**
 * @export
 */
export const DocumentSearchQuerySortByEnum = {
    Score: 'score',
    Title: 'title',
    PublishDate: 'publishDate',
    Category: 'category',
    CreateDate: 'createDate'
} as const;
export type DocumentSearchQuerySortByEnum = typeof DocumentSearchQuerySortByEnum[keyof typeof DocumentSearchQuerySortByEnum];

/**
 * @export
 */
export const DocumentSearchQuerySortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type DocumentSearchQuerySortOrderEnum = typeof DocumentSearchQuerySortOrderEnum[keyof typeof DocumentSearchQuerySortOrderEnum];


/**
 * Check if a given object implements the DocumentSearchQuery interface.
 */
export function instanceOfDocumentSearchQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sortBy" in value;
    isInstance = isInstance && "sortOrder" in value;
    isInstance = isInstance && "languages" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "filters" in value;

    return isInstance;
}

export function DocumentSearchQueryFromJSON(json: any): DocumentSearchQuery {
    return DocumentSearchQueryFromJSONTyped(json, false);
}

export function DocumentSearchQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSearchQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'sortBy': json['sortBy'],
        'sortOrder': json['sortOrder'],
        'minScore': !exists(json, 'minScore') ? undefined : json['minScore'],
        'languages': json['languages'],
        'id': json['id'],
        'scoreFilter': !exists(json, 'scoreFilter') ? undefined : DocumentSearchQueryScoreFilterFromJSON(json['scoreFilter']),
        'filters': DocumentSearchQueryFiltersFromJSON(json['filters']),
    };
}

export function DocumentSearchQueryToJSON(value?: DocumentSearchQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'sortBy': value.sortBy,
        'sortOrder': value.sortOrder,
        'minScore': value.minScore,
        'languages': value.languages,
        'id': value.id,
        'scoreFilter': DocumentSearchQueryScoreFilterToJSON(value.scoreFilter),
        'filters': DocumentSearchQueryFiltersToJSON(value.filters),
    };
}

