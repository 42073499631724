import React from "react";
import { Button } from "antd5";

import { Table } from "../../lib/core_components/Table";
import { ColumnType } from "../../lib/core_components/Table/ColumnTypes";
import { BidQualification_Result } from "../../lib/generated/app-service-gql/graphql";
import { Markdown } from "../reports/builder_components/Markdown";

import css from "./NoticeQualificationResult.module.scss";

export type QualificationResult = BidQualification_Result;
type Props = {
  result: QualificationResult[];
  onEdit: () => void;
  onRetry: () => void;
  onDocumentSelect: (result: QualificationResult) => void;
};
export function NoticeQualificationResult({ result, onEdit, onRetry, onDocumentSelect }: Props) {
  const columns: ColumnType<QualificationResult>[] = [
    {
      title: "Qualification Criteria",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text: string) => <Markdown text={text} targetType="new-tab" />,
    },
    {
      title: "Sources",
      dataIndex: "sources",
      key: "sources",
      render: (_, result: QualificationResult) =>
        result.sources?.map((source, index) => (
          <Button key={index} onClick={() => onDocumentSelect(result)}>
            {source.document}
          </Button>
        )),
    },
  ];

  return (
    <div className={css.container}>
      <Table columns={columns} dataSource={result} rowKey="question" isError={false} />

      <div className={css.actionsContainer}>
        <Button type="primary" onClick={onEdit}>
          Edit criteria
        </Button>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  );
}
