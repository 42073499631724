import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import {
  createSavedViewCreatedEvent,
  createSavedViewPinnedEvent,
} from "components/my_feed/tracking";
import { GetLeadSubscriptionsResponse, SavedViewType } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type Params = {
  view: SavedViewType;
  alertFrequency?: "DAILY" | "WEEKLY" | "NEVER";
  existingLeadSubscriptions?: GetLeadSubscriptionsResponse;
};

export function useCreateView(options?: UseMutationOptions<string, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  return useMutation(
    async ({ view, alertFrequency, existingLeadSubscriptions }) => {
      const newViewID = (await api.createSavedView({ createSavedViewRequest: { savedView: view } }))
        .savedViewId;

      await api.createPinnedSavedView({
        createPinnedSavedViewRequest: { savedViewId: newViewID },
      });

      // if the selected alert frequency is "NEVER" we just skip creating the view alert
      if (alertFrequency && alertFrequency !== "NEVER") {
        const existingPersonalSubscription =
          existingLeadSubscriptions?.personalSubscriptions.email.find(
            (subscription) => subscription.frequency === alertFrequency,
          );

        if (existingPersonalSubscription) {
          // Fix to align with the current api - contains IDs of all current saved view alerts in this frequency
          const subscribedViewIds = existingPersonalSubscription.leadSubscriptionContents
            ? Object.values(existingPersonalSubscription.leadSubscriptionContents)
                .filter((s) => s.resource_type === "SavedView")
                .map((s) => s.resource_id)
            : [];

          await api.updateLeadSubscription({
            id: existingPersonalSubscription.id,
            updateLeadSubscriptionRequest: {
              viewIds: [newViewID, ...subscribedViewIds],
              frequency: alertFrequency,
            },
          });
        } else {
          await api.createLeadSubscription({
            createLeadSubscriptionRequest: {
              viewIds: [newViewID],
              frequency: alertFrequency,
              type: "EMAIL",
            },
          });
        }
      }

      return newViewID;
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["views"]);
        await queryClient.invalidateQueries(["pinnedViews"]);
        await queryClient.invalidateQueries(["leadSubscriptions"]);
        await queryClient.invalidateQueries(["leadSubscriptionContents"]);
        logEvent(EventNames.viewCreated, createSavedViewCreatedEvent(vars.view.name, data));
        logEvent(
          EventNames.savedViewPinned,
          createSavedViewPinnedEvent("Pinned View Created", vars.view.name, "Automatic pin", data),
        );
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
