import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { DocumentGroup } from "components/buyer_details/DocumentSection";
import { DocumentType, documentTypeMapper } from "components/buyer_details/documentUtils";
import { Signal } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { DocumentMatch } from "lib/types/models";

/**
 * As grouped data is not 100% working and defined in the backend
 * right now we will group in the front end while categories are being finalised.
 * In the future the categories being displayed may be stored in the backend
 * @param matchedDocuments
 * @returns
 */
function groupDocuments(matchedDocuments: DocumentMatch[]): DocumentGroup[] {
  const documentGroups = new Map<DocumentType, DocumentMatch[]>();
  for (const document of matchedDocuments) {
    const documentType = documentTypeMapper(document.document.document_type ?? "");
    const existingDocumentGroup = documentGroups.get(documentType);
    if (existingDocumentGroup) {
      existingDocumentGroup.push(document);
    } else {
      documentGroups.set(documentType, [document]);
    }
  }

  const documentGroupArray = Array.from(documentGroups, ([documentType, documentMatches]) => ({
    documentType,
    documentMatches,
  }));

  return documentGroupArray.sort((a, b) => a.documentType - b.documentType);
}
type DocumentFilters = {
  ownerGuid?: string;
  fetch_all?: boolean;
};

type GroupedDocuments = {
  documents: DocumentGroup[];
  signals: Record<string, Signal>;
};

export function useDocuments(
  filters: DocumentFilters,
  pageSize?: number,
  options?: UseQueryOptions<GroupedDocuments, unknown, GroupedDocuments, [string, DocumentFilters]>,
) {
  const api = useStotlesApi();

  return useQuery(
    ["buyers", filters],
    async () => {
      const resp = await api.getDocuments(filters, pageSize);

      return {
        documents: groupDocuments(resp.matched_documents),
        signals: resp.signals,
      };
    },
    options,
  );
}
