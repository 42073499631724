/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MultipleEntitiesDecisionCleaningData } from './MultipleEntitiesDecisionCleaningData';
import {
    MultipleEntitiesDecisionCleaningDataFromJSON,
    MultipleEntitiesDecisionCleaningDataFromJSONTyped,
    MultipleEntitiesDecisionCleaningDataToJSON,
} from './MultipleEntitiesDecisionCleaningData';

/**
 * 
 * @export
 * @interface MultipleEntitiesDecision
 */
export interface MultipleEntitiesDecision {
    /**
     * 
     * @type {string}
     * @memberof MultipleEntitiesDecision
     */
    qualification: string;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleEntitiesDecision
     */
    cancelled: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleEntitiesDecision
     */
    note?: string;
    /**
     * 
     * @type {MultipleEntitiesDecisionCleaningData}
     * @memberof MultipleEntitiesDecision
     */
    cleaningData?: MultipleEntitiesDecisionCleaningData;
}

/**
 * Check if a given object implements the MultipleEntitiesDecision interface.
 */
export function instanceOfMultipleEntitiesDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "qualification" in value;
    isInstance = isInstance && "cancelled" in value;

    return isInstance;
}

export function MultipleEntitiesDecisionFromJSON(json: any): MultipleEntitiesDecision {
    return MultipleEntitiesDecisionFromJSONTyped(json, false);
}

export function MultipleEntitiesDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleEntitiesDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qualification': json['qualification'],
        'cancelled': json['cancelled'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'cleaningData': !exists(json, 'cleaning_data') ? undefined : MultipleEntitiesDecisionCleaningDataFromJSON(json['cleaning_data']),
    };
}

export function MultipleEntitiesDecisionToJSON(value?: MultipleEntitiesDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qualification': value.qualification,
        'cancelled': value.cancelled,
        'note': value.note,
        'cleaning_data': MultipleEntitiesDecisionCleaningDataToJSON(value.cleaningData),
    };
}

