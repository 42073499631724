import * as React from "react";

import AssigneeSelect from "components/form_components/AssigneeSelect";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { SignalDto } from "lib/generated/app-api";

type PropsNew = {
  recordGuid: string;
  procurementStageId: string;
  procurementStageStage: string;
  signals: SignalDto[];
  score?: number | null;
  assignee?: {
    guid: string;
    firstName: string;
    lastName: string;
  };
};
export function AssigneeContainer({
  recordGuid,
  procurementStageId,
  procurementStageStage,
  signals,
  score,
  assignee,
}: PropsNew): JSX.Element | null {
  return (
    // We stop the bubbling here so the slide-out doesn't open
    <div onClick={(e) => e.stopPropagation()}>
      <AssigneeSelect
        recordGuid={recordGuid}
        procurementStageId={procurementStageId}
        procurementStageStage={procurementStageStage}
        signals={signals}
        score={score}
        assignee={assignee}
        iconClassName={SHOW_ON_HOVER}
      />
    </div>
  );
}
