import React, { useMemo, useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";
import _ from "lodash";
import isEqual from "lodash.isequal";

import ExportFrameworkSuppliersButton from "components/framework/ExportFrameworkSuppliersButton";
import BulkSaveSupplier from "components/suppliers/BulkSaveSupplier";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import {
  FILTER_COLLAPSE_MATCH_STYLING,
  NEW_SUPPLIER_FILTER,
  useVariableValue,
} from "lib/featureFlags";
import { useDescribeFramework } from "lib/hooks/api/frameworks/useDescribeFramework";
import { useSearchFrameworkSuppliers } from "lib/hooks/api/frameworks/useSearchFrameworkOrganisations";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { useURLState } from "lib/hooks/useURLState";
import { EventDataTypes, useTracking } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import { isDefined } from "lib/utils";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import { FilterForm, FilterFormProps } from "../suppliers/FilterForm";
import { QuickFilterBar } from "../suppliers/QuickFilterBar";
import { AdditionalSupplierData, SupplierTable } from "../suppliers/SupplierTable";
import { useSuppliersTracking } from "../suppliers/tracking";
import {
  convertSupplierFiltersToRequest,
  DEFAULT_FILTERS,
  parseSuppliersUrlState,
  SupplierColumns,
  SupplierFilters,
} from "../suppliers/utils";
import { ALL_SUPPLIER_COLUMNS, DEFAULT_SUPPLIER_COLUMNS } from "./utils";

import css from "./FrameworkSupplierTable.module.scss";

type Props = {
  frameworkId: string;
  defaultFilters?: SupplierFilters;
  defaultColumns?: ColumnSetting<SupplierColumns>[];
  allAvailableColumns?: ColumnSetting<SupplierColumns>[];
  hiddenFilterSections?: FilterFormProps["hiddenSections"];
  linkToBuyerRelationshipPage?: { buyerGuid: string };
};

export function FrameworkSuppliersTable({
  frameworkId,
  defaultFilters = DEFAULT_FILTERS,
  hiddenFilterSections,
  linkToBuyerRelationshipPage,
}: Props) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_MATCH_STYLING, false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(DEFAULT_SUPPLIER_COLUMNS);
  const [urlFilters = defaultFilters, setFilters] = useURLState<SupplierFilters>(
    "supplierFilters",
    defaultFilters,
    parseSuppliersUrlState,
  );

  const [supplierSearch, setSupplierSearch] = useState(urlFilters.text);
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((f: SupplierFilters) => {
    const newMinusSort = { ...f, sort: undefined };
    const defaultMinusSort = { ...defaultFilters, sort: undefined };
    if (!isEqual(newMinusSort, defaultMinusSort) && f.sort?.field === "name") {
      f.sort = { field: "awards_count", order: "DESC" };
    }
    setFilters(f);
    setSupplierSearch(f.text);
  });

  const { data: frameworkData, isLoading } = useDescribeFramework(frameworkId);
  const { trackingData } = useTracking();
  const filterableSuppliersTrackingData = { ...trackingData, "CTA actioned": "Filter" };
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(filterableSuppliersTrackingData);

  const tracking = useSuppliersTracking();
  const debouncedFreeSearchLog = useDebounce(() => tracking.freeSearchLog());

  const frameworkLots = frameworkData?.lots;

  /**
   * In the case of filtering by the subset of suppliers within a framework, this useMemo makes sure that
   * the guids stay within the subset provided by the defaultFilters
   */
  const filtersWithSupplierGuids = useMemo((): SupplierFilters => {
    if (!frameworkLots || !frameworkData) {
      return urlFilters;
    }

    let lotsToFilterBy = frameworkLots;
    if (urlFilters.lotIds?.length) {
      lotsToFilterBy = frameworkLots.filter((lot) => urlFilters.lotIds?.includes(lot.id));
    }

    // This value will be used if we are filerting by lots, otherwise the full set of guids will be used
    const supplierGuidsInLots = lotsToFilterBy
      .map((lot) => lot.supplierGuids)
      .flat()
      .filter(isDefined);

    const guids = supplierGuidsInLots.length
      ? Array.from(new Set(supplierGuidsInLots))
      : frameworkData.suppliers?.map((s) => s.id) || [];

    // If there are lotsToFilterBy, then only select guids within those lots. Otherwise revert back to the
    // set of all supplier guids within this entire framework
    return {
      ...urlFilters,
      guids: urlFilters.guids ? _.intersection(urlFilters.guids, guids) : guids,
    };
  }, [frameworkData, frameworkLots, urlFilters]);

  const loggedFilterOpen = () => {
    setIsFilterDrawerOpen(true);
    tracking.filterDrawerOpened();
  };

  const { data: frameworkSupplierData } = useSearchFrameworkSuppliers(
    frameworkId,
    convertSupplierFiltersToRequest(filtersWithSupplierGuids),
    filtersWithSupplierGuids.sort || { field: "name", order: "ASC" },
    { current: 0, pageSize: 10000 },
  );

  const additionalData = React.useMemo<AdditionalSupplierData>(() => {
    if (!frameworkData || !frameworkData.suppliers || !frameworkSupplierData) {
      return {
        frameworkData: new Map(),
      };
    }
    const perSupplierData: AdditionalSupplierData["frameworkData"] = new Map();
    for (const supplier of frameworkData.suppliers) {
      const supplierFrameworkData = {
        frameworkId: frameworkData.id,
        callOffStats: frameworkSupplierData.organisations.find((org) => org.id === supplier.id)
          ?.callOffs,
        lots: frameworkData.lots.filter((lot) => lot.supplierGuids?.includes(supplier.id)),
      };

      perSupplierData.set(supplier.id, supplierFrameworkData);
    }

    return {
      frameworkData: perSupplierData,
    };
  }, [frameworkData, frameworkSupplierData]);

  return (
    <div className={css.container}>
      <div className={css.actionsContainer}>
        <div className={css.actions}>
          <Input
            placeholder="Search by supplier name"
            prefix={<SearchOutlined />}
            value={supplierSearch}
            onChange={(e) => {
              setSupplierSearch(e.target.value);
              debouncedSetFilters({ ...urlFilters, text: e.target.value });
              debouncedFreeSearchLog();
            }}
            allowClear
            className={css.searchInput}
          />
          <Button onClick={window.guestUser ? openGuestAccessPassPaywall : loggedFilterOpen}>
            <FilterOutlined className={css.filterIcon} /> Filters
          </Button>
        </div>
        <div className={css.actions}>
          <div className={css.clearSelected}>
            {selectedSuppliers && selectedSuppliers.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedSuppliers.length}
                  onClear={() => setSelectedSuppliers([])}
                />
                :
              </>
            )}
          </div>
          {selectedSuppliers && selectedSuppliers.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={ALL_SUPPLIER_COLUMNS}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<SupplierColumns>[])])
              }
              dataType={EventDataTypes.supplier}
            />
          )}
          {!window.guestUser &&
            enableNewSupplierFilter &&
            window.currentUser?.use_supplier_name === false && (
              <div className={css.saveSuppliers}>
                {selectedSuppliers.length > 0 && !window.guestUser && (
                  <BulkSaveSupplier supplierGuids={selectedSuppliers} />
                )}
              </div>
            )}
          <ExportFrameworkSuppliersButton
            frameworkId={frameworkId}
            totalResults={filtersWithSupplierGuids.guids?.length || 0}
            isLoading={isLoading}
            selectedSupplierGuids={selectedSuppliers}
          />
        </div>
      </div>
      <QuickFilterBar
        filters={filtersWithSupplierGuids}
        onClearFilter={(key) => setFilters({ ...urlFilters, [key]: undefined })}
        onFilterClick={() => setIsFilterDrawerOpen(true)}
        hiddenFilterSections={hiddenFilterSections}
        clearAllFilters={() => setFilters(defaultFilters)}
      />
      <SupplierTable
        filters={filtersWithSupplierGuids}
        onSortChange={(sort) => setFilters({ ...urlFilters, sort })}
        selectedColumns={selectedColumns.map((f) => f.field)}
        selectedRows={selectedSuppliers}
        onSelectedRowsChange={(rows) => setSelectedSuppliers(rows)}
        linkToBuyerRelationshipPage={linkToBuyerRelationshipPage}
        additionalData={additionalData}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            closeDrawer={() => setIsFilterDrawerOpen(false)}
            showTitle={isCollapseEnabled}
            onChange={debouncedSetFilters}
            filters={urlFilters}
            hiddenSections={hiddenFilterSections}
            frameworkLots={frameworkLots}
          />
        )}
      </FilterDrawer>
    </div>
  );
}
