import React, { useState } from "react";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";
import isEqual from "lodash.isequal";

import { PaywallBanner } from "components/paywall/PaywallBanner";
import Signal from "components/tags/Signal";
import { getBuyerSupplierRelationshipDestination } from "lib/appDestinations";
import MultiSelectDropdown, { Option } from "lib/core_components/MultiSelectDropdownList";
import { createUseDebounce } from "lib/debounce";
import { useSignalCategories } from "lib/hooks/api/teams/useSignalSettings";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { SignalCategory } from "lib/StotlesApi";
import { EventNames, useTracking } from "lib/tracking";
import { BuyerSummary, SELECT_ALL } from "lib/types/models";
import { PartnerCompetitorColumns, PartnersCompetitorsTable } from "./PartnerCompetitorTable";

import css from "./FilterablePartnerCompetitors.module.scss";

function getSignalOptions(signalOptions: string[], category: SignalCategory): Option<string>[] {
  return signalOptions.map((signal) => ({
    title: signal,
    value: signal,
    OptionComponent: (
      <Signal
        signal={{ name: signal, category }}
        paywallConfig={{
          requiredDataType: "BUYERS",
          categoriesToHide: [SignalCategory.PARTNER, SignalCategory.COMPETITOR],
        }}
      />
    ),
  }));
}

const PARTNER_FIELDS: PartnerCompetitorColumns[] = [
  "nameAndLastActivity",
  "allActivity",
  "relevantAwards",
  "signals",
  "spendData",
];

const COMPETITOR_FIELDS: PartnerCompetitorColumns[] = [
  "nameAndLastActivity",
  "allActivity",
  "upcomingExpiries",
  "signals",
  "spendData",
];

type BuyerSignalsFilter = {
  keywords: string[];
  partners: string[];
  competitors: string[];
  supplierName?: string;
};

export const DEFAULT_FILTERS: BuyerSignalsFilter = {
  keywords: [],
  partners: [],
  competitors: [],
  supplierName: "",
};

const useDebounce500 = createUseDebounce(500);

export function FilterablePartnerCompetitors({
  type,
  buyer,
}: {
  type: "partner" | "competitor";
  buyer: BuyerSummary;
}) {
  const isPartner = type === "partner";
  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Supplier tab",
  });
  const { data: signals } = useSignalCategories();
  const [filters, setFilters] = useState<BuyerSignalsFilter>(DEFAULT_FILTERS);
  const [textSearch, setTextSearch] = useState(DEFAULT_FILTERS.supplierName);
  const { logEvent } = useTracking();

  const textSearchEvent = useDebounce500((text: string) => {
    logEvent(EventNames.filterActioned, {
      "Filter name": `${type} search`,
      "Filter selection": text,
      "Action type": text.length > 0 ? "Filter applied" : "Filter cleared",
    });
    setFilters((f) => ({ ...f, supplierName: text }));
  });

  if (!hasSuppliers) {
    return <PaywallBanner featureType={isPartner ? "PARTNERS" : "COMPETITORS"} />;
  }

  const selectedOptions = {
    partners: filters.partners.includes(SELECT_ALL)
      ? signals?.partnerNames || []
      : filters.partners,
    competitors: filters.competitors.includes(SELECT_ALL)
      ? signals?.competitorNames || []
      : filters.competitors,
    keywords: filters.keywords.includes(SELECT_ALL)
      ? signals?.keywordNames || []
      : filters.keywords,
  };

  return (
    <div className={css.container}>
      {window.currentUser && ( // Don't show filters for guest pass users
        <div className={css.filterBar}>
          <Input
            placeholder={`Search ${type}s`}
            onChange={(e) => {
              setTextSearch(e.target.value);
              textSearchEvent(e.target.value);
            }}
            value={textSearch}
            className={css.textSearch}
            prefix={<SearchOutlined />}
            allowClear
          />
          <MultiSelectDropdown
            title="Keywords"
            searchable
            options={getSignalOptions(signals?.keywordNames || [], SignalCategory.KEYWORD)}
            selectedOptions={selectedOptions.keywords}
            onChange={(keywords) => setFilters({ ...filters, keywords })}
          />

          <MultiSelectDropdown
            title="Partners"
            searchable
            options={getSignalOptions(signals?.partnerNames || [], SignalCategory.PARTNER)}
            selectedOptions={selectedOptions.partners}
            onChange={(partners) => setFilters({ ...filters, partners })}
          />

          <MultiSelectDropdown
            title="Competitors"
            searchable
            options={getSignalOptions(signals?.competitorNames || [], SignalCategory.COMPETITOR)}
            selectedOptions={selectedOptions.competitors}
            onChange={(competitors) => setFilters({ ...filters, competitors })}
          />

          {!isEqual(filters, DEFAULT_FILTERS) && (
            <Button
              onClick={() => {
                setFilters(DEFAULT_FILTERS);
                setTextSearch("");
              }}
              type="link"
              icon={<CloseCircleOutlined />}
            >
              Clear all filters
            </Button>
          )}
        </div>
      )}
      <PartnersCompetitorsTable
        nameColumnTitle={
          isPartner ? "Partner name & last activity" : "Competitor name & last activity"
        }
        fields={isPartner ? PARTNER_FIELDS : COMPETITOR_FIELDS}
        buyer={buyer}
        onRowClick={(s) => getBuyerSupplierRelationshipDestination(s.guid, "..", "partner")}
        signalCategory={isPartner ? "Partners" : "Competitors"}
        filters={{ kind: type, ...filters }}
      />
    </div>
  );
}
