import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { APIError } from "lib/Api";
import { SupplierSummaryDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = ["suppliers", string | undefined];

export function useSupplier(
  guid?: string,
  options?: UseQueryOptions<
    SupplierSummaryDto | null,
    unknown,
    SupplierSummaryDto | null,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["suppliers", guid],
    async ({ queryKey: [, guid] }) => {
      if (!guid) {
        return null;
      }
      try {
        const response = await api.getSupplier({ guid: guid });
        return response;
      } catch (e) {
        if (e instanceof APIError && e.statusCode === 404) {
          return null;
        }
        throw e;
      }
    },
    options,
  );
}
