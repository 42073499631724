/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddBuyersRequest,
  AdminBuyerListDetails,
  AdminBuyerListsResponse,
  AdminCreateBuyerListRequest,
  AssignBuyerCategoriesRequest,
  AvailableSavedViewsResponse,
  BuyerModelReadResponse,
  BuyerModelUpdateRequest,
  BuyerRelationshipsReadResponse,
  CreateBuyerRelationshipsRequest,
  CreateGuestAccessPassRequest,
  CreateGuestAccessPassResponse,
  CreateRegionRequest,
  CreateUserRequest,
  CreateUserResponse,
  DeleteBuyerRelationshipsRequest,
  DescribeCompanyRequest,
  DescribeCompanyResponse,
  DescribeRegionRequest,
  DescribeRegionResponse,
  DescribeUserRequest,
  DescribeUserResponse,
  FeedSettingsPreviewRequest,
  FeedSettingsQueryPreviewResponse,
  ForwardGeocodeRequest,
  ForwardGeocodeResponse,
  GenerateRecordSectionResponse,
  GenerateRecordsSectionRequest,
  GetBuyerListPermissionsAdminResponse,
  GetCompanyLogoResponse,
  GetGuestAccessPassResponse,
  InspectRecordSearchRequest,
  InspectRecordSearchResponse,
  ListSavedViewsRequest,
  ListSavedViewsResponse,
  MergeCompanyRequest,
  MergeCompanyResponse,
  MigrateLayerCakeRequest,
  QuedPreviewRequest,
  QuedPreviewResponse,
  QuedQueryHighlightingRequest,
  QuedQueryHighlightingResponse,
  RemoveBuyersRequest,
  SaveDataSourcesRequest,
  SaveReportRequest,
  SaveReportRequest200Response,
  ScrapeLinkMetadataRequest,
  ScrapeLinkMetadataResponse,
  SearchCompaniesRequest,
  SearchCompaniesResponse,
  SearchEntitiesRequest,
  SearchEntitiesResponse,
  SearchRolesRequest,
  SearchRolesResponse,
  SearchTeamsRequest,
  SearchTeamsResponse,
  SwitchCompanyRequest,
  SwitchTeamRequest,
  UpdateBuyerListOwnerRequest,
  UpdateBuyerListPermissionsRequest,
  UpdateBuyerListSharingRequest,
  UpdateGuestAccessPassRequest,
  UpdateRegionRequest,
  UpdateUserConfiguredRequest,
  UpdateUserDetailsRequest,
  UpsertSavedViewsRequest,
  UserDetails,
  UserDetailsRequest,
  UserDetailsResponse,
  UserSearchRequest,
  UserSearchResponse,
} from '../models';
import {
    AddBuyersRequestFromJSON,
    AddBuyersRequestToJSON,
    AdminBuyerListDetailsFromJSON,
    AdminBuyerListDetailsToJSON,
    AdminBuyerListsResponseFromJSON,
    AdminBuyerListsResponseToJSON,
    AdminCreateBuyerListRequestFromJSON,
    AdminCreateBuyerListRequestToJSON,
    AssignBuyerCategoriesRequestFromJSON,
    AssignBuyerCategoriesRequestToJSON,
    AvailableSavedViewsResponseFromJSON,
    AvailableSavedViewsResponseToJSON,
    BuyerModelReadResponseFromJSON,
    BuyerModelReadResponseToJSON,
    BuyerModelUpdateRequestFromJSON,
    BuyerModelUpdateRequestToJSON,
    BuyerRelationshipsReadResponseFromJSON,
    BuyerRelationshipsReadResponseToJSON,
    CreateBuyerRelationshipsRequestFromJSON,
    CreateBuyerRelationshipsRequestToJSON,
    CreateGuestAccessPassRequestFromJSON,
    CreateGuestAccessPassRequestToJSON,
    CreateGuestAccessPassResponseFromJSON,
    CreateGuestAccessPassResponseToJSON,
    CreateRegionRequestFromJSON,
    CreateRegionRequestToJSON,
    CreateUserRequestFromJSON,
    CreateUserRequestToJSON,
    CreateUserResponseFromJSON,
    CreateUserResponseToJSON,
    DeleteBuyerRelationshipsRequestFromJSON,
    DeleteBuyerRelationshipsRequestToJSON,
    DescribeCompanyRequestFromJSON,
    DescribeCompanyRequestToJSON,
    DescribeCompanyResponseFromJSON,
    DescribeCompanyResponseToJSON,
    DescribeRegionRequestFromJSON,
    DescribeRegionRequestToJSON,
    DescribeRegionResponseFromJSON,
    DescribeRegionResponseToJSON,
    DescribeUserRequestFromJSON,
    DescribeUserRequestToJSON,
    DescribeUserResponseFromJSON,
    DescribeUserResponseToJSON,
    FeedSettingsPreviewRequestFromJSON,
    FeedSettingsPreviewRequestToJSON,
    FeedSettingsQueryPreviewResponseFromJSON,
    FeedSettingsQueryPreviewResponseToJSON,
    ForwardGeocodeRequestFromJSON,
    ForwardGeocodeRequestToJSON,
    ForwardGeocodeResponseFromJSON,
    ForwardGeocodeResponseToJSON,
    GenerateRecordSectionResponseFromJSON,
    GenerateRecordSectionResponseToJSON,
    GenerateRecordsSectionRequestFromJSON,
    GenerateRecordsSectionRequestToJSON,
    GetBuyerListPermissionsAdminResponseFromJSON,
    GetBuyerListPermissionsAdminResponseToJSON,
    GetCompanyLogoResponseFromJSON,
    GetCompanyLogoResponseToJSON,
    GetGuestAccessPassResponseFromJSON,
    GetGuestAccessPassResponseToJSON,
    InspectRecordSearchRequestFromJSON,
    InspectRecordSearchRequestToJSON,
    InspectRecordSearchResponseFromJSON,
    InspectRecordSearchResponseToJSON,
    ListSavedViewsRequestFromJSON,
    ListSavedViewsRequestToJSON,
    ListSavedViewsResponseFromJSON,
    ListSavedViewsResponseToJSON,
    MergeCompanyRequestFromJSON,
    MergeCompanyRequestToJSON,
    MergeCompanyResponseFromJSON,
    MergeCompanyResponseToJSON,
    MigrateLayerCakeRequestFromJSON,
    MigrateLayerCakeRequestToJSON,
    QuedPreviewRequestFromJSON,
    QuedPreviewRequestToJSON,
    QuedPreviewResponseFromJSON,
    QuedPreviewResponseToJSON,
    QuedQueryHighlightingRequestFromJSON,
    QuedQueryHighlightingRequestToJSON,
    QuedQueryHighlightingResponseFromJSON,
    QuedQueryHighlightingResponseToJSON,
    RemoveBuyersRequestFromJSON,
    RemoveBuyersRequestToJSON,
    SaveDataSourcesRequestFromJSON,
    SaveDataSourcesRequestToJSON,
    SaveReportRequestFromJSON,
    SaveReportRequestToJSON,
    SaveReportRequest200ResponseFromJSON,
    SaveReportRequest200ResponseToJSON,
    ScrapeLinkMetadataRequestFromJSON,
    ScrapeLinkMetadataRequestToJSON,
    ScrapeLinkMetadataResponseFromJSON,
    ScrapeLinkMetadataResponseToJSON,
    SearchCompaniesRequestFromJSON,
    SearchCompaniesRequestToJSON,
    SearchCompaniesResponseFromJSON,
    SearchCompaniesResponseToJSON,
    SearchEntitiesRequestFromJSON,
    SearchEntitiesRequestToJSON,
    SearchEntitiesResponseFromJSON,
    SearchEntitiesResponseToJSON,
    SearchRolesRequestFromJSON,
    SearchRolesRequestToJSON,
    SearchRolesResponseFromJSON,
    SearchRolesResponseToJSON,
    SearchTeamsRequestFromJSON,
    SearchTeamsRequestToJSON,
    SearchTeamsResponseFromJSON,
    SearchTeamsResponseToJSON,
    SwitchCompanyRequestFromJSON,
    SwitchCompanyRequestToJSON,
    SwitchTeamRequestFromJSON,
    SwitchTeamRequestToJSON,
    UpdateBuyerListOwnerRequestFromJSON,
    UpdateBuyerListOwnerRequestToJSON,
    UpdateBuyerListPermissionsRequestFromJSON,
    UpdateBuyerListPermissionsRequestToJSON,
    UpdateBuyerListSharingRequestFromJSON,
    UpdateBuyerListSharingRequestToJSON,
    UpdateGuestAccessPassRequestFromJSON,
    UpdateGuestAccessPassRequestToJSON,
    UpdateRegionRequestFromJSON,
    UpdateRegionRequestToJSON,
    UpdateUserConfiguredRequestFromJSON,
    UpdateUserConfiguredRequestToJSON,
    UpdateUserDetailsRequestFromJSON,
    UpdateUserDetailsRequestToJSON,
    UpsertSavedViewsRequestFromJSON,
    UpsertSavedViewsRequestToJSON,
    UserDetailsFromJSON,
    UserDetailsToJSON,
    UserDetailsRequestFromJSON,
    UserDetailsRequestToJSON,
    UserDetailsResponseFromJSON,
    UserDetailsResponseToJSON,
    UserSearchRequestFromJSON,
    UserSearchRequestToJSON,
    UserSearchResponseFromJSON,
    UserSearchResponseToJSON,
} from '../models';

export interface AddBuyersToListRequest {
    id: string;
    addBuyersRequest: AddBuyersRequest;
}

export interface AssignBuyerCategoriesOperationRequest {
    assignBuyerCategoriesRequest: AssignBuyerCategoriesRequest;
}

export interface BuyerModelReadRequest {
    id: string;
}

export interface BuyerModelUpdateOperationRequest {
    id: string;
    buyerModelUpdateRequest: BuyerModelUpdateRequest;
}

export interface BuyerRelationshipsReadRequest {
    id: string;
}

export interface CreateBuyerListRequest {
    adminCreateBuyerListRequest: AdminCreateBuyerListRequest;
}

export interface CreateBuyerRelationshipsOperationRequest {
    id: string;
    createBuyerRelationshipsRequest: CreateBuyerRelationshipsRequest;
}

export interface CreateGuestAccessPassOperationRequest {
    createGuestAccessPassRequest: CreateGuestAccessPassRequest;
}

export interface CreateRegionOperationRequest {
    createRegionRequest: CreateRegionRequest;
}

export interface CreateUserOperationRequest {
    createUserRequest: CreateUserRequest;
}

export interface DeleteBuyerListRequest {
    id: string;
}

export interface DeleteBuyerRelationshipsOperationRequest {
    id: string;
    deleteBuyerRelationshipsRequest: DeleteBuyerRelationshipsRequest;
}

export interface DeleteGuestAccessPassRequest {
    id: string;
}

export interface DeleteUserRequest {
    id: string;
}

export interface DescribeCompanyOperationRequest {
    describeCompanyRequest: DescribeCompanyRequest;
}

export interface DescribeRegionByIdRequest {
    describeRegionRequest: DescribeRegionRequest;
}

export interface DescribeUserOperationRequest {
    describeUserRequest: DescribeUserRequest;
}

export interface ForwardGeocodeOperationRequest {
    forwardGeocodeRequest: ForwardGeocodeRequest;
}

export interface GenerateRecordsSectionOperationRequest {
    generateRecordsSectionRequest: GenerateRecordsSectionRequest;
}

export interface GetBuyerListRequest {
    id: string;
}

export interface GetBuyerListPermissionsRequest {
    id: string;
}

export interface GetBuyerListsRequest {
    userId: string;
}

export interface GetCompanyLogoRequest {
    guid: string;
}

export interface GetGuestAccessPassRequest {
    id: string;
}

export interface GetQuedPreviewRequest {
    quedPreviewRequest: QuedPreviewRequest;
}

export interface GetQuedQueryHighlightingRequest {
    quedQueryHighlightingRequest: QuedQueryHighlightingRequest;
}

export interface GetUserDetailsRequest {
    id: string;
}

export interface InspectRecordSearchOperationRequest {
    inspectRecordSearchRequest: InspectRecordSearchRequest;
}

export interface ListSavedViewsOperationRequest {
    listSavedViewsRequest: ListSavedViewsRequest;
}

export interface MergeCompanyOperationRequest {
    mergeCompanyRequest: MergeCompanyRequest;
}

export interface MigrateLayerCakeOperationRequest {
    migrateLayerCakeRequest: MigrateLayerCakeRequest;
}

export interface PreviewFeedSettingsQueryRequest {
    feedSettingsPreviewRequest: FeedSettingsPreviewRequest;
}

export interface RemoveBuyersFromListRequest {
    id: string;
    removeBuyersRequest: RemoveBuyersRequest;
}

export interface SaveDataSourcesOperationRequest {
    saveDataSourcesRequest: SaveDataSourcesRequest;
}

export interface SaveReportRequestRequest {
    saveReportRequest: SaveReportRequest;
}

export interface ScrapeLinkMetadataOperationRequest {
    scrapeLinkMetadataRequest: ScrapeLinkMetadataRequest;
}

export interface SearchCompaniesOperationRequest {
    searchCompaniesRequest: SearchCompaniesRequest;
}

export interface SearchEntitiesOperationRequest {
    searchEntitiesRequest: SearchEntitiesRequest;
}

export interface SearchRolesOperationRequest {
    searchRolesRequest: SearchRolesRequest;
}

export interface SearchTeamsOperationRequest {
    searchTeamsRequest: SearchTeamsRequest;
}

export interface SearchUsersRequest {
    userSearchRequest: UserSearchRequest;
}

export interface SwitchCompanyOperationRequest {
    switchCompanyRequest: SwitchCompanyRequest;
}

export interface SwitchTeamOperationRequest {
    switchTeamRequest: SwitchTeamRequest;
}

export interface UpdateBuyerListOwnerOperationRequest {
    id: string;
    updateBuyerListOwnerRequest: UpdateBuyerListOwnerRequest;
}

export interface UpdateBuyerListPermissionsOperationRequest {
    id: string;
    updateBuyerListPermissionsRequest: UpdateBuyerListPermissionsRequest;
}

export interface UpdateBuyerListSharingOperationRequest {
    id: string;
    updateBuyerListSharingRequest: UpdateBuyerListSharingRequest;
}

export interface UpdateGuestAccessPassOperationRequest {
    id: string;
    updateGuestAccessPassRequest: UpdateGuestAccessPassRequest;
}

export interface UpdateRegionOperationRequest {
    updateRegionRequest: UpdateRegionRequest;
}

export interface UpdateUserConfiguredOperationRequest {
    id: string;
    updateUserConfiguredRequest: UpdateUserConfiguredRequest;
}

export interface UpdateUserDetailsOperationRequest {
    id: string;
    updateUserDetailsRequest: UpdateUserDetailsRequest;
}

export interface UpsertSavedViewsOperationRequest {
    upsertSavedViewsRequest: UpsertSavedViewsRequest;
}

export interface UserDetailsOperationRequest {
    userDetailsRequest: UserDetailsRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addBuyersToListRaw(requestParameters: AddBuyersToListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBuyersToList.');
        }

        if (requestParameters.addBuyersRequest === null || requestParameters.addBuyersRequest === undefined) {
            throw new runtime.RequiredError('addBuyersRequest','Required parameter requestParameters.addBuyersRequest was null or undefined when calling addBuyersToList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/add`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBuyersRequestToJSON(requestParameters.addBuyersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addBuyersToList(requestParameters: AddBuyersToListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addBuyersToListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async assignBuyerCategoriesRaw(requestParameters: AssignBuyerCategoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.assignBuyerCategoriesRequest === null || requestParameters.assignBuyerCategoriesRequest === undefined) {
            throw new runtime.RequiredError('assignBuyerCategoriesRequest','Required parameter requestParameters.assignBuyerCategoriesRequest was null or undefined when calling assignBuyerCategories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/buyer/bulk_category_assignment/assign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignBuyerCategoriesRequestToJSON(requestParameters.assignBuyerCategoriesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async assignBuyerCategories(requestParameters: AssignBuyerCategoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.assignBuyerCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async buyerModelReadRaw(requestParameters: BuyerModelReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerModelReadResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling buyerModelRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerModelReadResponseFromJSON(jsonValue));
    }

    /**
     */
    async buyerModelRead(requestParameters: BuyerModelReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerModelReadResponse> {
        const response = await this.buyerModelReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async buyerModelUpdateRaw(requestParameters: BuyerModelUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling buyerModelUpdate.');
        }

        if (requestParameters.buyerModelUpdateRequest === null || requestParameters.buyerModelUpdateRequest === undefined) {
            throw new runtime.RequiredError('buyerModelUpdateRequest','Required parameter requestParameters.buyerModelUpdateRequest was null or undefined when calling buyerModelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BuyerModelUpdateRequestToJSON(requestParameters.buyerModelUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async buyerModelUpdate(requestParameters: BuyerModelUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.buyerModelUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async buyerRelationshipsReadRaw(requestParameters: BuyerRelationshipsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerRelationshipsReadResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling buyerRelationshipsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyers/{id}/relationships`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerRelationshipsReadResponseFromJSON(jsonValue));
    }

    /**
     */
    async buyerRelationshipsRead(requestParameters: BuyerRelationshipsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerRelationshipsReadResponse> {
        const response = await this.buyerRelationshipsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBuyerListRaw(requestParameters: CreateBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBuyerListDetails>> {
        if (requestParameters.adminCreateBuyerListRequest === null || requestParameters.adminCreateBuyerListRequest === undefined) {
            throw new runtime.RequiredError('adminCreateBuyerListRequest','Required parameter requestParameters.adminCreateBuyerListRequest was null or undefined when calling createBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCreateBuyerListRequestToJSON(requestParameters.adminCreateBuyerListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBuyerListDetailsFromJSON(jsonValue));
    }

    /**
     */
    async createBuyerList(requestParameters: CreateBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBuyerListDetails> {
        const response = await this.createBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBuyerRelationshipsRaw(requestParameters: CreateBuyerRelationshipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createBuyerRelationships.');
        }

        if (requestParameters.createBuyerRelationshipsRequest === null || requestParameters.createBuyerRelationshipsRequest === undefined) {
            throw new runtime.RequiredError('createBuyerRelationshipsRequest','Required parameter requestParameters.createBuyerRelationshipsRequest was null or undefined when calling createBuyerRelationships.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyers/{id}/relationships`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBuyerRelationshipsRequestToJSON(requestParameters.createBuyerRelationshipsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async createBuyerRelationships(requestParameters: CreateBuyerRelationshipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.createBuyerRelationshipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createGuestAccessPassRaw(requestParameters: CreateGuestAccessPassOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateGuestAccessPassResponse>> {
        if (requestParameters.createGuestAccessPassRequest === null || requestParameters.createGuestAccessPassRequest === undefined) {
            throw new runtime.RequiredError('createGuestAccessPassRequest','Required parameter requestParameters.createGuestAccessPassRequest was null or undefined when calling createGuestAccessPass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/guest_access_passes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGuestAccessPassRequestToJSON(requestParameters.createGuestAccessPassRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateGuestAccessPassResponseFromJSON(jsonValue));
    }

    /**
     */
    async createGuestAccessPass(requestParameters: CreateGuestAccessPassOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateGuestAccessPassResponse> {
        const response = await this.createGuestAccessPassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createRegionRaw(requestParameters: CreateRegionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRegionRequest === null || requestParameters.createRegionRequest === undefined) {
            throw new runtime.RequiredError('createRegionRequest','Required parameter requestParameters.createRegionRequest was null or undefined when calling createRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/regions/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRegionRequestToJSON(requestParameters.createRegionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createRegion(requestParameters: CreateRegionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRegionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserResponse>> {
        if (requestParameters.createUserRequest === null || requestParameters.createUserRequest === undefined) {
            throw new runtime.RequiredError('createUserRequest','Required parameter requestParameters.createUserRequest was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestToJSON(requestParameters.createUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async createUser(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserResponse> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBuyerListRaw(requestParameters: DeleteBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteBuyerList(requestParameters: DeleteBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBuyerRelationshipsRaw(requestParameters: DeleteBuyerRelationshipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBuyerRelationships.');
        }

        if (requestParameters.deleteBuyerRelationshipsRequest === null || requestParameters.deleteBuyerRelationshipsRequest === undefined) {
            throw new runtime.RequiredError('deleteBuyerRelationshipsRequest','Required parameter requestParameters.deleteBuyerRelationshipsRequest was null or undefined when calling deleteBuyerRelationships.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyers/{id}/relationships`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteBuyerRelationshipsRequestToJSON(requestParameters.deleteBuyerRelationshipsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteBuyerRelationships(requestParameters: DeleteBuyerRelationshipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteBuyerRelationshipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGuestAccessPassRaw(requestParameters: DeleteGuestAccessPassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteGuestAccessPass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/guest_access_passes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteGuestAccessPass(requestParameters: DeleteGuestAccessPassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGuestAccessPassRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async describeCompanyRaw(requestParameters: DescribeCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeCompanyResponse>> {
        if (requestParameters.describeCompanyRequest === null || requestParameters.describeCompanyRequest === undefined) {
            throw new runtime.RequiredError('describeCompanyRequest','Required parameter requestParameters.describeCompanyRequest was null or undefined when calling describeCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/companies/describe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DescribeCompanyRequestToJSON(requestParameters.describeCompanyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeCompanyResponseFromJSON(jsonValue));
    }

    /**
     */
    async describeCompany(requestParameters: DescribeCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeCompanyResponse> {
        const response = await this.describeCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async describeRegionByIdRaw(requestParameters: DescribeRegionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeRegionResponse>> {
        if (requestParameters.describeRegionRequest === null || requestParameters.describeRegionRequest === undefined) {
            throw new runtime.RequiredError('describeRegionRequest','Required parameter requestParameters.describeRegionRequest was null or undefined when calling describeRegionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/regions/describe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DescribeRegionRequestToJSON(requestParameters.describeRegionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeRegionResponseFromJSON(jsonValue));
    }

    /**
     */
    async describeRegionById(requestParameters: DescribeRegionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeRegionResponse> {
        const response = await this.describeRegionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async describeUserRaw(requestParameters: DescribeUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeUserResponse>> {
        if (requestParameters.describeUserRequest === null || requestParameters.describeUserRequest === undefined) {
            throw new runtime.RequiredError('describeUserRequest','Required parameter requestParameters.describeUserRequest was null or undefined when calling describeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/describe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DescribeUserRequestToJSON(requestParameters.describeUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async describeUser(requestParameters: DescribeUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeUserResponse> {
        const response = await this.describeUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forwardGeocodeRaw(requestParameters: ForwardGeocodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwardGeocodeResponse>> {
        if (requestParameters.forwardGeocodeRequest === null || requestParameters.forwardGeocodeRequest === undefined) {
            throw new runtime.RequiredError('forwardGeocodeRequest','Required parameter requestParameters.forwardGeocodeRequest was null or undefined when calling forwardGeocode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/regions/forward_geocode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForwardGeocodeRequestToJSON(requestParameters.forwardGeocodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForwardGeocodeResponseFromJSON(jsonValue));
    }

    /**
     */
    async forwardGeocode(requestParameters: ForwardGeocodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwardGeocodeResponse> {
        const response = await this.forwardGeocodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateRecordsSectionRaw(requestParameters: GenerateRecordsSectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateRecordSectionResponse>> {
        if (requestParameters.generateRecordsSectionRequest === null || requestParameters.generateRecordsSectionRequest === undefined) {
            throw new runtime.RequiredError('generateRecordsSectionRequest','Required parameter requestParameters.generateRecordsSectionRequest was null or undefined when calling generateRecordsSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/report_configs/generate_records_section`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateRecordsSectionRequestToJSON(requestParameters.generateRecordsSectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateRecordSectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async generateRecordsSection(requestParameters: GenerateRecordsSectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateRecordSectionResponse> {
        const response = await this.generateRecordsSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableSavedViewsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableSavedViewsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/available_saved_views`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableSavedViewsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAvailableSavedViews(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableSavedViewsResponse> {
        const response = await this.getAvailableSavedViewsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListRaw(requestParameters: GetBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBuyerListDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBuyerListDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerList(requestParameters: GetBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBuyerListDetails> {
        const response = await this.getBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListPermissionsRaw(requestParameters: GetBuyerListPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBuyerListPermissionsAdminResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBuyerListPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBuyerListPermissionsAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerListPermissions(requestParameters: GetBuyerListPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBuyerListPermissionsAdminResponse> {
        const response = await this.getBuyerListPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListsRaw(requestParameters: GetBuyerListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBuyerListsResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getBuyerLists.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/buyer_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBuyerListsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerLists(requestParameters: GetBuyerListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBuyerListsResponse> {
        const response = await this.getBuyerListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyLogoRaw(requestParameters: GetCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanyLogoResponse>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling getCompanyLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/companies/{guid}/logo`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyLogoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyLogo(requestParameters: GetCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanyLogoResponse> {
        const response = await this.getCompanyLogoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGuestAccessPassRaw(requestParameters: GetGuestAccessPassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGuestAccessPassResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGuestAccessPass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/guest_access_passes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuestAccessPassResponseFromJSON(jsonValue));
    }

    /**
     */
    async getGuestAccessPass(requestParameters: GetGuestAccessPassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGuestAccessPassResponse> {
        const response = await this.getGuestAccessPassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuedPreviewRaw(requestParameters: GetQuedPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuedPreviewResponse>> {
        if (requestParameters.quedPreviewRequest === null || requestParameters.quedPreviewRequest === undefined) {
            throw new runtime.RequiredError('quedPreviewRequest','Required parameter requestParameters.quedPreviewRequest was null or undefined when calling getQuedPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/qued/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuedPreviewRequestToJSON(requestParameters.quedPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuedPreviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async getQuedPreview(requestParameters: GetQuedPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuedPreviewResponse> {
        const response = await this.getQuedPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuedQueryHighlightingRaw(requestParameters: GetQuedQueryHighlightingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuedQueryHighlightingResponse>> {
        if (requestParameters.quedQueryHighlightingRequest === null || requestParameters.quedQueryHighlightingRequest === undefined) {
            throw new runtime.RequiredError('quedQueryHighlightingRequest','Required parameter requestParameters.quedQueryHighlightingRequest was null or undefined when calling getQuedQueryHighlighting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/qued/highlighting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuedQueryHighlightingRequestToJSON(requestParameters.quedQueryHighlightingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuedQueryHighlightingResponseFromJSON(jsonValue));
    }

    /**
     */
    async getQuedQueryHighlighting(requestParameters: GetQuedQueryHighlightingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuedQueryHighlightingResponse> {
        const response = await this.getQuedQueryHighlightingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserDetailsRaw(requestParameters: GetUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getUserDetails(requestParameters: GetUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetails> {
        const response = await this.getUserDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inspectRecordSearchRaw(requestParameters: InspectRecordSearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectRecordSearchResponse>> {
        if (requestParameters.inspectRecordSearchRequest === null || requestParameters.inspectRecordSearchRequest === undefined) {
            throw new runtime.RequiredError('inspectRecordSearchRequest','Required parameter requestParameters.inspectRecordSearchRequest was null or undefined when calling inspectRecordSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/record_search_debugger/inspect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectRecordSearchRequestToJSON(requestParameters.inspectRecordSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectRecordSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async inspectRecordSearch(requestParameters: InspectRecordSearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectRecordSearchResponse> {
        const response = await this.inspectRecordSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSavedViewsRaw(requestParameters: ListSavedViewsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSavedViewsResponse>> {
        if (requestParameters.listSavedViewsRequest === null || requestParameters.listSavedViewsRequest === undefined) {
            throw new runtime.RequiredError('listSavedViewsRequest','Required parameter requestParameters.listSavedViewsRequest was null or undefined when calling listSavedViews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/pinned_saved_views/list_saved_views`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListSavedViewsRequestToJSON(requestParameters.listSavedViewsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSavedViewsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listSavedViews(requestParameters: ListSavedViewsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSavedViewsResponse> {
        const response = await this.listSavedViewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mergeCompanyRaw(requestParameters: MergeCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergeCompanyResponse>> {
        if (requestParameters.mergeCompanyRequest === null || requestParameters.mergeCompanyRequest === undefined) {
            throw new runtime.RequiredError('mergeCompanyRequest','Required parameter requestParameters.mergeCompanyRequest was null or undefined when calling mergeCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/companies/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeCompanyRequestToJSON(requestParameters.mergeCompanyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergeCompanyResponseFromJSON(jsonValue));
    }

    /**
     */
    async mergeCompany(requestParameters: MergeCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergeCompanyResponse> {
        const response = await this.mergeCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async migrateLayerCakeRaw(requestParameters: MigrateLayerCakeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.migrateLayerCakeRequest === null || requestParameters.migrateLayerCakeRequest === undefined) {
            throw new runtime.RequiredError('migrateLayerCakeRequest','Required parameter requestParameters.migrateLayerCakeRequest was null or undefined when calling migrateLayerCake.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/teams/migrate_layer_cake`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MigrateLayerCakeRequestToJSON(requestParameters.migrateLayerCakeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async migrateLayerCake(requestParameters: MigrateLayerCakeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.migrateLayerCakeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewFeedSettingsQueryRaw(requestParameters: PreviewFeedSettingsQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedSettingsQueryPreviewResponse>> {
        if (requestParameters.feedSettingsPreviewRequest === null || requestParameters.feedSettingsPreviewRequest === undefined) {
            throw new runtime.RequiredError('feedSettingsPreviewRequest','Required parameter requestParameters.feedSettingsPreviewRequest was null or undefined when calling previewFeedSettingsQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/company/feed_settings/preview_query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedSettingsPreviewRequestToJSON(requestParameters.feedSettingsPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedSettingsQueryPreviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async previewFeedSettingsQuery(requestParameters: PreviewFeedSettingsQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedSettingsQueryPreviewResponse> {
        const response = await this.previewFeedSettingsQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBuyersFromListRaw(requestParameters: RemoveBuyersFromListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeBuyersFromList.');
        }

        if (requestParameters.removeBuyersRequest === null || requestParameters.removeBuyersRequest === undefined) {
            throw new runtime.RequiredError('removeBuyersRequest','Required parameter requestParameters.removeBuyersRequest was null or undefined when calling removeBuyersFromList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBuyersRequestToJSON(requestParameters.removeBuyersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeBuyersFromList(requestParameters: RemoveBuyersFromListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeBuyersFromListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveDataSourcesRaw(requestParameters: SaveDataSourcesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.saveDataSourcesRequest === null || requestParameters.saveDataSourcesRequest === undefined) {
            throw new runtime.RequiredError('saveDataSourcesRequest','Required parameter requestParameters.saveDataSourcesRequest was null or undefined when calling saveDataSources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/company/data_sources/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveDataSourcesRequestToJSON(requestParameters.saveDataSourcesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveDataSources(requestParameters: SaveDataSourcesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveDataSourcesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveReportRequestRaw(requestParameters: SaveReportRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SaveReportRequest200Response>> {
        if (requestParameters.saveReportRequest === null || requestParameters.saveReportRequest === undefined) {
            throw new runtime.RequiredError('saveReportRequest','Required parameter requestParameters.saveReportRequest was null or undefined when calling saveReportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/report_configs/save_report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveReportRequestToJSON(requestParameters.saveReportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveReportRequest200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async saveReportRequest(requestParameters: SaveReportRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaveReportRequest200Response> {
        const response = await this.saveReportRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async scrapeLinkMetadataRaw(requestParameters: ScrapeLinkMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrapeLinkMetadataResponse>> {
        if (requestParameters.scrapeLinkMetadataRequest === null || requestParameters.scrapeLinkMetadataRequest === undefined) {
            throw new runtime.RequiredError('scrapeLinkMetadataRequest','Required parameter requestParameters.scrapeLinkMetadataRequest was null or undefined when calling scrapeLinkMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/report_configs/scrape_link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScrapeLinkMetadataRequestToJSON(requestParameters.scrapeLinkMetadataRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrapeLinkMetadataResponseFromJSON(jsonValue));
    }

    /**
     */
    async scrapeLinkMetadata(requestParameters: ScrapeLinkMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrapeLinkMetadataResponse> {
        const response = await this.scrapeLinkMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchCompaniesRaw(requestParameters: SearchCompaniesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchCompaniesResponse>> {
        if (requestParameters.searchCompaniesRequest === null || requestParameters.searchCompaniesRequest === undefined) {
            throw new runtime.RequiredError('searchCompaniesRequest','Required parameter requestParameters.searchCompaniesRequest was null or undefined when calling searchCompanies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/companies/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchCompaniesRequestToJSON(requestParameters.searchCompaniesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchCompaniesResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchCompanies(requestParameters: SearchCompaniesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchCompaniesResponse> {
        const response = await this.searchCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchEntitiesRaw(requestParameters: SearchEntitiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchEntitiesResponse>> {
        if (requestParameters.searchEntitiesRequest === null || requestParameters.searchEntitiesRequest === undefined) {
            throw new runtime.RequiredError('searchEntitiesRequest','Required parameter requestParameters.searchEntitiesRequest was null or undefined when calling searchEntities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/entity_resolution/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchEntitiesRequestToJSON(requestParameters.searchEntitiesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchEntitiesResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchEntities(requestParameters: SearchEntitiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchEntitiesResponse> {
        const response = await this.searchEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRolesRaw(requestParameters: SearchRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchRolesResponse>> {
        if (requestParameters.searchRolesRequest === null || requestParameters.searchRolesRequest === undefined) {
            throw new runtime.RequiredError('searchRolesRequest','Required parameter requestParameters.searchRolesRequest was null or undefined when calling searchRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/roles/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRolesRequestToJSON(requestParameters.searchRolesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchRolesResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchRoles(requestParameters: SearchRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchRolesResponse> {
        const response = await this.searchRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchTeamsRaw(requestParameters: SearchTeamsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchTeamsResponse>> {
        if (requestParameters.searchTeamsRequest === null || requestParameters.searchTeamsRequest === undefined) {
            throw new runtime.RequiredError('searchTeamsRequest','Required parameter requestParameters.searchTeamsRequest was null or undefined when calling searchTeams.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/teams/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchTeamsRequestToJSON(requestParameters.searchTeamsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchTeamsResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchTeams(requestParameters: SearchTeamsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchTeamsResponse> {
        const response = await this.searchTeamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchUsersRaw(requestParameters: SearchUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSearchResponse>> {
        if (requestParameters.userSearchRequest === null || requestParameters.userSearchRequest === undefined) {
            throw new runtime.RequiredError('userSearchRequest','Required parameter requestParameters.userSearchRequest was null or undefined when calling searchUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSearchRequestToJSON(requestParameters.userSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchUsers(requestParameters: SearchUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSearchResponse> {
        const response = await this.searchUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async switchCompanyRaw(requestParameters: SwitchCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.switchCompanyRequest === null || requestParameters.switchCompanyRequest === undefined) {
            throw new runtime.RequiredError('switchCompanyRequest','Required parameter requestParameters.switchCompanyRequest was null or undefined when calling switchCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/switch_company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchCompanyRequestToJSON(requestParameters.switchCompanyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async switchCompany(requestParameters: SwitchCompanyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.switchCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async switchTeamRaw(requestParameters: SwitchTeamOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.switchTeamRequest === null || requestParameters.switchTeamRequest === undefined) {
            throw new runtime.RequiredError('switchTeamRequest','Required parameter requestParameters.switchTeamRequest was null or undefined when calling switchTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/switch_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchTeamRequestToJSON(requestParameters.switchTeamRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async switchTeam(requestParameters: SwitchTeamOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.switchTeamRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBuyerListOwnerRaw(requestParameters: UpdateBuyerListOwnerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBuyerListOwner.');
        }

        if (requestParameters.updateBuyerListOwnerRequest === null || requestParameters.updateBuyerListOwnerRequest === undefined) {
            throw new runtime.RequiredError('updateBuyerListOwnerRequest','Required parameter requestParameters.updateBuyerListOwnerRequest was null or undefined when calling updateBuyerListOwner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/owner`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuyerListOwnerRequestToJSON(requestParameters.updateBuyerListOwnerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBuyerListOwner(requestParameters: UpdateBuyerListOwnerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBuyerListOwnerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBuyerListPermissionsRaw(requestParameters: UpdateBuyerListPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBuyerListPermissions.');
        }

        if (requestParameters.updateBuyerListPermissionsRequest === null || requestParameters.updateBuyerListPermissionsRequest === undefined) {
            throw new runtime.RequiredError('updateBuyerListPermissionsRequest','Required parameter requestParameters.updateBuyerListPermissionsRequest was null or undefined when calling updateBuyerListPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuyerListPermissionsRequestToJSON(requestParameters.updateBuyerListPermissionsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBuyerListPermissions(requestParameters: UpdateBuyerListPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBuyerListPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBuyerListSharingRaw(requestParameters: UpdateBuyerListSharingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBuyerListSharing.');
        }

        if (requestParameters.updateBuyerListSharingRequest === null || requestParameters.updateBuyerListSharingRequest === undefined) {
            throw new runtime.RequiredError('updateBuyerListSharingRequest','Required parameter requestParameters.updateBuyerListSharingRequest was null or undefined when calling updateBuyerListSharing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/buyer_lists/{id}/sharing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuyerListSharingRequestToJSON(requestParameters.updateBuyerListSharingRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBuyerListSharing(requestParameters: UpdateBuyerListSharingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBuyerListSharingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateGuestAccessPassRaw(requestParameters: UpdateGuestAccessPassOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGuestAccessPass.');
        }

        if (requestParameters.updateGuestAccessPassRequest === null || requestParameters.updateGuestAccessPassRequest === undefined) {
            throw new runtime.RequiredError('updateGuestAccessPassRequest','Required parameter requestParameters.updateGuestAccessPassRequest was null or undefined when calling updateGuestAccessPass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/guest_access_passes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGuestAccessPassRequestToJSON(requestParameters.updateGuestAccessPassRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateGuestAccessPass(requestParameters: UpdateGuestAccessPassOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGuestAccessPassRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateRegionRaw(requestParameters: UpdateRegionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateRegionRequest === null || requestParameters.updateRegionRequest === undefined) {
            throw new runtime.RequiredError('updateRegionRequest','Required parameter requestParameters.updateRegionRequest was null or undefined when calling updateRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/regions/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRegionRequestToJSON(requestParameters.updateRegionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateRegion(requestParameters: UpdateRegionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRegionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateUserConfiguredRaw(requestParameters: UpdateUserConfiguredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserConfigured.');
        }

        if (requestParameters.updateUserConfiguredRequest === null || requestParameters.updateUserConfiguredRequest === undefined) {
            throw new runtime.RequiredError('updateUserConfiguredRequest','Required parameter requestParameters.updateUserConfiguredRequest was null or undefined when calling updateUserConfigured.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/{id}/set_configured`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserConfiguredRequestToJSON(requestParameters.updateUserConfiguredRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUserConfigured(requestParameters: UpdateUserConfiguredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserConfiguredRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateUserDetailsRaw(requestParameters: UpdateUserDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserDetails.');
        }

        if (requestParameters.updateUserDetailsRequest === null || requestParameters.updateUserDetailsRequest === undefined) {
            throw new runtime.RequiredError('updateUserDetailsRequest','Required parameter requestParameters.updateUserDetailsRequest was null or undefined when calling updateUserDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDetailsRequestToJSON(requestParameters.updateUserDetailsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUserDetails(requestParameters: UpdateUserDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserDetailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async upsertSavedViewsRaw(requestParameters: UpsertSavedViewsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.upsertSavedViewsRequest === null || requestParameters.upsertSavedViewsRequest === undefined) {
            throw new runtime.RequiredError('upsertSavedViewsRequest','Required parameter requestParameters.upsertSavedViewsRequest was null or undefined when calling upsertSavedViews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/api/pinned_saved_views/upsert_saved_views`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertSavedViewsRequestToJSON(requestParameters.upsertSavedViewsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async upsertSavedViews(requestParameters: UpsertSavedViewsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.upsertSavedViewsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userDetailsRaw(requestParameters: UserDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsResponse>> {
        if (requestParameters.userDetailsRequest === null || requestParameters.userDetailsRequest === undefined) {
            throw new runtime.RequiredError('userDetailsRequest','Required parameter requestParameters.userDetailsRequest was null or undefined when calling userDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/document/user_details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDetailsRequestToJSON(requestParameters.userDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async userDetails(requestParameters: UserDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsResponse> {
        const response = await this.userDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
