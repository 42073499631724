import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { createSavedViewUnpinnedEvent } from "components/my_feed/tracking";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type Params = {
  id: string;
  name: string;
};

export function useUnpinView(options?: UseMutationOptions<unknown, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(
    async ({ id }) => {
      return await api.deletePinnedSavedView({
        deletePinnedSavedViewRequest: { savedViewId: id },
      });
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["pinnedViews"]);
        logEvent(
          EventNames.savedViewUnpinned,
          // should the edit type be 'reordered' for the unpinned event?
          createSavedViewUnpinnedEvent("Pinned View reordered", vars.name ?? "", vars.name),
        );
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
