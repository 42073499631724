import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ProviderOption } from "lib/generated/integration-api/models/ProviderOption";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string];

export function useProviderList(
  options?: UseQueryOptions<ProviderOption[], unknown, ProviderOption[], QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["providerList"],
    async () => {
      const response = await api.listProviders();
      return response.providerList;
    },
    options,
  );
}
