import * as React from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd5";
import classnames from "classnames";
import { Link } from "wouter";

import { useUTMParams, UTMParams } from "lib/providers/UTMParams";
import { EventNames, useTracking } from "lib/tracking";
import TextButton from "./TextButton";

import css from "./Links.module.scss";

export type TargetType =
  | "same-tab" // the default mode
  | "new-tab" // open in new tab (both internal and external links)
  | "external"; // open in new tab and show "external" icon unless specified not to

export type TextLinkProps = React.PropsWithChildren<{
  to: string;
  targetType?: TargetType; // same-tab by default
  disabled?: boolean;
  eventName?: EventNames;
  eventAttributes?: Record<string, string | string[] | Record<string, string[]> | undefined | null>;
  className?: string;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  showIcon?: boolean; // only relevant for external links, defaults to true
  skipUtmParams?: boolean;
  download?: string;
  authorised?: boolean;
  onUnauthorisedClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  useWouter?: boolean;
}>;

function isInternalLink(link: string) {
  return (
    link.includes("stotles.com") ||
    link.includes(window.location.origin) ||
    link[0] === "/" ||
    link[0] === "#"
  );
}

export function getLinkDestination(loc: string, utmParams?: UTMParams): string {
  let link = "";
  if (loc.startsWith("http") || loc.startsWith("mailto") || loc.startsWith("blob")) {
    link = loc;
  } else if (loc[0] === "/" || loc[0] === "#") {
    link = window.location.origin + loc;
  } else {
    link = "http://" + loc;
  }

  if (utmParams && isInternalLink(link)) {
    const params = new URLSearchParams(utmParams as Record<string, string>).toString();
    let url;
    try {
      url = new URL(link);
    } catch (_) {
      // URL throws on invalid url - fallback just in case
      return link;
    }

    if (url.search.length) {
      url.search += `&${params}`;
    } else {
      url.search += `?${params}`;
    }
    link = url.toString();
  }

  return link;
}

export function TextLink(props: TextLinkProps): JSX.Element {
  const {
    to,
    eventName,
    eventAttributes,
    className,
    onClick,
    targetType,
    showIcon = true,
    skipUtmParams,
    authorised = true,
    onUnauthorisedClick,
    useWouter,
    ...others
  } = props;
  const { logEvent } = useTracking();
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (eventName) logEvent(eventName, eventAttributes);
      if (onClick) onClick(e);
      e.stopPropagation();
    },
    [eventName, logEvent, eventAttributes, onClick],
  );

  const handleAuxClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // Only handle the middle button
      if (e.button === 1) {
        handleClick(e);
      }
    },
    [handleClick],
  );
  const target = targetType === "new-tab" || targetType === "external" ? "_blank" : "_self";

  const utmParams = useUTMParams()?.utmParams;

  if (!authorised) {
    return (
      <TextButton
        className={classnames(className, css.textLink)}
        onClick={onUnauthorisedClick}
        {...others}
      >
        {props.children}
      </TextButton>
    );
  }

  if (useWouter) {
    return (
      <Link
        className={classnames(className, css.textLink)}
        href={to}
        onClick={handleClick}
        onAuxClick={handleAuxClick}
        {...others}
      >
        {props.children}
      </Link>
    );
  }

  return (
    <a
      className={classnames(className, css.textLink)}
      target={target}
      href={others.disabled ? "#" : getLinkDestination(to, skipUtmParams ? undefined : utmParams)}
      rel="noopener"
      onClick={handleClick}
      onAuxClick={handleAuxClick}
      {...others}
    >
      {props.children}
      {targetType === "external" && showIcon && <ExportOutlined className={css.externalLinkIcon} />}
    </a>
  );
}

type BackToLinkProps = React.PropsWithChildren<{
  to: string;
  eventName?: EventNames;
  eventAttributes?: Record<string, string | string[] | Record<string, string[]> | undefined | null>;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
}>;

export function BackToLink({
  to,
  eventName,
  eventAttributes,
  className,
  onClick,
  children,
}: BackToLinkProps): JSX.Element {
  const { logEvent } = useTracking();

  /**
   * Document referrer will go back to previous link, otherwise link destination goes to
   * the to link specificed in the prop, final option is to go to root url
   */
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
      if (eventName) logEvent(eventName, eventAttributes);
      if (onClick) onClick(e);

      e.stopPropagation();
    },
    [eventName, logEvent, eventAttributes, onClick],
  );

  const link = document.referrer.includes(to) ? document.referrer : getLinkDestination(to) ?? "/";

  return (
    <TextLink to={link} className={classnames(className, css.backToLink)} onClick={handleClick}>
      {children}
    </TextLink>
  );
}

export type ButtonLinkProps = ButtonProps & TextLinkProps;

export function ButtonLink({
  to,
  targetType,
  eventName,
  eventAttributes,
  onClick,
  showIcon = true,
  authorised = true,
  onUnauthorisedClick,
  children,
  className,
  ...others
}: ButtonLinkProps): JSX.Element {
  const { logEvent } = useTracking();
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>) => {
      if (eventName) logEvent(eventName, eventAttributes);
      if (onClick) onClick(e);
      e.stopPropagation();
    },
    [eventName, logEvent, eventAttributes, onClick],
  );

  const handleAuxClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>) => {
      // Only handle the middle button
      if (e.button === 1) {
        handleClick(e);
      }
    },
    [handleClick],
  );

  if (!authorised) {
    return (
      <Button
        className={classnames(className, css.textLink)}
        onClick={onUnauthorisedClick}
        {...others}
      >
        {children}
      </Button>
    );
  }

  const target = targetType === "new-tab" || targetType === "external" ? "_blank" : "_self";

  return (
    <Button
      href={others.disabled ? "#" : getLinkDestination(to)}
      target={target}
      rel="noopener"
      onClick={handleClick}
      onAuxClick={handleAuxClick}
      {...others}
    >
      {children}
      {targetType === "external" && showIcon && <ExportOutlined className={css.externalLinkIcon} />}
    </Button>
  );
}
