import * as React from "react";
import { Card } from "antd5";
import classnames from "classnames";

import css from "./SettingsContentContainer.module.scss";

type Props = {
  title?: string | JSX.Element;
  children: React.ReactNode;
  className?: string;
};

function SettingsContentContainer({ title, children, className }: Props): JSX.Element {
  return (
    <Card
      className={classnames(css.settingsContainer, className)}
      title={title && <h1>{title}</h1>}
    >
      <div className={css.content}>{children}</div>
    </Card>
  );
}

export default SettingsContentContainer;
