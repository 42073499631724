import { CandidateOperation } from "components/organisation_clean/types";
import { DecisionToEdit, DecisionType, SubmittedDecision } from "lib/generated/app-api";

/**
 * Creates a list of OrgToAlias decisions to be sent for later review
 * @param orgId
 * @param aliases
 * @returns
 */
export function createAliasDecisions(orgId: string, aliases: string[]): SubmittedDecision[] {
  return aliases.map((alias) => {
    return {
      candidateOrganisationId: orgId,
      decisionData: {
        createAlias: {
          qualification: CandidateOperation.CREATE_ALIAS,
          alias,
        },
      },
    };
  });
}

/**
 * Creates a list of OrgToAlias decisions to be edited
 * @param orgId
 * @param aliases
 * @returns
 */
export function createAliasDecisionsToEdit(orgId: string, aliases: string[]): DecisionToEdit[] {
  return aliases.map((alias) => {
    return {
      candidateOrganisationId: orgId,
      decisionLogId: crypto.randomUUID(),
      decisionData: {
        createAlias: {
          qualification: CandidateOperation.CREATE_ALIAS,
          alias,
        },
      } as DecisionType,
    };
  });
}
