import React from "react";
import { Control } from "react-hook-form";
import { Divider } from "antd5";

import { SelectBuyerList } from "../../form_components/BuyerListSelect";
import { BuyerSelect } from "../../form_components/BuyerSelect";
import { BuyerTypeSelect } from "../../form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "../../form_components/CountryRegionSelect";
import { FilterOptions } from "../FilterForm";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface BuyersFilterContentProps {
  control: Control<NoticeFilters, unknown>;
  data: NoticeFilters;
  options?: FilterOptions;
}

function BuyersFilterContent({ control, data, options }: BuyersFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Buyer filters">
      <BuyerSelect
        name="buyers"
        label=""
        control={control}
        mode="multiple"
        placeholder="Search buyers"
        disabled={data.buyerListIds.length > 0 || options?.disabledFields?.includes("buyers")}
        allowClear
      />
      <Divider style={{ margin: "8px 0" }} />
      <SelectBuyerList
        name="buyerListIds"
        label="Buyer lists"
        control={control}
        placeholder="Select lists"
        mode="multiple"
        disabled={data.buyers.length > 0 || options?.disabledFields?.includes("buyerListIds")}
        allowClear
      />
      <BuyerTypeSelect
        name="buyerCategories"
        control={control}
        multiple
        label="Buyer type"
        placeholder="Select"
        disabled={options?.disabledFields?.includes("buyerCategories")}
        allowClear
      />
      <CountryRegionSelect
        name="buyerCountryRegions"
        control={control}
        label="Buyer location"
        placeholder="Select..."
        disabled={options?.disabledFields?.includes("buyerCountryRegions")}
        allowClear
      />
    </div>
  );
}

export default BuyersFilterContent;
