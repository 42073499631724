/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * spend-data management API
 * Provides API to ingest and fetch spend data.
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'

import type { ErrorType as SearchFilesErrorType } from './spendDataAPIClient';
import type { ErrorType as GetDownloadUrlErrorType } from './spendDataAPIClient';
import type { ErrorType as SearchSpendDataErrorType } from './spendDataAPIClient';
import type { ErrorType as IngestFilesErrorType } from './spendDataAPIClient';
import type { ErrorType as IngestAllFilesErrorType } from './spendDataAPIClient';
import type { ErrorType as ExportDataErrorType } from './spendDataAPIClient';
import type { ErrorType as SupplierSummaryErrorType } from './spendDataAPIClient';
import type { ErrorType as SupplierGuidSummaryErrorType } from './spendDataAPIClient';
import type { ErrorType as SupplierSummaryChartErrorType } from './spendDataAPIClient';
import type { ErrorType as SupplierSummaryGroupedErrorType } from './spendDataAPIClient';
import type { ErrorType as SupplierSummaryChartGroupedErrorType } from './spendDataAPIClient';
import type { ErrorType as BuyerSummaryErrorType } from './spendDataAPIClient';
import type { ErrorType as BuyerSummaryChartErrorType } from './spendDataAPIClient';
import type { ErrorType as TransactionSummaryChartErrorType } from './spendDataAPIClient';
import type { ErrorType as SpendExistsErrorType } from './spendDataAPIClient';
import searchFilesMutator from './spendDataAPIClient';
import getDownloadUrlMutator from './spendDataAPIClient';
import searchSpendDataMutator from './spendDataAPIClient';
import ingestFilesMutator from './spendDataAPIClient';
import ingestAllFilesMutator from './spendDataAPIClient';
import exportDataMutator from './spendDataAPIClient';
import supplierSummaryMutator from './spendDataAPIClient';
import supplierGuidSummaryMutator from './spendDataAPIClient';
import supplierSummaryChartMutator from './spendDataAPIClient';
import supplierSummaryGroupedMutator from './spendDataAPIClient';
import supplierSummaryChartGroupedMutator from './spendDataAPIClient';
import buyerSummaryMutator from './spendDataAPIClient';
import buyerSummaryChartMutator from './spendDataAPIClient';
import transactionSummaryChartMutator from './spendDataAPIClient';
import spendExistsMutator from './spendDataAPIClient';
import type {
  BuyerSummaryChartDataResponse,
  BuyerSummaryDataResponse,
  ExportDataRequest,
  GetDownloadUrlRequest,
  GetDownloadUrlResponse,
  IngestFileRequest,
  SearchFilesRequest,
  SearchFilesResponse,
  SearchSpendDataRequest,
  SearchSpendDataResponse,
  SpendExistsRequest,
  SpendExistsResponse,
  SummaryChartDataRequest,
  SummaryDataRequest,
  SupplierSummaryChartDataResponse,
  SupplierSummaryDataResponse,
  SupplierSummaryGuidDataResponse,
  TransactionSummaryChartDataResponse
} from './spendDataManagementAPI.schemas'



export const searchFiles = (
    searchFilesRequest: SearchFilesRequest,
 ) => {
      
      
      return searchFilesMutator<SearchFilesResponse>(
      {url: `/services/spend-data-management-api/data/search-files`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: searchFilesRequest
    },
      );
    }
  

export const getSearchFilesQueryKey = (searchFilesRequest: SearchFilesRequest,) => {
    return [`/services/spend-data-management-api/data/search-files`, searchFilesRequest] as const;
    }

    
export const getSearchFilesQueryOptions = <TData = Awaited<ReturnType<typeof searchFiles>>, TError = SearchFilesErrorType<unknown>>(searchFilesRequest: SearchFilesRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchFiles>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchFilesQueryKey(searchFilesRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchFiles>>> = () => searchFiles(searchFilesRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchFilesQueryResult = NonNullable<Awaited<ReturnType<typeof searchFiles>>>
export type SearchFilesQueryError = SearchFilesErrorType<unknown>

export const useSearchFiles = <TData = Awaited<ReturnType<typeof searchFiles>>, TError = SearchFilesErrorType<unknown>>(
 searchFilesRequest: SearchFilesRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchFiles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchFilesQueryOptions(searchFilesRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDownloadUrl = (
    getDownloadUrlRequest: GetDownloadUrlRequest,
 ) => {
      
      
      return getDownloadUrlMutator<GetDownloadUrlResponse>(
      {url: `/services/spend-data-management-api/data/get-download-url`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getDownloadUrlRequest
    },
      );
    }
  


export const getGetDownloadUrlMutationOptions = <TError = GetDownloadUrlErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDownloadUrl>>, TError,{data: GetDownloadUrlRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getDownloadUrl>>, TError,{data: GetDownloadUrlRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDownloadUrl>>, {data: GetDownloadUrlRequest}> = (props) => {
          const {data} = props ?? {};

          return  getDownloadUrl(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type GetDownloadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof getDownloadUrl>>>
    export type GetDownloadUrlMutationBody = GetDownloadUrlRequest
    export type GetDownloadUrlMutationError = GetDownloadUrlErrorType<unknown>

    export const useGetDownloadUrl = <TError = GetDownloadUrlErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDownloadUrl>>, TError,{data: GetDownloadUrlRequest}, TContext>, }
) => {

      const mutationOptions = getGetDownloadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const searchSpendData = (
    searchSpendDataRequest: SearchSpendDataRequest,
 ) => {
      
      
      return searchSpendDataMutator<SearchSpendDataResponse>(
      {url: `/services/spend-data-management-api/data/search-spend-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: searchSpendDataRequest
    },
      );
    }
  

export const getSearchSpendDataQueryKey = (searchSpendDataRequest: SearchSpendDataRequest,) => {
    return [`/services/spend-data-management-api/data/search-spend-data`, searchSpendDataRequest] as const;
    }

    
export const getSearchSpendDataQueryOptions = <TData = Awaited<ReturnType<typeof searchSpendData>>, TError = SearchSpendDataErrorType<unknown>>(searchSpendDataRequest: SearchSpendDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchSpendData>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchSpendDataQueryKey(searchSpendDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchSpendData>>> = () => searchSpendData(searchSpendDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchSpendData>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchSpendDataQueryResult = NonNullable<Awaited<ReturnType<typeof searchSpendData>>>
export type SearchSpendDataQueryError = SearchSpendDataErrorType<unknown>

export const useSearchSpendData = <TData = Awaited<ReturnType<typeof searchSpendData>>, TError = SearchSpendDataErrorType<unknown>>(
 searchSpendDataRequest: SearchSpendDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchSpendData>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchSpendDataQueryOptions(searchSpendDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ingestFiles = (
    ingestFileRequest: IngestFileRequest,
 ) => {
      
      
      return ingestFilesMutator<IngestFileRequest>(
      {url: `/services/spend-data-management-api/data/ingest-files`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: ingestFileRequest
    },
      );
    }
  


export const getIngestFilesMutationOptions = <TError = IngestFilesErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingestFiles>>, TError,{data: IngestFileRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ingestFiles>>, TError,{data: IngestFileRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ingestFiles>>, {data: IngestFileRequest}> = (props) => {
          const {data} = props ?? {};

          return  ingestFiles(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type IngestFilesMutationResult = NonNullable<Awaited<ReturnType<typeof ingestFiles>>>
    export type IngestFilesMutationBody = IngestFileRequest
    export type IngestFilesMutationError = IngestFilesErrorType<unknown>

    export const useIngestFiles = <TError = IngestFilesErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingestFiles>>, TError,{data: IngestFileRequest}, TContext>, }
) => {

      const mutationOptions = getIngestFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const ingestAllFiles = (
    
 ) => {
      
      
      return ingestAllFilesMutator<void>(
      {url: `/services/spend-data-management-api/data/ingest-all-files`, method: 'POST'
    },
      );
    }
  


export const getIngestAllFilesMutationOptions = <TError = IngestAllFilesErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingestAllFiles>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ingestAllFiles>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ingestAllFiles>>, void> = () => {
          

          return  ingestAllFiles()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type IngestAllFilesMutationResult = NonNullable<Awaited<ReturnType<typeof ingestAllFiles>>>
    
    export type IngestAllFilesMutationError = IngestAllFilesErrorType<unknown>

    export const useIngestAllFiles = <TError = IngestAllFilesErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingestAllFiles>>, TError,void, TContext>, }
) => {

      const mutationOptions = getIngestAllFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exportData = (
    exportDataRequest: ExportDataRequest,
 ) => {
      
      
      return exportDataMutator<Blob>(
      {url: `/services/spend-data-management-api/data/export-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: exportDataRequest,
        responseType: 'blob'
    },
      );
    }
  


export const getExportDataMutationOptions = <TError = ExportDataErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportData>>, TError,{data: ExportDataRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof exportData>>, TError,{data: ExportDataRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportData>>, {data: ExportDataRequest}> = (props) => {
          const {data} = props ?? {};

          return  exportData(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ExportDataMutationResult = NonNullable<Awaited<ReturnType<typeof exportData>>>
    export type ExportDataMutationBody = ExportDataRequest
    export type ExportDataMutationError = ExportDataErrorType<unknown>

    export const useExportData = <TError = ExportDataErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportData>>, TError,{data: ExportDataRequest}, TContext>, }
) => {

      const mutationOptions = getExportDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const supplierSummary = (
    summaryDataRequest: SummaryDataRequest,
 ) => {
      
      
      return supplierSummaryMutator<SupplierSummaryDataResponse>(
      {url: `/services/spend-data-management-api/data/supplier-summary-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryDataRequest
    },
      );
    }
  

export const getSupplierSummaryQueryKey = (summaryDataRequest: SummaryDataRequest,) => {
    return [`/services/spend-data-management-api/data/supplier-summary-data`, summaryDataRequest] as const;
    }

    
export const getSupplierSummaryQueryOptions = <TData = Awaited<ReturnType<typeof supplierSummary>>, TError = SupplierSummaryErrorType<unknown>>(summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummary>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSupplierSummaryQueryKey(summaryDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof supplierSummary>>> = () => supplierSummary(summaryDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof supplierSummary>>, TError, TData> & { queryKey: QueryKey }
}

export type SupplierSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof supplierSummary>>>
export type SupplierSummaryQueryError = SupplierSummaryErrorType<unknown>

export const useSupplierSummary = <TData = Awaited<ReturnType<typeof supplierSummary>>, TError = SupplierSummaryErrorType<unknown>>(
 summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSupplierSummaryQueryOptions(summaryDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const supplierGuidSummary = (
    summaryDataRequest: SummaryDataRequest,
 ) => {
      
      
      return supplierGuidSummaryMutator<SupplierSummaryGuidDataResponse>(
      {url: `/services/spend-data-management-api/data/supplier-summary-guid-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryDataRequest
    },
      );
    }
  

export const getSupplierGuidSummaryQueryKey = (summaryDataRequest: SummaryDataRequest,) => {
    return [`/services/spend-data-management-api/data/supplier-summary-guid-data`, summaryDataRequest] as const;
    }

    
export const getSupplierGuidSummaryQueryOptions = <TData = Awaited<ReturnType<typeof supplierGuidSummary>>, TError = SupplierGuidSummaryErrorType<unknown>>(summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierGuidSummary>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSupplierGuidSummaryQueryKey(summaryDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof supplierGuidSummary>>> = () => supplierGuidSummary(summaryDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof supplierGuidSummary>>, TError, TData> & { queryKey: QueryKey }
}

export type SupplierGuidSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof supplierGuidSummary>>>
export type SupplierGuidSummaryQueryError = SupplierGuidSummaryErrorType<unknown>

export const useSupplierGuidSummary = <TData = Awaited<ReturnType<typeof supplierGuidSummary>>, TError = SupplierGuidSummaryErrorType<unknown>>(
 summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierGuidSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSupplierGuidSummaryQueryOptions(summaryDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const supplierSummaryChart = (
    summaryChartDataRequest: SummaryChartDataRequest,
 ) => {
      
      
      return supplierSummaryChartMutator<SupplierSummaryChartDataResponse>(
      {url: `/services/spend-data-management-api/data/supplier-summary-chart-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryChartDataRequest
    },
      );
    }
  

export const getSupplierSummaryChartQueryKey = (summaryChartDataRequest: SummaryChartDataRequest,) => {
    return [`/services/spend-data-management-api/data/supplier-summary-chart-data`, summaryChartDataRequest] as const;
    }

    
export const getSupplierSummaryChartQueryOptions = <TData = Awaited<ReturnType<typeof supplierSummaryChart>>, TError = SupplierSummaryChartErrorType<unknown>>(summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChart>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSupplierSummaryChartQueryKey(summaryChartDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof supplierSummaryChart>>> = () => supplierSummaryChart(summaryChartDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChart>>, TError, TData> & { queryKey: QueryKey }
}

export type SupplierSummaryChartQueryResult = NonNullable<Awaited<ReturnType<typeof supplierSummaryChart>>>
export type SupplierSummaryChartQueryError = SupplierSummaryChartErrorType<unknown>

export const useSupplierSummaryChart = <TData = Awaited<ReturnType<typeof supplierSummaryChart>>, TError = SupplierSummaryChartErrorType<unknown>>(
 summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSupplierSummaryChartQueryOptions(summaryChartDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const supplierSummaryGrouped = (
    summaryDataRequest: SummaryDataRequest,
 ) => {
      
      
      return supplierSummaryGroupedMutator<SupplierSummaryDataResponse>(
      {url: `/services/spend-data-management-api/data/supplier-summary-data-grouped`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryDataRequest
    },
      );
    }
  

export const getSupplierSummaryGroupedQueryKey = (summaryDataRequest: SummaryDataRequest,) => {
    return [`/services/spend-data-management-api/data/supplier-summary-data-grouped`, summaryDataRequest] as const;
    }

    
export const getSupplierSummaryGroupedQueryOptions = <TData = Awaited<ReturnType<typeof supplierSummaryGrouped>>, TError = SupplierSummaryGroupedErrorType<unknown>>(summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryGrouped>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSupplierSummaryGroupedQueryKey(summaryDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof supplierSummaryGrouped>>> = () => supplierSummaryGrouped(summaryDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryGrouped>>, TError, TData> & { queryKey: QueryKey }
}

export type SupplierSummaryGroupedQueryResult = NonNullable<Awaited<ReturnType<typeof supplierSummaryGrouped>>>
export type SupplierSummaryGroupedQueryError = SupplierSummaryGroupedErrorType<unknown>

export const useSupplierSummaryGrouped = <TData = Awaited<ReturnType<typeof supplierSummaryGrouped>>, TError = SupplierSummaryGroupedErrorType<unknown>>(
 summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryGrouped>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSupplierSummaryGroupedQueryOptions(summaryDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const supplierSummaryChartGrouped = (
    summaryChartDataRequest: SummaryChartDataRequest,
 ) => {
      
      
      return supplierSummaryChartGroupedMutator<SupplierSummaryChartDataResponse>(
      {url: `/services/spend-data-management-api/data/supplier-summary-chart-data-grouped`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryChartDataRequest
    },
      );
    }
  

export const getSupplierSummaryChartGroupedQueryKey = (summaryChartDataRequest: SummaryChartDataRequest,) => {
    return [`/services/spend-data-management-api/data/supplier-summary-chart-data-grouped`, summaryChartDataRequest] as const;
    }

    
export const getSupplierSummaryChartGroupedQueryOptions = <TData = Awaited<ReturnType<typeof supplierSummaryChartGrouped>>, TError = SupplierSummaryChartGroupedErrorType<unknown>>(summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChartGrouped>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSupplierSummaryChartGroupedQueryKey(summaryChartDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof supplierSummaryChartGrouped>>> = () => supplierSummaryChartGrouped(summaryChartDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChartGrouped>>, TError, TData> & { queryKey: QueryKey }
}

export type SupplierSummaryChartGroupedQueryResult = NonNullable<Awaited<ReturnType<typeof supplierSummaryChartGrouped>>>
export type SupplierSummaryChartGroupedQueryError = SupplierSummaryChartGroupedErrorType<unknown>

export const useSupplierSummaryChartGrouped = <TData = Awaited<ReturnType<typeof supplierSummaryChartGrouped>>, TError = SupplierSummaryChartGroupedErrorType<unknown>>(
 summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof supplierSummaryChartGrouped>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSupplierSummaryChartGroupedQueryOptions(summaryChartDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const buyerSummary = (
    summaryDataRequest: SummaryDataRequest,
 ) => {
      
      
      return buyerSummaryMutator<BuyerSummaryDataResponse>(
      {url: `/services/spend-data-management-api/data/buyer-summary-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryDataRequest
    },
      );
    }
  

export const getBuyerSummaryQueryKey = (summaryDataRequest: SummaryDataRequest,) => {
    return [`/services/spend-data-management-api/data/buyer-summary-data`, summaryDataRequest] as const;
    }

    
export const getBuyerSummaryQueryOptions = <TData = Awaited<ReturnType<typeof buyerSummary>>, TError = BuyerSummaryErrorType<unknown>>(summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof buyerSummary>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBuyerSummaryQueryKey(summaryDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buyerSummary>>> = () => buyerSummary(summaryDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof buyerSummary>>, TError, TData> & { queryKey: QueryKey }
}

export type BuyerSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof buyerSummary>>>
export type BuyerSummaryQueryError = BuyerSummaryErrorType<unknown>

export const useBuyerSummary = <TData = Awaited<ReturnType<typeof buyerSummary>>, TError = BuyerSummaryErrorType<unknown>>(
 summaryDataRequest: SummaryDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof buyerSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBuyerSummaryQueryOptions(summaryDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const buyerSummaryChart = (
    summaryChartDataRequest: SummaryChartDataRequest,
 ) => {
      
      
      return buyerSummaryChartMutator<BuyerSummaryChartDataResponse>(
      {url: `/services/spend-data-management-api/data/buyer-summary-chart-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryChartDataRequest
    },
      );
    }
  

export const getBuyerSummaryChartQueryKey = (summaryChartDataRequest: SummaryChartDataRequest,) => {
    return [`/services/spend-data-management-api/data/buyer-summary-chart-data`, summaryChartDataRequest] as const;
    }

    
export const getBuyerSummaryChartQueryOptions = <TData = Awaited<ReturnType<typeof buyerSummaryChart>>, TError = BuyerSummaryChartErrorType<unknown>>(summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof buyerSummaryChart>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBuyerSummaryChartQueryKey(summaryChartDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buyerSummaryChart>>> = () => buyerSummaryChart(summaryChartDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof buyerSummaryChart>>, TError, TData> & { queryKey: QueryKey }
}

export type BuyerSummaryChartQueryResult = NonNullable<Awaited<ReturnType<typeof buyerSummaryChart>>>
export type BuyerSummaryChartQueryError = BuyerSummaryChartErrorType<unknown>

export const useBuyerSummaryChart = <TData = Awaited<ReturnType<typeof buyerSummaryChart>>, TError = BuyerSummaryChartErrorType<unknown>>(
 summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof buyerSummaryChart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBuyerSummaryChartQueryOptions(summaryChartDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const transactionSummaryChart = (
    summaryChartDataRequest: SummaryChartDataRequest,
 ) => {
      
      
      return transactionSummaryChartMutator<TransactionSummaryChartDataResponse>(
      {url: `/services/spend-data-management-api/data/transaction-summary-chart-data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: summaryChartDataRequest
    },
      );
    }
  

export const getTransactionSummaryChartQueryKey = (summaryChartDataRequest: SummaryChartDataRequest,) => {
    return [`/services/spend-data-management-api/data/transaction-summary-chart-data`, summaryChartDataRequest] as const;
    }

    
export const getTransactionSummaryChartQueryOptions = <TData = Awaited<ReturnType<typeof transactionSummaryChart>>, TError = TransactionSummaryChartErrorType<unknown>>(summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionSummaryChart>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionSummaryChartQueryKey(summaryChartDataRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionSummaryChart>>> = () => transactionSummaryChart(summaryChartDataRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionSummaryChart>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionSummaryChartQueryResult = NonNullable<Awaited<ReturnType<typeof transactionSummaryChart>>>
export type TransactionSummaryChartQueryError = TransactionSummaryChartErrorType<unknown>

export const useTransactionSummaryChart = <TData = Awaited<ReturnType<typeof transactionSummaryChart>>, TError = TransactionSummaryChartErrorType<unknown>>(
 summaryChartDataRequest: SummaryChartDataRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionSummaryChart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionSummaryChartQueryOptions(summaryChartDataRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const spendExists = (
    spendExistsRequest: SpendExistsRequest,
 ) => {
      
      
      return spendExistsMutator<SpendExistsResponse>(
      {url: `/services/spend-data-management-api/data/spend-exists`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: spendExistsRequest
    },
      );
    }
  

export const getSpendExistsQueryKey = (spendExistsRequest: SpendExistsRequest,) => {
    return [`/services/spend-data-management-api/data/spend-exists`, spendExistsRequest] as const;
    }

    
export const getSpendExistsQueryOptions = <TData = Awaited<ReturnType<typeof spendExists>>, TError = SpendExistsErrorType<unknown>>(spendExistsRequest: SpendExistsRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof spendExists>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSpendExistsQueryKey(spendExistsRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof spendExists>>> = () => spendExists(spendExistsRequest, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof spendExists>>, TError, TData> & { queryKey: QueryKey }
}

export type SpendExistsQueryResult = NonNullable<Awaited<ReturnType<typeof spendExists>>>
export type SpendExistsQueryError = SpendExistsErrorType<unknown>

export const useSpendExists = <TData = Awaited<ReturnType<typeof spendExists>>, TError = SpendExistsErrorType<unknown>>(
 spendExistsRequest: SpendExistsRequest, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof spendExists>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSpendExistsQueryOptions(spendExistsRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




