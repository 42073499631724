import React from "react";
import { Control, Controller } from "react-hook-form";

import { TenderStage } from "../../../lib/types/models";
import RecordStageButtons from "../../form_components/RecordStageButtons";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface ProcurementStageFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function ProcurementStageFilterContent({ control }: ProcurementStageFilterContentProps) {
  return (
    <div aria-label="Procurement stage filters">
      <Controller
        name="stage"
        control={control}
        render={({ field }) => (
          <div className={css.filterSection}>
            <RecordStageButtons
              {...field}
              checkedStages={field.value}
              availableStages={[
                TenderStage.STALE_PRE_TENDER,
                TenderStage.PRE_TENDER,
                TenderStage.OPEN,
                TenderStage.CLOSED,
                TenderStage.AWARDED,
              ]}
            />
          </div>
        )}
      />
    </div>
  );
}

export default ProcurementStageFilterContent;
