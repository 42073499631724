/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner } from './FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner';
import {
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSON,
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSONTyped,
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerToJSON,
} from './FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner';

/**
 * Structure for defining queries constructed from multiple attribute criteria objects.
 *        The resulting query will have the following format: 
 *        (criteria) AND (any[0] OR any[1] ... any[N]) AND NOT (exclude criteria)
 * @export
 * @interface FrameworkSearchQueryScoreFilterScoresInnerCriteria
 */
export interface FrameworkSearchQueryScoreFilterScoresInnerCriteria {
    /**
     * ALL of the criteria within this clause must be true for a result to be returned for this query.
     *         Each expression in this array is chained together by ANDs. It would look something like this:
     *           (condition1 AND condition2 AND (condition3a or condition 3b) )
     * @type {Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteria
     */
    all?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>;
    /**
     * ANY one of the criteria need to be true for a result to be returned for this query. 
     *           Each expression in this array is chained together by ORs.
     *           It's joined to the ALL clause of this object via an AND
     * @type {Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteria
     */
    any?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>;
    /**
     * NONE one of the criteria need to be true for a result to be returned for this query. 
     *           It's joined to the ALL clause of this object via an AND
     * @type {Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteria
     */
    none?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInner>;
}

/**
 * Check if a given object implements the FrameworkSearchQueryScoreFilterScoresInnerCriteria interface.
 */
export function instanceOfFrameworkSearchQueryScoreFilterScoresInnerCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSON(json: any): FrameworkSearchQueryScoreFilterScoresInnerCriteria {
    return FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSONTyped(json, false);
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSearchQueryScoreFilterScoresInnerCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': !exists(json, 'all') ? undefined : ((json['all'] as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSON)),
        'any': !exists(json, 'any') ? undefined : ((json['any'] as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSON)),
        'none': !exists(json, 'none') ? undefined : ((json['none'] as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSON)),
    };
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaToJSON(value?: FrameworkSearchQueryScoreFilterScoresInnerCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': value.all === undefined ? undefined : ((value.all as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerToJSON)),
        'any': value.any === undefined ? undefined : ((value.any as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerToJSON)),
        'none': value.none === undefined ? undefined : ((value.none as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerToJSON)),
    };
}

