import * as React from "react";
import { Radio } from "antd5";
import classnames from "classnames";

import { countryCodes } from "../../lib/data/optionItems";
import { useUniqueIdentifier } from "../../lib/hooks/useUniqueIdentifier";
import { isDefined } from "../../lib/utils";
import CountryFilter from "./CountryFilter";

import css from "./AllCountriesField.module.scss";

type Props = {
  values?: string[];
  onChange: (values: string[]) => void;
  label?: string;
  className?: string;
};

type CountryPreset = keyof typeof COUNTRY_PRESETS;

function isPresetMatch(preset: CountryPreset, countryCodes: string[]) {
  const presetCCs = COUNTRY_PRESETS[preset];
  if (presetCCs.size !== countryCodes.length) {
    return false;
  }
  for (const cc of countryCodes) {
    if (!presetCCs.has(cc)) {
      return false;
    }
  }
  return true;
}

function getPresetMatch(countryCodes: string[]): CountryPreset | null {
  if (isPresetMatch("UK_IE", countryCodes)) return "UK_IE";
  if (isPresetMatch("EU", countryCodes)) return "EU";
  if (isPresetMatch("EUROPE", countryCodes)) return "EUROPE";
  return null;
}

/**
 * This component lets the user select from some predefined sets of countries
 * or select a custom list. On load we match their existing selection
 * to the presets, but we don't automatically switch the preset
 * (if the user selects UK & IE in manual form we don't switch to the preset mode
 *  because they might want to select more countries).
 *
 * That's why we only call `getPresetMatch` when initialising the state.
 */
const AllCountriesField = ({ values = [], onChange, label, className }: Props): JSX.Element => {
  const inputId = useUniqueIdentifier("all-countries-input");

  /**
   * Our standard countries multiselect usees country codes,
   * but feed settings code expects country names,
   * so we need to do this bit of translation.
   */
  const selectedCountryCodes = React.useMemo(() => {
    return values
      .map((countryName) => countryCodes.find((country) => country.name === countryName)?.code)
      .filter(isDefined);
  }, [values]);

  // One of the predefined sets or "CUSTOM" - we keep it separate
  // so that lookups in COUNTRY_PRESETS is easy
  const [selectedPreset, setSelectedPreset] = React.useState<CountryPreset | "CUSTOM">(
    () => getPresetMatch(selectedCountryCodes) || "CUSTOM",
  );

  const handleChange = React.useCallback(
    (newCountryCodes: string[]) => {
      onChange(
        newCountryCodes
          .map((cc) => countryCodes.find((country) => country.code === cc)?.name)
          .filter(isDefined),
      );
    },
    [onChange],
  );

  const handleSetPreset = React.useCallback(
    (preset: CountryPreset | "CUSTOM") => {
      setSelectedPreset(preset);
      if (preset !== "CUSTOM") {
        handleChange(Array.from(COUNTRY_PRESETS[preset].values()));
      }
    },
    [handleChange],
  );

  return (
    <div className={classnames(css.countriesField, className)}>
      {label && (
        <label className={css.label} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Radio.Group
          value={selectedPreset}
          onChange={(e) => handleSetPreset(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="UK_IE">United Kingdom & Ireland</Radio.Button>
          <Radio.Button value="EU">European Union countries</Radio.Button>
          <Radio.Button value="EUROPE">All European countries</Radio.Button>
          <Radio.Button value="CUSTOM">Custom selection</Radio.Button>
        </Radio.Group>
      </div>

      {selectedPreset === "CUSTOM" && (
        <CountryFilter
          id={inputId}
          showLabel={false}
          onChange={handleChange}
          value={selectedCountryCodes}
        />
      )}
    </div>
  );
};

export default AllCountriesField;

const COUNTRY_PRESETS = {
  UK_IE: new Set(["UK", "IE"]),
  EUROPE: new Set([
    "AL", // Albania
    "AD", // Andorra
    "AM", // Armenia
    "AT", // Austria
    "BY", // Belarus
    "BE", // Belgium
    "BA", // Bosnia and Herzegovina
    "BG", // Bulgaria
    "HR", // Croatia
    "CY", // Cyprus
    "CZ", // Czech Republic
    "DK", // Denmark
    "EE", // Estonia
    "FO", // Faroe Islands
    "FI", // Finland
    "FR", // France
    "GE", // Georgia
    "DE", // Germany
    "GI", // Gibraltar
    "GR", // Greece
    "HU", // Hungary
    "IS", // Iceland
    "IE", // Ireland
    "IM", // Isle of Man
    "IT", // Italy
    "XK", // Kosovo
    "LV", // Latvia
    "LI", // Liechtenstein
    "LT", // Lithuania
    "LU", // Luxembourg
    "MK", // Macedonia
    "MT", // Malta
    "MD", // Moldova
    "MC", // Monaco
    "ME", // Montenegro
    "NL", // Netherlands
    "NO", // Norway
    "PL", // Poland
    "PT", // Portugal
    "RO", // Romania
    "RU", // Russian Federation
    "SM", // San Marino
    "RS", // Serbia
    "SK", // Slovakia
    "SI", // Slovenia
    "ES", // Spain
    "SE", // Sweden
    "CH", // Switzerland
    "TR", // Turkey
    "UA", // Ukraine
    "UK", // United Kingdom
    "VA", // Vatican City State
  ]),
  EU: new Set([
    "AT", // Austria
    "BE", // Belgium
    "BG", // Bulgaria
    "HR", // Croatia
    "CY", // Cyprus
    "CZ", // Czechia
    "DK", // Denmark
    "EE", // Estonia
    "FI", // Finland
    "FR", // France
    "DE", // Germany
    "EL", // Greece
    "HU", // Hungary
    "IE", // Ireland
    "IT", // Italy
    "LV", // Latvia
    "LT", // Lithuania
    "LU", // Luxembourg
    "MT", // Malta
    "NL", // Netherlands
    "PL", // Poland
    "PT", // Portugal
    "RO", // Romania
    "SK", // Slovakia
    "SI", // Slovenia
    "ES", // Spain
    "SE", // Sweden
  ]),
};
