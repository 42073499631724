import React, { useState } from "react";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button, Result } from "antd5";
import { Redirect } from "wouter";

import { useVariableValue } from "../../lib/featureFlags";
import { useNoticeDocuments } from "../../lib/hooks/api/bid/useNoticeDocuments";
import { useNoticeQualification } from "../../lib/hooks/api/bid/useNoticeQualification";
import { useLocalStorage } from "../../lib/hooks/useLocalStorage";
import { LLMProvider } from "../../lib/types/graphQLEnums";
import { DetailsContent, DetailsHeader, DetailsPage } from "../app_layout/DetailsLayout";
import LoadingState from "../buyer_details/question_panel/LoadingState";
import { NoticeDocumentUpload } from "./notice_document_upload/NoticeDocumentUpload";
import DocumentDrawer from "./DocumentViewer";
import { NoticeQualifcationForm, QualificationFormValues } from "./NoticeQualificationForm";
import { NoticeQualificationResult, QualificationResult } from "./NoticeQualificationResult";

export function NoticeQualificationPage({ noticeId }: { noticeId: string }) {
  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue("notice-auto-qualification", false);
  if (devCycleReady && !isEnabled) {
    return <Redirect to="/" />;
  }
  return (
    <DetailsPage>
      <DetailsHeader>
        <h1>Notice Qualification</h1>
      </DetailsHeader>
      <DetailsContent>
        <NoticeQualification noticeId={noticeId} />
      </DetailsContent>
    </DetailsPage>
  );
}

const DEFAULT_PROMPT = `system
You are a helpful analyst. You will be given a QUESTION and some DOCUMENTS.

Keep in mind the following:
(1) The DOCUMENTS are tender specifications that were published by a public sector organization who is soliciting bids from prospective suppliers.
(2) The QUESTION is being asked by a prospective supplier who is evaluating whether or not they should submit a bid for this tender.
(3) You will be provided a set of functions that you can use to search and retrieve the DOCUMENTS to help answer the query.

Your role is to provide an ANSWER to the QUESTION by analyzing the DOCUMENTS. You will also provide SOURCES which will identify which parts of the DOCUMENTS were used to generate the ANSWER.

Ensure that your response observes the following:

(1) The response should be a JSON object that matches the format shown in the example below.
(2) The ANSWER should be concise and factual.
(3) Provide a SOURCE for each relevant section of the DOCUMENTS used to generate the ANSWER. There can be one or more SOURCE for each ANSWER.
(3) The ANNOTATIONS should return the exact strings of text found in the DOCUMENTS that were used to generate the ANSWER, nothing more. There can be one or more ANNOTATION within each DOCUMENT.
(4) If I input the ANNOTATIONS into a simple tool that searches the text of the DOCUMENTS, they should perfectly match strings of text that exist within the DOCUMENTS.
(6) If there is no useful information in the DOCUMENTS as it relates to the QUESTION, return "Not found" in the ANSWER and leave the ANNOTATIONS empty.

Below is an example of the desired output which is properly formatted, and in response to the QUESTION "What is the clarification deadline for this tender?":

{
  "answer": "Friday, August 23 2024, 10:00 (UK time)",
  "sources": [
    {
      "documentId": "4e3adb45-4993-4e85-9a07-1916cf9dd8ae",
      "document": "tender_doc.docx",
      "annotations": ["10 a.m. UK time on Friday, 23 August 2024"]
    }
  ]
}
Return JSON only in your response, nothing else.
`;

function NoticeQualification({ noticeId }: { noticeId: string }) {
  const {
    data: documents,
    isLoading: isLoadingDocs,
    isError: isErrorDocs,
    refetch: refetchDocs,
  } = useNoticeDocuments(noticeId);
  const [showUploadForm, setShowUploadForm] = useState(true);
  const [formValues, setFormValues] = useLocalStorage<QualificationFormValues>(
    "noticeQualificationForm",
    {
      llmPrompt: DEFAULT_PROMPT,
      noticeId,
      qualificationQuestions: [],
      llmProvider: LLMProvider.Mistral,
    },
  );
  const {
    mutate: qualifyNotice,
    isLoading: isLoadingQualify,
    isError: isErrorQualify,
    reset,
    data,
  } = useNoticeQualification();
  const [selectedDocument, setSelectedDocument] = React.useState<QualificationResult | undefined>(
    undefined,
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isLoading = isLoadingDocs || isLoadingQualify;
  const isError = isErrorDocs || isErrorQualify;

  if (isLoading) {
    // this is temporarily borrowing the loading state from the question panel
    return <LoadingState />;
  }

  if (isError) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle="There was an error qualifying the notice"
        extra={<Button onClick={() => (showUploadForm ? refetchDocs() : reset())}>Restart</Button>}
      />
    );
  }

  if (showUploadForm) {
    return (
      <NoticeDocumentUpload
        noticeId={noticeId}
        onComplete={() => setShowUploadForm(false)}
        documents={documents}
      />
    );
  }

  if (data) {
    return (
      <>
        <NoticeQualificationResult
          result={data.result}
          onRetry={() => qualifyNotice({ input: { ...formValues, noticeId } })}
          onEdit={() => reset()}
          onDocumentSelect={(result: QualificationResult) => {
            setSelectedDocument(result);
            setDrawerOpen(true);
          }}
        />
        {selectedDocument?.sources?.[0] ? (
          <DocumentDrawer
            open={drawerOpen}
            source={selectedDocument.sources[0]}
            onClose={() => setDrawerOpen(false)}
          />
        ) : null}
      </>
    );
  }

  return (
    <NoticeQualifcationForm
      onSubmit={(values) => {
        setFormValues(values);
        qualifyNotice({ input: values });
      }}
      noticeId={noticeId}
      defaultValues={{
        ...formValues,
        noticeId,
      }}
    />
  );
}
