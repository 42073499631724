import * as React from "react";
import { useForm } from "react-hook-form";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Tag } from "antd5";
import isEqual from "lodash.isequal";

import { Input } from "components/form_components/Inputs";
import { FrameworkCallOffTableCell } from "components/framework/FrameworkCallOffTableCell";
import PaywallPopover from "components/paywall/PaywallPopover";
import { getSortOrder } from "components/reports/builder_components/SupplierTable";
import { getBuyerDestination } from "lib/appDestinations";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import { ColumnHeader } from "lib/core_components/ColumnHeader";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import { createUseDebounce } from "lib/debounce";
import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";
import {
  SearchFrameworkOrganisationsRequestOrganisationRolesEnum,
  SearchFrameworkOrganisationsResponseOrganisationsInner,
} from "lib/generated/data-svc";
import {
  SearchFrameworkOrganisationsResult,
  useSearchFrameworkBuyers,
} from "lib/hooks/api/frameworks/useSearchFrameworkOrganisations";
import { PaginationState, SortState } from "lib/search/types";
import { EventData, EventDataTypes } from "lib/tracking";
import { OrganisationRole } from "lib/types/graphQLEnums";
import { ColumnSetting } from "lib/types/models";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import BulkSaveBuyer from "./BulkSaveBuyer";
import { BuyerFilterForm, BuyerFilterSections } from "./BuyerFilterForm";
import { NameColumn } from "./BuyersTable";
import { DEFAULT_PAGINATION } from "./BuyerTable";
import { ExportBuyersButton } from "./ExportBuyersButton";
import { QuickFilterBar } from "./QuickFilterBar";
import { TableContext } from "./types";
import {
  DEFAULT_BUYER_FILTERS,
  useFilterableBuyerTableState,
} from "./useFilterableBuyerTableState";

import css from "./FrameworkBuyersTable.module.scss";

const ROLE_LABEL_BY_ROLE: Record<string, string> = {
  [SearchFrameworkOrganisationsRequestOrganisationRolesEnum.ContractingAuthority]:
    "Contracting authority",
  [SearchFrameworkOrganisationsRequestOrganisationRolesEnum.TransactingBuyer]: "Transacting buyer",
  [SearchFrameworkOrganisationsRequestOrganisationRolesEnum.Provider]: "Provider",
};

function getBuyerColumns({
  sortOrder,
  selectedColumns,
  trackingData,
  frameworkId,
}: {
  sortOrder: SortState;
  selectedColumns: FrameworkBuyerColumn[];
  trackingData?: EventData;
  frameworkId: string;
}) {
  const name: ColumnType<SearchFrameworkOrganisationsResponseOrganisationsInner> = {
    key: "buyer_name",
    title: <ColumnHeader header=" Buyer name" subHeader="&amp; buyer type" />,
    render: (_, buyer) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "BUYERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={trackingData}
      >
        <NameColumn buyerGuid={buyer.id} buyerName={buyer.name} categories={buyer.categories} />
      </PaywallPopover>
    ),
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "buyer_name"),
    sizeConfig: {
      small: 440,
      medium: 440,
      large: 480,
      xlarge: 520,
    },
  };
  const role: ColumnType<SearchFrameworkOrganisationsResponseOrganisationsInner> = {
    key: "role",
    title: "Role",
    render: (_, b) => {
      return b.roles.map((r) => <Tag>{ROLE_LABEL_BY_ROLE[r] || r}</Tag>);
    },
  };

  const frameworkLastActivity: ColumnType<SearchFrameworkOrganisationsResponseOrganisationsInner> =
    {
      key: "frameworkLastActivity",
      title: "Last activity",
      render: (_, b) => {
        const lastActivity = b.callOffs?.lastActivity;
        return lastActivity ? toHumanRelativeTimeFromNow(lastActivity) : "--";
      },
      sorter: true,
      sortOrder: getSortOrder(sortOrder, "frameworkLastActivity"),
      sortDirections: ["descend", "ascend", "descend"],
    };

  const frameworkCallOffs: ColumnType<SearchFrameworkOrganisationsResponseOrganisationsInner> = {
    key: "frameworkCallOffs",
    title: "Call offs",
    render: (_, b) => {
      return (
        <FrameworkCallOffTableCell
          averageAmount={b.callOffs?.averageAmount.amount}
          callOffCount={b.callOffs?.count}
          currency={b.callOffs?.averageAmount.currency}
          linkTo={`/frameworks/${frameworkId}/call-offs?callOffFilters[buyers][]=${b.id}`}
        />
      );
    },
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "frameworkCallOffs"),
    sortDirections: ["descend", "ascend", "descend"],
  };

  const columns = { name, role, frameworkLastActivity, frameworkCallOffs };
  return selectedColumns.map((column) => columns[column]);
}

/**
 * This table is a bit different from the regular buyers table - the data it displays is quite
 * framework-specific (showing how buyers intersect with a specific framework) - and probably
 * doesn't make sense in more general buyer contexts
 */
function FrameworkBuyersTable({
  sortOrder,
  onSortChange,
  selectedRows,
  onSelectedRowsChange,
  selectedColumns,
  frameworkId,
  tableContext,
  pagination,
  onPaginationChange,
}: {
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  sortOrder: SortState;
  onSortChange: (sort: SortState) => void;
  selectedRows?: string[];
  onSelectedRowsChange: (
    selectedRowKeys: string[],
    selectedRows: SearchFrameworkOrganisationsResponseOrganisationsInner[],
  ) => void;
  selectedColumns: FrameworkBuyerColumn[];
  frameworkId: string;
  pagination: PaginationState;
  onPaginationChange: (pagination: PaginationState) => void;
  // TODO: Fetch this from react context using a hook
  tableContext: TableContext<SearchFrameworkOrganisationsResult>;
}) {
  const columns = getBuyerColumns({
    sortOrder,
    selectedColumns,
    frameworkId,
  });
  const { queryResult } = tableContext;

  const restrictionCheck = (_r: SearchFrameworkOrganisationsResponseOrganisationsInner) =>
    !!window.guestUser;

  const onRow =
    createHandleTableRowClickthrough<SearchFrameworkOrganisationsResponseOrganisationsInner>(
      (r) => getBuyerDestination(r.id),
      undefined,
      undefined,
      restrictionCheck,
    );

  return (
    <Table<SearchFrameworkOrganisationsResponseOrganisationsInner>
      dataSource={queryResult.data?.organisations || []}
      columns={columns}
      loading={queryResult.isLoading}
      isError={queryResult.isError}
      pagination={{
        ...pagination,
        total: tableContext.totalCount ? tableContext.totalCount + 1 : 0,
        onChange: (page, pageSize) => onPaginationChange({ current: page, pageSize }),
        showSizeChanger: true,
      }}
      onChange={(_, __, sorter) => {
        if (Array.isArray(sorter)) {
          sorter = sorter[0];
        }
        // If the sort order is not defined, we don't want to trigger a sort without an order
        if (!sorter.order) {
          return;
        }
        const newSortState: SortState = {
          order: sorter.order === "descend" ? "DESC" : "ASC",
          field: sorter.columnKey as string,
        };
        if (!isEqual(newSortState, sortOrder)) {
          onSortChange(newSortState);
        }
      }}
      rowSelection={
        selectedRows && {
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRows,
        }
      }
      rowKey="buyerGuid"
      onRow={onRow}
    />
  );
}

type FrameworkBuyerColumn = "name" | "frameworkCallOffs" | "role" | "frameworkLastActivity";

const COLUMNS: ColumnSetting<FrameworkBuyerColumn>[] = [
  {
    title: "Buyer name",
    field: "name",
  },
  {
    title: "Call offs",
    field: "frameworkCallOffs",
  },
  {
    title: "Role",
    field: "role",
  },
  {
    title: "Last activity",
    field: "frameworkLastActivity",
  },
];

const DEFAULT_SORT: SortState = {
  field: "buyer_name",
  order: "ASC",
};

export function FilterableFrameworkBuyersTable({
  hiddenSections,
  frameworkId,
}: {
  defaultSortOrder?: SortState;
  hiddenSections?: BuyerFilterSections[];
  defaultColumns?: ColumnSetting<FrameworkBuyerColumn>[];
  allColumns?: ColumnSetting<FrameworkBuyerColumn>[];
  frameworkId: string;
}) {
  const tableState = useFilterableBuyerTableState(DEFAULT_BUYER_FILTERS, DEFAULT_SORT);
  const { buyerTableFilters, setBuyerTableFilters, buyerSort, setBuyerSort } = tableState;
  const [pagination, setPagination] = React.useState<PaginationState>(DEFAULT_PAGINATION);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false);

  const [selectedColumns, setSelectedColumns] = React.useState(COLUMNS);
  const [selectedBuyers, setSelectedBuyers] = React.useState<string[]>([]);

  const updateFilters = React.useCallback(
    (filters: AllBuyersStatsRequestAllBuyersStatsFilters) => {
      setBuyerTableFilters(filters);
      // Always reset pagination when filters change
      setPagination(DEFAULT_PAGINATION);
    },
    [setBuyerTableFilters],
  );

  const useDebounce500 = createUseDebounce(500);
  const debouncedSetBuyerTableFilters = useDebounce500((filters) => {
    updateFilters(filters);
  });

  const values = buyerTableFilters;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      buyerName: buyerTableFilters.buyerName,
    },
    mode: "onChange",
    values,
  });

  React.useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        debouncedSetBuyerTableFilters({ ...buyerTableFilters, buyerName: data.buyerName });
      })(),
    );
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, debouncedSetBuyerTableFilters, buyerTableFilters]);

  const updateSortState = React.useCallback(
    (newSortState: SortState) => {
      setBuyerSort(newSortState);
      // Always reset pagination when sort changes
      setPagination(DEFAULT_PAGINATION);
    },
    [setBuyerSort],
  );

  const queryResult = useSearchFrameworkBuyers(
    frameworkId,
    [
      OrganisationRole.TransactingBuyer,
      OrganisationRole.Provider,
      OrganisationRole.ContractingAuthority,
    ],
    buyerTableFilters,
    buyerSort,
    pagination,
  );

  const tableContext: TableContext<SearchFrameworkOrganisationsResult> = {
    queryResult,
    totalCount: queryResult.data?.totalCount,
  };

  return (
    <>
      <div className={css.filterBar}>
        <div className={css.filterItems}>
          <form
            className={css.searchInput}
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              name="buyerName"
              label=""
              control={control}
              allowClear
            />
          </form>
          <Button onClick={() => setIsFilterDrawerOpen(true)}>
            <FilterOutlined className={css.filterIcon} /> Filters
          </Button>
        </div>
        <div className={css.columnsAndExport}>
          <div className={css.clearSelected}>
            {selectedBuyers.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedBuyers.length}
                  onClear={() => setSelectedBuyers([])}
                />
                :
              </>
            )}
          </div>
          {selectedBuyers && selectedBuyers.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={COLUMNS}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<FrameworkBuyerColumn>[])])
              }
              dataType={EventDataTypes.buyer}
            />
          )}
          <div className={css.saveBuyers}>
            {selectedBuyers.length > 0 && !window.guestUser && (
              <BulkSaveBuyer buyerGuids={selectedBuyers} />
            )}
          </div>
          <ExportBuyersButton
            filters={buyerTableFilters}
            selectedBuyers={selectedBuyers}
            tableContext={tableContext}
          />
        </div>
      </div>
      <div className={css.viewFilters}>
        <QuickFilterBar
          filters={buyerTableFilters}
          sortState={buyerSort}
          tableContext={tableContext}
          onClearFilter={(key) => updateFilters({ ...buyerTableFilters, [key]: undefined })}
          onFilterClick={() => setIsFilterDrawerOpen(true)}
          hiddenSections={hiddenSections || []}
          clearAllFilters={() => updateFilters(DEFAULT_BUYER_FILTERS)}
        />
      </div>
      <FrameworkBuyersTable
        tableContext={tableContext}
        filters={buyerTableFilters}
        sortOrder={buyerSort}
        onSortChange={updateSortState}
        selectedRows={selectedBuyers}
        onSelectedRowsChange={(rows) => setSelectedBuyers(rows)}
        selectedColumns={selectedColumns.map((column) => column.field)}
        frameworkId={frameworkId}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      {isFilterDrawerOpen && (
        <Drawer
          title="Filters"
          onClose={() => setIsFilterDrawerOpen(false)}
          open={isFilterDrawerOpen}
          size="large"
        >
          <BuyerFilterForm
            filters={buyerTableFilters}
            onFiltersChange={debouncedSetBuyerTableFilters}
            options={{
              disabledSections: hiddenSections,
            }}
            onClose={() => setIsFilterDrawerOpen(false)}
          />
        </Drawer>
      )}
    </>
  );
}
