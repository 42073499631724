import * as React from "react";
import { CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Input } from "antd5";
import classnames from "classnames";

import css from "./TreeSelect.module.scss";

/**
 * Simple checkable tree select component
 */
type SelectGroupProps = {
  option: Option;
  checkedStatusByValue: CheckedStatusByValue;
  onCheck: (checkedStatusByValue: GroupSelectValue) => void;
  searchTerm?: string;
  trackingEvent?: (field: string, selected: boolean) => void;
};

export type GroupSelectValue =
  | { includeAll: true; names?: undefined }
  | { names: string[]; includeAll?: false | undefined };

type Option = {
  title: string;
  value: string;
  renderOption?: (v: string) => React.ReactNode;
  disabled?: boolean;
  children?: Omit<Option, "children">[];
};

export type Options = Record<string, Option>;

export type SelectedOptions = Record<keyof Options, GroupSelectValue>;

type ItemByFilterField<ItemType> = Record<keyof SelectedOptions, ItemType>;

type CheckedStatusByValue = Record<string, boolean>;

const compare = function (a: string, b: string) {
  return a.trim().localeCompare(b.trim(), undefined, { sensitivity: "base" });
};
const isIncluded = function (a: string, b: string) {
  return a.trim().toLocaleLowerCase().includes(b.trim().toLocaleLowerCase());
};

function SelectGroup({
  option,
  checkedStatusByValue,
  onCheck,
  searchTerm,
  trackingEvent,
}: SelectGroupProps) {
  const [expanded, setExpanded] = React.useState(false);
  const { title, children, renderOption, disabled } = option;

  const sortedOptions = React.useMemo(() => {
    if (!children) return [];
    return Object.values(children).sort((a, b) => compare(a.title, b.title));
  }, [children]);

  const filteredOptions: Omit<Option, "children">[] = React.useMemo(() => {
    if (!searchTerm) return sortedOptions;
    else {
      return sortedOptions.filter((o) => isIncluded(o.title, searchTerm));
    }
  }, [searchTerm, sortedOptions]);

  // Memo to keep track of if all items have been selected
  const allSelected = React.useMemo(() => {
    const selectedCount = Object.values(checkedStatusByValue).filter((checked) => checked).length;
    return selectedCount === (children?.length || 0);
  }, [children, checkedStatusByValue]);

  // Handles the click on the top level item in the tree which has special select/deselect all treatment
  const handleCategoryClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      onCheck(allSelected ? { names: [] } : { includeAll: true });
      trackingEvent && trackingEvent(`${title}: All options`, !allSelected);
    },
    [allSelected, trackingEvent, onCheck, title],
  );

  const handleCheck = React.useCallback(
    (checked: boolean, field: string) => {
      let value: GroupSelectValue;
      const selectedKeys = Object.keys(checkedStatusByValue).filter((k) => checkedStatusByValue[k]);
      if (checked) {
        if (selectedKeys.length + 1 === (children?.length || 0)) {
          value = { includeAll: true };
        } else {
          value = { names: Array.from(new Set([...selectedKeys, field])) };
        }
      } else {
        value = { names: selectedKeys.filter((v) => v !== field) };
      }
      onCheck(value);
    },
    [checkedStatusByValue, onCheck, children],
  );

  // This is to make sure users can see all options if they've searched for something
  React.useEffect(() => {
    if (searchTerm && filteredOptions.length > 0) {
      setExpanded(true);
    }
  }, [filteredOptions, searchTerm]);

  const hideNode = React.useMemo(() => {
    if (searchTerm) {
      // If there's a searchTerm and none of the children or the parent match it, then we hide it
      return filteredOptions.length === 0 && !isIncluded(option.title, searchTerm);
    } else return false;
  }, [searchTerm, filteredOptions, option]);

  return hideNode ? (
    <></>
  ) : (
    <li role="treeitem" key={title}>
      <span className={css.switcher}>
        <CaretRightOutlined
          className={css.collapseIcon}
          rotate={expanded ? 90 : 0}
          onClick={() => setExpanded((v) => !v)}
        />
      </span>
      <Checkbox
        className={css.checkbox}
        checked={allSelected}
        onClick={handleCategoryClick}
        disabled={disabled}
      >
        {renderOption ? renderOption(title) : title}
      </Checkbox>
      <ul
        role="group"
        className={classnames(css.collapsibleTreeList, { [css.expanded]: expanded })}
      >
        {filteredOptions.map((o) => (
          <li
            key={o.value}
            className={css.listItem}
            onClick={(e) => e.stopPropagation()}
            role="treeitem"
          >
            <span style={{ display: "flex", alignItems: "center", gridColumnGap: 4 }}>
              <Checkbox
                checked={checkedStatusByValue[o.value]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={css.checkbox}
                onChange={(e) => {
                  e.stopPropagation();
                  handleCheck(e.target.checked, o.value);
                  trackingEvent && trackingEvent(`${title}: ${o.title}`, e.target.checked);
                }}
                disabled={o.disabled}
              >
                {renderOption ? renderOption(o.title) : o.title}
              </Checkbox>
            </span>
          </li>
        ))}
      </ul>
    </li>
  );
}

type Props = {
  options: Options;
  selectedOptions: SelectedOptions;
  onSelectedChange: (opts: SelectedOptions) => void;
  searchable?: boolean;
  trackingEvent?: (field: string, selected: boolean) => void;
};

function TreeSelect({
  onSelectedChange,
  selectedOptions,
  options,
  searchable,
  trackingEvent,
}: Props) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const dropdownValuesByField = React.useMemo(() => {
    const filterValuesPerField = Object.keys(options).reduce((obj, key: keyof Options) => {
      obj[key] = {};
      return obj;
    }, {} as ItemByFilterField<CheckedStatusByValue>);

    for (const [field, opt] of Object.entries(options) as [keyof Options, Option][]) {
      if (opt.children) {
        for (const subOpt of opt.children) {
          const checked =
            selectedOptions[field]?.includeAll ||
            !!selectedOptions[field]?.names?.includes(subOpt.value);
          filterValuesPerField[field][subOpt.value] = checked;
        }
      }
    }

    return filterValuesPerField;
  }, [options, selectedOptions]);

  const createOnCheckHandler = React.useCallback(
    (field: keyof Options) => {
      return (v: GroupSelectValue) => {
        onSelectedChange({
          ...selectedOptions,
          [field]: v,
        });
      };
    },
    [onSelectedChange, selectedOptions],
  );

  const handleChildlessClick = React.useCallback(
    (key: string, checked: boolean) => {
      // When a top level item is selected, we only have the option of `includeAll` being true or false with no names
      const newValue: GroupSelectValue = checked
        ? { includeAll: true }
        : { includeAll: false, names: [] };
      createOnCheckHandler(key)(newValue);
      trackingEvent && trackingEvent(key, checked);
    },
    [createOnCheckHandler, trackingEvent],
  );

  return (
    <div className={css.container}>
      {searchable && (
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search..."
          className={css.input}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          allowClear
        />
      )}
      <ul className={css.treeList} role="tree">
        {Object.entries(options).map(([key, option]) =>
          option.children && option.children.length > 0 ? (
            <SelectGroup
              key={option.value}
              option={option}
              checkedStatusByValue={dropdownValuesByField[key]}
              onCheck={createOnCheckHandler(key)}
              searchTerm={searchTerm}
              trackingEvent={trackingEvent}
            />
          ) : !option.children ? (
            <Checkbox
              key={option.value}
              className={classnames(css.childlessCheckbox, css.checkbox)}
              onChange={(e) => handleChildlessClick(key, e.target.checked)}
              checked={selectedOptions[key]?.includeAll}
              disabled={option.disabled}
            >
              {option.renderOption ? option.renderOption(option.title) : option.title}
            </Checkbox>
          ) : (
            <></>
          ),
        )}
      </ul>
    </div>
  );
}

export default TreeSelect;
