import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  BuyersRelationshipStatsRequest,
  BuyersRelationshipStatsResponse,
} from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string | undefined, string, BuyersRelationshipStatsRequest];

function getQueryKey(buyerRelationshipStatsRequest: BuyersRelationshipStatsRequest): QueryKey {
  if (buyerRelationshipStatsRequest.buyerGuids) {
    return ["buyers", "signalStats", buyerRelationshipStatsRequest];
  }

  return [buyerRelationshipStatsRequest.buyerListId, "signalStats", buyerRelationshipStatsRequest];
}

export function useBuyerSignalStats(
  buyerRelationshipStatsRequest: BuyersRelationshipStatsRequest,
  options?: UseQueryOptions<
    BuyersRelationshipStatsResponse,
    unknown,
    BuyersRelationshipStatsResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    getQueryKey(buyerRelationshipStatsRequest),
    ({ queryKey: [, , request] }) =>
      api.fetchBuyerSignalStats({
        buyersRelationshipStatsRequest: request,
      }),
    options,
  );
}
