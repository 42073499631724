import React from "react";

import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";
import OscarSearchPageContent from "../_shared/OscarSearchPageContent";

import css from "./OscarSearchPage.module.scss";

const STOTLES_API = new StotlesAPI();

function OscarSearchPage(): JSX.Element {
  return (
    <ApiContextProvider api={STOTLES_API}>
      <div className={css.pageLayout}>
        <OscarSearchPageContent />
      </div>
    </ApiContextProvider>
  );
}

export default OscarSearchPage;
