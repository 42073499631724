import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";

type Props = { id: string; rank: number; name: string };

export function useUpdatePinnedView(
  options?: UseMutationOptions<unknown, unknown, Props, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(
    async ({ id, rank }: Props) => {
      return api.updatePinnedSavedView({
        updatePinnedSavedViewRequest: { savedViewId: id, rank },
      });
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["pinnedViews"]);
        logEvent(EventNames.pinnedSavedViewRankChange, {
          "Saved view id": vars.id,
          "Data type": EventDataTypes.savedView,
          "Edit type": "Pinned View reordered",
          "Pinned View name": vars.name,
        });
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
