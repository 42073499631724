import { ThemeConfig } from "antd5";

export const getTabsConfig = ({
  horizontalItemPadding,
}: {
  horizontalItemPadding: number;
}): ThemeConfig => {
  return {
    components: {
      Tabs: {
        horizontalMargin: "0",
        horizontalItemGutter: 0,
        horizontalItemPaddingLG: `12px ${horizontalItemPadding}px`,
      },
    },
  };
};
