import React from "react";

import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { useSearchFrameworks } from "lib/hooks/api/frameworks/useSearchFrameworks";
import { EventDataTypes } from "lib/tracking";
import { capitalize, convertCamelCaseToSpaceSeparatedWords } from "lib/utils";
import {
  cpvCodeFilterToLabel,
  keywordsFilterToLabel,
  supplierGuidsFilterToLabel,
} from "lib/utils/filterToLabelUtils";
import { FilterFormProps, FilterSections } from "./FilterForm";
import { convertFrameworkFiltersToRequest, DEFAULT_PAGINATION, FrameworkFilters } from "./utils";

const sectionsToFilters: Record<FilterSections, (keyof FrameworkFilters)[]> = {
  suppliers: ["supplierIds"],
  buyers: ["buyerIds", "buyerListIds", "buyerCategories", "buyerCountryRegions"],
};

function getAppliedFilters(
  filters: FrameworkFilters,
  hiddenFilterSections?: FilterFormProps["hiddenSections"],
) {
  const appliedFilters = Object.entries(filters).filter(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "object") {
      if ("hideNulls" in value && "filter" in value) {
        return Object.values(value.filter || {}).filter((v) => v).length > 0 || value.hideNulls;
      }
      if ("hideNulls" in value || "to" in value || "from" in value) {
        return (
          Object.values(value).filter((v) => {
            if (typeof v === "number") {
              return true;
            }
            return v;
          }).length > 0
        );
      }
      if (key === "sort") {
        return false;
      }
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string") {
      return value.length > 0;
    }
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  });

  const hiddenFilters =
    hiddenFilterSections?.flatMap((section) => sectionsToFilters[section]) || [];

  return appliedFilters
    .map(([key]) => key)
    .filter((key) => !hiddenFilters.includes(key as keyof FrameworkFilters));
}

export const filterKeyToLabel: (
  filters?: FrameworkFilters,
) => Record<keyof FrameworkFilters, string> = (filters) => ({
  buyerListIds: "Buyer lists",
  signals: "Signals",
  signalScore: "Signal score",
  buyerIds: "Buyers",
  buyerCategories: "Buyer type",
  buyerCountryRegions: "Buyer location",
  procedureType: "Procedure type",
  buyerParticipantType: "Buyer participant type",
  languages: "Languages",
  awardType: "Award type",
  closeDate: "Close date",
  startDate: "Start date",
  endDate: "End date",
  stage: "Framework stage",
  searchText: "",
  supplierIds: supplierGuidsFilterToLabel(filters?.supplierIds),
  cpvDimensions: "Sector",
  cpvCodes: cpvCodeFilterToLabel(filters?.cpvCodes),
  keywords: keywordsFilterToLabel(filters?.keywords),
  excludeKeywords: filters?.excludeKeywords
    ? `Exclude keywords (${filters?.excludeKeywords?.length})`
    : `Excluded keywords`,
  value: "Value",
  sort: "",
});

type Props = {
  filters: FrameworkFilters;
  onClearFilter: (value: keyof FrameworkFilters) => void;
  onFilterClick: (value: keyof FrameworkFilters) => void;
  hiddenFilterSections?: FilterFormProps["hiddenSections"];
  clearAllFilters: () => void;
  clearAllFiltersHide?: () => boolean;
};
export function QuickFilterBar({
  filters,
  onClearFilter,
  onFilterClick,
  hiddenFilterSections,
  clearAllFilters,
  clearAllFiltersHide,
}: Props) {
  const appliedFilters = getAppliedFilters(filters, hiddenFilterSections);

  const { isLoading, data } = useSearchFrameworks(
    convertFrameworkFiltersToRequest(filters, DEFAULT_PAGINATION),
    false,
  );

  const sortField: string = filters.sort?.field
    ? capitalize(convertCamelCaseToSpaceSeparatedWords(filters.sort.field))
    : "";

  return (
    <FilterBar<FrameworkFilters>
      appliedFilters={appliedFilters}
      filterKeyToLabel={filterKeyToLabel(filters)}
      sortField={sortField}
      onClear={onClearFilter}
      isLoading={isLoading}
      totalResults={data?.totalResults || 0}
      hiddenFields={["searchText"]}
      dataType={EventDataTypes.frameworks}
      onFilterClick={onFilterClick}
      clearAllFilters={clearAllFilters}
      clearAllFiltersHide={clearAllFiltersHide}
    />
  );
}
