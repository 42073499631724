import React from "react";
import { FieldValues } from "react-hook-form";

import { useNoticeLists } from "lib/hooks/api/notices/lists/useNoticeLists";
import { Select, SelectProps } from "./Inputs";

type Props<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function NoticeListSelect<T extends FieldValues>(props: Props<T>) {
  const { isLoading, data } = useNoticeLists();

  const options = [
    {
      label: "My lists",
      options:
        data?.userLists.map((list) => ({ label: list.name, value: list.id.toString() })) || [],
    },
    {
      label: "Other lists",
      options:
        data?.sharedLists.map((list) => ({ label: list.name, value: list.id.toString() })) || [],
    },
  ];

  return (
    <Select {...props} loading={isLoading} options={options} allowClear optionFilterProp="label" />
  );
}
