/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner';

/**
 * Base structure for defining queries constructed from multiple attribute criteria objects.       This is on the basic level
 * @export
 * @interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner
 */
export interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner {
    /**
     * All of the conditions in this array must be met. Equivalent to an AND filter
     * @type {Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner
     */
    all?: Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>;
    /**
     * At least one or more of the conditions in this array must be met, together with all         conditions in all (if there are any). Equivalent to an OR filter
     * @type {Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner
     */
    any?: Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>;
    /**
     * None of the conditions in this array must be met. Equivalent to a NOT filter
     * @type {Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner
     */
    none?: Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner>;
}

/**
 * Check if a given object implements the NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner interface.
 */
export function instanceOfNoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSON(json: any): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner {
    return NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSONTyped(json, false);
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': !exists(json, 'all') ? undefined : ((json['all'] as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON)),
        'any': !exists(json, 'any') ? undefined : ((json['any'] as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON)),
        'none': !exists(json, 'none') ? undefined : ((json['none'] as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON)),
    };
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerToJSON(value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': value.all === undefined ? undefined : ((value.all as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON)),
        'any': value.any === undefined ? undefined : ((value.any as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON)),
        'none': value.none === undefined ? undefined : ((value.none as Array<any>).map(NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON)),
    };
}

