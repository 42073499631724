import React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Popover, Skeleton, Tooltip } from "antd5";

import { TextLink } from "components/actions/Links";
import { getDocumentTypeLabel } from "components/documents/utils";
import OrganisationsContainer from "components/record_details/OrganisationsPopover";
import { NewRelevanceScore } from "lib/core_components/NewRelevanceScore";
import { PropertyInline } from "lib/core_components/Property";
import { DOCUMENTS_SIGNAL_SCORE } from "lib/featureFlags";
import { LINK_REGEX } from "lib/utils";
import { SignalEntityType } from "lib/utils/signalUtils";
import { useDocumentTracking } from "./tracking";
import { tooltips } from "./utils";

import css from "./DocumentDetails.module.scss";

type DocumentDetailsProps = {
  id: string;
  buyers: { id: string; name: string }[];
  signalScore?: number;
  type: string;
  publishDate: string;
  sourceUrls: { id: string; sourceUrl?: string | null }[];
  alternativeSource: string;
  isLoading: boolean;
};

export default function DocumentDetails({
  id,
  buyers,
  signalScore,
  type,
  publishDate,
  sourceUrls,
  alternativeSource,
  isLoading,
}: DocumentDetailsProps) {
  const isSignalScoreEnabled = useVariableValue(DOCUMENTS_SIGNAL_SCORE, false);
  const sourceUrlLinks = sourceUrls
    .filter((source) => !!source.sourceUrl)
    .map((source) => String(source.sourceUrl));

  return (
    <div className={css.infoContainer}>
      <h3>Details</h3>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {isSignalScoreEnabled && (
            <PropertyInline
              title={isSignalScoreEnabled ? "Signal score" : "Signals"}
              value={
                <NewRelevanceScore
                  relevanceScore={signalScore}
                  popoverPlacement="bottomLeft"
                  id={id}
                  entityType={SignalEntityType.Document}
                />
              }
            />
          )}
          <PropertyInline
            title="Buyer(s)"
            value={<OrganisationsContainer orgs={buyers} orgType="buyers" />}
          />
          <PropertyInline title="Type" value={getDocumentTypeLabel(type)} />
          <PropertyInline
            title="Published"
            value={publishDate ? publishDate.split("T")[0] : <EmptyState />}
          />
          <PropertyInline
            title="Source(s)"
            value={
              <SourcesComponent sourceUrls={sourceUrlLinks} alternativeSource={alternativeSource} />
            }
            valueClassName={sourceUrls.length ? css.ellipsis : undefined}
          />
        </>
      )}
    </div>
  );
}

function SourcesComponent({
  sourceUrls,
  alternativeSource,
}: {
  sourceUrls: string[];
  alternativeSource: string;
}) {
  if (sourceUrls.length) {
    return <SourceUrls sources={sourceUrls} />;
  }
  if (alternativeSource) {
    return LINK_REGEX.test(alternativeSource) ? (
      <SourceUrls sources={[alternativeSource]} />
    ) : (
      <AlternativeSource source={alternativeSource} />
    );
  }
  return <EmptyState />;
}

function SourceUrls({ sources }: { sources: string[] }) {
  const tracking = useDocumentTracking();

  const sourcesTotal = sources.length;

  const popoverContent = (
    <div className={css.popoverContent}>
      {sources.map((source) => (
        <TextLink
          to={source}
          targetType="new-tab"
          className={css.ellipsis}
          onClick={tracking.documentSourceViewed}
        >
          {source}
        </TextLink>
      ))}
    </div>
  );

  if (sources.length === 1) {
    return (
      <TextLink to={sources[0]} targetType="new-tab" onClick={tracking.documentSourceViewed}>
        {sources[0]}
      </TextLink>
    );
  }

  return (
    <Popover
      placement="bottom"
      title={<div>{`${sourcesTotal} sources`}</div>}
      content={popoverContent}
    >
      {<span>{`${sourcesTotal} sources`}</span>}
    </Popover>
  );
}

function EmptyState() {
  return (
    <Tooltip title={tooltips.emptyState} overlayInnerStyle={{ padding: "12px" }}>
      <span className={css.emptyState}>No data found</span>
    </Tooltip>
  );
}

function AlternativeSource({ source }: { source: string }) {
  return (
    <Tooltip title={tooltips.alternativeSource} overlayInnerStyle={{ padding: "12px" }}>
      <span className={css.emptyState}>{source}</span>
    </Tooltip>
  );
}
