import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { App, Button } from "antd5";
import { RangePickerProps } from "antd5/es/date-picker";
import dayjs from "dayjs";

import { BuyerSelect } from "components/form_components/BuyerSelect";
import FileInput from "components/form_components/FileInput";
import { DatePicker, Input, Select } from "components/form_components/Inputs";
import { useUploadFileDocument } from "lib/hooks/api/admin/useUploadFileDocument";
import { useUploadUrlDocument } from "lib/hooks/api/admin/useUploadUrlDocument";
import { LINK_REGEX } from "lib/utils";
import { documentTypes } from "./utils";

import css from "./UploadDocuments.module.scss";

export type FileData = {
  url: string;
  file: File[];
  publishDate?: Date;
  title?: string;
  description?: string;
};

export type UploadDocumentsFormFields = {
  createdBy: string;
  organisationIds: string[];
  sourceUrl?: string;
  alternativeSource?: string;
  category: string;
  files: FileData[];
};

export function UploadDocuments({ userId }: { userId: string }) {
  const { message } = App.useApp();

  const defaultValues: UploadDocumentsFormFields = {
    createdBy: userId,
    organisationIds: [],
    sourceUrl: undefined,
    alternativeSource: undefined,
    category: "",
    files: [
      { url: "", publishDate: undefined, title: undefined, description: undefined, file: [] },
    ],
  };

  const { control, handleSubmit, getValues } = useForm<UploadDocumentsFormFields>({
    mode: "onSubmit",
    defaultValues: defaultValues,
  });

  const { mutate: uploadUrlDocument } = useUploadUrlDocument({
    onSuccess: (_data, variables, _context) =>
      void message.success(`File ${variables.fileId} uploaded successfully`),
    onError: (_data, variables, _context) =>
      void message.error(
        `Failed to upload File ${variables.fileId}. Please contact us if the problem persists.`,
      ),
  });

  const { mutate: uploadFileDocument } = useUploadFileDocument({
    onSuccess: (_data, variables, _context) =>
      void message.success(`File ${variables.fileId} uploaded successfully`),
    onError: (_data, variables, _context) =>
      void message.error(
        `Failed to upload File ${variables.fileId}. Please contact us if the problem persists.`,
      ),
  });

  const { fields, append, remove } = useFieldArray<UploadDocumentsFormFields>({
    control,
    name: "files",
  });

  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <form
      className={css.form}
      onSubmit={handleSubmit((formInput) => {
        formInput.files.forEach(async (fileData, index) => {
          const publishDate = fileData.publishDate?.toISOString();
          if (fileData.url) {
            uploadUrlDocument({
              fileId: index + 1,
              createdBy: formInput.createdBy,
              organisationIds: formInput.organisationIds,
              sourceUrl: formInput.sourceUrl,
              alternativeSource: formInput.alternativeSource,
              category: formInput.category,
              url: fileData.url,
              title: fileData.title,
              description: fileData.description,
              publishDate: publishDate ?? "",
            });
          } else {
            uploadFileDocument({
              fileId: index + 1,
              userId: userId,
              fileData: {
                ...fileData,
                publishDate: publishDate ?? "",
              },
              formInput: formInput,
            });
          }
        });
      })}
    >
      <BuyerSelect
        name="organisationIds"
        label="Buyer(s)"
        control={control}
        mode="multiple"
        placeholder="Select buyer(s)"
        allowClear
        rules={{ required: { value: true, message: "Buyer is required" } }}
      />
      <Input
        control={control}
        name="sourceUrl"
        label="Source URL"
        placeholder="Location where these documents were found"
        rules={{
          pattern: {
            value: LINK_REGEX,
            message: "Please enter a valid URL",
          },
          validate: () => {
            const sourceUrl = getValues("sourceUrl");
            const alternativeUrl = getValues("alternativeSource");
            if (!sourceUrl && !alternativeUrl) {
              return "Either a Source URL or an Alternative Source is required";
            }
            if (sourceUrl && alternativeUrl) {
              return "Please provide either a Source URL or an Alternative Source, not both";
            }
            return true;
          },
        }}
      />
      <Input
        control={control}
        name="alternativeSource"
        label="Alternative source"
        placeholder="E.g. FOI request"
      />
      <Select
        name="category"
        control={control}
        label="Document type"
        optionFilterProp="label"
        options={documentTypes}
        placeholder="Select document type"
        rules={{ required: { value: true, message: "Category is required" } }}
      />
      {fields.map((field, index) => (
        <div className={css.files}>
          <div className={css.fileHeader}>
            <p>File {index + 1}</p>
            <Button
              className={css.remove}
              type="text"
              shape="circle"
              onClick={() => remove(index)}
              icon={<MinusCircleOutlined />}
              size="large"
              disabled={fields.length === 1}
            />
          </div>
          <div key={field.id} className={css.fileGroup}>
            <Input
              control={control}
              name={`files.${index}.url`}
              label="Document link"
              containerClassName={css.input}
              rules={{
                pattern: {
                  value: LINK_REGEX,
                  message: "Please enter a valid URL",
                },
                validate: () => {
                  const fileData = getValues(`files.${index}`);
                  if (!fileData.url && !fileData.file.length) {
                    return "File does not contain a file link or an uploaded file. Please add one.";
                  }
                  if (fileData.url && fileData.file.length) {
                    return "File contains both a file link and an uploaded file. Please delete one.";
                  }
                  return true;
                },
              }}
            />
            <div className={css.formText}>OR</div>
            <Controller
              control={control}
              name={`files.${index}.file`}
              render={({ field }) => (
                <FileInput {...field} iconClassName={css.fileIcon} multiple={false} />
              )}
            />
            <DatePicker
              name={`files.${index}.publishDate`}
              control={control}
              label="Publish date"
              disabledDate={disabledFutureDate}
              placeholder="Select publish date"
              rules={{ required: { value: true, message: "Publish date is required" } }}
            />
            <Input
              control={control}
              name={`files.${index}.title`}
              label="Title"
              placeholder="Document title"
              rules={{
                validate: () => {
                  const url = getValues(`files.${index}.url`);
                  const title = getValues(`files.${index}.title`);
                  if (url && !title) {
                    return "Title is required for uploads via document link";
                  }
                  return true;
                },
              }}
            />
            <Input
              control={control}
              name={`files.${index}.description`}
              label="Description"
              placeholder="Description"
            />
          </div>
        </div>
      ))}
      <Button
        className={css.add}
        type="text"
        icon={<PlusOutlined />}
        onClick={() =>
          append({
            url: "",
            file: [],
            publishDate: undefined,
            title: undefined,
            description: undefined,
          })
        }
      >
        Add another file
      </Button>
      <Button htmlType="submit" type="primary" className={css.button} icon={<UploadOutlined />}>
        Upload documents
      </Button>
    </form>
  );
}
