import React from "react";
import { Button, Modal } from "antd5";

import css from "./SignalSettingsConfirmationModal.module.scss";

type SignalSettingsModalProps = {
  isOpen: boolean;
  showConfirmation: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onConfirmationRedirect: () => void;
  resetUpdateSignalSettings: () => void;
};

export default function SignalSettingsConfirmationModal({
  isOpen,
  showConfirmation,
  onSubmit,
  onCancel,
  onConfirmationRedirect,
  resetUpdateSignalSettings,
}: SignalSettingsModalProps) {
  const handleModalCancel = () => {
    resetUpdateSignalSettings();
    onCancel();
  };

  const handleConfirmationRedirect = () => {
    onConfirmationRedirect();
  };

  return (
    <Modal
      open={isOpen}
      onOk={onSubmit}
      onCancel={handleModalCancel}
      centered
      footer={
        showConfirmation ? (
          <>
            <Button onClick={handleModalCancel}>Close</Button>
            <Button type="primary" onClick={handleConfirmationRedirect}>
              Go to Notices
            </Button>
          </>
        ) : undefined
      }
    >
      {showConfirmation === false ? (
        <div className={css.modalContent}>
          <div className={css.modalHeader}>
            <p className={css.modalTitle}>
              Changing this will automatically update your saved views
            </p>
          </div>
          <p className={css.modalSubtitle}>
            These changes will impact your whole team. We recommend letting your colleagues know
            before you make any changes.
          </p>
        </div>
      ) : (
        <div className={css.modalContent}>
          <div className={css.modalHeader}>
            <p className={css.modalTitle}>Settings Updated Successfully</p>
          </div>
          <p className={css.modalSubtitle}>
            Your signal settings have been updated. The changes will be reflected in your saved
            views.
          </p>
        </div>
      )}
    </Modal>
  );
}
