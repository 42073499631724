import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { QualificationStage } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string];

export type QualifcationStages = {
  tenders: QualificationStage[];
  preEngagement: QualificationStage[];
};

export function useQualificationStages(
  options?: UseQueryOptions<
    QualifcationStages | undefined,
    unknown,
    QualifcationStages | undefined,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["qualificationStages"],
    async () => {
      const response = await api.getQualificationStages();

      return {
        tenders: response.results.filter((stage) => !stage.isPreEngagement),
        preEngagement: response.results.filter((stage) => stage.isPreEngagement),
      };
    },
    { ...options },
  );
}
