import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { assertCurrentUser } from "lib/currentUser";
import { GetAssignedNoticesResponseResult } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string];

type Response = {
  allNotices: GetAssignedNoticesResponseResult[];
  teamNotices: GetAssignedNoticesResponseResult[];
};

export function useAssignedNotices(
  options?: UseQueryOptions<Response, unknown, Response, QueryKey>,
) {
  const api = useOpenApi();
  const user = assertCurrentUser();

  return useQuery(
    ["assignedNotices"],
    async () => {
      const response = await api.getAssignedNotices();

      const teamNotices = response?.results.filter((u) => u.teamId === user.team.id);

      return {
        allNotices: response?.results || [],
        teamNotices: teamNotices || [],
      };
    },
    { ...options },
  );
}
