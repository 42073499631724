import React from "react";
import { Control } from "react-hook-form";

import { NoticeListSelect } from "../../form_components/NoticeListSelect";
import { UserSelect } from "../../form_components/UserSelect";
import { SelectQualificationForm } from "../../record_qualifications/SelectQualification";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface WorkflowFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function WorkflowFilterContent({ control }: WorkflowFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Workflow filters">
      <UserSelect
        name="assignee"
        label="Assignee"
        control={control}
        mode="multiple"
        placeholder="Select team members"
        allowClear
      />
      <SelectQualificationForm
        label="Qualification"
        name="procurementStageQualifications"
        control={control}
        allowClear
      />
      <NoticeListSelect
        name="noticeLists"
        label="Notices saved to"
        placeholder="Select list"
        control={control}
        mode="multiple"
        allowClear
      />
    </div>
  );
}

export default WorkflowFilterContent;
