import * as React from "react";

import TextButton from "components/actions/TextButton";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { useRestrictedRecordRowClick } from "lib/hooks/useRestrictedRowClick";
import { useRecordViewer } from "lib/providers/RecordViewer";
import { RecordDetails } from "lib/types/models";

import css from "./SimpleTitleCell.module.scss";

type Props = {
  record: RecordDetails;
  restrictRecordView?: boolean;
};

export default function SimpleTitleCell({ record, restrictRecordView }: Props): JSX.Element {
  const { viewRecord } = useRecordViewer();

  const getRequiredDataTypesForRow = React.useCallback(
    (r) => (r.stage === "AWARDED" ? "AWARDS" : undefined),
    [],
  );

  const onRowClick = useRestrictedRecordRowClick(getRequiredDataTypesForRow);

  return (
    <TextButton
      underline
      className={css.textButton}
      onClick={(e) => {
        if (restrictRecordView) {
          onRowClick(record);
        } else {
          viewRecord(record.guid, { "Context source": "Title click" });
          e.stopPropagation();
        }
      }}
    >
      <EllipsisTooltipText fullText={record.name} childText={record.name} />
    </TextButton>
  );
}
