import React from "react";
import { message, Modal } from "antd5";

import { convertNoticeFiltersToView } from "components/notices/utils";
import { assertCurrentUser } from "lib/currentUser";
import { SavedViewType } from "lib/generated/app-api";
import { useGetLeadSubscriptions } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptions";
import { useCreateView } from "../../../lib/hooks/api/notices/views/useCreateView";
import { useMyFeedPageState } from "../useMyFeedPageState";
import { SavedViewForm } from "./SavedViewForm";

import css from "./CreateViewModal.module.scss";

type CreateViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedView?: SavedViewType;
};

export function CreateViewModal({ isOpen, onClose, selectedView }: CreateViewModalProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const { filters, tableColumns, setSelectedView } = useMyFeedPageState();

  const { data: existingLeadSubscriptions, isLoading: isLoadingLeadSubscriptions } =
    useGetLeadSubscriptions();

  const { mutate: createView, isLoading: isCreatingView } = useCreateView({
    onSuccess: async (data, vars) => {
      setSelectedView(data, "", "Create view modal");
      onClose();
      await messageApi.success(`Successfully created ${vars.view.name}`);
    },
    onError: async (_data, vars) => {
      onClose();
      await messageApi.error(`Failed to create ${vars.view.name}`);
    },
  });

  return (
    <Modal
      className={css.modal}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title="Save view"
      okButtonProps={{ disabled: isLoadingLeadSubscriptions }}
    >
      <p className={css.modalDescription}>
        Quickly access your most commonly used filters at anytime by creating filtered views.
      </p>
      <SavedViewForm
        defaultValues={{
          viewName: "",
          viewDescription: "",
          alertFrequency: "DAILY",
        }}
        onSubmit={(data) => {
          const currentUser = assertCurrentUser();

          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { updatedAt, ...createSelectedViewFields } = selectedView || {};

          createView({
            view: {
              ...createSelectedViewFields,
              id: undefined,
              name: data.viewName,
              description: data.viewDescription,
              ownerId: currentUser.guid,
              view: convertNoticeFiltersToView(filters, tableColumns),
              isStandardView: false,
            },
            alertFrequency: data.alertFrequency,
            existingLeadSubscriptions: existingLeadSubscriptions,
          });
        }}
        onCancel={onClose}
        isLoading={isCreatingView}
      />
      {contextHolder}
    </Modal>
  );
}
