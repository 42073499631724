import React, { Dispatch, SetStateAction, useMemo } from "react";
import produce from "immer";
import { groupBy } from "lodash";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import {
  useSupplierGuidSummary,
  useSupplierSummaryChart,
} from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  SpendDataFilters,
  SupplierGuidSummary,
  SupplierSummary,
  SupplierSummaryChartData,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { EventNames, TrackingEvent, TrackingProvider, useTracking } from "lib/tracking";
import { SpendDataFilters as UiFilters } from "./hooks/useSpendPageState";
import { DateUnitSelector } from "./DateUnitSelector";
import SpendChart, { Dataset } from "./SpendChart";

function createChartClickedOnEvent(filterValue: string): TrackingEvent {
  return {
    name: EventNames.chartClickedOn,
    data: {
      "Action type": "Filter applied",
      "Filter name": "Supplier name",
      "Context source": "All suppliers chart",
      "Filter value": filterValue,
    },
  };
}

type Props = {
  filters: SpendDataFilters;
  setFilters: Dispatch<SetStateAction<UiFilters>>;
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: Dispatch<"month" | "quarter" | "year">;
};

const assertSupplierGuids = (
  suppliers: SupplierSummary[] | SupplierGuidSummary[] | undefined,
): SupplierGuidSummary[] | undefined => {
  if (!suppliers) {
    return undefined;
  } else if (suppliers.length === 0 || "supplierGuid" in suppliers[0]) {
    return suppliers as SupplierGuidSummary[];
  } else {
    throw new Error(`Expected supplierGuid to be in suppliers`);
  }
};

function SupplierChart({ filters, setFilters, dateUnit, setDateUnit }: Props) {
  const { logEvents } = useTracking();
  const { data: top10Suppliers } = useSupplierGuidSummary(
    {
      filters,
      limit: 10,
      offset: 0,
      orderBy: "total",
      sortOrder: "DESC",
    },
    { query: { enabled: !window.guestUser } },
  );
  const supplierFilters: SpendDataFilters = {
    supplierGuids: assertSupplierGuids(top10Suppliers?.data)?.map((d) => d.supplierGuid),
  };

  const { data, isLoading } = useSupplierSummaryChart(
    {
      filters: {
        ...filters,
        ...supplierFilters,
      },
      dateUnit,
      groupBy: "supplier_guid",
    },

    { query: { enabled: !!top10Suppliers } },
  );

  // convert to chart data grouped by buyer
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    const dates = Array.from(new Set(data.data.map((d) => d.date).filter((d) => d))).sort();

    const grouped =
      data &&
      groupBy(
        data.data.filter((d) => d.date),
        "supplier",
      );

    return Object.entries(grouped).map(([supplier, data]) => {
      const dateMap = {} as Record<string, SupplierSummaryChartData>;
      for (const d of data) {
        dateMap[d.date] = d;
      }

      return {
        label: supplier,
        id: supplier,
        data: dates.map((date) => {
          const d = dateMap[date] || { date, total: 0 };
          return d;
        }),
      };
    });
  }, [data]);

  const onDatasetsClick = (datasets: Dataset[]) => {
    const supplierNames = datasets.map((d) => d.label);
    if (supplierNames.length !== 1) {
      return;
    }
    logEvents(createChartClickedOnEvent(`"${supplierNames[0]}"`));
    setFilters(
      produce((draft) => {
        draft.supplier = `"${supplierNames[0]}"`;
      }),
    );
  };
  return (
    <TrackingProvider data={{ "Context source": "Supplier spending chart" }}>
      <DetailsSection
        title={`Spend over time by ${
          top10Suppliers && top10Suppliers.total > 10 ? "top 10 suppliers" : "supplier"
        }`}
        action={<DateUnitSelector dateUnit={dateUnit} setDateUnit={setDateUnit} />}
      >
        <SpendChart
          data={chartData}
          dateUnit={dateUnit}
          onDatasetsClick={onDatasetsClick}
          isLoading={isLoading}
        />
      </DetailsSection>
    </TrackingProvider>
  );
}

export default SupplierChart;
