/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactDetailsRequest,
  ContactDetailsResponse,
  DescribeEntityRequest,
  DescribeNoticeFieldsResponse,
  DescribeObjectResponse,
  FetchPropertyValuesRequest,
  FetchPropertyValuesResponse,
  FindProviderResponse,
  HealthControllerReadiness200Response,
  HealthControllerReadiness503Response,
  InstallUrlRequest,
  InstallUrlResponse,
  ListNoticeMappingsRequest,
  ListNoticeMappingsResponse,
  NoticeConfigBuilderRequest,
  NoticeConfigBuilderResponse,
  NoticeDetailsRequest,
  NoticeDetailsResponse,
  NoticeFormResponse,
  NoticeListEntitiesResponse,
  ProviderListResponse,
  SearchAccountsRequest,
  SearchAccountsResponse,
  SearchOwnersResponse,
  SendContactRequest,
  SendContactResponse,
  SendNoticeRequest,
  SendNoticeResponse,
} from '../models';
import {
    ContactDetailsRequestFromJSON,
    ContactDetailsRequestToJSON,
    ContactDetailsResponseFromJSON,
    ContactDetailsResponseToJSON,
    DescribeEntityRequestFromJSON,
    DescribeEntityRequestToJSON,
    DescribeNoticeFieldsResponseFromJSON,
    DescribeNoticeFieldsResponseToJSON,
    DescribeObjectResponseFromJSON,
    DescribeObjectResponseToJSON,
    FetchPropertyValuesRequestFromJSON,
    FetchPropertyValuesRequestToJSON,
    FetchPropertyValuesResponseFromJSON,
    FetchPropertyValuesResponseToJSON,
    FindProviderResponseFromJSON,
    FindProviderResponseToJSON,
    HealthControllerReadiness200ResponseFromJSON,
    HealthControllerReadiness200ResponseToJSON,
    HealthControllerReadiness503ResponseFromJSON,
    HealthControllerReadiness503ResponseToJSON,
    InstallUrlRequestFromJSON,
    InstallUrlRequestToJSON,
    InstallUrlResponseFromJSON,
    InstallUrlResponseToJSON,
    ListNoticeMappingsRequestFromJSON,
    ListNoticeMappingsRequestToJSON,
    ListNoticeMappingsResponseFromJSON,
    ListNoticeMappingsResponseToJSON,
    NoticeConfigBuilderRequestFromJSON,
    NoticeConfigBuilderRequestToJSON,
    NoticeConfigBuilderResponseFromJSON,
    NoticeConfigBuilderResponseToJSON,
    NoticeDetailsRequestFromJSON,
    NoticeDetailsRequestToJSON,
    NoticeDetailsResponseFromJSON,
    NoticeDetailsResponseToJSON,
    NoticeFormResponseFromJSON,
    NoticeFormResponseToJSON,
    NoticeListEntitiesResponseFromJSON,
    NoticeListEntitiesResponseToJSON,
    ProviderListResponseFromJSON,
    ProviderListResponseToJSON,
    SearchAccountsRequestFromJSON,
    SearchAccountsRequestToJSON,
    SearchAccountsResponseFromJSON,
    SearchAccountsResponseToJSON,
    SearchOwnersResponseFromJSON,
    SearchOwnersResponseToJSON,
    SendContactRequestFromJSON,
    SendContactRequestToJSON,
    SendContactResponseFromJSON,
    SendContactResponseToJSON,
    SendNoticeRequestFromJSON,
    SendNoticeRequestToJSON,
    SendNoticeResponseFromJSON,
    SendNoticeResponseToJSON,
} from '../models';

export interface DescribeContactDetailsRequest {
    contactDetailsRequest: ContactDetailsRequest;
}

export interface DescribeEntityOperationRequest {
    describeEntityRequest: DescribeEntityRequest;
}

export interface FetchPropertyValuesOperationRequest {
    fetchPropertyValuesRequest: FetchPropertyValuesRequest;
}

export interface InstallCompleteCallbackRequest {
    state: string;
    code: string;
}

export interface IntegrationInstallUrlRequest {
    providerId: string;
    installUrlRequest: InstallUrlRequest;
}

export interface ListNoticeMappingsOperationRequest {
    listNoticeMappingsRequest: ListNoticeMappingsRequest;
}

export interface NoticeDetailsOperationRequest {
    noticeDetailsRequest: NoticeDetailsRequest;
}

export interface SearchAccountsOperationRequest {
    searchAccountsRequest: SearchAccountsRequest;
}

export interface SendContactOperationRequest {
    sendContactRequest: SendContactRequest;
}

export interface SendNoticeOperationRequest {
    sendNoticeRequest: SendNoticeRequest;
}

export interface UninstallRequest {
    providerId: string;
}

export interface UpdateNoticeConfigRequest {
    noticeConfigBuilderRequest: NoticeConfigBuilderRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * 
     */
    async describeContactDetailsRaw(requestParameters: DescribeContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactDetailsResponse>> {
        if (requestParameters.contactDetailsRequest === null || requestParameters.contactDetailsRequest === undefined) {
            throw new runtime.RequiredError('contactDetailsRequest','Required parameter requestParameters.contactDetailsRequest was null or undefined when calling describeContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/contacts/details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactDetailsRequestToJSON(requestParameters.contactDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactDetailsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async describeContactDetails(requestParameters: DescribeContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactDetailsResponse> {
        const response = await this.describeContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async describeEntityRaw(requestParameters: DescribeEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeObjectResponse>> {
        if (requestParameters.describeEntityRequest === null || requestParameters.describeEntityRequest === undefined) {
            throw new runtime.RequiredError('describeEntityRequest','Required parameter requestParameters.describeEntityRequest was null or undefined when calling describeEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/describeEntity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DescribeEntityRequestToJSON(requestParameters.describeEntityRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeObjectResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async describeEntity(requestParameters: DescribeEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeObjectResponse> {
        const response = await this.describeEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async describeNoticeFieldsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeNoticeFieldsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/describeNoticeFields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeNoticeFieldsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async describeNoticeFields(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeNoticeFieldsResponse> {
        const response = await this.describeNoticeFieldsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async fetchPropertyValuesRaw(requestParameters: FetchPropertyValuesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchPropertyValuesResponse>> {
        if (requestParameters.fetchPropertyValuesRequest === null || requestParameters.fetchPropertyValuesRequest === undefined) {
            throw new runtime.RequiredError('fetchPropertyValuesRequest','Required parameter requestParameters.fetchPropertyValuesRequest was null or undefined when calling fetchPropertyValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/fetchPropertyValues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchPropertyValuesRequestToJSON(requestParameters.fetchPropertyValuesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchPropertyValuesResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async fetchPropertyValues(requestParameters: FetchPropertyValuesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchPropertyValuesResponse> {
        const response = await this.fetchPropertyValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async fetchProviderRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindProviderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/fetchProvider`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindProviderResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async fetchProvider(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindProviderResponse> {
        const response = await this.fetchProviderRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async healthControllerReadinessRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthControllerReadiness200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/integration/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthControllerReadiness200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async healthControllerReadiness(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthControllerReadiness200Response> {
        const response = await this.healthControllerReadinessRaw(initOverrides);
        return await response.value();
    }

    /**
     * Callback called by the provider once the user has signed in. Saves the auth token and an install in our DB
     * 
     */
    async installCompleteCallbackRaw(requestParameters: InstallCompleteCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling installCompleteCallback.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling installCompleteCallback.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/oauth/callback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback called by the provider once the user has signed in. Saves the auth token and an install in our DB
     * 
     */
    async installCompleteCallback(requestParameters: InstallCompleteCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.installCompleteCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * For each integration creates a url that initiates an oauth sign in with their provider
     * 
     */
    async integrationInstallUrlRaw(requestParameters: IntegrationInstallUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallUrlResponse>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling integrationInstallUrl.');
        }

        if (requestParameters.installUrlRequest === null || requestParameters.installUrlRequest === undefined) {
            throw new runtime.RequiredError('installUrlRequest','Required parameter requestParameters.installUrlRequest was null or undefined when calling integrationInstallUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/{providerId}/install`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallUrlRequestToJSON(requestParameters.installUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallUrlResponseFromJSON(jsonValue));
    }

    /**
     * For each integration creates a url that initiates an oauth sign in with their provider
     * 
     */
    async integrationInstallUrl(requestParameters: IntegrationInstallUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallUrlResponse> {
        const response = await this.integrationInstallUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listNoticeMappingsRaw(requestParameters: ListNoticeMappingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListNoticeMappingsResponse>> {
        if (requestParameters.listNoticeMappingsRequest === null || requestParameters.listNoticeMappingsRequest === undefined) {
            throw new runtime.RequiredError('listNoticeMappingsRequest','Required parameter requestParameters.listNoticeMappingsRequest was null or undefined when calling listNoticeMappings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/listNoticeMappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListNoticeMappingsRequestToJSON(requestParameters.listNoticeMappingsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNoticeMappingsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async listNoticeMappings(requestParameters: ListNoticeMappingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListNoticeMappingsResponse> {
        const response = await this.listNoticeMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List provider options for a given company that will be displayed in the integration marketplace
     * 
     */
    async listProvidersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProviderListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderListResponseFromJSON(jsonValue));
    }

    /**
     * List provider options for a given company that will be displayed in the integration marketplace
     * 
     */
    async listProviders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProviderListResponse> {
        const response = await this.listProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async noticeDetailsRaw(requestParameters: NoticeDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeDetailsResponse>> {
        if (requestParameters.noticeDetailsRequest === null || requestParameters.noticeDetailsRequest === undefined) {
            throw new runtime.RequiredError('noticeDetailsRequest','Required parameter requestParameters.noticeDetailsRequest was null or undefined when calling noticeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoticeDetailsRequestToJSON(requestParameters.noticeDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeDetailsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async noticeDetails(requestParameters: NoticeDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeDetailsResponse> {
        const response = await this.noticeDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async noticeFormSchemaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeFormResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/notice-form-schema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeFormResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async noticeFormSchema(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeFormResponse> {
        const response = await this.noticeFormSchemaRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async noticeListEntitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeListEntitiesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/listEntities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeListEntitiesResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async noticeListEntities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeListEntitiesResponse> {
        const response = await this.noticeListEntitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchAccountsRaw(requestParameters: SearchAccountsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchAccountsResponse>> {
        if (requestParameters.searchAccountsRequest === null || requestParameters.searchAccountsRequest === undefined) {
            throw new runtime.RequiredError('searchAccountsRequest','Required parameter requestParameters.searchAccountsRequest was null or undefined when calling searchAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/accounts/search-accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAccountsRequestToJSON(requestParameters.searchAccountsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAccountsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchAccounts(requestParameters: SearchAccountsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchAccountsResponse> {
        const response = await this.searchAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchOwnersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchOwnersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/owners/search-owners`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchOwnersResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchOwners(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchOwnersResponse> {
        const response = await this.searchOwnersRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async sendContactRaw(requestParameters: SendContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendContactResponse>> {
        if (requestParameters.sendContactRequest === null || requestParameters.sendContactRequest === undefined) {
            throw new runtime.RequiredError('sendContactRequest','Required parameter requestParameters.sendContactRequest was null or undefined when calling sendContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/contacts/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendContactRequestToJSON(requestParameters.sendContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendContactResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async sendContact(requestParameters: SendContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendContactResponse> {
        const response = await this.sendContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async sendNoticeRaw(requestParameters: SendNoticeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendNoticeResponse>> {
        if (requestParameters.sendNoticeRequest === null || requestParameters.sendNoticeRequest === undefined) {
            throw new runtime.RequiredError('sendNoticeRequest','Required parameter requestParameters.sendNoticeRequest was null or undefined when calling sendNotice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendNoticeRequestToJSON(requestParameters.sendNoticeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendNoticeResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async sendNotice(requestParameters: SendNoticeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendNoticeResponse> {
        const response = await this.sendNoticeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove the installation data for a given provider id. We also remove the refresh and access tokens.
     * 
     */
    async uninstallRaw(requestParameters: UninstallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling uninstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/provider/{providerId}/uninstall`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove the installation data for a given provider id. We also remove the refresh and access tokens.
     * 
     */
    async uninstall(requestParameters: UninstallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uninstallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateNoticeConfigRaw(requestParameters: UpdateNoticeConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeConfigBuilderResponse>> {
        if (requestParameters.noticeConfigBuilderRequest === null || requestParameters.noticeConfigBuilderRequest === undefined) {
            throw new runtime.RequiredError('noticeConfigBuilderRequest','Required parameter requestParameters.noticeConfigBuilderRequest was null or undefined when calling updateNoticeConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/integration/crm/notices/updateNoticeConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoticeConfigBuilderRequestToJSON(requestParameters.noticeConfigBuilderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeConfigBuilderResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateNoticeConfig(requestParameters: UpdateNoticeConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeConfigBuilderResponse> {
        const response = await this.updateNoticeConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
