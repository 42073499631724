import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { GetRecordListsResponseResult, RecordListSummaryDto } from "lib/generated/app-api";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventData, EventNames, useTracking } from "lib/tracking";

type Params = {
  list: GetRecordListsResponseResult | RecordListSummaryDto;
  trackingData?: EventData;
};

export function useDeleteNoticeList(
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  return useMutation(async ({ list }: Params) => api.deleteUserList(list.id), {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      await queryClient.invalidateQueries(["recordLists"]);
      await queryClient.invalidateQueries(["noticeLists"]);
      const { list, trackingData } = vars;

      logEvent(EventNames.listDeleted, {
        ...trackingData,
        "List Name": list.name,
        "List id": list.id,
      });
      options?.onSuccess?.(data, vars, ctx);
    },
  });
}
