import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { DescribeObjectResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string, string | undefined];

export function useDescribeEntity(
  name: string,
  options?: UseQueryOptions<
    DescribeObjectResponse | undefined,
    unknown,
    DescribeObjectResponse,
    QueryKey
  >,
) {
  const api = useIntegrationApi();

  return useQuery(
    ["describeEntity", name],
    async () => {
      const response = await api.describeEntity({
        describeEntityRequest: { entityName: name },
      });
      return response;
    },
    options,
  );
}
