import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Collapse, ConfigProvider } from "antd5";

import TextButton from "components/actions/TextButton";

import css from "./AdvancedSettings.module.scss";

type Props = {
  collapseKey: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
};

function AdvancedSettings({ action, collapseKey, children }: Props): JSX.Element | null {
  const [activeKey, setActiveKey] = React.useState<string[] | string>([]);
  const isActive = Array.isArray(activeKey)
    ? activeKey.includes(collapseKey)
    : activeKey === collapseKey;
  if (!children) {
    return null;
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            paddingSM: 0,
            padding: 0,
          },
        },
      }}
    >
      <Collapse
        className={css.advancedSettings}
        bordered={false}
        activeKey={activeKey}
        onChange={(newActiveKey) => setActiveKey(newActiveKey)}
        items={[
          {
            key: collapseKey,
            children: children,
            className: css.collapseItem,
            showArrow: false,
            label: (
              <span className={css.header}>
                <TextButton bold className={css.hideLabel}>
                  {isActive ? "Hide advanced settings" : "Show advanced settings"}{" "}
                  <DownOutlined className={css.arrowIcon} rotate={isActive ? 180 : 0} />
                </TextButton>
                {action}
              </span>
            ),
          },
        ]}
      />
    </ConfigProvider>
  );
}

export default AdvancedSettings;
