import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Calendar, Popover, Select, Tabs } from "antd5"; // dodgy global styles here
import dayjs, { Dayjs } from "dayjs";

import { FilterPill } from "./FilterPill";

import css from "./RelativeDatePicker.module.scss";

//https://www.notion.so/stotles/0022-Relative-dates-c1c9cf427844485cabceade99fc55df9#3bc88486058b4a738accb55deca71478

export const TODAY = "PT0S";

const today = dayjs().startOf("day");

type RelativeDateOptions = Record<string, { label: string; value: Dayjs }>;

const PAST_OPTIONS: RelativeDateOptions = {
  "P-7D": { label: "1 week ago", value: today.subtract(1, "week") },
  "P-1M": { label: "1 month ago", value: today.subtract(1, "month") },
  "P-3M": { label: "3 months ago", value: today.subtract(3, "month") },
  "P-6M": { label: "6 months ago", value: today.subtract(6, "month") },
  "P-1Y": { label: "1 year ago", value: today.subtract(1, "year") },
  "P-2Y": { label: "2 years ago", value: today.subtract(2, "year") },
  "P-3Y": { label: "3 years ago", value: today.subtract(3, "year") },
  "P-4Y": { label: "4 years ago", value: today.subtract(4, "year") },
  "P-5Y": { label: "5 years ago", value: today.subtract(5, "year") },
};

const FUTURE_OPTIONS: RelativeDateOptions = {
  P7D: { label: "1 week from now", value: today.add(1, "week") },
  P1M: { label: "1 month from now", value: today.add(1, "month") },
  P3M: { label: "3 months from now", value: today.add(3, "month") },
  P6M: { label: "6 months from now", value: today.add(6, "month") },
  P1Y: { label: "1 year from now", value: today.add(1, "year") },
  P2Y: { label: "2 years from now", value: today.add(2, "year") },
  P3Y: { label: "3 years from now", value: today.add(3, "year") },
  P4Y: { label: "4 years from now", value: today.add(4, "year") },
  P5Y: { label: "5 years from now", value: today.add(5, "year") },
};

export const RELATIVE_DATE_OPTIONS: RelativeDateOptions = {
  PT0S: { label: "Today", value: today },
  ...PAST_OPTIONS,
  ...FUTURE_OPTIONS,
};

/* Whole RelativeOptions but without "today", variable is just used to filter */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const { PT0S: _, ...optionsWithoutToday } = RELATIVE_DATE_OPTIONS;

type DatePickerPopoverProps = {
  value?: Dayjs;
  relativeValue?: string;
  onSelect: (value?: Dayjs, relativeValue?: string) => void;
  disabledDate?: (current: Dayjs) => boolean;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  showFutureDates?: boolean;
};

export function DatePickerPopover({
  value,
  relativeValue,
  onSelect,
  disabledDate,
  disabled,
  format,
  placeholder,
  showFutureDates,
}: DatePickerPopoverProps): JSX.Element {
  const [visible, setVisible] = React.useState<boolean>(false);

  const onChange = React.useCallback(
    (value, relativeValue) => {
      onSelect(value, relativeValue);
    },
    [onSelect],
  );

  const clear = React.useCallback(
    (e: React.MouseEvent) => {
      onChange(undefined, undefined);
      e.stopPropagation();
    },
    [onChange],
  );

  return (
    <Popover
      content={
        <DatePicker
          value={value}
          relativeValue={relativeValue}
          onSelect={onChange}
          disabledDate={disabledDate}
          showFutureDates={showFutureDates}
        />
      }
      trigger={"click"}
      open={visible}
      onOpenChange={setVisible}
      getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
      overlayInnerStyle={{ paddingTop: 0 }}
    >
      <Button disabled={disabled} className={css.trigger}>
        {getDateValue(value, relativeValue, format)}
        {value || relativeValue ? (
          <div onClick={clear}>
            <CloseCircleFilled />
          </div>
        ) : (
          <span className={css.placeholder}>{placeholder}</span>
        )}
      </Button>
    </Popover>
  );
}

function DatePicker({
  value,
  relativeValue,
  onSelect,
  disabledDate,
  showFutureDates,
}: DatePickerPopoverProps) {
  if (relativeValue && !value && relativeValue in RELATIVE_DATE_OPTIONS) {
    value = RELATIVE_DATE_OPTIONS[relativeValue].value;
  }

  function getRelativeOptions(options: RelativeDateOptions) {
    return Object.entries(options).map(([key, { value, label }]) => {
      const disabled = disabledDate && disabledDate(value);
      return (
        <FilterPill
          key={key}
          onClick={() => onSelect(value, key)}
          disabled={disabled}
          selected={relativeValue === key}
        >
          {label}
        </FilterPill>
      );
    });
  }

  const pastOptions = getRelativeOptions(PAST_OPTIONS);
  const futureOptions = getRelativeOptions(FUTURE_OPTIONS);

  return (
    <Tabs
      className={css.popover}
      items={[
        {
          key: "relative",
          label: "Relative date",
          children: (
            <div className={css.relativeDatePicker}>
              <div className={css.now}>
                <span>Now</span>
                <FilterPill
                  onClick={() => onSelect(today)}
                  disabled={disabledDate && disabledDate(today)}
                  selected={relativeValue == TODAY}
                >
                  Today
                </FilterPill>
              </div>
              <div className={css.pastAndFuture}>
                <div className={css.column}>
                  <span>Past</span>
                  {pastOptions}
                </div>
                {showFutureDates && (
                  <div className={css.column}>
                    <span>Future</span>
                    {futureOptions}
                  </div>
                )}
              </div>
            </div>
          ),
        },
        {
          key: "specific",
          label: "Specific date",
          children: (
            <Calendar
              fullscreen={false}
              value={value || dayjs()}
              onSelect={(val) => onSelect(val)}
              onChange={onSelect}
              disabledDate={disabledDate}
              headerRender={DatePickerHeader}
            />
          ),
        },
      ]}
    />
  );
}

// array of month names and value generated based on userslocale
const monthOptions: { value: string; label: string }[] = Array.from({ length: 12 }, (_, i) => {
  return { value: i.toString(), label: dayjs().month(i).format("MMMM") };
});

// Custom calendar header
function DatePickerHeader({ value, onChange }: { value: Dayjs; onChange: (value: Dayjs) => void }) {
  const month = value.month();

  const year = value.year();
  const yearOptions: { value: string; label: string }[] = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    yearOptions.push({ value: i.toString(), label: String(i) });
  }
  return (
    <div className={css.datePickerHeader}>
      <Select
        size="small"
        popupMatchSelectWidth={false}
        onChange={(newYear: number) => {
          const now = value.year(newYear);
          if (onChange) {
            onChange(now);
          }
        }}
        value={year}
        options={yearOptions}
      />
      <Select
        size="small"
        popupMatchSelectWidth={false}
        value={String(month)}
        onChange={(selectedMonth: string) => {
          const newValue = value.month(parseInt(selectedMonth));
          if (onChange) {
            onChange(newValue);
          }
        }}
        options={monthOptions}
      />
    </div>
  );
}

function getDateValue(
  value?: Dayjs,
  relativeValue?: string,
  format = "YYYY-MM-DD",
): string | undefined {
  if (relativeValue && RELATIVE_DATE_OPTIONS[relativeValue]) {
    return RELATIVE_DATE_OPTIONS[relativeValue].label;
  }
  return value?.format(format);
}
