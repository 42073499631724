/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeamManagementUserTeam } from './TeamManagementUserTeam';
import {
    TeamManagementUserTeamFromJSON,
    TeamManagementUserTeamFromJSONTyped,
    TeamManagementUserTeamToJSON,
} from './TeamManagementUserTeam';

/**
 * 
 * @export
 * @interface TeamManagementUser
 */
export interface TeamManagementUser {
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    createdAt: string;
    /**
     * 
     * @type {TeamManagementUserTeam}
     * @memberof TeamManagementUser
     */
    team: TeamManagementUserTeam;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUser
     */
    paymentType: TeamManagementUserPaymentTypeEnum;
}


/**
 * @export
 */
export const TeamManagementUserPaymentTypeEnum = {
    Paid: 'paid',
    Freemium: 'freemium'
} as const;
export type TeamManagementUserPaymentTypeEnum = typeof TeamManagementUserPaymentTypeEnum[keyof typeof TeamManagementUserPaymentTypeEnum];


/**
 * Check if a given object implements the TeamManagementUser interface.
 */
export function instanceOfTeamManagementUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function TeamManagementUserFromJSON(json: any): TeamManagementUser {
    return TeamManagementUserFromJSONTyped(json, false);
}

export function TeamManagementUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamManagementUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'createdAt': json['createdAt'],
        'team': TeamManagementUserTeamFromJSON(json['team']),
        'paymentType': json['paymentType'],
    };
}

export function TeamManagementUserToJSON(value?: TeamManagementUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'createdAt': value.createdAt,
        'team': TeamManagementUserTeamToJSON(value.team),
        'paymentType': value.paymentType,
    };
}

