import React from "react";
import { hot } from "react-hot-loader/root";

import { TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";

import css from "./EmailConfirmationPage.module.scss";

function EmailConfirmationPage() {
  return (
    <div className={css.emailConfirmationPage}>
      <div className={css.innerWrapper}>
        <h1 className={css.heading}>Email confirmed</h1>
        <div className={css.text}>
          Thanks for confirming your email! You can now go ahead and log in and continue setting up
          your account.
        </div>
        <TextLink className={css.loginLink} to="/users/sign_in">
          Go to login
        </TextLink>
      </div>
    </div>
  );
}

export default withAppLayout(hot(EmailConfirmationPage), {
  hideMenuItems: true,
  hideUserItems: true,
  pageName: "Onboarding - Confirmed email",
});
