import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { TeamDetails } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

/**
 * Hook used to switch teams for the current user. User can only switch to a team within their current company
 * @param options
 * @returns
 */
export function useSwitchTeam(options?: UseMutationOptions<void, unknown, TeamDetails, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(
    (teamDetails) => api.switchUserTeam({ switchUserTeamRequest: { teamId: teamDetails.id } }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["teams", "currentTeamInfo"]);

        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
