import * as React from "react";
import { hot } from "react-hot-loader/root";
import { LeftOutlined, LoadingOutlined, WarningTwoTone } from "@ant-design/icons";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { App, Button } from "antd5"; // one of the buttons can probably be converted to a textbutton, chheck styles and upgrade message to use context
import produce from "immer";

import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { candidatesToSubmittedDecisions } from "components/organisation_clean/adapters";
import CleanDuplicatesPage from "components/organisation_clean/CleanDuplicatesPage";
import FindDuplicatesPage from "components/organisation_clean/FindDuplicatesPage";
import ModifyAnchorPage from "components/organisation_clean/ModifyAnchorPage";
import ReviewOrgCleanPage from "components/organisation_clean/ReviewOrgCleanPage";
import SelectAnchorPage from "components/organisation_clean/SelectAnchorPage";
import TextInputModal from "components/organisation_clean/TextInputModal";
import {
  CandidateOperation,
  MultipleEntitiesCandidate,
  OrgToBeCreated,
  OrgWithStats,
  ProcessStages,
  QualifiedCandidate,
  UpdatableAttributes,
  UpdateAttributesChange,
} from "components/organisation_clean/types";
import { validateCleaningProgress } from "components/organisation_clean/validation";
import NavigationFooter from "lib/core_components/NavigationFooter";
import { assertCurrentUser } from "lib/currentUser";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { SubmitOrgCleaningTaskRequestTaskTypeEnum } from "lib/generated/app-api";
import { usePreventNavigation } from "lib/hooks/usePreventNavigation";
import { useOpenApi } from "lib/openApiContext";
import { useDialogManager } from "lib/providers/DialogManager";
import { yellow500 } from "lib/themes/colors";
import { assertDefined, createSetNestedState } from "lib/utils";
import { createAliasDecisions } from "lib/utils/aliasUtils";

import css from "./BuyerCleaningPage.module.scss";

// Currently only using this for the singular buyer cancel & report
function CancelAndReportButton({ onCancelSubmit }: { onCancelSubmit: (note: string) => void }) {
  const dialogManager = useDialogManager();
  return (
    <TextButton
      className={css.cancelAndReport}
      onClick={() =>
        dialogManager.openDialog(TextInputModal, {
          onSubmit: onCancelSubmit,
          modalTitle: "Unsure: Report and move to next buyer",
          title: "Are you sure you want to cancel & report this buyer? ",
          description: "Please submit a note on why you're not sure about this buyer.",
        })
      }
    >
      Unsure: Report &amp; move to next buyer
    </TextButton>
  );
}

export enum Steps {
  SELECT_ANCHOR,
  MODIFY_ANCHOR,
  FIND_DUPLICATES,
  CLEAN_DUPLICATES,
  REVIEW,
}

// Due to typescript's reverse mapping the length of the compiled object is doubled, hence the
// division by 2
const TOTAL_STEPS = Object.values(Steps).length / 2;

type CommonStepState = {
  userGuid: string;
  qualifiedCandidates: Record<string, QualifiedCandidate>;
  newBuyers: OrgToBeCreated[];
};

const useHandleSubmit = (options?: UseMutationOptions<void, unknown, BuyerformState, unknown>) => {
  const api = useOpenApi();

  return useMutation(async (formState: BuyerformState) => {
    const { aliases, anchorBuyer, qualifiedCandidates, step } = formState;
    assertDefined(anchorBuyer);

    const cancelLocation = Steps[step];
    const cancelNote = "SUBMITTED_FOR_REVIEW";

    const aliasDecisions = createAliasDecisions(anchorBuyer.guid, aliases);
    const decisions = [...aliasDecisions, ...candidatesToSubmittedDecisions(qualifiedCandidates)];

    await api.submitOrgCleaningTask({
      submitOrgCleaningTaskRequest: {
        decisions,
        organisationId: anchorBuyer.guid,
        cancelLocation,
        cancelNote,
        taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DeDupeCleaning,
      },
    });
  }, options);
};

const useHandleSubmitAndProcess = (
  options?: UseMutationOptions<void, unknown, BuyerformState, unknown>,
) => {
  const api = useOpenApi();

  return useMutation(async (formState: BuyerformState) => {
    const anchor = formState.anchorBuyer;
    assertDefined(anchor);

    const decisions = candidatesToSubmittedDecisions(formState.qualifiedCandidates);

    await api.submitAndProcessOrgCleaningTask({
      submitAndProcessOrgCleaningTaskRequest: {
        decisions,
        organisationId: anchor.guid,
        taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DeDupeCleaning,
      },
    });
  }, options);
};

type BuyerformState =
  | ({
      step: Steps.SELECT_ANCHOR;
      aliases: string[];
      anchorBuyer: OrgWithStats | undefined;
    } & CommonStepState)
  | ({
      step: Exclude<Steps, Steps.SELECT_ANCHOR>;
      aliases: string[];
      anchorBuyer: OrgWithStats;
    } & CommonStepState);

function BuyerCleaningPage() {
  const { message } = App.useApp();
  const initialFormState: BuyerformState = React.useMemo(
    () => ({
      aliases: [],
      userGuid: assertCurrentUser().guid,
      anchorBuyer: undefined,
      step: Steps.SELECT_ANCHOR,
      qualifiedCandidates: {},
      newBuyers: [],
    }),
    [],
  );

  const [formState, setFormState] = React.useState<BuyerformState>(() => initialFormState);
  // This state keeps track of what index of the multiple entity suspects we're on for the cleaning stage
  const [cleaningIndex, setCleaningIndex] = React.useState<number>(0);
  const candidatesRequiringCleaning: string[] = React.useMemo(() => {
    const arr = [];
    for (const [guid, qs] of Object.entries(formState.qualifiedCandidates)) {
      if (qs.qualification === CandidateOperation.MULTIPLE_ENTITIES) {
        arr.push(guid);
      }
    }
    return arr;
  }, [formState.qualifiedCandidates]);

  usePreventNavigation(
    !!formState.anchorBuyer,
    "Are you sure you want to leave the page? All changes will go unsaved.",
  );

  const currentCandidate = React.useMemo(() => {
    const currentGuid = candidatesRequiringCleaning[cleaningIndex];
    return formState.qualifiedCandidates[currentGuid] as MultipleEntitiesCandidate;
  }, [formState.qualifiedCandidates, cleaningIndex, candidatesRequiringCleaning]);

  const handleChangeStep = React.useCallback((step: Steps) => {
    setFormState(
      produce((form) => {
        form.step = step;
      }),
    );
  }, []);

  // TODO: deprecated - everything will soon require human review
  const { mutate: handleSubmitAndProcess, isLoading: submitAndProcessLoading } =
    useHandleSubmitAndProcess({
      onSuccess: () => {
        void message.success("Thanks for submitting the buyer cleaning task");
        setFormState(initialFormState);
      },
      onError: (e) => {
        const msg = e instanceof Error ? e.message : `${e}`;

        void message.error(msg);
      },
    });
  const { mutate: handleSubmit } = useHandleSubmit({
    onSuccess: () => {
      void message.success("Thanks for submitting the buyer cleaning task");
      setFormState(initialFormState);
    },
    onError: (e) => {
      const msg = e instanceof Error ? e.message : `${e}`;

      void message.error(msg);
    },
  });

  const handleNextCleaning = React.useCallback(() => {
    if (cleaningIndex < candidatesRequiringCleaning.length - 1) {
      setCleaningIndex((old) => old + 1);
    } else {
      handleChangeStep(Steps.REVIEW);
    }
  }, [cleaningIndex, handleChangeStep, candidatesRequiringCleaning]);

  const handlePreviousCleaning = React.useCallback(() => {
    if (cleaningIndex > 0) {
      setCleaningIndex((old) => old - 1);
    }
  }, [cleaningIndex]);

  const setQualifiedCandidates = React.useMemo(
    () => createSetNestedState("qualifiedCandidates", setFormState),
    [],
  );

  const setAliases = React.useCallback((aliases: string[]) => {
    setFormState(
      produce((draftState) => {
        draftState.aliases = aliases;
      }),
    );
  }, []);

  const renderCandidateCancelButton = React.useCallback(
    (candidateGuid: string) => {
      return (
        <CancelAndReportButton
          onCancelSubmit={(note: string) => {
            setQualifiedCandidates(
              produce((draft) => {
                draft[candidateGuid].note = note;
                draft[candidateGuid].processStage = ProcessStages.CANCELLED;
              }),
            );
            handleNextCleaning();
          }}
        />
      );
    },
    [setQualifiedCandidates, handleNextCleaning],
  );

  const addToQualifiedCandidates = React.useCallback(
    (buyer: OrgWithStats, q: CandidateOperation, note?: string | undefined) => {
      setQualifiedCandidates(
        produce((qualifiedCandidates) => {
          if (q === CandidateOperation.MULTIPLE_ENTITIES) {
            qualifiedCandidates[buyer.guid] = {
              buyer,
              qualification: CandidateOperation.MULTIPLE_ENTITIES,
              processStage: ProcessStages.REQUIRES_CLEAN,
              note,
            };
          } else if (
            // anything else other than anchor updates - they are handled separately
            q !== CandidateOperation.UPDATE_ATTRIBUTES
          ) {
            qualifiedCandidates[buyer.guid] = {
              buyer,
              qualification: q,
              processStage: ProcessStages.DONE,
              note,
            };
          }
        }),
      );
      // If a user goes back to the qualification screen and re-qualifies already qualified candidates
      // After having been through a few multi-entity ones, we need to reset the index. Otherwise it breaks if there
      // are no longer as many buyers requiring cleaning
      setCleaningIndex(0);
    },
    [setQualifiedCandidates],
  );

  const onAnchorUpdate = (newValues: UpdatableAttributes) => {
    const buyer = formState.anchorBuyer;
    if (!buyer) return;

    setQualifiedCandidates(
      produce((qualifiedCandidates) => {
        qualifiedCandidates[buyer.guid] = {
          buyer: buyer,
          qualification: CandidateOperation.UPDATE_ATTRIBUTES,
          processStage: ProcessStages.REQUIRES_CLEAN,
          newValues: {
            ...(qualifiedCandidates[buyer.guid] as UpdateAttributesChange | undefined)?.newValues,
            ...newValues,
          },
        };
      }),
    );
  };

  const handleUpdateProcessStage = React.useCallback(
    (guid: string, processStage: ProcessStages) => {
      setQualifiedCandidates(
        produce((qualifiedCandidates) => {
          qualifiedCandidates[guid].processStage = processStage;
        }),
      );
    },
    [setQualifiedCandidates],
  );

  const handleUnmarkAsCancelled = React.useCallback(
    (guid: string) => {
      setQualifiedCandidates(
        produce((qualifiedCandidates) => {
          const candidate = qualifiedCandidates[guid];
          candidate.processStage = ProcessStages.REQUIRES_CLEAN;
          candidate.note = undefined;
        }),
      );
    },
    [setQualifiedCandidates],
  );

  const updateAnchor = React.useCallback((b: OrgWithStats) => {
    setFormState(
      produce((draftState) => {
        draftState.anchorBuyer = b;
      }),
    );
  }, []);

  const modifyNewBuyers = React.useMemo(() => createSetNestedState("newBuyers", setFormState), []);
  // TODO WORK ON VERIFICATION
  // Verifies if the current suspect is sufficiently done
  React.useEffect(() => {
    if (candidatesRequiringCleaning.length < 1 || !currentCandidate) {
      return;
    } else {
      handleUpdateProcessStage(
        currentCandidate.buyer.guid,
        validateCleaningProgress(currentCandidate),
      );
    }
  }, [currentCandidate, candidatesRequiringCleaning, handleUpdateProcessStage]);

  const currentCandidateDone = React.useMemo(() => {
    if (!currentCandidate) return false;
    if (
      currentCandidate.processStage === ProcessStages.DONE ||
      (currentCandidate.processStage === ProcessStages.CANCELLED && currentCandidate?.note)
    ) {
      return true;
    } else return false;
  }, [currentCandidate]);

  switch (formState.step) {
    case Steps.SELECT_ANCHOR:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <SelectAnchorPage
              onAnchorChange={(b) => {
                if (formState.anchorBuyer) {
                  setFormState(initialFormState);
                  setCleaningIndex(0);
                }
                updateAnchor(b);
              }}
              anchorOrg={formState.anchorBuyer}
              orgPrimaryRole="Buyer"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => handleChangeStep(Steps.MODIFY_ANCHOR)}
            nextButtonLabel="Next"
            nextDisabled={!formState.anchorBuyer}
            currentStep={Steps.SELECT_ANCHOR}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
    case Steps.MODIFY_ANCHOR:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <ModifyAnchorPage
              anchorOrg={formState.anchorBuyer}
              onAnchorUpdate={onAnchorUpdate}
              referenceOrgConnectors={["oscar"]}
              orgPrimaryRole="Buyer"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => handleChangeStep(Steps.FIND_DUPLICATES)}
            onBack={() => handleChangeStep(Steps.SELECT_ANCHOR)}
            backButtonLabel="Back"
            nextButtonLabel="Next"
            nextDisabled={!formState.anchorBuyer}
            currentStep={Steps.MODIFY_ANCHOR}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );

    case Steps.FIND_DUPLICATES:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <FindDuplicatesPage
              qualifiedCandidates={Object.fromEntries(
                Object.entries(formState.qualifiedCandidates).filter(
                  ([_guid, candidate]) =>
                    candidate.qualification !== CandidateOperation.UPDATE_ATTRIBUTES,
                ),
              )}
              onCandidateChange={addToQualifiedCandidates}
              anchorOrg={formState.anchorBuyer}
              orgPrimaryRole="Buyer"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => {
              const nextStep =
                candidatesRequiringCleaning.length === 0 ? Steps.REVIEW : Steps.CLEAN_DUPLICATES;
              handleChangeStep(nextStep);
            }}
            onBack={() => handleChangeStep(Steps.MODIFY_ANCHOR)}
            nextButtonLabel="Next"
            backButtonLabel="Back"
            currentStep={Steps.FIND_DUPLICATES}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
    case Steps.CLEAN_DUPLICATES:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            {currentCandidate.processStage === ProcessStages.CANCELLED && (
              <div className={css.cancelledOverlay}>
                <div className={css.cancelContent}>
                  <p>
                    <WarningTwoTone twoToneColor={yellow500} style={{ marginRight: "8px" }} />
                    <b>{currentCandidate.buyer.name}</b> was marked as cancelled with the note "
                    {currentCandidate.note}".
                    <br />
                    If you wish to unmark it as cancelled and continue, click below.
                    <br />
                    Otherwise, continue to navigate to other candidates using the buttons at the
                    bottom of the page.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => handleUnmarkAsCancelled(currentCandidate.buyer.guid)}
                  >
                    Continue cleaning this buyer
                  </Button>
                </div>
              </div>
            )}
            <CleanDuplicatesPage
              anchorOrg={formState.anchorBuyer}
              candidate={currentCandidate}
              totalCandidates={candidatesRequiringCleaning.length}
              currentCandidateNumber={cleaningIndex + 1}
              modifyNewBuyers={modifyNewBuyers}
              handleUpdateCandidate={setQualifiedCandidates}
              orgPrimaryRole="Buyer"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={handleNextCleaning}
            onBack={() => handleChangeStep(Steps.FIND_DUPLICATES)}
            nextButtonLabel="Submit & move to next buyer"
            backButtonLabel="Back to finding candidates"
            nextDisabled={!currentCandidateDone}
            renderAdditionalCTARight={() =>
              renderCandidateCancelButton(currentCandidate.buyer.guid)
            }
            renderAdditionalCTALeft={
              cleaningIndex > 0
                ? () => (
                    <Button type="link" onClick={handlePreviousCleaning}>
                      <LeftOutlined /> Previous cleaning candidate
                    </Button>
                  )
                : undefined
            }
            currentStep={Steps.CLEAN_DUPLICATES}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
    case Steps.REVIEW:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <ReviewOrgCleanPage
              pageTitle="Step 4. Review and submit"
              anchorOrg={formState.anchorBuyer}
              qualifiedCandidates={formState.qualifiedCandidates}
              formStateStringified={JSON.stringify(formState)}
              aliases={formState.aliases}
              setAliases={setAliases}
              orgPrimaryRole="Buyer"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={async () => {
              await handleSubmit(formState);
            }}
            onBack={() => {
              const backStep =
                candidatesRequiringCleaning.length === 0
                  ? Steps.FIND_DUPLICATES
                  : Steps.CLEAN_DUPLICATES;
              handleChangeStep(backStep);
            }}
            nextButtonLabel="Submit for review"
            backButtonLabel="Back"
            renderAdditionalCTARight={() => (
              <>
                {FeatureToggles.isEnabled(Feature.SUBMIT_BUYER_CLEANING) && (
                  <TextButton
                    className={css.submitNow}
                    onClick={async () => {
                      await handleSubmitAndProcess(formState);
                    }}
                  >
                    Submit &amp; make changes now {submitAndProcessLoading && <LoadingOutlined />}
                  </TextButton>
                )}
              </>
            )}
            nextDisabled={
              Object.keys(formState.qualifiedCandidates).length < 1 && formState.aliases.length < 1
            }
            currentStep={Steps.REVIEW}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
  }
}

export default hot(
  withAppLayout(BuyerCleaningPage, {
    pageName: "Buyer Cleaning",
  }),
);
