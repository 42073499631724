import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";

import {
  AllBuyersStatsRequestAllBuyersStatsFilters,
  SupplierSearchRequest,
} from "lib/generated/app-api";
import { SearchFrameworkOrganisationsResponse } from "lib/generated/data-svc";
import { useOpenApi } from "lib/openApiContext";
import { PaginationState, SortState } from "lib/search/types";
import { OrganisationRole } from "lib/types/graphQLEnums";
import { assert } from "lib/utils";
import { useSuppliers } from "../suppliers/useSuppliers";
import { useAllBuyerStats } from "../useAllBuyerSearch";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";
import { useDescribeFramework } from "./useDescribeFramework";

type SearchFrameworkOrganisationsQueryOptions = Pick<
  UseQueryOptions<
    SearchFrameworkOrganisationsResult,
    unknown,
    SearchFrameworkOrganisationsResult,
    []
  >,
  "enabled"
>;

/**
 * Optimally all of this would be implemented in the backend
 * but currently some of the logic is in Rails and some lives in data-service.
 *
 * There are some important design decisions we should think through
 * before adding this to the graphql schema,
 * so it's less impactful to perform the search on the client
 * for now and then later clean it up once we're happy with how to model
 * organisation (or framework organisation) search in GraphQL.
 */

const CLIENT_SIDE_SORT_FIELDS = ["frameworkCallOffs", "frameworkLastActivity", "frameworkLots"];

export type SearchFrameworkOrganisationsResult = {
  organisations: SearchFrameworkOrganisationsResponse["organisations"];
  totalCount: number;
};

/**
 * Fetches buyers  with specific roles in a framework
 * based on supplied filters.
 *
 * Optimally there would be a single codepath for buyers & suppliers,
 * but the filters are not compatible and for now we have to use
 * two different search APIs.
 *
 * Currently this is implemented in the frontend because
 * we need to fetch data from the existing Rails buyer search API
 * and pass it to a data-service API that adds framework information.
 */
export const useSearchFrameworkBuyers = (
  frameworkId: string,
  roles: OrganisationRole[],
  filters: AllBuyersStatsRequestAllBuyersStatsFilters,
  sortState: SortState,
  paginationState: PaginationState,
  options?: SearchFrameworkOrganisationsQueryOptions,
): UseQueryResult<SearchFrameworkOrganisationsResult> => {
  const clientSideSort = CLIENT_SIDE_SORT_FIELDS.includes(sortState.field);

  const { data: frameworkData } = useDescribeFramework(frameworkId);

  const frameworkBuyerGuids = frameworkData?.buyers?.map((b) => b.id);

  /**
   * Fetch all framework buyers matching the filters.
   * We use the specified sort unless it's a field
   * we can only sort by in the frontend - then we use the default,
   * so that in most cases we'll have the data already fetched!
   */
  const { data: filteredBuyers } = useAllBuyerStats(
    {
      offset: 0,
      sort: clientSideSort ? "buyer_name" : sortState.field,
      sortOrder: clientSideSort ? "ASC" : sortState.order,
      filters,
      buyerGuids: frameworkBuyerGuids,
    },
    {
      ...options,
      enabled: !!frameworkData,
    },
  );

  /**
   * Fetch all information about all framework orgs in "buyer" roles.
   * We do this once because the data is not affected by filters and is fairly
   * cheap to calculate. Then we can reuse it when filters/sorting/pagination changes.
   */
  const { data: frameworkOrganisations } = useSearchFrameworkOrganisations(
    frameworkId,
    frameworkBuyerGuids,
    [
      OrganisationRole.TransactingBuyer,
      OrganisationRole.Provider,
      OrganisationRole.ContractingAuthority,
    ],
    options,
  );

  /**
   * Combine the filtered buyers with framework data
   * and perform sorting & pagination as needed.
   */
  return useQuery(
    ["framework_buyers", filteredBuyers, frameworkOrganisations, sortState, paginationState],
    () => {
      assert(frameworkOrganisations);
      assert(filteredBuyers);

      const filteredBuyerGuids = filteredBuyers?.signalStats.map((result) => result.buyerGuid);

      let organisations = frameworkOrganisations.organisations;

      if (clientSideSort) {
        switch (sortState.field) {
          case "frameworkCallOffs":
            organisations = _.sortBy(organisations, [
              (org) => org.callOffs?.count || 0,
              (org) => org.callOffs?.averageAmount || 0,
            ]);
            break;

          case "frameworkLastActivity":
            {
              const OLDEST_DATE = new Date(1900, 1, 1);
              organisations = _.sortBy(
                organisations,
                (org) => org.callOffs?.lastActivity || OLDEST_DATE,
              );
            }
            break;

          default:
            throw new Error(`Unsupported sort field ${sortState.field}`);
        }

        if (sortState.order === "DESC") {
          organisations.reverse();
        }
      } else {
        const orgById = new Map(organisations.map((org) => [org.id, org]));
        organisations = filteredBuyerGuids.map((orgId) => {
          const org = orgById.get(orgId);
          assert(org);
          return org;
        });
      }

      const offset = (paginationState.current - 1) * paginationState.pageSize;
      const limit = paginationState.pageSize;
      organisations = organisations.slice(offset, offset + limit);

      return {
        organisations,
        totalCount: filteredBuyers.totalCount,
      };
    },
    {
      ...options,
      enabled: !!(filteredBuyers && frameworkOrganisations),
    },
  );
};

type SupplierSearchFilters = Pick<SupplierSearchRequest, "text" | "isSme" | "guid" | "country">;

export const useSearchFrameworkSuppliers = (
  frameworkId: string,
  filters: SupplierSearchFilters,
  sortState: SortState,
  paginationState: PaginationState,
  options?: SearchFrameworkOrganisationsQueryOptions,
) => {
  const clientSideSort = CLIENT_SIDE_SORT_FIELDS.includes(sortState.field);

  const { data: frameworkData } = useDescribeFramework(frameworkId);

  const frameworkSupplierGuids = frameworkData?.suppliers?.map((b) => b.id);

  const { data: filteredSuppliers } = useSuppliers(
    {
      ...filters,
      guid: frameworkSupplierGuids,
      // offset: 0,
      sort: clientSideSort ? undefined : sortState.field,
      sortOrder: clientSideSort ? undefined : sortState.order,
    },
    {
      ...options,
      enabled: options?.enabled == false ? false : !!frameworkData,
    },
  );

  const { data: frameworkOrganisations } = useSearchFrameworkOrganisations(
    frameworkId,
    frameworkSupplierGuids,
    [OrganisationRole.Supplier],
    options,
  );

  return useQuery(
    ["frameworkSuppliers", filteredSuppliers, frameworkOrganisations, sortState, paginationState],
    () => {
      assert(frameworkOrganisations);
      assert(filteredSuppliers);

      return {
        organisations: frameworkOrganisations.organisations,
        totalCount: filteredSuppliers.pagingInfo.totalResults,
      };
    },
    {
      ...options,
      enabled: !!(filteredSuppliers && frameworkOrganisations),
    },
  );
};

const useSearchFrameworkOrganisations = (
  frameworkId: string,
  organisationIds: string[] | undefined,
  roles: OrganisationRole[],
  options?: SearchFrameworkOrganisationsQueryOptions,
) => {
  const api = useOpenApi();
  return useQuery(
    ["framework_organisations", frameworkId, organisationIds],
    async () => {
      assert(organisationIds);

      const resp = await api.searchFrameworkOrganisations({
        body: {
          frameworkId: frameworkId,
          organisation: {
            id: organisationIds,
            roles,
          },
        },
      });

      // the front end code has access to the type definition (so we can have some typed results) -
      // but runtime access is disabled for non admins
      return resp as SearchFrameworkOrganisationsResponse;
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
      enabled: !!organisationIds,
    },
  );
};
