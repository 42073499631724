import React from "react";
import { useForm } from "react-hook-form";
import { Button, Progress } from "antd5";

import { Select } from "components/form_components/Inputs";
import { fieldInteractionEvent } from "./tracking";

import css from "./ExperienceSelection.module.scss";

type ExperienceSelectionProps = {
  onSubmit: (value: string) => void;
  companyName?: string | null;
};

function helperText(selectedExperience: string | undefined) {
  switch (selectedExperience) {
    case "none":
      return (
        <p className={css.helperText}>
          Great! We can help people wherever they are in their journey.
        </p>
      );
    case "limited":
      return <p className={css.helperText}>Some pub-sec teams see 88% YOY growth after joining.</p>;
    case "familiar":
      return (
        <p className={css.helperText}>Nice! Stotles can help supercharge your current win rate.</p>
      );
    case "mature":
      return (
        <p className={css.helperText}>
          You’re a pro! We can help you boost your win rate even further.
        </p>
      );
    default:
      return null;
  }
}

function ExperienceSelection({ onSubmit, companyName }: ExperienceSelectionProps) {
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<{ supplierOrBuyer: string; experience?: string }>({
    defaultValues: { supplierOrBuyer: "Supplier", experience: undefined },
  });
  const isSupplier = watch("supplierOrBuyer") === "Supplier";
  const selectedExperienceValue = watch("experience");

  return (
    <form
      onSubmit={handleSubmit((value) => {
        onSubmit(value.supplierOrBuyer === "Buyer" ? "buyer" : value.experience || "");
      })}
    >
      <div className={css.experienceContainer}>
        <div className={css.progressBar}>
          <Progress percent={50} showInfo={false} />
        </div>
        <div className={css.headerText}>
          <h1 className={css.title}>Which best describes you?</h1>
          <p className={css.subTitle}>We personalise your app experience based on who you are.</p>
        </div>
        <div className={css.radioOption}>
          <label className={css.radioLabel}>
            <input
              type="radio"
              value={"Supplier"}
              {...register("supplierOrBuyer", {})}
              className={css.radioInput}
            />
            Supplier
          </label>
          <p className={css.subRadioText}>
            Find upcoming notices, track competitors and find contacts.
          </p>
          {isSupplier && (
            <div className={css.selectSection}>
              <Select
                placeholder="Select experience level"
                options={[
                  { label: "New to public sector", value: "none" },
                  { label: "1-9 contracts won", value: "limited" },
                  { label: "10-49 contracts won", value: "familiar" },
                  { label: "50+ contracts won", value: "mature" },
                ]}
                name={"experience"}
                label={
                  companyName
                    ? "How many public sector contracts has " + companyName + " won?"
                    : "How many public sector contracts has your company won?"
                }
                control={control}
                rules={{
                  required: true,
                }}
                onChange={(value) => {
                  fieldInteractionEvent(value, "experience");
                }}
              />
              {helperText(selectedExperienceValue)}
            </div>
          )}
        </div>
        <div className={css.radioOption}>
          <label>
            <input
              type="radio"
              value="Buyer"
              {...register("supplierOrBuyer", {
                required: true,
                onChange: (value) => {
                  fieldInteractionEvent("true", value.target.value);
                  setValue("experience", undefined);
                },
              })}
              className={css.radioInput}
            />
            Buyer
          </label>
          <p className={css.subRadioText}>Research suppliers and view spend data.</p>
        </div>
        <Button type="primary" disabled={!isValid} htmlType="submit">
          Continue
        </Button>
      </div>
    </form>
  );
}

export default ExperienceSelection;
