import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetLeadSubscriptionsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = [string];

export function useGetLeadSubscriptions(
  options?: UseQueryOptions<
    GetLeadSubscriptionsResponse,
    unknown,
    GetLeadSubscriptionsResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(["leadSubscriptions"], async () => await api.getLeadSubscriptions(), {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });
}
