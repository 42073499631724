import React from "react";
import { Empty } from "antd5";

import { TextLink } from "components/actions/Links";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import SignalsContainer from "components/tags/SignalsContainer";
import { getCompetitorDestination, getPartnerDestination } from "lib/appDestinations";
import { numberSort, stringSort } from "lib/columnSort";
import { ColumnHeader } from "lib/core_components/ColumnHeader";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import {
  ColumnSizeConfig,
  ColumnType,
  commonTableColumns,
} from "lib/core_components/Table/ColumnTypes";
import { IN_ROW_STAT_LINK, InRowStatText } from "lib/core_components/Table/Table";
import { SupplierStatsSummary } from "lib/generated/app-api";
import { useSupplierStatsList } from "lib/hooks/api/suppliers/useSupplierStatsList";
import { SignalCategory as AllSignalCategories, SignalCategory } from "lib/StotlesApi";
import { tagColourFromSignal } from "lib/tagUtils";
import { EventNames, TrackingProvider } from "lib/tracking";
import { capitalize } from "lib/utils";

import css from "./SupplierSummary.module.scss";

type SupplierSummaryProps = {
  signalCategory: SignalCategory;
};

const BASIC_SIZE: ColumnSizeConfig = {
  small: 150,
  medium: 150,
  large: 180,
  xlarge: 200,
};

export function SupplierSummary({ signalCategory }: SupplierSummaryProps) {
  const { data, isLoading, isError } = useSupplierStatsList({ category: signalCategory });
  const columns = React.useMemo(() => getColumns(signalCategory), [signalCategory]);

  const onRow = React.useMemo(() => {
    const destinationGetter =
      signalCategory === SignalCategory.COMPETITOR
        ? getCompetitorDestination
        : getPartnerDestination;
    // If the signal doesn't have any records, we don't allow navigating to it
    const restrictionCheck = (r: SupplierStatsSummary) => r.allRecordsCount === 0;
    return createHandleTableRowClickthrough<SupplierStatsSummary>(
      (r) => destinationGetter(r.signalName),
      undefined,
      undefined,
      restrictionCheck,
    );
  }, [signalCategory]);

  if (isLoading) {
    return <SkeletonSupplierSummaryTable signalCategory={signalCategory} />;
  }

  return (
    <DetailsPage>
      <DetailsHeader borderBottom={false}>
        <h1 className={css.pageTitle}>My {signalCategory.toLocaleLowerCase()}</h1>
      </DetailsHeader>
      {!data?.suppliers.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null}>
          <h2>No {signalCategory.toLowerCase()} yet</h2>
          <p className={css.noResultsMessage}>
            When you have marked a{" "}
            {signalCategory === SignalCategory.COMPETITOR ? "competitor" : "partner"} they will
            appear here. You can add {signalCategory.toLowerCase()} <br /> via your settings{" "}
            <TextLink to="/account-management/feed-settings">here</TextLink>.
          </p>
        </Empty>
      ) : (
        <TrackingProvider data={{ "Context source": "In-row" }}>
          <DetailsContent>
            <Table
              columns={columns}
              dataSource={data.suppliers}
              pagination={false}
              rowKey={(r) => r.signalName}
              onRow={onRow}
              isError={isError}
            />
          </DetailsContent>
        </TrackingProvider>
      )}
    </DetailsPage>
  );
}

function SkeletonSupplierSummaryTable({ signalCategory }: SupplierSummaryProps) {
  const columns = React.useMemo(() => getColumns(signalCategory), [signalCategory]);
  return <SkeletonTable columns={columns} pageSize={20} active />;
}

function getColumns(signalCategory: SignalCategory): ColumnType<SupplierStatsSummary>[] {
  const singularType = signalCategory === SignalCategory.COMPETITOR ? "competitor" : "partner";
  const getDestination =
    signalCategory === SignalCategory.COMPETITOR ? getCompetitorDestination : getPartnerDestination;
  let customColumn: ColumnType<SupplierStatsSummary>;

  if (signalCategory === SignalCategory.PARTNER) {
    customColumn = {
      key: "matchingNotices",
      title: <ColumnHeader header="Notices" subHeader="matching keywords" />,
      render: (_, r) =>
        r.keywordRecordsCount > 0 ? (
          <TextLink
            to={`${getDestination(r.signalName)}/?keywords=__SELECT_ALL__`}
            className={IN_ROW_STAT_LINK}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": `Records matching partner & keyword`,
            }}
          >
            {r.keywordRecordsCount}
          </TextLink>
        ) : (
          <InRowStatText>{r.keywordRecordsCount}</InRowStatText>
        ),
      dataIndex: "keywordRecordsCount",
      sorter: numberSort((b: SupplierStatsSummary) => b.keywordRecordsCount),
      sortDirections: ["descend", "ascend", "descend"],
      sizeConfig: BASIC_SIZE,
    };
  } else {
    customColumn = {
      key: "contractExpiries",
      title: <ColumnHeader header="Contract expiries" subHeader="with competitor signal" />,
      render: (_, r) =>
        r.expiriesCount > 0 ? (
          <TextLink
            to={`${getDestination(r.signalName)}/upcoming_expiries`}
            className={IN_ROW_STAT_LINK}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": `Upcoming expiries matching competitor`,
            }}
          >
            {r.expiriesCount}
          </TextLink>
        ) : (
          <InRowStatText>{r.expiriesCount}</InRowStatText>
        ),
      dataIndex: "expiriesCount",
      sorter: numberSort((b: SupplierStatsSummary) => b.expiriesCount),
      sortDirections: ["descend", "ascend", "descend"],
      sizeConfig: BASIC_SIZE,
    };
  }

  return [
    {
      ...commonTableColumns.titleColumn,
      key: "signalName",
      title: <ColumnHeader header={capitalize(singularType)} subHeader="and its latest activity" />,
      render: (_, r) =>
        r.allRecordsCount === 0 ? (
          <p>{r.signalName}</p>
        ) : (
          <div>
            <TextLink className={css.titleLink} to={`${getDestination(r.signalName)}`}>
              {r.signalName}
            </TextLink>
            {r.latestActivity && <p className={css.signalLatestActivity}>{r.latestActivity}</p>}
          </div>
        ),
      dataIndex: "signalName",
      sorter: stringSort((b: SupplierStatsSummary) => b.signalName),
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: "savedBuyers",
      title: <ColumnHeader header="Saved buyers" subHeader={`connected with ${singularType}`} />,
      render: (_, r) =>
        r.keyAccountsCount > 0 ? (
          <TextLink
            to={`${getDestination(r.signalName)}/buyers/saved_buyers`}
            className={IN_ROW_STAT_LINK}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": `Saved buyers connected to ${singularType}`,
            }}
          >
            {r.keyAccountsCount}
          </TextLink>
        ) : (
          <InRowStatText>{r.keyAccountsCount}</InRowStatText>
        ),
      dataIndex: "keyAccountsCount",
      sorter: numberSort((b: SupplierStatsSummary) => b.keyAccountsCount),
      sortDirections: ["descend", "ascend", "descend"],
      sizeConfig: BASIC_SIZE,
    },
    {
      key: "notices",
      title: <ColumnHeader header="Notices" subHeader={`with ${singularType} signal`} />,
      render: (_, r) =>
        r.allRecordsCount > 0 ? (
          <TextLink
            to={getDestination(r.signalName)}
            className={IN_ROW_STAT_LINK}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": `Records matching ${singularType}`,
            }}
          >
            {r.allRecordsCount}
          </TextLink>
        ) : (
          <InRowStatText>{r.allRecordsCount}</InRowStatText>
        ),
      dataIndex: "allRecordsCount",
      sorter: numberSort((b: SupplierStatsSummary) => b.allRecordsCount),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sizeConfig: BASIC_SIZE,
    },
    customColumn,
    {
      key: "signals",
      title: <ColumnHeader header="Signals" subHeader={`connected to this ${singularType}`} />,
      dataIndex: "signals",
      render: (_, r) => {
        const signals = r.signals
          .filter((x) => x.category !== signalCategory)
          .map((s) => ({
            ...s,
            colour: tagColourFromSignal(s.category),
          }));
        return (
          <SignalsContainer
            signals={signals}
            maxSignals={2}
            redactedSignalCategories={[AllSignalCategories.COMPETITOR, AllSignalCategories.PARTNER]}
            requiredDataType="SUPPLIERS"
            contextSource="In-row"
          />
        );
      },
      sizeConfig: {
        small: 150,
        medium: 200,
        large: 250,
        xlarge: 300,
      },
    },
  ];
}
