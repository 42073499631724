import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { FileData, UploadDocumentsFormFields } from "components/documents/UploadDocuments";
import { getCSRFToken } from "lib/utils";

export type FileDataType = Omit<FileData, "publishDate"> & {
  publishDate?: string;
};

type Params = {
  fileId: number;
  userId: string;
  fileData: FileDataType;
  formInput: UploadDocumentsFormFields;
};

export function useUploadFileDocument(
  options?: UseMutationOptions<AxiosResponse, unknown, Params, unknown>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileData, formInput, userId }: Params) => {
      const headers = {
        "X-CSRF-Token": getCSRFToken() ?? "invalid",
      };

      const formData = new FormData();

      const file = fileData.file[0];
      formData.append("file", file, file.name);

      formData.append("createdBy", userId);
      formData.append("category", formInput.category);

      if (fileData.publishDate) {
        formData.append("publishedAt", fileData.publishDate);
      }

      formInput.organisationIds.forEach((orgId: string) => {
        formData.append("organisations[]", orgId);
      });

      if (formInput.sourceUrl) {
        formData.append("sourceUrl", formInput.sourceUrl);
      }

      if (formInput.alternativeSource) {
        formData.append("alternativeSource", formInput.alternativeSource);
      }

      if (fileData.title) {
        formData.append("title", fileData.title);
      }

      if (fileData.description && fileData.description.length > 0) {
        formData.append("description", fileData.description);
      }

      const response = await axios.post("/api/docs/file-upload", formData, {
        headers: headers,
      });

      return response;
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["documentsAdmin"]);
        void queryClient.invalidateQueries(["documents"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
