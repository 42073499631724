import { useQueryClient } from "@tanstack/react-query";

import { questionAnswered } from "components/buyer_details/question_panel/tracking";
import { graphql } from "lib/generated/app-service-gql";
import {
  Question,
  QuestionAnsweredSubscription,
  QuestionAnsweredSubscriptionVariables,
} from "lib/generated/app-service-gql/graphql";
import { useTracking } from "lib/tracking";
import { useSubscription } from "../useGraphQlClient";

// adding this so it generates the types
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _questionAnswered = graphql(`
  subscription questionAnswered($id: String!) {
    questionAnswered(id: $id) {
      id
      question
      answer
      isComplete
      referenceId
    }
  }
`);

export function useQuestionAnswered(orgName: string, questionId?: string, enabled = true) {
  const { logEvents } = useTracking();
  const client = useQueryClient();

  const { data, ...rest } = useSubscription<
    QuestionAnsweredSubscription,
    QuestionAnsweredSubscriptionVariables
  >(
    `
subscription questionAnswered($id: String!) {
  questionAnswered(id: $id) {
    id
    question
    answer
    isComplete
    referenceId
  }
}
`,
    { id: questionId || "" },
    {
      enabled: !!questionId && enabled,
      onData: (data) => {
        client.setQueryData(
          ["questions", { referenceId: data.questionAnswered.referenceId }],
          (oldData: { getQuestions: Question[] } | undefined) => {
            if (data.questionAnswered.isComplete && oldData) {
              const updatedData = [
                {
                  ...data.questionAnswered,
                  responseReceivedAt: new Date().toISOString(),
                },
                ...oldData.getQuestions,
              ];
              logEvents(
                questionAnswered(
                  data.questionAnswered.question,
                  data.questionAnswered.id,
                  data.questionAnswered.answer || "",
                  orgName,
                ),
              );
              return {
                getQuestions: updatedData,
              };
            } else {
              return oldData;
            }
          },
        );
      },
    },
  );

  return { data: data?.questionAnswered, ...rest };
}
