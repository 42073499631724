import React from "react";
import Icon from "@ant-design/icons";

import { ProviderTypes } from "lib/generated/integration-api/models/ProviderTypes";
import Hubspot from "lib/icons/Hubspot";
import Salesforce from "lib/icons/Salesforce";
import { PROVIDER_METADATA, ProviderMetadata } from "./providerMetadata";

export function getIntegrationIcon(providerType: ProviderTypes, color?: string): JSX.Element {
  switch (providerType) {
    case ProviderTypes.Hubspot:
      return (
        <Icon>
          <Hubspot color={color} />
        </Icon>
      );
    case ProviderTypes.Salesforce:
      return (
        <Icon>
          <Salesforce color={color} />
        </Icon>
      );
    default:
      throw new Error(`Unsupported integration ${providerType}.`);
  }
}

export function getProviderMetadata(providerType: ProviderTypes): ProviderMetadata {
  return PROVIDER_METADATA[providerType];
}

export const friendlyProviderName: Record<ProviderTypes, string> = {
  [ProviderTypes.Hubspot]: "HubSpot",
  [ProviderTypes.Salesforce]: "Salesforce",
};

export function getNoticeHeaderCopy(providerType: ProviderTypes, entityName: string) {
  const headerCopy = PROVIDER_METADATA[providerType].createNoticeModal[entityName];
  if (!headerCopy) throw `Unsupported entity name ${entityName} for provider ${providerType}`;
  else return headerCopy;
}
