import * as React from "react";
import { Tooltip } from "antd5";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import { StageIconFull } from "lib/icons/StageIcon";
import {
  blue600,
  borderGrey,
  openGreen,
  purple,
  red600,
  red800,
  yellow500,
  yellow600,
} from "lib/themes/colors";
import { SearchRequestStages as FrameworkStages } from "lib/types/graphQLEnums";

import css from "./FrameworkStage.module.scss";

type StageConfig = {
  color: string;
  order: number;
  title: string;
  tooltip?: string;
};

export const FRAMEWORK_STAGE_CONFIG: Record<string, StageConfig> = {
  [FrameworkStages.Stale]: {
    title: "Stale pre-tender",
    color: yellow500,
    tooltip:
      "This framework is not yet open for suppliers to apply and the pre-tender notice is at least 3 months old",
    order: 1,
  },
  [FrameworkStages.Upcoming]: {
    title: "Pre-tender",
    color: yellow600,
    tooltip: "This framework is not yet open for suppliers to apply",
    order: 2,
  },
  [FrameworkStages.Tendering]: {
    title: "Open",
    color: openGreen,
    tooltip: "This framework is open for suppliers to apply",
    order: 3,
  },
  [FrameworkStages.Closed]: {
    title: "Closed",
    color: red600,
    tooltip: "This framework is closed for suppliers to apply, but has not yet been awarded",
    order: 4,
  },
  [FrameworkStages.Awarded]: {
    title: "Awarded",
    color: blue600,
    tooltip:
      "Suppliers have been awarded to this framework, but the contracts within have not started yet",
    order: 5,
  },
  [FrameworkStages.Live]: {
    title: "Live",
    color: purple,
    tooltip: "Suppliers have been awarded to this framework and the contracts within have started",
    order: 6,
  },
  [FrameworkStages.Expired]: {
    title: "Expired",
    color: borderGrey,
    tooltip: "Suppliers are no longer being awareded contracts through this framework",
    order: 7,
  },
  [FrameworkStages.Cancelled]: {
    title: "Cancelled",
    tooltip: "This framework has been cancelled",
    color: red800,
    order: 8,
  },
};

export default function FrameworkStage({
  stage,
  className,
  tooltipPlacement,
}: {
  stage?: string;
  className?: string;
  tooltipPlacement?: TooltipProps["placement"];
}): JSX.Element | null {
  if (!stage) return null;

  const stageConfig = FRAMEWORK_STAGE_CONFIG[stage as FrameworkStages];

  return (
    <Tooltip
      placement={tooltipPlacement}
      overlayClassName={css.stageTooltip}
      title={stageConfig.tooltip ?? "Framework documents with an unknown close date"}
    >
      <span className={classnames(css.frameworkStage, className)}>
        <StageIconFull fill={stageConfig.color} size={8} /> {stageConfig.title ?? "Unknown"}
      </span>
    </Tooltip>
  );
}
