import React from "react";
import { Control } from "react-hook-form";

import { Checkbox, DateFilter, DateRange } from "../../form_components/Inputs";
import { FrameworkFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface ContractDatesFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
  hideNullsWhenSet: (fieldName: "closeDate" | "startDate" | "endDate", value: DateFilter) => void;
}

function ContractDatesFilterContent({
  control,
  hideNullsWhenSet,
}: ContractDatesFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="ContractDates filters">
      <div>
        <DateRange
          name="closeDate.filter"
          control={control}
          label="Close date"
          onChange={(v) => hideNullsWhenSet("closeDate", v)}
        />
        <Checkbox
          label=""
          name="closeDate.hideNulls"
          control={control}
          fieldLabel="Hide frameworks without close dates"
        />
      </div>
      <div>
        <DateRange
          name="startDate.filter"
          control={control}
          label="Start date"
          onChange={(v) => hideNullsWhenSet("startDate", v)}
        />
        <Checkbox
          label=""
          name="startDate.hideNulls"
          control={control}
          fieldLabel="Hide frameworks without start dates"
        />
      </div>
      <div>
        <DateRange
          name="endDate.filter"
          control={control}
          label="End date"
          onChange={(v) => hideNullsWhenSet("endDate", v)}
        />
        <Checkbox
          label=""
          name="endDate.hideNulls"
          control={control}
          fieldLabel="Hide frameworks without end dates"
        />
      </div>
    </div>
  );
}

export default ContractDatesFilterContent;
