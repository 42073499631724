import React from "react";
import { App, Modal } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useDeleteView } from "lib/hooks/api/notices/views/useDeleteView";

type RenameViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedView: SavedViewType;
  onSuccess?: () => void;
};

export function DeleteViewModal({
  isOpen,
  onClose,
  selectedView,
  onSuccess,
}: RenameViewModalProps) {
  const { message } = App.useApp();
  const { mutate, isLoading } = useDeleteView({
    onSuccess: async (_data, vars) => {
      // using the non hook messages here as the component gets unmounted and the tests dont share
      void message.success(`Successfully deleted ${vars.name}`);
      onSuccess?.();
      onClose();
    },
    onError: async (_data, vars) => {
      await message.error(`Failed to delete ${vars.name}`);
    },
  });

  return (
    <Modal
      title="Delete view"
      open={isOpen}
      onCancel={onClose}
      onOk={() => selectedView.id && mutate({ id: selectedView.id, name: selectedView.name })}
      okText="Delete"
      okButtonProps={{ danger: true, loading: isLoading }}
      closeIcon={null}
    >
      <p>Are you sure you want to delete this view? This action cannot be undone.</p>
    </Modal>
  );
}
