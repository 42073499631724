/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameworkSearchQueryScoreFilterScoresInnerCriteria } from './FrameworkSearchQueryScoreFilterScoresInnerCriteria';
import {
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSON,
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSONTyped,
    FrameworkSearchQueryScoreFilterScoresInnerCriteriaToJSON,
} from './FrameworkSearchQueryScoreFilterScoresInnerCriteria';

/**
 * 
 * @export
 * @interface FrameworkSearchQueryScoreFilterScoresInner
 */
export interface FrameworkSearchQueryScoreFilterScoresInner {
    /**
     * 
     * @type {FrameworkSearchQueryScoreFilterScoresInnerCriteria}
     * @memberof FrameworkSearchQueryScoreFilterScoresInner
     */
    criteria: FrameworkSearchQueryScoreFilterScoresInnerCriteria;
    /**
     * 
     * @type {number}
     * @memberof FrameworkSearchQueryScoreFilterScoresInner
     */
    boost: number;
}

/**
 * Check if a given object implements the FrameworkSearchQueryScoreFilterScoresInner interface.
 */
export function instanceOfFrameworkSearchQueryScoreFilterScoresInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "criteria" in value;
    isInstance = isInstance && "boost" in value;

    return isInstance;
}

export function FrameworkSearchQueryScoreFilterScoresInnerFromJSON(json: any): FrameworkSearchQueryScoreFilterScoresInner {
    return FrameworkSearchQueryScoreFilterScoresInnerFromJSONTyped(json, false);
}

export function FrameworkSearchQueryScoreFilterScoresInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSearchQueryScoreFilterScoresInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': FrameworkSearchQueryScoreFilterScoresInnerCriteriaFromJSON(json['criteria']),
        'boost': json['boost'],
    };
}

export function FrameworkSearchQueryScoreFilterScoresInnerToJSON(value?: FrameworkSearchQueryScoreFilterScoresInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': FrameworkSearchQueryScoreFilterScoresInnerCriteriaToJSON(value.criteria),
        'boost': value.boost,
    };
}

