/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSearchAggregationQuery } from './NoticeSearchAggregationQuery';
import {
    NoticeSearchAggregationQueryFromJSON,
    NoticeSearchAggregationQueryFromJSONTyped,
    NoticeSearchAggregationQueryToJSON,
} from './NoticeSearchAggregationQuery';

/**
 * 
 * @export
 * @interface AnalyseNoticeAggregationRequest
 */
export interface AnalyseNoticeAggregationRequest {
    /**
     * 
     * @type {NoticeSearchAggregationQuery}
     * @memberof AnalyseNoticeAggregationRequest
     */
    query: NoticeSearchAggregationQuery;
}

/**
 * Check if a given object implements the AnalyseNoticeAggregationRequest interface.
 */
export function instanceOfAnalyseNoticeAggregationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "query" in value;

    return isInstance;
}

export function AnalyseNoticeAggregationRequestFromJSON(json: any): AnalyseNoticeAggregationRequest {
    return AnalyseNoticeAggregationRequestFromJSONTyped(json, false);
}

export function AnalyseNoticeAggregationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyseNoticeAggregationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': NoticeSearchAggregationQueryFromJSON(json['query']),
    };
}

export function AnalyseNoticeAggregationRequestToJSON(value?: AnalyseNoticeAggregationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': NoticeSearchAggregationQueryToJSON(value.query),
    };
}

