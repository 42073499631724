import { sendGa4Event } from "../../lib/ga4Tracking";

export const ACCOUNT_SETUP_FORM = { id: "account_setup", name: "Account setup" };

export const fieldInteractionEvent = (value: string, fieldName: string) => {
  if (value) {
    sendGa4Event({
      event: "account_setup_form_interaction",
      form: { ...ACCOUNT_SETUP_FORM, fieldName },
    });
  }
};
