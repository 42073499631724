import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd5";
import produce from "immer";
import isEqual from "lodash.isequal";

import { LimitedExportButton } from "components/actions/ExportButton";
import TextButton from "components/actions/TextButton";
import BuyerCategoryTreeSelectDropdown from "components/form_components/dropdown_list_selects/BuyerCategoryTreeSelectDropdown";
import SignalsTreeDropdown from "components/form_components/dropdown_list_selects/SignalsTreeSelectDropdown";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import MultiSelectDropdown from "lib/core_components/MultiSelectDropdownList";
import { countryCodes } from "lib/data/optionItems";
import { createUseDebounce } from "lib/debounce";
import { useSearchRegions } from "lib/hooks/api/useSearchRegion";
import { useOpenApi } from "lib/openApiContext";
import { SignalCategory } from "lib/StotlesApi";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { BuyerColumn, BuyerSearchColumn, ColumnSetting, TableSettings } from "lib/types/models";
import BulkSaveBuyer from "./BulkSaveBuyer";
import { DEFAULT_PAGE_PARAMS } from "./paramUtils";

import css from "./BuyerFilters.module.scss";

export type SignalFilter =
  | {
      includeAll: true;
    }
  | {
      names: string[];
      includeAll?: false;
    };

export type BuyerSignalFilters = {
  competitors?: SignalFilter;
  partners?: SignalFilter;
  keywords?: SignalFilter;
  cpvCodes?: SignalFilter;
};

/**
 * Type which describes the contents of the available filters on the buyer list tables
 */
export type BuyerFilter = {
  signals: BuyerSignalFilters;
  categoryIds: string[];
  includeUncategorisedBuyers?: boolean;
  countryCodes: string[];
  regionIds: string[];
  buyerName: string;
};

type AvailableSignalFilters = {
  competitors?: string[];
  partners?: string[];
  keywords?: string[];
  cpvCodes?: string[];
};

const useDebounce400 = createUseDebounce(400);

export const BUYER_EXPORT_LIMIT = 500;

type Props<BC extends BuyerColumn | BuyerSearchColumn> = {
  currentFilters: BuyerFilter;
  setFilters: React.Dispatch<React.SetStateAction<BuyerFilter>>;
  onlyRelevantBuyers?: boolean;
  totalCount: number;
  tableSettings: TableSettings<BC>;
  onChangeTableSettings: React.Dispatch<React.SetStateAction<TableSettings<BC>>>;
  allAvailableColumns: ColumnSetting<BC>[];
  exportAction?: (format: "xlsx" | "csv") => Promise<[string, Blob]>;
  selectedRowGuids: string[];
};
function BuyerFilters<BC extends BuyerColumn | BuyerSearchColumn>({
  currentFilters,
  setFilters,
  onlyRelevantBuyers,
  totalCount,
  tableSettings,
  onChangeTableSettings,
  allAvailableColumns,
  selectedRowGuids,
  exportAction,
}: Props<BC>) {
  const { logEvent } = useTracking();
  const [signalOptions, setSignalOptions] = React.useState<AvailableSignalFilters>({});
  const [buyerCategoryOptions, setBuyerCategoryOptions] = React.useState<string[]>([]);

  const { data: allRegions, isLoading: regionsLoading } = useSearchRegions();

  const openApi = useOpenApi();

  const hasActiveFilters = React.useMemo(() => {
    return !isEqual(currentFilters, DEFAULT_PAGE_PARAMS);
  }, [currentFilters]);

  const allRegionsSet = React.useMemo(() => {
    return new Set(allRegions?.regions.map((r) => r.id));
  }, [allRegions?.regions]);

  const countryCodesSet = React.useMemo(() => {
    return new Set(countryCodes.map((c) => c.code));
  }, []);

  const handleCountryAndRegionCheck = (countryAndRegions: string[]) => {
    const regions = countryAndRegions.filter((r) => allRegionsSet.has(r));
    const countries = countryAndRegions.filter((c) => countryCodesSet.has(c));

    setFilters(
      produce((f) => {
        f.regionIds = regions;
        f.countryCodes = countries;
      }),
    );
  };

  const handleClearFilters = React.useCallback(() => {
    setFilters({
      signals: {},
      countryCodes: [],
      regionIds: [],
      categoryIds: [],
      includeUncategorisedBuyers: undefined,
      buyerName: "",
    });
    logEvent(EventNames.filterActioned, {
      "Filter name": "All filters",
      "Filter selection": "All options",
      "Action type": "Filter cleared",
    });
  }, [logEvent, setFilters]);

  // This effect fetches the filter values for the buyer list
  React.useEffect(() => {
    void (async () => {
      if (window.currentUser) {
        const resp = await openApi.getTeamSignalSettings();
        const allSignals = resp.signals;

        setSignalOptions({
          keywords: allSignals
            .filter((s) => s.category == SignalCategory.KEYWORD)
            .map((s) => s.name),
          competitors: allSignals
            .filter((s) => s.category == SignalCategory.COMPETITOR)
            .map((s) => s.name),
          partners: allSignals
            .filter((s) => s.category == SignalCategory.PARTNER)
            .map((s) => s.name),
          cpvCodes: allSignals
            .filter((s) => s.category == SignalCategory.CPV_CODE)
            .map((s) => s.name),
        });
        const buyerFilterResp = await openApi.fetchBuyerFilters({
          buyerFilterRequest: { onlyRelevantBuyers },
        });
        setBuyerCategoryOptions(buyerFilterResp.buyerCategoryIds);
      }
    })();
  }, [onlyRelevantBuyers, openApi]);

  const createTrackingHandler = React.useCallback(
    (filterName: string) => {
      return (field: string, selected: boolean) => {
        logEvent(EventNames.filterActioned, {
          "Filter name": filterName,
          "Filter selection": field,
          "Action type": selected ? "Filter applied" : "Filter cleared",
        });
      };
    },
    [logEvent],
  );

  const countryAndRegionsDisplay = React.useMemo(() => {
    // If we don't have the regions yet, don't show any options
    if (!allRegions || !currentFilters) {
      return [];
    }

    const selectedCountries = countryCodes
      .filter((c) => currentFilters.countryCodes.includes(c.code))
      .sort((a, b) =>
        a.name.trim().toLocaleLowerCase().localeCompare(b.name.trim().toLocaleLowerCase()),
      )
      .map((c) => ({
        title: c.name,
        value: c.code,
        OptionComponent: CountryRegionOption(c.name, c.description ?? "Country"),
      }));

    const unselectedCountries = countryCodes
      .filter((c) => !currentFilters.countryCodes.includes(c.code))
      .sort((a, b) =>
        a.name.trim().toLocaleLowerCase().localeCompare(b.name.trim().toLocaleLowerCase()),
      )
      .map((c) => ({
        title: c.name,
        value: c.code,
        OptionComponent: CountryRegionOption(c.name, c.description ?? "Country"),
      }));

    const selectedRegions = allRegions?.regions
      .filter((r) => currentFilters.regionIds.includes(r.id))
      .map((r) => ({
        title: r.name,
        value: r.id,
        OptionComponent: CountryRegionOption(r.name, r.description),
      }));

    const unselectedRegions = allRegions?.regions
      .filter((r) => !currentFilters.regionIds.includes(r.id))
      .map((r) => ({
        title: r.name,
        value: r.id,
        OptionComponent: CountryRegionOption(r.name, r.description),
      }));

    return [...selectedCountries, ...selectedRegions, ...unselectedRegions, ...unselectedCountries];
  }, [allRegions, currentFilters]);

  const buyerNameChangeEvent = React.useCallback(
    (value: string) => {
      logEvent(EventNames.filterActioned, {
        "Filter name": "Buyer name",
        "Filter selection": value,
        "Action type": value.length > 0 ? "Filter applied" : "Filter cleared",
      });
      setFilters(
        produce((f) => {
          f.buyerName = value;
        }),
      );
    },
    [logEvent, setFilters],
  );

  const handleTextSearch = useDebounce400(buyerNameChangeEvent);

  const selectedCountryRegions = React.useMemo(() => {
    return [...currentFilters?.countryCodes, ...currentFilters.regionIds] || [];
  }, [currentFilters?.countryCodes, currentFilters.regionIds]);

  return (
    <div className={css.filtersContainer}>
      <div className={css.buyerFilters}>
        <Input
          placeholder="Search buyer name"
          onChange={(e) => handleTextSearch(e.target.value)}
          className={css.searchInput}
          prefix={<SearchOutlined className={css.searchIcon} />}
          allowClear
        />

        <SignalsTreeDropdown
          signalOptions={signalOptions}
          selectedSignals={currentFilters.signals}
          searchable
          onChange={(changes) =>
            setFilters(
              produce((f) => {
                f.signals = changes;
              }),
            )
          }
          trackingEvent={createTrackingHandler("Signals")}
          paywallConfig={{
            requiredDataType: "BUYERS",
            categoriesToHide: [SignalCategory.COMPETITOR, SignalCategory.PARTNER],
          }}
          dropdownOverlayClassName={css.filterOverlay}
        />
        <BuyerCategoryTreeSelectDropdown
          showUncategorised={currentFilters.includeUncategorisedBuyers}
          selectedIds={currentFilters.categoryIds}
          buyerCategoryOptions={buyerCategoryOptions}
          onChange={(ids, showUncategorised) =>
            setFilters(
              produce((f) => {
                f.categoryIds = ids;
                f.includeUncategorisedBuyers = showUncategorised;
              }),
            )
          }
          trackingEvent={createTrackingHandler("Buyer type")}
          dropdownOverlayClassName={css.filterOverlay}
        />
        <MultiSelectDropdown
          title="Buyer location"
          description="Country / Region"
          searchable
          hideSelectAll={true}
          loading={regionsLoading}
          className={css.filter}
          overlayClassName={css.countryRegionOverlay}
          skipSort
          options={countryAndRegionsDisplay}
          selectedOptions={selectedCountryRegions}
          onChange={handleCountryAndRegionCheck}
        />

        {hasActiveFilters && (
          <TextButton className={css.clearFilters} onClick={handleClearFilters}>
            Clear filters
          </TextButton>
        )}
      </div>
      <div className={css.buyerFilters}>
        {selectedRowGuids.length > 0 ? (
          <BulkSaveBuyer buyerGuids={selectedRowGuids} />
        ) : (
          <EditColumnsButton<TableSettings<BC>>
            selectedColumns={tableSettings.columns as ColumnSetting<BC>[]}
            onColumnSettingChange={onChangeTableSettings}
            allAvailableColumns={allAvailableColumns}
            dataType={EventDataTypes.buyer}
          />
        )}

        {exportAction && (
          <LimitedExportButton
            exportAction={exportAction}
            exportLimit={BUYER_EXPORT_LIMIT}
            resultsCount={selectedRowGuids.length || totalCount}
            requiredDataType="BUYERS"
            exportedDataType={EventDataTypes.buyer}
          />
        )}
      </div>
    </div>
  );
}

function CountryRegionOption(name: string, description: string) {
  return (
    <>
      <b>{name}</b>
      <span className={css.optionDescription}>{description}</span>
    </>
  );
}

export default BuyerFilters;
