import { LeadSignal } from "./types/models";
import { Signal, SignalCategory } from "./StotlesApi";

export function tagColourFromSignal(category: string): string | undefined {
  let colour: string | undefined = undefined;
  switch (category) {
    case SignalCategory.KEYWORD:
      colour = "blue";
      break;
    case SignalCategory.CPV_CODE:
      colour = "default";
      break;
    case SignalCategory.PARTNER:
      colour = "yellow";
      break;
    case SignalCategory.COMPETITOR:
      colour = "red";
      break;
  }

  return colour;
}

export function signalsToLeadSignals(
  documentSignals: string[],
  signals: Record<string, Signal>,
): LeadSignal[] {
  const leadSignals: LeadSignal[] = [];

  for (const signalGuid of documentSignals) {
    const signal = signals[signalGuid];

    if (signal) {
      leadSignals.push({
        category: signal.category as SignalCategory,
        colour: tagColourFromSignal(signal.category) ?? "blue",
        id: signal.id,
        name: signal.name,
      });
    }
  }

  return leadSignals;
}
