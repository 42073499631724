import React from "react";
import { Button } from "antd5";

import PaywallPopover from "components/paywall/PaywallPopover";
import { useAccountBriefingRequest } from "lib/hooks/api/buyer/useAccountBriefingRequest";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, useTracking } from "lib/tracking";
import { BuyerDetails, BuyerSummary } from "lib/types/models";

export type BriefingType = "account" | "opportunity";

type Props = {
  buyer: BuyerSummary | BuyerDetails;
  // TODO: move to reading from tracking context (context as in react context)
  contextName: string;
  buttonText?: string;
  className?: string;
  briefingType: BriefingType;
};

export default function AccountBriefingBtn({
  buyer,
  contextName,
  className,
  briefingType,
}: Props): JSX.Element | null {
  const { mutate, isLoading } = useAccountBriefingRequest();
  const { logEvent } = useTracking();

  const subscription = useSubscription();

  const onClick = () => {
    const currentUser = window.currentUser;
    if (!currentUser || !subscription.hasDataTypes("BUYERS")) {
      return;
    }

    logEvent(EventNames.accountBriefingBtnClicked, {
      "Context source": contextName,
      "Current user": {
        email: currentUser.email,
        guid: currentUser.guid,
        "company name": currentUser.company.name,
        "company id": currentUser.company.id,
      },
      "Current url": window.location.href,
    });
    const buyerUrl = new URL(`/buyers/${buyer.guid}`, window.location.href);
    mutate({ buyerId: buyer.id, type: briefingType, url: buyerUrl.toString() });
  };

  if (!window.currentUser?.has_account_briefing_permission) {
    return null;
  }

  return (
    <PaywallPopover featureType="BUYERS" contextSource={contextName} isAddOn>
      <Button type="primary" className={className} onClick={onClick} loading={isLoading}>
        Get {briefingType} briefing
      </Button>
    </PaywallPopover>
  );
}
