// Warning: these routes are currently only used in the vertical nav, changing them will not affect other routes in the app

export const routes = {
  home: "/",
  notices: "/",
  frameworks: "/frameworks",
  spend: "/spend",
  documents: "/documents",
  contacts: "/contacts",
  buyers: "/buyers/all-buyers",
  suppliers: "/suppliers/search",
  opportunities: "/opportunities",
  myNotices: "/my-notices",
  buyerLists: "/buyers/lists",
  customBriefings: "/reports/custom",
  industryReports: "/reports/industry",
  settings: "/account-management/feed-settings",
  help: "https://help.stotles.com/",
};

export const settingsRoutes = {
  signalSettings: "/account-management/feed-settings",
  displaySettings: "/account-management/display-settings",
  changePassword: "/account-management/change-password",
  subscription: "/account-management/subscription",
  notifications: "/account-management/notifications",
  team: "/account-management/my-team",
  integrations: "/account-management/integrations",
};

export const isOnListsPath =
  window.location.pathname.includes(routes.myNotices) ||
  window.location.pathname.includes(routes.buyerLists);
export const isOnReportsPath = window.location.pathname.includes("/reports");
export const isOnSettingsPath = window.location.pathname.includes("/account-management");

const accountSettingRoutes = [
  settingsRoutes.displaySettings,
  settingsRoutes.changePassword,
  settingsRoutes.subscription,
];

export const isOnAccountSettingsPage = accountSettingRoutes.some((route) =>
  window.location.pathname.includes(route),
);
