import React, { useMemo } from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { getDocumentInfo } from "components/reports/utils";
import { PdfLoader } from "lib/documents/PdfLoader";
import { extractSearchTerms } from "lib/documents/search";
import {
  DocumentReferenceParameters,
  DocumentReferenceParametersFromJSON,
} from "lib/generated/admin-api";

import css from "./ViewDocumentPage.module.scss";

type Props = {
  documentRef: DocumentReferenceParameters;
};

function ViewDocumentPage({ documentRef }: Props): JSX.Element {
  const docInfo = getDocumentInfo(DocumentReferenceParametersFromJSON(documentRef));
  const searchTerms = useMemo(() => extractSearchTerms(docInfo?.searchTerm), [docInfo?.searchTerm]);

  return (
    <div className={css.viewDocumentPage}>
      <PdfLoader documentUrl={docInfo?.url} searchTerms={searchTerms} />
    </div>
  );
}

export default hot(withAppLayout(ViewDocumentPage, {}));
