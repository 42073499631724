/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateAliasDecision } from './CreateAliasDecision';
import {
    CreateAliasDecisionFromJSON,
    CreateAliasDecisionFromJSONTyped,
    CreateAliasDecisionToJSON,
} from './CreateAliasDecision';
import type { DecisionTypeUpdateAttributes } from './DecisionTypeUpdateAttributes';
import {
    DecisionTypeUpdateAttributesFromJSON,
    DecisionTypeUpdateAttributesFromJSONTyped,
    DecisionTypeUpdateAttributesToJSON,
} from './DecisionTypeUpdateAttributes';
import type { DuplicateDecision } from './DuplicateDecision';
import {
    DuplicateDecisionFromJSON,
    DuplicateDecisionFromJSONTyped,
    DuplicateDecisionToJSON,
} from './DuplicateDecision';
import type { InactiveDecision } from './InactiveDecision';
import {
    InactiveDecisionFromJSON,
    InactiveDecisionFromJSONTyped,
    InactiveDecisionToJSON,
} from './InactiveDecision';
import type { MultipleEntitiesDecision } from './MultipleEntitiesDecision';
import {
    MultipleEntitiesDecisionFromJSON,
    MultipleEntitiesDecisionFromJSONTyped,
    MultipleEntitiesDecisionToJSON,
} from './MultipleEntitiesDecision';
import type { NotRelatedDecision } from './NotRelatedDecision';
import {
    NotRelatedDecisionFromJSON,
    NotRelatedDecisionFromJSONTyped,
    NotRelatedDecisionToJSON,
} from './NotRelatedDecision';
import type { SignalsToOrgDecision } from './SignalsToOrgDecision';
import {
    SignalsToOrgDecisionFromJSON,
    SignalsToOrgDecisionFromJSONTyped,
    SignalsToOrgDecisionToJSON,
} from './SignalsToOrgDecision';
import type { TransferResponsibilityDecision } from './TransferResponsibilityDecision';
import {
    TransferResponsibilityDecisionFromJSON,
    TransferResponsibilityDecisionFromJSONTyped,
    TransferResponsibilityDecisionToJSON,
} from './TransferResponsibilityDecision';
import type { UnsureDecision } from './UnsureDecision';
import {
    UnsureDecisionFromJSON,
    UnsureDecisionFromJSONTyped,
    UnsureDecisionToJSON,
} from './UnsureDecision';

/**
 * 
 * @export
 * @interface DecisionType
 */
export interface DecisionType {
    /**
     * 
     * @type {DuplicateDecision}
     * @memberof DecisionType
     */
    duplicate?: DuplicateDecision;
    /**
     * 
     * @type {NotRelatedDecision}
     * @memberof DecisionType
     */
    notRelated?: NotRelatedDecision;
    /**
     * 
     * @type {UnsureDecision}
     * @memberof DecisionType
     */
    unsure?: UnsureDecision;
    /**
     * 
     * @type {MultipleEntitiesDecision}
     * @memberof DecisionType
     */
    multipleEntities?: MultipleEntitiesDecision;
    /**
     * 
     * @type {TransferResponsibilityDecision}
     * @memberof DecisionType
     */
    transferResponsibilities?: TransferResponsibilityDecision;
    /**
     * 
     * @type {InactiveDecision}
     * @memberof DecisionType
     */
    inactive?: InactiveDecision;
    /**
     * 
     * @type {SignalsToOrgDecision}
     * @memberof DecisionType
     */
    signalsToOrg?: SignalsToOrgDecision;
    /**
     * 
     * @type {CreateAliasDecision}
     * @memberof DecisionType
     */
    createAlias?: CreateAliasDecision;
    /**
     * 
     * @type {DecisionTypeUpdateAttributes}
     * @memberof DecisionType
     */
    updateAttributes?: DecisionTypeUpdateAttributes;
}

/**
 * Check if a given object implements the DecisionType interface.
 */
export function instanceOfDecisionType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DecisionTypeFromJSON(json: any): DecisionType {
    return DecisionTypeFromJSONTyped(json, false);
}

export function DecisionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duplicate': !exists(json, 'duplicate') ? undefined : DuplicateDecisionFromJSON(json['duplicate']),
        'notRelated': !exists(json, 'not_related') ? undefined : NotRelatedDecisionFromJSON(json['not_related']),
        'unsure': !exists(json, 'unsure') ? undefined : UnsureDecisionFromJSON(json['unsure']),
        'multipleEntities': !exists(json, 'multiple_entities') ? undefined : MultipleEntitiesDecisionFromJSON(json['multiple_entities']),
        'transferResponsibilities': !exists(json, 'transfer_responsibilities') ? undefined : TransferResponsibilityDecisionFromJSON(json['transfer_responsibilities']),
        'inactive': !exists(json, 'inactive') ? undefined : InactiveDecisionFromJSON(json['inactive']),
        'signalsToOrg': !exists(json, 'signals_to_org') ? undefined : SignalsToOrgDecisionFromJSON(json['signals_to_org']),
        'createAlias': !exists(json, 'create_alias') ? undefined : CreateAliasDecisionFromJSON(json['create_alias']),
        'updateAttributes': !exists(json, 'update_attributes') ? undefined : DecisionTypeUpdateAttributesFromJSON(json['update_attributes']),
    };
}

export function DecisionTypeToJSON(value?: DecisionType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duplicate': DuplicateDecisionToJSON(value.duplicate),
        'not_related': NotRelatedDecisionToJSON(value.notRelated),
        'unsure': UnsureDecisionToJSON(value.unsure),
        'multiple_entities': MultipleEntitiesDecisionToJSON(value.multipleEntities),
        'transfer_responsibilities': TransferResponsibilityDecisionToJSON(value.transferResponsibilities),
        'inactive': InactiveDecisionToJSON(value.inactive),
        'signals_to_org': SignalsToOrgDecisionToJSON(value.signalsToOrg),
        'create_alias': CreateAliasDecisionToJSON(value.createAlias),
        'update_attributes': DecisionTypeUpdateAttributesToJSON(value.updateAttributes),
    };
}

