import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetLeadSubscriptionsStatusResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = [string, string | null];

export function useGetLeadSubscriptionStatus(
  token: string | null,
  options?: UseQueryOptions<
    GetLeadSubscriptionsStatusResponse | undefined,
    unknown,
    GetLeadSubscriptionsStatusResponse | undefined,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["leadSubscriptions", token],
    async () => {
      if (!token) {
        return undefined;
      }
      return await api.getLeadSubscriptionStatus({ token });
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
      enabled: !!token && (options?.enabled ?? true),
    },
  );
}
