import React, { ReactNode } from "react";
import classNames from "classnames";

import { InfoTooltip, InfoTooltipProps } from "./InfoTooltip";

import css from "./ColumnHeader.module.scss";

type ColumnHeaderProps = {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  tooltipProps?: InfoTooltipProps;
  icon?: ReactNode;
  // TODO: Add other colour options that match other ant elements
  iconColour?: "warning";
};

export function ColumnHeader(props: ColumnHeaderProps): JSX.Element {
  const { header, subHeader, icon, iconColour, tooltipProps } = props;
  const iconClass = iconColour === "warning" ? css.headerIconWarning : undefined;
  const headerContent = (
    <div className={css.headerContainer}>
      {icon && <span className={classNames(css.headerIcon, iconClass)}>{icon}</span>}
      <div className={css.header}>{header}</div>
      {subHeader && <div className={css.subHeader}>{subHeader}</div>}
    </div>
  );

  if (tooltipProps) {
    const tooltipPropsWithDefaults: InfoTooltipProps = {
      placement: "bottom",
      ...tooltipProps,
    };

    return <InfoTooltip {...tooltipPropsWithDefaults}>{headerContent}</InfoTooltip>;
  } else {
    return headerContent;
  }
}
