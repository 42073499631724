import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  HardDeleteTeamFromOnboardingMutation,
  HardDeleteTeamFromOnboardingMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const deleteTeam = graphql(`
  mutation hardDeleteTeamFromOnboarding($input: DeleteTeamInput!) {
    hardDeleteTeamFromOnboarding(DeleteTeamInput: $input) {
      id
    }
  }
`);

export function useHardDeleteTeamFromOnboarding(
  options?: UseMutationOptions<
    HardDeleteTeamFromOnboardingMutation,
    unknown,
    HardDeleteTeamFromOnboardingMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(deleteTeam, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["teams"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
  return { data: data?.hardDeleteTeamFromOnboarding.id, ...rest };
}
