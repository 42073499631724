import React from "react";

import { Report } from "components/reports/Report";
import { ReportSubscriptionContextProvider } from "components/reports/ReportSubscriptionProvider";
import { ReportConfig, ReportConfigFromJSON } from "lib/generated/admin-api";
import UTMParamsProvider from "lib/providers/UTMParams";
import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";

const STOTLES_API = new StotlesAPI();
type PrintReportPageProps = {
  report: ReportConfig;
};

function PrintReportPage({ report }: PrintReportPageProps): JSX.Element {
  report = ReportConfigFromJSON(report) as ReportConfig & { id: string };
  return (
    <ApiContextProvider api={STOTLES_API}>
      <UTMParamsProvider utmParams={{ source: "pdf", campaign: report.id, medium: "report" }}>
        <ReportSubscriptionContextProvider>
          <Report report={report} />
        </ReportSubscriptionContextProvider>
      </UTMParamsProvider>
    </ApiContextProvider>
  );
}

export default PrintReportPage;
