import { useQuery, useQueryClient } from "@tanstack/react-query";

import { RecordDto, RecordSearchResponseDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

export function useRecord(guid: string) {
  const client = useQueryClient();
  const api = useOpenApi();

  const query = useQuery(
    ["record", guid],
    async () => {
      const data = await api.searchRecords({ recordSearchRequestDto: { guid: [guid] } });

      return data?.results[0];
    },
    {
      initialData: () => {
        const data = client.getQueriesData<RecordSearchResponseDto>({ queryKey: ["records"] });
        let record: RecordDto | undefined = undefined;
        for (let i = 0; i < data.length && !record; i++) {
          const [, response] = data[i];
          record = response?.results?.find((r) => r.guid === guid);
        }
        return record;
      },
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );

  return query;
}
