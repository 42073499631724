import React from "react";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { useProHelper } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";

import css from "./ViewButton.module.scss";

type Props = {
  title: string;
  description: string;
  onClick: () => void;
  isSelected: boolean;
};

function ViewButton({ title, description, isSelected, onClick }: Props): JSX.Element {
  const subscription = useSubscription();
  const isSubscribed = subscription.hasDataTypes("SUPPLIERS");
  const { checkSubscription } = useProHelper();
  return (
    <button
      onClick={isSubscribed ? onClick : () => checkSubscription("SUPPLIERS")}
      className={
        !isSubscribed ? css.savedViewDisabled : isSelected ? css.savedViewActive : css.savedViewItem
      }
    >
      <EllipsisTooltipText fullText={title} containerClassname={css.paneViewTitle} />

      <div className={css.descriptionContainer}>
        <EllipsisTooltipText
          fullText={description}
          containerClassname={css.description}
          tooltipProps={{ placement: "bottom" }}
        />
      </div>
    </button>
  );
}

export default ViewButton;
