import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { App } from "antd5";

import { graphql } from "lib/generated/app-service-gql";
import {
  DeleteSignalNameToOrgMappingsMutation,
  DeleteSignalNameToOrgMappingsMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../../useGraphQlClient";

const deleteSignalNameToOrgMappings = graphql(`
  mutation deleteSignalNameToOrgMappings($signalNames: [String!]!) {
    deleteSignalNameToOrgMappings(signalNames: $signalNames) {
      signalNames
    }
  }
`);

/**
 * Delets organisation mappings for the provided signal names. Will be called when a user submits a migration
 * @param options
 * @returns
 */
export function useDeleteSignalNameToOrgMappings(
  options?: UseMutationOptions<
    DeleteSignalNameToOrgMappingsMutation,
    unknown,
    DeleteSignalNameToOrgMappingsMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data, ...rest } = useGraphQlMutation(deleteSignalNameToOrgMappings, {
    ...options,
    onSuccess: (data, variables, context) => {
      const { signalNames } = data.deleteSignalNameToOrgMappings;
      void message.success(`Deleted previous signal mappings for:\n ${signalNames.join(", ")}`, 3);
      void queryClient.invalidateQueries(["admin", "mappedSignals", "signalsToMigrate"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
  return { data: data?.deleteSignalNameToOrgMappings.signalNames, ...rest };
}
