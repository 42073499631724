import * as React from "react";

import Sparkles from "lib/icons/Sparkles";

import css from "./AiCard.module.scss";

type AiCardProps = {
  title: string;
  description?: string;
  onClick: () => void;
};

function AiCard({ description, onClick, title }: AiCardProps) {
  return (
    <div onClick={onClick} className={css.card} aria-label="ai card">
      <Sparkles fill="white" className={css.sparkles} />
      <div className={css.text}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
export default AiCard;
