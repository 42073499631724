import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { DocumentQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const documentQuery = graphql(`
  query documentAdmin($id: String!) {
    document(documentId: $id) {
      id
      title
      description
      category
      publishedAt
      sourceUrlLinks {
        id
        sourceUrl
      }
      alternativeSource
      buyers {
        id
        name
      }
    }
  }
`);

export type DocumentDetails = DocumentQuery["document"];

export function useDocument(
  documentId: string,
  options?: UseQueryOptions<DocumentQuery, unknown, DocumentQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["documentAdmin", documentId],
    documentQuery,
    [{ id: documentId }],
    options,
  );

  return { data: data?.document, ...rest };
}
