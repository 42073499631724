import * as React from "react";
import classNames from "classnames";

import CentredSpinner from "lib/core_components/CentredSpinner";

import css from "./StatisticsCard.module.scss";

export type StatisticsCardProps = {
  value: number;
  loading: boolean;
  objectType: string;
  pluralObjectType?: string;
  description: string;
  onClick: () => void;
  active: boolean;
};

function StatisticsCard({
  value,
  objectType,
  description,
  onClick,
  loading,
  pluralObjectType,
  active,
}: StatisticsCardProps) {
  const formattedObjectType = value === 1 ? objectType : pluralObjectType ?? `${objectType}s`;

  return (
    <div
      onClick={onClick}
      className={classNames(css.card, { [css.active]: active })}
      aria-label="statistic card"
    >
      <span>
        <h2>{loading ? <CentredSpinner /> : value}</h2>
        <h3>{formattedObjectType}</h3>
      </span>
      <p>{description}</p>
    </div>
  );
}
export default StatisticsCard;
