import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Tag } from "antd5";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { Option } from "./ClassificationListSelect";

import css from "./SelectionTags.module.scss";

type Props = {
  selections: Option[];
  icon?: React.ReactNode;
  iconedSelectionIds?: string[];
  handleRemoval: (op: Option) => void;
};

function SelectionTags({ selections, icon, iconedSelectionIds, handleRemoval }: Props) {
  if (selections.length == 0) {
    return null;
  }

  const iconElem = icon ? <span className={css.icon}>{icon}</span> : null;
  return (
    <div className={css.selections} aria-label="selections">
      <div className={css.tagContainer}>
        {selections.map((op: Option) => {
          const isIconed = iconedSelectionIds?.includes(op.value) || false;
          return (
            <Tag
              aria-label="selectionTag"
              key={op.value}
              className={css.tag}
              onClose={() => handleRemoval(op)}
              closeIcon={<CloseOutlined />}
            >
              {isIconed && iconElem}
              <EllipsisTooltipText fullText={op.label} textProps={{ className: css.tagLabel }} />
            </Tag>
          );
        })}
      </div>
    </div>
  );
}

export default SelectionTags;
