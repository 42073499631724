import { EventNames, TrackingEvent } from "lib/tracking";

export function createFeedbackSubmittedEvent(
  positive: boolean,
  question: string,
  answer: string,
  orgName: string,
  feedback?: string,
  untrue?: boolean,
  unhelpful?: boolean,
): TrackingEvent {
  const positiveData = {
    "Prompt selection": question,
    "Prompt answer": answer,
    "What did you like about the answer": feedback,
    "Page source": "Buyer profile",
    "Context source": "Positive feedback modal",
    "Organisation name": orgName,
  };

  const negativeData = {
    "Prompt selection": question,
    "Prompt answer": answer,
    "What didn't you like about the answer": feedback,
    "This isn't true selected": untrue ? true : false,
    "This isn't helpful selected": unhelpful ? true : false,
    "Page source": "Buyer profile",
    "Context source": "Negative feedback modal",
    "Organisation name": orgName,
  };

  return {
    name: positive ? EventNames.positiveFeedbackSubmitted : EventNames.negativeFeedbackSubmitted,
    data: positive ? positiveData : negativeData,
  };
}

export function createFeedbackCloseEvent(
  positive: boolean,
  question: string,
  answer: string,
  orgName: string,
  feedback?: string,
  untrue?: boolean,
  unhelpful?: boolean,
): TrackingEvent {
  const positiveData = {
    "Prompt selection": question,
    "Prompt answer": answer,
    "What did you like about the answer": feedback,
    "Page source": "Buyer profile",
    "Context source": "Positive feedback modal",
    "Organisation name": orgName,
  };

  const negativeData = {
    "Prompt selection": question,
    "Prompt answer": answer,
    "What didn't you like about the answer": feedback,
    "This isn't true selected": untrue ? true : false,
    "This isn't helpful selected": unhelpful ? true : false,
    "Page source": "Buyer profile",
    "Context source": "Negative feedback modal",
    "Organisation name": orgName,
  };

  return {
    name: positive ? EventNames.positiveFeedbackDismissed : EventNames.negativeFeedbackDismissed,
    data: positive ? positiveData : negativeData,
  };
}

export function createFeedbackOpenedEvent(
  positive: boolean,
  question: string,
  answer: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.feedbackModalOpened,
    data: {
      Sentiment: positive ? "Positive" : "Negative",
      "Prompt selection": question,
      "Prompt answer": answer,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function createTrackingLinkContextEvent(
  recordGuid: string,
  question: string,
  answer: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.referenceLinkOpened,
    data: {
      "Reference type": "Record",
      "Record guid": recordGuid,
      "Prompt selection": question,
      "Prompt answer": answer,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function createNewChatCreatedEvent(orgName: string): TrackingEvent {
  return {
    name: EventNames.newChatCreated,
    data: {
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function questionAnswered(
  question: string,
  questionId: string,
  answer: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.answerGenerated,
    data: {
      "Prompt selection": question,
      "Prompt answer": answer,
      "Question ID": questionId,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function chatSelectedEvent(question: string, chatId: string, orgName: string) {
  return {
    name: EventNames.chatSelected,
    data: {
      "Chat name": question,
      ChatID: chatId,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function createPromptActionedEvent(question: string, orgName: string): TrackingEvent {
  return {
    name: EventNames.promptActioned,
    data: {
      "Prompt selection": question,
      "Input actioned": "Question box",
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function createSuggestedPromptActionedEvent(
  question: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.promptActioned,
    data: {
      "Prompt selection": question,
      "Input actioned": "Suggestion button",
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function aiReportVisitStotlesClicked(
  orgName: string,
  chatName: string,
  chatId: string,
): TrackingEvent {
  return {
    name: EventNames.visitStotlesClicked,
    data: {
      "Chat name": chatName,
      "Chat ID": chatId,
      "Page source": "AI Report",
      "Context source": "AI Overview Block",
      "Organisation name": orgName,
    },
  };
}

export function createReportModalOpenedEvent(
  question: string,
  questionId: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.shareReportModalOpened,
    data: {
      "Chat name": question,
      ChatID: questionId,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function reportCreated(
  question: string,
  questionId: string,
  orgName: string,
): TrackingEvent {
  return {
    name: EventNames.aiReportGenerated,
    data: {
      "Chat name": question,
      ChatID: questionId,
      "Page source": "Buyer profile",
      "Context source": "Ask AI",
      "Organisation name": orgName,
    },
  };
}

export function aiShareReportClicked(
  orgName: string,
  chatName: string,
  chatId: string,
  shareMode: "Copy link" | "LinkedIn" | "Twitter" | "Email",
): TrackingEvent {
  return {
    name: EventNames.shareReportClicked,
    data: {
      "Share mode": shareMode,
      "Page source": "AI Report",
      "Context source": "AI Overview Block",
      "Organisation name": orgName,
      "Chat name": chatName,
      "Chat ID": chatId,
    },
  };
}

export function aiReportSignUpForFreeClicked(
  orgName: string,
  chatName: string,
  chatId: string,
): TrackingEvent {
  return {
    name: EventNames.signUpForFreeClicked,
    data: {
      "Page source": "AI Report",
      "Context source": "CTA Block",
      "Organisation name": orgName,
      "Chat name": chatName,
      "Chat ID": chatId,
    },
  };
}

export function aiReportBookADemoClicked(
  orgName: string,
  chatName: string,
  chatId: string,
): TrackingEvent {
  return {
    name: EventNames.bookADemoClicked,
    data: {
      "Page source": "AI Report",
      "Context source": "CTA Block",
      "Organisation name": orgName,
      "Chat name": chatName,
      "Chat ID": chatId,
    },
  };
}

export function aiReportViewed(orgName: string, reportTitle: string) {
  return {
    name: EventNames.aiReportViewed,
    data: {
      "Organisation Name": orgName,
      "Report title": reportTitle,
      "Report type": "AI Report",
    },
  };
}
