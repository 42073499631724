import React, { useState } from "react";
import { Select, Skeleton } from "antd5";

import { useDebouncedValue } from "lib/debounce";
import {
  CompanyToMigrate,
  useCompaniesToMigrate,
} from "lib/hooks/api/admin/signal_migration/useCompaniesToMigrate";

import css from "./SelectUnmigratedComponents.module.scss";

type Props = {
  selectedCompany?: CompanyToMigrate;
  onCompanyChange: (selectedCompany?: CompanyToMigrate) => void;
};

function SelectUnmigratedCompanies({ selectedCompany, onCompanyChange }: Props): JSX.Element {
  const [textSearch, setTextSearch] = useState<string>("");
  const [debouncedText] = useDebouncedValue(textSearch, 300);

  const { isLoading, data } = useCompaniesToMigrate(debouncedText);
  const companyResults = data?.companiesToMigrate || [];

  return (
    <div>
      <Select
        className={css.searchInput}
        loading={isLoading}
        showSearch
        size="large"
        onSearch={setTextSearch}
        value={selectedCompany?.id}
        optionFilterProp="label"
        options={companyResults.map((company) => ({
          key: company.id,
          value: company.id,
          label: `${company.name} (${company.userCount} users)`,
        }))}
        dropdownRender={(menu) => {
          if (isLoading) {
            return <Skeleton className={css.simplePadding} active />;
          }
          return menu;
        }}
        placeholder="Search by company name"
        onChange={(v) => {
          const newSelectedCompany = companyResults.find((company) => company.id === v);
          if (newSelectedCompany) {
            onCompanyChange(newSelectedCompany);
          } else {
            onCompanyChange(undefined);
          }
        }}
      />
    </div>
  );
}

export default SelectUnmigratedCompanies;
