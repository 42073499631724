import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetSupplierStatsListRequest, SupplierListSummaryResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, string];

export function useSupplierStatsList(
  getSupplierStatsListRequest: GetSupplierStatsListRequest,
  options?: UseQueryOptions<
    SupplierListSummaryResponse,
    unknown,
    SupplierListSummaryResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["supplierStats", getSupplierStatsListRequest.category],
    () => api.getSupplierStatsList(getSupplierStatsListRequest),
    options,
  );
}
