import { Plugin } from "chart.js";

const colourPalette = [
  "#678FE4",
  "#FFCA38",
  "#81D5DE",
  "#EE7D60",
  "#9BB9FA",
  "#A75E6E",
  "#F4B583",
  "#8C94D1",
  "#F55389",
  "#397C9D",
];

function getChartDatasetColour(index: number): string {
  return colourPalette[index % colourPalette.length];
}

export const StotlesChartStyle: Plugin<"line" | "bar"> = {
  id: "stotlesChartStyle",
  beforeInit: function (chart) {
    //set title font and color for x and y axes
    if (!chart.options.scales) {
      return;
    }
    Object.values(chart.options.scales).forEach((scale) => {
      if (!scale) {
        return;
      }
      if (
        scale?.type === "linear" ||
        scale?.type === "category" ||
        scale?.type === "time" ||
        scale?.type === "logarithmic" ||
        scale?.type === "timeseries"
      ) {
        scale.title = {
          ...scale.title,
          color: "#525252",
          font: {
            size: 12,
            weight: "bold",
          },
        };
      }
      if (!scale.ticks) {
        scale.ticks = {};
      }
      scale.ticks.font = {
        size: 10,
      };
      scale.ticks.color = "#6C6D70";
    });

    if (chart.data.datasets) {
      chart.data.datasets.forEach((dataset, index) => {
        dataset.borderColor = dataset.backgroundColor || getChartDatasetColour(index);
        dataset.backgroundColor = dataset.backgroundColor || getChartDatasetColour(index);
        dataset.borderWidth = 2;
      });
    }

    chart.update();
  },
};
