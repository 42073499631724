import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { BuyerCpvStatsResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";

type CpvStatsProps = {
  buyerId: number;
  upcomingExpiries?: boolean;
  period?: string;
};
export function useBuyerCpvStats(
  props: CpvStatsProps,
  options?: UseQueryOptions<
    BuyerCpvStatsResponse,
    unknown,
    BuyerCpvStatsResponse,
    [string, string, CpvStatsProps]
  >,
) {
  const api = useStotlesApi();
  return useQuery(
    ["buyers", "cpvStats", props],
    () => api.getBuyerCpvStats(props.buyerId, props.upcomingExpiries, props.period),
    options,
  );
}
