import React from "react";
import {
  CaretDownOutlined,
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  PushpinOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { App, Button, Dropdown } from "antd5";
import isEqual from "lodash.isequal";

import { convertNoticeFiltersToView } from "components/notices/utils";
import { SavedViewType } from "lib/generated/app-api";
import { assert } from "lib/utils";
import { useUnpinView } from "../../../lib/hooks/api/notices/views/useUnpinView";
import { useUpdateView } from "../../../lib/hooks/api/notices/views/useUpdateView";
import { EMPTY_FILTERS, useMyFeedPageState } from "../useMyFeedPageState";
import { AlertButton } from "./AlertButton";
import { CreateViewModal } from "./CreateViewModal";
import { DuplicateViewModal } from "./DuplicateViewModal";
import { EditViewModal } from "./EditViewModal";

import css from "./ViewOptions.module.scss";

type ViewOptionsProps = {
  selectedView?: SavedViewType;
};

function ViewOptions({ selectedView }: ViewOptionsProps) {
  const { message } = App.useApp();
  const { filters, hasUserModifiedView, tableColumns, setSelectedView } = useMyFeedPageState();
  const { mutate: unpinViewMutation } = useUnpinView();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const { mutate: mutateView, isLoading } = useUpdateView("Update view dropdown", {
    onSuccess: (_data, vars) => message.success(`Successfully updated ${vars.view.name}`),
    onError: (_data, vars) => message.error(`Failed to update ${vars.view.name}`),
  });

  return (
    <div className={css.otherButtonContainer}>
      {selectedView && (
        <>
          <EditViewModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            selectedView={selectedView}
          />
          <DuplicateViewModal
            isOpen={isDuplicateModalOpen}
            onClose={() => setIsDuplicateModalOpen(false)}
            selectedView={selectedView}
            onSuccess={(id) => setSelectedView(id, "", "Duplicate view modal")}
          />
          <Dropdown
            menu={{
              items: [
                {
                  label: "Unpin view",
                  key: "unpin",
                  onClick: () => {
                    assert(selectedView.id);
                    unpinViewMutation({ id: selectedView.id, name: selectedView.name });
                  },
                  icon: <PushpinOutlined />,
                },
                {
                  label: "Duplicate view",
                  key: "duplicate",
                  onClick: () => {
                    setIsDuplicateModalOpen(true);
                  },
                  icon: <CopyOutlined />,
                },
                {
                  label: "Edit view",
                  key: "edit",
                  onClick: () => {
                    setIsEditModalOpen(true);
                  },
                  icon: <EditOutlined />,
                },
              ],
            }}
            trigger={["hover"]}
            className={css.moreInfoDropdown}
            placement="bottomRight"
          >
            <Button onClick={(e) => e.stopPropagation()}>
              More <CaretDownOutlined className={css.dropdownArrow} />
            </Button>
          </Dropdown>
        </>
      )}
      {selectedView?.id && (
        <AlertButton selectedView={selectedView} hasUserModifiedView={hasUserModifiedView} />
      )}
      <Dropdown
        menu={{
          items: [
            {
              label: "Update current view",
              key: "updateCurrent",
              disabled: !selectedView || selectedView.isStandardView || !hasUserModifiedView,
              onClick: () => {
                assert(selectedView);
                const convertedViewToFilter = convertNoticeFiltersToView(filters, tableColumns);
                mutateView({
                  view: {
                    ...selectedView,
                    view: convertedViewToFilter,
                  },
                });
              },
            },
            {
              label: "Save as new view",
              key: "saveAsNew",
              disabled: !hasUserModifiedView || isEqual(filters, EMPTY_FILTERS),
              onClick: () => {
                setIsCreateModalOpen(true);
              },
            },
          ],
        }}
        trigger={["hover"]}
        placement="bottomRight"
      >
        <Button
          type="primary"
          onClick={(e) => e.stopPropagation()}
          loading={isLoading}
          icon={<SaveOutlined />}
        >
          Save view <DownOutlined className={css.dropdownArrow} />
        </Button>
      </Dropdown>
      <CreateViewModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        selectedView={selectedView}
      />
    </div>
  );
}

export default ViewOptions;
