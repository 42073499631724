import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { BuyerListDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, string];

export function useBuyerList(
  id: string,
  options?: UseQueryOptions<BuyerListDto, unknown, BuyerListDto, QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    ["buyerLists", id],
    async () => {
      const list = await api.getBuyerList({ id });
      return list;
    },
    options,
  );
}
