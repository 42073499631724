import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { RecordDto, RecordSearchResponseDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { NoticeDetails } from "../notices/useNotice";
import { NoticesDto } from "../notices/useNotices";
import { updateCachedNotice, updateCachedNotices, updateCachedRecord } from "../notices/utils";

type Params = { recordGuid: string };
export function useMarkAsSeen(
  options?: UseMutationOptions<false | void, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ recordGuid }: Params) => {
      const userId = window.currentUser?.guid;
      if (!userId) {
        return false;
      }

      // check cache to see if we need to actually make the request
      const queryData = queryClient.getQueriesData<RecordSearchResponseDto>({
        queryKey: ["records"],
      });
      let hasSeen = false;
      queryData.forEach(([_key, resp]) => {
        resp?.results.forEach((record) => {
          if (record.guid === recordGuid) {
            if (record.seenByUsers.find((user) => user.guid === userId)) {
              hasSeen = true;
              return;
            }
          }
        });
      });

      if (hasSeen) {
        return false;
      }

      return api.markRecordAsSeen({ recordGuid, userId });
    },
    {
      ...options,
      onSuccess: (data, vars, ctx) => {
        if (window.currentUser && data !== false) {
          updateCachedRecord(queryClient, vars.recordGuid, (r) => {
            return markAsSeenByMe<RecordDto>(r, r.seenByUsers);
          });

          updateCachedNotice(queryClient, vars.recordGuid, (r) => {
            return markAsSeenByMe<NoticeDetails>(r, r.seenByUsers);
          });

          updateCachedNotices(queryClient, vars.recordGuid, (r) => {
            return markAsSeenByMe<NoticesDto>(r, r.seenByUsers);
          });
        }

        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}

function markAsSeenByMe<T>(notice: T, seenByUsers: { firstName: string; guid: string }[]): T {
  if (!seenByUsers.find((user) => user.guid === window.currentUser?.guid)) {
    return {
      ...notice,
      seenByUsers: [
        ...seenByUsers,
        {
          firstName: window.currentUser?.first_name || "",
          guid: window.currentUser?.guid || "",
        },
      ],
    };
  }
  return notice;
}
