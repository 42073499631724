import React from "react";
import { message, Modal } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useGetLeadSubscriptions } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptions";
import { useDuplicateView } from "../../../lib/hooks/api/notices/views/useDuplicateView";
import { SavedViewForm } from "./SavedViewForm";

import css from "./DuplicateViewModal.module.scss";

type RenameViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedView: SavedViewType;
  onSuccess?: (id: string) => void;
};

export function DuplicateViewModal({
  isOpen,
  onClose,
  selectedView,
  onSuccess,
}: RenameViewModalProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const { data: existingLeadSubscriptions, isLoading: isLoadingLeadSubscriptions } =
    useGetLeadSubscriptions();

  const { mutate: mutateView, isLoading } = useDuplicateView({
    onSuccess: async (data, vars) => {
      onSuccess?.(data);
      onClose();
      await messageApi.success(`Successfully duplicated ${vars.view.name}`);
    },
    onError: async (_data, vars) => {
      onClose();
      await messageApi.error(`Failed to duplicate ${vars.view.name}`);
    },
  });

  return (
    <Modal
      className={css.modal}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={`Duplicate "${selectedView.name}"`}
      okButtonProps={{ disabled: isLoadingLeadSubscriptions }}
    >
      <p className={css.modalDescription}>
        Quickly access your most commonly used filters at anytime by creating filtered views.
      </p>
      <SavedViewForm
        defaultValues={{
          viewName: `${selectedView.name} (Copy)`,
          viewDescription: selectedView.description ? selectedView.description : "",
          alertFrequency: "DAILY",
        }}
        onSubmit={(data) => {
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          const { updatedAt, ...duplicateSavedViewFields } = selectedView;

          mutateView({
            view: {
              ...duplicateSavedViewFields,
              id: undefined,
              isStandardView: false,
              ownerId: window.currentUser?.guid,
              name: data.viewName,
              description: data.viewDescription,
            },
            alertFrequency: data.alertFrequency,
            existingLeadSubscriptions: existingLeadSubscriptions,
          });
        }}
        onCancel={onClose}
        isLoading={isLoading}
      />
      {contextHolder}
    </Modal>
  );
}
