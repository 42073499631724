import React from "react";
import Button, { ButtonProps } from "antd5/lib/button";
import classNames from "classnames";

import css from "./FilterPill.module.scss";

type FilterPillProps = {
  disabled?: boolean;
  selected?: boolean;
} & ButtonProps;

export function FilterPill({
  className,
  disabled,
  selected,
  ...props
}: FilterPillProps): JSX.Element {
  return (
    <Button
      {...props}
      disabled={disabled}
      className={classNames(className, css.filterPill, {
        [css.disabled]: disabled,
        [css.selected]: selected,
        [css.active]: !selected,
      })}
    />
  );
}
