import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, ConfigProvider, Modal } from "antd5";

import { MidPaddingModal } from "lib/themes/MidPaddingModal";
import { EventNames, useTracking } from "lib/tracking";

import css from "./SaveKeywordModal.module.scss";

export const HIDE_SAVE_KEYWORD_MODAL = "HIDE_SAVE_KEYWORD_MODAL";

export type SaveKeywordModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

function SaveKeywordModal({ isOpen, onConfirm, onClose }: SaveKeywordModalProps) {
  const { logEvent } = useTracking();

  const closeModal = (actionType: "Confirm" | "Cancel") => {
    logEvent(EventNames.saveSignalsModalActioned, {
      "Context source": "Save keywords confirm modal",
      "Action type": actionType,
      "Don't show me again checked?":
        localStorage.getItem(HIDE_SAVE_KEYWORD_MODAL) === "true" ? "Yes" : "No",
    });
    onClose();
  };

  return (
    <ConfigProvider theme={MidPaddingModal}>
      <Modal
        title={
          <div className={css.header}>
            <InfoCircleOutlined className={css.icon} />
            <h2 className={css.title}>Save free-text keywords as signals</h2>
          </div>
        }
        open={isOpen}
        closable={false}
        footer={
          <div className={css.footer} aria-label="Modal footer">
            <Checkbox
              onChange={(e) => localStorage.setItem(HIDE_SAVE_KEYWORD_MODAL, `${e.target.checked}`)}
            >
              Don't show me this again
            </Checkbox>
            <span className={css.buttons}>
              <Button onClick={() => closeModal("Cancel")}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  onConfirm();

                  closeModal("Confirm");
                }}
              >
                Continue
              </Button>
            </span>
          </div>
        }
      >
        <div className={css.content}>
          Saving these keywords as signals will also also update your signal settings and affect all
          saved views where all keyword signals are applied. Do you still wish to continue?
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default SaveKeywordModal;
