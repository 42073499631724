import React from "react";
import { Select, Skeleton } from "antd5";

import { SimpleTeamObj, useAdminTeams } from "lib/hooks/api/teams/useAdminTeams";
import { red700 } from "lib/themes/colors";

import css from "./SelectUnmigratedComponents.module.scss";

type Props = {
  currentCompanyId: string;
  selectedTeam?: SimpleTeamObj;
  onTeamChange: (selectedTeam?: SimpleTeamObj) => void;
};

function SelectUnmigratedTeams({
  currentCompanyId,
  selectedTeam,
  onTeamChange,
}: Props): JSX.Element {
  const { isLoading, data, isError } = useAdminTeams(currentCompanyId);

  if (isError) {
    return <span style={{ fontWeight: "700", color: red700 }}>Error fetching teams</span>;
  }

  return (
    <div>
      <Select
        className={css.searchInput}
        loading={isLoading}
        showSearch
        size="large"
        value={selectedTeam?.id}
        optionFilterProp="label"
        options={data?.map((team) => ({
          key: team.id,
          value: team.id,
          label: `${team.name} (${team.users.length} users)`,
        }))}
        dropdownRender={(menu) => {
          if (isLoading) {
            return <Skeleton className={css.simplePadding} active />;
          }
          if (data?.length === 0) {
            return <span className={css.simplePadding}>No unmigrated teams found</span>;
          }

          return menu;
        }}
        placeholder="Search by team name"
        onChange={(v) => {
          const newSelectedTeam = data?.find((company) => company.id === v);
          if (newSelectedTeam) {
            onTeamChange(newSelectedTeam);
          } else {
            onTeamChange(undefined);
          }
        }}
      />
    </div>
  );
}

export default SelectUnmigratedTeams;
