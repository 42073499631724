import { useEffect } from "react";
import { Control, FieldValues, useFormState, useWatch } from "react-hook-form";

type Callback<T> = (values: T) => void;

function useWatchFormValues<T extends FieldValues>(control: Control<T>, callback: Callback<T>): T {
  // Subscribe to form values using useWatch
  const formValues = useWatch({ control }) as T;

  // Subscribe to form state to get isDirty
  const { isDirty } = useFormState({ control });

  useEffect(() => {
    // Only call the callback if the form is dirty
    if (isDirty && typeof callback === "function") {
      callback(formValues);
    }
  }, [formValues, callback, isDirty]);

  return formValues;
}

export default useWatchFormValues;
