import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CompaniesToMigrateQuery,
  QueryCompaniesToMigrateArgs,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../../useGraphQlClient";

const companiesToMigrate = graphql(`
  query companiesToMigrate($textSearch: String!) {
    companiesToMigrate(textSearch: $textSearch) {
      id
      name
      feedSettingIds
      userCount
    }
  }
`);
type CompaniesToMigrateResponse = CompaniesToMigrateQuery["companiesToMigrate"];
export type CompanyToMigrate = CompaniesToMigrateResponse[0];

export function useCompaniesToMigrate(
  textSearch: QueryCompaniesToMigrateArgs["textSearch"],
  options?: UseQueryOptions<CompaniesToMigrateQuery, unknown, CompaniesToMigrateQuery, QueryKey>,
) {
  return useGraphQlQuery(
    ["admin", "companiesToMigrate", textSearch],
    companiesToMigrate,
    [{ textSearch }],
    {
      ...options,
    },
  );
}
