import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  DocumentsHighlightRequest_SearchCriteria,
  HighlightDocumentQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const documentQuery = graphql(`
  query highlightDocument($request: DocumentsHighlightRequest!) {
    highlightDocument(DocumentsHighlightRequest: $request) {
      titleHighlights
      contentHighlights
    }
  }
`);

export type DocumentHighlights = HighlightDocumentQuery["highlightDocument"];

export function useDocumentHighlight(
  documentId: string,
  searchCriteria: DocumentsHighlightRequest_SearchCriteria,
  options?: UseQueryOptions<HighlightDocumentQuery, unknown, HighlightDocumentQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["documentHighlight", documentId, searchCriteria],
    documentQuery,
    [{ request: { documentId, searchCriteria } }],
    {
      ...options,
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );

  return { data: data?.highlightDocument, ...rest };
}
