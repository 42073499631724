import * as React from "react";
import { useMemo } from "react";
import { Radio } from "antd5";

import { ViewConfigurationFilterSettingsRelevanceScoreEnum as RelevanceScoreEnum } from "lib/generated/app-api";
import RelevanceIcon from "lib/icons/Relevance";
import { SIGNAL_SCORE_MAP } from "lib/utils/signalUtils";

import css from "./NewRelevanceScoreButtons.module.scss";

type LabelProps = {
  currentValue: RelevanceScoreEnum;
  selectedValues: RelevanceScoreEnum[];
  entityType: "notices" | "documents";
};
function RelevanceScoreLabel({ currentValue, selectedValues, entityType }: LabelProps) {
  const score: number = SIGNAL_SCORE_MAP[currentValue];

  const isSelected = (val: string) => selectedValues.includes(val as RelevanceScoreEnum);

  const getScoreLabel = (score: number): string => {
    if (score === 3) return score.toString();
    if (score === 2 || score === 1) return `${score} and above`;
    if (score === 0) return `Show all ${entityType}`;
    return ""; // In case of an unexpected score value
  };

  const scoreLabel = getScoreLabel(score);

  return (
    <span key={currentValue} className={css.newRelevanceScoreButton} aria-label={scoreLabel}>
      <RelevanceIcon darkMode={isSelected(currentValue)} relevance={score} />
      <b>{scoreLabel}</b>
    </span>
  );
}

type Props = {
  onChange: (values: RelevanceScoreEnum[]) => void;
  id?: string;
  value: RelevanceScoreEnum[];
  entityType: "notices" | "documents";
};

// Because this is used within Form.Item we have to wrap it in a forwardRef
export const NewRelevanceScoreButtons = React.forwardRef<HTMLDivElement, Props>(
  ({ onChange, id, value, entityType }, ref) => {
    /**
     * The actualScoreValue is the min value of the RelevanceScoreEnum array...
     * So if it's an array of [Low, Medium, High], the actualScoreValue will be Low (which looks like 1 and above on the radio button)
     */
    const actualScoreValue = useMemo((): RelevanceScoreEnum => {
      if (value.length === 0) {
        return RelevanceScoreEnum.None;
      }
      // Find the RelevanceScoreEnum with the minimum corresponding value in SIGNAL_SCORE_MAP
      return value.reduce((minEnum, currentEnum) => {
        return SIGNAL_SCORE_MAP[currentEnum] < SIGNAL_SCORE_MAP[minEnum] ? currentEnum : minEnum;
      });
    }, [value]);

    const handleSelect = (stage: RelevanceScoreEnum) => {
      if (actualScoreValue.includes(stage) || stage === RelevanceScoreEnum.None) {
        onChange([]);
      } else {
        onChange([stage]);
      }
    };

    return (
      <div id={id} ref={ref}>
        <Radio.Group
          optionType="default"
          className={css.relevanceScoreButtonContainer}
          defaultValue={""}
          value={actualScoreValue}
          onChange={(val) => handleSelect(val.target.value)}
          options={Object.keys(RelevanceScoreEnum)
            .reverse()
            .map((key) => {
              return {
                label: (
                  <RelevanceScoreLabel
                    currentValue={key as RelevanceScoreEnum}
                    selectedValues={value}
                    entityType={entityType}
                  />
                ),
                value: key,
              };
            })}
        />
      </div>
    );
  },
);
