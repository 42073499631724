import { arrayToParams, booleanToUrlParam, urlParamToArray } from "lib/search/url_params";
import { BuyerFilter, SignalFilter } from "./BuyerFilters";

export const DEFAULT_PAGE_PARAMS = {
  signals: {},
  buyerName: "",
  countryCodes: [],
  regionIds: [],
  includeUncategorisedBuyers: undefined,
  categoryIds: [],
};

type URLParams = {
  keywords?: string;
  partners?: string;
  competitors?: string;
  cpv_codes?: string;
  buyer_name?: string;
  country_codes?: string;
  region_ids?: string;
  include_uncategorised_buyers?: string;
  category_ids?: string;
};

const INCLUDE_ALL = "include_all";

const signalFilterToURLParam = (sf: SignalFilter | undefined) => {
  if (!sf) return undefined;
  if (sf.includeAll) {
    return INCLUDE_ALL;
  } else {
    return arrayToParams(sf.names);
  }
};

const urlParamToSignalFilter = (urlParam: string | null): SignalFilter | undefined => {
  if (!urlParam) return undefined;
  if (urlParam.includes(INCLUDE_ALL)) return { includeAll: true };
  else return { names: urlParam.split(",") };
};

export const toUrlParams = (params: BuyerFilter): URLParams => {
  return {
    keywords: signalFilterToURLParam(params.signals?.keywords),
    partners: signalFilterToURLParam(params.signals?.partners),
    competitors: signalFilterToURLParam(params.signals?.competitors),
    cpv_codes: signalFilterToURLParam(params.signals?.cpvCodes),
    category_ids: arrayToParams(params.categoryIds),
    include_uncategorised_buyers:
      params.includeUncategorisedBuyers !== undefined
        ? booleanToUrlParam(params.includeUncategorisedBuyers)
        : undefined,
    country_codes: arrayToParams(params.countryCodes),
    region_ids: arrayToParams(params.regionIds),
    buyer_name: params.buyerName || undefined,
  };
};

export const fromUrlParams = (urlSearchParams: URLSearchParams): BuyerFilter => {
  const keywords = urlSearchParams.get("keywords");
  const partners = urlSearchParams.get("partners");
  const competitors = urlSearchParams.get("competitors");
  const cpvCodes = urlSearchParams.get("cpvCodes");
  const anySignalFiltersDefined = keywords || partners || competitors || cpvCodes;
  return {
    signals: anySignalFiltersDefined
      ? {
          keywords: urlParamToSignalFilter(keywords),
          partners: urlParamToSignalFilter(partners),
          competitors: urlParamToSignalFilter(competitors),
          cpvCodes: urlParamToSignalFilter(cpvCodes),
        }
      : {},
    categoryIds:
      urlParamToArray(urlSearchParams, "category_ids") || DEFAULT_PAGE_PARAMS.categoryIds,
    includeUncategorisedBuyers: urlSearchParams.has("include_uncategorised_buyers")
      ? urlSearchParams.get("include_uncategorised_buyers") === "Y"
      : DEFAULT_PAGE_PARAMS.includeUncategorisedBuyers,
    countryCodes:
      urlParamToArray(urlSearchParams, "country_codes") || DEFAULT_PAGE_PARAMS.countryCodes,
    regionIds: urlParamToArray(urlSearchParams, "region_ids") || DEFAULT_PAGE_PARAMS.countryCodes,
    buyerName: urlSearchParams.get("buyer_name") || DEFAULT_PAGE_PARAMS.buyerName,
  };
};
