import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type Params = { id: string };

export function useDeleteDocument(options?: UseMutationOptions<void, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(({ id }: Params) => api.deleteDocument({ body: { id } }), {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["documentsAdmin"]);
      void queryClient.invalidateQueries(["documents"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
}
