import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import {
  NoticeConfigBuilderRequest,
  NoticeConfigBuilderResponse,
} from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type RequestParams = {
  request: NoticeConfigBuilderRequest;
};

export function useUpdateNoticeConfig(
  options?: UseMutationOptions<NoticeConfigBuilderResponse, unknown, RequestParams, unknown>,
) {
  const api = useIntegrationApi();
  return useMutation(
    ({ request }: RequestParams) =>
      api.updateNoticeConfig({
        noticeConfigBuilderRequest: request,
      }),
    options,
  );
}
