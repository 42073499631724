import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { InputRef } from "antd5";

import { KeywordSelect } from "components/form_components/keyword_select/KeywordSelect";
import { useDocumentTracking } from "./tracking";

import css from "./FilterForm.module.scss";

export type DocumentViewerFilters = {
  keywords: string[];
};

type FilterFormProps = {
  filters: DocumentViewerFilters;
  onChange: (filters: DocumentViewerFilters) => void;
};

export default function FilterForm({ filters, onChange }: FilterFormProps) {
  const tracking = useDocumentTracking();
  const searchInputRef = useRef<InputRef>(null);

  const { control, watch, handleSubmit, setValue } = useForm<DocumentViewerFilters>({
    mode: "onChange",
    defaultValues: filters,
  });

  useEffect(() => {
    setValue("keywords", filters.keywords);
  }, [filters.keywords, setValue]);

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        onChange(d);
        tracking.documentSearched();
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onChange, tracking]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.input?.click();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <form>
      <KeywordSelect
        name="keywords"
        label=""
        control={control}
        containerClassName={css.keywordFilter}
        ref={searchInputRef}
      />
    </form>
  );
}
