import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { AllCpvCodesQuery } from "lib/generated/app-service-gql/graphql";
import { graphql } from "../../../generated/app-service-gql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const cpvCodesQuery = graphql(`
  query allCpvCodes {
    allCpvCodes {
      cpvCodes {
        id
        name
        code
        parentCode
        childIds
      }
    }
  }
`);

export function useAllCpvCodes(
  options?: UseQueryOptions<AllCpvCodesQuery, unknown, AllCpvCodesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["allCpvCodes"], cpvCodesQuery, [], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.allCpvCodes, ...rest };
}
