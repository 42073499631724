import * as React from "react";

import BuyersContainer from "components/table_components/BuyersContainer";
import SimpleTitleCell from "components/table_components/SimpleTitleCell";
import SignalsContainer from "components/tags/SignalsContainer";
import { numberSort } from "lib/columnSort";
import RecordStage from "lib/core_components/RecordStage";
import RedactedWrapper, { RedactedLink } from "lib/core_components/RedactedWrapper";
import { RelevanceScorePopover } from "lib/core_components/RelevanceScore";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { SortState } from "lib/search/types";
import { SearchRecordsParams } from "lib/StotlesApi";
import { getRecordSignals, RecordDetails } from "lib/types/models";
import { capitalize, formatAmount } from "lib/utils";
import { NoticeTabsTypes, ProfileType, SecondaryTab } from "./types";

export const noticesSecondaryTabs: Record<NoticeTabsTypes, { title: string }> = {
  [SecondaryTab.ALL_NOTICES]: {
    title: "All notices",
  },
  [SecondaryTab.AWARDS]: {
    title: "Awards",
  },
  [SecondaryTab.UPCOMING_EXPIRIES]: {
    title: "Upcoming expiries",
  },
};

export const buyersSecondaryTabs = {
  [SecondaryTab.ALL_BUYERS]: {
    title: "All buyers",
  },
  [SecondaryTab.SAVED_BUYERS]: {
    title: "Saved buyers",
  },
};

export const PAGINATION_DEFAULT_CONFIG = {
  pageSize: 20,
  current: 1,
};

export const DEFAULT_NOTICE_SORTS: Record<NoticeTabsTypes, SortState> = {
  [SecondaryTab.ALL_NOTICES]: { field: "relevance_score", order: "DESC" },
  [SecondaryTab.AWARDS]: { field: "relevance_score", order: "DESC" },
  [SecondaryTab.UPCOMING_EXPIRIES]: { field: "relevance_score", order: "DESC" },
};

const DEFAULT_SMALL_COLUMN = {
  // Has to be at least 120 otherwise you don't see the sorter
  xlarge: 140,
  large: 140,
  medium: 140,
  small: 120,
};

const DEFAULT_LARGE_COLUMN = {
  xlarge: 250,
  large: 250,
  medium: 220,
  small: 190,
};

const getSortOrder = (sort: SortState, key: string): "ascend" | "descend" | undefined => {
  if (sort.field === key) {
    return sort.order === "ASC" ? "ascend" : "descend";
  } else return undefined;
};

export type NoticeColumns =
  | "signals"
  | "title"
  | "stage"
  | "value"
  | "publishDate"
  | "expiryDate"
  | "closeDate"
  | "relevanceScore";

type NoticeColumnProps = {
  profileType: ProfileType;
  signalName: string;
  sort: SortState;
  columnNames: NoticeColumns[];
};

export const getNoticesColumns = ({
  profileType,
  signalName,
  sort,
  columnNames,
}: NoticeColumnProps): ColumnType<RecordDetails>[] => {
  const signals: ColumnType<RecordDetails> = {
    ...commonTableColumns.signalsColumn,
    title: "Signals",
    key: "lead_signals",
    render: (_: unknown, e: RecordDetails) => {
      const hideSignal = { category: `${capitalize(profileType)}s`, name: signalName };
      const signals = getRecordSignals(e)?.filter(
        (s) => s.category !== hideSignal.category && s.name !== hideSignal.name,
      );

      return signals ? (
        <SignalsContainer
          requiredDataType="SUPPLIERS"
          redactedSignalCategories={["Partners", "Competitors"]}
          signals={signals}
          contextSource="In-row"
        />
      ) : null;
    },
    sorter: false,
    sizeConfig: DEFAULT_LARGE_COLUMN,
  };

  const title: ColumnType<RecordDetails> = {
    ...commonTableColumns.titleColumn,
    title: "Title",
    dataIndex: "name",
    key: "name",
    render: (_: unknown, r: RecordDetails) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <RedactedWrapper
            redactContent={<RedactedLink textToRedact={r.name} />}
            requiredDataType={"SUPPLIERS"}
            contextSource={"In-row"}
          >
            <SimpleTitleCell record={r} />
          </RedactedWrapper>
          <RedactedWrapper
            requiredDataType={"SUPPLIERS"}
            redactContent={r.buyer.name}
            contextSource={"In-row"}
          >
            <BuyersContainer
              buyers={r.buyers.map((b) => ({ id: b.id, name: b.name }))}
              recordName={r.name}
              showAsLinks={false}
            />
          </RedactedWrapper>
        </div>
      );
    },
    sorter: true,
    sortOrder: getSortOrder(sort, "name"),
    sizeConfig: {
      xlarge: 550,
      large: 550,
      medium: 400,
      small: 250,
    },
  };

  const stage: ColumnType<RecordDetails> = {
    ...commonTableColumns.stageColumn,
    key: "stage",
    title: "Stage",
    render: (_: unknown, r: RecordDetails) => <RecordStage stage={r.stage} />,
  };

  const value: ColumnType<RecordDetails> = {
    ...commonTableColumns.valueColumn,
    title: "Value",
    dataIndex: "value_src",
    key: "value",
    sorter: true,
    render: (_: unknown, r: RecordDetails) => {
      const value = formatAmount(r.value_src, r.currency);
      return value;
    },
    sizeConfig: DEFAULT_SMALL_COLUMN,
    sortOrder: getSortOrder(sort, "value"),
  };

  const publishDate: ColumnType<RecordDetails> = {
    ...commonTableColumns.dateColumn,
    title: "Published Date",
    dataIndex: "publish_date",
    key: "publish_date",
    sorter: true,
    render: (_: unknown, r: RecordDetails) => r.publish_date,
    sortOrder: getSortOrder(sort, "publish_date"),
    sizeConfig: DEFAULT_SMALL_COLUMN,
  };

  const expiryDate: ColumnType<RecordDetails> = {
    ...commonTableColumns.dateColumn,
    key: "expiry_date",
    title: "Expiry date",
    render: (_: unknown, r: RecordDetails) => r.expiry_date,
    sorter: true,
    sortOrder: getSortOrder(sort, "expiry_date"),
    sizeConfig: DEFAULT_SMALL_COLUMN,
  };

  const closeDate: ColumnType<RecordDetails> = {
    ...commonTableColumns.dateColumn,
    key: "close_date",
    title: "Close date",
    render: (_: unknown, r: RecordDetails) => r.close_date,
    sorter: true,
    sortOrder: getSortOrder(sort, "close_date"),
    sizeConfig: DEFAULT_SMALL_COLUMN,
  };

  const relevanceScore: ColumnType<RecordDetails> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "relevance_score",
    render: (_, r) => (
      <RelevanceScorePopover
        recordGuid={r.guid}
        recordStage={r.stage}
        signals={r.signals ?? []}
        relevanceScore={r.relevance_score}
      />
    ),
    sorter: numberSort((r: RecordDetails) => r.relevance_score),
    sortOrder: getSortOrder(sort, "relevance_score"),
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this notice.",
    },
  };

  const columnTypeMap: Record<NoticeColumns, ColumnType<RecordDetails>> = {
    signals,
    title,
    stage,
    value,
    publishDate,
    expiryDate,
    closeDate,
    relevanceScore,
  };

  const columns: ColumnType<RecordDetails>[] = [];

  for (const column of columnNames) {
    columns.push(columnTypeMap[column]);
  }

  return columns;
};

export const UPCOMING_EXPIRIES_FILTERS: Partial<SearchRecordsParams> = {
  stage: ["awarded"],
  close_nulls: "Y",
  expiry_date_relative_from: "PT0S",
  expiry_nulls: "N",
};

export const AWARD_FILTERS: Partial<SearchRecordsParams> = {
  stage: ["awarded"],
  close_nulls: "Y",
  expiry_nulls: "Y",
};

export const ALL_NOTICES_COLUMNS: NoticeColumns[] = [
  "relevanceScore",
  "title",
  "stage",
  "publishDate",
  "value",
  "expiryDate",
  "closeDate",
];
export const UPCOMING_EXPIRIES_COLUMNS: NoticeColumns[] = [
  "relevanceScore",
  "title",
  "expiryDate",
  "value",
  "publishDate",
];
export const AWARDS_COLUMNS: NoticeColumns[] = [
  "relevanceScore",
  "title",
  "expiryDate",
  "value",
  "publishDate",
];
