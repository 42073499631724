import React from "react";
import Lottie from "lottie-react";

import loadingAnimation from "./loadingStarsAnimation.json";

import css from "./LoadingState.module.scss";

export default function LoadingState() {
  return (
    <div className={css.loading}>
      <Lottie animationData={loadingAnimation} className={css.lottie} />
    </div>
  );
}
