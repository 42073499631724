import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetJobResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";

export function useJob(
  jobId: string | undefined,
  options: UseQueryOptions<GetJobResponse | undefined, unknown, GetJobResponse, string[]>,
) {
  const api = useStotlesApi();
  return useQuery(
    ["job", jobId || ""],
    async () => {
      if (!jobId) {
        return undefined;
      }
      return api.getJob(jobId);
    },
    { enabled: !!jobId, ...options },
  );
}
