import React, { useEffect, useRef } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Core } from "@pdftron/webviewer";
import { Skeleton } from "antd5";
import classNames from "classnames";

import { SimpleMatchHighlighter } from "lib/core_components/TextMatchHighlighter";
import { pluralise } from "lib/utils";
import { useKeyboardShortcut } from "../../lib/hooks/useKeyboardShortcut";
import EmptyDocument from "./EmptyDocument";

import css from "./DocumentSearchResults.module.scss";

import MAGNIFYING_GLASS from "../../../assets/images/icons/magnifying_glass_detailed.svg";

export type SearchResultSnippet = Core.Search.SearchResult & { originalIndex: number };

type DocumentSearchResultsProps = {
  activeResultIndex: number;
  setActiveResultIndex: (index: number) => void;
  searchResults: SearchResultSnippet[] | null;
  onJumpToSearchResult: (result: SearchResultSnippet) => void;
  isLoading?: boolean;
  filterCount: number;
  isError?: boolean;
};

export default function DocumentSearchResults({
  activeResultIndex,
  setActiveResultIndex,
  searchResults,
  onJumpToSearchResult,
  isLoading,
  filterCount,
  isError,
}: DocumentSearchResultsProps) {
  const handleSetActiveSearchResult = (index: number, result: SearchResultSnippet) => {
    setActiveResultIndex(index);
    onJumpToSearchResult(result);
  };

  const jumpToPreviousMatch = () => {
    const index = activeResultIndex - 1;

    if (searchResults && index >= 0) {
      const searchResult = searchResults[index];
      handleSetActiveSearchResult(index, searchResult);
    }
  };

  const jumpToNextMatch = () => {
    const index = activeResultIndex + 1;

    if (searchResults && index < searchResults.length) {
      const searchResult = searchResults[index];
      handleSetActiveSearchResult(index, searchResult);
    }
  };

  useKeyboardShortcut(["ArrowUp"], jumpToPreviousMatch);
  useKeyboardShortcut(["ArrowDown"], jumpToNextMatch);

  return (
    <div className={css.results}>
      <div className={css.header}>
        <h3 className={css.count}>{pluralise(searchResults?.length ?? 0, "Result")}</h3>
        <div className={css.arrows}>
          <div
            className={classNames(css.arrowContainer, {
              [css.disabled]: !searchResults?.length || activeResultIndex === 0,
            })}
            aria-label="Previous search result"
            onClick={jumpToPreviousMatch}
          >
            <UpOutlined />
          </div>
          <div
            className={classNames(css.arrowContainer, {
              [css.disabled]:
                !searchResults?.length || activeResultIndex === searchResults.length - 1,
            })}
            aria-label="Next search result"
            onClick={jumpToNextMatch}
          >
            <DownOutlined />
          </div>
        </div>
      </div>
      <div className={css.resultsContainer}>
        {filterCount > 0 ? (
          <SearchResultsContainer
            isLoading={isLoading}
            searchResults={searchResults}
            handleSetActiveSearchResult={handleSetActiveSearchResult}
            activeResultIndex={activeResultIndex}
            isError={isError}
          />
        ) : (
          <div className={css.emptyState}>
            <img src={MAGNIFYING_GLASS} alt="Magnifying glass" />
            <h3>Search for some keywords to get started</h3>
            <p>Matches in this document will appear here.</p>
          </div>
        )}
      </div>
    </div>
  );
}

function SearchResultsContainer({
  isLoading,
  searchResults,
  handleSetActiveSearchResult,
  activeResultIndex,
  isError,
}: {
  isLoading?: boolean;
  searchResults: SearchResultSnippet[] | null;
  handleSetActiveSearchResult: (index: number, result: SearchResultSnippet) => void;
  activeResultIndex: number;
  isError?: boolean;
}) {
  const searchResultsByPage = searchResults?.reduce<Record<number, SearchResultSnippet[]>>(
    (acc, result) => {
      const pageNum = result.pageNum;
      if (!acc[pageNum]) {
        acc[pageNum] = [];
      }
      acc[pageNum].push(result);
      return acc;
    },
    {},
  );

  const resultRefs = useRef<(HTMLDivElement | null)[]>([]);

  // scroll to the active result
  useEffect(() => {
    // defer scrolling to the next frame to ensure DOM is rendered
    requestAnimationFrame(() => {
      if (resultRefs.current[activeResultIndex]) {
        resultRefs.current[activeResultIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    });
  }, [activeResultIndex]);

  if (isError) {
    return <div>Sorry, something has gone wrong.</div>;
  }

  if (isLoading) {
    return <Skeleton active />;
  }

  if (searchResults && searchResults.length === 0) {
    return <EmptyDocument />;
  }

  return (
    <div className={css.flexColumn}>
      {Object.entries(searchResultsByPage ?? []).map(([pageNum, results], index) => (
        <div key={index}>
          <h3 className={css.label}>Page {pageNum}</h3>
          <div className={css.resultsWrapper}>
            {results.map((result) => (
              <div
                key={`${result.originalIndex}`}
                ref={(el) => (resultRefs.current[result.originalIndex] = el)}
                onClick={() => handleSetActiveSearchResult(result.originalIndex, result)}
                className={classNames(css.snippet, {
                  [css.activeSnippet]: activeResultIndex === result.originalIndex,
                })}
                data-testid="result-snippet"
              >
                <SimpleMatchHighlighter
                  text={result.ambientStr}
                  matches={[{ start: result.resultStrStart, end: result.resultStrEnd }]}
                  highlightClassName={css.highlight}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
