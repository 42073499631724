import * as React from "react";
import { DevCycleProvider, DevCycleUser } from "@devcycle/react-client-sdk";
import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Alert, App, ConfigProvider } from "antd5";
import classNames from "classnames";
import { lightTheme } from "styles/theme";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import { ReportSubscriptionContextProvider } from "components/reports/ReportSubscriptionProvider";
import { UserDataProvider } from "lib/data_providers/UserDataProvider";
import { IntegrationAPIProvider } from "lib/integrationApiContext";
import { OpenAPIProvider, STOTLES_OPEN_API } from "lib/openApiContext";
import { DialogManagerProvider } from "lib/providers/DialogManager";
import { RecordViewerProvider } from "lib/providers/RecordViewer";
import { AdminAPIProvider } from "lib/stotlesAdminApiContext";
import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";
import {
  CreateStotlesDataProvidersContainer,
  StotlesDataContextProvider,
} from "../../lib/providers/StotlesData";

import css from "./AdminComponent.module.scss";

const REACT_QUERY_CLIENT = new QueryClient();
const STOTLES_API = new StotlesAPI();
const STOTLES_DATA_PROVIDERS_CONTAINER = CreateStotlesDataProvidersContainer(
  STOTLES_API,
  STOTLES_OPEN_API,
);

type AdminErrorBoundaryState = {
  error: Error | undefined;
};

type AdminErrorBoundaryProps = {
  children: React.ReactNode;
};
class AdminErrorBoundary extends React.Component<AdminErrorBoundaryProps, AdminErrorBoundaryState> {
  constructor(props: AdminErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // We don't override `componentDidCatch` so that errors bubble up to window.onerror
  // and can be caught by sentry

  render() {
    if (this.state.error) {
      return (
        <Alert
          type="error"
          message="Error occurred"
          showIcon
          description={
            <div>
              <p>{this.state.error.message}</p>
              <pre>{this.state.error.stack}</pre>
            </div>
          }
        />
      );
    }
    return this.props.children;
  }
}

const devCycleUserDetails: DevCycleUser | undefined = window.currentUser
  ? {
      user_id: window.currentUser.guid,
      customData: {
        company_id: window.currentUser.company.guid,
        company_name: window.currentUser.company.name,
        team_id: window.currentUser.team.id,
        team_name: window.currentUser.team.name,
        has_frameworks: window.currentUser.active_data_types.includes("FRAMEWORKS"),
        has_awards: window.currentUser.active_data_types.includes("AWARDS"),
        has_contacts: window.currentUser.active_data_types.includes("CONTACTS"),
        has_documents: window.currentUser.active_data_types.includes("DOCUMENTS"),
        has_buyer_supplier_data: window.currentUser.active_data_types.includes("BUYERS|SUPPLIERS"),
        subscription: window.currentUser.subscription,
        partner_programme: window.currentUser.company.partner_programme?.name || null,
        payment_type: window.currentUser.company.payment_type,
        use_supplier_name: window.currentUser.use_supplier_name,
      },
    }
  : undefined;

type AdminComponentOptions = {
  fullHeight?: boolean;
};

/**
 * DevCycle component that conditionally wraps its children with a DevCycleProvider.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 *
 * @returns {JSX.Element} - The children wrapped with DevCycleProvider if the SDK key is present, otherwise the children as is.
 */
function DevCycle({ children }: { children: React.ReactNode }) {
  if (!window.dev_cycle_sdk_key) {
    return <>{children}</>;
  }
  return (
    <DevCycleProvider
      config={{
        sdkKey: window.dev_cycle_sdk_key,
        user: devCycleUserDetails,
        options: { enableEdgeDB: true },
      }}
    >
      {children}
    </DevCycleProvider>
  );
}

export function WrapAdminComponent<Props>(
  Component: React.ComponentType<Props>,
  options?: AdminComponentOptions,
) {
  return (props: Props): JSX.Element => (
    <ConfigProvider prefixCls="ant5" theme={DefaultTheme}>
      <App className={classNames({ [css.fullHeightAdminApp]: !!options?.fullHeight })}>
        <DevCycle>
          <QueryClientProvider client={REACT_QUERY_CLIENT}>
            <ApiContextProvider api={STOTLES_API}>
              <OpenAPIProvider>
                <UserDataProvider>
                  <ThemeProvider theme={lightTheme}>
                    <StotlesDataContextProvider instance={STOTLES_DATA_PROVIDERS_CONTAINER}>
                      <AdminAPIProvider>
                        <IntegrationAPIProvider>
                          <AdminErrorBoundary>
                            <RecordViewerProvider>
                              {/** TODO: Remove this provider once we can inject mock Subscription/ProHelper providers */}
                              <ReportSubscriptionContextProvider>
                                <DialogManagerProvider>
                                  <Component {...props} />
                                </DialogManagerProvider>
                              </ReportSubscriptionContextProvider>
                            </RecordViewerProvider>
                          </AdminErrorBoundary>
                        </IntegrationAPIProvider>
                      </AdminAPIProvider>
                    </StotlesDataContextProvider>
                  </ThemeProvider>
                </UserDataProvider>
              </OpenAPIProvider>
            </ApiContextProvider>
          </QueryClientProvider>
        </DevCycle>
      </App>
    </ConfigProvider>
  );
}
