import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import AllCountriesInput from "./all_countries_input/AllCountriesInput";
import CoreCountriesInput from "./core_countries_input/CoreCountriesInput";

import css from "./CountryInput.module.scss";

type CountryInputProps<T extends FieldValues> = {
  countryFieldName: Path<T>;
  languageFieldName: Path<T>;
  control: Control<T, unknown>;
  internationalDataAccess: boolean;
};

export default function CountryInput<T extends FieldValues>({
  countryFieldName,
  languageFieldName,
  control,
  internationalDataAccess,
}: CountryInputProps<T>) {
  if (internationalDataAccess === false) {
    return <CoreCountriesInput name={countryFieldName} control={control} />;
  }

  return (
    <div className={css.container}>
      <AllCountriesInput
        countryFieldName={countryFieldName}
        languageFieldName={languageFieldName}
        control={control}
      />
    </div>
  );
}
