import React from "react";

import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";
import { EventDataTypes } from "lib/tracking";
import { SortState } from "../../lib/search/types";
import { BuyerFilterSections } from "./BuyerFilterForm";
import { TableContext } from "./types";

function getAppliedFilters(filters: AllBuyersStatsRequestAllBuyersStatsFilters) {
  const appliedFiltersArray = Object.entries(filters).filter(([_key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "object") {
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string") {
      return value.length > 0;
    }
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  });

  return appliedFiltersArray.map(([key]) => key);
}

const sectionToFilterKey: Record<
  BuyerFilterSections,
  (keyof AllBuyersStatsRequestAllBuyersStatsFilters)[]
> = {
  suppliers: ["supplierIds", "supplierSme"],
  buyerInformation: ["buyerName", "buyerGuids", "buyerLists", "buyerTypes"],
  signals: ["signals"],
};

export function QuickFilterBar({
  filters,
  sortState,
  onClearFilter,
  onFilterClick,
  hiddenSections,
  tableContext,
  clearAllFilters,
  clearAllFiltersHide,
}: {
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  sortState: SortState;
  onClearFilter: (value: keyof AllBuyersStatsRequestAllBuyersStatsFilters) => void;
  onFilterClick: (value: keyof AllBuyersStatsRequestAllBuyersStatsFilters) => void;
  hiddenSections: BuyerFilterSections[];
  // TODO: Fetch this from react context using a hook
  tableContext: TableContext;
  clearAllFilters: () => void;
  clearAllFiltersHide?: () => boolean;
}) {
  const hiddenSectionFields =
    hiddenSections?.map((section) => sectionToFilterKey[section]).flat() || [];

  const appliedFiltersMapped = getAppliedFilters(filters);

  const filterKeyToLabel: Record<keyof AllBuyersStatsRequestAllBuyersStatsFilters, string> = {
    buyerName: "Buyer name",
    signals: "Signals",
    countryCodes: "Country",
    hqCountry: "Buyer HQ",
    supplierIds: `Suppliers ${filters.supplierIds?.length}`,
    supplierSme: "SME Supplier",
    categories: "Categories",
    buyerTypes: "Buyer Type",
    buyerGuids: `Buyers (${filters.buyerGuids?.length})`,
    buyerLists: `Buyer lists (${filters.buyerLists?.length})`,
    regionIds: "Region IDs",
  };

  const {
    totalCount,
    queryResult: { isLoading },
  } = tableContext;

  return (
    <FilterBar<AllBuyersStatsRequestAllBuyersStatsFilters>
      appliedFilters={appliedFiltersMapped}
      isLoading={isLoading}
      totalResults={totalCount || 0}
      filterKeyToLabel={filterKeyToLabel}
      onClear={onClearFilter}
      onFilterClick={onFilterClick}
      sortField={sortState.field}
      dataType={EventDataTypes.buyer}
      hiddenFields={hiddenSectionFields}
      clearAllFilters={clearAllFilters}
      clearAllFiltersHide={clearAllFiltersHide}
    />
  );
}
