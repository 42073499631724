import React from "react";
import { EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import CentredSpinner from "lib/core_components/CentredSpinner";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { Question } from "lib/generated/app-service-gql/graphql";
import { useQuestions } from "lib/hooks/api/question/useQuestions";
import * as tracking from "lib/tracking";
import { chatSelectedEvent, createNewChatCreatedEvent } from "./tracking";

import css from "./Questions.module.scss";

type QuestionsProps = {
  newChat: () => void;
  onQuestionClick: (question: Question) => void;
  orgName: string;
  orgId: string;
};

export default function Questions({
  newChat,
  onQuestionClick: setQuestion,
  orgName,
  orgId,
}: QuestionsProps) {
  const { logEvents } = tracking.useTracking();

  const { data: questions, isLoading } = useQuestions({
    referenceId: orgId,
  });

  const questionsFromToday: JSX.Element[] = [];
  const questionsFromLast7Days: JSX.Element[] = [];
  const questionsFromLast30Days: JSX.Element[] = [];
  const questionsFromMoreThan30Days: JSX.Element[] = [];

  questions?.forEach((question) => {
    if (question.responseReceivedAt) {
      const responseDate = new Date(question.responseReceivedAt);
      const today = new Date();
      const last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
      const last30Days = new Date(new Date().setDate(new Date().getDate() - 30));
      if (responseDate.toDateString() === today.toDateString()) {
        questionsFromToday.push(
          <div key={question.id}>
            <Button
              type="text"
              block
              className={css.historicalQuestionButton}
              onClick={() => {
                logEvents(chatSelectedEvent(question.question, question.id, orgName));
                setQuestion({
                  question: question.question,
                  answer: question.answer ?? "",
                  id: question.id,
                  isComplete: question.isComplete,
                  referenceId: question.referenceId,
                  reportId: question.reportId,
                });
              }}
            >
              <div className={css.buttonContent}>
                <EllipsisTooltipText
                  fullText={question.question}
                  containerClassname={css.wrappedText}
                />
                {question.reportId && <FileDoneOutlined className={css.fileIcon} />}
              </div>
            </Button>
          </div>,
        );
      } else if (responseDate < today && responseDate > last7Days) {
        questionsFromLast7Days.push(
          <div key={question.id}>
            <Button
              type="text"
              block
              className={css.historicalQuestionButton}
              onClick={() => {
                logEvents(chatSelectedEvent(question.question, question.id, orgName));
                setQuestion({
                  question: question.question,
                  answer: question.answer ?? "",
                  id: question.id,
                  isComplete: question.isComplete,
                  referenceId: question.referenceId,
                  reportId: question.reportId,
                });
              }}
            >
              <div className={css.buttonContent}>
                <EllipsisTooltipText
                  fullText={question.question}
                  containerClassname={css.wrappedText}
                />
                {question.reportId && <FileDoneOutlined className={css.fileIcon} />}
              </div>
            </Button>
          </div>,
        );
      } else if (responseDate < last7Days && responseDate > last30Days) {
        questionsFromLast30Days.push(
          <div key={question.id}>
            <Button
              type="text"
              block
              className={css.historicalQuestionButton}
              onClick={() => {
                logEvents(chatSelectedEvent(question.question, question.id, orgName));
                setQuestion({
                  question: question.question,
                  answer: question.answer ?? "",
                  id: question.id,
                  isComplete: question.isComplete,
                  referenceId: question.referenceId,
                  reportId: question.reportId,
                });
              }}
            >
              <div className={css.buttonContent}>
                <EllipsisTooltipText
                  fullText={question.question}
                  containerClassname={css.wrappedText}
                />
                {question.reportId && <FileDoneOutlined className={css.fileIcon} />}
              </div>
            </Button>
          </div>,
        );
      } else if (responseDate < last30Days) {
        questionsFromMoreThan30Days.push(
          <div key={question.id}>
            <Button
              type="text"
              block
              className={css.historicalQuestionButton}
              onClick={() => {
                logEvents(chatSelectedEvent(question.question, question.id, orgName));
                setQuestion({
                  question: question.question,
                  answer: question.answer ?? "",
                  id: question.id,
                  isComplete: question.isComplete,
                  referenceId: question.referenceId,
                  reportId: question.reportId,
                });
              }}
            >
              <div className={css.buttonContent}>
                <EllipsisTooltipText
                  fullText={question.question}
                  containerClassname={css.wrappedText}
                />
                {question.reportId && <FileDoneOutlined className={css.fileIcon} />}
              </div>
            </Button>
          </div>,
        );
      }
    }
  });

  return (
    <div>
      <div className={css.newChatSection}>
        <Button
          type="text"
          onClick={() => {
            logEvents(createNewChatCreatedEvent(orgName));
            newChat();
          }}
          block
          className={css.newChatButton}
        >
          New Chat {<EditOutlined className={css.editIcon} />}
        </Button>
      </div>
      {isLoading && (
        <div>
          <CentredSpinner />
        </div>
      )}
      {questions && questions.length > 0 && (
        <div className={css.history}>
          {questionsFromToday && questionsFromToday.length > 0 && (
            <div>
              <h3>Today</h3>
              {questionsFromToday}
            </div>
          )}
          {questionsFromLast7Days && questionsFromLast7Days.length > 0 && (
            <div>
              <h3>Last 7 days</h3>
              {questionsFromLast7Days}
            </div>
          )}
          {questionsFromLast30Days && questionsFromLast30Days.length > 0 && (
            <div>
              <h3>Last 30 days</h3>
              {questionsFromLast30Days}
            </div>
          )}
          {questionsFromMoreThan30Days && questionsFromMoreThan30Days.length > 0 && (
            <div>
              <h3>30 days +</h3>
              {questionsFromMoreThan30Days}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
