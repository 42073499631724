import React, { ReactElement } from "react";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, message, Popconfirm, Select, Tooltip } from "antd5";

import { ButtonLink } from "components/actions/Links";
import {
  NoticeConfigBuilderRequest,
  NoticeConfigBuilderResponseStatusEnum,
} from "lib/generated/integration-api";
import { useDescribeEntity } from "lib/hooks/api/integrations/useDescribeEntity";
import { useViewNoticeFieldsProvider } from "lib/hooks/api/integrations/useDescribeNoticeFieldsProvider";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useListNoticeEntitiesProvider } from "lib/hooks/api/integrations/useListNoticeEntitiesProvider";
import { useUpdateNoticeConfig } from "lib/hooks/api/integrations/useUpdateNoticeConfig";
import { EventNames, useTracking } from "lib/tracking";
import { getIntegrationIcon, getProviderMetadata } from "./util";

import css from "./IntegrationSetup.module.scss";

import LogoURL from "../../../assets/images/stotles_logo.svg";

type FormValue = {
  /**
   * Id/key for CRM
   */
  id: string;
  /**
   * Display name
   */
  name: string;
  /**
   * Wether it is required by the CRM
   */
  required: boolean;
  /**
   * If the user has marked the field as required for pushing notices to CRM
   * Fields that are required by the CRM cannot be unchecked by the user
   */
  hasMarkedRequired: boolean;
};

export function IntegrationSetup() {
  const [selectedEntity, setSelectedEntity] = React.useState<string>("");
  const [addFieldVisible, setAddFieldVisible] = React.useState<boolean>(true);
  const [formState, setFormState] = React.useState<Record<string, FormValue>>({}); // store fieldId and formValues
  const { logEvent } = useTracking();
  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;
  const { data: noticeEntities } = useListNoticeEntitiesProvider();
  const { data: noticeFields } = useViewNoticeFieldsProvider();
  const { data: entityDetails } = useDescribeEntity(selectedEntity, {
    enabled: selectedEntity.length !== 0,
  });
  const { mutate: updateNoticeConfig, isLoading } = useUpdateNoticeConfig({
    onSuccess: (response) => {
      if (response.status === NoticeConfigBuilderResponseStatusEnum.Failure) {
        void message.error(response.message ?? "Error saving settings");
        return;
      }

      logEvent(EventNames.settingsSavedForIntegration, {
        "Page source": "Integration Configuration",
        "Connected provider": providerType,
      });

      void message.success(`${providerName} settings successfully saved`);
    },
  });

  React.useEffect(() => {
    if (noticeEntities && noticeEntities.entities.length > 0) {
      // set the initial option
      setSelectedEntity(noticeEntities.entities[0]);
    }
  }, [noticeEntities]);

  React.useEffect(() => {
    const fields: Record<string, FormValue> = {};

    if (entityDetails && noticeFields) {
      for (const field of noticeFields.fields) {
        const fieldData = entityDetails.entity.fields.find((x) => x.id === field.id);
        if (fieldData) {
          fields[fieldData.id] = {
            required: fieldData.required,
            id: field.id,
            name: fieldData.name,
            hasMarkedRequired: field.required,
          };
        }
      }
    }

    setFormState(fields);
  }, [entityDetails, noticeFields]);

  const remainingFields = React.useMemo(() => {
    return entityDetails?.entity.fields.filter((x) => !formState[x.id]);
  }, [entityDetails, formState]);

  if (!selectedEntity || !noticeFields || !entityDetails || !providerType || !remainingFields) {
    return <></>;
  }

  const providerIcon = getIntegrationIcon(providerType);
  const providerName = getProviderMetadata(providerType).name;

  const handleFieldRequired = (id: string, checked: boolean) => {
    setFormState((oldState) => ({
      ...oldState,
      [id]: {
        ...oldState[id],
        hasMarkedRequired: checked,
      },
    }));
    const event = checked
      ? EventNames.fieldMarkedRequiredForIntegration
      : EventNames.fieldMarkedNotRequiredForIntegration;
    logEvent(event, {
      "Page source": "Integration Configuration",
      "Connected provider": providerType,
      "Field id": id,
      "Stotles object": entityDetails.entity.name,
    });
  };

  const handleFieldDelete = (id: string) => {
    setFormState((oldState) => {
      const newState = { ...oldState };
      delete newState[id];
      return newState;
    });

    logEvent(EventNames.fieldRemovedForIntegration, {
      "Page source": "Integration Configuration",
      "Connected provider": providerType,
      "Field id": id,
      "Stotles object": entityDetails.entity.name,
    });
  };

  const handleAddField = (value: ReactElement) => {
    if (typeof value === "string") {
      const entityField = entityDetails.entity.fields.find((x) => x.id === value);
      if (entityField) {
        setFormState((current) => ({
          ...current,
          [entityField.id]: {
            required: entityField.required,
            id: entityField.id,
            name: entityField.name,
            hasMarkedRequired: entityField.required,
          },
        }));
        setAddFieldVisible(true);
      }

      logEvent(EventNames.fieldAddedForIntegration, {
        "Page source": "Integration Configuration",
        "Connected provider": providerType,
        "Field id": value,
        "Stotles object": entityDetails.entity.name,
      });
    }
  };

  const handleSave = () => {
    const request: NoticeConfigBuilderRequest = {
      entityName: selectedEntity,
      fields: [],
    };

    for (const [key, value] of Object.entries(formState)) {
      let stotlesField = "";

      // temp work around TODO: this needs to move to the backend
      if (key === "dealname" || key === "Name" || key === "Surname") {
        stotlesField = "title";
      }
      if (key === "hubspot_owner_id" || key === "OwnerId") {
        stotlesField = "owner";
      }
      if (key === "accountId" || key === "AccountId") {
        stotlesField = "account";
      }
      if (key === "amount" || key === "Amount") {
        stotlesField = "value";
      }
      if (key === "description" || key === "Description") {
        stotlesField = "description";
      }
      if (key === "closedate" || key === "CloseDate") {
        stotlesField = "closeDate";
      }

      request.fields.push({
        name: key,
        isRequired: value.hasMarkedRequired,
        stotlesField,
      });
    }

    updateNoticeConfig({ request });
  };

  return (
    <div className={css.setupPage}>
      <div className={css.integrationLogos}>
        <div className={css.providerHeaderLogo}>{providerIcon}</div>
        <img className={css.stotlesHeaderLogo} src={LogoURL} alt="Stotles logo" />
      </div>
      <h2 className={css.title}>Customise your {providerName} Integration</h2>
      <p>Select the the {providerName} fields you would like to push to from Stotles.</p>
      <div className={css.navigation}>
        <ButtonLink
          ghost
          to="/account-management/integrations"
          className={css.backButton}
          eventName={EventNames.backButtonFromIntegrationClicked}
          eventAttributes={{
            "Page source": "Integration Configuration",
            "Connected provider": providerType,
          }}
        >
          <ArrowLeftOutlined /> Back
        </ButtonLink>
        <ButtonLink
          type="primary"
          to="/"
          eventName={EventNames.viewFeedFromIntegrationClicked}
          eventAttributes={{
            "Page source": "Integration Configuration",
            "Connected provider": providerType,
          }}
        >
          Go to your feed
        </ButtonLink>
      </div>
      <div className={css.setupForm}>
        <div className={css.setupFormHeader}>
          <span className={css.heading}>
            When pushing to <span className={css.inlineProviderLogo}>{providerIcon}</span>
            <b> {selectedEntity}s </b>
            from
            <img className={css.inlineStotlesLogo} src={LogoURL} alt="Stotles logo" />
            <b>Procurement notices</b>
          </span>
          <p>
            Choose which {providerName} fields you want to fill in when pushing Procurement notices
            to your {selectedEntity}s.
          </p>
        </div>
        <div className={css.setupBody}>
          <table className={css.fieldTable}>
            <tbody>
              <tr className={css.fieldHeading}>
                <th>{providerName}</th>
                <th>
                  Required{" "}
                  <Tooltip title="Select which fields you want to be required before pushing to your CRM from Stotles.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </th>
              </tr>
              {Object.entries(formState).map(([fieldId, formValue]) => (
                <tr key={fieldId}>
                  <td>{formValue.name}</td>
                  <td>
                    <Checkbox
                      checked={formValue.hasMarkedRequired}
                      disabled={formValue.required}
                      onChange={(e) => {
                        handleFieldRequired(formValue.id, e.target.checked);
                      }}
                    />
                  </td>
                  <td>
                    {!formValue.required && (
                      <Popconfirm
                        title={`Are you sure you want to delete this field?`}
                        okText="Ok"
                        cancelText="Cancel"
                        onConfirm={() => {
                          handleFieldDelete(formValue.id);
                        }}
                      >
                        <DeleteOutlined className={css.removeField} />
                      </Popconfirm>
                    )}
                  </td>
                </tr>
              ))}
              {remainingFields.length > 0 && (
                <tr>
                  <td>
                    <Select
                      showSearch={true}
                      placeholder={
                        <>
                          <PlusOutlined /> Add Fields
                        </>
                      }
                      // Slightly unique in that we want to override the value
                      value={
                        <>
                          <PlusOutlined /> Add Fields
                        </>
                      }
                      onSelect={(value: ReactElement) => handleAddField(value)}
                      className={css.fieldSelect}
                      showArrow={false}
                      options={remainingFields?.map((x) => ({
                        value: entityDetails.entity.fields.find((f) => f.id === x.id)?.id,
                        label: x.name,
                      }))}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={css.setupFooter}>
          <Button
            onClick={handleSave}
            disabled={!addFieldVisible}
            loading={isLoading}
            type="primary"
          >
            Save settings
          </Button>
        </div>
      </div>
    </div>
  );
}
