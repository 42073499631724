/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    companyId: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    admin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    configured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    sendTiUpdates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    showBuyerCategories: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    hasExportAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    hasAccountBriefingAccess: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    userPersona?: CreateUserRequestUserPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    pubSecExperience?: CreateUserRequestPubSecExperienceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    hubspotContactId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    teamId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    dataTypeSubscriptions?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    subscription: CreateUserRequestSubscriptionEnum;
}


/**
 * @export
 */
export const CreateUserRequestUserPersonaEnum = {
    StrategyLeadership: 'Strategy / Leadership',
    OperationalEndUser: 'Operational / End User'
} as const;
export type CreateUserRequestUserPersonaEnum = typeof CreateUserRequestUserPersonaEnum[keyof typeof CreateUserRequestUserPersonaEnum];

/**
 * @export
 */
export const CreateUserRequestPubSecExperienceEnum = {
    None: 'none',
    Limited: 'limited',
    Familiar: 'familiar',
    Mature: 'mature',
    Buyer: 'buyer'
} as const;
export type CreateUserRequestPubSecExperienceEnum = typeof CreateUserRequestPubSecExperienceEnum[keyof typeof CreateUserRequestPubSecExperienceEnum];

/**
 * @export
 */
export const CreateUserRequestSubscriptionEnum = {
    Freemium: 'freemium',
    Growth: 'growth',
    Expert: 'expert'
} as const;
export type CreateUserRequestSubscriptionEnum = typeof CreateUserRequestSubscriptionEnum[keyof typeof CreateUserRequestSubscriptionEnum];


/**
 * Check if a given object implements the CreateUserRequest interface.
 */
export function instanceOfCreateUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "configured" in value;
    isInstance = isInstance && "sendTiUpdates" in value;
    isInstance = isInstance && "showBuyerCategories" in value;
    isInstance = isInstance && "hasExportAccess" in value;
    isInstance = isInstance && "hasAccountBriefingAccess" in value;
    isInstance = isInstance && "hubspotContactId" in value;
    isInstance = isInstance && "subscription" in value;

    return isInstance;
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'role': json['role'],
        'email': json['email'],
        'companyId': json['company_id'],
        'admin': json['admin'],
        'configured': json['configured'],
        'sendTiUpdates': json['send_ti_updates'],
        'showBuyerCategories': json['show_buyer_categories'],
        'hasExportAccess': json['has_export_access'],
        'hasAccountBriefingAccess': json['has_account_briefing_access'],
        'userPersona': !exists(json, 'user_persona') ? undefined : json['user_persona'],
        'pubSecExperience': !exists(json, 'pub_sec_experience') ? undefined : json['pub_sec_experience'],
        'hubspotContactId': json['hubspot_contact_id'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
        'dataTypeSubscriptions': !exists(json, 'data_type_subscriptions') ? undefined : json['data_type_subscriptions'],
        'subscription': json['subscription'],
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'role': value.role,
        'email': value.email,
        'company_id': value.companyId,
        'admin': value.admin,
        'configured': value.configured,
        'send_ti_updates': value.sendTiUpdates,
        'show_buyer_categories': value.showBuyerCategories,
        'has_export_access': value.hasExportAccess,
        'has_account_briefing_access': value.hasAccountBriefingAccess,
        'user_persona': value.userPersona,
        'pub_sec_experience': value.pubSecExperience,
        'hubspot_contact_id': value.hubspotContactId,
        'team_id': value.teamId,
        'data_type_subscriptions': value.dataTypeSubscriptions,
        'subscription': value.subscription,
    };
}

