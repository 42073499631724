import React from "react";
import { Input } from "antd5";

type Props = {
  onSearch: (companyGuid: string) => void;
  companyName?: string;
};

function SwitchCompanyByGuid(props: Props) {
  const onSearch = (value: string) => {
    const companyGuid = value;
    props.onSearch(companyGuid);
  };

  return (
    <div>
      <Input.Search
        style={{ width: "300px" }}
        placeholder="Insert Company GUID"
        onSearch={onSearch}
        enterButton
      />
      {props.companyName && <p>{props.companyName}</p>}
    </div>
  );
}

export default SwitchCompanyByGuid;
