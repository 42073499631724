import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type Params = { ids: string[] };

export function useDeleteLeadSubscriptionContent(
  options?: UseMutationOptions<void, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(
    ({ ids }: Params) =>
      api.deleteLeadSubscriptionContent({ deleteLeadSubscriptionContentRequest: { ids } }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["leadSubscriptionContents"]);
        void queryClient.invalidateQueries(["leadSubscriptions"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
