//Primitives

// bluesNEW(14.06.2024)
export const blue25 = "#F7FAFF";
export const blue50 = "#EDF3FF";
export const blue100 = "#CADBFE";
export const blue200 = "#9DBCFB";
export const blue300 = "#84AAFB";
export const blue400 = "#4F86FA";
export const blue500 = "#2569F9";
export const blue600 = "#074FE9";
export const blue700 = "#0644C6";
export const blue800 = "#04369F";
export const blue900 = "#032772";

// greysNEW(14.06.2024)
export const grey0 = "#FFFFFF";
export const grey10 = "#F7F7F8";
export const grey25 = "#EEEFF1";
export const grey50 = "#E6E7EA";
export const grey100 = "#D5D8DD";
export const grey200 = "#B3B8C1";
export const grey300 = "#9299A6";
export const grey400 = "#707A8B";
export const grey500 = "#4F5A6F";
export const grey600 = "#2D3B54";
export const grey700 = "#263247";
export const grey800 = "#1F2839";
export const grey900 = "#171F2C";
export const grey1000 = "#10151E";

// redsNEW(14.06.2024)
export const red50 = "#FFEEF0";
export const red100 = "#FECDD1";
export const red200 = "#FDABB3";
export const red300 = "#FB8994";
export const red400 = "#FA5766";
export const red500 = "#E9071C";
export const red600 = "#D10519";
export const red700 = "#BF1642";
export const red800 = "#A80514";
export const red900 = "#81040F";

// greensNEW(14.06.2024)
export const green50 = "#E5F5EB";
export const green100 = "#D5EEDD";
export const green200 = "#B3E1C2";
export const green300 = "#91D3A7";
export const green400 = "#6FC68B";
export const green500 = "#4DB870";
export const green600 = "#2BAB55";
export const green700 = "#249047";
export const green800 = "#1D743A";
export const green900 = "#0F3E1F";

// greensOLD(Mapped to New primitives 14.06.2024)
export const green = green500;
export const openGreen = green600;

// basicsNEW(14.06.2024)
export const white = "#FFFFFF";
export const black = "#000000";

// greysOLD
export const borderGrey = grey50;

//opacity dark OLD
export const opacityDark2 = "rgba(23, 31, 44, 0.02)";

// yellowNEW(14.06.2024)
export const yellow50 = "#FFFBF0";
export const yellow100 = "#FFF3D3";
export const yellow200 = "#FFECB6";
export const yellow300 = "#FFE498";
export const yellow400 = "#FFD86C";
export const yellow500 = "#FFC932";
export const yellow600 = "#FAB800";
export const yellow700 = "#D69E00";
export const yellow800 = "#9E7400";
export const yellow900 = "#423100";
// TODO: update theses names to use the correct colour names
export const yellow = "#FFC932";

// purpleNEW(14.06.2024)
export const purple50 = "#F2EFF9";
export const purple100 = "#D7CFED";
export const purple200 = "#C9BEE6";
export const purple300 = "#A18ED4";
export const purple400 = "#785DC1";
export const purple500 = "#6345B5";
export const purple600 = "#4732A8";
export const purple700 = "#473181";
export const purple800 = "#392767";
// TODO: update theses names to use the correct colour names
export const purple = "#785DC1";

//SemanticsNEW(14.06.2024)

// semantics // primary
export const sysPrimaryDefault = blue500;
export const sysPrimaryHover = blue600;
export const sysPrimaryPressed = blue700;
export const sysPrimarySubtle = blue50;
export const sysPrimarySubtleAlt = blue100;

// semantics // text
export const sysTextDefault = grey700;
export const sysTextSecondary = grey500;
export const sysTextInactive = grey300;
export const sysTextPlaceholder = grey400;
export const sysTextWhite = grey0;
export const sysTextBlack = black;

// semantics // background
export const sysBackgroundDefault = white;
export const sysBackgroundAlternative = grey10;
export const sysBackgroundInactive = grey25;
export const sysBackgroundInverse = grey600;
export const sysBackgroundOverlay = grey900;

// semantics // border
export const sysBorderPrimary = grey50;
export const sysBorderSecondary = grey10;
export const sysBorderInverse = white;

// semantics // focus
export const sysFocusBr = blue900;

// semantics // signal
export const sysSignalDefault = grey50;
export const sysSignalKeyword = blue100;
export const sysSignalCompetitor = red100;
export const sysSignalPartner = yellow200;
export const sysSignalBuyer = purple100;

// semantics // success
export const sysSuccessDefault = green600;
export const sysSuccessHover = green700;
export const sysSuccessPressed = green800;
export const sysSuccessSubtle = green50;
export const sysSuccessSubtleAlt = green100;

// semantics // destructive
export const sysDestructiveDefault = red500;
export const sysDestructiveHover = red600;
export const sysDestructivePressed = red800;
export const sysDestructiveSubtle = red50;
export const sysDestructiveSubtleAlt = red100;

// semantics // warning
export const sysWarningDefault = yellow600;
export const sysWarningHover = yellow700;
export const sysWarningPressed = yellow800;
export const sysWarningSubtle = yellow50;
export const sysWarningSubtleAlt = yellow100;

// semantics // info
export const sysInfoDefault = blue500;
export const sysInfoHover = blue600;
export const sysInfoPressed = blue700;
export const sysInfoSubtle = blue50;
export const sysInfoSubtleAlt = blue100;

// semantics // status
export const sysStatusPositive = green500;
export const sysStatusNegative = red400;
export const sysStatusInProgress = yellow500;

//SemanticsOLD

export const errorRed = red600;
// TODO: update below names to use the correct colour names
export const brandRed = "#FA5766";
export const signalKeyword = blue200;
export const signalCompetitor = red200;
export const signalPartner = yellow200;
export const signalBuyer = purple200;
export const signalCpvCode = blue200;
