import * as React from "react";

import { TextLink } from "components/actions/Links";
import SaveSupplier from "components/suppliers/SaveSupplier";
import SignalsContainer from "components/tags/SignalsContainer";
import Property from "lib/core_components/Property";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { SupplierDetailsDto, SupplierDetailsDtoRefOrgInfoSourceEnum } from "lib/generated/app-api";
import { useSupplierStats } from "lib/hooks/api/suppliers/useSupplierStats";
import { useSupplierStatsGql } from "lib/hooks/api/suppliers/useSupplierStatsGql";
import { SignalCategory } from "lib/StotlesApi";
import { EventData, EventNames } from "lib/tracking";
import { EM_DASH, formatAddress } from "lib/utils";
import { PrimaryTab, SecondaryTab } from "./supplier_utils";
import SupplierHeaderCards from "./SupplierHeaderCards";

import css from "./SupplierHeader.module.scss";

import SupplierIcon from "../../../assets/images/icons/supplier.svg";

type Props = {
  handleNavigation: (
    location: PrimaryTab | SecondaryTab,
    eventName: EventNames,
    data: EventData,
  ) => void;
  supplier: SupplierDetailsDto;
};

export default function SupplierHeader({ handleNavigation, supplier }: Props): JSX.Element {
  // Still need to run this API for now because we don't really have signals or
  // saved buyers on organisations in app-service yet
  const { data: supplierStats, isLoading } = useSupplierStats(
    { guid: supplier.guid },
    { enabled: !!window.currentUser },
  );

  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);

  const supplierSignalsEnabled =
    enableNewSupplierFilter && window.currentUser?.use_supplier_name === false;

  // TODO: Add top-level signals & saved buyers to this API
  const { data: organisationStats, isLoading: isLoadingNoticeSearch } = useSupplierStatsGql(
    supplier.guid,
    {
      enabled: supplierSignalsEnabled,
    },
  );

  const refOrg = supplier.referenceOrganisations.find(
    (ro) =>
      ro.source === SupplierDetailsDtoRefOrgInfoSourceEnum.CompaniesHouse ||
      ro.source === SupplierDetailsDtoRefOrgInfoSourceEnum.DunAndBradstreet,
  );

  return (
    <div className={css.header} aria-label="Supplier header">
      <div className={css.inlineTitleActions}>
        <span className={css.title}>
          <img src={SupplierIcon} className={css.placeholderIcon} />
          <h1>{supplier.name}</h1>
        </span>
        {!window.guestUser && supplierSignalsEnabled && (
          <SaveSupplier
            supplierGuid={supplier.guid}
            supplierName={supplier.name}
            triggerType="button"
            contextSource="Supplier profile"
          />
        )}
      </div>
      <div className={css.stats} aria-label="Supplier properties">
        <Property className={css.statistic} title="Country" value={supplier.country} />
        {FeatureToggles.isEnabled(Feature.SUPPLIER_PROFILE_REF_ORG_INFO) && refOrg ? (
          <Property
            className={css.statistic}
            title="Address"
            value={
              <TextLink
                to={`https://www.google.com/maps/search/?api=1&query=${formatAddress(
                  refOrg.address,
                )}`}
                targetType="external"
              >
                {formatAddress(refOrg.address)}
              </TextLink>
            }
          />
        ) : (
          <Property className={css.statistic} title="Region" value={supplier.address.town} />
        )}
        <Property
          className={css.statistic}
          title="SME"
          value={supplier.isSme ? "Yes" : supplier.isSme === false ? "No" : EM_DASH}
        />
        {FeatureToggles.isEnabled(Feature.SUPPLIER_PROFILE_REF_ORG_INFO) &&
          refOrg?.basicContactInfo.websiteUrl && (
            <Property
              className={css.statistic}
              title="Website"
              value={
                <TextLink to={refOrg.basicContactInfo.websiteUrl} targetType="external">
                  {refOrg.basicContactInfo.websiteUrl}
                </TextLink>
              }
            />
          )}
        <Property
          title="Signals"
          value={
            supplierStats?.signals ? (
              <SignalsContainer
                signals={supplierStats.signals}
                maxSignals={3}
                requiredDataType="SUPPLIERS"
                redactedSignalCategories={[SignalCategory.COMPETITOR, SignalCategory.PARTNER]}
                contextSource="Supplier header"
              />
            ) : null
          }
        />
      </div>

      {enableNewSupplierFilter && window.currentUser?.use_supplier_name === false ? (
        // I think it's fine to show these cards to guest users, as they do not depend on signals
        <SupplierHeaderCards
          handleNavigation={handleNavigation}
          awardsCount={organisationStats?.directAwards.totalResults || 0}
          mentionsCount={organisationStats?.mentionedNotices.totalResults || 0}
          expiriesCount={organisationStats?.upcomingExpiries.totalResults || 0}
          keyAccountsCount={supplierStats?.keyAccountsCount || 0} // TODO: Add key accounts to the organisationStats API
          isLoading={isLoadingNoticeSearch}
        />
      ) : (
        <>
          {/*  Don't show cards for guest users (API doesn't fire for guest users), as they don't have signals yet */}
          {supplierStats && (
            <SupplierHeaderCards
              handleNavigation={handleNavigation}
              awardsCount={supplierStats?.awardsCount || 0}
              mentionsCount={0}
              expiriesCount={supplierStats?.expiriesCount || 0}
              keyAccountsCount={supplierStats?.keyAccountsCount || 0}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </div>
  );
}
