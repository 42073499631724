import * as React from "react";
import { Tooltip } from "antd5";

import { formatDate } from "lib/utils";

type Props = {
  size?: number;
  className?: string;
  ariaLabel?: string;
  verificationDate?: Date;
};

export default function VerifiedProviderIcon({
  size = 16,
  ariaLabel = "framework-verified-icon",
  className,
  verificationDate,
}: Props): JSX.Element {
  const formattedDate = verificationDate ? formatDate(verificationDate) : "";
  return (
    <Tooltip
      overlayStyle={{ maxWidth: "240px", textAlignLast: "center" }}
      title={`This framework was verified by the provider${
        verificationDate ? ` on ${formattedDate}` : ""
      }`}
      placement="top"
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        aria-label={ariaLabel}
      >
        <g clipPath="url(#clip0_20_64857)">
          <path
            d="M6.00004 7.99992L7.33337 9.33325L10.3334 6.33325M11.9342 3.33226C12.0714 3.66427 12.3349 3.92818 12.6667 4.06597L13.8302 4.5479C14.1622 4.68544 14.426 4.94925 14.5636 5.28129C14.7011 5.61333 14.7011 5.98641 14.5636 6.31846L14.082 7.48112C13.9444 7.81331 13.9442 8.18677 14.0824 8.5188L14.5632 9.68112C14.6313 9.84557 14.6664 10.0219 14.6665 10.1999C14.6665 10.3779 14.6315 10.5542 14.5633 10.7187C14.4952 10.8832 14.3953 11.0326 14.2694 11.1585C14.1435 11.2843 13.9941 11.3842 13.8296 11.4522L12.6669 11.9338C12.3349 12.0711 12.071 12.3346 11.9333 12.6664L11.4513 13.8299C11.3138 14.162 11.05 14.4258 10.718 14.5633C10.3859 14.7009 10.0129 14.7009 9.68085 14.5633L8.51823 14.0817C8.18619 13.9445 7.81326 13.9448 7.48142 14.0825L6.31797 14.5638C5.98612 14.701 5.61338 14.7009 5.28162 14.5634C4.94986 14.426 4.68621 14.1625 4.54858 13.8308L4.06652 12.667C3.92924 12.335 3.66574 12.0711 3.33394 11.9333L2.17048 11.4513C1.8386 11.3139 1.57488 11.0502 1.4373 10.7184C1.29971 10.3865 1.29953 10.0136 1.43678 9.68161L1.91835 8.51895C2.05554 8.1869 2.05526 7.81395 1.91757 7.48211L1.43669 6.31777C1.36851 6.15332 1.3334 5.97704 1.33337 5.79901C1.33335 5.62098 1.3684 5.44469 1.43652 5.28021C1.50465 5.11573 1.60452 4.96629 1.73042 4.84042C1.85632 4.71455 2.00579 4.61473 2.17028 4.54665L3.33291 4.06506C3.66462 3.9279 3.92836 3.66472 4.06624 3.33329L4.54816 2.16979C4.68569 1.83775 4.94949 1.57394 5.28152 1.4364C5.61355 1.29887 5.98662 1.29887 6.31865 1.4364L7.48127 1.918C7.81331 2.05519 8.18624 2.05491 8.51808 1.91722L9.68202 1.43715C10.014 1.29969 10.387 1.29972 10.719 1.43723C11.0509 1.57474 11.3147 1.83847 11.4522 2.17043L11.9343 3.33427L11.9342 3.33226Z"
            stroke="#3970E4"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_20_64857">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
}
