import React, { useMemo, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import _ from "lodash";

import CentredSpinner from "../../../../lib/core_components/CentredSpinner";
import { useSignalSettingsGQL } from "../../../../lib/hooks/api/teams/useSignalSettingsGQL";
import InfiniteSuppliersChecklist from "../../../form_components/dropdown_list_selects/new_supplier_selects/InfiniteSuppliersChecklist";
import { SupplierOption } from "../../../form_components/dropdown_list_selects/new_supplier_selects/types";
import { SupplierSelect } from "../../../form_components/SupplierSelect";
import { SupplierTagMultiselectExplanation } from "../../../form_components/SupplierTagMultiselect";
import { MatchCountMap } from "../../../onboarding/SharedOnboarding";
import Tag, { TagProps } from "../../../ui/tag/Tag";

import css from "./SupplierInput.module.scss";

type SimpleOrg = {
  id: string;
  name: string;
};

type TextSearch = {
  competitor: string;
  partner: string;
};

const convertOrgToSupplierOption = (orgs?: SimpleOrg[] | null): SupplierOption[] => {
  if (!orgs) return [];
  return orgs.map((o) => ({ label: o.name, value: o.id }));
};

const handleTagRender = (tagProps: TagProps, counts: MatchCountMap | undefined) => {
  const supplierName = tagProps.label?.toString();
  const supplierCount = supplierName ? counts?.[supplierName] : undefined;

  if (supplierName == null) {
    return <Tag key={tagProps.id} label={tagProps.label} />;
  }

  return (
    <Tag
      key={tagProps.id}
      label={`${supplierName} ${supplierCount == null ? "" : `(${supplierCount})`}`}
    />
  );
};

const flattenValues = (values: (string | SupplierOption)[]): string[] => {
  return values.map((v) => {
    if (typeof v === "object" && "value" in v && typeof v.value === "string") {
      return v.value;
    }
    return String(v);
  });
};

type SupplierInputProps<T extends FieldValues> = {
  competitorFieldName: Path<T>;
  partnerFieldName: Path<T>;
  control: Control<T, unknown>;
  competitorCounts: MatchCountMap | undefined;
  partnerCounts: MatchCountMap | undefined;
};

export default function SupplierInput<T extends FieldValues>({
  competitorFieldName,
  partnerFieldName,
  control,
  competitorCounts,
  partnerCounts,
}: SupplierInputProps<T>) {
  const { data: signals, isLoading: isLoadingSignals } = useSignalSettingsGQL();

  const competitorField = useController({
    control,
    name: competitorFieldName,
  });
  const partnerField = useController({
    control,
    name: partnerFieldName,
  });

  const [textSearch, setTextSearch] = useState<TextSearch>({
    competitor: "",
    partner: "",
  });

  const competitors = useMemo(
    () => convertOrgToSupplierOption(signals?.competitors),
    [signals?.competitors],
  );

  const partners = useMemo(
    () => convertOrgToSupplierOption(signals?.partners),
    [signals?.partners],
  );

  const allSignalSuppliers = useMemo(() => _.union(competitors, partners), [competitors, partners]);

  if (isLoadingSignals) {
    return <CentredSpinner />;
  }

  const renderInfiniteSuppliersChecklist = (supplierType: "competitor" | "partner") => (
    <InfiniteSuppliersChecklist
      signalSuppliers={allSignalSuppliers}
      excludeSignals={true}
      textSearch={textSearch[supplierType]}
      selectedSuppliers={
        (supplierType === "competitor" ? competitorField : partnerField).field.value.map(
          (v: string) => ({
            label: v,
            value: v,
          }),
        ) ?? []
      }
      onChange={(values) => {
        const field = supplierType === "competitor" ? competitorField : partnerField;
        field.field.onChange(flattenValues(values));
      }}
    />
  );

  const renderSupplierSelect = (
    fieldName: Path<T>,
    title: string,
    supplierType: "competitor" | "partner",
    tagVariant: "error" | "warning",
    counts: MatchCountMap | undefined,
  ) => (
    <div className={css.section}>
      <div className={css.header}>
        <p className={css.title}>{title}</p>
        <SupplierTagMultiselectExplanation supplierType={supplierType} useSupplierName={false} />
      </div>
      <SupplierSelect
        name={fieldName}
        label=""
        control={control}
        mode="multiple"
        placeholder={`Search ${supplierType}s...`}
        allowClear
        idType="guid"
        tagVariant={tagVariant}
        tagRender={(tagProps) => handleTagRender(tagProps, counts)}
        onCustomSearch={(text) => setTextSearch({ ...textSearch, [supplierType]: text })}
        dropdownRender={(_) => renderInfiniteSuppliersChecklist(supplierType)}
      />
    </div>
  );

  return (
    <div className={css.container}>
      {renderSupplierSelect(
        competitorFieldName,
        "Competitors",
        "competitor",
        "error",
        competitorCounts,
      )}
      {renderSupplierSelect(partnerFieldName, "Partners", "partner", "warning", partnerCounts)}
    </div>
  );
}
