import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type Params = {
  fileId: number;
  createdBy: string;
  url: string;
  sourceUrl?: string;
  alternativeSource?: string;
  organisationIds: string[];
  category: string;
  publishDate: string;
  title?: string;
  description?: string;
};

export function useUploadUrlDocument(
  options?: UseMutationOptions<object, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      createdBy,
      url,
      sourceUrl,
      alternativeSource,
      organisationIds,
      category,
      publishDate,
      title,
      description,
    }: Params) =>
      api.uploadUrlDocument({
        body: {
          createdBy: createdBy,
          url: url,
          sourceUrl: sourceUrl,
          alternativeSource: alternativeSource,
          organisations: organisationIds,
          category: category,
          publishedAt: publishDate,
          title: title,
          description: description,
        },
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["documentsAdmin"]);
        void queryClient.invalidateQueries(["documents"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
