import * as React from "react";
import { Alert, Modal } from "antd5";

import { ButtonLink } from "components/actions/Links";
import { ProviderTypes } from "lib/generated/integration-api";
import { ExternalLink } from "lib/icons/ExternalLink";
import { EventNames, useTracking } from "lib/tracking";
import { getProviderMetadata } from "./util";

import css from "./LearnMoreModal.module.scss";

type Props = {
  providerType: ProviderTypes;
  onClose: () => void;
  isOpen: boolean;
};

export default function LearnMoreModal({ providerType, isOpen, onClose }: Props) {
  const metadata = getProviderMetadata(providerType);
  const { logEvent } = useTracking();
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={
        <ButtonLink
          targetType="external"
          className={css.setUpButton}
          showIcon={false}
          to={metadata.learnMoreModal.setupLink}
          onClick={() => {
            logEvent(EventNames.setUpIntegrationGuidelinesClicked, { Provider: providerType });
          }}
        >
          <ExternalLink className={css.icon} />
          Set up Guidelines
        </ButtonLink>
      }
    >
      <h1 className={css.title}>Why use our {metadata.name} integration?</h1>
      <Alert
        type="warning"
        className={css.adminMessage}
        message={metadata.learnMoreModal.adminMessage}
      />
      {metadata.learnMoreModal.contentBlocks.map((block) => (
        <div key={block.subtitle}>
          <h2 className={css.subtitle}>{block.subtitle}</h2>
          <p className={css.textBlock}>{block.text}</p>
        </div>
      ))}
    </Modal>
  );
}
