import StotlesAdminAPI from "lib/StotlesAdminApi";
import {
  ApiError,
  ApiErrorFromJSON,
  Configuration,
  DefaultApi,
  instanceOfApiError,
  ResponseError,
} from "./generated/admin-api";
import { Configuration as DataServiceConfiguration, SearchApi } from "./generated/data-svc";
import { defineInjectable } from "./reactInjector";
import { getCSRFToken } from "./utils";

type AdminAPI = {
  legacyAPI: StotlesAdminAPI;
  api: DefaultApi;
  searchApi: SearchApi;
};

const ADMIN_API_INJECTABLE = defineInjectable<AdminAPI>("AdminAPI", () => {
  const apiConfig = new Configuration({
    basePath: "",
    headers: {
      "X-CSRF-Token": getCSRFToken() as string,
    },
  });

  const dataServiceConfig = new DataServiceConfiguration({
    basePath: "",
    headers: {
      "X-CSRF-Token": getCSRFToken() as string,
    },
  });

  return {
    legacyAPI: new StotlesAdminAPI(),
    api: new DefaultApi(apiConfig),
    searchApi: new SearchApi(dataServiceConfig),
  };
});

export const useAdminApi = ADMIN_API_INJECTABLE.useInstance;

// TODO: Remove once we migrate all admin code to use OpenAPI-generated API classes
export function useStotlesAdminApi(): StotlesAdminAPI {
  const { legacyAPI: stotlesAdminApi } = useAdminApi();
  return stotlesAdminApi;
}

export const AdminAPIProvider = ADMIN_API_INJECTABLE.Provider;

export async function extractApiError(error: ResponseError): Promise<ApiError> {
  const body = await error.response.json();
  if (!instanceOfApiError(body)) {
    throw error;
  }
  return ApiErrorFromJSON(body);
}
