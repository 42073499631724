import React from "react";
import { Tabs } from "antd5";

import ContactsTable from "components/contacts/ContactsTable";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { BuyerDetails } from "lib/types/models";
import { HandleNavigation, SecondaryTab } from "../types";
import { ProcurementContacts } from "./Procurement";

import css from "./ContactsPanel.module.scss";

type PanelProps = {
  buyer: BuyerDetails;
  handleTabNavigation: HandleNavigation;
  activeTab: SecondaryTab | null;
};

export default function ContactsPanel({
  buyer,
  handleTabNavigation,
  activeTab,
}: PanelProps): JSX.Element {
  const { authorised: hasContacts } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Decision maker contacts table",
  });

  return (
    <Tabs
      activeKey={activeTab ? activeTab : SecondaryTab.CONTACTS}
      onChange={(key) => handleTabNavigation(key as SecondaryTab)}
      items={[
        {
          key: SecondaryTab.CONTACTS,
          label: <>Decision-makers{!hasContacts && <PaywallStar className={css.paywallIcon} />}</>,
          children: (
            <ContactsTable
              buyerId={buyer.guid}
              contextSource={"Decision maker contacts table"}
              pageSize={10}
              showDropdownFilters={true}
              showAllFiltersDrawer={false}
            />
          ),
        },
        {
          key: SecondaryTab.PROCUREMENT,
          label: "Procurement",
          children: <ProcurementContacts buyer={buyer} />,
        },
      ]}
    />
  );
}
