import React, { useState } from "react";
import { Tag } from "antd5";
import { isEqual, startCase } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import { TextLink } from "components/actions/Links";
import { formatNumber } from "components/app_layout/Typography";
import SignalsContainer from "components/tags/SignalsContainer";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { IN_ROW_STAT, Table } from "lib/core_components/Table/Table";
import { NEW_HIGHLIGHT_LOGIC, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import {
  FrameworkDto,
  UseSearchFrameworks,
  useSearchFrameworks,
} from "lib/hooks/api/frameworks/useSearchFrameworks";
import FrameworkIcon from "lib/icons/FrameworkIcon";
import VerifiedProviderIcon from "lib/icons/VerifiedProviderIcon";
import { EventDataTypes, EventNames, logEvent, TrackingProvider, useTracking } from "lib/tracking";
import { Stages } from "lib/types/graphQLEnums";
import { ColumnSetting } from "lib/types/models";
import { formatDate, pluralise } from "lib/utils";
import { SignalEntityType } from "lib/utils/signalUtils";
import { NewRelevanceScore } from "../../lib/core_components/NewRelevanceScore";
import { FrameworkCallOffTableCell } from "./FrameworkCallOffTableCell";
import FrameworkStage from "./FrameworkStage";
import {
  AWARD_TYPE_LABEL,
  convertFrameworkFiltersToRequest,
  DEFAULT_PAGINATION,
  frameworkDetailsUrl,
  FrameworkFilters,
  isValidSortField,
  PROCEDURE_TYPE_LABEL,
} from "./utils";

import css from "./FrameworkTable.module.scss";

function getSortOrder(key: string, sort?: SortState): "ascend" | "descend" | null {
  return sort?.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

export type FrameworkColumns =
  | "signalScore"
  | "title"
  | "procedureType"
  | "awardType"
  | "stage"
  | "value"
  | "suppliers"
  | "startDate"
  | "endDate"
  | "closeDate"
  | "callOffs"
  | "callOffIdentifierRule"
  | "signals";

export const DEFAULT_FRAMEWORK_COLUMNS: ColumnSetting<FrameworkColumns>[] = [
  { title: "Signal score", field: "signalScore" },
  { title: "Name", field: "title", disabled: true },
  { title: "Framework type", field: "procedureType" },
  { title: "Award type", field: "awardType" },
  { title: "Stage", field: "stage" },
  { title: "Value", field: "value" },
  { title: "Suppliers", field: "suppliers" },
  { title: "Start date", field: "startDate" },
  { title: "End date", field: "endDate" },
];

if (FeatureToggles.isEnabled(Feature.CALL_OFFS)) {
  DEFAULT_FRAMEWORK_COLUMNS.push({ title: "Call offs", field: "callOffs" });
}

export const ALL_FRAMEWORK_COLUMNS: ColumnSetting<FrameworkColumns>[] = [
  ...DEFAULT_FRAMEWORK_COLUMNS,
  { title: "Close date", field: "closeDate" },
  { title: "Signals", field: "signals" },
];

function getFrameworkColumns({
  filters,
  selectedColumns,
  isNewHighlightingLogicEnabled,
}: {
  filters: FrameworkFilters;
  selectedColumns: FrameworkColumns[];
  isNewHighlightingLogicEnabled: boolean;
}) {
  const sort = filters.sort;

  const signalScore: ColumnType<FrameworkDto> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "signalScore",
    dataIndex: "signalScore",
    render: (_, f) => (
      <NewRelevanceScore
        id={f.id}
        popoverPlacement="bottomLeft"
        relevanceScore={f.signalScore ?? 0}
        entityType={SignalEntityType.Framework}
        eventAttributes={{
          "Framework GUID": f.id,
          Stage: f.stage,
          "Signal score": f.signalScore ?? 0,
          "Data type": EventDataTypes.frameworks,
        }}
      />
    ),
    sorter: numberSort((f: FrameworkDto) => f.signalScore ?? 0),
    sortOrder: getSortOrder("signalScore", sort),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this notice.",
    },
    sizeConfig: {
      small: 120,
      medium: 120,
      large: 120,
      xlarge: 120,
    },
  };

  const title: ColumnType<FrameworkDto> = {
    title: "Framework & Provider",
    dataIndex: "title",
    key: "title",
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: stringSort((f) => f.title),
    sortOrder: getSortOrder("title", sort),
    render: (_, f) => (
      <div className={css.frameworkProvider}>
        <FrameworkIcon size={32} className={css.frameworkIcon} />
        <div className={css.titleContainer}>
          <div className={css.frameworkName}>
            <EllipsisTooltipTextLink
              fullText={f.title}
              linkProps={{
                to: frameworkDetailsUrl(f.id, isNewHighlightingLogicEnabled ? filters : undefined),
                className: css.titleText,
                onClick: () => logEvent(EventNames.frameworkClicked, { "Framework ID": f.id }),
              }}
              linkText={f.title}
              tooltipProps={{ overlayClassName: css.tooltip }}
            />
            {f.validatedByProvider && (
              <VerifiedProviderIcon
                className={css.verifiedProvider}
                ariaLabel="framework-verified-icon"
              />
            )}
          </div>
          {f.serviceProvider?.name && (
            <span className={css.providerText}>{f.serviceProvider?.name}</span>
          )}
        </div>
      </div>
    ),
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const procedureType: ColumnType<FrameworkDto> = {
    title: "Framework type",
    dataIndex: "procedureType",
    key: "procedureType",
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: stringSort((f) => f.procedureType),
    render: (_, framework: FrameworkDto) =>
      framework.procedureType ? PROCEDURE_TYPE_LABEL[framework.procedureType] : "--",
    sortOrder: getSortOrder("procedureType", sort),
  };

  const awardType: ColumnType<FrameworkDto> = {
    title: "Award type",
    dataIndex: "awardType",
    key: "awardType",
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["ascend", "descend", "ascend"],
    render: (_, framework: FrameworkDto) =>
      framework.awardType ? AWARD_TYPE_LABEL[framework.awardType] : "--",
    sorter: stringSort((f) => f.awardType),
    sortOrder: getSortOrder("awardType", sort),
  };

  const stage: ColumnType<FrameworkDto> = {
    title: "Stage",
    key: "stage",
    ellipsis: {
      showTitle: false,
    },
    dataIndex: "stage",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: stringSort((f) => f.stage),
    sortOrder: getSortOrder("stage", sort),
    render: (_, framework: FrameworkDto) => <FrameworkStage stage={framework.stage} />,
    sizeConfig: {
      small: 125,
      medium: 140,
      large: 140,
      xlarge: 140,
    },
  };

  const value: ColumnType<FrameworkDto> = {
    ...commonTableColumns.valueColumn,
    key: "value",
    dataIndex: "value",
    title: "Value",
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["descend", "ascend", "descend"],
    sorter: numberSort((f) => f.value?.amount, getSortOrder("value", sort)),
    sortOrder: getSortOrder("value", sort),
    align: "left",
    render: (_, framework: FrameworkDto) => {
      return (
        framework?.value &&
        formatNumber({
          value: framework.value?.amount,
          locale: "en-GB",
          currency: framework.value?.currency,
        })
      );
    },
  };

  const suppliers: ColumnType<FrameworkDto> = {
    key: "suppliers",
    dataIndex: "supplierCount",
    title: "Suppliers",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: numberSort((f) => f.supplierCount, getSortOrder("suppliers", sort)),
    sortOrder: getSortOrder("suppliers", sort),

    // Setting this to stop the default clickthrough event if user doesn't exactly click suppliers link
    onCell: (_rec) => ({
      onClick: (event) => event.stopPropagation(),
    }),

    ...commonTableColumns.expandableColumn,
    render: (_, f) =>
      f.supplierCount > 0 ? (
        <>
          <TextLink
            to={`/frameworks/${f.id}/suppliers`}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": "Framework suppliers",
            }}
            className={IN_ROW_STAT}
            onClick={() => logEvent(EventNames.suppliersClicked, { "Framework ID": f.id })}
          >
            <div className={css.supplierCount}>{pluralise(f.supplierCount, "Supplier")}</div>
          </TextLink>
          <div className={css.lotCount}>{pluralise(f.lotCount, "Lot")}</div>
        </>
      ) : (
        <span className={IN_ROW_STAT}>0</span>
      ),
    align: "left",
    sizeConfig: {
      small: 120,
      medium: 120,
      large: 120,
      xlarge: 120,
    },
  };

  const startDate: ColumnType<FrameworkDto> = {
    ...commonTableColumns.dateColumn,
    key: "startDate",
    title: "Start date",
    ellipsis: {
      showTitle: false,
    },
    render: (_, f) =>
      f.contractPeriod?.startDate ? formatDate(new Date(f.contractPeriod.startDate)) : undefined,
    sorter: stringSort((f) => f.contractPeriod?.startDate),
    sortOrder: getSortOrder("startDate", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const endDate: ColumnType<FrameworkDto> = {
    ...commonTableColumns.dateColumn,
    key: "endDate",
    title: "End date",
    ellipsis: {
      showTitle: false,
    },
    render: (_, f) =>
      f.contractPeriod?.endDate ? formatDate(new Date(f.contractPeriod.endDate)) : undefined,
    sorter: stringSort((f) => f.contractPeriod?.endDate),
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder: getSortOrder("endDate", sort),
    showSorterTooltip: false,
  };

  const closeDate: ColumnType<FrameworkDto> = {
    ...commonTableColumns.dateColumn,
    key: "closeDate",
    title: "Close date",
    ellipsis: {
      showTitle: false,
    },
    render: (_, f) => f.tenderPeriod?.endDate,
    sorter: stringSort((f) => f.tenderPeriod?.endDate),
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder: getSortOrder("closeDate", sort),
    showSorterTooltip: false,
  };

  const callOffs: ColumnType<FrameworkDto> = {
    key: "callOffs",
    dataIndex: "callOff",
    title: "Call-offs",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: numberSort((f) => f.callOff.count, getSortOrder("value", sort)),
    sortOrder: getSortOrder("callOffs", sort),

    // Setting this to stop the default clickthrough event if user doesn't exactly click suppliers link
    onCell: (_rec) => ({
      onClick: (event) => event.stopPropagation(),
    }),

    ...commonTableColumns.expandableColumn,
    render: (_, f) => {
      const earlyStageFramework = [Stages.Upcoming, Stages.Tendering].includes(f.stage);
      if (earlyStageFramework) {
        return <span className={IN_ROW_STAT}>N/A</span>;
      }

      const noCallOffs = f.callOff.count < 1;
      if (noCallOffs) {
        return <span className={IN_ROW_STAT}>--</span>;
      }

      return (
        <FrameworkCallOffTableCell
          averageAmount={f.callOff.averageAmount?.amount}
          callOffCount={f.callOff.count}
          currency={f.callOff.averageAmount?.currency}
          linkTo={`/frameworks/${f.id}/call-offs`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Framework call-offs",
          }}
          className={IN_ROW_STAT}
          onClick={() => logEvent(EventNames.callOffClicked, { "Framework ID": f.id })}
        />
      );
    },
    align: "left",
    sizeConfig: {
      small: 125,
      medium: 125,
      large: 150,
      xlarge: 150,
    },
  };

  const callOffIdentifierRule: ColumnType<FrameworkDto> = {
    title: "Call-off identification rule",
    key: "callOffIdentifierRule",
    render: (f) => {
      const keywords = f.callOffIdentificationRule?.config.keywords as string[][] | undefined;

      return (
        <div>
          {keywords
            ? keywords.map((ands, i) => (
                <React.Fragment key={i}>
                  <Tag>{ands.join(" AND ")}</Tag>
                  {i !== keywords.length - 1 && " OR "}
                </React.Fragment>
              ))
            : "Not defined ❌"}
          <TextLink to={`/tools/call_off_identifier_rules/${f.id}`}>Edit</TextLink>
        </div>
      );
    },
    // Sorting is not supported for now - with our current gql configuration it's a bit tricky to do
    // nested filtering/sorting
    // sortDirections: ["descend", "ascend", "descend"],
    // sorter: numberSort(f => (f as any).callOffIdentificationRule?.config.keywords.length || 0),
    // sortOrder: getSortOrder("callOffIdentifierRule", sort),
  };

  const signals: ColumnType<FrameworkDto> = {
    title: "Signals",
    key: "signals",
    sizeConfig: {
      xlarge: 250,
      large: 150,
      medium: 150,
      small: 150,
    },
    showSorterTooltip: false,
    render: (_, f) => {
      return <SignalsContainer signals={f.signals || []} contextSource="In-row" />;
    },
  };

  const columns = {
    signalScore,
    title,
    procedureType,
    awardType,
    stage,
    value,
    suppliers,
    startDate,
    endDate,
    closeDate,
    callOffs,
    callOffIdentifierRule,
    signals,
  };

  return selectedColumns.map((c) => columns[c]);
}

type SortState = Exclude<FrameworkFilters["sort"], undefined>;
type Props = {
  filters: FrameworkFilters;
  onSortChange: (sort: SortState) => void;
  selectedColumns: FrameworkColumns[];
  selectedRows?: string[];
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: FrameworkDto[]) => void;
  frameworkProvider?: UseSearchFrameworks;
};

export function FrameworkTable({
  filters,
  onSortChange,
  selectedColumns,
  selectedRows,
  onSelectedRowsChange,
  frameworkProvider = useSearchFrameworks,
}: Props) {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const includeSignals = selectedColumns.includes("signals");
  const { isLoading, data, isError } = frameworkProvider(
    convertFrameworkFiltersToRequest(filters, pagination),
    includeSignals,
  );

  const isNewHighlightingLogicEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  useDeepCompareEffect(() => {
    onSelectedRowsChange?.([], []);
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  const { logEvent } = useTracking();

  const columns = React.useMemo(() => {
    return getFrameworkColumns({
      filters,
      selectedColumns,
      isNewHighlightingLogicEnabled,
    });
  }, [filters, selectedColumns, isNewHighlightingLogicEnabled]);

  return (
    <TrackingProvider
      data={{
        "Context source": "Table row",
      }}
    >
      <Table<FrameworkDto>
        ariaLabel="Frameworks Table"
        dataSource={data?.results || []}
        columns={columns}
        loading={isLoading}
        scroll={{ x: true }}
        pagination={{
          ...pagination,
          total: data?.totalResults || 0,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize });
            logEvent(EventNames.paginationSelected, { Value: page });
          },
          showSizeChanger: true,
        }}
        onChange={(_, __, sorter) => {
          if (Array.isArray(sorter)) {
            sorter = sorter[0];
          }
          // If the sort order is not defined, we don't want to trigger a sort without an order
          if (!filters.sort && !sorter.order) {
            return;
          }
          const sortField = isValidSortField(sorter.columnKey?.toString());
          if (sortField) {
            const newSortState: SortState = {
              order: sorter.order === "descend" ? "DESC" : "ASC",
              field: sortField,
            };
            if (!isEqual(filters.sort, newSortState)) {
              onSortChange?.(newSortState);
              // TODO: maybe move all tracking?
              logEvent(EventNames.tableSorted, {
                Context: "Column action",
                "Sorted by": startCase(newSortState.field),
                "Sort order": newSortState.order,
              });
            }
          }
        }}
        rowSelection={
          onSelectedRowsChange &&
          selectedRows && {
            onChange: (selectedRowKeys, selectedRows) => {
              onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
            },
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRows,
          }
        }
        rowKey="id"
        isError={isError}
      />
    </TrackingProvider>
  );
}
