/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameworkSearchQueryScoreFilterScoresInner } from './FrameworkSearchQueryScoreFilterScoresInner';
import {
    FrameworkSearchQueryScoreFilterScoresInnerFromJSON,
    FrameworkSearchQueryScoreFilterScoresInnerFromJSONTyped,
    FrameworkSearchQueryScoreFilterScoresInnerToJSON,
} from './FrameworkSearchQueryScoreFilterScoresInner';

/**
 * When this is passed it is used as the basis for calculating the signal score
 * @export
 * @interface FrameworkSearchQueryScoreFilter
 */
export interface FrameworkSearchQueryScoreFilter {
    /**
     * 
     * @type {Array<FrameworkSearchQueryScoreFilterScoresInner>}
     * @memberof FrameworkSearchQueryScoreFilter
     */
    scores: Array<FrameworkSearchQueryScoreFilterScoresInner>;
}

/**
 * Check if a given object implements the FrameworkSearchQueryScoreFilter interface.
 */
export function instanceOfFrameworkSearchQueryScoreFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scores" in value;

    return isInstance;
}

export function FrameworkSearchQueryScoreFilterFromJSON(json: any): FrameworkSearchQueryScoreFilter {
    return FrameworkSearchQueryScoreFilterFromJSONTyped(json, false);
}

export function FrameworkSearchQueryScoreFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSearchQueryScoreFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scores': ((json['scores'] as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerFromJSON)),
    };
}

export function FrameworkSearchQueryScoreFilterToJSON(value?: FrameworkSearchQueryScoreFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scores': ((value.scores as Array<any>).map(FrameworkSearchQueryScoreFilterScoresInnerToJSON)),
    };
}

