import React from "react";
import Icon, { CloseOutlined } from "@ant-design/icons";

import MegaphoneIcon from "lib/icons/Megaphone";

import css from "./Banner.module.scss";

type BannerProps = {
  keyName: string;
  isFeatureAnnouncement?: boolean;
  header: string;
  message: string;
  graphicSrc: string;
  buttons: JSX.Element;
  addPadding?: boolean;
  onClose?: () => void;
};

export function Banner({
  keyName,
  isFeatureAnnouncement,
  header,
  message,
  graphicSrc,
  buttons,
  addPadding,
  onClose,
}: BannerProps) {
  const [displayHelp, setDisplayHelp] = React.useState<boolean>(() => {
    const modalClosed = window.localStorage.getItem(keyName);
    if (modalClosed) return false;
    return true;
  });

  const closeBanner = () => {
    window.localStorage.setItem(keyName, "1");
    setDisplayHelp(false);

    if (onClose) {
      onClose();
    }
  };

  return displayHelp ? (
    <div className={addPadding ? css.addPadding : undefined}>
      <div className={css.banner}>
        <div className={css.textSection}>
          <div className={css.header}>
            {isFeatureAnnouncement && (
              <span className={css.featureMessage}>
                <Icon component={MegaphoneIcon} /> Feature announcement
              </span>
            )}
            <h1 className={css.heading}>{header}</h1>
            <p className={css.message}>{message}</p>
            <div className={css.btnContainer}>{buttons}</div>
          </div>
        </div>
        <div className={css.secondSection}>
          <div className={css.graphicSection}>
            <img src={graphicSrc} alt="Announcement graphic" />
          </div>
          <CloseOutlined className={css.closeButton} onClick={closeBanner} />
        </div>
      </div>
    </div>
  ) : null;
}
