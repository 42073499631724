import BuyersFilterContent from "./Buyers";
import ContractDatesFilterContent from "./ContractDates";
import ContractValueFilterContent from "./ContractValue";
import CPVCodeFilterContent from "./CPVCode";
import FrameworksFilterContent from "./Frameworks";
import KeywordsFilterContent from "./Keywords";
import ProcurementStageFilterContent from "./ProcurementStage";
import SignalsFilterContent from "./Signals";
import SignalScoreFilterContent from "./SignalScore";
import SuppliersFilterContent from "./Suppliers";
import WorkflowFilterContent from "./Workflow";

// Add new filters to this map
export const noticeFilterContents = {
  keywords: KeywordsFilterContent,
  signals: SignalsFilterContent,
  signalScore: SignalScoreFilterContent,
  procurementStage: ProcurementStageFilterContent,
  contractValue: ContractValueFilterContent,
  contractDates: ContractDatesFilterContent,
  buyers: BuyersFilterContent,
  suppliers: SuppliersFilterContent,
  frameworks: FrameworksFilterContent,
  workflow: WorkflowFilterContent,
  cpvCode: CPVCodeFilterContent,
};
