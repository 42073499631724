import React from "react";
import { Control } from "react-hook-form";

import { Select } from "../../form_components/Inputs";
import { AWARD_TYPE_OPTIONS, FrameworkFilters, PROCEDURE_TYPE_OPTIONS } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface FrameworkFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
}

function FrameworkFilterContent({ control }: FrameworkFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Framework filters">
      <Select
        name="procedureType"
        control={control}
        label="Framework type"
        options={PROCEDURE_TYPE_OPTIONS}
        mode="multiple"
        optionFilterProp="label"
        allowClear
      />

      <Select
        name="awardType"
        control={control}
        label="Award type"
        options={AWARD_TYPE_OPTIONS}
        mode="multiple"
        optionFilterProp="label"
        allowClear
      />
    </div>
  );
}

export default FrameworkFilterContent;
