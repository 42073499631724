import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SubscriptionContentsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = [string];

export function useGetLeadSubscriptionContents(
  options?: UseQueryOptions<
    SubscriptionContentsResponse,
    unknown,
    SubscriptionContentsResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["leadSubscriptionContents"],
    async () => await api.getLeadSubscriptionContents(),
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );
}
