import React from "react";
import { Radio } from "antd5";

import { EventNames, useTracking } from "lib/tracking";

type Props = {
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: (granularity: "month" | "quarter" | "year") => void;
};

export function DateUnitSelector({ dateUnit, setDateUnit }: Props) {
  const { logEvent } = useTracking();
  return (
    <Radio.Group
      value={dateUnit}
      onChange={(e) => {
        setDateUnit(e.target.value);
        logEvent(EventNames.dateUnitsSelected, {
          "Unit selected": e.target.value,
        });
      }}
    >
      <Radio.Button value="month">Month</Radio.Button>
      <Radio.Button value="quarter">Quarter</Radio.Button>
      <Radio.Button value="year">Year</Radio.Button>
    </Radio.Group>
  );
}
