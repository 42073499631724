import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useStotlesApi } from "lib/stotlesApiContext";

export function useExportReports(options?: UseMutationOptions<Blob, unknown, string, string[]>) {
  const api = useStotlesApi();
  return useMutation(async (reportId: string) => {
    const response = await api.fetchData("GET", `/reports/${reportId}/pdf`, {});
    return response;
  }, options);
}
