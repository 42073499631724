import * as React from "react";
import { hot } from "react-hot-loader/root";

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { ContactUsWidget } from "components/contact_us_widget/ContactUsWidget";
import { useDialogManager } from "lib/providers/DialogManager";

import css from "./InvitationExpiredPage.module.scss";

function InvitationExpiredPage(): JSX.Element {
  const dialogManager = useDialogManager();

  return (
    <div className={css.invitationExpiredPage}>
      <div className={css.innerWrapper}>
        <h1>Oops!</h1>
        <p>
          This invite link has now expired. Ask the person who invited you to send another
          invitation, or{" "}
          <TextButton
            onClick={() =>
              dialogManager.openDialog(ContactUsWidget, { triggeredFrom: "Invitation expiry page" })
            }
          >
            get in touch
          </TextButton>{" "}
          in order to get started.
        </p>
        <div className={css.actionItemsContainer}>
          <TextLink to="https://stotles.com" className={css.actionItems}>
            Go to stotles.com
          </TextLink>
        </div>
      </div>
    </div>
  );
}

export default withAppLayout(hot(InvitationExpiredPage), {
  hideMenuItems: true,
  hideUserItems: true,
  pageName: "Onboarding - Invitation Expired",
});
