import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SupplierStatsRequest, SupplierStatsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, string];

export function useSupplierStats(
  req: SupplierStatsRequest,
  options?: UseQueryOptions<SupplierStatsResponse, unknown, SupplierStatsResponse, QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    ["supplierStats", req.guid],
    () => api.supplierStats({ supplierStatsRequest: req }),
    options,
  );
}
