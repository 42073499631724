import { Plugin } from "chart.js";

export const LegendClickHandler: Plugin = {
  id: "legend_click_handler",
  beforeInit: function (chart) {
    const legend = chart.legend;
    if (legend) {
      legend.options.onClick = (e, legendItem, legend) => {
        const index = legendItem.datasetIndex;
        if (index == null) {
          return;
        }

        const chart = legend.chart;
        if (chart.getVisibleDatasetCount() === chart.data.datasets.length) {
          chart.data.datasets.forEach(function (_, i) {
            if (i !== index) {
              chart.setDatasetVisibility(i, false);
            }
          });
        } else if (chart.getVisibleDatasetCount() === 1 && chart.isDatasetVisible(index)) {
          chart.data.datasets.forEach(function (_, i) {
            chart.setDatasetVisibility(i, true);
          });
        } else {
          chart.setDatasetVisibility(index, !chart.isDatasetVisible(index));
        }

        chart.update();
      };
      chart.update();
    }
  },
};
