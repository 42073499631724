import React, { useCallback, useEffect, useState } from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Core } from "@pdftron/webviewer";
import { Button, Drawer, Input } from "antd5";

import FileViewer, {
  FileViewerContextProvider,
  useFileViewerInstance,
} from "../../lib/core_components/FileViewer";
import { BidQualification_Result_Sources } from "../../lib/generated/app-service-gql/graphql";
import { SearchResultSnippet } from "../document_details/DocumentSearchResults";

import css from "./DocumentViewer.module.scss";

type DocumentDrawerProps = {
  open: boolean;
  source: BidQualification_Result_Sources;
  onClose: () => void;
};

type DocumentViewerProps = {
  documentUrl: string;
  fileExtension?: string;
  source: BidQualification_Result_Sources;
  open: boolean;
};

export default function DocumentDrawer({ open, source, onClose }: DocumentDrawerProps) {
  const documentUrl = source.documentLink;
  // TODO: this should be done in the backend
  const fileExtension = source.document?.split(".").pop();
  return (
    <FileViewerContextProvider>
      <Drawer
        open={open}
        onClose={onClose}
        styles={{
          body: { padding: 0 },
        }}
        forceRender
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: this property does exist in ant but types are not right
        width={1200}
        closeIcon={null}
      >
        <div className={css.previewWrapper}>
          <div className={css.controls}>
            <Button
              title="Close"
              icon={<DoubleRightOutlined className={css.closeIcon} />}
              onClick={onClose}
              className={css.controlsBtn}
            />
          </div>
          {documentUrl && (
            <DocumentViewer
              documentUrl={documentUrl}
              fileExtension={fileExtension}
              source={source}
              open={open}
            />
          )}
        </div>
      </Drawer>
    </FileViewerContextProvider>
  );
}

function DocumentViewer({ documentUrl, fileExtension, source, open }: DocumentViewerProps) {
  const { instance } = useFileViewerInstance();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [documentReady, setIsDocumentReady] = useState(false);

  const search = useCallback(() => {
    if (!instance) return;

    const { documentViewer, Search } = instance.Core;

    if (!searchTerm) {
      documentViewer.clearSearchResults();
      return;
    }

    if (documentViewer) {
      const results: SearchResultSnippet[] = [];
      let index = 0;

      documentViewer.clearSearchResults();
      documentViewer.textSearchInit(
        searchTerm,
        Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT | Search.Mode.AMBIENT_STRING,
        {
          fullSearch: true,
          onResult: (result: Core.Search.SearchResult) => {
            if (result.resultCode === Search.ResultCode.FOUND) {
              results.push({ ...result, originalIndex: index });
              index++;
            }
          },
          onDocumentEnd: () => {
            if (results.length) {
              documentViewer.displayAdditionalSearchResults(results);
              documentViewer.setActiveSearchResult(results[0]);
            }
          },
        },
      );
    }
  }, [instance, searchTerm]);

  useEffect(() => {
    const documentViewer = instance?.Core.documentViewer;

    const handleIsDocumentReady = (isReady: boolean) => {
      setIsDocumentReady(isReady);
    };

    if (documentViewer) {
      // Waiting for documentLoaded event is not sufficient and gives incomplete results
      // Instead, wait for the last event of the document lifecycle
      documentViewer.addEventListener("annotationsLoaded", () => {
        handleIsDocumentReady(true);
        search();
      });
    }

    // Clean up event listeners
    return () => {
      if (documentViewer) {
        documentViewer.removeEventListener("annotationsLoaded", () => handleIsDocumentReady(false));
      }
    };
  }, [instance, search]);

  useEffect(() => {
    if (open) {
      if (documentReady && source?.annotations && source.annotations.length > 0) {
        // only when open and document is ready
        // Just pull the first highlight for now
        setSearchTerm(source.annotations[0] || undefined);
        search();
      }
    } else {
      setSearchTerm(undefined);
    }
  }, [open, documentReady, search, source.annotations]);

  return (
    <div className={css.container}>
      <div className={css.header}>
        <h2>{source.document}</h2>
        <Input
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>
      <FileViewer documentUrl={documentUrl} fileExtension={fileExtension} />
    </div>
  );
}
