/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { BasicContactInformation } from './BasicContactInformation';
import {
    BasicContactInformationFromJSON,
    BasicContactInformationFromJSONTyped,
    BasicContactInformationToJSON,
} from './BasicContactInformation';

/**
 * 
 * @export
 * @interface SupplierDetailsDtoRefOrgInfo
 */
export interface SupplierDetailsDtoRefOrgInfo {
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDtoRefOrgInfo
     */
    source: SupplierDetailsDtoRefOrgInfoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDtoRefOrgInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDtoRefOrgInfo
     */
    sourceIdentifier: string;
    /**
     * 
     * @type {Address}
     * @memberof SupplierDetailsDtoRefOrgInfo
     */
    address: Address;
    /**
     * 
     * @type {BasicContactInformation}
     * @memberof SupplierDetailsDtoRefOrgInfo
     */
    basicContactInfo: BasicContactInformation;
}


/**
 * @export
 */
export const SupplierDetailsDtoRefOrgInfoSourceEnum = {
    Oscar: 'oscar',
    CompaniesHouse: 'companies_house',
    DunAndBradstreet: 'dun_and_bradstreet'
} as const;
export type SupplierDetailsDtoRefOrgInfoSourceEnum = typeof SupplierDetailsDtoRefOrgInfoSourceEnum[keyof typeof SupplierDetailsDtoRefOrgInfoSourceEnum];


/**
 * Check if a given object implements the SupplierDetailsDtoRefOrgInfo interface.
 */
export function instanceOfSupplierDetailsDtoRefOrgInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "sourceIdentifier" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "basicContactInfo" in value;

    return isInstance;
}

export function SupplierDetailsDtoRefOrgInfoFromJSON(json: any): SupplierDetailsDtoRefOrgInfo {
    return SupplierDetailsDtoRefOrgInfoFromJSONTyped(json, false);
}

export function SupplierDetailsDtoRefOrgInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierDetailsDtoRefOrgInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'name': json['name'],
        'sourceIdentifier': json['source_identifier'],
        'address': AddressFromJSON(json['address']),
        'basicContactInfo': BasicContactInformationFromJSON(json['basic_contact_info']),
    };
}

export function SupplierDetailsDtoRefOrgInfoToJSON(value?: SupplierDetailsDtoRefOrgInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'name': value.name,
        'source_identifier': value.sourceIdentifier,
        'address': AddressToJSON(value.address),
        'basic_contact_info': BasicContactInformationToJSON(value.basicContactInfo),
    };
}

