import React, { useEffect, useState } from "react";
import { FileSearchOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Drawer } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useUpdatePinnedView } from "lib/hooks/api/notices/views/useUpdatePinnedView";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { NoticeViews } from "../NoticeViews";
import PinnedViewItem from "./PinnedViewItem";

import css from "./PinnedViewsList.module.scss";

type Props = {
  pinnedViews: SavedViewType[];
};

function PinnedViewsList({ pinnedViews }: Props) {
  const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
  const [views, setViews] = useState(pinnedViews.map((view) => ({ ...view, id: view.id || "" })));
  const { logEvent } = useTracking();
  const { mutate: updateView } = useUpdatePinnedView();

  useEffect(() => {
    setViews(pinnedViews.map((view) => ({ ...view, id: view.id || "" })));
  }, [pinnedViews]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      const newIndex: number = over.data.current?.sortable.index;
      const droppedView = views.find((view) => view.id === active.id);
      if (droppedView) {
        const updatedViews = views.filter((view) => view.id !== active.id);
        updatedViews.splice(newIndex, 0, droppedView);
        setViews(updatedViews);
        updateView({ id: active.id.toString(), rank: newIndex, name: droppedView.name });
      }
    }
  };

  return (
    <div className={css.savedViewsBar}>
      <ul className={css.quickSelectMenu}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={views} strategy={horizontalListSortingStrategy}>
            {views.map((view) => (
              <PinnedViewItem key={view.id} view={view} />
            ))}
          </SortableContext>
        </DndContext>
        <button
          key={"all_views"}
          className={css.allViewsItem}
          onClick={() => {
            setIsViewDrawerOpen(true);
            logEvent(EventNames.allViewsTableOpened, {
              "Data type": EventDataTypes.savedView,
            });
          }}
        >
          <div className={css.allViewsContent}>
            <FileSearchOutlined className={css.fileSearchIcon} />
            All views
          </div>
        </button>
      </ul>
      <Drawer
        title="Notice views"
        onClose={() => setIsViewDrawerOpen(false)}
        open={isViewDrawerOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - height is not in the types
        height={"70vh"}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - placement is not in the types
        placement={"bottom"}
        destroyOnClose
      >
        <NoticeViews onClose={() => setIsViewDrawerOpen(false)} />
      </Drawer>
    </div>
  );
}

export default PinnedViewsList;
