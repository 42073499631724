import * as React from "react";
import { createPortal } from "react-dom";

type WithDialogProps<Props, Result> = Props & {
  isOpen: boolean;
  onClose?: (() => void) | ((...args: [Result]) => void);
};

type DialogHandle<DialogProps> = { id: number; __propType: DialogProps };

/**
 * Main interface for accessing DialogManager
 */
export interface DialogManager {
  /**
   * Opens a dialog rendered with `componentType` and `props`.
   * Returns a handle for the dialog and a promise that is resolved when the dialog is closed.
   * The value of the promise is the value passed by the dialog to the `onClose` prop.
   *
   * Props can't contain `isOpen` and `onClose` as these will be provieded by the dialog manager.
   *
   * NOTE: You need to take care of updating props of the dialog using the `updateProps` method.
   * If you pass a value that might change (e.g. something managed with useState)
   * it won't automatically updated when it changes, as DialogManager maintains a snapshot
   * of what you passed it.
   *
   * The dialog will be immediately open and closed only when `onClose` is called.
   */
  openDialog<Props, Result>(
    componentType: React.ComponentType<WithDialogProps<Props, Result>>,
    props: Omit<Props, "isOpen" | "onClose">,
    portal?: HTMLElement,
  ): [DialogHandle<Props>, Promise<Result>];

  /**
   * Updates props of a dialog specified by the handle.
   *
   * @param handle - a handle to the dialog returned by `openDialog`
   * @param newProps - *partial* object with props of the dialog
   */
  updateProps<Props>(
    handle: DialogHandle<Props>,
    newProps: Partial<Omit<Props, "isOpen" | "onClose">>,
  ): void;
}

class InvalidDialogManager implements DialogManager {
  openDialog<Props, Result>(
    _componentType: React.ComponentType<WithDialogProps<Props, Result>>,
    _props: Omit<Props, "isOpen" | "onClose">,
  ): [DialogHandle<Props>, Promise<Result>] {
    throw new Error("Accessing DialogManager without a provider.");
  }

  updateProps<Props>(
    _handle: DialogHandle<Props>,
    _newProps: Partial<Omit<Props, "isOpen" | "onClose">>,
  ) {
    throw new Error("Accessing DialogManager without a provider.");
  }
}

const DialogManagerContext = React.createContext<DialogManager>(new InvalidDialogManager());

/**
 * Internal storage of data required to handle one dialog.
 *
 * We declare it here as generic so that utility functions using it can be fully typed,
 * but in fact the type information will be erased as we store different dialogs in one list.
 */
interface DialogData<Props, Result> {
  componentType: React.ComponentType<WithDialogProps<Props, Result>>;
  props: Props;
  id: number; // for internal use of dialog manager
  accept: (() => void) | ((result: Result) => void); // resolving function for the `openDialog` promise
  portal?: HTMLElement;
}

/**
 * List of currently open dialogs.
 */
interface State {
  dialogs: DialogData<unknown, unknown>[];
}

export class DialogManagerProvider
  extends React.Component<unknown, State>
  implements DialogManager
{
  state: State = {
    dialogs: [],
  };

  private _NEXT_DIALOG_ID_ = 1;
  getNextId(): number {
    const nextId = this._NEXT_DIALOG_ID_;
    this._NEXT_DIALOG_ID_++;
    return nextId;
  }

  openDialog<Props, Result>(
    componentType: React.ComponentType<WithDialogProps<Props, Result>>,
    props: Omit<Props, "isOpen" | "onClose">,
    portal?: HTMLElement,
  ): [DialogHandle<Props>, Promise<Result>] {
    const handle = { id: this.getNextId() } as DialogHandle<Props>;
    return [
      handle,
      new Promise<Result>((accept) => {
        // have to erase types as we'll store DialogData with different props for each component
        // but as we always store props with a matching component, this is not a problem
        const dialog = {
          componentType: componentType as React.ComponentType<WithDialogProps<unknown, unknown>>,
          props: props as unknown,
          id: handle.id,
          accept: accept as (result: unknown) => void,
          portal,
        };

        this.setState(({ dialogs }) => ({
          dialogs: [...dialogs, dialog],
        }));
      }),
    ];
  }

  updateProps<Props>(
    handle: DialogHandle<Props>,
    newProps: Partial<Omit<Props, "isOpen" | "onClose">>,
  ): void {
    const newDialogs = this.state.dialogs.map((dialogData) => {
      if (dialogData.id !== handle.id) {
        return dialogData;
      }

      return {
        ...dialogData,
        props: {
          ...(dialogData.props as Record<string, any>),
          ...newProps,
        },
      };
    });
    this.setState({ dialogs: newDialogs });
  }

  private closeDialog(idToRemove: number) {
    this.setState(({ dialogs }) => ({
      dialogs: dialogs.filter(({ id }) => idToRemove !== id),
    }));
  }

  private renderDialog<Props, Result>({
    accept,
    props,
    id,
    componentType,
    portal,
  }: DialogData<Props, Result>) {
    const Component = componentType;
    const handleClose = (...args: any[]) => {
      this.closeDialog(id);
      if (accept.length === 0) {
        // typescript can't figure out that this doesn't take arguments
        (accept as () => void)();
      } else {
        accept(args[0]);
      }
    };

    const component = portal ? (
      createPortal(
        <Component {...props} key={id} isOpen={true} onClose={handleClose} />,
        portal,
        id.toString(),
      )
    ) : (
      <Component {...props} key={id} isOpen={true} onClose={handleClose} />
    );

    // Wrapping in a dialog context means that any modal opened within the modal will
    // close when the outer modal closes as the context disappears with the outer modal
    return <DialogManagerProvider key={id}>{component}</DialogManagerProvider>;
  }

  render(): JSX.Element {
    return (
      <DialogManagerContext.Provider value={this}>
        {this.props.children}
        {this.state.dialogs.map(this.renderDialog, this)}
      </DialogManagerContext.Provider>
    );
  }
}

export function useDialogManager(): DialogManager {
  return React.useContext(DialogManagerContext);
}
