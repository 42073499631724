import React from "react";
import { Select, SelectProps } from "antd5";
import { DefaultOptionType } from "antd5/lib/cascader";
import { FilterFunc } from "rc-select/lib/Select";

import { langCodes } from "lib/data/optionItems";

const filterOptionByTitle: FilterFunc<DefaultOptionType> = (input, option) =>
  (option?.title?.toString() || "").toLowerCase().startsWith(input.toLowerCase());

type CommonIntlMultiFilterProps = Omit<SelectProps<string[]>, "options"> & {
  values: string[];
};

export function LanguagesFilter(props: CommonIntlMultiFilterProps): JSX.Element {
  const { values, ...rest } = props;
  const selectOptions = langCodes.map((opt) => ({
    label: opt.name,
    value: opt.code,
    title: opt.name,
  }));

  return (
    <Select
      {...rest}
      placeholder="Select languages..."
      value={values}
      mode="multiple"
      filterOption={filterOptionByTitle}
      options={selectOptions}
    />
  );
}
