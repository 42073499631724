import React from "react";
import { TablePaginationConfig } from "antd5";
import isEqual from "lodash.isequal";

import { TextLink } from "components/actions/Links";
import { convertBuyerFiltersToNoticeFilters } from "components/buyers/utils/convertBuyerFiltersToNoticeFilters";
import { generateNoticeSearchUrl } from "components/notices/utils";
import PaywallPopover from "components/paywall/PaywallPopover";
import SignalsContainer from "components/tags/SignalsContainer";
import { getBuyerDestination, getBuyerSupplierRelationshipDestination } from "lib/appDestinations";
import { ColumnHeader } from "lib/core_components/ColumnHeader";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { IN_ROW_STAT_LINK, Table } from "lib/core_components/Table/Table";
import {
  AllBuyersStats,
  AllBuyersStatsRequestAllBuyersStatsFilters,
  AllBuyersStatsResponse,
} from "lib/generated/app-api";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { SortState } from "lib/search/types";
import { tagColourFromSignal } from "lib/tagUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import { NameColumn } from "./NameColumn";
import { TableContext } from "./types";
import { DEFAULT_BUYER_FILTERS } from "./useFilterableBuyerTableState";

function getSortOrder(sort: SortState, key: string): "ascend" | "descend" | null {
  return sort.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

export type BuyerColumns = "name" | "notice" | "country" | "town" | "region" | "signals";

export const ALL_BUYER_COLUMNS: ColumnSetting<BuyerColumns>[] = [
  { title: "Buyer name", field: "name", disabled: true },
  { title: "Notices", field: "notice" },
  { title: "Country", field: "country" },
  { title: "Town", field: "town" },
  { title: "Region", field: "region" },
  { title: "Signals", field: "signals" },
];

const generateNoticeFiltersUrl = (
  buyerGuid: string,
  filters: AllBuyersStatsRequestAllBuyersStatsFilters,
): string => {
  if (!isEqual(filters, DEFAULT_BUYER_FILTERS)) {
    const noticeFilters = convertBuyerFiltersToNoticeFilters(filters, `${buyerGuid}`);
    const noticeSearchUrl = generateNoticeSearchUrl(noticeFilters);
    return noticeSearchUrl;
  }
  return "";
};

function getBuyerColumns({
  sortOrder,
  selectedColumns,
  filters,
  onUnauthorisedClick,
  trackingData,
  isSupplierRelationship,
}: {
  sortOrder: SortState;
  selectedColumns: BuyerColumns[];
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  onUnauthorisedClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  trackingData?: EventData;
  isSupplierRelationship?: boolean;
}) {
  const name: ColumnType<AllBuyersStats> = {
    key: "buyer_name",
    title: <ColumnHeader header=" Buyer name" subHeader="&amp; buyer type" />,
    render: (_, buyer) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "BUYERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={trackingData}
      >
        <NameColumn
          buyerGuid={buyer.buyerGuid}
          buyerName={buyer.buyerName}
          categories={buyer.categories}
          isSupplierRelationship={!!isSupplierRelationship}
        />
      </PaywallPopover>
    ),
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "buyer_name"),
    sizeConfig: {
      small: 440,
      medium: 440,
      large: 480,
      xlarge: 520,
    },
  };

  const notice: ColumnType<AllBuyersStats> = {
    key: "total_record_count",
    title: "Notices",
    render: (_, buyer) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "BUYERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
      >
        <TextLink
          to={
            isSupplierRelationship
              ? getBuyerSupplierRelationshipDestination(buyer.buyerGuid, "", "buyer")
              : `/buyers/${buyer.buyerGuid}/activity${generateNoticeFiltersUrl(
                  buyer.buyerGuid,
                  filters,
                )}`
          }
          useWouter={isSupplierRelationship}
          className={IN_ROW_STAT_LINK}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Total record count",
          }}
          onUnauthorisedClick={onUnauthorisedClick}
          authorised={!window.guestUser}
        >
          {buyer.stats.totalRecordCount.count}
        </TextLink>
      </PaywallPopover>
    ),
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "total_record_count"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: {
      small: 150,
      medium: 150,
      large: 180,
      xlarge: 200,
    },
  };

  const country: ColumnType<AllBuyersStats> = {
    key: "country",
    title: "Country",
    render: (_, buyer) => buyer.country,
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "country"),
  };

  const town: ColumnType<AllBuyersStats> = {
    key: "town",
    title: "Town",
    render: (_, buyer) => buyer.town,
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "town"),
    ellipsis: true,
  };

  const region: ColumnType<AllBuyersStats> = {
    key: "region",
    title: "Region",
    render: (_, buyer) => buyer.region,
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "region"),
    ellipsis: true,
  };

  const signals: ColumnType<AllBuyersStats> = {
    key: "signals",
    title: "Signals",
    render: (_, buyer) => {
      if (buyer.signals) {
        const signals = buyer.signals.map((signal) => ({
          ...signal,
          colour: tagColourFromSignal(signal.category),
        }));
        return (
          <SignalsContainer
            signals={signals}
            maxSignals={3}
            // redactedSignalCategories={}
            requiredDataType="BUYERS"
            contextSource="In-row"
          />
        );
      }
    },
    sorter: true,
    sortOrder: getSortOrder(sortOrder, "signals"),
    sizeConfig: {
      small: 150,
      medium: 200,
      large: 250,
      xlarge: 300,
    },
  };

  const columns = { name, notice, country, town, region, signals };
  return selectedColumns.map((column) => columns[column]);
}

export function BuyerTable({
  filters,
  sortOrder,
  onSortChange,
  selectedRows,
  onSelectedRowsChange,
  selectedColumns,
  pagination,
  onPaginationChange,
  tableContext,
  isSupplierRelationship,
}: {
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  sortOrder: SortState;
  onSortChange: (sort: SortState) => void;
  pagination: TablePaginationConfig;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
  selectedRows?: string[];
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: AllBuyersStats[]) => void;
  selectedColumns: BuyerColumns[];
  // TODO: Fetch this from react context using a hook
  tableContext: TableContext<AllBuyersStatsResponse>;
  isSupplierRelationship?: boolean;
}) {
  const { trackingData } = useTracking();
  const buyerTrackingData: EventData = {
    ...trackingData,
    "CTA actioned": "Buyer",
  };
  const openGuestPaywall = useRestrictedGuestAccess(buyerTrackingData);

  const openGuestAccessPaywall = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    openGuestPaywall();
  };

  const restrictionCheck = (_r: AllBuyersStats) => !!window.guestUser;

  const getSupplierId = window.location.pathname.split("/")[2];

  const onRow = createHandleTableRowClickthrough<AllBuyersStats>(
    (r) =>
      isSupplierRelationship
        ? getBuyerSupplierRelationshipDestination(
            r.buyerGuid,
            "/suppliers/" + getSupplierId,
            "buyer",
          )
        : getBuyerDestination(r.buyerGuid),
    undefined,
    undefined,
    restrictionCheck,
  );

  const columns = getBuyerColumns({
    sortOrder,
    selectedColumns,
    filters,
    onUnauthorisedClick: openGuestAccessPaywall,
    trackingData: buyerTrackingData,
    isSupplierRelationship,
  });

  const {
    queryResult: { data, isLoading, isError },
  } = tableContext;

  return (
    <Table<AllBuyersStats>
      dataSource={data?.signalStats || []}
      columns={columns}
      loading={isLoading}
      isError={isError}
      pagination={{
        ...pagination,
        total: data?.totalCount ? data.totalCount + 1 : 0,
        onChange: (page, pageSize) => onPaginationChange({ current: page, pageSize }),
        showSizeChanger: true,
      }}
      onChange={(_, __, sorter) => {
        if (Array.isArray(sorter)) {
          sorter = sorter[0];
        }
        // If the sort order is not defined, we don't want to trigger a sort without an order
        if (!sorter.order) {
          return;
        }
        const newSortState: SortState = {
          order: sorter.order === "descend" ? "DESC" : "ASC",
          field: sorter.columnKey as string,
        };
        if (!isEqual(newSortState, sortOrder)) {
          onSortChange(newSortState);
        }
      }}
      rowSelection={
        onSelectedRowsChange &&
        selectedRows && {
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRows,
        }
      }
      rowKey="buyerGuid"
      onRow={onRow}
    />
  );
}
