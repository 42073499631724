/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentSignalsRequestSignalsInnerFiltersAllInner } from './DocumentSignalsRequestSignalsInnerFiltersAllInner';
import {
    DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSON,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSONTyped,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerToJSON,
} from './DocumentSignalsRequestSignalsInnerFiltersAllInner';

/**
 * The filters to be used for the search
 * @export
 * @interface DocumentSearchQueryFilters
 */
export interface DocumentSearchQueryFilters {
    /**
     * ALL of the criteria within this clause must be true for a result to be returned for this query.
     *         Each expression in this array is chained together by ANDs. It would look something like this:
     *           (condition1 AND condition2 AND (condition3a or condition 3b) )
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>}
     * @memberof DocumentSearchQueryFilters
     */
    all?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>;
    /**
     * ANY one of the criteria need to be true for a result to be returned for this query. 
     *           Each expression in this array is chained together by ORs.
     *           It's joined to the ALL clause of this object via an AND
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>}
     * @memberof DocumentSearchQueryFilters
     */
    any?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>;
    /**
     * NONE one of the criteria need to be true for a result to be returned for this query. 
     *           It's joined to the ALL clause of this object via an AND
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>}
     * @memberof DocumentSearchQueryFilters
     */
    none?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInner>;
}

/**
 * Check if a given object implements the DocumentSearchQueryFilters interface.
 */
export function instanceOfDocumentSearchQueryFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentSearchQueryFiltersFromJSON(json: any): DocumentSearchQueryFilters {
    return DocumentSearchQueryFiltersFromJSONTyped(json, false);
}

export function DocumentSearchQueryFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSearchQueryFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': !exists(json, 'all') ? undefined : ((json['all'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSON)),
        'any': !exists(json, 'any') ? undefined : ((json['any'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSON)),
        'none': !exists(json, 'none') ? undefined : ((json['none'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSON)),
    };
}

export function DocumentSearchQueryFiltersToJSON(value?: DocumentSearchQueryFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': value.all === undefined ? undefined : ((value.all as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerToJSON)),
        'any': value.any === undefined ? undefined : ((value.any as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerToJSON)),
        'none': value.none === undefined ? undefined : ((value.none as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerToJSON)),
    };
}

