import React from "react";
import { Empty } from "antd5";
import { useLocation } from "wouter";

import { Table } from "../../../lib/core_components/Table";
import { ColumnType } from "../../../lib/core_components/Table/ColumnTypes";
import { OpportunityFromSearch } from "../../../lib/hooks/api/opportunities/useOpportunities";
import { Currency } from "../../app_layout/Typography";

const COLUMNS: ColumnType<OpportunityFromSearch>[] = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "description",
    title: "Description",
    dataIndex: "description",
  },
  {
    key: "value",
    title: "Value",
    render: (opportunity) => <Currency value={opportunity.value} />,
  },
  {
    key: "stage",
    title: "Stage",
    render: (opportunity) => opportunity.stage.name,
  },
];

type Props = {
  opportunities: OpportunityFromSearch[] | undefined;
  isLoading: boolean;
  isError: boolean;
};

export default function OpportunitiesTable({ opportunities, isLoading, isError }: Props) {
  const [, location] = useLocation();

  const handleRowClick = (key: string | undefined) => {
    if (key) {
      location(`/${key}`);
    }
  };

  return (
    <Table
      dataSource={opportunities}
      loading={isLoading}
      columns={COLUMNS}
      isError={isError}
      onRow={(record) => ({
        onClick: () => {
          handleRowClick(record.id);
        },
      })}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No opportunities found"} />
        ),
      }}
    />
  );
}
