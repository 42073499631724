type TabConfigType = { primary: PrimaryTab; secondary: SecondaryTab | null };

export enum PrimaryTab {
  NOTICES = "/",
  BUYERS = "/buyers",
  FRAMEWORKS = "/frameworks",
}

export enum SecondaryTab {
  "AWARDS" = "/awards",
  "MENTIONS" = "/mentions",
  "UPCOMING_EXPIRIES" = "/upcoming_expiries",
  "ALL_BUYERS" = "/buyers",
  "SAVED_BUYERS" = "/buyers/saved_buyers",
}

export const labelledRoutes: Record<PrimaryTab, string> & Record<SecondaryTab, string> = {
  [PrimaryTab.NOTICES]: "/",
  [PrimaryTab.FRAMEWORKS]: "/frameworks",
  [SecondaryTab.AWARDS]: "/awards",
  [SecondaryTab.MENTIONS]: "/mentions",
  [SecondaryTab.UPCOMING_EXPIRIES]: "/upcoming_expiries",
  [SecondaryTab.ALL_BUYERS]: "/buyers",
  [SecondaryTab.SAVED_BUYERS]: "/buyers/saved_buyers",
};

const isPrimaryTab = (tab: string): tab is PrimaryTab => {
  return Object.values(PrimaryTab).some((path) => path === tab);
};

export const getTabConfigFromLocation = (loc: string): TabConfigType => {
  const locWithoutTrailingSlash = loc === "/" ? loc : loc.match(/(.+)\/$/)?.[1] || loc;

  if (isPrimaryTab(locWithoutTrailingSlash)) {
    return { primary: locWithoutTrailingSlash, secondary: null };
  } else {
    let primary: PrimaryTab;

    switch (locWithoutTrailingSlash) {
      case SecondaryTab.UPCOMING_EXPIRIES:
        primary = PrimaryTab.NOTICES;
        break;
      case SecondaryTab.AWARDS:
        primary = PrimaryTab.NOTICES;
        break;

      case SecondaryTab.MENTIONS:
        primary = PrimaryTab.NOTICES;
        break;
      case SecondaryTab.ALL_BUYERS:
        primary = PrimaryTab.BUYERS;
        break;
      case SecondaryTab.SAVED_BUYERS:
        primary = PrimaryTab.BUYERS;
        break;
      default:
        return { primary: PrimaryTab.NOTICES, secondary: SecondaryTab.AWARDS };
    }
    return { primary, secondary: locWithoutTrailingSlash };
  }
};
