/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Filter field based on a date range
 *   Relative dates take precedence over absolute dates
 *   relative dates should be in the format ISO8601 duration format,
 *   e.g. P1Y for 1 year, P1M for 1 month, P1D for 1 day
 *   We currently only support a subset of the ISO8601 duration format
 * @export
 * @interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
 */
export interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate {
    /**
     * 
     * @type {Date}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
     */
    relativeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
     */
    relativeTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate
     */
    includeEmptyDates?: boolean;
}

/**
 * Check if a given object implements the NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate interface.
 */
export function instanceOfNoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json: any): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate {
    return NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSONTyped(json, false);
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'relativeFrom': !exists(json, 'relativeFrom') ? undefined : json['relativeFrom'],
        'relativeTo': !exists(json, 'relativeTo') ? undefined : json['relativeTo'],
        'includeEmptyDates': !exists(json, 'includeEmptyDates') ? undefined : json['includeEmptyDates'],
    };
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'relativeFrom': value.relativeFrom,
        'relativeTo': value.relativeTo,
        'includeEmptyDates': value.includeEmptyDates,
    };
}

