import Cookies from "js-cookie";

/** This is only ever set in dev mode to override UTM cookie domain. */
declare global {
  interface Window {
    stotlesCookieDomain?: string;
  }
}

export const STOTLES_UTM_COOKIE = "stotles_utm";

export type VisitInfo = {
  referrer: string;
  url: string;
  utmParams: UtmParams;
};

type UtmParams = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
  [key: string]: string | undefined;
};

export function storeUtmParams(): void {
  if (Cookies.get(STOTLES_UTM_COOKIE)) {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  const referrer = document.referrer;
  const visitInfo: VisitInfo = {
    referrer: referrer,
    url: location.href,
    utmParams: {},
  };

  params.forEach((v, k) => {
    const match = k.match(/utm_(.+)/);
    if (!match || !match[1]) {
      return;
    }
    visitInfo.utmParams[match[1]] = v;
  });

  Cookies.set(STOTLES_UTM_COOKIE, visitInfo, {
    domain: window.stotlesCookieDomain || "stotles.com",
  });
}
