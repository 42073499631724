import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TeamManagementUsersRequest, TeamManagementUsersResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = ["team_management_users", string | undefined, TeamManagementUsersRequest];

export function useUserManagement(
  request: TeamManagementUsersRequest,
  options?: UseQueryOptions<
    TeamManagementUsersResponse,
    unknown,
    TeamManagementUsersResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();

  return useQuery(
    ["team_management_users", window.currentUser?.guid, request],
    () =>
      api.teamManagementUsers({
        teamManagementUsersRequest: request,
      }),
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
