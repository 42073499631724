import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { AiReport } from "components/reports/ai/AiReport";
import { Report } from "components/reports/Report";
import { ReportSubscriptionContextProvider } from "components/reports/ReportSubscriptionProvider";
import { ReportConfig, ReportConfigFromJSON } from "lib/generated/admin-api";
import UTMParamsProvider from "lib/providers/UTMParams";
import { TrackingProvider } from "lib/tracking";

import css from "./ReportPage.module.scss";

type ReportPageProps = {
  report: ReportConfig;
};

function ReportPage({ report }: ReportPageProps) {
  report = ReportConfigFromJSON(report) as ReportConfig & { id: string };
  return (
    <div className={css.reportPage}>
      <TrackingProvider
        data={{
          "Report ID": report.id,
          "Report title": report.reportData.title,
          CompanyId: report.companyId,
        }}
      >
        <UTMParamsProvider utmParams={{ source: "webapp", campaign: report.id, medium: "report" }}>
          <ReportSubscriptionContextProvider>
            {report.reportType !== "ai_report" ? (
              <Report report={report} />
            ) : (
              <AiReport report={report} />
            )}
          </ReportSubscriptionContextProvider>
        </UTMParamsProvider>
      </TrackingProvider>
    </div>
  );
}

export default hot(
  withAppLayout(ReportPage, (props) => {
    const report = ReportConfigFromJSON(props.report);
    return {
      pageName: "Report",
      pageViewProps: {
        "Report ID": report.id,
        "Report title": report.reportData.title,
        CompanyId: report.companyId,
      },
    };
  }),
);
