import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UpdateTeamMutation,
  UpdateTeamMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const updateTeam = graphql(`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(UpdateTeamInput: $input) {
      id
      name
      isPrivate
    }
  }
`);

export function useUpdateTeam(
  options?: UseMutationOptions<UpdateTeamMutation, unknown, UpdateTeamMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(updateTeam, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["teams"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
  return { data: data?.updateTeam, ...rest };
}
