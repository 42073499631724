/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MergeAction } from './MergeAction';
import {
    MergeActionFromJSON,
    MergeActionFromJSONTyped,
    MergeActionToJSON,
} from './MergeAction';
import type { RenameAction } from './RenameAction';
import {
    RenameActionFromJSON,
    RenameActionFromJSONTyped,
    RenameActionToJSON,
} from './RenameAction';

/**
 * 
 * @export
 * @interface HierarchicalCandidateCandidateAction
 */
export interface HierarchicalCandidateCandidateAction {
    /**
     * 
     * @type {MergeAction}
     * @memberof HierarchicalCandidateCandidateAction
     */
    merge?: MergeAction;
    /**
     * 
     * @type {RenameAction}
     * @memberof HierarchicalCandidateCandidateAction
     */
    rename?: RenameAction;
}

/**
 * Check if a given object implements the HierarchicalCandidateCandidateAction interface.
 */
export function instanceOfHierarchicalCandidateCandidateAction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HierarchicalCandidateCandidateActionFromJSON(json: any): HierarchicalCandidateCandidateAction {
    return HierarchicalCandidateCandidateActionFromJSONTyped(json, false);
}

export function HierarchicalCandidateCandidateActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HierarchicalCandidateCandidateAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merge': !exists(json, 'merge') ? undefined : MergeActionFromJSON(json['merge']),
        'rename': !exists(json, 'rename') ? undefined : RenameActionFromJSON(json['rename']),
    };
}

export function HierarchicalCandidateCandidateActionToJSON(value?: HierarchicalCandidateCandidateAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merge': MergeActionToJSON(value.merge),
        'rename': RenameActionToJSON(value.rename),
    };
}

