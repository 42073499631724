/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue';

/**
 * A set of filters that can be used to search for frameworks.
 * @export
 * @interface FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
 */
export interface FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    /**
     * The ids of the frameworks to be matched against the query.
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    ids?: Array<string>;
    /**
     * The country codes to be matched against the query.
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    countries?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    searchText?: string;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    contractStartDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    contractEndDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    tenderingCloseDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    lastUpdatedDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    procedureTypes?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerProcedureTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    awardTypes?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    stages?: Array<FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum>;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    providers?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    contractingAuthorities?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    transactingBuyers?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    suppliers?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes}
     * @memberof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    categoriesCpvCodes?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes;
}


/**
 * @export
 */
export const FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerProcedureTypesEnum = {
    Framework: 'FRAMEWORK',
    DynamicPurchasingSystem: 'DYNAMIC_PURCHASING_SYSTEM'
} as const;
export type FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerProcedureTypesEnum = typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerProcedureTypesEnum[keyof typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerProcedureTypesEnum];

/**
 * @export
 */
export const FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardTypesEnum = {
    DirectAward: 'DIRECT_AWARD',
    CompetitionOnly: 'COMPETITION_ONLY',
    CompetitionAndDirect: 'COMPETITION_AND_DIRECT',
    Other: 'OTHER'
} as const;
export type FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardTypesEnum = typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardTypesEnum[keyof typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardTypesEnum];

/**
 * @export
 */
export const FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum = {
    Stale: 'STALE',
    Upcoming: 'UPCOMING',
    Tendering: 'TENDERING',
    Closed: 'CLOSED',
    Awarded: 'AWARDED',
    Live: 'LIVE',
    Expired: 'EXPIRED',
    Cancelled: 'CANCELLED'
} as const;
export type FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum = typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum[keyof typeof FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum];


/**
 * Check if a given object implements the FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner interface.
 */
export function instanceOfFrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON(json: any): FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    return FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSONTyped(json, false);
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'countries': !exists(json, 'countries') ? undefined : json['countries'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'contractStartDate': !exists(json, 'contractStartDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['contractStartDate']),
        'contractEndDate': !exists(json, 'contractEndDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['contractEndDate']),
        'tenderingCloseDate': !exists(json, 'tenderingCloseDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['tenderingCloseDate']),
        'lastUpdatedDate': !exists(json, 'lastUpdatedDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['lastUpdatedDate']),
        'value': !exists(json, 'value') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSON(json['value']),
        'procedureTypes': !exists(json, 'procedureTypes') ? undefined : json['procedureTypes'],
        'awardTypes': !exists(json, 'awardTypes') ? undefined : json['awardTypes'],
        'stages': !exists(json, 'stages') ? undefined : json['stages'],
        'providers': !exists(json, 'providers') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['providers']),
        'contractingAuthorities': !exists(json, 'contractingAuthorities') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['contractingAuthorities']),
        'transactingBuyers': !exists(json, 'transactingBuyers') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['transactingBuyers']),
        'suppliers': !exists(json, 'suppliers') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['suppliers']),
        'categoriesCpvCodes': !exists(json, 'categoriesCpvCodes') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSON(json['categoriesCpvCodes']),
    };
}

export function FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON(value?: FrameworkSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'countries': value.countries,
        'keywords': value.keywords,
        'searchText': value.searchText,
        'contractStartDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.contractStartDate),
        'contractEndDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.contractEndDate),
        'tenderingCloseDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.tenderingCloseDate),
        'lastUpdatedDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.lastUpdatedDate),
        'value': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueToJSON(value.value),
        'procedureTypes': value.procedureTypes,
        'awardTypes': value.awardTypes,
        'stages': value.stages,
        'providers': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.providers),
        'contractingAuthorities': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.contractingAuthorities),
        'transactingBuyers': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.transactingBuyers),
        'suppliers': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.suppliers),
        'categoriesCpvCodes': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesToJSON(value.categoriesCpvCodes),
    };
}

