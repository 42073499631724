import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  PartnersCompetitorsRequest,
  PartnersCompetitorsResponse,
  SuppliersV3Request,
} from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type PartnerOrCompetitorRequest = {
  buyerId: number;
  request: PartnersCompetitorsRequest;
};

type SupplierRequest = {
  buyerId: number;
  request: SuppliersV3Request;
};

export function useBuyersPartnersCompetitors(
  request: PartnerOrCompetitorRequest,
  options?: UseQueryOptions<
    PartnersCompetitorsResponse,
    unknown,
    PartnersCompetitorsResponse,
    [string, SupplierRequest]
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["buyersPartnersCompetitors", request],
    async () => {
      const response = await api.fetchPartnersOrCompetitorsForBuyer({
        id: request.buyerId,
        partnersCompetitorsRequest: request.request,
      });
      return response;
    },
    { ...options, refetchOnMount: false },
  );
}
