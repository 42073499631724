import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { NoticeListEntitiesResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string];

export function useListNoticeEntitiesProvider(
  options?: UseQueryOptions<
    NoticeListEntitiesResponse | undefined,
    unknown,
    NoticeListEntitiesResponse,
    QueryKey
  >,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["listNoticeEntities"],
    async () => {
      const response = await api.noticeListEntities();
      return response;
    },
    options,
  );
}
