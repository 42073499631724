import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { hot } from "react-hot-loader/root";
import { App } from "antd5";

import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import MigrateSignalsStep from "components/organisation_clean/signal_migration/MigrateSignalsStep";
import SelectCompanySignalsStep from "components/organisation_clean/signal_migration/SelectCompanySignalsStep";
import NavigationFooter from "lib/core_components/NavigationFooter";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { SignalToMigrate } from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import { useSubmitMigrateSignalTasks } from "lib/hooks/api/admin/signal_migration/useSubmitMigrateSignalTasks";
import { OrgBasicInfo } from "lib/hooks/api/organisations/useSearchOrganisations";
import { usePreventNavigation } from "lib/hooks/usePreventNavigation";

import css from "./OrgSignalMigrationPage.module.scss";

export enum Steps {
  CHOOSE_COMPANY_SIGNAL,
  MIGRATE_SUPPLIER_SIGNALS,
}

export type MigrateSignalsFormState = {
  signals: SignalToMigrate[];
  orgs?: OrgBasicInfo[];
  step?: Steps;
};

const emptyFormState: MigrateSignalsFormState = {
  signals: [],
  step: Steps.CHOOSE_COMPANY_SIGNAL,
};

/**
 * Tool which allows users to verify buyers via linking them with an oscar ID, mark buyers as inactive and also
 * transfer the responsibilites of these inactive buyers to other buyers (these buyers MUST be verified).
 * @returns
 */
function OrgSignalMigrationPage() {
  const [disableSubmit, setDisableSubmit] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
    watch,
    setValue,
  } = useForm<MigrateSignalsFormState>({
    mode: "onChange",
    defaultValues: emptyFormState,
  });

  const currentFormState = watch();

  // For some annoying reason, the form kept submitting automatically when orgs were populated. This useEffect stops this tomfoolery
  useEffect(() => {
    if (currentFormState.step === Steps.MIGRATE_SUPPLIER_SIGNALS) {
      // Create a timer to set the state to false after 2 seconds
      const timer = setTimeout(() => {
        setDisableSubmit(false);
      }, 2000);
      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [currentFormState.step]);

  const { message } = App.useApp();
  usePreventNavigation(
    isDirty,
    "Are you sure you want to leave the page? All changes will go unsaved.",
  );

  const { mutate: onSubmit, isLoading } = useSubmitMigrateSignalTasks(false, {
    onSuccess: () => {
      void message.success("Thanks for submitting the signal migration task!");
      reset();
    },
    onError: (e) => {
      const msg = e instanceof Error ? e.message : `${JSON.stringify(e)}`;
      void message.error(`Error submitting signal migration task: ${msg}`);
    },
  });

  const { mutate: onSubmitNow, isLoading: isLoadingSubmitNow } = useSubmitMigrateSignalTasks(true, {
    onSuccess: () => {
      void message.success(
        "Thanks for submitting the signal migration task! \
      Task was processed immediately and does not need review",
      );
      reset();
    },
    onError: (e) => {
      const msg = e instanceof Error ? e.message : `${JSON.stringify(e)}`;
      void message.error(`Error submitting signal migration task: ${msg}`);
    },
  });

  return (
    <form
      className={css.pageContainer}
      onSubmit={handleSubmit((v) => {
        onSubmit(v);
      })}
    >
      {currentFormState.step === Steps.CHOOSE_COMPANY_SIGNAL ? (
        <>
          <header>
            <h1>Step 1: Choose a company and which of it's signals to migrate</h1>
          </header>
          <SelectCompanySignalsStep control={control} reset={reset} />
          <NavigationFooter
            className={css.nav}
            onNext={() => {
              setDisableSubmit(true);
              setValue("step", Steps.MIGRATE_SUPPLIER_SIGNALS);
            }}
            nextButtonLabel="Next"
            currentStep={Steps.CHOOSE_COMPANY_SIGNAL}
            totalSteps={2}
          />
        </>
      ) : (
        <>
          <header>
            <h1>Step 2. Signal migration statement</h1>
          </header>
          <MigrateSignalsStep control={control} isReview={false} />
          <NavigationFooter
            className={css.nav}
            onNext={() => void message.loading("Submitting for review")}
            nextButtonLabel="Submit for review"
            nextLoading={isLoading}
            nextDisabled={
              disableSubmit || !currentFormState?.orgs || currentFormState?.orgs.length === 0
            }
            nextHtmlType="submit"
            backButtonLabel="Back"
            onBack={() => setValue("step", Steps.CHOOSE_COMPANY_SIGNAL)}
            renderAdditionalCTARight={() => (
              <>
                {FeatureToggles.isEnabled(Feature.SUBMIT_BUYER_CLEANING) && (
                  <TextButton
                    className={css.submitNow}
                    loading={isLoadingSubmitNow}
                    disabled={!currentFormState?.orgs || currentFormState?.orgs.length === 0}
                    onClick={async () => {
                      await onSubmitNow(currentFormState);
                    }}
                  >
                    Submit &amp; make changes now
                  </TextButton>
                )}
              </>
            )}
            currentStep={Steps.MIGRATE_SUPPLIER_SIGNALS}
            totalSteps={2}
          />
        </>
      )}
    </form>
  );
}

export default hot(
  withAppLayout(OrgSignalMigrationPage, {
    pageName: "Signal migration statement",
  }),
);
