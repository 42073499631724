import React, { useState } from "react";
import { Alert, Select } from "antd5";

import { createUseDebounce } from "lib/debounce";
import { CompanyDto } from "lib/generated/admin-api/models/CompanyDto";
import { ResponseError } from "lib/generated/admin-api/runtime";
import { useAdminApi } from "lib/stotlesAdminApiContext";

import css from "./SwitchCompanySearch.module.scss";

type Props = {
  onChange: (companyGuid: CompanyDto) => void;
};

function SwitchCompanySearch(props: Props) {
  const [companies, setCompanies] = useState<CompanyDto[] | undefined>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto>();
  const [error, setError] = useState<string | undefined>(undefined);
  const adminApi = useAdminApi();

  const debounce = createUseDebounce(500);

  const onSearch = async (event: string) => {
    try {
      const { companies } = await adminApi.api.searchCompanies({
        searchCompaniesRequest: { query: event },
      });
      setCompanies(companies);
    } catch (apiError) {
      if (apiError instanceof ResponseError) {
        setError(`Error ${apiError.response.status}: ${apiError.message}`);
      } else {
        setError("Unknown error occurred.");
      }
    }
  };

  const onSelect = (event: string) => {
    const companyGuid = event;
    const company = companies?.find((c) => c.guid === companyGuid);
    setSelectedCompany(company);
    company && props.onChange(company);
  };

  const debouncedOnSearch = debounce(onSearch);

  return (
    <div>
      <Select
        className={css.select}
        id="search-by-text"
        allowClear
        showSearch
        placeholder="Search for a company"
        onSearch={debouncedOnSearch}
        onChange={onSelect}
        defaultActiveFirstOption={false}
        suffixIcon={false}
        filterOption={false}
        notFoundContent={null}
        value={selectedCompany?.guid}
        options={companies?.map((company) => ({
          label: (
            <>
              {company.name} {company.isDemo && "- (demo)"} - ({company.users?.length} users)
            </>
          ),
          value: company.guid,
        }))}
      />
      {error && <Alert message={error} type="error" />}
    </div>
  );
}

export default SwitchCompanySearch;
