import { isEqual, pickBy } from "lodash";

import { EventNames, TrackingEvent } from "lib/tracking";
import { SpendDataFilters } from "./hooks/useSpendPageState";

export function createViewSelectedEvent(viewName: string): TrackingEvent {
  return {
    name: EventNames.viewSelected,
    data: {
      "Context source": "Quick select",
      "View name": viewName,
    },
  };
}

export function createFilterChangedEvents(
  previous: SpendDataFilters,
  next: SpendDataFilters,
): TrackingEvent[] {
  // find the diff between the 2 filters
  const diff = pickBy(next, (v, k) => !isEqual(previous[k as keyof SpendDataFilters], v));

  // log each filter change, unlikley this will ever be more than 1
  return Object.entries(diff).map(([key, value]) => ({
    name: EventNames.filterActioned,
    data: {
      "Context source": "Filter slide-out",
      "Filter name": key,
      "Filter selection": value,
      "Action type":
        Array.isArray(value) && value.length === 0 ? "Filter cleared" : "Filter applied",
    },
  }));
}

export function createGroupByChangedEvent(groupByName: string): TrackingEvent {
  return {
    name: EventNames.groupBySelected,
    data: {
      "Context source": "Tabs",
      "Selection name": groupByName,
    },
  };
}

export function createDataExportedEvent(exportFormat: string, totalRows: number): TrackingEvent {
  return {
    name: EventNames.exportedData,
    data: {
      "Context source": "Table",
      "Data type": "Table",
      Format: exportFormat,
      Count: totalRows,
    },
  };
}
