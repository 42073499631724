import React from "react";
import { hot } from "react-hot-loader/root";
import { Menu } from "antd5";
import { Redirect, Route, Router, Switch, useLocation } from "wouter";

import { ButtonLink, TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableBuyerTable } from "components/buyers/FilterableBuyerTable";
import { DEFAULT_BUYER_FILTERS } from "components/buyers/useFilterableBuyerTableState";
import { GuestPassBanner } from "components/guest_passes/banner/GuestPassBanner";
import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { FilterableNoticeTable } from "components/notices/FilterableNoticeTable";
import { NoticeColumns } from "components/notices/NoticeTable";
import { useFilterableNoticeTableState } from "components/notices/useFilterableNoticeTableState";
import { NoticeFilters } from "components/notices/utils";
import {
  getTabConfigFromLocation,
  labelledRoutes,
  PrimaryTab,
  SecondaryTab,
} from "components/supplier_details/supplier_utils";
import SupplierHeader from "components/supplier_details/SupplierHeader";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import {
  GetBuyerListsPermissionEnum,
  SupplierDetailsDto,
  SupplierDetailsDtoFromJSON,
} from "lib/generated/app-api";
import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { EventData, EventNames, logEvent, TrackingProvider } from "lib/tracking";
import * as tracking from "lib/tracking";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { GuestAccessPass } from "lib/types/models";
import SupplierBuyerRelationshipPage from "./SupplierBuyerRelationshipPage";

import css from "./GuestPassSupplierProfilePage.module.scss";

const AWARDS_COLUMNS: NoticeColumns[] = [
  "relevance_score",
  "indicator",
  "title",
  "expiry_date",
  "value",
  "stage",
  "qualification",
  "assignee",
];

export const DEFAULT_SUPPLIER_NOTICES_SORT: NoticeFilters["sort"] = {
  field: "relevance_score",
  order: "DESC",
};

type WrapperProps = {
  supplier: unknown;
  guest_access_pass: GuestAccessPass;
};

export function GuestPassSupplierProfilePageWrapper({ supplier: supplierRaw }: WrapperProps) {
  const supplier = SupplierDetailsDtoFromJSON(supplierRaw);

  return (
    <Router base={`/guest_access/suppliers/${supplier.id}`}>
      <Switch>
        <Route path="/buyers/saved_buyers">
          <GuestPassSupplierProfilePage supplier={supplier} />
        </Route>

        {/* buyer supplier relationship page */}
        <Route<{ buyerGuid: string }> path="/buyer/:buyerGuid">
          {({ buyerGuid }) => <Redirect to={`/buyers/${buyerGuid}`} replace={true} />}
        </Route>
        <Route<{ buyerGuid: string }> path="/buyers/:buyerGuid">
          {({ buyerGuid }) => (
            <SupplierBuyerRelationshipPage
              supplierName={supplier.name}
              buyerGuid={buyerGuid}
              supplierGuid={supplier.guid}
            />
          )}
        </Route>

        {/* Default */}
        <Route>
          <GuestPassSupplierProfilePage supplier={supplier} />
        </Route>
      </Switch>
    </Router>
  );
}

type PageProps = {
  supplier: SupplierDetailsDto;
};
export function GuestPassSupplierProfilePage({ supplier }: PageProps) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const { data: buyerLists } = useBuyerLists(GetBuyerListsPermissionEnum.CreateBuyerListEntries, {
    enabled: !!window.currentUser,
  });

  const [location, navigate] = useLocation();

  const setLocation = React.useCallback(
    (route: string) => navigate(`${route}${window.location.search}`),
    [navigate],
  );

  const [tabConfig, setTabConfig] = React.useState(() =>
    getTabConfigFromLocation(location as PrimaryTab | SecondaryTab),
  );

  const { setNoticeTableFilters } = useFilterableNoticeTableState();

  const handleNavigation = (
    loc: PrimaryTab | SecondaryTab,
    eventName: EventNames,
    eventData: EventData,
  ) => {
    setNoticeTableFilters(undefined);
    setTabConfig(getTabConfigFromLocation(loc));

    setLocation(loc);

    logEvent(eventName, eventData);
  };

  const getDefaultNoticeFilters = (): NoticeFilters => {
    switch (tabConfig.secondary) {
      case SecondaryTab.AWARDS:
        return enableNewSupplierFilter && window.currentUser?.use_supplier_name === false
          ? {
              ...EMPTY_FILTERS,
              stage: ["AWARDED"],
              supplierMentionType: SupplierMentionType.DirectlyAwarded,
              supplierGuids: [supplier.guid],
              sort: DEFAULT_SUPPLIER_NOTICES_SORT,
            }
          : {
              ...EMPTY_FILTERS,
              stage: ["AWARDED"],
              suppliers: [supplier.id],
              signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
              sort: DEFAULT_SUPPLIER_NOTICES_SORT,
            };

      case SecondaryTab.MENTIONS:
        return {
          ...EMPTY_FILTERS,
          supplierMentionType: SupplierMentionType.MentionedInOnly,
          supplierGuids: [supplier.guid],
          sort: DEFAULT_SUPPLIER_NOTICES_SORT,
        };
      case SecondaryTab.UPCOMING_EXPIRIES:
        return {
          ...EMPTY_FILTERS,
          stage: ["AWARDED"],
          suppliers: [supplier.id],
          expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },
          signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
        };
      default:
        return {
          ...EMPTY_FILTERS,
          suppliers: [supplier.id],
        };
    }
  };

  return (
    <TrackingProvider
      data={{
        "Context source": "Guest pass modal",
      }}
    >
      <DetailsPage>
        <DetailsHeader className={css.noPaddingBottom}>
          <TrackingProvider data={{ "Context source": "Guest pass banner" }}>
            <GuestPassBanner />
          </TrackingProvider>
          <TrackingProvider data={{ "Context source": "Header" }}>
            <SupplierHeader supplier={supplier} handleNavigation={handleNavigation} />
            <TrackingProvider
              data={{ "Context source": labelledRoutes[tabConfig.secondary || tabConfig.primary] }}
            >
              <Menu mode="horizontal" selectedKeys={[tabConfig.primary]} className={css.menuTabs}>
                <Menu.Item
                  key={PrimaryTab.NOTICES}
                  onClick={() =>
                    handleNavigation(PrimaryTab.NOTICES, EventNames.tabChanged, {
                      "Context source": "Primary Tab",
                      "Tab Selected": labelledRoutes[PrimaryTab.NOTICES],
                    })
                  }
                  className={css.menuTabItem}
                >
                  Notices
                </Menu.Item>
                <Menu.Item
                  key={PrimaryTab.BUYERS}
                  onClick={() =>
                    handleNavigation(PrimaryTab.BUYERS, EventNames.tabChanged, {
                      "Context source": "Primary Tab",
                      "Tab Selected": labelledRoutes[PrimaryTab.BUYERS],
                    })
                  }
                  className={css.menuTabItem}
                >
                  Buyers
                </Menu.Item>
              </Menu>
            </TrackingProvider>
          </TrackingProvider>
        </DetailsHeader>
        <DetailsContent className={css.supplierProfileContent}>
          {tabConfig.primary === PrimaryTab.BUYERS ? (
            <TrackingProvider data={{ "Context source": "Buyers tab" }}>
              <FilterableBuyerTable
                defaultFilters={{
                  ...DEFAULT_BUYER_FILTERS,
                  supplierIds: [supplier.id],
                  buyerLists:
                    tabConfig.secondary === SecondaryTab.SAVED_BUYERS
                      ? buyerLists?.all?.map((buyerList) => buyerList.id) || []
                      : [],
                }}
                defaultSortOrder={{ field: "signals", order: "DESC" }}
                hiddenSections={["suppliers"]}
              />
            </TrackingProvider>
          ) : (
            <FilterableNoticeTable
              defaultFilters={getDefaultNoticeFilters()}
              defaultColumns={AWARDS_COLUMNS}
              hiddenSections={["suppliers"]}
              paywallEnabled={true}
            />
          )}
        </DetailsContent>
      </DetailsPage>
    </TrackingProvider>
  );
}

const displayCustomAction: () => JSX.Element = () => {
  return (
    <div className={css.linksContainer}>
      <TextLink to="/users/sign_in" className={css.signInLink}>
        Sign in
      </TextLink>

      <ButtonLink
        to="/get-started"
        type="primary"
        className={css.signUpButton}
        eventName={tracking.EventNames.clickedSignUpCTA}
        eventAttributes={{
          "Context source": "Guest passes",
        }}
      >
        Sign up for free
      </ButtonLink>
    </div>
  );
};

export default hot(
  withAppLayout(GuestPassSupplierProfilePageWrapper, (props) => {
    const supplier = SupplierDetailsDtoFromJSON(props.supplier);

    return {
      pageName: "Guest pass - Supplier profile",
      pageViewProps: {
        "Organisation name": supplier.name,
        "Organisation ID": supplier.id,
        "Guest pass title": props.guest_access_pass.title,
        "Guest pass GUID": props.guest_access_pass.id,
      },
      trackingData: {
        "Organisation name": supplier.name,
        "Guest pass title": props.guest_access_pass.title,
        "Guest pass GUID": props.guest_access_pass.id,
      },
      hideMenuItems: true,
      hideUserItems: true,
      disableLogoNav: true,
      displayCustomAction: displayCustomAction(),
    };
  }),
);
