/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue';

/**
 * 
 * @export
 * @interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
 */
export interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    procurementProcessIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    countries?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    searchText?: string;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    categoriesCpvCodes?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    suppliers?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    buyers?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    stages?: Array<NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum>;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    awardDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    closeDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    publishedDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    expiryDate?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    frameworks?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner
     */
    frameworkActivity?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFrameworkActivityEnum;
}


/**
 * @export
 */
export const NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum = {
    StalePreTender: 'STALE_PRE_TENDER',
    PreTender: 'PRE_TENDER',
    Open: 'OPEN',
    Closed: 'CLOSED',
    Awarded: 'AWARDED'
} as const;
export type NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum = typeof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum[keyof typeof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerStagesEnum];

/**
 * @export
 */
export const NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFrameworkActivityEnum = {
    HideFrameworkAgreementNotices: 'HIDE_FRAMEWORK_AGREEMENT_NOTICES',
    HideAllFrameworkActivity: 'HIDE_ALL_FRAMEWORK_ACTIVITY',
    ShowOnlyFrameworkCallOffs: 'SHOW_ONLY_FRAMEWORK_CALL_OFFS'
} as const;
export type NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFrameworkActivityEnum = typeof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFrameworkActivityEnum[keyof typeof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFrameworkActivityEnum];


/**
 * Check if a given object implements the NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner interface.
 */
export function instanceOfNoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSON(json: any): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    return NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSONTyped(json, false);
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'procurementProcessIds': !exists(json, 'procurementProcessIds') ? undefined : json['procurementProcessIds'],
        'countries': !exists(json, 'countries') ? undefined : json['countries'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'categoriesCpvCodes': !exists(json, 'categoriesCpvCodes') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSON(json['categoriesCpvCodes']),
        'suppliers': !exists(json, 'suppliers') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['suppliers']),
        'buyers': !exists(json, 'buyers') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['buyers']),
        'value': !exists(json, 'value') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueFromJSON(json['value']),
        'stages': !exists(json, 'stages') ? undefined : json['stages'],
        'awardDate': !exists(json, 'awardDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['awardDate']),
        'closeDate': !exists(json, 'closeDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['closeDate']),
        'publishedDate': !exists(json, 'publishedDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['publishedDate']),
        'expiryDate': !exists(json, 'expiryDate') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['expiryDate']),
        'frameworks': !exists(json, 'frameworks') ? undefined : json['frameworks'],
        'frameworkActivity': !exists(json, 'frameworkActivity') ? undefined : json['frameworkActivity'],
    };
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerToJSON(value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'procurementProcessIds': value.procurementProcessIds,
        'countries': value.countries,
        'keywords': value.keywords,
        'searchText': value.searchText,
        'categoriesCpvCodes': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesToJSON(value.categoriesCpvCodes),
        'suppliers': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.suppliers),
        'buyers': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.buyers),
        'value': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerValueToJSON(value.value),
        'stages': value.stages,
        'awardDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.awardDate),
        'closeDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.closeDate),
        'publishedDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.publishedDate),
        'expiryDate': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.expiryDate),
        'frameworks': value.frameworks,
        'frameworkActivity': value.frameworkActivity,
    };
}

