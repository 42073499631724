import React from "react";
import { CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Tooltip } from "antd5";

import {
  canEditList,
  DeleteListModal,
  DuplicateListModal,
  RenameListModal,
} from "components/user_lists/UserListsModals";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useDialogManager } from "lib/providers/DialogManager";
import { EventDataTypes, TrackingProvider, useTracking } from "lib/tracking";

type UserListProps = {
  list: GetRecordListsResponseResult;
};

export function EditListMenu({ list }: UserListProps) {
  const dialogManager = useDialogManager();
  const { trackingData } = useTracking();
  const canEdit = canEditList(list);
  return (
    <TrackingProvider
      data={{ "List name": list.name, "List id": list.id, "Data type": EventDataTypes.recordList }}
    >
      <Dropdown
        menu={{
          items: [
            {
              label: "Duplicate",
              icon: <CopyOutlined />,
              key: "duplicate",
            },
            {
              label: (
                <Tooltip
                  title={
                    canEdit
                      ? undefined
                      : "You can’t rename this list because you are not the owner."
                  }
                >
                  Rename
                </Tooltip>
              ),
              icon: <EditOutlined />,
              key: "rename",
              disabled: !canEdit,
            },
            {
              label: (
                <Tooltip
                  title={
                    canEdit
                      ? undefined
                      : "You can’t delete this list because you are not the owner."
                  }
                >
                  Delete
                </Tooltip>
              ),
              icon: <DeleteOutlined />,
              key: "delete",
              disabled: !canEdit,
            },
          ],
          onClick: (e) => {
            e.domEvent.stopPropagation();
            if (e.key === "duplicate") {
              return void dialogManager.openDialog(DuplicateListModal, {
                list: list,
                trackingData,
              });
            }
            if (e.key === "rename") {
              return void dialogManager.openDialog(RenameListModal, {
                list: list,
                trackingData,
              });
            }
            if (e.key === "delete") {
              return void dialogManager.openDialog(DeleteListModal, {
                list: list,
                trackingData,
              });
            }
          },
        }}
        placement="bottomRight"
      >
        <Button onClick={(e) => e.stopPropagation()}>
          Actions
          <DownOutlined />
        </Button>
      </Dropdown>
    </TrackingProvider>
  );
}
