import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetNotificationSettingsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = [string];

export function useNotificationSettings(
  options?: UseQueryOptions<unknown, unknown, GetNotificationSettingsResponse, QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    ["notificationSettings"],
    async () => {
      return await api.getNotificationSettings();
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
