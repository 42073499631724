import * as React from "react";
import { Button, message } from "antd5";

import { useStotlesApi } from "lib/stotlesApiContext";
import SettingsContentContainr from "./SettingsContentContainer";

function ChangePassword(): JSX.Element {
  const api = useStotlesApi();

  return (
    <SettingsContentContainr title="Change password">
      <div>
        <p>
          If you need to change your password, click the button below and we will send you
          instructions via email
        </p>

        <Button
          type="primary"
          onClick={async () => {
            try {
              await api.sendPasswordResetRequest();
              message.success("You should receive an email with instructions shortly", 2);
            } catch (e) {
              message.error(
                "Unable to initiate password reset; please contact us if the problem persists",
                5,
              );
              throw e;
            }
          }}
        >
          Reset password
        </Button>
      </div>
    </SettingsContentContainr>
  );
}

export default ChangePassword;
