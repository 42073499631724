import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { BuyerDto, BuyerListDto, UpdateBuyerListRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type UpdateListParams = { list: BuyerListDto } & UpdateBuyerListRequest;

export function useUpdateBuyerList(
  options?: UseMutationOptions<BuyerDto, unknown, UpdateListParams, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(
    ({ list, name }: UpdateListParams) =>
      api.updateBuyerList({ id: list.id, updateBuyerListRequest: { name } }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["buyerLists"]);
        void queryClient.invalidateQueries(["supplierStats"]);
        void queryClient.invalidateQueries(["buyers", "buyersForSupplier"]);
        logEvent(EventNames.buyerListRenamed, {
          "List id": variables.list.id,
          "Previous list name": variables.list.name,
          "New list name": variables.name,

          "Context source": "saved-buyers",
        });
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
