import React from "react";
import { Form } from "antd5";

import AllCountriesField from "components/form_components/AllCountriesField";
import SimplifiedCountriesField from "components/form_components/SimplifiedCountriesField";
import LanguagesField from "../../../form_components/LanguagesField";

import css from "./Countries.module.scss";

type Props = {
  valid: boolean;
  values: string[];
  onValuesChange: (newVals: string[] | undefined) => void;
  languages: string[] | undefined;
  onLanguagesChange: (newVals: string[] | undefined) => void;
  internationalDataAccess?: boolean;
};

export default function CountriesCollapseContent({
  valid,
  values,
  onValuesChange,
  internationalDataAccess,
  languages,
  onLanguagesChange,
}: Props): JSX.Element {
  return (
    <Form.Item
      validateStatus={valid ? undefined : "error"}
      help={valid ? undefined : "Please choose at least one country"}
    >
      {internationalDataAccess ? (
        <div className={css.intlContainer}>
          <AllCountriesField
            values={values}
            label="Which countries would you like to cover?"
            onChange={onValuesChange}
          />
          <LanguagesField
            values={languages}
            label="Which languages would you like to search in?"
            onChange={onLanguagesChange}
          />
        </div>
      ) : (
        <SimplifiedCountriesField
          values={values}
          label="Which countries would you like to cover?"
          onChange={onValuesChange}
        />
      )}
    </Form.Item>
  );
}
