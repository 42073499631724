import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import { CandidateOperation } from "components/organisation_clean/types";
import { SubmitOrgCleaningTaskRequestTaskTypeEnum, SubmittedDecision } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { assertDefined } from "lib/utils";
import { useDeleteSignalNameToOrgMappings } from "./useDeleteSignalNameToOrgMappings";
import { SignalToMigrate } from "./useSignalsToMigrate";

/**
 * Creates a list of OrgToAlias decisions to be sent for later review
 * @param orgGuid
 * @param newOrg
 * @returns
 */
export function createSignalToOrgDecisions(
  orgId: string,
  signalsToMigrate: SignalToMigrate[],
): SubmittedDecision[] {
  return signalsToMigrate.map((signal) => {
    return {
      candidateOrganisationId: orgId,
      decisionData: {
        signalsToOrg: {
          qualification: CandidateOperation.SIGNALS_TO_ORG,
          signalIds: signal.ids,
          signalName: signal.name,
          note: "NEW_SIGNAL_MAPPING_PROCESS",
          cancelled: false,
        },
      },
    };
  });
}

export const useSubmitMigrateSignalTasks = (
  submitNow: boolean,
  options?: UseMutationOptions<void, unknown, MigrateSignalsFormState, unknown>,
) => {
  const api = useOpenApi();
  const { mutateAsync: deleteOrgSignals } = useDeleteSignalNameToOrgMappings();

  return useMutation(async (formState: MigrateSignalsFormState) => {
    const { signals, orgs } = formState;

    assertDefined(signals);
    assertDefined(orgs);
    let decisions: SubmittedDecision[] = [];

    const orgIds = orgs.map((org) => org.id);
    for (const id of orgIds) {
      decisions.push(...createSignalToOrgDecisions(id, signals));
    }

    if (submitNow) {
      await deleteOrgSignals({ signalNames: signals.map((s) => s.name) });
      await api.submitAndProcessOrgCleaningTask({
        submitAndProcessOrgCleaningTaskRequest: {
          decisions,
          organisationId: orgIds[0],
          taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DataMigration,
        },
      });
    } else {
      const cancelLocation = "ORG_SIGNAL_MIGRATION_PAGE";
      const cancelNote = "SUBMITTED_FOR_REVIEW";
      // Only one task for all of the decisions, because we want one review page
      await api.submitOrgCleaningTask({
        submitOrgCleaningTaskRequest: {
          decisions,
          // This id will just be an anchor org used to represent the task, there are actually several anchor orgs within this task. Each with their own decision
          organisationId: orgIds[0],
          cancelLocation,
          cancelNote,
          taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DataMigration,
        },
      });
    }
  }, options);
};
