import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { AllBuyersStatsRequest, AllBuyersStatsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, AllBuyersStatsRequest];

function getQueryKey(allBuyersStatsRequest: AllBuyersStatsRequest): QueryKey {
  return ["buyers", allBuyersStatsRequest];
}

export function useAllBuyerStats(
  allBuyerStatsRequest: AllBuyersStatsRequest,
  options?: UseQueryOptions<AllBuyersStatsResponse, unknown, AllBuyersStatsResponse, QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    getQueryKey(allBuyerStatsRequest),
    ({ queryKey: [, request] }) =>
      api.allBuyersStats({
        allBuyersStatsRequest: request,
      }),
    options,
  );
}
