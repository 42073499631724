import React, { useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import classnames from "classnames";

import css from "./CollapseHeader.module.scss";

interface CollapseHeaderProps {
  title: string;
  itemKey: string;
  activeKeys: string[] | string;
  small?: boolean;
}

export function CollapseHeader({ title, itemKey, activeKeys, small }: CollapseHeaderProps) {
  const isActive = useMemo(() => {
    if (Array.isArray(activeKeys)) {
      return activeKeys.includes(itemKey);
    }
    return activeKeys === itemKey;
  }, [activeKeys, itemKey]);

  return (
    <div className={classnames(css.collapseHeader, { [css.small]: small })}>
      <div>{title}</div>
      <Button type="link">
        {isActive ? "Hide" : "Show"} <DownOutlined rotate={isActive ? 180 : 0} />
      </Button>
    </div>
  );
}
