import React, { useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  bell03,
  chevronLeft,
  integration,
  signal01,
  user01,
  users01,
} from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { isOnAccountSettingsPage, settingsRoutes } from "../../../lib/routes";
import { Text } from "../../../styles/utility-components";
import NavItem from "./NavItem";
import SubNavItem from "./SubNavItem";
import { EXPANDEDWIDTH, MainNav, SubNavWrapper } from "./VerticalNav.styles";

function SettingsNav() {
  const accountSettingsRef = useRef<HTMLDivElement>(null);

  const [accountSettingsExpanded, setAccountSettingsExpanded] = useState(isOnAccountSettingsPage);

  function handleBack() {
    window.location.href = sessionStorage.getItem("lastMainNavPageVisited") || "/";
  }

  return (
    <Wrapper>
      <TopRow>
        <Button onClick={handleBack}>
          <UIcon svg={chevronLeft} size={16} color={"inherit"} />
        </Button>
        <Text h2>Settings</Text>
      </TopRow>
      <MainNav>
        <NavItem title="Signal settings" icon={signal01} path={settingsRoutes.signalSettings} />
        <NavItem
          title="Account settings"
          icon={user01}
          onClick={() => {
            setAccountSettingsExpanded(!accountSettingsExpanded);
          }}
          isSubNavExpanded={accountSettingsExpanded}
        >
          <SubNavWrapper
            ref={accountSettingsRef}
            height={accountSettingsRef.current?.scrollHeight || "100%"}
            subNavExpanded={accountSettingsExpanded}
          >
            <SubNavItem title="Subscription" path={settingsRoutes.subscription} />
            <SubNavItem title="Display settings" path={settingsRoutes.displaySettings} />
            <SubNavItem title="Change password" path={settingsRoutes.changePassword} />
          </SubNavWrapper>
        </NavItem>
        <NavItem title="Notifications" icon={bell03} path={settingsRoutes.notifications} />
        <NavItem title="My team" icon={users01} path={settingsRoutes.team} />
        <NavItem title="Integrations" icon={integration} path={settingsRoutes.integrations} />
      </MainNav>
    </Wrapper>
  );
}

export default SettingsNav;

const Wrapper = styled.div(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  backgroundColor: theme.colors.white,
  zIndex: 4,
  width: EXPANDEDWIDTH,
  padding: 24,
  borderRight: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const TopRow = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

const Button = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: 8,
  borderRadius: 4,
  color: theme.colors.sysTextDefault,
  cursor: "pointer",
  transition: "all 0.3s",

  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    color: theme.colors.sysPrimaryHover,
  },
}));
