import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { BuyerListDetails } from "lib/generated/app-api";
import { CreateBuyerListRequest } from "lib/generated/app-api/models/CreateBuyerListRequest";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

export type BuyerContextSource =
  | "In-row"
  | "Bulk action"
  | "buyer-profile"
  | "buyer lists page"
  | "supplier-profile"
  | "Notice preview";

export function useCreateBuyerList({
  contextSource = "In-row",
  options,
}: {
  contextSource?: BuyerContextSource;
  options?: UseMutationOptions<BuyerListDetails, unknown, CreateBuyerListRequest, unknown>;
}) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  return useMutation(
    (body: CreateBuyerListRequest) => api.createBuyerList({ createBuyerListRequest: body }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["buyerLists"]);
        void queryClient.invalidateQueries(["supplierStats"]);
        void queryClient.invalidateQueries(["buyers", "buyersForSupplier"]);
        logEvent(EventNames.buyerListCreated, {
          "List id": data.id,
          "List name": variables.name,
          "Permission type": variables.sharingType,
          "Context source": contextSource,
        });
        if (options?.onSuccess) {
          options?.onSuccess(data, variables, context);
        }
      },
    },
  );
}
