import * as React from "react";

/**
 * The keys of the UTMParams get prepended
 * with `utm_` by the provider
 * They should be the names of the tracking tags after utm
 */
export type UTMParams = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
  [tag: string]: string | undefined;
};
type UTMParamsProps = { utmParams: UTMParams };

function convertToUTM(params: UTMParams): UTMParams {
  const utmParams: UTMParams = {};
  for (const [key, value] of Object.entries(params)) {
    if (!value) continue;
    utmParams[`utm_${key}`] = value;
  }
  return utmParams;
}

function UTMParamsProvider(props: React.PropsWithChildren<UTMParamsProps>): JSX.Element {
  const { children, utmParams } = props;
  const contextData = React.useMemo(() => {
    return { utmParams: convertToUTM(utmParams) };
  }, [utmParams]);

  return <UTMParamsContext.Provider value={contextData}>{children}</UTMParamsContext.Provider>;
}

const UTMParamsContext = React.createContext<React.PropsWithChildren<UTMParamsProps> | undefined>(
  undefined,
);

export function useUTMParams(): UTMParamsProps | undefined {
  return React.useContext(UTMParamsContext);
}

export default UTMParamsProvider;
