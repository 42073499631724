import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  KeywordsRecommendationQuery,
  KeywordsRecommendationQueryVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const keywordsRecommendation = graphql(`
  query keywordsRecommendation($args: GenerateKeywordsRecommendationRequest!) {
    keywordsRecommendation(GenerateKeywordsRecommendationRequest: $args) {
      keywords
    }
  }
`);

export function useKeywordsRecommendation(
  args: KeywordsRecommendationQueryVariables["args"],
  options?: UseQueryOptions<
    KeywordsRecommendationQuery,
    unknown,
    KeywordsRecommendationQuery,
    QueryKey
  >,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["keywordsRecommendation", args],
    keywordsRecommendation,
    [{ args }],
    {
      ...options,
    },
  );

  return { data: data?.keywordsRecommendation, ...rest };
}
