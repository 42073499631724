import React from "react";
import { Popover, Spin } from "antd5";
import classNames from "classnames";
import { Link, Redirect } from "wouter";

import TextButton from "components/actions/TextButton";
import { DetailsPage } from "components/app_layout/DetailsLayout";
import SignalsContainer from "components/tags/SignalsContainer";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { IN_ROW_STAT_LINK, InRowStatText } from "lib/core_components/Table/Table";
import { Signal } from "lib/generated/app-api";
import { SearchRecordsParams } from "lib/StotlesApi";
import { EventNames, useTracking } from "lib/tracking";
import { joinToEnglishList } from "lib/utils";
import NoticesTable from "./NoticesTable";
import {
  FilterParams,
  filterParamsToSearchParams,
  filterParamsToUrlParams,
  urlParamsToFilterParams,
} from "./routingUtils";
import { ProfileType } from "./types";

import tagHelperCss from "../../styles/tag_helpers.module.scss";
import css from "./RecordViewerOverlay.module.scss";

export const NOTICE_LABELS_BY_TYPE = {
  all_activity: "All activity",
  upcoming_expiries: "Upcoming expiries",
} as const;

type Props = {
  params: FilterParams;
  noticeType: keyof typeof NOTICE_LABELS_BY_TYPE;
  signalName: string;
  signalCategory: ProfileType;
};

/*
 * The smart wrapper which extracts params from the url and renders the right viewer. Also handles
 * checking that the params are valid and suitable for rendering the viewer
 */
export function WrappedRecordViewer({
  noticeType,
  signalName,
  signalCategory,
}: Omit<Props, "params">) {
  const params = React.useMemo(() => {
    return urlParamsToFilterParams();
  }, []);

  // Lightweight validation that we have enough to render the viewer; otherwise just go to the main
  // signal page
  if (params && noticeType in NOTICE_LABELS_BY_TYPE) {
    return (
      <DetailsPage className={css.layout}>
        <RecordViewer
          params={params}
          noticeType={noticeType as keyof typeof NOTICE_LABELS_BY_TYPE}
          signalCategory={signalCategory}
          signalName={signalName}
        />
      </DetailsPage>
    );
  } else {
    return <Redirect to="/" />;
  }
}

function RecordViewer({ params, noticeType, signalCategory, signalName }: Props) {
  const [count, setCount] = React.useState<number | undefined>(undefined);

  const filtersApplied = React.useMemo(() => {
    const spans = [];

    spans.push(
      <span>
        <span
          className={classNames(
            signalCategory === ProfileType.PARTNER ? "tag-yellow" : "tag-red",
            tagHelperCss.nonTagCellTag,
          )}
        >
          {signalName}
        </span>{" "}
        is mentioned or awarded
      </span>,
    );

    if (params.buyer) {
      spans.push(
        <span>
          {" "}
          <RedactedWrapper redactContent={params.buyer.name} requiredDataType="SUPPLIERS">
            <strong>{params.buyer.name}</strong> is the buyer
          </RedactedWrapper>
        </span>,
      );
    }

    if (params.keywords?.length) {
      if (params.keywords.includes("__SELECT_ALL__")) {
        spans.push(
          <span>
            <span className={classNames("tag-blue", tagHelperCss.nonTagCellTag)}>any keyword</span>{" "}
            is mentioned
          </span>,
        );
      } else if (params.keywords.length > 1) {
        const popoverSignals: Signal[] = params.keywords.map((k) => ({
          name: k,
          category: "Keywords",
        }));
        spans.push(
          <span>
            one of{" "}
            <Popover
              placement="rightTop"
              content={<SignalsContainer signals={popoverSignals} vertical />}
            >
              <span className={classNames("tag-blue", tagHelperCss.nonTagCellTag)}>
                keyword ({params.keywords.length})
              </span>{" "}
            </Popover>
            is mentioned
          </span>,
        );
      } else {
        spans.push(
          <span>
            {" "}
            <span className={classNames("tag-blue", tagHelperCss.nonTagCellTag)}>
              {params.keywords[0]}
            </span>{" "}
            is mentioned
          </span>,
        );
      }
    }

    return spans;
  }, [params, signalCategory, signalName]);

  const filters = React.useMemo(() => {
    return filterParamsToSearchParams(params);
  }, [params]);

  return (
    <div className={css.overlay}>
      <TextButton onClick={() => window.history.back()}>Back to {signalName}</TextButton>
      <h1>
        {NOTICE_LABELS_BY_TYPE[noticeType]} {count === undefined ? <Spin /> : `(${count})`}
      </h1>
      <p>
        where{" "}
        {filtersApplied.map((s, i) => (
          <React.Fragment key={i}>
            {s}
            {filtersApplied.length > 2 && i < filtersApplied.length - 1 && ", "}
            {filtersApplied.length > 1 && i === filtersApplied.length - 2 && " and "}
          </React.Fragment>
        ))}
        .
      </p>
      <NoticesTable
        filters={filters as Partial<SearchRecordsParams>}
        columnNames={["title", "expiryDate", "value", "publishDate", "signals"]}
        defaultSort={{ field: "name", order: "ASC" }}
        onRecordLoad={(apiResp) => setCount(apiResp.paging_info.total_results)}
      />
    </div>
  );
}

type BtnProps = {
  count: number;
  params: FilterParams;
  noticeType: keyof typeof NOTICE_LABELS_BY_TYPE;
};

function extractSignalTypeTracking(params: FilterParams) {
  const signalStrings = [];

  if (params.competitor) {
    signalStrings.push(`Competitors: ${params.competitor.name}`);
  }
  if (params.partner) {
    signalStrings.push(`Partners: ${params.partner.name}`);
  }

  if (params.keywords && params.keywords.length > 0) {
    const keywords =
      params.keywords[0] === "__SELECT_ALL__" ? "All" : joinToEnglishList(params.keywords);
    signalStrings.push(`Keywords: ${keywords}`);
  }

  return signalStrings.length === 0 ? "None" : signalStrings.join(", ");
}

export function OpenRecordViewerOverlayBtn({ count, params, noticeType }: BtnProps) {
  const { logEvent } = useTracking();
  const handleClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      const signalType = extractSignalTypeTracking(params);

      logEvent(EventNames.recordDrilldownOpened, {
        "Highlight type": NOTICE_LABELS_BY_TYPE[noticeType],
        "Signal type": signalType,
      });
    },
    [params, noticeType, logEvent],
  );
  const dest = React.useMemo(() => {
    const pms = filterParamsToUrlParams(params);

    return `/procurement_notices/${noticeType}?${pms.toString()}`;
  }, [params, noticeType]);

  return count > 0 ? (
    <Link href={dest} onClick={handleClick} className={IN_ROW_STAT_LINK}>
      {count}
    </Link>
  ) : (
    <InRowStatText>0</InRowStatText>
  );
}
