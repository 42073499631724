import * as React from "react";
import { Button, Checkbox, message } from "antd5";

import { BetaIcon } from "lib/core_components/Labels";
import { useStotlesApi } from "lib/stotlesApiContext";
import SettingsContentContainer from "./SettingsContentContainer";

import css from "./DisplaySettings.module.scss";

function DisplaySettings(): JSX.Element {
  const [showBuyerCategories, setShowBuyerCategories] = React.useState(
    window.currentUser?.show_buyer_categories,
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const api = useStotlesApi();

  React.useEffect(() => {
    const loadSettings = async () => {
      try {
        const { show_buyer_categories } = await api.getDisplaySettings();
        setShowBuyerCategories(show_buyer_categories);
      } catch (e) {
        void message.error(
          "Failed to get display settings, please contact an admin if this issue persists.",
        );
      }
    };
    void loadSettings();
  }, [api]);

  const handleUpdateDisplaySettings = React.useCallback(() => {
    try {
      void api.updateDisplaySettings({ show_buyer_categories: showBuyerCategories });
      void message.success("Successfully updated display settings");
      setIsDirty(false);
    } catch (e) {
      void message.error(
        "Failed to update display settings, please contact an admin if this issue persists.",
      );
    }
  }, [api, showBuyerCategories]);

  return (
    <SettingsContentContainer
      title={
        <>
          Display settings
          <BetaIcon />
        </>
      }
    >
      <div className={css.displaySettings}>
        <Checkbox
          checked={showBuyerCategories}
          onChange={(e) => {
            setShowBuyerCategories(e.target.checked);
            setIsDirty(true);
          }}
        >
          Show buyer types in tables
        </Checkbox>
        <Button
          type="primary"
          className={css.saveButton}
          disabled={!isDirty}
          onClick={handleUpdateDisplaySettings}
        >
          Save
        </Button>
      </div>
    </SettingsContentContainer>
  );
}

export default DisplaySettings;
