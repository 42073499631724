import * as React from "react";
import { List, Popover } from "antd5";

import css from "./MultipleLinksDisplay.module.scss";

type LinksDisplayProps = {
  links: JSX.Element[];
};
function LinksDisplay({ links }: LinksDisplayProps) {
  if (links.length === 0) return null;
  if (links.length === 1) return links[0];
  else {
    return (
      <>
        {links[0]} &amp;{" "}
        <Popover
          trigger="hover"
          placement="bottomLeft"
          content={
            <List className={css.cellPopoverList}>
              {links.map((s) => (
                <List.Item>{s}</List.Item>
              ))}
            </List>
          }
        >
          <p className={css.popoverTrigger}>{links.slice(1).length} more</p>
        </Popover>{" "}
      </>
    );
  }
}

export default LinksDisplay;
