import * as React from "react";

// source: https://fontawesome.com/icons/share
// licensed under CC BY 4.0 https://creativecommons.org/licenses/by/4.0/
// modification: outline only (hollow)

/* USAGE:
 *
 * import { Icon } from "antd5";
 * ...
 * <Icon component={ShareIcon} />
 *
 **/

export default function Comment(): JSX.Element {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1384_249)">
        <path
          d="M7.95336 5.57809C7.59242 5.57809 7.31273 5.85778 7.31273 6.20309C7.31273 6.5484 7.59242 6.82809 7.95336 6.82809C8.28304 6.82809 8.56273 6.5484 8.56273 6.20309C8.56273 5.85778 8.28304 5.57809 7.95336 5.57809ZM3.57836 5.57809C3.21742 5.57809 2.93773 5.85778 2.93773 6.20309C2.93773 6.5484 3.21742 6.82809 3.57836 6.82809C3.90804 6.82809 4.18773 6.5484 4.18773 6.20309C4.18773 5.85778 3.90804 5.57809 3.57836 5.57809Z"
          fill="#565656"
        />
        <path
          d="M12.9685 4.38937C12.2456 3.39428 11.2032 2.67729 10.0154 2.35812V2.35968C9.74823 2.06281 9.44667 1.78937 9.10917 1.54562C6.55135 -0.313754 2.96073 0.253433 1.09354 2.81125C-0.411146 4.88937 -0.347083 7.68468 1.18729 9.67062L1.19979 11.7425C1.19979 11.7925 1.2076 11.8425 1.22323 11.8894C1.24288 11.952 1.27469 12.0101 1.31683 12.0604C1.35896 12.1107 1.41061 12.1522 1.4688 12.1826C1.527 12.2129 1.5906 12.2315 1.65597 12.2372C1.72135 12.2429 1.78721 12.2357 1.84979 12.2159L3.82792 11.5925C4.35135 11.7784 4.89198 11.8847 5.42948 11.9144L5.42167 11.9206C6.81385 12.9347 8.63885 13.2394 10.3123 12.6862L12.2982 13.3331C12.3482 13.3487 12.3998 13.3581 12.4529 13.3581C12.7295 13.3581 12.9529 13.1347 12.9529 12.8581V10.7644C14.3295 8.89562 14.3654 6.31281 12.9685 4.38937ZM4.04667 10.4831L3.85917 10.405L2.31229 10.8894L2.29667 9.26437L2.17167 9.12375C0.849791 7.51125 0.762292 5.18781 1.99979 3.48312C3.50604 1.4175 6.39354 0.961246 8.45292 2.45187C10.5185 3.95343 10.9764 6.83625 9.48417 8.88937C8.2326 10.6066 5.99198 11.2409 4.04667 10.4831ZM11.9373 10.2175L11.8123 10.3737L11.8279 11.9987L10.2967 11.4831L10.1092 11.5612C9.23417 11.8862 8.30135 11.9128 7.43729 11.6706L7.43417 11.6691C8.62172 11.3049 9.65906 10.5652 10.3904 9.56125C11.5842 7.91593 11.7779 5.84875 11.0842 4.08625L11.0935 4.0925C11.4529 4.35031 11.7826 4.67218 12.0623 5.06125C13.1967 6.6175 13.1326 8.73625 11.9373 10.2175Z"
          fill="#565656"
        />
        <path
          d="M5.76586 5.57809C5.40492 5.57809 5.12523 5.85778 5.12523 6.20309C5.12523 6.5484 5.40492 6.82809 5.76586 6.82809C6.09554 6.82809 6.37523 6.5484 6.37523 6.20309C6.37523 5.85778 6.09554 5.57809 5.76586 5.57809Z"
          fill="#565656"
        />
      </g>
      <defs>
        <clipPath id="clip0_1384_249">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
