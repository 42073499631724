import * as React from "react";
import { Button, Tooltip } from "antd5";
import classNames from "classnames";

import css from "./BigCheckButton.module.scss";

export type Props = {
  label: string | JSX.Element;
  value: string;
  icon?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  id?: string | undefined;
  disabled?: boolean;
  className?: string;
  iconPosition?: "left" | "right";
  tooltip?: string;
};

export default function BigCheckButton({
  label,
  value,
  icon,
  onChange,
  checked,
  id,
  disabled,
  className,
  iconPosition = "left",
  tooltip,
}: Props): JSX.Element {
  return (
    <div id={id} className={classNames(css.container, className)}>
      <Tooltip title={tooltip} placement="topLeft">
        <Button
          role="checkbox"
          id={value}
          className={css.checkboxButton}
          onClick={() => onChange && onChange(!checked)}
          type={checked ? "primary" : undefined}
          disabled={disabled}
          size="large"
        >
          {icon && iconPosition === "left" && <span className={css.icon}>{icon}</span>}
          <span>{label}</span>
          {icon && iconPosition === "right" && <span className={css.icon}>{icon}</span>}
        </Button>
      </Tooltip>
    </div>
  );
}
