import React from "react";
import { Modal } from "antd5";

import StyledListWrapper from "../../lib/core_components/StyledListWrapper";
import { TextLink } from "../actions/Links";
import { HELP_EDITING_YOUR_FEED_URL } from "../onboarding/SharedOnboarding";

import css from "./TextSearchTipsModal.module.scss";

type Props = {
  onClose(): void;
  isOpen: boolean;
};

export function TextSearchTipsModal({ onClose, isOpen }: Props): React.ReactElement {
  return (
    <Modal
      onCancel={() => onClose()}
      footer={null}
      title="Searching for keywords"
      open={isOpen}
      width={720}
    >
      <div>
        <h2 className={css.tipsModalSubtitle}>Advanced keyword search options</h2>
        <em>
          See{" "}
          <TextLink to={HELP_EDITING_YOUR_FEED_URL} targetType="external" showIcon={false}>
            <b>our tips</b>
          </TextLink>{" "}
          on how to create a feed that is hyper-relevant to what you are looking for.
        </em>
      </div>
      <StyledListWrapper>
        <div className={css.tipsSection}>
          <p>A normal keyword entry will include other words that share the same root word.</p>
          <ul>
            <li>
              E.g., <em>consulting</em> will match <em>consultant</em> and <em>consultation</em>
            </li>
            <li>
              E.g., <em>facility management</em> will match <em>facilities management</em>
            </li>
          </ul>
        </div>
        <div className={css.tipsSection}>
          <p>To search for an exact match, use quotations. This is not case sensitive.</p>
          <ul>
            <li>
              E.g., <em>"consulting"</em> will <u>not</u> match <em>consultant</em> or{" "}
              <em>consultation</em>
            </li>
            <li>
              E.g., <em>"facility management"</em> will <u>not</u> match{" "}
              <em>facilities management</em>
            </li>
            <li>
              E.g., <em>"app"</em> will <u>not</u> match <em>application</em>. The match considers
              the whole word
            </li>
          </ul>
        </div>
        <div className={css.tipsSection}>
          <p>
            To better identify acronyms, keywords typed in ALL CAPS will only match keywords that
            are also fully capitalised. Any other capitalisation will search with no case
            sensitivity.
          </p>
          <ul>
            <li>
              E.g., <em>CHECK</em> (the cyber security scheme) will <u>not</u> match <em>check</em>{" "}
              (the verb)
            </li>
            <li>
              E.g., <em>SAAS</em> will <u>not</u> match <em>SaaS</em>, but <em>saas</em> will
            </li>
          </ul>
        </div>
        <div className={css.tipsSection}>
          <p>To narrow down certain keyword results, combine them with the AND operator.</p>
          <ul>
            <li>
              E.g., <em>software AND design</em> would find more relevant results than if you typed{" "}
              <em>software</em> and <em>design</em> separately
            </li>
          </ul>
        </div>
      </StyledListWrapper>
    </Modal>
  );
}
