import * as React from "react";

import { blue50, blue600 } from "lib/themes/colors";

type Props = {
  size?: number;
  background?: string;
  fill?: string;
  className?: string;
};

export default function FrameworkIcon({
  size = 48,
  background = blue50,
  fill = blue600,
  className,
}: Props): JSX.Element {
  return (
    <svg
      className={className ?? undefined}
      width={size}
      height={size}
      viewBox={`0 0 48 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20_64858)">
        <rect width={48} height={48} rx={48 / 8} fill={background} />
        <path
          d="M20.4 15H16.6C16.0399 15 15.7599 15 15.546 15.109C15.3578 15.2049 15.2049 15.3578 15.109 15.546C15 15.7599 15 16.0399 15 16.6V20.4C15 20.9601 15 21.2401 15.109 21.454C15.2049 21.6422 15.3578 21.7951 15.546 21.891C15.7599 22 16.0399 22 16.6 22H20.4C20.9601 22 21.2401 22 21.454 21.891C21.6422 21.7951 21.7951 21.6422 21.891 21.454C22 21.2401 22 20.9601 22 20.4V16.6C22 16.0399 22 15.7599 21.891 15.546C21.7951 15.3578 21.6422 15.2049 21.454 15.109C21.2401 15 20.9601 15 20.4 15Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4 15H27.6C27.0399 15 26.7599 15 26.546 15.109C26.3578 15.2049 26.2049 15.3578 26.109 15.546C26 15.7599 26 16.0399 26 16.6V20.4C26 20.9601 26 21.2401 26.109 21.454C26.2049 21.6422 26.3578 21.7951 26.546 21.891C26.7599 22 27.0399 22 27.6 22H31.4C31.9601 22 32.2401 22 32.454 21.891C32.6422 21.7951 32.7951 21.6422 32.891 21.454C33 21.2401 33 20.9601 33 20.4V16.6C33 16.0399 33 15.7599 32.891 15.546C32.7951 15.3578 32.6422 15.2049 32.454 15.109C32.2401 15 31.9601 15 31.4 15Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4 26H27.6C27.0399 26 26.7599 26 26.546 26.109C26.3578 26.2049 26.2049 26.3578 26.109 26.546C26 26.7599 26 27.0399 26 27.6V31.4C26 31.9601 26 32.2401 26.109 32.454C26.2049 32.6422 26.3578 32.7951 26.546 32.891C26.7599 33 27.0399 33 27.6 33H31.4C31.9601 33 32.2401 33 32.454 32.891C32.6422 32.7951 32.7951 32.6422 32.891 32.454C33 32.2401 33 31.9601 33 31.4V27.6C33 27.0399 33 26.7599 32.891 26.546C32.7951 26.3578 32.6422 26.2049 32.454 26.109C32.2401 26 31.9601 26 31.4 26Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4 26H16.6C16.0399 26 15.7599 26 15.546 26.109C15.3578 26.2049 15.2049 26.3578 15.109 26.546C15 26.7599 15 27.0399 15 27.6V31.4C15 31.9601 15 32.2401 15.109 32.454C15.2049 32.6422 15.3578 32.7951 15.546 32.891C15.7599 33 16.0399 33 16.6 33H20.4C20.9601 33 21.2401 33 21.454 32.891C21.6422 32.7951 21.7951 32.6422 21.891 32.454C22 32.2401 22 31.9601 22 31.4V27.6C22 27.0399 22 26.7599 21.891 26.546C21.7951 26.3578 21.6422 26.2049 21.454 26.109C21.2401 26 20.9601 26 20.4 26Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_20_64858">
          <rect width={48} height={48} fill={background} />
        </clipPath>
      </defs>
    </svg>
  );
}
