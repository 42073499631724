/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsRequestOrganisation
 */
export interface SearchFrameworkOrganisationsRequestOrganisation {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFrameworkOrganisationsRequestOrganisation
     */
    id: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFrameworkOrganisationsRequestOrganisation
     */
    roles: Array<SearchFrameworkOrganisationsRequestOrganisationRolesEnum>;
}


/**
 * @export
 */
export const SearchFrameworkOrganisationsRequestOrganisationRolesEnum = {
    TransactingBuyer: 'TransactingBuyer',
    ContractingAuthority: 'ContractingAuthority',
    Provider: 'Provider',
    Supplier: 'Supplier'
} as const;
export type SearchFrameworkOrganisationsRequestOrganisationRolesEnum = typeof SearchFrameworkOrganisationsRequestOrganisationRolesEnum[keyof typeof SearchFrameworkOrganisationsRequestOrganisationRolesEnum];


/**
 * Check if a given object implements the SearchFrameworkOrganisationsRequestOrganisation interface.
 */
export function instanceOfSearchFrameworkOrganisationsRequestOrganisation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsRequestOrganisationFromJSON(json: any): SearchFrameworkOrganisationsRequestOrganisation {
    return SearchFrameworkOrganisationsRequestOrganisationFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsRequestOrganisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsRequestOrganisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'roles': json['roles'],
    };
}

export function SearchFrameworkOrganisationsRequestOrganisationToJSON(value?: SearchFrameworkOrganisationsRequestOrganisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'roles': value.roles,
    };
}

