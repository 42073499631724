import React from "react";
import { App, Modal } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useGetLeadSubscriptionContents } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptionContents";
import { assertDefined } from "lib/utils";
import { useUpdateView } from "../../../lib/hooks/api/notices/views/useUpdateView";
import { getAlert } from "../alerts";
import { SavedViewForm } from "./SavedViewForm";

import css from "./EditViewModal.module.scss";

type EditViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedView: SavedViewType;
};

export function EditViewModal({ isOpen, onClose, selectedView }: EditViewModalProps) {
  assertDefined(selectedView.id);

  const { message } = App.useApp();

  const { mutate: mutateView, isLoading } = useUpdateView("Edit view modal", {
    onSuccess: async (_data, vars) => {
      onClose();
      void message.success(`${vars.view.name} saved`);
    },
    onError: async () => {
      onClose();
      void message.error("Failed to save view");
    },
  });

  const { data } = useGetLeadSubscriptionContents();
  const personalSavedViewAlert = getAlert(data?.personalSubscriptions ?? [], selectedView.id);

  return (
    <Modal
      className={css.modal}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={`Save "${selectedView.name}"`}
    >
      <p className={css.modalDescription}>
        Quickly access your most commonly used filters at anytime by creating filtered views.
      </p>
      <SavedViewForm
        defaultValues={{
          viewName: selectedView.name,
          viewDescription: selectedView.description ? selectedView.description : "",
          alertFrequency: personalSavedViewAlert?.id ? personalSavedViewAlert.frequency : "NEVER",
        }}
        isStandardView={selectedView.isStandardView}
        onSubmit={(data) =>
          mutateView({
            view: {
              ...selectedView,
              name: data.viewName,
              description: data.viewDescription,
            },
            alertId: personalSavedViewAlert?.id,
            alertFrequency: data.alertFrequency,
          })
        }
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Modal>
  );
}
