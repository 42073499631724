import React, { useCallback, useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { App, Button, Input } from "antd5"; // check action items css and remove typography, upgrade to new context message

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { useStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { Text } from "../../styles/utility-components";

import css from "./EmailVerificationPage.module.scss";

type Props = {
  status?: string;
};
function EmailVerificationPage({ status }: Props) {
  const { message } = App.useApp();
  const [showRequestEmail, setShowRequestEmail] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const api = useStotlesApi();
  const userName = window.currentUser?.first_name;
  const email = window.currentUser?.email;

  useEffect(() => {
    if (timerSeconds === 0) return;
    setTimeout(() => {
      setTimerSeconds((oldTimerSeconds) => oldTimerSeconds - 1);
    }, 1000);
  }, [timerSeconds]);

  useEffect(() => {
    if (status && status === "confirmation_failure") {
      message.error(
        <span>
          Our system hasn't verified your email yet, please double check and reach out to{" "}
          <TextLink className={css.stotlesEmailLink} to="mailto:team@stotles.com">
            team@stotles.com
          </TextLink>{" "}
          if issues persist
        </span>,
        3,
      );
    }
  }, [message, status]);

  const handleSendVerificationEmail = useCallback(async () => {
    let resp;
    try {
      resp = await api.sendVerificationEmailRequest();
      tracking.logEvent(tracking.EventNames.clickedReSendEmail, {
        "Page source": "Onboarding - Verify email",
      });
      message.success(resp.message);
    } catch (_) {
      if (resp) {
        message.error(resp.message);
      }
    }
    setTimerSeconds(60);
  }, [api, message]);

  const didNotGetEmailAction = useCallback(() => {
    setShowRequestEmail(true);
    tracking.logEvent(tracking.EventNames.clickedNoEmailReceived, {
      "Page source": "Onboarding - Verify email",
    });
  }, [setShowRequestEmail]);

  return (
    <div className={css.emailVerificationPage}>
      <div className={css.innerWrapper}>
        <h1 className={css.primaryHeading}>Hi, {userName}!</h1>
        <h2 className={css.secondaryHeading}>You're almost there</h2>
        <Text>
          Thanks for signing up with Stotles! We've sent you an email to verify your account, so
          please check your inbox (or spam folder) to get started.
        </Text>
        <div className={css.actionItemsContainer}>
          <TextLink
            to="/email-confirmed"
            className={css.actionItems}
            eventName={tracking.EventNames.clickedAlreadyVerified}
          >
            Already verified?
          </TextLink>
          <TextButton className={css.actionItems} onClick={didNotGetEmailAction}>
            Didn't get an email?
          </TextButton>
        </div>
        {showRequestEmail && (
          <div className={css.requestEmailContainer}>
            <Text>Double-check your email address below and request another email to be sent</Text>
            <div className={css.requestInputForm}>
              <Input
                placeholder="Email"
                type="email"
                disabled
                value={email}
                className={css.requestEmailInput}
              />
              <Button
                className={css.requestEmailButton}
                type="primary"
                disabled={timerSeconds > 0}
                onClick={handleSendVerificationEmail}
              >
                {timerSeconds > 0 ? `Wait ${timerSeconds} seconds...` : "Re-send email"}
              </Button>
            </div>
            <small>
              If you're still having trouble or the email is incorrect, get in touch at{" "}
              <TextLink className={css.stotlesEmailLink} to="mailto:team@stotles.com">
                team@stotles.com
              </TextLink>
            </small>
          </div>
        )}
      </div>
    </div>
  );
}

export default withAppLayout(hot(EmailVerificationPage), {
  hideMenuItems: true,
  hideUserItems: true,
  pageName: "Onboarding - Verify email",
});
