import { UseMutationOptions } from "@tanstack/react-query";

import { createFeedbackSubmittedEvent } from "components/buyer_details/question_panel/tracking";
import { graphql } from "lib/generated/app-service-gql/gql";
import {
  ProvideQaFeedbackMutation,
  ProvideQaFeedbackMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useTracking } from "lib/tracking";
import { useGraphQlMutation } from "../useGraphQlClient";

const provideFeedbackQuery = graphql(`
  mutation provideQaFeedback($args: ProvideFeedbackRequest!) {
    provideQaFeedback(ProvideFeedbackRequest: $args) {
      questionId
      userDetailedFeedback
      isPositive
    }
  }
`);

type Tracking = {
  question: string;
  answer: string;
  orgName: string;
  isTrue?: boolean;
  isHelpful?: boolean;
};

export function useProvideFeedback(
  options?: UseMutationOptions<
    ProvideQaFeedbackMutation,
    unknown,
    ProvideQaFeedbackMutationVariables & Tracking,
    unknown
  >,
) {
  const { logEvents } = useTracking();
  const { data, ...rest } = useGraphQlMutation(provideFeedbackQuery, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      logEvents(
        createFeedbackSubmittedEvent(
          data.provideQaFeedback.isPositive,
          vars.question,
          vars.answer,
          vars.orgName,
          data.provideQaFeedback.userDetailedFeedback
            ? data.provideQaFeedback.userDetailedFeedback
            : undefined,
          vars.isTrue,
          vars.isHelpful,
        ),
      );
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.provideQaFeedback, ...rest };
}
