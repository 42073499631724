import { Key } from "react";
import { ColumnType as AntColumnType } from "antd5/es/table";

import css from "./ColumnTypes.module.scss";

type TableSize =
  | "small" // <1280px, optimised for ~800px
  | "medium" // <1440px, optimised for ~1280px
  | "large" //  <1920px, optimised for ~1440px
  | "xlarge"; // >=1920px, optimised for ~1920px

// Column size for each table size
export type ColumnSizeConfig = Partial<Record<TableSize, number>>;

export type ColumnType<E> = AntColumnType<E> & {
  key: Key;
  sizeConfig?: ColumnSizeConfig;
  dataIndex?: keyof E;
};

const nonClickableCell = (_entry: unknown, _index: unknown): React.HTMLAttributes<HTMLElement> => {
  // by default, rows in the inbox are clickable (see this._onRow). If the cell has an action in it,
  // eg. tag cell / action buttons, it is a good idea to not make it clickable, to avoid user frustration
  // if they miss what they wanted to click on. This also makes code simpler, by not having to interfere
  //  with lower level components
  return {
    onClick: ((e) => e.stopPropagation()) as React.ReactEventHandler<HTMLElement>,
  };
};

export const commonTableColumns = {
  dateColumn: {
    sizeConfig: {
      // 120 gives slightly nicer padding
      xlarge: 120,
      large: 120,
      medium: 110,
      small: 110,
    },
    ellipsis: true,
  },
  actionColumn: {
    sizeConfig: {
      xlarge: 48,
      large: 48,
      medium: 48,
      small: 48,
    },
    className: css.actionColumn,
    fixed: "right" as const,
    onCell: nonClickableCell,
  },
  expandableColumn: {
    ellipsis: true,
    width: 500,
  },
  tagColumn: {
    filterMultiple: true,
    ellipsis: true,
    sizeConfig: {
      xlarge: 300,
      large: 200,
      medium: 200,
      small: 200,
    },
    onCell: nonClickableCell,
    className: css.tagColumn,
  },
  tagColumnNarrow: {
    filterMultiple: true,
    ellipsis: true,
    sizeConfig: {
      xlarge: 250,
      large: 150,
      medium: 150,
      small: 150,
    },
    onCell: nonClickableCell,
    className: css.tagColumn,
  },
  titleColumn: {
    sizeConfig: {
      xlarge: 650,
      large: 400,
      medium: 320,
      small: 320,
    },
    ellipsis: true,
  },
  buyerColumn: {
    sizeConfig: {
      xlarge: 300,
      large: 300,
      medium: 200,
      small: 150,
    },
    ellipsis: true,
  },
  valueColumn: {
    ellipsis: true,
    align: "right" as const,
    sizeConfig: {
      xlarge: 150,
      large: 150,
      medium: 130,
      small: 100,
    },
    className: css.valueColumn,
  },
  stageColumn: {
    ellipsis: true,
    sizeConfig: {
      xlarge: 145,
      large: 130,
      medium: 130,
      small: 100,
    },
    className: css.stageColumn,
  },
  countryColumn: {
    width: 168,
    ellipsis: true,
  },
  supplierColumn: {
    sizeConfig: {
      xlarge: 300,
      large: 150,
      medium: 130,
      small: 130,
    },
  },
  isSMEColumn: {
    width: 150,
  },
  signalsColumn: {
    sizeConfig: {
      xlarge: 250,
      large: 190,
      medium: 100,
      small: 100,
    },
  },
  relevanceColumn: {
    sizeConfig: {
      xlarge: 150,
      large: 150,
      medium: 130,
      small: 100,
    },
    className: css.relevanceColumn,
  },
  qualification: {
    sizeConfig: {
      xlarge: 225,
      large: 200,
      medium: 200,
      small: 170,
    },
  },
  assignee: {
    sizeConfig: {
      xlarge: 120,
      large: 100,
      medium: 100,
      small: 100,
    },
  },
};
