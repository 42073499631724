import { SupplierMentionType } from "lib/types/graphQLEnums";
import {
  ALL_COMPETITORS_TOKEN,
  ALL_CPV_CODES_TOKEN,
  ALL_KEYWORDS_TOKEN,
  ALL_PARTNERS_TOKEN,
} from "lib/types/models";

export function supplierMentionTypeToLabel(type?: SupplierMentionType): string {
  switch (type) {
    case SupplierMentionType.MentionedInOnly:
      return "Mentioned in only";
    case SupplierMentionType.DirectlyAwarded:
      return "Directly awarded";
    default:
      return "";
  }
}

export function supplierGuidsFilterToLabel(guids?: string[]): string {
  if (!guids) {
    return "";
  }

  let guidCount = guids.length;

  if (guids.includes(ALL_COMPETITORS_TOKEN)) {
    guidCount--;
  }
  if (guids.includes(ALL_PARTNERS_TOKEN)) {
    guidCount--;
  }

  return `Suppliers (${guidCount})`;
}

export function keywordsFilterToLabel(keywords?: string[]): string {
  if (!keywords) {
    return "";
  }

  const count = keywords.includes(ALL_KEYWORDS_TOKEN) ? keywords.length - 1 : keywords.length;

  return `Keywords (${count})`;
}

export function cpvCodeFilterToLabel(codes?: string[]): string {
  if (!codes) {
    return "";
  }

  const count = codes.includes(ALL_CPV_CODES_TOKEN) ? codes.length - 1 : codes.length;

  return `CPV codes (${count})`;
}
