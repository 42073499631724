import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { KeywordsQuery, KeywordsQueryVariables } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const keywords = graphql(`
  query keywords($args: GenerateKeywordsRequest!) {
    keywords(GenerateKeywordsRequest: $args) {
      companyName
      keywords
    }
  }
`);

export function useKeywords(
  args: KeywordsQueryVariables["args"],
  options?: UseQueryOptions<KeywordsQuery, unknown, KeywordsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["keywords", args], keywords, [{ args }], {
    ...options,
  });
  return { data: data?.keywords, ...rest };
}
