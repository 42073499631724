import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useStotlesApi } from "lib/stotlesApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";

export function useDeleteView(
  options?: UseMutationOptions<unknown, unknown, { id: string; name: string }, unknown>,
) {
  const api = useStotlesApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(async ({ id }) => api.deleteSavedView(id), {
    ...options,
    onSuccess: (data, vars, ctx) => {
      void queryClient.invalidateQueries(["views"]);
      void queryClient.invalidateQueries(["pinnedViews"]);
      logEvent(EventNames.viewDeleted, {
        "Saved view name": vars.name,
        "Saved view id": vars.id,
        "Data type": EventDataTypes.savedView,
      });
      options?.onSuccess?.(data, vars, ctx);
    },
  });
}
