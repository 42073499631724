export const getCompetitorDestination = (signalName: string) =>
  `/competitors/${encodeURIComponent(signalName)}`;

export const getPartnerDestination = (signalName: string) =>
  `/partners/${encodeURIComponent(signalName)}`;

export const getBuyerDestination = (buyerGuid: string) => `/buyers/${buyerGuid}`;

export const getBuyerSupplierRelationshipDestination = (
  supplierName: string,
  prefix = "",
  type: "supplier" | "partner" | "competitor" | "buyer" = "supplier",
) => `${prefix}/${type}/${encodeURI(supplierName)}`;
