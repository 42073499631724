import * as React from "react";
import { hot } from "react-hot-loader/root";
import { message, Progress } from "antd5";
import { isAxiosError } from "axios";

import { withAppLayout } from "components/app_layout/AppLayout";
import { CreateAccountForm, FormData } from "components/onboarding/CreateAccountForm";
import { useOpenApi } from "lib/openApiContext";
import * as tracking from "lib/tracking";
import { customHeader } from "./CreateAccountCustomHeader";

import css from "./CreateAccountPage.module.scss";

import WORKDAY_LOGO from "../../../assets/images/onboarding/workday_logo.svg";

type Props = {
  email: string;
  partner_programme_name: string | null;
  overrides: {
    first_name: string | null;
    last_name: string | null;
    company_name: string | null;
  };
  inviter_id: string | null;
};

function CreateAccountPage(props: Props) {
  const { overrides, email, inviter_id, partner_programme_name } = props;
  const api = useOpenApi();
  const [initialFormData] = React.useState(() => ({
    email,
    firstName: overrides.first_name || "",
    lastName: overrides.last_name || "",
    companyName: overrides.company_name || "",
    jobTitle: "",
    partnerProgrammeName: partner_programme_name,
  }));

  React.useEffect(() => {
    tracking.identifyByEmail(email);
    const payload: Record<string, string> = {
      email: email,
      "first name": initialFormData.firstName,
      "last name": initialFormData.lastName,
      "company name": initialFormData.companyName,
      "Job title": initialFormData.jobTitle,
    };
    if (inviter_id) {
      payload["invitation sent by"] = inviter_id;
    }
    tracking.pageView("Onboarding - User Info Form", payload);

    // This effect is a pageView only effect - ie. its responsibility is purely to track pageView
    // events, and log a payload from when the user first viewed the page. When the formData
    // changes, another pageView event should not be triggered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateAccount = async (formData: FormData) => {
    try {
      await api.createAccount({
        createAccountRequest: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          companyName: formData.companyName,
          jobTitle: formData.jobTitle,
        },
      });
      tracking.logEvent(tracking.EventNames.userAccountCreated, {
        Email: email,
        "First name": formData.firstName,
        "Last name": formData.lastName,
        "Company name": formData.companyName,
        "Job title": formData.jobTitle,
      });
      await tracking.logUserConversion();
      window.location.reload();
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        console.error(e.response?.data);
      }
      void message.warning(
        "It looks like we encountered a problem. Please reach out to team@stotles.com if it persists.",
        5,
      );
      throw e;
    }
  };

  return (
    <div className={css.createAccountContainer}>
      <div className={css.createAccount}>
        <div className={css.leftPane}>
          <div className={css.leftPaneInner}>
            <div className={css.header}>
              <div className={css.progressBar}>
                <Progress percent={25} showInfo={false} />
              </div>
              <div className={css.headerTitle}>
                <h1>Complete account setup</h1>
              </div>
              <p>{`You've registered with ${email}`} </p>
            </div>

            <CreateAccountForm
              email={email}
              readonlyCompany={!!overrides.company_name}
              initialValues={initialFormData}
              onCreateAccount={handleCreateAccount}
            />
          </div>
        </div>
        <div className={css.rightPane}>
          <div className={css.rightImageContainer}>
            <div className={css.rightText}>
              <img src={WORKDAY_LOGO} className={css.workdayLogo} />
              <h1>
                "Stotles has made it really easy for us to identify opportunities and respond more
                proactively than we have done in the past."
              </h1>
              <p className={css.employeeName}>Imran Razzaq</p>
              <p className={css.jobTitle}>Public Sector Sales Director for UK&I</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default hot(
  withAppLayout(CreateAccountPage, {
    pageName: "Onboarding - User Info Form",
    hideMenuItems: true,
    hideUserItems: true,
    disableLogoNav: true,
    displayCustomAction: customHeader(),
  }),
);
