import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd5";

import { useGenerateKeywordsRecommendation } from "lib/hooks/api/keywords/useGenerateKeywordsRecommendation";

import css from "./SuggestedKeywordsOnboarding.module.scss";

type SuggestedKeywordsProps = {
  initialKeywords: string[];
  onClick: (keyword: string) => void;
  existingKeywords: string[];
  dismissedKeywords: string[];
  description?: string;
  forSignalSettings?: boolean;
};

const NUMBER_OF_WANTED_SUGGESTIONS_KEYWORDS = 10;

function SuggestedKeywordsOnboarding({
  initialKeywords,
  onClick,
  description,
  existingKeywords,
  dismissedKeywords,
}: SuggestedKeywordsProps) {
  const [suggestions, setSuggestions] = React.useState<string[]>(initialKeywords);

  const { mutate, isLoading } = useGenerateKeywordsRecommendation({
    onSuccess: (data) => {
      if (suggestions.length === 0) {
        setSuggestions(data.keywordsRecommendations.keywords);
      } else {
        if (data.keywordsRecommendations.keywords.length === 0) return; // API can return an empty array
        const neededSuggestions = NUMBER_OF_WANTED_SUGGESTIONS_KEYWORDS - suggestions.length;
        const newSuggestions = data.keywordsRecommendations.keywords.slice(0, neededSuggestions); // Tries to get wanted number but can be less

        const combinedSuggestions = [...suggestions, ...newSuggestions];
        const uniqueSuggestions = Array.from(new Set(combinedSuggestions));

        setSuggestions(uniqueSuggestions);
      }
    },
  });

  const mappedKeywords = () => {
    return suggestions.map((recommendation: string) => (
      <Button
        className={css.keywordSuggestion}
        key={recommendation}
        onClick={() => {
          setSuggestions(suggestions.filter((keyword) => keyword !== recommendation));
          mutate({
            args: {
              dismissedKeywords,
              existingKeywords,
              // Generating 1 more than needed since OpenAI may return duplicate keywords and/or less than requested
              numberOfKeywords: NUMBER_OF_WANTED_SUGGESTIONS_KEYWORDS - suggestions.length + 1,
              currentlySuggestedKeywords: suggestions,
            },
          });

          onClick(recommendation);
        }}
        disabled={isLoading}
      >
        {recommendation} <PlusOutlined />
      </Button>
    ));
  };

  return (
    <div className={css.suggestedSection}>
      <div className={css.headerRow}>
        <div>
          <h2 className={css.suggestedLabel}>Select from recommended keywords</h2>
          {description && <p className={css.description}>{description}</p>}
        </div>
        <div className={css.refreshSection}>
          <Button
            onClick={() => {
              setSuggestions([]);
              mutate({
                args: {
                  dismissedKeywords,
                  existingKeywords,
                  numberOfKeywords: NUMBER_OF_WANTED_SUGGESTIONS_KEYWORDS,
                  currentlySuggestedKeywords: suggestions,
                },
              });
            }}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className={css.buttonsRow}>
        {mappedKeywords()}
        {isLoading && suggestions.length > 0 && (
          <Skeleton.Button active className={css.loadingButton} />
        )}
        {isLoading && suggestions.length === 0 && (
          <>
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
            <Skeleton.Button active className={css.loadingButton} />
          </>
        )}
      </div>
    </div>
  );
}

export default SuggestedKeywordsOnboarding;
