import React from "react";
import { Button, Select } from "antd5"; // upgrade and verify

import { CompanyDto } from "lib/generated/admin-api/models/CompanyDto";

import css from "./SwitchCompanyRecents.module.scss";

type Props = {
  companies: CompanyDto[];
  onChange: (companyGuid: string) => void;
  clearButton: () => void;
};

function SwitchCompanyRecents({ companies, clearButton, onChange }: Props) {
  return (
    <div>
      <Select
        className={css.select}
        onChange={onChange}
        options={companies.map((com) => ({ label: com.name, value: com.guid }))}
      />

      <Button style={{ display: "block", marginTop: "10px" }} onClick={clearButton}>
        Clear Recents
      </Button>
    </div>
  );
}

export default SwitchCompanyRecents;
