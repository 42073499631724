import React from "react";
import { Spin } from "antd5";

import css from "./PageSpinner.module.scss";

export function PageSpinner() {
  return (
    <div className={css.spinnerContianer}>
      <Spin />
    </div>
  );
}
