import React from "react";
import { useForm } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { Select, Tag } from "antd5";
import classnames from "classnames";

import { InfoButton } from "components/actions/InfoButton";
import { MatchCountMap } from "components/onboarding/SharedOnboarding";
import { signalCompetitor, signalPartner } from "../../lib/themes/colors";
import { SupplierSelect } from "./SupplierSelect";

import css from "components/form_components/SupplierTagMultiselect.module.scss"; // ant-5 change to relative path and use classnames object? lint fails here otherwise chack with michael if unsure

type Props = {
  countries?: string[];
  id?: string;
  supplierCounts?: MatchCountMap;
  supplierType: "partner" | "competitor";
  supplierNames: string[] | undefined;
  onSupplierNamesChange: (newNames: string[]) => void;
  selectionType: "NAME" | "ID"; // @deprecated - use supplierNames
  supplierIds: number[] | undefined; // @deprecated - use supplierNames
  onSupplierIdsChange: (newIds: number[]) => void; // @deprecated - use supplierNames
};

function SupplierTagMultiselect({
  supplierCounts,
  supplierType,
  selectionType,
  supplierNames,
  onSupplierNamesChange,
  ...rest
}: Props): JSX.Element {
  // this is only temporary, and is unlikely to ever be visible in prod as it is historic to layercake
  const { control } = useForm({ defaultValues: { supplierIds: rest.supplierIds || [] } });

  return (
    <div className={classnames(css.supplierTagMultiselect, css[supplierType])}>
      {selectionType === "ID" ? (
        <form>
          <SupplierSelect
            name="supplierIds"
            label=""
            control={control}
            onChange={(v) => {
              rest.onSupplierIdsChange(v);
            }}
            mode="multiple"
            placeholder="Type organisation names (e.g. Google, Amazon)..."
            idType="id"
          />
        </form>
      ) : (
        <Select
          mode="tags"
          value={supplierNames}
          onChange={onSupplierNamesChange}
          optionLabelProp="label"
          style={{ width: "100%" }}
          placeholder="Type organisation names (e.g. Google, Amazon)..."
          tokenSeparators={[",", "\n", ";"]}
          options={supplierNames?.map((s) => ({
            label: `${s}${supplierCounts?.[s] ? " (" + supplierCounts[s] + ")" : ""}`,
            value: s,
          }))}
          tagRender={({ label, closable, onClose }) => (
            <Tag
              closable={closable}
              onClose={onClose}
              color={supplierType === "competitor" ? signalCompetitor : signalPartner}
              className={css.tag}
              closeIcon={<CloseOutlined color="black" className={css.tag} />}
            >
              {label}
            </Tag>
          )}
          open={false}
          suffixIcon={null}
        />
      )}
    </div>
  );
}

export default SupplierTagMultiselect;

type ExplanationProps = {
  supplierType: "partner" | "competitor";
  useSupplierName: boolean;
};

export function SupplierTagMultiselectExplanation({
  supplierType,
  useSupplierName,
}: ExplanationProps): JSX.Element {
  const text = React.useMemo(() => {
    let typeSpecific = "";
    if (supplierType === "partner") {
      typeSpecific =
        "Tracking your partners’ activity will help you have an insight on potential sub-contracting opportunities, and find warm introductions to relevant buyers.";
    } else {
      typeSpecific =
        "Tracking your competitors’ activity will help you have an insight on their existing contracts and upcoming expiries. Use this data to anticipate and prepare for upcoming tenders.";
    }

    if (useSupplierName) return typeSpecific;

    return `${typeSpecific}\n\nThe text you enter here will search both the names of suppliers awarded work, and the text within documents. For example, if you input Salesforce, we will return awards where Salesforce won the contract, and results that include text like "We need a firm to implement our Salesforce system".`;
  }, [supplierType, useSupplierName]);

  return <InfoButton tooltipOverlayStyle={{ whiteSpace: "pre-line", minWidth: 507 }} note={text} />;
}
