import * as React from "react";
import { useCallback, useMemo } from "react";
import { hot } from "react-hot-loader/root";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { message } from "antd5";
import produce from "immer";

import { withAppLayout } from "components/app_layout/AppLayout";
import { createDecision } from "components/organisation_clean/adapters";
import OrgRelationshipPreview from "components/organisation_clean/OrgRelationshipPreview";
import OrgRelationshipSelection from "components/organisation_clean/OrgRelationshipSelection";
import {
  OrgToBeCreated,
  OrgWithStats,
  UpdatableAttributes,
} from "components/organisation_clean/types";
import NavigationFooter from "lib/core_components/NavigationFooter";
import { assertCurrentUser } from "lib/currentUser";
import {
  DecisionTypeUpdateAttributes,
  SubmitOrgCleaningTaskRequestTaskTypeEnum,
  SubmittedDecision,
} from "lib/generated/app-api";
import { usePreventNavigation } from "lib/hooks/usePreventNavigation";
import { useOpenApi } from "lib/openApiContext";
import { assertDefined, isDefined } from "lib/utils";

import css from "./BuyerRelationshipPage.module.scss";

export const getUpdateAttrsForOrg = (
  org: OrgWithStats | OrgToBeCreated,
  updateAttributeDecisions: SubmittedDecision[],
): DecisionTypeUpdateAttributes | undefined => {
  if (updateAttributeDecisions.length === 0) {
    return undefined;
  }
  return updateAttributeDecisions?.find((dec) => {
    if (dec.decisionData.updateAttributes && dec.candidateOrganisationId) {
      return org.guid === dec.candidateOrganisationId;
    } else {
      return false;
    }
  })?.decisionData.updateAttributes;
};

export const isAnyOrgUnverifed = (
  orgs: OrgWithStats[] | OrgToBeCreated[],
  updateAttributeDecisions: SubmittedDecision[],
): boolean => {
  return orgs.some(
    (buyer) => !getUpdateAttrsForOrg(buyer, updateAttributeDecisions)?.isStotlesVerified,
  );
};

export enum Steps {
  SELECT_BUYER_RELATIONSHIPS,
  SUMMARY_BUYER_RELATIONSIPS,
}

type BuyerformState = {
  step: Steps;
  userGuid: string;
  inactiveBuyers: OrgWithStats[];
  newBuyers: OrgToBeCreated[];
  targetBuyers: OrgWithStats[];
  updateAttributeDecisions: SubmittedDecision[];
};

const useHandleSubmit = (options?: UseMutationOptions<void, unknown, BuyerformState, unknown>) => {
  const api = useOpenApi();

  return useMutation(async (formState: BuyerformState) => {
    const { targetBuyers, newBuyers, inactiveBuyers, updateAttributeDecisions, step } = formState;

    const cancelLocation = Steps[step];
    const cancelNote = "SUBMITTED_FOR_REVIEW";

    assertDefined(inactiveBuyers);

    // Each of these decisions will be submitted for each inactive buyer
    for (const anchorBuyer of inactiveBuyers) {
      let decisions: SubmittedDecision[] = [];

      // Looking at the update attribute decisions for this inactive buyer
      // And also adding the update decisions for the target buyers - this may mean that these update decisions are appearing
      // repeatedly for different anchors (different inactive buyers), but this is a small price to pay for the ease of the review stage
      const targetBuyerGuids = targetBuyers.map((buyer) => buyer.guid);
      const targetBuyerUpdateDecisions = updateAttributeDecisions.filter((dec) => {
        if (dec.candidateOrganisationId) {
          return (
            targetBuyerGuids.includes(dec.candidateOrganisationId) ||
            dec.candidateOrganisationId === anchorBuyer.guid
          );
        }
        return false;
      });

      // If there are no buyers to transfer relationships to, then create a INACTIVE decision for each inactive buyer
      if (targetBuyers.length === 0 && newBuyers.length === 0) {
        const inactiveDecisions = createDecision(anchorBuyer.guid, "inactive");
        decisions = [...targetBuyerUpdateDecisions, inactiveDecisions];

        // Otherwise we're dealing with transfer responsibilities decisions
      } else {
        // Decisions to transfer the responsibilities of the inactive buyers to target buyers
        const transferToTargetOrgDesc = targetBuyers.map((buyer) =>
          createDecision(buyer.guid, "transferResponsibilities"),
        );

        // Decisions to transfer the responsibilities of the inactive buyers to new buyers
        const transferToNewOrgDesc = newBuyers.map((newBuyer) =>
          createDecision(anchorBuyer.guid, "transferResponsibilities", newBuyer),
        );

        decisions = [
          ...targetBuyerUpdateDecisions,
          ...transferToTargetOrgDesc,
          ...transferToNewOrgDesc,
        ];
      }

      await api.submitOrgCleaningTask({
        submitOrgCleaningTaskRequest: {
          decisions,
          organisationId: anchorBuyer.guid,
          cancelLocation,
          cancelNote,
          taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.RelationshipCleaning,
        },
      });
    }
  }, options);
};

/**
 * Tool which allows users to verify buyers via linking them with an oscar ID, mark buyers as inactive and also
 * transfer the responsibilites of these inactive buyers to other buyers (these buyers MUST be verified).
 * @returns
 */
function BuyerRelationshipPage() {
  const initialFormState: BuyerformState = React.useMemo(
    () => ({
      userGuid: assertCurrentUser().guid,
      step: Steps.SELECT_BUYER_RELATIONSHIPS,
      inactiveBuyers: [],
      newBuyers: [],
      targetBuyers: [],
      updateAttributeDecisions: [],
    }),
    [],
  );

  const [formState, setFormState] = React.useState<BuyerformState>(() => initialFormState);

  const [messageApi, contextHolder] = message.useMessage();

  const { inactiveBuyers, targetBuyers, newBuyers, step, updateAttributeDecisions } = formState;

  usePreventNavigation(
    inactiveBuyers.length > 0 || targetBuyers.length > 0 || newBuyers.length > 0,
    "Are you sure you want to leave the page? All changes will go unsaved.",
  );

  const navigateToNextStep = (step: Steps) => {
    setFormState(
      produce((form) => {
        form.step = step;
      }),
    );
  };

  const handleChangeStep = (step: Steps) => {
    const unverifiedNewBuyers = newBuyers.some((buyer) => !buyer.is_stotles_verified);
    const unverifiedInactiveBuyers = isAnyOrgUnverifed(inactiveBuyers, updateAttributeDecisions);
    const unverifiedTargetBuyers = isAnyOrgUnverifed(targetBuyers, updateAttributeDecisions);

    if (
      (unverifiedNewBuyers || unverifiedInactiveBuyers || unverifiedTargetBuyers) &&
      step === Steps.SUMMARY_BUYER_RELATIONSIPS
    ) {
      void messageApi.warning({
        content: "It is recommended that all buyers should be verified before proceeding.",
        duration: 1.5,
        onClose: () => navigateToNextStep(step),
      });
    } else {
      navigateToNextStep(step);
    }
  };

  const { mutateAsync: handleSubmit } = useHandleSubmit({
    onSuccess: () => {
      void message.success("Thanks for submitting the buyer verification task!");
      setFormState(initialFormState);
    },
    onError: (e) => {
      const msg = e instanceof Error ? e.message : `${e}`;
      void message.error(`Error submitting verification task: ${msg}`);
    },
  });

  /**
   * Sets the buyers which we want to mark as inactive, blocks non admins from having more than one inactive buyer
   */
  const setInactiveBuyers = React.useCallback(
    (b: OrgWithStats[]) => {
      if (b.length > 1 && !assertCurrentUser().admin) {
        void messageApi.error("Only admins can mark more than one buyer as inactive.");
      } else {
        setFormState(
          produce((draftState) => {
            draftState.inactiveBuyers = b;
          }),
        );
      }
    },
    [messageApi],
  );

  /**
   * Basically sets the buyer to whom we're transferring responsibilities to - can only be
   * a buyer which already exists in our db
   */
  const setTargetBuyers = React.useCallback((b: OrgWithStats[]) => {
    setFormState(
      produce((draftState) => {
        draftState.targetBuyers = b;
      }),
    );
  }, []);

  const setNewBuyers = React.useCallback((b: OrgToBeCreated[]) => {
    setFormState(
      produce((draftState) => {
        draftState.newBuyers = b;
      }),
    );
  }, []);

  /**
   * Creates decisions to update the attributes of a buyer. Newvalues contains the values that are being updated, plus the old values of the object which are not being updates
   */
  const setAttributeDecisions = React.useCallback(
    (orgGuid: string, newValues: UpdatableAttributes) => {
      setFormState(
        produce((draftState) => {
          const newDecision: SubmittedDecision = {
            candidateOrganisationId: orgGuid,
            decisionData: {
              updateAttributes: {
                oscarId: newValues.oscar_id?.toString(),
                companiesHouseId: newValues.companies_house_id,
                isStotlesVerified: newValues.is_stotles_verified,
              },
            },
          };

          draftState.updateAttributeDecisions = updateAttributeDecisions
            .filter((decision) => decision.candidateOrganisationId !== orgGuid)
            .concat([newDecision]);
        }),
      );
    },
    [updateAttributeDecisions],
  );

  const orgOscarId = useCallback(
    (org: OrgWithStats): string | null | undefined => {
      const updateAttrsForOrg = getUpdateAttrsForOrg(org, updateAttributeDecisions);

      if (updateAttrsForOrg) {
        // Oscar ID will only be present for an org if it has been defined
        if (isDefined(updateAttrsForOrg.oscarId)) {
          return updateAttrsForOrg?.oscarId;
        }
      }
      return org.oscar_id;
    },
    [updateAttributeDecisions],
  );

  const orgVerification = useCallback(
    (org: OrgWithStats): boolean => {
      const updateAttrsForOrg = getUpdateAttrsForOrg(org, updateAttributeDecisions);

      if (updateAttrsForOrg) {
        // Verification will only be present for an org if it has been defined i.e set to true or false
        if (isDefined(updateAttrsForOrg.isStotlesVerified)) {
          return updateAttrsForOrg?.isStotlesVerified;
        }
      }
      return org.is_stotles_verified ?? false;
    },
    [updateAttributeDecisions],
  );

  // We're keeping the original orgs and the updates to them in separate arrays so we can compare the original & updated in previews
  const displayedBuyers = useMemo((): {
    displayedTargetBuyers: OrgWithStats[];
    displayedInactiveBuyers: OrgWithStats[];
  } => {
    const displayedInactiveBuyers: OrgWithStats[] = inactiveBuyers.map((buyer) => {
      return { ...buyer, oscar_id: orgOscarId(buyer), is_stotles_verified: orgVerification(buyer) };
    });

    const displayedTargetBuyers: OrgWithStats[] = targetBuyers.map((buyer) => {
      return { ...buyer, oscar_id: orgOscarId(buyer), is_stotles_verified: orgVerification(buyer) };
    });

    return { displayedTargetBuyers, displayedInactiveBuyers };
  }, [inactiveBuyers, orgOscarId, orgVerification, targetBuyers]);

  switch (step) {
    case Steps.SELECT_BUYER_RELATIONSHIPS:
      return (
        <div className={css.buyerRelationshipPage}>
          <header>
            <h1>Step 1. Buyer relationship statement</h1>
          </header>
          <div className={css.pageContent}>
            <OrgRelationshipSelection
              orgPrimaryRole="Buyer"
              targetOrgs={targetBuyers}
              inactiveOrgs={inactiveBuyers}
              newOrgs={newBuyers}
              displayedInactiveOrgs={displayedBuyers.displayedInactiveBuyers}
              displayedTargetOrgs={displayedBuyers.displayedTargetBuyers}
              setInactiveOrgs={setInactiveBuyers}
              setTargetOrgs={setTargetBuyers}
              setNewOrgs={setNewBuyers}
              setAttributeDecisions={setAttributeDecisions}
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => handleChangeStep(Steps.SUMMARY_BUYER_RELATIONSIPS)}
            nextButtonLabel="Next"
            nextDisabled={inactiveBuyers.length === 0}
            currentStep={Steps.SELECT_BUYER_RELATIONSHIPS}
            totalSteps={2}
          />
          {contextHolder}
        </div>
      );

    case Steps.SUMMARY_BUYER_RELATIONSIPS:
      return (
        <div className={css.buyerRelationshipPage}>
          <header>
            <h1>Step 2. Summary of changes</h1>
          </header>
          <div className={css.pageContent}>
            <OrgRelationshipPreview
              orgPrimaryRole="Buyer"
              targetOrgs={displayedBuyers.displayedTargetBuyers}
              newOrgs={newBuyers}
              inactiveOrgs={displayedBuyers.displayedInactiveBuyers}
              formStateStringified={JSON.stringify(formState, null, 2)}
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={async () => {
              await handleSubmit(formState);
            }}
            onBack={() => handleChangeStep(Steps.SELECT_BUYER_RELATIONSHIPS)}
            nextButtonLabel="Submit for review"
            backButtonLabel="Back"
            currentStep={Steps.SUMMARY_BUYER_RELATIONSIPS}
            totalSteps={2}
          />
          {contextHolder}
        </div>
      );
  }
}

export default hot(
  withAppLayout(BuyerRelationshipPage, {
    pageName: "Buyer Relationship Statement",
  }),
);
