import { CpvCodesResponse_CpvCodes } from "lib/generated/app-service-gql/graphql";
import { CpvTreeNode } from "./useCpvTree";

export type CpvCode = CpvCodesResponse_CpvCodes & { isSignal: boolean };
/**
 * Helper function to determine the significant portion of a CPV code (non-zero prefix)
 */
export function getCpvPrefix(cpvCode: string): string {
  // Remove trailing zeroes
  const nonZeroPart = cpvCode.replace(/0+$/, "");

  // If the resulting length is 1, return the first two characters of the original string
  if (nonZeroPart.length === 1) {
    return cpvCode.substring(0, 2);
  }

  // Otherwise, return the non-zero portion
  return nonZeroPart;
}

/**
 * Goes through all of the selected CPV codes and only returns the top ancestor cpv codes
 * so that we don't unecessarily have loads of CPV children
 * @param codes
 * @returns
 */
export function onlyShowCpvParents(codes: string[]): string[] {
  // Sort codes to ensure that descendant cpv codes follow their parents
  codes.sort();

  const selectedCodePrefixes = codes.map((code) => getCpvPrefix(code));

  const resultingCodes: string[] = [];
  const existingPrefixes: string[] = [];

  for (let i = 0; i < codes.length; i++) {
    const currentPrefix = getCpvPrefix(selectedCodePrefixes[i]);

    // Check if the current code is a prefix of any subsequent code
    const existingPrefix = existingPrefixes.find((p) => currentPrefix.startsWith(p));

    // If this prefix does not exist in the resulting codes, add it
    if (!existingPrefix) {
      // Push a list of existing prefixes for easy comparison logic within this loop
      existingPrefixes.push(currentPrefix);

      // But also push the current code because that's what we actually care about
      resultingCodes.push(codes[i]);
    }
  }

  return [...new Set(resultingCodes)]; // Ensure uniqueness
}

export function deselectNode(
  allCpvCodes: CpvCode[],
  value: string[],
  removedCodeValue: string,
): string[] {
  const removedCode = allCpvCodes.find((c) => c.code === removedCodeValue);
  const parentCode = allCpvCodes.find((c) => c.code === removedCode?.parentCode);
  const parentChildren = parentCode?.childIds?.filter((c) => c !== removedCode?.code) || [];
  // remove the parent node but add the other children
  const newValue = [...value.filter((v) => v !== parentCode?.code), ...parentChildren];
  // if it has a parent continue up the tree removing the parent nodes
  if (parentCode?.parentCode) {
    return deselectNode(allCpvCodes, newValue, parentCode.code);
  }
  return newValue;
}

/**
 * Helper function to find all nodes that match the search
 * text by recursively searching the tree and stopping
 * the recursion on a given branch when when it finds a matching node
 * We improve performance for numeric searches by only checking the start of
 * the CPV code
 * Note: if we need to enable highlighting then we'll need to change this
 * to fully search the tree even when a match is found
 *
 * @param nodes - CPV tree nodes
 * @param textSearch
 * @param isCpvCodeSearch - if true, only search for CPV codes
 */
export function findMatchingCpvCodes(
  nodes: CpvTreeNode[],
  textSearch: string,
  isCpvCodeSearch: boolean,
): CpvTreeNode[] {
  if (textSearch.length === 0) {
    return [];
  }

  function isMatch(node: CpvTreeNode, searchText: string) {
    if (isCpvCodeSearch) {
      // if search text is only digits, look for CPV Code match
      return node.value.toLowerCase().startsWith(searchText);
    } else {
      // if name or code matches, add to matches
      return (
        node.label.toLowerCase().includes(searchText) ||
        node.value.toLowerCase().includes(searchText)
      );
    }
  }

  const searchText = textSearch.toLowerCase();
  const matches: CpvTreeNode[] = [];

  for (const node of nodes) {
    if (isMatch(node, searchText)) {
      matches.push(node);
    } else {
      const matchedChildren = findMatchingCpvCodes(
        node.children || [],
        textSearch,
        isCpvCodeSearch,
      );
      if (matchedChildren.length > 0) {
        matches.push(...matchedChildren);
      }
    }
  }
  return matches;
}
