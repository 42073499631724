import * as React from "react";
import { createPortal } from "react-dom";

import PaywallModal from "components/paywall/PaywallModal";
import { FeatureType } from "components/paywall/paywallUtils";
import * as tracking from "lib/tracking";
import {
  DataTypeNameWithBuyersAndSuppliers,
  ProDataTypeSeparateBuyersSuppliers,
} from "lib/types/models";
import { SubscriptionContext } from "./Subscription";

type PaywallProps = {
  onBack?: () => void;
  requiredDataSubscription?: ProDataTypeSeparateBuyersSuppliers;
  featureType?: FeatureType;
  trackingPageName?: string;
  trackingExtraProps?: any;
};

function Paywall({
  requiredDataSubscription,
  featureType,
  trackingPageName,
  trackingExtraProps,
}: PaywallProps) {
  const paywallUtils: React.ReactNode = React.useMemo(() => {
    if (requiredDataSubscription) {
      return (
        <PaywallModal
          featureType={featureType ?? "SUPPLIERS"}
          // Return to home page
          onClose={() => (window.location.href = "/")}
          isOpen={true}
        />
      );
    } else {
      return null;
    }
  }, [featureType, requiredDataSubscription]);

  React.useEffect(() => {
    const body = document.body;
    if (paywallUtils) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "initial";
    }

    return () => {
      body.style.overflow = "initial";
    };
  }, [paywallUtils]);

  React.useEffect(() => {
    if (paywallUtils) {
      tracking.logEvent(tracking.EventNames.paywallViewed, {
        "Paywall reason": "User not subscribed to data source",
        "Data source(s) required": requiredDataSubscription,
        "Feature type": featureType ?? "SUPPLIERS",
        "User's current data sources": window.currentUser?.data_type_subscriptions,
        "Page name": trackingPageName,
        "Other page info": trackingExtraProps,
      });
    }
  }, [trackingExtraProps, trackingPageName, paywallUtils, requiredDataSubscription, featureType]);

  if (!paywallUtils) return null;

  return createPortal(paywallUtils, document.body);
}

interface PaywallManager {
  showPaywall(props: PaywallProps): void;
  hidePaywall(): void;
  confirmUserDataSubscription(
    requiredDataSubscription: DataTypeNameWithBuyersAndSuppliers,
    featureType: FeatureType,
  ): void;
}

type PaywallManagerState = {
  isVisible: boolean;
  paywallProps: PaywallProps;
};

class InvalidPaywallManager implements PaywallManager {
  showPaywall(_props: PaywallProps) {
    this.throwNotImplementedError();
  }

  hidePaywall() {
    this.throwNotImplementedError();
  }

  confirmUserDataSubscription() {
    this.throwNotImplementedError();
  }

  private throwNotImplementedError() {
    throw new Error("Accessing PaywallManager without a provider.");
  }
}

const PaywallManagerContext = React.createContext<PaywallManager>(new InvalidPaywallManager());

export function useConfirmUserDataSubscription(
  dataSubscription: ProDataTypeSeparateBuyersSuppliers,
  featureType: FeatureType,
): void {
  const paywallManager = usePaywallManager();
  React.useEffect(() => {
    // If it's a guest user we don't want to call paywalls
    if (!window.authToken) {
      paywallManager.confirmUserDataSubscription(dataSubscription, featureType);
    }
  });
}

type PaywallManagerProps = Pick<PaywallProps, "trackingPageName" | "trackingExtraProps">;

export class PaywallManagerProvider
  extends React.Component<PaywallManagerProps, PaywallManagerState>
  implements PaywallManager
{
  static contextType = SubscriptionContext;
  declare context: React.ContextType<typeof SubscriptionContext>;

  constructor(props: PaywallManagerProps) {
    super(props);
    const { trackingPageName, trackingExtraProps } = props;

    this.state = {
      isVisible: false,
      paywallProps: {
        trackingPageName,
        trackingExtraProps,
        featureType: undefined,
        requiredDataSubscription: undefined,
      },
    };
  }

  showPaywall = (paywallProps: PaywallProps): void => {
    this.setState({ isVisible: true, paywallProps });
  };

  hidePaywall = (): void => {
    this.setState({ isVisible: false });
  };

  confirmUserDataSubscription = (
    requiredDataSubscription: ProDataTypeSeparateBuyersSuppliers,
    featureType?: FeatureType,
  ): void => {
    this.setState((prevState) => ({
      ...prevState,
      isVisible: !this.context.hasDataTypes(requiredDataSubscription),
      paywallProps: { ...prevState.paywallProps, requiredDataSubscription, featureType },
    }));
  };

  render(): JSX.Element {
    const { isVisible, paywallProps } = this.state;

    return (
      <PaywallManagerContext.Provider value={this}>
        {this.props.children}
        {/* TODO: maybe remove dependency on paywallProps */}
        {isVisible && paywallProps && <Paywall {...paywallProps} />}
      </PaywallManagerContext.Provider>
    );
  }
}

function usePaywallManager(): PaywallManager {
  return React.useContext(PaywallManagerContext);
}
