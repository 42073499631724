import React, { useState } from "react";
import { Button, ConfigProvider } from "antd5";

import UserInitials, { UnassignedIcon } from "components/comments/UserInitials";
import { useProvideFeedback } from "lib/hooks/api/question/useProvideFeedback";
import Sparkles from "lib/icons/Sparkles";
import { white } from "lib/themes/colors";
import * as tracking from "lib/tracking";
import AiFeedbackModal from "./AiFeedbackModal";
import { AnswerMarkdown } from "./AnswerMarkdown";
import { CreateReportModal } from "./CreateReportModal";
import {
  createFeedbackCloseEvent,
  createFeedbackOpenedEvent,
  createReportModalOpenedEvent,
  createTrackingLinkContextEvent,
} from "./tracking";

import css from "./ChatResponse.module.scss";

import SPARKLES from "../../../../assets/images/icons/sparkles.svg";
import THUMBS_DOWN from "../../../../assets/images/icons/thumbs_down.svg";
import THUMBS_UP from "../../../../assets/images/icons/thumbs_up.svg";

type ChatResponseProps = {
  question: string;
  answer: string;
  orgName: string;
  orgId: string;
  questionId: string;
  isComplete?: boolean;
  reportId?: string | null;
};

export default function ChatResponse({
  question,
  answer,
  orgName,
  orgId,
  questionId,
  isComplete,
  reportId,
}: ChatResponseProps) {
  const [feedbackRecorded, setFeedbackRecorded] = useState<{ positive: boolean } | undefined>(
    undefined,
  );
  const [isCreateReportOpen, setIsCreateReportOpen] = useState(false);
  const currentUser = window.currentUser;

  const { mutate: provideFeedback } = useProvideFeedback();

  const { logEvents } = tracking.useTracking();

  return (
    <div className={css.aiQuestionItems}>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              // need to set this to prevent inheritence of the Ask AI modal theming
              contentBg: "#ffffff",
            },
          },
        }}
      >
        <AiFeedbackModal
          onClose={(feedback?: string, untrue?: boolean, unhelpful?: boolean) => {
            logEvents(
              createFeedbackCloseEvent(
                feedbackRecorded?.positive ? true : false,
                question,
                answer,
                orgName,
                feedback,
                untrue,
                unhelpful,
              ),
            );
            setFeedbackRecorded(undefined);
          }}
          onSubmit={() => {
            setFeedbackRecorded(undefined);
          }}
          visible={feedbackRecorded ? true : false}
          positiveFeedback={feedbackRecorded?.positive ? true : false}
          questionId={questionId}
          question={question}
          answer={answer}
          orgName={orgName}
        />
      </ConfigProvider>
      <div className={css.userAnswerRow}>
        {window.guestUser ? (
          <UnassignedIcon showTooltip={false} />
        ) : (
          <UserInitials
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            className={css.avatar}
          />
        )}
        <div className={css.userQuestion}>
          <p className={css.userHeader}>{window.guestUser ? "You" : currentUser?.first_name}</p>
          <p>{question}</p>
        </div>
      </div>
      <div className={css.ariAnswerRow}>
        <img src={SPARKLES} height={32} alt="sparkles" className={css.sparkles} />
        <div className={css.ariAnswer}>
          <p className={css.ariHeader}>Stotles AI</p>
          <AnswerMarkdown
            answer={answer}
            trackingLinkContext={(recordGuid) => {
              logEvents(createTrackingLinkContextEvent(recordGuid, question, answer, orgName));
            }}
          />
        </div>
      </div>
      {isComplete && (
        <div className={css.interactionRow}>
          <Button
            className={css.feedbackButton}
            onClick={() => {
              logEvents(createFeedbackOpenedEvent(true, question, answer, orgName));
              provideFeedback({
                question: question,
                answer: answer,
                orgName: orgName,
                args: {
                  isPositive: true,
                  questionId: questionId,
                },
              });
              setFeedbackRecorded({ positive: true });
            }}
            icon={<img src={THUMBS_UP} height={16} alt="thumbs up" />}
          />
          <Button
            className={css.feedbackButton}
            onClick={() => {
              logEvents(createFeedbackOpenedEvent(false, question, answer, orgName));
              provideFeedback({
                question: question,
                answer: answer,
                orgName: orgName,
                args: {
                  isPositive: false,
                  questionId: questionId,
                },
              });
              setFeedbackRecorded({ positive: false });
            }}
            icon={<img src={THUMBS_DOWN} height={16} alt="thumbs down" />}
          />
          <Button
            type="primary"
            onClick={() => {
              if (!reportId) {
                logEvents(createReportModalOpenedEvent(question, questionId, orgName));
                setIsCreateReportOpen(true);
              }
            }}
            href={reportId ? `/reports/${reportId}` : undefined}
            target="_blank"
            icon={<Sparkles fill={white} className={css.iconSparkles} />}
          >
            {reportId ? "View report" : "Generate report"}
          </Button>
          <CreateReportModal
            isOpen={isCreateReportOpen}
            onClose={() => setIsCreateReportOpen(false)}
            questions={[
              {
                question,
                questionId,
                answer,
                referenceId: orgId,
                referenceType: "BUYER",
              },
            ]}
            orgName={orgName}
          />
        </div>
      )}
    </div>
  );
}
