import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd5";
import produce from "immer";

import TextButton from "components/actions/TextButton";
import MultiSelectDropdown from "lib/core_components/MultiSelectDropdownList";
import { createUseDebounce } from "lib/debounce";
import { EventNames, useTracking } from "lib/tracking";
import { SELECT_ALL } from "lib/types/models";
import { useSignalCategories } from "../../lib/hooks/api/teams/useSignalSettings";
import { SupplierRelationshipFilter } from "./types";

import css from "./RelationshipProfileFilter.module.scss";

const useDebounce500 = createUseDebounce(500);

type Props = {
  search_type: string;
  onFiltersChange: React.Dispatch<React.SetStateAction<SupplierRelationshipFilter>>;
  filters: SupplierRelationshipFilter;
};

function RelationshipProfileFilter({ search_type, filters, onFiltersChange }: Props) {
  const { logEvent } = useTracking();

  const [textSearch, setTextSearch] = React.useState("");

  const {
    data: { keywordNames },
  } = useSignalCategories();

  const onKeywordsChange = (newKeywords: string[]) =>
    onFiltersChange(
      produce(filters, (draft) => {
        draft.keywords = newKeywords;
      }),
    );

  const hasActiveFilters = () => {
    if (!filters) {
      return false;
    } else return Object.values(filters).some((value) => value.length > 0);
  };

  const handleClearFilters = () => {
    onFiltersChange({ keywords: [], textSearch: "" });
    logEvent(EventNames.filterActioned, {
      "Filter name": "All filters",
      "Filter selection": "All options",
      "Action type": "Filter cleared",
    });
  };

  const selectedkeywords = filters?.keywords.includes(SELECT_ALL)
    ? keywordNames
    : filters?.keywords;

  const textSearchChangeEvent = (value: string) => {
    logEvent(EventNames.filterActioned, {
      "Filter name": `${search_type} search`,
      "Filter selection": value,
      "Action type": value.length > 0 ? "Filter applied" : "Filter cleared",
    });
    onFiltersChange(
      produce((f) => {
        f.textSearch = value;
      }),
    );
  };

  const debouncedTextSearchChangeEvent = useDebounce500(textSearchChangeEvent);

  const handleTextSearch = (value: string) => {
    setTextSearch(value);
    debouncedTextSearchChangeEvent(value);
  };

  return (
    <div className={css.filtersContainer}>
      <Input
        placeholder={`Search ${search_type}`}
        onChange={(e) => handleTextSearch(e.target.value)}
        value={textSearch}
        className={css.searchInput}
        prefix={<SearchOutlined className={css.searchIcon} />}
        allowClear
      />
      <MultiSelectDropdown
        title="Keywords"
        optionClassName={css.keywordTag}
        options={keywordNames.map((kw) => ({ title: kw, value: kw }))}
        selectedOptions={selectedkeywords}
        onChange={onKeywordsChange}
      />
      {hasActiveFilters() && (
        <TextButton bold className={css.clearFilters} onClick={handleClearFilters}>
          Clear all filters
        </TextButton>
      )}
    </div>
  );
}

export default RelationshipProfileFilter;
