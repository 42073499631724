import * as React from "react";

import { TextLink } from "components/actions/Links";
import { useCheckSubscription } from "lib/providers/ProHelper";
import MultipleEntityLinks from "./MultipleEntityLinks";

type Supplier = {
  id: number;
  name: string;
  guid?: string; //TODO: make this required once we switch suppliers to guids
};

type Props = {
  recordName: string;
  suppliers: Supplier[];
};

export default function SuppliersContainer({ recordName, suppliers }: Props): JSX.Element | null {
  const { authorised, checkSubscription } = useCheckSubscription("SUPPLIERS", {
    "Context source": "In-row",
  });
  const supplierLinks = React.useMemo(() => {
    if (!suppliers) return null;

    const uniqueSuppliers: Supplier[] = [];
    suppliers.forEach((s) => {
      // This dedupes the suppliers in case there are some that are in multiple lots
      if (!uniqueSuppliers.some((us) => us.id === s.id)) uniqueSuppliers.push(s);
    });

    return uniqueSuppliers.map((s) => (
      <TextLink
        to={`/suppliers/${s.id}`}
        authorised={authorised}
        onUnauthorisedClick={checkSubscription}
        key={s.id}
      >
        <b>{s.name}</b>
      </TextLink>
    ));
  }, [authorised, checkSubscription, suppliers]);

  return supplierLinks ? (
    <MultipleEntityLinks title={`Suppliers for "${recordName}"`} links={supplierLinks} />
  ) : null;
}
