import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NoticeQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const noticeQuery = graphql(`
  query notice($id: String!) {
    notice(noticeId: $id) {
      guid
      name
      description
      entityType
      stage
      qualification
      legacyRelevanceScore
      score
      value
      currency
      url
      expiryDate
      closeDate
      publishDate
      buyerContactName
      buyerContactEmail
      buyerContactPhone
      procurementStage {
        id
        stage
        procurementProcessId
      }
      lots {
        lotNumber
        lotTitle
        value
        currency
        endDate
        suppliers {
          id
          guid
          name
        }
      }
      cpvCodes {
        code
        name
      }
      buyers {
        id
        guid
        name
        buyerUrl
        country
        town
        postalCode
        address
      }
      assignee {
        guid
        firstName
        lastName
      }
      seenByUsers {
        guid
        firstName
      }
      relatedFrameworkProcess {
        frameworkId
        processType
      }
      signals {
        id
        name
        category
      }
    }
  }
`);

export type NoticeDetails = NoticeQuery["notice"];

export function useNotice(
  noticeId: string,
  options?: UseQueryOptions<NoticeQuery, unknown, NoticeQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["notice", noticeId], noticeQuery, [{ id: noticeId }], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.notice, ...rest };
}
