import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  GetQuestionsQuery,
  GetQuestionsQueryVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const getQuestions = graphql(`
  query getQuestions($args: GetQuestionsRequest!) {
    getQuestions(GetQuestionsRequest: $args) {
      id
      question
      isComplete
      answer
      responseReceivedAt
      referenceId
      reportId
    }
  }
`);

export function useQuestions(
  args: GetQuestionsQueryVariables["args"],
  options?: UseQueryOptions<GetQuestionsQuery, unknown, GetQuestionsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["questions", args], getQuestions, [{ args }], {
    ...options,
  });
  return { data: data?.getQuestions, ...rest };
}
