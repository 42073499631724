import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "antd5";

import { Input, Radio, TextArea } from "components/form_components/Inputs";
import { useAllViews } from "lib/hooks/api/notices/views/useAllViews";

import css from "./SavedViewForm.module.scss";

type FormValues = {
  viewName: string;
  viewDescription: string;
  alertFrequency?: "DAILY" | "WEEKLY" | "NEVER";
};

export function SavedViewForm({
  defaultValues,
  isStandardView,
  onSubmit,
  onCancel,
  isLoading,
}: {
  defaultValues?: FormValues;
  isStandardView?: boolean;
  onSubmit: (data: FormValues) => void;
  onCancel: () => void;
  isLoading?: boolean;
}) {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues,
  });

  const { data: allViews } = useAllViews();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form className={css.form} onSubmit={handleSubmit((d) => onSubmit(d))}>
      <Input
        control={control}
        label="Name"
        placeholder="Type view name here..."
        name="viewName"
        type="text"
        disabled={isStandardView}
        tooltipText={isStandardView ? "You cannot edit a standard view's name" : ""}
        rules={{
          required: {
            value: true,
            message: "View name is required",
          },
          validate: (val) => {
            if (val && defaultValues?.viewName === val) {
              return true;
            }

            if (val && allViews?.map((v) => v.name.toLowerCase()).includes(val.toLowerCase())) {
              return "A view already exists with this name";
            }
            return true;
          },
        }}
      />

      <TextArea
        control={control}
        label="Description"
        placeholder="What does this view show? Enter a description here..."
        name="viewDescription"
        disabled={isStandardView}
        tooltipText={isStandardView ? "You cannot edit a standard view's description" : ""}
      />

      {defaultValues?.alertFrequency && (
        <Radio
          label="Email me about new activity in this view"
          name={"alertFrequency"}
          control={control}
          options={[
            { label: "Daily", value: "DAILY" },
            { label: "Weekly", value: "WEEKLY" },
            { label: "Never", value: "NEVER" },
          ]}
          optionType="default"
        />
      )}
      <div className={css.formActions}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </form>
  );
}
