import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button } from "antd5";

import { Input } from "components/form_components/Inputs";
import { sendGa4Event } from "../../lib/ga4Tracking";
import { ACCOUNT_SETUP_FORM, fieldInteractionEvent } from "./tracking";

import css from "./CreateAccountForm.module.scss";

export type FormData = {
  jobTitle: string;
  firstName: string;
  lastName: string;
  companyName: string;

  // Note that these won't be used in account creation,
  // but it's useful to have it for displaying it to user
  email: string;
  partnerProgrammeName: string | null;
};

type Props = {
  email: string;
  readonlyCompany: boolean;

  onCreateAccount: (data: FormData) => Promise<void>;
  initialValues: FormData;
};

export function CreateAccountForm(props: Props) {
  const { onCreateAccount, initialValues, readonlyCompany } = props;
  const {
    control,
    setError,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    watch,
  } = useForm<FormData>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const previousFormValue = useRef<Partial<FormData>>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      sendGa4Event({
        event: "continue_click",
        form: ACCOUNT_SETUP_FORM,
      });
      await onCreateAccount(data);
    } catch (e) {
      if (e instanceof Error) {
        setError("root", { message: e.message });
      } else {
        throw e;
      }
    }
  });

  useEffect(() => {
    // they have started interacting with the form
    if (isDirty) {
      sendGa4Event({
        event: "account_setup_form_start",
        form: ACCOUNT_SETUP_FORM,
      });
    }
  }, [isDirty]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        // if the field was empty and now it's not
        if (
          (!previousFormValue.current || !previousFormValue.current[name]) &&
          value[name] !== undefined
        ) {
          fieldInteractionEvent(value[name]?.toString() || "", name);
        }
      }
      previousFormValue.current = value;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={onSubmit} className={css.onboardingForm} id="create-account">
      <Input<FormData>
        name="firstName"
        placeholder="Ari"
        label="First name"
        control={control}
        size="large"
        rules={{ required: { value: true, message: "Please fill out your first name" } }}
      />
      <Input<FormData>
        name="lastName"
        placeholder="Stotle"
        label="Last name"
        control={control}
        size="large"
        rules={{ required: { value: true, message: "Please fill out your last name" } }}
      />
      <Input<FormData>
        name="companyName"
        placeholder="Phylosofi"
        label="Company name"
        disabled={readonlyCompany}
        readOnly={readonlyCompany}
        control={control}
        size="large"
        rules={{ required: { value: true, message: "Please fill out your company name" } }}
      />

      <Input<FormData>
        name="jobTitle"
        placeholder="Account Manager"
        label="Job title"
        control={control}
        size="large"
        rules={{ required: { value: true, message: "Please fill out your job title" } }}
      />
      {initialValues.partnerProgrammeName && (
        <Input<FormData>
          name="partnerProgrammeName"
          placeholder="Partner programme name"
          label="Partner programme name"
          disabled={true}
          readOnly
          control={control}
          size="large"
          required
        />
      )}
      <Button
        size="middle"
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        disabled={!isDirty || !isValid}
        className={css.submitButton}
      >
        Continue
      </Button>
    </form>
  );
}
