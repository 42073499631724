import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  ExportNoticesMutation,
  ExportNoticesMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const exportNotices = graphql(`
  mutation exportNotices(
    $filters: NoticesSearchRequest!
    $exportFormat: ExportFormatArgs!
    $selectedNotices: SelectedNotices!
    $selectedFields: [String!]
  ) {
    exportNotices(
      NoticesSearchRequest: $filters
      ExportFormat: $exportFormat
      SelectedNotices: $selectedNotices
      SelectedFields: $selectedFields
    ) {
      id
    }
  }
`);

type UseNoticeExport = UseMutationResult<
  ExportNoticesMutation["exportNotices"],
  unknown,
  ExportNoticesMutationVariables,
  unknown
>;

export function useNoticeExport(
  options?: UseMutationOptions<
    ExportNoticesMutation,
    unknown,
    ExportNoticesMutationVariables,
    unknown
  >,
): UseNoticeExport {
  const { data, ...rest } = useGraphQlMutation(exportNotices, options);
  return { data: data?.exportNotices, ...rest } as unknown as UseNoticeExport;
}
