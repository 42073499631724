import React from "react";
import { Button } from "antd5";
import classnames from "classnames";

import { SignalDto } from "lib/generated/app-api";
import { useCalculateSignals } from "lib/hooks/api/signals/useCalculateSignals";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { ExternalLink } from "lib/icons/ExternalLink";
import { tagColourFromSignal } from "lib/tagUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { getSignalTypes } from "lib/types/models";
import { SignalEntityType } from "lib/utils/signalUtils";
import CentredSpinner from "./CentredSpinner";

// Disable linter - It's a shared file!
// eslint-disable-next-line css-modules/no-unused-class
import tagHelperCss from "../../styles/tag_helpers.module.scss";
// eslint-disable-next-line css-modules/no-unused-class
import css from "./RelevanceScore.module.scss";

type RelevanceScoreProps = {
  entityId: string;
  eventAttributes?: EventData;
  entityType: SignalEntityType;
};

export function NewRelevanceScoreContent({
  entityId,
  eventAttributes,
  entityType,
}: RelevanceScoreProps) {
  const { logEvent } = useTracking();

  const { data, isLoading } = useCalculateSignals(entityId, entityType);

  const { allSignals, keywordSignals, competitorSignals, partnerSignals, cpvSignals } = data;

  const openGuestAccessPassPaywall = useRestrictedGuestAccess();

  const signalsTypes = getSignalTypes(allSignals);
  eventAttributes = {
    ...eventAttributes,
    Signals: signalsTypes,
    "Signal types": signalsTypes ? Object.keys(signalsTypes) : [],
  };

  return (
    <div className={css.container}>
      <p>
        Our relevance score is calculated based on this record's match with your signal settings.
      </p>
      {isLoading ? (
        <div className={css.loadingSpinner} aria-label="Relevance score loading">
          <CentredSpinner />
        </div>
      ) : (
        <span aria-label="Signals container">
          {allSignals.length === 0 && <b>This record doesn't match any of your signals.</b>}
          {keywordSignals.length > 0 && <SignalsBlock name="Keywords" signals={keywordSignals} />}
          {competitorSignals.length > 0 && (
            <SignalsBlock name="Competitors" signals={competitorSignals} />
          )}
          {partnerSignals.length > 0 && <SignalsBlock name="Partners" signals={partnerSignals} />}

          {cpvSignals.length > 0 && <SignalsBlock name="CPV codes" signals={cpvSignals} />}
        </span>
      )}
      <Button
        type="default"
        className={css.settingsBtn}
        target="_blank"
        href="/account-management/feed-settings"
        onClick={(e) => {
          logEvent(EventNames.relevanceScoreUpdateSettings, eventAttributes);
          e.stopPropagation();
          if (window.guestUser) {
            e.preventDefault();
            openGuestAccessPassPaywall();
          }
        }}
      >
        <ExternalLink className={css.externalLink} />
        Update your signal settings
      </Button>
    </div>
  );
}

function SignalsBlock({ name, signals }: { name: string; signals: SignalDto[] }) {
  return (
    <div aria-label={`${name} block`}>
      <div className={css.signalNameTitle}>{name}:</div>
      <ul className={css.signalsContainer}>
        {signals.map((s) => (
          <li
            key={s.id}
            className={classnames(
              `tag-${tagColourFromSignal(s.category) || "default"}`,
              tagHelperCss.nonTagCellTag,
              css.tagPill,
            )}
          >
            {s.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
