import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

export type AssignedRole = {
  roleName: string | null;
  accessorId: string;
  firstName: string;
  lastName: string;
  email: string;
};

type UpdateParams = {
  buyerListGuid: string;
  previousPermissions?: AssignedRole[];
  permissionUpdate: AssignedRole[];
};
export function useUpdateBuyerListPermissions(
  options?: UseMutationOptions<void, unknown, UpdateParams, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  return useMutation(
    ({ buyerListGuid, permissionUpdate }: UpdateParams) =>
      api.updateBuyerListPermissions({
        id: buyerListGuid,
        updateBuyerListPermissionsRequest: { updates: permissionUpdate },
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["buyerLists"]);
        logEvent(EventNames.buyerListRenamed, {
          "List id": variables.buyerListGuid,
          "Previous user settings": variables.previousPermissions?.map((perm) => ({
            "Permission level": perm.roleName,
            "User email": perm.email,
          })),
          "New user settings": variables.permissionUpdate.map((perm) => ({
            "Permission level": perm.roleName,
            "User email": perm.email,
          })),

          "Context source": "saved-buyers",
        });
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
