import React from "react";
import { hot } from "react-hot-loader/root";
import { Button } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import {
  DetailsContent,
  DetailsPage,
  DetailsRow,
  DetailsSection,
} from "components/app_layout/DetailsLayout";
import { AssignedNotices } from "components/notices/AssignedNotices";
import { NoticesSummaryQualification } from "components/notices/NoticesSummaryQualification";
import { generateNoticeSearchUrl } from "components/notices/utils";
import { ListsTable } from "../../components/notices/ListsTable";

import css from "./MyNoticesPage.module.scss";

const preQualifiedUrl = generateNoticeSearchUrl({
  procurementStageQualifications: [
    "pre_engage_to_do",
    "pre_engage_done",
    "pre_engage_not_relevant",
  ],
});

const tenderQualifiedUrl = generateNoticeSearchUrl({
  procurementStageQualifications: [
    "in_review",
    "bid_prep",
    "bid_submitted",
    "lost",
    "not_relevant",
    "won",
  ],
});

export function MyNoticesPage() {
  return (
    <DetailsPage>
      <DetailsContent>
        <h1 className={css.header}>My notices</h1>
        <DetailsRow>
          <DetailsSection
            title={<div className={css.title}>Tenders</div>}
            className={css.topRowItem}
            action={
              <Button type="link" href={tenderQualifiedUrl}>
                View All
              </Button>
            }
            aria-label="Tenders"
          >
            <NoticesSummaryQualification isPreEngagement={false} />
          </DetailsSection>
          <DetailsSection
            title={<div className={css.title}>Pre-engagement</div>}
            className={css.topRowItem}
            action={
              <Button type="link" href={preQualifiedUrl}>
                View All
              </Button>
            }
            aria-label="Pre-engagement"
          >
            <NoticesSummaryQualification isPreEngagement={true} />
          </DetailsSection>
          <AssignedNotices />
        </DetailsRow>
        <DetailsRow>
          <ListsTable />
        </DetailsRow>
      </DetailsContent>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(MyNoticesPage, {
    pageName: "Saved notices page",
  }),
);
