import React from "react";
import { Skeleton } from "antd5";

import { ListNewSavedViewsResponse, PinnedSavedViewDto } from "lib/generated/app-api";
import { SavedViewType } from "../saved_views_bar/types";
import { useMyFeedPageState } from "../useMyFeedPageState";
import PinnedViewsList from "./PinnedViewsList";
import { NoViewHeaderText, ViewHeaderText } from "./ViewHeaderText";
import ViewOptions from "./ViewOptions";

import css from "./ViewsContainer.module.scss";

const filteredAllViews = (
  allViews: ListNewSavedViewsResponse["savedViews"],
  pinnedViews: PinnedSavedViewDto[],
) => {
  const pinnedView = pinnedViews.map((view) => allViews.find((v) => v.id === view.savedViewId));
  return pinnedView.filter((view) => view !== undefined) as SavedViewType[];
};

function ViewsContainer() {
  const { allViews, pinnedViews, selectedView, isLoadingViews } = useMyFeedPageState();

  if (isLoadingViews) {
    return <Skeleton />;
  }

  if (!allViews || !pinnedViews) {
    return null;
  }

  return (
    <div className={css.viewsHeaderContainer}>
      <div className={css.viewsHeaderRow}>
        <div className={css.viewsHeaderText}>
          {selectedView ? <ViewHeaderText view={selectedView} /> : <NoViewHeaderText />}
        </div>
        <div className={css.viewsHeaderOptions}>
          <ViewOptions selectedView={selectedView} />
        </div>
      </div>
      <div className={css.pinnedViewsList}>
        <PinnedViewsList pinnedViews={filteredAllViews(allViews, pinnedViews)} />
      </div>
    </div>
  );
}

export default ViewsContainer;
