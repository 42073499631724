import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ListPermission } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, string, string];

export function useBuyerListPermissions(
  id: string,
  options?: UseQueryOptions<ListPermission[], unknown, ListPermission[], QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    ["buyerLists", id, "permissions"],
    async () => {
      const response = await api.getBuyerListPermissions({ id });
      return response.permissions;
    },
    options,
  );
}
