import { addGlobalEventProcessor, configureScope } from "@sentry/react";

import { getCSRFToken } from "./utils";

export class APIError extends Error {
  name = "APIError";

  constructor(
    message: string,
    private _url: string,
    private _statusCode: number,
    private _requestId: string | null,
    private _response: any | null,
  ) {
    super(message);

    Object.setPrototypeOf(this, APIError.prototype);
  }

  get url(): string {
    return this._url;
  }

  get requestId(): string | null {
    return this._requestId;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get response(): any {
    return this._response;
  }

  static async fromFetchResponse(url: string, response: Response): Promise<APIError> {
    let responsePayload = null;
    let responseMessage: string | null = null;
    // try to get the response data but ignore errors
    try {
      const responseBody = await response.text();
      try {
        responsePayload = JSON.parse(responseBody);
      } catch (_) {
        // no-op
      }
      responseMessage =
        responsePayload?.message || responseBody?.slice(0, 50) || "no message given";
    } catch (_) {
      responseMessage = "no message given";
    }

    const requestId = response.headers.get("X-Request-ID");

    return new APIError(
      // including the path in the message helps us to triage better in sentry and slack
      // (and ideally with sentry's deduplication too)
      `API call to ${url} failed with status ${response.status}: ${responseMessage}`,
      url,
      response.status,
      requestId,
      responsePayload,
    );
  }
}

export function registerSentryAPIErrorHandler(): void {
  addGlobalEventProcessor((event, hint) => {
    if (hint) {
      if (hint.originalException instanceof APIError) {
        const apiError = hint.originalException;

        if (!event.tags) {
          event.tags = {};
        }
        event.tags.url = apiError.url;
        event.tags.status_code = apiError.statusCode;
        if (apiError.requestId) {
          event.tags.request_id = apiError.requestId;
        }
      }
    }
    return event;
  });
}

type RequestOptions<RequestPayload> = {
  query?: Record<string, string | number>;
  body?: RequestPayload;
} & Pick<RequestInit, "keepalive">;

class API {
  private headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  private TRANSACTION_HEADER = "X-Transaction-ID";
  private SENTRY_TRANSACTION_TAG = "transaction_id";

  constructor(private endpoint: string) {
    const csrfToken = getCSRFToken();
    const authToken = window.authToken;
    if (csrfToken) {
      this.headers["X-CSRF-Token"] = csrfToken;
    }
    if (authToken) {
      this.headers["Authorization"] = `Bearer ${authToken}`;
    }
  }

  /**
   * fetch API wrapper with error handling.
   *
   * Converts HTTP errors to ApiError instances.
   *
   * Also throws on network errors.
   */

  private async fetchWrapper<RequestPayload, RequestResponse>(
    method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE",
    path: string,
    { query, body }: RequestOptions<RequestPayload> = {},
    processResponse: (response: Response) => Promise<RequestResponse>,
    noBodyResponse: () => RequestResponse,
  ): Promise<RequestResponse> {
    try {
      let url = `${this.endpoint}/${path}`;
      if (query) {
        // Types for URLSearchParams are not correct, we can safely use `any` here
        url += "?" + new URLSearchParams(query as any);
      }

      const transactionId = Math.random().toString(36).substr(2, 9);
      configureScope((scope) => {
        scope.setTag(this.SENTRY_TRANSACTION_TAG, transactionId);
      });
      const response = await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: { ...this.headers, [this.TRANSACTION_HEADER]: transactionId },
        credentials: "same-origin", // this is the default everywhere except Edge <17
      });
      if (response.status > 399) {
        switch (response.status) {
          case 401:
            window.location.reload(); // let the back end take user to log in page
            break;
          default:
            throw await APIError.fromFetchResponse(url, response);
        }
      } else if (response.status === 204) {
        // Let's assume that Response will include `null` if it's a possible response.
        // Otherwise all api calls would have signature `Response | null` which we don't want.
        return noBodyResponse();
      }
      return await processResponse(response);
    } catch (error) {
      // The error might be the APIError thrown above or a TypeError raised in case of network issues

      // TODO: Handle errors and rethrow
      if (error instanceof APIError) {
        console.error(error.message, error.statusCode, error.response);
      } else {
        console.error(error);
      }
      throw error;
    }
  }

  /**
   * Makes a request to the backend and returns a json object.
   */
  async fetchJSON<RequestPayload, APIResponse>(
    method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE",
    path: string,
    requestOptions: RequestOptions<RequestPayload> = {},
  ): Promise<APIResponse> {
    return this.fetchWrapper(
      method,
      path,
      requestOptions,
      (response) => response.json(),
      () => ({}) as APIResponse,
    );
  }

  /**
   * Makes a request to the backend and returns the response as a Blob object.
   */
  async fetchData<RequestPayload>(
    method: "GET" | "POST",
    path: string,
    requestOptions: RequestOptions<RequestPayload>,
  ): Promise<Blob> {
    return this.fetchWrapper(
      method,
      path,
      requestOptions,
      (response) => response.blob(),
      () => new Blob(),
    );
  }
}

export default API;
