import React, { useEffect } from "react";
import { FieldValues, useController } from "react-hook-form";
import { Select, SelectProps } from "antd5";
import { DefaultOptionType } from "antd5/lib/select";

import { InfoButton } from "components/actions/InfoButton";
import { countryCodes, countryToCountryCode } from "lib/data/optionItems";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { useSearchRegions } from "lib/hooks/api/useSearchRegion";
import { assertDefined } from "lib/utils";
import { Props } from "./Inputs";

import css from "./CountryRegionSelect.module.scss";

type CountryRegionProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & Props<T>;
export function CountryRegionSelect<T extends FieldValues>(props: CountryRegionProps<T>) {
  const { data: allRegions, isLoading } = useSearchRegions();
  const { field } = useController(props);
  const { data: signalSettings } = useSignalSettings({
    enabled: FeatureToggles.isEnabled(Feature.RELEVANT_REGION_DATA_FILTER),
  });

  const userCountryCodes = signalSettings?.countries?.map((countryName) =>
    countryToCountryCode(countryName),
  );

  // not ideal but typings aren't quite correct with generic field values
  const value = field.value && "selected" in field.value ? field.value.selected : [];

  useEffect(() => {
    const countries = (field.value && field.value.countries) || [];
    const regions = (field.value && field.value.regions) || [];

    // not ideal but typings aren't quite correct with generic field values
    onChange([...countries, ...regions] as unknown as T);

    // we only need to update this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allRegionsSet = React.useMemo(() => {
    return new Set(allRegions?.regions.map((r) => r.id));
  }, [allRegions?.regions]);

  const countryCodesSet = React.useMemo(() => {
    return new Set(countryCodes.map((c) => c.code));
  }, []);

  const filteredOptions = React.useMemo(() => {
    if (!allRegions) {
      // it's loading
      return [];
    }

    let ukCountry: DefaultOptionType | undefined;
    const countries: DefaultOptionType[] = [];

    for (const country of countryCodes) {
      if (country.code === "UK") {
        ukCountry = {
          label: CountryRegionOption(country.name, country.description),
          value: country.code,
        };
      } else {
        // do not include countries that don't match the country codes in user's signal settings
        if (userCountryCodes?.includes(country.code)) {
          countries.push({
            label: CountryRegionOption(country.name, country.description ?? "Country"),
            value: country.code,
          });
        }
      }
    }

    assertDefined(ukCountry);

    const regions = allRegions.regions
      .sort((a, b) => {
        if (a.sortOrder !== b.sortOrder) {
          return a.sortOrder - b.sortOrder; // Sort by sortOrder first
        } else {
          return a.name.localeCompare(b.name); // If sortOrder is the same, sort by region name
        }
      })
      .filter((region) => userCountryCodes?.includes(region.countryCode))
      .map((region) => ({
        label: CountryRegionOption(region.name, region.description),
        value: region.id,
      }));

    // we want to display UK first (if found in user's signal settings), then all regions and then all countries
    if (userCountryCodes?.includes("UK")) {
      return [ukCountry, ...regions, ...countries];
    }

    return [...regions, ...countries];
  }, [allRegions, userCountryCodes]);

  const options = React.useMemo(() => {
    if (!allRegions) {
      // it's loading
      return [];
    }

    let ukCountry: DefaultOptionType | undefined;
    const countries: DefaultOptionType[] = [];

    for (const country of countryCodes) {
      if (country.code === "UK") {
        ukCountry = {
          label: CountryRegionOption(country.name, country.description),
          value: country.code,
        };
      } else {
        countries.push({
          label: CountryRegionOption(country.name, country.description ?? "Country"),
          value: country.code,
        });
      }
    }
    assertDefined(ukCountry);

    const regions = allRegions.regions
      .sort((a, b) => {
        if (a.sortOrder !== b.sortOrder) {
          return a.sortOrder - b.sortOrder; // Sort by sortOrder first
        } else {
          return a.name.localeCompare(b.name); // If sortOrder is the same, sort by region name
        }
      })
      .map((region) => ({
        label: CountryRegionOption(region.name, region.description),
        value: region.id,
      }));

    // we want to display UK first, then all regions and then all countries
    return [ukCountry, ...regions, ...countries];
  }, [allRegions]);

  const onChange = (value: T) => {
    // split out the country + regions before submitting
    const regions = value.filter((r: string) => allRegionsSet.has(r));
    const countries = value.filter((c: string) => countryCodesSet.has(c));

    field.onChange({
      selected: value,
      countries: countries,
      regions: regions,
    });
  };

  return (
    <div className={css.container}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{props.label}</h3>
        <InfoButton
          note={
            <div style={{ textAlign: "center" }}>
              This is the location of the buyer's headquarters
            </div>
          }
          tooltipOverlayStyle={{ width: 200 }}
        />
      </label>
      <Select
        {...props}
        onChange={onChange}
        loading={isLoading}
        value={value}
        filterOption={(inputValue, option) => {
          if (option?.value && option.value) {
            const value = option.value.toString();
            const name =
              allRegions?.regions.find((x) => x.id === value) ||
              countryCodes.find((x) => x.code === value);

            if (name && name.name.toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }
          }
          return false;
        }}
        mode="multiple"
        placeholder="Select country or region"
        options={
          FeatureToggles.isEnabled(Feature.RELEVANT_REGION_DATA_FILTER) ? filteredOptions : options
        }
        optionFilterProp="label"
      />
    </div>
  );
}

function CountryRegionOption(name: string, description?: string) {
  return (
    <>
      <span>{name}</span>
      {description && <span className={css.optionDescription}>{description}</span>}
    </>
  );
}
