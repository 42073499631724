import React from "react";

import {
  PRE_ENGAGE_QUALIFICATION,
  TENDER_QUALIFICATION,
} from "components/record_qualifications/QualificationStatus";
import { numberSort, stringSort } from "lib/columnSort";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import { RecordSearchRequestDtoProcurementStageQualificationsEnum } from "lib/generated/app-api";
import {
  QualifcationStages,
  useQualificationStages,
} from "lib/hooks/api/notices/useQualificationStages";
import { EventNames, useTracking } from "lib/tracking";
import { generateNoticeSearchUrl } from "./utils";

type NoticesSummaryQualificationProps = {
  isPreEngagement: boolean;
};

type QualificationTableData = {
  status: React.ReactNode;
  count: number;
  stage: string;
};

const QUALIFICATION_COLS: ColumnType<QualificationTableData>[] = [
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    sorter: stringSort((r) => r.stage),
  },
  {
    title: "Notices",
    dataIndex: "count",
    key: "count",
    sorter: numberSort((r) => r.count),
  },
];

function getTableData(stages: QualifcationStages, isPreEngagement: boolean) {
  if (isPreEngagement) {
    return Object.entries(PRE_ENGAGE_QUALIFICATION)
      .filter(([key]) => key !== "unqualified")
      .map(([key, value]) => ({
        status: value,
        stage: key,
        count: stages.preEngagement.find((q) => q.stage === key)?.count || 0,
      }));
  }
  return Object.entries(TENDER_QUALIFICATION)
    .filter(([key]) => key !== "unqualified")
    .map(([key, value]) => ({
      status: value,
      stage: key,
      count: stages.tenders.find((q) => q.stage === key)?.count || 0,
    }));
}

export function NoticesSummaryQualification({ isPreEngagement }: NoticesSummaryQualificationProps) {
  const { data: qualificationStages, isError, isLoading } = useQualificationStages();

  const { logEvent } = useTracking();

  const onRowClick = ({ stage }: QualificationTableData) => ({
    onClick() {
      logEvent(EventNames.noticeListClicked, {
        "List type": "Qualified notices",
        "List ID": stage,
      });
      window.location.assign(
        generateNoticeSearchUrl({
          procurementStageQualifications: [
            stage as RecordSearchRequestDtoProcurementStageQualificationsEnum,
          ],
        }),
      );
    },
  });

  if (isLoading) {
    return <SkeletonTable pageSize={4} columns={QUALIFICATION_COLS} active />;
  }

  if (!qualificationStages) {
    return null;
  }

  const tableData = getTableData(qualificationStages, isPreEngagement);

  return (
    <Table
      columns={QUALIFICATION_COLS}
      dataSource={tableData}
      pagination={false}
      onRow={onRowClick}
      rowKey={(qualificationStage) => qualificationStage.stage}
      isError={isError}
    />
  );
}
