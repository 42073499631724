import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  KeywordsRecommendationsMutation,
  KeywordsRecommendationsMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const keywordsRecommendation = graphql(`
  mutation keywordsRecommendations($args: GenerateKeywordsRecommendationRequest!) {
    keywordsRecommendations(GenerateKeywordsRecommendationRequest: $args) {
      keywords
    }
  }
`);

export function useGenerateKeywordsRecommendation(
  options?: UseMutationOptions<
    KeywordsRecommendationsMutation,
    unknown,
    KeywordsRecommendationsMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(keywordsRecommendation, {
    ...options,
  });

  return { data: data?.keywordsRecommendations, ...rest };
}
