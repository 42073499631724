import stableJsonStringify from "fast-json-stable-stringify";

import { enforceSequentialResponse } from "lib/utils/enforceSequentialResponse";

export { OutOfOrderResponseError } from "lib/utils/enforceSequentialResponse";

/**
 * Returns a function that takes the same parameters as the input function,
 * but caches the results of invocations for exactly same arguments
 * (uses stableJsonStringify for comparing arguments).
 *
 * It only accepts asynchronous functions but makes sure that only one request
 * for the same arguments will be executed, even in case of races.
 */
function memoizeAsyncFunction<Params extends unknown[], Ret>(
  asyncFunction: (...args: Params) => Promise<Ret>,
): typeof asyncFunction {
  const cachedResponses = new Map<string, Promise<Ret>>();

  return (...args: Params): Promise<Ret> => {
    const hash = stableJsonStringify(args);
    const cached = cachedResponses.get(hash);
    if (cached) {
      return cached;
    }
    const responsePromise = asyncFunction(...args).catch((err: Error) => {
      // In case of errors we want to reset the cached promise so we can retry
      // with the same parameters
      if (cachedResponses.get(hash) === responsePromise) {
        cachedResponses.delete(hash);
      }
      throw err;
    });
    cachedResponses.set(hash, responsePromise);
    return responsePromise;
  };
}

/**
 * Caches results & ensures sequential order of async function calls.
 *
 * If you just need one of the behaviours, you can use the above functions directly.
 */
export default class CachedSequentialRequestHandler<Params extends unknown[], Ret> {
  fetchFunction: (...args: Params) => Promise<Ret>;

  constructor(fetchFunction: (...args: Params) => Promise<Ret>) {
    this.fetchFunction = enforceSequentialResponse(memoizeAsyncFunction(fetchFunction));
  }

  /**
   * @throws {OutOfOrderResponseError} Must make sure it is handled in an appropriate manner by the caller
   */
  async makeRequest(...args: Params): Promise<Ret> {
    return this.fetchFunction(...args);
  }
}
