import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { DescribeNoticeFieldsResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string];

export function useViewNoticeFieldsProvider(
  options?: UseQueryOptions<
    DescribeNoticeFieldsResponse,
    unknown,
    DescribeNoticeFieldsResponse,
    QueryKey
  >,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["integrationNoticeFields"],
    () => {
      return api.describeNoticeFields();
    },
    options,
  );
}
