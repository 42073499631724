import * as React from "react";
import { Button } from "antd5";
import { SizeType } from "antd5/lib/config-provider/SizeContext";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import RecordStage from "lib/core_components/RecordStage";
import { useSubscription } from "lib/providers/Subscription";
import { Status, TenderStage } from "lib/types/models";

import css from "./RecordStageButtons.module.scss";

type Props = {
  onChange: (values: Status[]) => void;
  id?: string;
  className?: string;
  checkedStages: Status[];
  availableStages: Status[];
  disabledStages?: Status[];
  size?: SizeType;
  tooltipPlacement?: TooltipProps["placement"];
};

// Because this is used within Form.Item we have to wrap it in a forwardRef
const RecordStageButtons = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      id,
      className,
      checkedStages,
      availableStages,
      size,
      tooltipPlacement,
      disabledStages,
    },
    ref,
  ) => {
    const subscription = useSubscription();
    const hasAwards = subscription.hasDataTypes("AWARDS");

    const checkedValues = React.useMemo(() => {
      return hasAwards ? checkedStages : checkedStages.filter((s) => s !== TenderStage.AWARDED);
    }, [checkedStages, hasAwards]);

    const handleSelect = React.useCallback(
      (stage) => {
        if (checkedValues.includes(stage)) {
          onChange(checkedValues.filter((v) => v !== stage));
        } else onChange([...checkedValues, stage]);
      },
      [checkedValues, onChange],
    );

    return (
      <div
        className={classnames(css.recordStageButtonContainer, className, {
          [css.small]: size === "small",
        })}
        id={id}
        ref={ref}
      >
        {availableStages.map((s) => {
          const paywalledAwards = s === TenderStage.AWARDED && !hasAwards;
          return (
            <Button
              size={size}
              key={s}
              className={classnames({
                [css.selected]: checkedValues.includes(s),
                [css.paidTagButton]: paywalledAwards,
              })}
              onClick={() => {
                if (!(disabledStages?.includes(s) || paywalledAwards)) handleSelect(s);
              }}
              value={s}
            >
              <RecordStage
                tooltipPlacement={tooltipPlacement}
                className={css.stage}
                stage={s as TenderStage}
                preview={paywalledAwards}
              />
            </Button>
          );
        })}
      </div>
    );
  },
);

export default RecordStageButtons;
