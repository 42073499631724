/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HierarchicalCandidateCandidateAction } from './HierarchicalCandidateCandidateAction';
import {
    HierarchicalCandidateCandidateActionFromJSON,
    HierarchicalCandidateCandidateActionFromJSONTyped,
    HierarchicalCandidateCandidateActionToJSON,
} from './HierarchicalCandidateCandidateAction';

/**
 * 
 * @export
 * @interface HierarchicalCandidate
 */
export interface HierarchicalCandidate {
    /**
     * 
     * @type {string}
     * @memberof HierarchicalCandidate
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof HierarchicalCandidate
     */
    multipleValidBuyersExist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HierarchicalCandidate
     */
    relationToAnchor?: string;
    /**
     * 
     * @type {HierarchicalCandidateCandidateAction}
     * @memberof HierarchicalCandidate
     */
    candidateAction?: HierarchicalCandidateCandidateAction;
}

/**
 * Check if a given object implements the HierarchicalCandidate interface.
 */
export function instanceOfHierarchicalCandidate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function HierarchicalCandidateFromJSON(json: any): HierarchicalCandidate {
    return HierarchicalCandidateFromJSONTyped(json, false);
}

export function HierarchicalCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HierarchicalCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'multipleValidBuyersExist': !exists(json, 'multiple_valid_buyers_exist') ? undefined : json['multiple_valid_buyers_exist'],
        'relationToAnchor': !exists(json, 'relation_to_anchor') ? undefined : json['relation_to_anchor'],
        'candidateAction': !exists(json, 'candidate_action') ? undefined : HierarchicalCandidateCandidateActionFromJSON(json['candidate_action']),
    };
}

export function HierarchicalCandidateToJSON(value?: HierarchicalCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'multiple_valid_buyers_exist': value.multipleValidBuyersExist,
        'relation_to_anchor': value.relationToAnchor,
        'candidate_action': HierarchicalCandidateCandidateActionToJSON(value.candidateAction),
    };
}

