import React, { useCallback, useMemo, useRef, useState } from "react";
import { getElementAtEvent, Line } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import {
  Chart,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { color } from "chart.js/helpers";
import { DateTime, DurationLikeObject } from "luxon";

import { LegendClickHandler } from "lib/chartjs/plugins/LegendClickHandler";
import { StotlesChartStyle } from "lib/chartjs/plugins/StotlesChartStyle";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { formatShortAmount } from "lib/utils";
import { NumberDataPointValue, SpendTooltip } from "./SpendTooltip";

import css from "./SpendChart.module.scss";

export type Dataset = {
  label: string;
  data: SpendChartData[];
  id: string;
};

type ChartProps = {
  data: Dataset[];
  dateUnit: "month" | "quarter" | "year";
  onDatasetsClick?: (dataset: Dataset[]) => void;
  isLoading?: boolean;
};

type SpendChartData = {
  date: string;
  total: number;
};

Chart.register(
  TimeSeriesScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LegendClickHandler,
  StotlesChartStyle,
);

export default function SpendChart({ data, dateUnit, onDatasetsClick, isLoading }: ChartProps) {
  const [tooltipProps, setTooltipProps] = useState<{
    top: number;
    left: number;
    show: boolean;
    dataPoints?: TooltipItem<"line">[];
  }>({
    top: 0,
    left: 0,
    show: false,
  });

  const chartRef = useRef<ChartJSOrUndefined<"line">>(undefined);
  const setActiveDatasets = useCallback((activeDatasets: number[]) => {
    const chart = chartRef.current;
    if (!chart) return;

    const datasets = chart.data.datasets;
    let updated = false;
    for (let i = 0; i < datasets.length; i++) {
      const currentColour = datasets[i].backgroundColor || datasets[i].borderColor;

      if (!currentColour) continue;

      if (activeDatasets.length && !activeDatasets.includes(i)) {
        const newColour = color(currentColour as string)
          .alpha(0.2)
          .rgbString();
        if (datasets[i].borderColor !== newColour) {
          datasets[i].borderColor = newColour;
          updated = true;
        }
        if (datasets[i].backgroundColor !== newColour) {
          datasets[i].backgroundColor = newColour;
          updated = true;
        }
      } else {
        const newColour = color(currentColour as string)
          .alpha(1)
          .rgbString();
        if (datasets[i].borderColor !== newColour) {
          datasets[i].borderColor = newColour;
          updated = true;
        }
        if (datasets[i].backgroundColor !== newColour) {
          datasets[i].backgroundColor = newColour;
          updated = true;
        }
      }
    }
    if (updated) {
      chart.update();
    }
  }, []);

  const options: ChartOptions<"line"> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: true,
          position: "right",
          onHover: function (evt, legendItem) {
            const chart = chartRef.current;
            if (
              !chart ||
              legendItem.datasetIndex === undefined ||
              !chart.isDatasetVisible(legendItem.datasetIndex)
            ) {
              return;
            }
            const activeDatasets =
              legendItem.datasetIndex !== undefined ? [legendItem.datasetIndex] : [];
            setActiveDatasets(activeDatasets);
          },
          onLeave: function () {
            setActiveDatasets([]);
          },
          labels: {
            useBorderRadius: true,
            borderRadius: 4,
            boxWidth: 12,
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            const position = context.chart.canvas.getBoundingClientRect();

            setTooltipProps((o) => ({
              ...o,
              top: position.top + window.pageYOffset + context.tooltip.caretY / 2 + 25,
              left: position.left + window.pageXOffset + context.tooltip.caretX + 25,
              show: !!context.tooltip.opacity,
              dataPoints: context.tooltip.dataPoints,
            }));
          },
        },
      },
      onHover: function (evt, activeElements) {
        if (evt.native?.target)
          (evt.native.target as HTMLElement).style.cursor = activeElements?.length
            ? "pointer"
            : "default";
        const activeDatasets = activeElements.map((el) => el.datasetIndex);
        setActiveDatasets(activeDatasets);
      },
      maintainAspectRatio: false,
      elements: {
        point: {
          // if there is only one data point, show a circle as there is no line
          radius: data.length && data[0].data.length > 1 ? 0 : 2,
          hoverRadius: 5,
          hitRadius: 25,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        x: {
          type: "time" as const,
          time: {
            unit: dateUnit,
            displayFormats: {
              quarter: "Qq yyyy",
              month: "MMM yyyy",
              year: "yyyy",
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            align: "center",
          },
        },
        y: {
          title: {
            display: true,
            text: "Value GBP",
          },
          ticks: {
            callback: (value: string | number) => formatShortAmount(value),
          },
          min: 0,
        },
      },
    }),
    [data, dateUnit, setActiveDatasets],
  );

  const onClick = useCallback(
    (evt) => {
      const chart = chartRef.current;
      if (!chart || !onDatasetsClick) {
        return;
      }
      const elements = getElementAtEvent(chart, evt);

      onDatasetsClick(elements.map((element) => data[element.datasetIndex]));
    },
    [data, onDatasetsClick],
  );

  const formatDataPointValue = useCallback(
    (dataPoint: TooltipItem<"line" | "bar">) => (
      <NumberDataPointValue dataPoint={dataPoint} currency="GBP" />
    ),
    [],
  );

  const formatDataPointLabel = useCallback(
    (dataPoint: TooltipItem<"line" | "bar">) => {
      if (options.scales?.x?.type !== "time" || !options.scales?.x?.time?.unit) {
        return dataPoint.label;
      }

      const format =
        options.scales.x?.time?.displayFormats?.[options.scales?.x?.time?.unit] ?? "yyyy-MM-dd";
      return DateTime.fromMillis(dataPoint.parsed.x).toFormat(format);
    },

    [options.scales?.x],
  );

  const chartData = useMemo(() => createChartData(data, dateUnit), [data, dateUnit]);
  return (
    <div className={css.chartContainer}>
      {isLoading || !chartData ? (
        <CentredSpinner />
      ) : (
        <>
          <Line data={chartData} options={options} onClick={onClick} ref={chartRef} />
          <SpendTooltip
            {...tooltipProps}
            formatDataPointValue={formatDataPointValue}
            formatDataPointLabel={formatDataPointLabel}
            clickMessage="Click to filter"
          />
        </>
      )}
    </div>
  );
}

function createChartData(
  chartData: { label: string; data: SpendChartData[]; id: string }[],
  interval: keyof DurationLikeObject,
) {
  let minDate: DateTime | undefined = undefined;
  let maxDate: DateTime | undefined = undefined;
  //get the min and max date
  for (const dataset of chartData)
    for (const d of dataset.data) {
      const date = DateTime.fromISO(d.date);
      if (!minDate || date < minDate) {
        minDate = date;
      }
      if (!maxDate || date > maxDate) {
        maxDate = date;
      }
    }
  const dates: string[] = [];
  if (minDate && maxDate) {
    for (let date = minDate; date <= maxDate; date = date.plus({ [interval]: 1 })) {
      dates.push(date.toString());
    }
  }
  return {
    labels: dates,
    datasets: chartData.map((dataset) => {
      const mapData = {} as Record<string, SpendChartData>;

      for (const d of dataset.data) {
        const date = DateTime.fromISO(d.date).toFormat("yyyy-MM-dd");
        mapData[date] = d;
      }

      const data = dates.map((date) => {
        const d = mapData[DateTime.fromISO(date).toFormat("yyyy-MM-dd")] || { date, total: 0 };
        return d;
      });
      return {
        label: dataset.label,
        data: data.map((d) => d.total),
      };
    }),
  };
}
