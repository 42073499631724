import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { createAlertsUpdatedEvent } from "components/my_feed/tracking";
import { SavedViewType } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { AlertModificationType, EventNames, useTracking } from "lib/tracking";

type Params = {
  view: SavedViewType;
  alertId?: string;
  alertFrequency: "DAILY" | "WEEKLY" | "NEVER";
};

type CreateAlert = {
  type: AlertModificationType.created;
  alertFrequency: "DAILY" | "WEEKLY" | "NEVER";
};
type UpdateAlert = {
  type: AlertModificationType.frequency;
  alertFrequency: "DAILY" | "WEEKLY";
  alertId: string;
};
type DeleteAlert = { type: AlertModificationType.deleted; alertId: string };
type AlertModification = CreateAlert | UpdateAlert | DeleteAlert;
function calculateAlertModification(
  alertFrequency: "DAILY" | "WEEKLY" | "NEVER",
  alertId?: string,
): AlertModification {
  if (!alertId) {
    return { type: AlertModificationType.created, alertFrequency };
  }

  if (alertFrequency === "DAILY" || alertFrequency === "WEEKLY") {
    return { type: AlertModificationType.frequency, alertFrequency, alertId };
  }

  return { type: AlertModificationType.deleted, alertId };
}

export function useUpdateAlerts(options?: UseMutationOptions<unknown, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  return useMutation(
    async ({ view, alertId, alertFrequency }) => {
      if (!view.id) {
        throw new Error("View id is required to update alerts");
      }

      const modification = calculateAlertModification(alertFrequency, alertId);

      if (modification.type === AlertModificationType.created) {
        return api.createLeadSubscriptionContent({
          createLeadSubscriptionContentRequest: {
            frequency: alertFrequency,
            resourceId: view.id,
            resourceType: "SavedView",
          },
        });
      }

      if (modification.type === AlertModificationType.frequency) {
        return api.updateLeadSubscriptionContent({
          id: modification.alertId,
          updateLeadSubscriptionContentRequest: { frequency: modification.alertFrequency },
        });
      }

      return api.deleteLeadSubscriptionContent({
        deleteLeadSubscriptionContentRequest: { ids: [modification.alertId] },
      });
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["leadSubscriptionContents"]);
        await queryClient.invalidateQueries(["leadSubscriptions"]);
        const modification = calculateAlertModification(vars.alertFrequency, vars.alertId);

        logEvent(
          EventNames.alertModified,
          createAlertsUpdatedEvent(
            vars.view.name,
            modification.type,
            vars.alertFrequency,
            vars.view.id,
          ),
        );

        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
