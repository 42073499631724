import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { UnsubscribeRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useLeadSubscriptionUnsubscribe(
  options?: UseMutationOptions<void, unknown, UnsubscribeRequest, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation((req: UnsubscribeRequest) => api.unsubscribe(req), {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["leadSubscriptions"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
}
