import { PrimaryTab, SecondaryTab, TabConfigType } from "./types";

export const labelledRoutes: Record<PrimaryTab, string> & Record<SecondaryTab, string> = {
  "/": "Summary tab",
  "/contacts": "Contacts tab",
  "/contacts/procurement": "Procurement contacts",
  "/activity": "Activity",
  "/activity/open_opportunities": "Activity: Open opportunities",
  "/activity/upcoming_expiries": "Activity: Upcoming contract expiries",
  "/activity/all": "Activity: All activity",
  "/activity/awards": "Activity: Awards",
  "/suppliers": "Supplier tab",
  "/suppliers/partners": "Suppliers: Partners",
  "/suppliers/competitors": "Suppliers: Competitors",
  "/documents": "Document tab",
  "/document": "Document tab",
  "/frameworks": "Frameworks",
};

const isPrimaryTab = (tab: string): tab is PrimaryTab => {
  return Object.values(PrimaryTab).some((path) => path === tab);
};

export const getTabConfigFromLocation = (loc: string): TabConfigType => {
  const locWithoutTrailingSlash = loc === "/" ? loc : loc.match(/(.+)\/$/)?.[1] || loc;

  if (isPrimaryTab(locWithoutTrailingSlash)) {
    return { primary: locWithoutTrailingSlash, secondary: null };
  } else {
    let primary: PrimaryTab;

    switch (locWithoutTrailingSlash) {
      case SecondaryTab.OPEN_OPPORTUNITIES:
      case SecondaryTab.UPCOMING_EXPIRIES:
      case SecondaryTab.ALL_RECORDS:
      case SecondaryTab.AWARDS:
        primary = PrimaryTab.PROCUREMENT_ACTIVITY;
        break;
      case SecondaryTab.PARTNERS:
      case SecondaryTab.COMPETITORS:
        primary = PrimaryTab.SUPPLIERS;
        break;
      case SecondaryTab.CONTACTS:
      case SecondaryTab.PROCUREMENT:
        primary = PrimaryTab.CONTACTS;
        break;
      default:
        return { primary: PrimaryTab.SUMMARY, secondary: null };
    }
    return { primary, secondary: locWithoutTrailingSlash };
  }
};
