import { SupplierColumns } from "components/suppliers/utils";
import { ColumnSetting } from "lib/types/models";

export const DEFAULT_SUPPLIER_COLUMNS: ColumnSetting<SupplierColumns>[] = [
  { title: "Name", field: "name", disabled: true },
  { title: "Lots", field: "frameworkLots" },
  { title: "Last activity", field: "frameworkLastActivity" },
  { title: "Call-offs", field: "frameworkCallOffs" },
  { title: "Is SME", field: "sme" },
];

export const ALL_SUPPLIER_COLUMNS: ColumnSetting<SupplierColumns>[] = [
  ...DEFAULT_SUPPLIER_COLUMNS,
  { title: "Country", field: "country" },
];
