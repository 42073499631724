import * as React from "react";

import useAsync from "lib/hooks/useAsync";
import { LeadSubscriptions, LeadSupscriptionUpdate } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { LeadSubscriptionType, SavedView, UserListSummary } from "lib/types/models";

type EnhancedView = SavedView & {
  guid: string;
};

type NotificationSettings =
  | {
      savedViews: undefined;
      userLists: undefined;
      subscriptions: undefined;
      reloadSubscription: () => Promise<void>;
      updateSubscription: (subscription: LeadSupscriptionUpdate, id: string) => Promise<void>;
      createSubscription: (
        subscription: LeadSupscriptionUpdate & { type: LeadSubscriptionType },
      ) => Promise<void>;
      deleteSubscription: (subscriptionId: string) => Promise<void>;
      status: "error" | "loading";
    }
  | {
      savedViews: EnhancedView[];
      userLists: UserListSummary[];
      subscriptions: LeadSubscriptions;
      reloadSubscription: () => Promise<void>;
      updateSubscription: (subscription: LeadSupscriptionUpdate, id: string) => Promise<void>;
      createSubscription: (
        subscription: LeadSupscriptionUpdate & { type: LeadSubscriptionType },
      ) => Promise<void>;
      deleteSubscription: (subscriptionId: string) => Promise<void>;
      status: "reloading" | "ready";
    };

type NotificationSettingsProviderProps = {
  children?: React.ReactNode;
};

function NotificationSettingsProvider({
  children,
}: NotificationSettingsProviderProps): JSX.Element {
  const api = useStotlesApi();
  //TODO fix hook so it will use callback within
  const getSavedViews = React.useCallback(async () => {
    const { saved_views } = await api.getAvailableSavedViews();
    return saved_views.map((sv) => ({ ...sv, guid: sv.id }));
  }, [api]);

  const getUserLists = React.useCallback(async () => {
    const { user_lists } = await api.getUserLists();
    return user_lists;
  }, [api]);

  const getSubscriptions = React.useCallback(async () => {
    return await api.getLeadSubscriptions();
  }, [api]);

  const updateSubscription = React.useCallback(
    async (subscription, id) => {
      return api.updateLeadSubscription(subscription, id);
    },
    [api],
  );

  const createSubscription = React.useCallback(
    async (subscription) => {
      return api.createLeadSubscription(subscription);
    },
    [api],
  );

  const deleteSubscription = React.useCallback(
    async (subscriptionId) => {
      return api.deleteLeadSubscription(subscriptionId);
    },
    [api],
  );

  const userLists = useAsync(getUserLists);
  const savedViews = useAsync(getSavedViews);
  const subscriptions = useAsync(getSubscriptions);

  const reloadSubscription = React.useCallback(async () => {
    subscriptions.run();
  }, [subscriptions]);

  const value = React.useMemo<NotificationSettings>(() => {
    if (
      savedViews.status === "error" ||
      userLists.status === "error" ||
      subscriptions.status === "error"
    ) {
      return {
        savedViews: undefined,
        userLists: undefined,
        subscriptions: undefined,
        reloadSubscription,
        updateSubscription,
        createSubscription,
        deleteSubscription,
        status: "error",
      };
    }
    if (
      savedViews.status === "loading" ||
      userLists.status === "loading" ||
      subscriptions.status === "loading"
    ) {
      return {
        savedViews: undefined,
        userLists: undefined,
        subscriptions: undefined,
        reloadSubscription,
        status: "loading",
        updateSubscription,
        createSubscription,
        deleteSubscription,
      };
    }

    return {
      savedViews: savedViews.value,
      userLists: userLists.value,
      subscriptions: subscriptions.value,
      status: subscriptions.status,
      reloadSubscription,
      updateSubscription,
      createSubscription,
      deleteSubscription,
    };
  }, [
    reloadSubscription,
    savedViews,
    subscriptions,
    userLists,
    updateSubscription,
    createSubscription,
    deleteSubscription,
  ]);
  return (
    <NotificationSettingsContext.Provider value={value}>
      {children}
    </NotificationSettingsContext.Provider>
  );
}

const NotificationSettingsContext = React.createContext<NotificationSettings | undefined>(
  undefined,
);

export function useNotificationSettings(): NotificationSettings {
  const context = React.useContext(NotificationSettingsContext);
  if (context === undefined) {
    throw new Error("useNotificationSettings must be within NotificationSettingsProvider");
  }
  return context;
}

export default NotificationSettingsProvider;
