import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type Props = { notificationsEnabled?: boolean; sendAppActivityNotifications?: boolean };

export function useUpdateNotificationSettings(
  options?: UseMutationOptions<unknown, unknown, Props, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ notificationsEnabled, sendAppActivityNotifications }: Props) => {
      return api.updateNotificationSettings({
        updateNotificationSettingsRequest: {
          notificationsEnabled: notificationsEnabled,
          sendAppActivityNotifications: sendAppActivityNotifications,
        },
      });
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["notificationSettings"]);
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
