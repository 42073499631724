/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSearchQuery } from './NoticeSearchQuery';
import {
    NoticeSearchQueryFromJSON,
    NoticeSearchQueryFromJSONTyped,
    NoticeSearchQueryToJSON,
} from './NoticeSearchQuery';

/**
 * 
 * @export
 * @interface AnalyseNoticeSearchRequest
 */
export interface AnalyseNoticeSearchRequest {
    /**
     * 
     * @type {NoticeSearchQuery}
     * @memberof AnalyseNoticeSearchRequest
     */
    query: NoticeSearchQuery;
}

/**
 * Check if a given object implements the AnalyseNoticeSearchRequest interface.
 */
export function instanceOfAnalyseNoticeSearchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "query" in value;

    return isInstance;
}

export function AnalyseNoticeSearchRequestFromJSON(json: any): AnalyseNoticeSearchRequest {
    return AnalyseNoticeSearchRequestFromJSONTyped(json, false);
}

export function AnalyseNoticeSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyseNoticeSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': NoticeSearchQueryFromJSON(json['query']),
    };
}

export function AnalyseNoticeSearchRequestToJSON(value?: AnalyseNoticeSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': NoticeSearchQueryToJSON(value.query),
    };
}

