import React, { useMemo } from "react";
import { Empty, Skeleton, Tabs, TabsProps } from "antd5";
import { Link, Redirect } from "wouter";

import { BackToLink, TextLink } from "components/actions/Links";
import { DetailsHeader, DetailsPage, DetailsSection } from "components/app_layout/DetailsLayout";
import { PageHeader } from "components/app_layout/Typography";
import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { FilterableNoticeTable } from "components/notices/FilterableNoticeTable";
import { NoticeFilters } from "components/notices/utils";
import { BuyerSupplierChart } from "components/spend_data/BuyerSupplierChart";
import TransactionsTable from "components/spend_data/TransactionsTable";
import RedactedWrapper, { RedactedLink } from "lib/core_components/RedactedWrapper";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { useBuyers } from "lib/hooks/api/buyer/useBuyers";
import { useSupplier } from "lib/hooks/api/suppliers/useSupplier";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { usePageView } from "lib/tracking";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { BuyerDetails, BuyerSummary } from "lib/types/models";

import css from "./SupplierBuyerRelationshipPage.module.scss";

type BuyerSupplierRelationshipPageProps =
  | {
      buyer: BuyerDetails;
      supplierGuid?: never;
      signalId: string;
      isPartner?: boolean;
      isCompetitor?: boolean;
    }
  | {
      buyer: BuyerDetails;
      supplierGuid?: string;
      signalId?: never;
      isPartner?: boolean;
      isCompetitor?: boolean;
    };

export function BuyerSupplierRelationshipPage({
  buyer,
  signalId,
  supplierGuid,
  isPartner,
  isCompetitor,
}: BuyerSupplierRelationshipPageProps) {
  const signals = useSignalSettings();
  const supplier = useSupplier(supplierGuid, {
    enabled: !!supplierGuid,
  });
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);

  // A failsafe just in case this somehow happens
  if (signalId && enableNewSupplierFilter && window.currentUser?.use_supplier_name === false) {
    return (
      <Redirect
        to={`/buyers/${buyer.id}/suppliers/${isCompetitor ? "competitors" : "partners"}`}
        replace={true}
      />
    );
  }

  const supplierName = signalId
    ? signals.data?.signals.find((s) => s.id === signalId)?.name
    : supplier.data?.name;

  const backLink = (
    <BackToLink className={css.backToLink} to="/">
      Back to {buyer.name}
    </BackToLink>
  );
  const supplierLink = isCompetitor
    ? `/competitors/${supplierName}`
    : isPartner
      ? `/partners/${supplierName}`
      : `/suppliers/${supplierGuid}`;
  const header = (
    <RedactedWrapper
      requiredDataType="SUPPLIERS"
      redactContent={
        <>
          Activity between <Link to="/">{buyer.name}</Link> and{" "}
          <RedactedLink textToRedact={supplierName ?? ""} />
        </>
      }
    >
      <PageHeader>
        Activity between <Link to="/">{buyer.name}</Link> and{" "}
        <TextLink to={supplierLink}>{supplierName}</TextLink>
      </PageHeader>
    </RedactedWrapper>
  );

  return (
    <BuyerSupplierRelationshipContent
      buyer={buyer}
      supplierGuid={supplierGuid}
      signalId={signalId}
      backLink={backLink}
      header={header}
    />
  );
}

type SupplierBuyerRelationshipPageProps = {
  supplierName: string;
  supplierGuid?: string;
  buyerGuid: string;
};

function SupplierBuyerRelationshipPage({
  buyerGuid,
  supplierName,
  supplierGuid,
}: SupplierBuyerRelationshipPageProps) {
  const { isLoading, data: buyers } = useBuyers({ guids: [buyerGuid] });

  const signals = useSignalSettings();
  const signal = signals.data?.signals.find((s) => s.name === supplierName);

  const buyer = buyers?.results[0];

  if (isLoading) {
    return <Skeleton />;
  }

  if (!buyer) {
    return (
      <DetailsPage>
        <div className={css.content}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      </DetailsPage>
    );
  }

  const header = (
    <>
      Activity between <Link to="/">{supplierName}</Link> and{" "}
      <TextLink to={`/buyers/${buyer.guid}`}>{buyer.name}</TextLink>
    </>
  );
  const backLink = (
    <BackToLink to="/" className={css.backToLink}>
      Back to {supplierName}
    </BackToLink>
  );

  return (
    <BuyerSupplierRelationshipContent
      buyer={buyer}
      backLink={backLink}
      header={header}
      signalId={signal?.id}
      supplierGuid={supplierGuid}
    />
  );
}

type BuyerSupplierRelationshipContentProps = {
  buyer: BuyerSummary;
  supplierGuid?: string;
  signalId?: string;
  backLink: React.ReactNode;
  header: React.ReactNode;
};

function BuyerSupplierRelationshipContent({
  buyer,
  supplierGuid,
  signalId,
  backLink,
  header,
}: BuyerSupplierRelationshipContentProps) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const pageParams = useMemo(
    () => ({
      buyerGuid: buyer.guid,
      supplierGuid,
      signalId,
    }),
    [buyer.guid, supplierGuid, signalId],
  );
  usePageView("BuyerSupplierRelationshipPage", pageParams);

  const signals = useSignalSettings();

  const supplierName = supplierGuid
    ? signals.data?.signals.find((s) => s.id === supplierGuid)?.name
    : signalId && signals.data?.signals.find((s) => s.id === signalId)?.name;

  const noticeFilters: NoticeFilters = {
    ...EMPTY_FILTERS,
    buyers: [buyer.guid],
    signals: signalId ? [signalId] : [],
    supplierGuids: supplierGuid ? [supplierGuid] : undefined,
    supplierMentionType:
      enableNewSupplierFilter && window.currentUser?.use_supplier_name === false
        ? SupplierMentionType.DirectlyAwarded
        : undefined,
  };

  const tabs: TabsProps["items"] = [
    {
      key: "notices",
      label: "All notices",
      children: (
        <FilterableNoticeTable
          defaultFilters={noticeFilters}
          hiddenSections={["buyers", "suppliers"]}
          defaultColumns={["title", "expiry_date", "value", "published_date", "relevance_score"]}
        />
      ),
    },
    {
      key: "transactions",
      label: "All transactions",
      children: (
        <TransactionsTable
          filters={{
            supplierGuids: supplierGuid ? [supplierGuid] : undefined,
            buyerIds: [buyer.guid],
            supplierNames: supplierName ? [supplierName] : undefined,
          }}
        />
      ),
    },
  ];

  return (
    <DetailsPage>
      <DetailsHeader>
        {backLink}
        <PageHeader>{header}</PageHeader>
      </DetailsHeader>
      <div className={css.content}>
        <DetailsSection title="Invoiced Spend vs Notices Over Time" contentClassName={css.chart}>
          {supplierGuid ? (
            <BuyerSupplierChart supplierGuid={supplierGuid} buyer={buyer} />
          ) : (
            signalId && <BuyerSupplierChart signalId={signalId} buyer={buyer} />
          )}
        </DetailsSection>
        <Tabs items={tabs} />
      </div>
    </DetailsPage>
  );
}

export default SupplierBuyerRelationshipPage;
