import * as React from "react";
import { hot } from "react-hot-loader/root";
import classnames from "classnames";
import { Redirect, Route, Router, Switch, useLocation } from "wouter";

import { BackToLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import BuyerTabs from "components/relationship_profiles/BuyerTabs";
import HeaderStatisticCards from "components/relationship_profiles/HeaderStatisticCards";
import NoticesTabs from "components/relationship_profiles/NoticesTabs";
import {
  NOTICE_LABELS_BY_TYPE,
  WrappedRecordViewer,
} from "components/relationship_profiles/RecordViewerOverlay";
import RelationshipProfileFilter from "components/relationship_profiles/RelationshipProfileFilter";
import {
  fromUrlParams,
  getTabConfigFromLocation,
  labelledRoutes,
  toUrlParams,
} from "components/relationship_profiles/routingUtils";
import SupplierRelationshipsTabs from "components/relationship_profiles/SupplierRelationshipTabs";
import {
  PrimaryTab,
  ProfileType,
  SecondaryTab,
  SupplierRelationshipFilter,
} from "components/relationship_profiles/types";
import { EventNames, logEvent } from "lib/tracking";
import { useUrlParamsTracker } from "lib/urlParamsTracker";
import { capitalize } from "lib/utils";
import SupplierBuyerRelationshipPage from "./SupplierBuyerRelationshipPage";

import css from "./SupplierRelationshipPage.module.scss";

const signalCategoryBaseURL = {
  [ProfileType.PARTNER]: "/partners",
  [ProfileType.COMPETITOR]: "/competitors",
};

type SupplierRelationshipProps = {
  signal_category: ProfileType;
  signal_name: string;
};

function SupplierRelationshipPageWrapper(props: SupplierRelationshipProps) {
  const basePath = signalCategoryBaseURL[props.signal_category];
  const encodedSignal = encodeURIComponent(props.signal_name);

  const renderRecordViewer = React.useCallback(
    ({ noticeType }: { noticeType: keyof typeof NOTICE_LABELS_BY_TYPE }) => {
      return (
        <ProfileTypeContext.Provider
          value={{
            signalCategory: props.signal_category,
            signalName: props.signal_name,
          }}
        >
          <WrappedRecordViewer
            signalCategory={props.signal_category}
            signalName={props.signal_name}
            noticeType={noticeType}
          />
        </ProfileTypeContext.Provider>
      );
    },
    [props],
  );

  return (
    <Router base={`${basePath}/${encodedSignal}`}>
      <Switch>
        {/* procurement notice viewer */}
        <Route path="/procurement_notices/:noticeType">{renderRecordViewer}</Route>

        <Route path="/buyers/saved_buyers">
          <SupplierRelationshipPage {...props} />
        </Route>

        <Route<{ buyerGuid: string }> path="/buyer/:buyerGuid">
          {({ buyerGuid }) => <Redirect to={`/buyers/${buyerGuid}`} replace={true} />}
        </Route>
        <Route<{ buyerGuid: string }> path="/buyers/:buyerGuid">
          {({ buyerGuid }) => (
            <SupplierBuyerRelationshipPage buyerGuid={buyerGuid} supplierName={props.signal_name} />
          )}
        </Route>
        {/* Default */}
        <Route>
          <SupplierRelationshipPage {...props} />
        </Route>
      </Switch>
    </Router>
  );
}

type ProfileContextType = {
  signalName: string | undefined;
  signalCategory: ProfileType | undefined;
};

export const ProfileTypeContext = React.createContext<ProfileContextType>({
  signalName: undefined,
  signalCategory: undefined,
});

function SupplierRelationshipPage({ signal_category, signal_name }: SupplierRelationshipProps) {
  const [location, setLocation] = useLocation();
  const [tabConfig, setTabConfig] = React.useState(() =>
    getTabConfigFromLocation(location as PrimaryTab | SecondaryTab),
  );
  const [filters, setFilters] = React.useState<SupplierRelationshipFilter>(() => ({
    keywords: [],
    textSearch: "",
  }));

  React.useEffect(() => {
    const tc = getTabConfigFromLocation(location);
    if (tc.primary !== tabConfig.primary || tc.secondary !== tabConfig.secondary) {
      setTabConfig(tc);
    }
  }, [location, tabConfig]);

  const resetFilters = React.useCallback(() => setFilters({ keywords: [], textSearch: "" }), []);

  const handleNavigation = React.useCallback(
    (loc: PrimaryTab | SecondaryTab) => {
      setLocation(loc);
      // Clearing filters when a top level tab is actioned
      setTabConfig((oldTabConfig) => {
        const newTabConfig = getTabConfigFromLocation(loc);
        if (oldTabConfig.primary != newTabConfig.primary) {
          resetFilters();
        }
        return newTabConfig;
      });
    },
    [resetFilters, setLocation],
  );

  const handleTabNavigation = React.useCallback(
    (loc: PrimaryTab | SecondaryTab) => {
      handleNavigation(loc);
      const config = getTabConfigFromLocation(loc);
      const activeRoute = config.secondary || config.primary;
      logEvent(EventNames.tabChanged, {
        "Context source": config.secondary ? "Secondary Tab" : "Primary Tab",
        "Tab Selected": labelledRoutes[activeRoute],
      });
    },
    [handleNavigation],
  );

  useUrlParamsTracker({
    params: filters,
    onChange: setFilters,
    toUrlParams,
    fromUrlParams,
  });

  return (
    <DetailsPage>
      <ProfileTypeContext.Provider
        value={{ signalCategory: signal_category, signalName: signal_name }}
      >
        <DetailsHeader className={css.noPaddingBottom}>
          <div className={css.headingWrapper}>
            <BackToLink to={signalCategoryBaseURL[signal_category]} className={css.backLink}>
              Back to {signal_category}s
            </BackToLink>
            <div>
              <h1 className={css.heading}>{signal_name}</h1>{" "}
              <div
                className={classnames(
                  `tag-${signal_category === ProfileType.PARTNER ? "yellow" : "red"}`,
                  "tag",
                  css.pageSignal,
                )}
              >
                {capitalize(signal_category)}
              </div>
            </div>
            <HeaderStatisticCards
              signalCategory={signal_category}
              signalName={signal_name}
              handleNavigation={handleNavigation}
              filters={filters}
              onFiltersChange={setFilters}
            />
            <SupplierRelationshipsTabs
              tabConfig={tabConfig}
              handleTabNavigation={handleTabNavigation}
            />
          </div>
        </DetailsHeader>
        <DetailsContent>
          <RelationshipProfileFilter
            filters={filters}
            onFiltersChange={setFilters}
            search_type={tabConfig.primary === PrimaryTab.BUYERS ? "buyers" : "notices"}
          />
          {tabConfig.primary === PrimaryTab.BUYERS ? (
            <BuyerTabs
              tabConfig={tabConfig}
              handleTabNavigation={handleTabNavigation}
              filters={filters}
            />
          ) : (
            <NoticesTabs
              tabConfig={tabConfig}
              handleTabNavigation={handleTabNavigation}
              filters={filters}
            />
          )}
        </DetailsContent>
      </ProfileTypeContext.Provider>
    </DetailsPage>
  );
}

export default hot(withAppLayout(SupplierRelationshipPageWrapper, {}));
