/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner } from './DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner';
import {
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSON,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSONTyped,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerToJSON,
} from './DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner';

/**
 * Base structure for defining queries constructed from multiple attribute criteria objects.       This is on the basic level
 * @export
 * @interface DocumentSignalsRequestSignalsInnerFiltersAllInner
 */
export interface DocumentSignalsRequestSignalsInnerFiltersAllInner {
    /**
     * All of the conditions in this array must be met. Equivalent to an AND filter
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInner
     */
    all?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>;
    /**
     * At least one or more of the conditions in this array must be met, together with all         conditions in all (if there are any). Equivalent to an OR filter
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInner
     */
    any?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>;
    /**
     * None of the conditions in this array must be met. Equivalent to a NOT filter
     * @type {Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInner
     */
    none?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner>;
}

/**
 * Check if a given object implements the DocumentSignalsRequestSignalsInnerFiltersAllInner interface.
 */
export function instanceOfDocumentSignalsRequestSignalsInnerFiltersAllInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSON(json: any): DocumentSignalsRequestSignalsInnerFiltersAllInner {
    return DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSONTyped(json, false);
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSignalsRequestSignalsInnerFiltersAllInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all': !exists(json, 'all') ? undefined : ((json['all'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSON)),
        'any': !exists(json, 'any') ? undefined : ((json['any'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSON)),
        'none': !exists(json, 'none') ? undefined : ((json['none'] as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSON)),
    };
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerToJSON(value?: DocumentSignalsRequestSignalsInnerFiltersAllInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all': value.all === undefined ? undefined : ((value.all as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerToJSON)),
        'any': value.any === undefined ? undefined : ((value.any as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerToJSON)),
        'none': value.none === undefined ? undefined : ((value.none as Array<any>).map(DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerToJSON)),
    };
}

