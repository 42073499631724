import React from "react";
import { DownOutlined, PlusOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Empty, Input, Menu, message, Modal } from "antd5";
import { SorterResult, TablePaginationConfig } from "antd5/es/table/interface";
import classNames from "classnames";
import { capitalize } from "lodash";

import { DetailsContent, DetailsHeader } from "components/app_layout/DetailsLayout";
import { PageHeader } from "components/app_layout/Typography";
import UserInitials from "components/comments/UserInitials";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { BuyerDto, BuyerListDto } from "lib/generated/app-api";
import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { useDeleteBuyerList } from "lib/hooks/api/buyer_lists/useDeleteBuyerList";
import { useUsers } from "lib/hooks/api/useUsers";
import { useDialogManager } from "lib/providers/DialogManager";
import { SortState } from "lib/search/types";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { escapeRegExp } from "lib/utils";
import BuyerListPermissionsModal, {
  BuyerListPermissionsModalProps,
} from "./BuyerListPermissionsModal";
import { UserCreateBuyerListModal, UserModalProps } from "./CreateBuyerListModal";
import { RenameBuyerListModal, RenameBuyerListModalProps } from "./RenameBuyerListModal";

import css from "./BuyerLists.module.scss";

const { confirm } = Modal;

type Actions = Record<string, { action: () => void; permission: string; label: string }>;
function NameCell({ list }: { list: BuyerListDto }) {
  const dialogManager = useDialogManager();

  // Using this state to maintain dropdown button visibility when user is in dropdown menu -
  // otherwise dropdown button dissappears as they're no longer in the row
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

  const { mutate: deleteList } = useDeleteBuyerList({
    onSuccess: () => message.success("List successfully deleted!"),
    onError: () => message.error("Sorry, an error has occurred while deleting this list"),
  });

  const actions: Actions = {
    rename: {
      label: "Rename",
      permission: "can_update",
      action: () =>
        dialogManager.openDialog(RenameBuyerListModal, {
          list: list,
        } as Omit<RenameBuyerListModalProps, "isOpen" | "onClose">),
    },
    delete: {
      label: "Delete",
      permission: "can_delete",
      action: () =>
        confirm({
          content: "Are you sure you want to delete this buyer list?",
          onOk: () => deleteList({ id: list.id, name: list.name }),
          prefixCls: "ant5",
        }),
    },
    permissions: {
      label: "Share permissions",
      permission: "can_update",
      action: () =>
        dialogManager.openDialog(BuyerListPermissionsModal, {
          list: list,
        } as Omit<BuyerListPermissionsModalProps, "isOpen" | "onClose">),
    },
  };

  const availableActions = Object.keys(actions).filter((key) =>
    list.permissionActions?.includes(actions[key].permission),
  );

  return (
    <div className={css.nameCell}>
      <EllipsisTooltipTextLink
        fullText={list.name}
        linkText={<b>{list.name}</b>}
        linkProps={{ to: `/buyers/lists/${list.id}` }}
      />
      {availableActions.length > 0 && (
        <Dropdown
          className={classNames({ [SHOW_ON_HOVER]: !dropdownOpen })}
          onOpenChange={(menuHoveredOver) => setDropdownOpen(menuHoveredOver)}
          open={dropdownOpen}
          menu={{
            items: availableActions.map((key) => ({
              key,
              label: actions[key].label,
              onClick: () => actions[key].action(),
            })),
          }}
        >
          <Button>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
}

function BuyerLists() {
  const { data: lists, isLoading, isFetching, isError } = useBuyerLists();
  const { data: users } = useUsers();
  const [searchValue, setSearchValue] = React.useState("");
  const { logEvent } = useTracking();
  const dialogManager = useDialogManager();

  const [tab, setTab] = React.useState<"allBuyerLists" | "myBuyerLists">("allBuyerLists");

  const [sortState, setSortState] = React.useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const getSortOrder = (
    sort: SortState,
    key: keyof BuyerListDto,
  ): "ascend" | "descend" | undefined => {
    if (sort.field === key) {
      return sort.order === "ASC" ? "ascend" : "descend";
    } else return undefined;
  };

  const onTabChange = React.useCallback(
    (key: "allBuyerLists" | "myBuyerLists") => {
      logEvent(EventNames.tabChanged, {
        "Tab selected": key === "allBuyerLists" ? "All buyer lists" : "My buyer lists",
      });
      setTab(key);
    },
    [logEvent],
  );

  const handleChange = React.useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Partial<Record<keyof BuyerListDto, string[]>>,
      sorter: SorterResult<BuyerListDto> | SorterResult<BuyerListDto>[],
    ) => {
      if (Array.isArray(sorter)) {
        sorter = sorter[0];
      }

      setSortState({
        field: sorter.field as string,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    },
    [setSortState],
  );

  /**
   * Buyer lists where the current user is the owner of these lists
   */
  const myBuyerLists = React.useMemo(() => {
    return lists?.all.filter((list) => list.ownerId === window.currentUser?.guid);
  }, [lists?.all]);

  const displayedLists: BuyerListDto[] = React.useMemo(() => {
    const seletedlists = tab === "allBuyerLists" ? lists?.all : myBuyerLists;
    return (
      seletedlists?.filter((list) => list.name.match(new RegExp(escapeRegExp(searchValue), "i"))) ||
      []
    );
  }, [lists?.all, myBuyerLists, searchValue, tab]);

  const columns: ColumnType<BuyerListDto>[] = React.useMemo(
    () => [
      {
        title: "List name",
        key: "name",
        dataIndex: "name",
        width: 440,
        render: (_, list: BuyerListDto) => <NameCell list={list} />,
        sorter: stringSort((list) => list.name),
        sortOrder: getSortOrder(sortState, "name"),
        sortDirections: ["descend", "ascend", "descend"],
        ellipsis: true,
      },
      {
        title: "Buyers",
        key: "entries",
        dataIndex: "entries",
        render: (buyers: BuyerDto[]) => buyers.length,
        sorter: numberSort((buyers) => buyers.entries.length, getSortOrder(sortState, "entries")),
        sortDirections: ["descend", "ascend", "descend"],
        sortOrder: getSortOrder(sortState, "entries"),
      },
      {
        key: "type",
        title: "Type",
        dataIndex: "sharingType",
        sorter: stringSort((list) => list.sharingType),
        sortOrder: getSortOrder(sortState, "sharingType"),
        sortDirections: ["descend", "ascend", "descend"],
        render: (data: string) => capitalize(data),
      },
      {
        key: "owner",
        title: "Owner",
        dataIndex: "ownerId",
        sorter: stringSort((list) => {
          const user = users?.find((user) => user.guid === list.ownerId);

          return user ? `${user.firstName} ${user.lastName}` : "Admin created view";
        }),
        sortOrder: getSortOrder(sortState, "ownerId"),
        sortDirections: ["ascend", "descend", "ascend"],
        render: (owner: string) => {
          const user = users?.find((user) => user.guid === owner);
          return user ? (
            <div className={css.ownerCell}>
              <UserInitials firstName={user?.firstName} lastName={user?.lastName} />
              {user?.firstName} {user?.lastName}
            </div>
          ) : (
            // Doing this to align with AllViewsPanel (just on my own doing this - can rmeove if not needed)
            <div className={css.ownerCell}>
              <Avatar icon={<UserOutlined />} />
              {"Admin created view"}
            </div>
          );
        },
      },
    ],
    [sortState, users],
  );

  const displayedTable = React.useMemo(() => {
    if (isLoading) {
      return <SkeletonTable columns={columns} pageSize={10} active />;
    }

    return (
      <TrackingProvider data={{ "Context source": "In-row" }}>
        <Table
          columns={columns}
          onChange={handleChange}
          loading={isFetching}
          isError={isError}
          dataSource={displayedLists}
          rowKey={(r) => r.id}
          locale={{
            emptyText: (
              <Empty className={css.emptyState}>
                <h2>No buyer lists created yet</h2>
              </Empty>
            ),
          }}
          pagination={displayedLists.length < 20 ? false : undefined}
          onRow={(record, _) => {
            return {
              onClick: () => {
                logEvent(EventNames.buyerListClicked, {
                  "List type": "Buyer list",
                  "List name": record.name,
                  "List ID": record.id,
                });
              },
            };
          }}
        />
      </TrackingProvider>
    );
  }, [columns, displayedLists, handleChange, isError, isFetching, isLoading, logEvent]);

  /**
   * Want to display the search box and menu tabs when loading or when data exists. Just so there's as little as possible
   * disruption when user is loading the screen most of the time. We only obfuscate these CTAs (if that's the right word for it)
   * when there is no data
   */
  const dataExists = React.useMemo(
    (): boolean => (lists && lists.all.length > 0) || isLoading,
    [isLoading, lists],
  );

  return (
    <>
      <DetailsHeader className={css.buyerListHeader}>
        <div className={css.titleAndBtn}>
          <PageHeader>Buyer lists</PageHeader>
          <Button
            type="primary"
            onClick={() => {
              void dialogManager.openDialog(UserCreateBuyerListModal, {
                buyerGuids: [],
                contextSource: "buyer lists page",
              } as Omit<UserModalProps, "isOpen" | "onClose">);
            }}
            icon={<PlusOutlined />}
          >
            Create list
          </Button>
        </div>
        {dataExists ? (
          <Menu
            mode="horizontal"
            selectedKeys={[tab]}
            className={css.menuTabs}
            items={[
              {
                key: "allBuyerLists",
                onClick: () => onTabChange("allBuyerLists"),
                className: css.menuTabItem,
                label: (
                  <span key="allBuyerLists">
                    All buyer lists {!!lists?.all.length && `(${lists.all.length})`}
                  </span>
                ),
              },
              {
                key: "myBuyerLists",
                onClick: () => onTabChange("myBuyerLists"),
                className: css.menuTabItem,
                label: (
                  <span key="myBuyerLists">
                    My buyer lists {!!myBuyerLists?.length && `(${myBuyerLists.length})`}
                  </span>
                ),
              },
            ]}
          />
        ) : (
          // Just having this padding for no data to make header look a bit cleaner when tabs above aren't displayed
          <span style={{ paddingBottom: "16px" }} />
        )}
      </DetailsHeader>

      <div className={css.searchContainer}>
        {dataExists && (
          <Input
            placeholder="Search lists"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={css.searchInput}
            prefix={<SearchOutlined />}
            allowClear
          />
        )}
      </div>
      <DetailsContent>{displayedTable}</DetailsContent>
    </>
  );
}

export default BuyerLists;
