import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import { countryCodes, langCodes } from "../../../../../lib/data/optionItems";
import {
  generateCountryOptions,
  generateLanguageOptions,
} from "../../../../../lib/utils/signalSettingsUtils";
import { Select } from "../../../../form_components/Inputs";

import css from "./AllCountriesInput.module.scss";

type AllCountriesInputProps<T extends FieldValues> = {
  countryFieldName: Path<T>;
  languageFieldName: Path<T>;
  control: Control<T, unknown>;
};

export default function AllCountriesInput<T extends FieldValues>({
  countryFieldName,
  languageFieldName,
  control,
}: AllCountriesInputProps<T>) {
  const allCountryOptions = generateCountryOptions(countryCodes);
  const allLanguageOptions = generateLanguageOptions(langCodes);

  return (
    <div className={css.container}>
      <Select
        name={countryFieldName}
        label="Countries"
        control={control}
        placeholder="Select countries"
        options={allCountryOptions}
        mode="multiple"
        allowClear
        selectAll
      />

      <Select
        name={languageFieldName}
        label="Languages"
        control={control}
        placeholder="Select languages"
        options={allLanguageOptions}
        mode="multiple"
        allowClear
        selectAll
      />
    </div>
  );
}
