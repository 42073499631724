/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransferResponsibilityDecisionNewBuyer } from './TransferResponsibilityDecisionNewBuyer';
import {
    TransferResponsibilityDecisionNewBuyerFromJSON,
    TransferResponsibilityDecisionNewBuyerFromJSONTyped,
    TransferResponsibilityDecisionNewBuyerToJSON,
} from './TransferResponsibilityDecisionNewBuyer';

/**
 * 
 * @export
 * @interface TransferResponsibilityDecision
 */
export interface TransferResponsibilityDecision {
    /**
     * 
     * @type {string}
     * @memberof TransferResponsibilityDecision
     */
    qualification: string;
    /**
     * 
     * @type {TransferResponsibilityDecisionNewBuyer}
     * @memberof TransferResponsibilityDecision
     */
    newBuyer?: TransferResponsibilityDecisionNewBuyer;
}

/**
 * Check if a given object implements the TransferResponsibilityDecision interface.
 */
export function instanceOfTransferResponsibilityDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "qualification" in value;

    return isInstance;
}

export function TransferResponsibilityDecisionFromJSON(json: any): TransferResponsibilityDecision {
    return TransferResponsibilityDecisionFromJSONTyped(json, false);
}

export function TransferResponsibilityDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferResponsibilityDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qualification': json['qualification'],
        'newBuyer': !exists(json, 'new_buyer') ? undefined : TransferResponsibilityDecisionNewBuyerFromJSON(json['new_buyer']),
    };
}

export function TransferResponsibilityDecisionToJSON(value?: TransferResponsibilityDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qualification': value.qualification,
        'new_buyer': TransferResponsibilityDecisionNewBuyerToJSON(value.newBuyer),
    };
}

