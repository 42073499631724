import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Input } from "components/form_components/Inputs";
import { createUseDebounce } from "lib/debounce";
import { SavedViewType } from "lib/generated/app-api";
import { useUpdateView } from "../../../lib/hooks/api/notices/views/useUpdateView";

import css from "./ViewHeaderText.module.scss";

type FormValues = {
  viewName: string;
  viewDescription: string;
};

type Props = {
  view: SavedViewType;
};

export function ViewHeaderText({ view }: Props) {
  const [viewId, setViewId] = useState<string>();
  const [titleDisplay, setTitleDisplay] = useState<string>(view.name);
  const [descriptionDisplay, setDescriptionDisplay] = useState<string>(view.description ?? "");
  const { control, handleSubmit, watch, reset } = useForm<FormValues>({
    defaultValues: {
      viewName: view.name,
      viewDescription: view.description ? view.description : "",
    },
    mode: "onChange",
  });

  const { mutate: mutateView } = useUpdateView("Inline title/description edit");

  const userDebounce500 = createUseDebounce(500);

  const debouncedMutateView = userDebounce500(mutateView);

  // reset the form on change
  useEffect(() => {
    if (view.id !== viewId) {
      setTitleDisplay(view.name);
      setDescriptionDisplay(view.description ?? "");
      setViewId(view.id);
      reset({ viewName: view.name, viewDescription: view.description ?? "" });
    }
  }, [descriptionDisplay, reset, titleDisplay, view.description, view.id, view.name, viewId]);

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        setTitleDisplay(data.viewName);
        setDescriptionDisplay(data.viewDescription);

        debouncedMutateView({
          view: {
            ...view,
            name: data.viewName,
            description: data.viewDescription,
          },
        });
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, view, debouncedMutateView]);

  return (
    <div className={css.titleAndDesc}>
      <form>
        <Input
          className={css.currentViewTitle}
          name="viewName"
          label=""
          disabled={view.isStandardView}
          type="text"
          control={control}
          value={titleDisplay}
          placeholder="View name"
          rules={{
            required: {
              value: true,
              message: "View name is required",
            },
          }}
        />
        <Input
          className={css.currentViewDescription}
          name="viewDescription"
          label=""
          disabled={view.isStandardView}
          type="text"
          control={control}
          value={descriptionDisplay}
          placeholder="What does this view show? Enter a description here..."
        />
      </form>
    </div>
  );
}

export function NoViewHeaderText() {
  return (
    <div className={css.titleAndDesc}>
      <div className={css.currentViewTitle}>Find notices</div>
      <div className={css.currentViewDescription}>Search all public sector procurement notices</div>
    </div>
  );
}
