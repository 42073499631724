type Ga4Event = Record<string, string> & { event: string };

export function sendGa4Event({
  event,
  form,
}: {
  event: string;
  form: { name: string; id: string; fieldName?: string };
}) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const dataLayerEvent: Ga4Event = {
    event,
    form_id: form.id,
    form_name: form.name,
    form_url: window.location.href,
  };

  if (form.fieldName) {
    dataLayerEvent.field_name = form.fieldName;
  }

  window.dataLayer.push(dataLayerEvent);
}
