import React from "react";
import {
  ArrowRightOutlined,
  LinkedinOutlined,
  LinkOutlined,
  MailOutlined,
  XOutlined,
} from "@ant-design/icons";
import { App, Button } from "antd5";

import {
  aiReportBookADemoClicked,
  aiReportSignUpForFreeClicked,
  aiReportViewed,
  aiReportVisitStotlesClicked,
  aiShareReportClicked,
} from "components/buyer_details/question_panel/tracking";
import { copyToClipboard } from "lib/clipboard";
import {
  CpvChartSection,
  DocumentReferenceParameters,
  HeaderSection,
  LinkListSection,
  RecordListSection,
  ReportConfig,
  RichTextSection,
} from "lib/generated/admin-api";
import { DocumentListSection } from "lib/generated/admin-api/models/DocumentListSection";
import { SuppliersSection } from "lib/generated/admin-api/models/SuppliersSection";
import { useOrganisations } from "lib/hooks/api/organisations/useOrganisations";
import Sparkles from "lib/icons/Sparkles";
import * as tracking from "lib/tracking";
import { assert } from "lib/utils";
import { AiQuestionSection } from "../items/aiQuestion/AiQuestionSection";
import ContactsGrid from "../items/contacts/ContactsChart";
import CpvChart from "../items/cpv_chart/CpvChart";
import DocumentList from "../items/document_list/DocumentList";
import { AiHeader } from "../items/header/AiHeader";
import IframeSection from "../items/iframe/IframeSection";
import LinkList from "../items/link_list/LinkList";
import RecordList from "../items/record_list/RecordList";
import Suppliers from "../items/suppliers/Suppliers";
import { RichText } from "../items/text/Text";
import { DispatchComponentProps, ReadOnlySection, SectionDispatcher } from "../Section";
import { extractMetadata } from "../utils";

import "react-mde/lib/styles/css/react-mde-all.css";
import css from "./AiReport.module.scss";

import GEO_BODY from "../../../../assets/images/geometric_cta.svg";

const AiReportSectionDispatcher: SectionDispatcher = {
  RichText: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<RichTextSection>): JSX.Element | null {
    return <RichText {...section.generated} id={reportSectionId} />;
  },
  Header: function ({
    section: { generated },
    metadata: { title, publishedAt },
  }: DispatchComponentProps<HeaderSection>): JSX.Element {
    return <AiHeader title={title} date={publishedAt} {...generated} />;
  },
  RecordList: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<RecordListSection>): JSX.Element {
    return <RecordList {...section.generated} id={reportSectionId} />;
  },
  LinkList: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<LinkListSection>): JSX.Element {
    return <LinkList {...section.generated} id={reportSectionId} />;
  },
  DocumentList: function ({
    section,
    metadata: { reportId },
    reportSectionId,
    getDocumentRef,
  }: DispatchComponentProps<DocumentListSection>): JSX.Element {
    return (
      <DocumentList
        {...section.generated}
        id={reportSectionId}
        getDocumentRef={getDocumentRef}
        reportId={reportId}
      />
    );
  },
  CpvChart: function ({
    section: { generated },
    reportSectionId,
  }: DispatchComponentProps<CpvChartSection>): JSX.Element | null {
    return <CpvChart {...generated} id={reportSectionId} />;
  },
  Suppliers: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<SuppliersSection>): JSX.Element {
    return <Suppliers {...section.generated} {...section.parameters} id={reportSectionId} />;
  },
  Contacts: ContactsGrid,
  Iframe: IframeSection,
  AiQuestion: AiQuestionSection,
};

type AiReportProps = { report: ReportConfig };

function generateAiMailToLink({ reportTitle }: { reportTitle: string }) {
  const subject = encodeURIComponent(reportTitle);
  const body = encodeURIComponent(
    `Hello!\nCheck out this report: ${reportTitle}, which you can access below:\n${window.location.href}\nThis report was crafted with Stotles (https://stotles.com) a platform which accelerate public sector sales.`,
  );

  return `mailto:?subject=${subject}&body=${body}`;
}

export function AiReport({ report }: AiReportProps): JSX.Element {
  const metadata = React.useMemo(() => extractMetadata(report), [report]);
  const { message } = App.useApp();
  const { logEvents } = tracking.useTracking();

  const getDocumentRef = React.useCallback(
    (id: string): DocumentReferenceParameters | undefined => {
      return report.documents?.find((d) => d.id === id)?.parameters;
    },
    [report.documents],
  );

  const title = report.reportData.title;
  const reportHeader = report.reportData.sections.find((section) => section.header !== undefined);
  const reportBody = report.reportData.sections.find((section) => section.aiQuestion !== undefined);

  assert(reportBody?.aiQuestion?.referenceId);
  const { data } = useOrganisations(reportBody?.aiQuestion?.referenceId, {
    onSuccess(data) {
      logEvents(aiReportViewed(data?.organisations[0].name || "n/a", title));
    },
  });

  if (reportHeader && reportBody) {
    return (
      <div>
        <div className={css.reportLayout}>
          {metadata && (
            <>
              <div className={css.reportHeader}>
                <AiHeader
                  title={title}
                  date={metadata.publishedAt}
                  organisationName={data?.organisations[0].name}
                />
              </div>
              <div className={css.reportBody}>
                <div className={css.reportAuthor}>
                  <div className={css.ctaAuthor}>
                    <div className={css.copy}>
                      <div className={css.aiIcon}>
                        <Sparkles className={css.sparkles} />
                      </div>
                      <h1>This report was generated with Stotles.</h1>
                      <p>
                        At Stotles, we help commercial teams discover, approach and engage with
                        relevant public sector opportunities so you can focus on what really matters
                        - winning more work. Check out our app to accelerate your Public Sector
                        Sales, today.
                      </p>
                      <div className={css.visitStotlesLinkContainer}>
                        <Button
                          type="link"
                          href="https://stotles.com"
                          className={css.visitStotlesLink}
                          target="_blank"
                          onClick={() =>
                            logEvents(
                              aiReportVisitStotlesClicked(
                                reportBody.aiQuestion?.referenceId || "n/a",
                                title,
                                reportBody.aiQuestion?.questionId || "n/a",
                              ),
                            )
                          }
                        >
                          Visit Stotles <ArrowRightOutlined />
                        </Button>
                      </div>
                    </div>
                    <div className={css.shareSection}>
                      <span className={css.shareSpan}>Share</span>
                      <Button
                        className={css.shareIcon}
                        icon={<LinkOutlined />}
                        onClick={() => {
                          copyToClipboard({ plain: window.location.href });
                          logEvents(
                            aiShareReportClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                              "Copy link",
                            ),
                          );
                          void message.success("URL copied to clipboard!");
                        }}
                      />
                      <Button
                        className={css.shareIcon}
                        icon={<MailOutlined />}
                        href={generateAiMailToLink({
                          reportTitle: title,
                        })}
                        target="_blank"
                        onClick={() => {
                          logEvents(
                            aiShareReportClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                              "Email",
                            ),
                          );
                        }}
                      />
                      <Button
                        className={css.shareIcon}
                        icon={<LinkedinOutlined />}
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                          window.location.href,
                        )}`}
                        target="_blank"
                        onClick={() => {
                          logEvents(
                            aiShareReportClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                              "LinkedIn",
                            ),
                          );
                        }}
                      />
                      <Button
                        className={css.shareIcon}
                        icon={<XOutlined />}
                        href={`http://twitter.com/intent/tweet?text=${encodeURIComponent(
                          window.location.href,
                        )}`}
                        target="_blank"
                        onClick={() => {
                          logEvents(
                            aiShareReportClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                              "Twitter",
                            ),
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.reportText}>
                  <div className={css.reportAiResponse}>
                    <ReadOnlySection
                      reportSectionId={reportBody.id}
                      section={reportBody}
                      dispatcher={AiReportSectionDispatcher}
                      metadata={metadata}
                      getDocumentRef={getDocumentRef}
                    />
                  </div>
                  <div className={css.ctaSupplier}>
                    <h2>Become a preferred pub-sec supplier today</h2>
                    <p>
                      It’s time to stand out from the competition and take your public sector
                      selling to the next level. With Stotles, you will discover, track and
                      pre-engage with the most relevant opportunities in the market. So, what are
                      you waiting for?
                    </p>
                    <div>
                      <Button
                        type="primary"
                        className={css.primaryCta}
                        target="_blank"
                        href="https://app.stotles.com/get-started"
                        onClick={() => {
                          logEvents(
                            aiReportSignUpForFreeClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                            ),
                          );
                        }}
                      >
                        Sign up for free
                      </Button>
                      <Button
                        className={css.secondaryCta}
                        target="_blank"
                        href="https://www.stotles.com/request-a-demo"
                        onClick={() => {
                          logEvents(
                            aiReportBookADemoClicked(
                              reportBody.aiQuestion?.referenceId || "n/a",
                              title,
                              reportBody.aiQuestion?.questionId || "n/a",
                            ),
                          );
                        }}
                      >
                        Book a demo
                      </Button>
                    </div>
                    <img src={GEO_BODY} alt="stotles geometrics" className={css.geoImage} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return <div>Report not found</div>;
  }
}
