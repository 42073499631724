import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { reportCreated } from "components/buyer_details/question_panel/tracking";
import { graphql } from "lib/generated/app-service-gql";
import {
  CreateAiReportMutation,
  CreateAiReportMutationVariables,
  Question,
} from "lib/generated/app-service-gql/graphql";
import { useTracking } from "lib/tracking";
import { useGraphQlMutation } from "../useGraphQlClient";

const createAiReport = graphql(`
  mutation createAiReport($input: CreateAiReportInput!) {
    createAiReport(CreateAiReportInput: $input) {
      id
    }
  }
`);

export function useCreateAiReport(
  orgName: string,
  options?: UseMutationOptions<
    CreateAiReportMutation,
    unknown,
    CreateAiReportMutationVariables,
    unknown
  >,
) {
  const client = useQueryClient();

  const { logEvents } = useTracking();
  const { data, ...rest } = useGraphQlMutation(createAiReport, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      client.setQueryData(
        ["questions", { referenceId: vars.input.questions[0].referenceId }],
        (oldData: { getQuestions: Question[] } | undefined) => {
          const questionToUpdate = oldData?.getQuestions.find(
            (q) => q.id === vars.input.questions[0].questionId,
          );
          if (questionToUpdate && oldData) {
            questionToUpdate.reportId = data?.createAiReport?.id;
          } else {
            return {
              getQuestions: oldData?.getQuestions || [],
            };
          }
        },
      );
      logEvents(
        reportCreated(
          vars.input.questions[0].question,
          vars.input.questions[0].questionId,
          orgName,
        ),
      );
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.createAiReport, ...rest };
}
