import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { createSavedViewUpdatedEvent } from "components/my_feed/tracking";
import { SavedViewType } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { AlertModificationType, EventNames, useTracking } from "lib/tracking";

type Params = {
  view: SavedViewType;
  alertId?: string;
  alertFrequency?: "DAILY" | "WEEKLY" | "NEVER";
};

export function useUpdateView(
  context: string,
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  let alertModificationType: AlertModificationType;

  return useMutation(
    async ({ view, alertId, alertFrequency }) => {
      if (!view.id) {
        throw new Error("Saved view id is required");
      }
      await api.updateSavedView({
        id: view.id,
        updateSavedViewRequest: { savedView: view },
      });

      // an alert exists for this view so we either update or delete it
      if (alertId) {
        if (alertFrequency === "DAILY" || alertFrequency === "WEEKLY") {
          alertModificationType = AlertModificationType.frequency;
          await api.updateLeadSubscriptionContent({
            id: alertId,
            updateLeadSubscriptionContentRequest: { frequency: alertFrequency },
          });
        } else {
          alertModificationType = AlertModificationType.deleted;
          await api.deleteLeadSubscriptionContent({
            deleteLeadSubscriptionContentRequest: { ids: [alertId] },
          });
        }
      } else if (alertFrequency === "DAILY" || alertFrequency === "WEEKLY") {
        alertModificationType = AlertModificationType.created;
        // no alert yet exists for this view so we create one
        await api.createLeadSubscriptionContent({
          createLeadSubscriptionContentRequest: {
            frequency: alertFrequency,
            resourceId: view.id,
            resourceType: "SavedView",
          },
        });
      }
      return;
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["views"]);
        await queryClient.invalidateQueries(["leadSubscriptionContents"]);
        await queryClient.invalidateQueries(["leadSubscriptions"]);
        logEvent(
          EventNames.viewUpdated,
          createSavedViewUpdatedEvent(
            vars.view.name,
            context,
            vars.view.id,
            alertModificationType,
            vars.alertFrequency,
          ),
        );
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
