import * as React from "react";

import DocumentsTable from "lib/documents/DocumentsTable";
import { useStotlesApi } from "lib/stotlesApiContext";

export default function PreviousUploadsTable({
  userId: currentUserGuid,
  onDocumentClick,
}: {
  userId: string;
  onDocumentClick: (fileId: string) => void;
}): JSX.Element {
  const api = useStotlesApi();
  return (
    <DocumentsTable
      fetchFolderResults={api.searchFolder.bind(api)}
      fetchMissingBuyerInfo={api.searchBuyers.bind(api)}
      onEditDocumentClick={onDocumentClick}
      filters={{
        uploaderGuid: [currentUserGuid],
      }}
      loadResultsOnMount
    />
  );
}
