import React from "react";
import { useForm } from "react-hook-form";
import { App, Button, Modal } from "antd5";

import { BuyerListDto } from "lib/generated/app-api";
import { useUpdateBuyerList } from "lib/hooks/api/buyer_lists/useUpdateBuyerList";
import { Input } from "../form_components/Inputs";

import css from "./RenameBuyerListModal.module.scss";

export type RenameBuyerListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  list: BuyerListDto;
};

export function RenameBuyerListModal({ isOpen, onClose, list }: RenameBuyerListModalProps) {
  const { control, handleSubmit } = useForm({ defaultValues: { name: list.name } });
  const { message } = App.useApp();
  const { isLoading, mutate } = useUpdateBuyerList({
    onSuccess: () => {
      void message.success("Buyer list updated successfully!");
      onClose();
    },
    onError() {
      void message.error("Failed to update buyer list");
    },
  });

  return (
    <Modal title="Rename buyer list" open={isOpen} onCancel={onClose} footer={null}>
      <form
        onSubmit={handleSubmit((v) =>
          mutate({
            name: v.name,
            list: list,
          }),
        )}
        className={css.form}
      >
        <Input
          name="name"
          control={control}
          label="List name"
          rules={{ required: "List name is required" }}
        />
        <div className={css.footer}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Updating..." : "Rename buyer list"}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default RenameBuyerListModal;
