import * as React from "react";
import debounce from "lodash.debounce";

// Debounced function takes the same parameters but discards the return value
type Debounced<Fn extends (...args: any) => any> = (...args: Parameters<Fn>) => void;

/**
 * Creates a `useDebounce` hook with preconfigured wait time.
 *
 * ### Why use ref here?
 * If we just used `useMemo` that depdended on `fn` a new debounced function
 * would be created every time `fn` changed. If this happened while a function call was debounced
 * the old call would be triggered with old `fn`.
 *
 * This could happen e.g. if `fn` changes while the user is typing quickly.
 *
 * By using a ref we make sure that the debounce will always call the latest `fn`.
 *
 * ### Why hook factory?
 * In most usecases debounce wait time never changes. If we wanted to handle changing
 * waiting time properly the code would be much more complex.
 */
export function createUseDebounce(waitMiliseconds: number) {
  return function useDebounce<Fn extends (...args: any[]) => any>(fn: Fn): Debounced<Fn> {
    const fnRef = React.useRef(fn);
    // update the ref when the actual function changes
    React.useEffect(() => {
      fnRef.current = fn;
    }, [fn]);

    const debounced = React.useMemo(
      () => debounce<Debounced<Fn>>((...args: any[]) => fnRef.current(...args), waitMiliseconds),
      [],
    );
    return debounced;
  };
}

export function useDebouncedValue<T>(value: T, delay = 200): [T, () => void] {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  //skip waiting and update to the latest value
  const updateNow = React.useCallback(() => {
    setDebouncedValue(value);
  }, [value]);

  return [debouncedValue, updateNow];
}
