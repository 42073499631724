/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferResponsibilityDecisionNewBuyer
 */
export interface TransferResponsibilityDecisionNewBuyer {
    /**
     * 
     * @type {string}
     * @memberof TransferResponsibilityDecisionNewBuyer
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof TransferResponsibilityDecisionNewBuyer
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransferResponsibilityDecisionNewBuyer
     */
    isStotlesVerified?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransferResponsibilityDecisionNewBuyer
     */
    oscarId?: string | null;
}

/**
 * Check if a given object implements the TransferResponsibilityDecisionNewBuyer interface.
 */
export function instanceOfTransferResponsibilityDecisionNewBuyer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function TransferResponsibilityDecisionNewBuyerFromJSON(json: any): TransferResponsibilityDecisionNewBuyer {
    return TransferResponsibilityDecisionNewBuyerFromJSONTyped(json, false);
}

export function TransferResponsibilityDecisionNewBuyerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferResponsibilityDecisionNewBuyer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'name': json['name'],
        'isStotlesVerified': !exists(json, 'is_stotles_verified') ? undefined : json['is_stotles_verified'],
        'oscarId': !exists(json, 'oscar_id') ? undefined : json['oscar_id'],
    };
}

export function TransferResponsibilityDecisionNewBuyerToJSON(value?: TransferResponsibilityDecisionNewBuyer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'name': value.name,
        'is_stotles_verified': value.isStotlesVerified,
        'oscar_id': value.oscarId,
    };
}

