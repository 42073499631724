import * as React from "react";
import { Tooltip } from "antd5";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import PaywallPopover from "components/paywall/PaywallPopover";
import PaywallStar from "lib/icons/PaywallStar";
import { TenderStage } from "lib/types/models";
import { TENDER_STAGE_LABELS } from "lib/types/tenderStage";

import css from "./RecordStage.module.scss";

const stageColours: Record<TenderStage, string> = {
  STALE_PRE_TENDER: "gold",
  PRE_TENDER: "yellow",
  OPEN: "green",
  CLOSED: "red",
  AWARDED: "blue",
};

const stageTooltip: Record<TenderStage, string> = {
  PRE_TENDER: "Pre-tenders published in the last 3 months",
  OPEN: "Tender documents with a close date in the future",
  CLOSED: "Tender documents with a close date in the past",
  AWARDED: "Notification of a completed procurement process",
  STALE_PRE_TENDER:
    "Pre-tenders published more than 3 months ago, or that have a close date in the past",
};

function RecordStage({
  stage,
  className,
  tooltipPlacement,
  preview,
}: {
  stage: TenderStage;
  className?: string;
  tooltipPlacement?: TooltipProps["placement"];
  preview?: boolean; // When preview is true, the tooltip is not shown
}): JSX.Element {
  if (preview) {
    return (
      <PaywallPopover featureType="AWARDS">
        <span
          className={classnames(
            className,
            css.stage,
            css[`stage-${stageColours[stage]}`] ?? css["stage-unknown"],
          )}
        >
          • {TENDER_STAGE_LABELS[stage] ?? "Unknown"} <PaywallStar className={css.paywallIcon} />
        </span>
      </PaywallPopover>
    );
  } else {
    return (
      <Tooltip
        placement={tooltipPlacement}
        overlayClassName={css.stageTooltip}
        title={stageTooltip[stage] ?? "Tender documents with an unknown close date"}
      >
        <span
          className={classnames(
            className,
            css.stage,
            css[`stage-${stageColours[stage]}`] ?? css["stage-unknown"],
          )}
        >
          • {TENDER_STAGE_LABELS[stage] ?? "Unknown"}{" "}
        </span>
      </Tooltip>
    );
  }
}
export default RecordStage;
