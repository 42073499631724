import React from "react";
import { Button } from "antd5";

import * as tracking from "lib/tracking";
import { createSuggestedPromptActionedEvent } from "./tracking";

import css from "./SuggestedPrompts.module.scss";

type SuggestedPromptsProps = {
  onPromptClick: (question: string) => void;
  orgName: string;
};

export default function SuggestedPrompts({ onPromptClick, orgName }: SuggestedPromptsProps) {
  const prompts = [
    [
      {
        line1: "Show me this buyer's awards",
        line2: "related to CRM software",
        prompt: "Show me this buyer's awards related to CRM software",
      },
      {
        line1: "What procurement methods has this buyer used",
        line2: "to purchase technology services?",
        prompt: "What procurement methods has this buyer used to purchase technology services?",
      },
    ],
    [
      {
        line1: "What database technologies",
        line2: "are used by this organisation?",
        prompt: "What database technologies are used by this organisation?",
      },
      {
        line1: "Name the certifications required",
        line2: "to supply services to this organisation",
        prompt: "Name the certifications required to supply services to this organisation",
      },
    ],
  ];

  const { logEvents } = tracking.useTracking();

  return (
    <div className={css.newSuggestedQuestions}>
      {prompts.map((column, index) => {
        return (
          <div className={css.suggestionColumn} key={index}>
            {column.map((prompt, index) => {
              return (
                <Button
                  key={`${index}`}
                  onClick={() => {
                    onPromptClick(prompt.prompt);
                    logEvents(createSuggestedPromptActionedEvent(prompt.prompt, orgName));
                  }}
                >
                  <p className={css.line1}>{prompt.line1}</p>
                  <p className={css.line2}>{prompt.line2}</p>
                </Button>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
