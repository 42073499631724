import React from "react";
import { Progress } from "antd5";

import { useStotlesApi } from "../../lib/stotlesApiContext";

type Props = {
  onComplete: () => void;
  jobId?: string;
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 */
function FeedUpdateProgressBar({ onComplete, jobId }: Props): JSX.Element {
  /**
   * We have to handle three cases:
   *  - jobId is not defined (just fake progress)
   *  - jobId is defined while progress bar is running
   *  - jobId is defined from the start
   *
   * The average time to finish should be ~5s.
   * If jobId is defined but job is not finished in that time we will just stay at ~95%
   * If the job is not finished in 15s we "time out" and force a 100% progress.
   */
  const api = useStotlesApi();
  const [progress, setProgress] = React.useState(0);
  // We store these in a ref as they're only used in timeouts and we don't want
  // to rerun hooks when these change
  const isDone = React.useRef(false);
  const jobCheckRetries = React.useRef(0);

  // Store jobId in a ref so we don't have to rerun useEffects when it changes
  const jobIdRef = React.useRef(jobId);
  React.useEffect(() => {
    jobIdRef.current = jobId;
  }, [jobId]);

  // Stops any running timeout loops & calls onComplete
  const onFinishProgress = React.useCallback(() => {
    isDone.current = true;
    // This is a bit cheeky but we want the user to see 100%
    // but when onComplete is called the progress bar might just disappear.
    setTimeout(() => {
      onComplete();
    }, 500);
  }, [onComplete]);

  // FAKE PROGRESS LOOP
  const TICK_TIMEOUT_MS = 200;
  const AVERAGE_COMPLETE_TIME_MS = 5000;
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    const tick = () => {
      if (isDone.current) {
        return;
      }
      setProgress((p) => {
        // This is in percentage points
        const averageTickProgress = 100 * (TICK_TIMEOUT_MS / AVERAGE_COMPLETE_TIME_MS);
        // Increase the progress by 0.5-1.5 times the average expected increase
        const newP = p + Math.floor((Math.random() + 0.5) * averageTickProgress);
        if (jobIdRef.current) {
          if (newP >= 95) {
            // If job is not done yet we won't let progress go over 95
            // and the other loop will take care of calling onComplete
            return p;
          }
        } else {
          if (newP >= 100) {
            onFinishProgress();
            return 100;
          }
        }
        return newP;
      });

      timeoutId = setTimeout(tick, TICK_TIMEOUT_MS);
    };
    tick();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [onFinishProgress]);

  // JOB STATUS POLL LOOP
  const STATUS_CHECK_TIMEOUT_MS = 1000;
  // The progress bar will wait at most STATUS_CHECK_TIMEOUT_MS * MAX_RETRIES miliseconds
  const MAX_RETRIES = 30;
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (!api || !jobId) {
      return;
    }
    const tick = async () => {
      if (isDone.current) {
        return;
      }
      if (jobCheckRetries.current > MAX_RETRIES) {
        setProgress(100);
        onFinishProgress();
      }

      jobCheckRetries.current += 1;
      const job = await api.getJob(jobId);
      if (job.finished) {
        setProgress(100);
        onFinishProgress();
        return;
      }
      timeoutId = setTimeout(tick, STATUS_CHECK_TIMEOUT_MS);
    };
    tick();
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [jobId, setProgress, onFinishProgress, api]);

  return (
    <Progress
      percent={progress}
      status={progress < 100 ? "active" : "success"}
      showInfo={progress === 100} // only show the icon when finished
    />
  );
}

export default FeedUpdateProgressBar;
