import React from "react";
import { useForm } from "react-hook-form";
import { App, Button, ConfigProvider, Modal } from "antd5";

import { Input } from "components/form_components/Inputs";
import { CreateAiReportInput_Questions } from "lib/generated/app-service-gql/graphql";
import { useCreateAiReport } from "lib/hooks/api/question/useCreateAiReport";
import Sparkles from "lib/icons/Sparkles";
import { white } from "lib/themes/colors";

import css from "./CreateReportModal.module.scss";

type CreateReportFrom = {
  title: string;
  questions: CreateAiReportInput_Questions[];
};
export function CreateReportModal({
  isOpen,
  onClose,
  questions,
  orgName,
}: {
  isOpen: boolean;
  onClose: () => void;
  questions: CreateAiReportInput_Questions[];
  orgName: string;
}) {
  const { control, handleSubmit } = useForm<CreateReportFrom>({
    defaultValues: { questions, title: "" },
  });
  const { message } = App.useApp();
  const { mutate, isLoading } = useCreateAiReport(orgName, {
    onSuccess: (data, _variables, _context) => {
      void message.success("Report created successfully");
      // open the report in a new tab
      window.open(`/reports/${data.createAiReport.id}`, "_blank");
      onClose();
    },
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            // need to set this to prevent inheritence of the Ask AI modal theming
            contentBg: "#ffffff",
          },
        },
      }}
    >
      <Modal
        title="Create a public link for this question"
        open={isOpen}
        onCancel={onClose}
        footer={null}
      >
        <form
          onSubmit={handleSubmit((values) => mutate({ input: values }))}
          className={css.container}
        >
          <div>
            Share this answer with your team and start actioning insights generated for this buyer
          </div>
          <Input
            control={control}
            name="title"
            label="What would you like to name this report?"
            rules={{
              required: true,
              maxLength: { value: 30, message: "Report titles cannot be more than 30 characters" },
            }}
          />
          <div className={css.footer}>
            <Button onClick={onClose} loading={isLoading}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" icon={<Sparkles fill={white} />}>
              Generate report
            </Button>
          </div>
        </form>
      </Modal>
    </ConfigProvider>
  );
}
