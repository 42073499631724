import React from "react";
import { Alert, Tag } from "antd5";

import { grey800 } from "../../lib/themes/colors";

import css from "./BetaWarning.module.scss";

export function BetaWarning({ className }: { className?: string }) {
  return (
    <Alert
      message={
        <div className={css.header}>
          <span>
            This is a beta feature, data collection for all buyer types is still underway{" "}
          </span>
          <Tag color={grey800}>beta</Tag>
        </div>
      }
      description="As we continue to develop this feature we will be increasing the coverage of our data. Right now we cover key buyers across Central, Local, and Healthcare."
      type="warning"
      closable
      className={className}
    />
  );
}
