import * as React from "react";

// these should be ok for most use cases, though sometimes it might need more granularity in the
// thresholds?
const DEFAULT_OPTIONS = {
  rootMargin: "0px",
  threshold: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0],
  root: null,
};

const useElementInViewport = <E extends HTMLElement>(
  observerOptions?: IntersectionObserverInit,
): [React.MutableRefObject<E | null>, boolean] => {
  const elementRef = React.useRef<E | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const onObserve: IntersectionObserverCallback = ([elementEntry]) => {
      setIsVisible(elementEntry.isIntersecting);
      // we could track other things here too, such as how much of the element is actually visible
    };

    const observer = new IntersectionObserver(onObserve, observerOptions || DEFAULT_OPTIONS);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(elementRef.current);
      }
    };
  }, [observerOptions]);

  return [elementRef, isVisible];
};

export default useElementInViewport;
