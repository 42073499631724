import React from "react";
import { Control } from "react-hook-form";

import { KeywordSelect } from "../../form_components/keyword_select/KeywordSelect";
import { FrameworkFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface KeywordsFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
}

function KeywordsFilterContent({ control }: KeywordsFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Keywords filters">
      <KeywordSelect name="keywords" label="Include keywords" control={control} />
      <KeywordSelect
        name="excludeKeywords"
        label="Exclude frameworks that mention"
        type="excludeKeywords"
        control={control}
      />
    </div>
  );
}

export default KeywordsFilterContent;
