import * as React from "react";

import css from "./RangeInputs.module.scss";

type RangeInputProps = {
  from: React.ReactElement;
  to: React.ReactElement;
};

export function RangeInput(props: RangeInputProps): JSX.Element {
  const { from, to } = props;
  return (
    <div className={css.valueFields}>
      {from}
      <span>to</span>
      {to}
    </div>
  );
}
