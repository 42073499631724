import {
  DEFAULT_FILTERS,
  FrameworkFilters,
  parseFrameworksUrlState,
} from "components/framework/utils";
import { NEW_HIGHLIGHT_LOGIC, useVariableValue } from "lib/featureFlags";
import { FrameworkHighlightRequest } from "lib/generated/app-service-gql/graphql";
import { useURLState } from "lib/hooks/useURLState";
import { FrameworkHighlightResponse, useFrameworkHighlight } from "./useFrameworkHighlight";

function convertFrameworkFiltersToHighlightRequest(
  id: string,
  filters: FrameworkFilters,
): FrameworkHighlightRequest {
  const keywordFilters = filters.keywords || [];
  const searchText =
    filters.searchText && filters.searchText.trim() !== "" ? [filters.searchText] : [];

  // TODO: When supplier mentioned type is added to framework filters, we need
  // to adjust the highlighting logic here. If directly awarded is actioned,
  // suppliers should not be highlighted.
  return {
    frameworkId: id,
    // If supplier mention type is directly awarded, we don't want to highlight the suppliers
    orgIds: filters.supplierIds,
    keywords: [...keywordFilters, ...searchText],
    highlightSignals: false,
  };
}

type FrameworkHighlightingResponse = {
  highlightTerms: FrameworkHighlightResponse | undefined;
  filters: FrameworkFilters;
};
/**
 * Wrapper function for useFrameworkHighlight. it gets the currently actioned
 * framework filters from the url params, converts that into a
 * {@link FrameworkHighlightRequest} object to be used in the
 * {@link useFrameworkHighlight} hook. Then hey presto we have highlights! * @returns
 */
export function useFrameworkHighlighting(
  frameworkId: string,
  defaultFilters: FrameworkFilters = DEFAULT_FILTERS,
): FrameworkHighlightingResponse {
  const [filters = defaultFilters] = useURLState<FrameworkFilters>(
    "frameworkFilters",
    DEFAULT_FILTERS,
    parseFrameworksUrlState,
  );

  const isHighlightingEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  const highlightFilters = convertFrameworkFiltersToHighlightRequest(frameworkId, filters);

  const { data: highlightTerms } = useFrameworkHighlight(highlightFilters, {
    enabled: isHighlightingEnabled,
  });

  return { highlightTerms, filters };
}
