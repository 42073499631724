import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { GetRecordListsResponseResult, RecordListSummaryDto } from "lib/generated/app-api";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventData, EventNames, useTracking } from "lib/tracking";

type Params = {
  list: GetRecordListsResponseResult | RecordListSummaryDto;
  name: string;
  trackingData?: EventData;
};

export function useRenameNoticeList(
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  return useMutation(async ({ list, name }: Params) => api.updateUserList(list.id, name), {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      await queryClient.invalidateQueries(["noticeLists"]);
      const { list, trackingData } = vars;

      logEvent(EventNames.listUpdated, {
        ...trackingData,
        "Existing List name": list.name,
        "Existing List id": list.id,
        "New List name": data.name,
      });
      options?.onSuccess?.(data, vars, ctx);
    },
  });
}
