/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DistinctCandidate } from './DistinctCandidate';
import {
    DistinctCandidateFromJSON,
    DistinctCandidateFromJSONTyped,
    DistinctCandidateToJSON,
} from './DistinctCandidate';
import type { HierarchicalCandidate } from './HierarchicalCandidate';
import {
    HierarchicalCandidateFromJSON,
    HierarchicalCandidateFromJSONTyped,
    HierarchicalCandidateToJSON,
} from './HierarchicalCandidate';

/**
 * 
 * @export
 * @interface MultipleEntitiesDecisionCleaningData
 */
export interface MultipleEntitiesDecisionCleaningData {
    /**
     * 
     * @type {DistinctCandidate}
     * @memberof MultipleEntitiesDecisionCleaningData
     */
    distinct?: DistinctCandidate;
    /**
     * 
     * @type {HierarchicalCandidate}
     * @memberof MultipleEntitiesDecisionCleaningData
     */
    hierarchical?: HierarchicalCandidate;
}

/**
 * Check if a given object implements the MultipleEntitiesDecisionCleaningData interface.
 */
export function instanceOfMultipleEntitiesDecisionCleaningData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MultipleEntitiesDecisionCleaningDataFromJSON(json: any): MultipleEntitiesDecisionCleaningData {
    return MultipleEntitiesDecisionCleaningDataFromJSONTyped(json, false);
}

export function MultipleEntitiesDecisionCleaningDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleEntitiesDecisionCleaningData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distinct': !exists(json, 'distinct') ? undefined : DistinctCandidateFromJSON(json['distinct']),
        'hierarchical': !exists(json, 'hierarchical') ? undefined : HierarchicalCandidateFromJSON(json['hierarchical']),
    };
}

export function MultipleEntitiesDecisionCleaningDataToJSON(value?: MultipleEntitiesDecisionCleaningData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distinct': DistinctCandidateToJSON(value.distinct),
        'hierarchical': HierarchicalCandidateToJSON(value.hierarchical),
    };
}

