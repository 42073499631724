import * as React from "react";
import { Tooltip } from "antd5";
import { ProfileTypeContext } from "pages/app/SupplierRelationshipPage";

import BookmarkIcon from "components/actions/Bookmark";
import { PAGINATION_DEFAULT_CONFIG } from "components/relationship_profiles/tableConfigs";
import {
  PaginationConfig,
  ProfileType,
  SupplierRelationshipFilter,
} from "components/relationship_profiles/types";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import { LinkCell } from "components/table_components/LinkCell";
import SignalsContainer from "components/tags/SignalsContainer";
import { EllipsisTooltipWouterLink } from "lib/core_components/EllipsisTooltip";
import RedactedWrapper, { RedactedLink } from "lib/core_components/RedactedWrapper";
import { Table } from "lib/core_components/Table";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import {
  SupplierSignalBuyerResponse,
  SupplierSignalBuyerStats,
  SupplierSignalBuyerStatsStats,
} from "lib/generated/app-api";
import { useBuyerStatsSupplierSignal } from "lib/hooks/api/buyer/useBuyerStatsSupplierSignal";
import { useIsBuyerInList } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { useRestrictedLink } from "lib/hooks/useRestrictedRowClick";
import { SortState } from "lib/search/types";
import { OpenRecordViewerOverlayBtn } from "./RecordViewerOverlay";

import css from "./BuyerTable.module.scss";

type Props = {
  showKeyAccountsOnly: boolean;
  onBuyerLoad: (resp: SupplierSignalBuyerResponse) => void;
  filters: SupplierRelationshipFilter;
};

const getSortOrder = (sort: SortState, key: string): "ascend" | "descend" | undefined => {
  if (sort.field === key) {
    return sort.order === "ASC" ? "ascend" : "descend";
  } else return undefined;
};

const DEFAULT_SMALL_COLUMN = {
  // Has to be at least 120 otherwise you don't see the sorter
  xlarge: 180,
  large: 170,
  medium: 170,
  small: 120,
};

const DEFAULT_LARGE_COLUMN = {
  xlarge: 270,
  large: 260,
  medium: 230,
  small: 190,
};

const DEFAULT_SORT: SortState = { field: "last_activity", order: "DESC" };

function BuyerListTooltip({ buyerGuid }: { buyerGuid: string }) {
  const {
    isSaved: { isSaved, savedListNames },
  } = useIsBuyerInList(buyerGuid);
  if (!isSaved) {
    return null;
  }
  return (
    <Tooltip title={`Saved to ${savedListNames.join(", ")}`}>
      <div>
        <BookmarkIcon colour="grey" filled />
      </div>
    </Tooltip>
  );
}

function BuyerTable({ showKeyAccountsOnly, onBuyerLoad, filters }: Props) {
  const [{ sort, pagination }, setQueryOptions] = React.useState<{
    pagination: PaginationConfig;
    sort: SortState;
  }>({ pagination: PAGINATION_DEFAULT_CONFIG, sort: DEFAULT_SORT });
  const { signalCategory, signalName } = React.useContext(ProfileTypeContext);

  const { data, isLoading, isFetching, isError } = useBuyerStatsSupplierSignal(
    {
      signalName: signalName || "",
      signalCategory: signalCategory === ProfileType.COMPETITOR ? "Competitors" : "Partners",
      filters: {
        showKeyAccountsOnly,
        keywordSignals: filters.keywords,
        buyerName: filters.textSearch || "",
      },
      offset: ((pagination.current || 1) - 1) * (pagination.pageSize || 0),
      sort: sort.field,
      sortOrder: sort.order,
      limit: pagination.pageSize,
    },
    {
      enabled: !!signalName,
      onSuccess(data) {
        onBuyerLoad(data);
      },
      keepPreviousData: true,
    },
  );

  const onRowClick = useRestrictedLink("SUPPLIERS");

  const tableColumns: ColumnType<SupplierSignalBuyerStats>[] = React.useMemo(() => {
    return [
      {
        ...commonTableColumns.buyerColumn,
        key: "buyer_name",
        title: "Buyer",
        render: (_, b: SupplierSignalBuyerStats) => (
          <div className={css.buyerCell}>
            <div className={css.buyerWrap}>
              <RedactedWrapper
                requiredDataType={"SUPPLIERS"}
                redactContent={<RedactedLink textToRedact={b.buyerName} />}
                contextSource={"In-row"}
              >
                <EllipsisTooltipWouterLink
                  fullText={b.buyerName}
                  linkText={b.buyerName}
                  linkProps={{ to: `/buyers/${b.buyerGuid}` }}
                />
              </RedactedWrapper>
              <RedactedWrapper
                requiredDataType={"SUPPLIERS"}
                redactContent={`${b.categories[0] ? b.categories[0].buyerCategoryId : undefined}`}
                contextSource={"In-row"}
              >
                <BuyerCategoriesWithPopover
                  size="default"
                  buyerCategories={b.categories.map((c) => ({
                    buyer_category_id: c.buyerCategoryId,
                    source: c.source,
                  }))}
                  buyerName={b.buyerName}
                />
              </RedactedWrapper>
            </div>
            <BuyerListTooltip buyerGuid={b.buyerGuid} />
          </div>
        ),
        sizeConfig: DEFAULT_LARGE_COLUMN,
        sorter: true,
        sortOrder: getSortOrder(sort, "buyer_name"),
      },
      {
        title: "All activity",
        key: "all_records",
        dataIndex: "stats",
        render: (s: SupplierSignalBuyerStatsStats, b: SupplierSignalBuyerStats) => {
          const signalFilter = signalCategory &&
            signalName && { [signalCategory]: { name: signalName } };
          return FeatureToggles.isEnabled(Feature.SPEND_DATA) ? (
            <LinkCell value={s.allRecords} destination={`/buyers/${b.buyerGuid}`} />
          ) : (
            <OpenRecordViewerOverlayBtn
              count={s.allRecords}
              params={{
                stage: ["awarded", "open", "closed", "pre_tender", "stale_pre_tender"],
                keywords: filters.keywords,
                buyer: { guid: b.buyerGuid, name: b.buyerName },
                ...signalFilter,
              }}
              noticeType="all_activity"
            />
          );
        },
        sizeConfig: DEFAULT_SMALL_COLUMN,
        sorter: true,
        sortOrder: getSortOrder(sort, "all_records"),
      },
      {
        title: "Upcoming expiries",
        key: "expiries",
        dataIndex: "stats",
        render: (s: SupplierSignalBuyerStatsStats, b: SupplierSignalBuyerStats) => {
          const signalFilter = signalCategory &&
            signalName && { [signalCategory]: { name: signalName } };
          return (
            <OpenRecordViewerOverlayBtn
              count={s.expiries}
              params={{
                stage: ["awarded"],
                keywords: filters.keywords,
                buyer: { guid: b.buyerGuid, name: b.buyerName },
                expiryDate: { relativeFrom: "PT0S" },
                expiryNulls: false,
                ...signalFilter,
              }}
              noticeType="upcoming_expiries"
            />
          );
        },
        sizeConfig: DEFAULT_SMALL_COLUMN,
        sorter: true,
        sortOrder: getSortOrder(sort, "expiries"),
      },
      {
        title: "Last activity",
        key: "last_activity",
        dataIndex: "stats",
        render: (s) => s.lastActivity,
        sizeConfig: DEFAULT_SMALL_COLUMN,
        sorter: true,
        sortOrder: getSortOrder(sort, "last_activity"),
      },
      {
        ...commonTableColumns.signalsColumn,
        title: "Matched keywords",
        key: "keyword_signals",
        render: (_: unknown, b) => {
          return <SignalsContainer signals={b.keywordSignals} contextSource="In-row" />;
        },
        sorter: false,
        sizeConfig: DEFAULT_LARGE_COLUMN,
      },
    ];
  }, [filters.keywords, signalCategory, signalName, sort]);

  return (
    <Table
      dataSource={data?.results}
      columns={tableColumns}
      isError={isError}
      pagination={{ ...pagination, total: data?.pagingInfo.totalResults }}
      loading={isLoading || isFetching}
      rowKey="buyerGuid"
      onRow={(r) => ({ onClick: () => onRowClick(`/buyers/${r.buyerGuid}`) })}
      onChange={(page, _, sort) => {
        if (Array.isArray(sort)) {
          sort = sort[0];
        }
        setQueryOptions({
          pagination: {
            pageSize: page.pageSize || pagination.pageSize,
            current: page.current || pagination.current,
          },
          sort: {
            order: sort.order === "descend" ? "DESC" : "ASC",
            field: sort.columnKey as string,
          },
        });
      }}
    />
  );
}

export default BuyerTable;
