import React from "react";
import { Control } from "react-hook-form";

import { SignalSelect } from "../../form_components/SignalSelect";
import { FrameworkFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface SignalsFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
}

function SignalsFilterContent({ control }: SignalsFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Signals filters">
      <SignalSelect
        name="signals"
        label="Signals"
        control={control}
        placeholder="Select..."
        allowClear
        hideLabel
      />
    </div>
  );
}

export default SignalsFilterContent;
