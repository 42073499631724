import * as React from "react";
import { Radio } from "antd5";

import { useUniqueIdentifier } from "../../lib/hooks/useUniqueIdentifier";
import { LanguagesFilter } from "./LanguagesFilter";

import css from "./LanguagesField.module.scss";

type Props = {
  values?: string[];
  onChange: (values: string[]) => void;
  label?: string;
};

type LanguagePreset = keyof typeof LANGUAGE_PRESETS;

function isPresetMatch(preset: LanguagePreset, languageCodes: string[]) {
  const presetCCs = LANGUAGE_PRESETS[preset];
  if (presetCCs.size !== languageCodes.length) {
    return false;
  }
  for (const cc of languageCodes) {
    if (!presetCCs.has(cc)) {
      return false;
    }
  }
  return true;
}

function getPresetMatch(languageCodes: string[]): LanguagePreset | null {
  if (languageCodes.length === 0) return "ALL";
  if (isPresetMatch("ENGLISH", languageCodes)) return "ENGLISH";
  return null;
}

/**
 * See AllCountriesField for explanation of the state and how the "presets" work.
 */
const LanguagesField = ({ values = [], onChange, label }: Props): JSX.Element => {
  const inputId = useUniqueIdentifier("language-input");

  const [selectedPreset, setSelectedPreset] = React.useState<LanguagePreset | "CUSTOM">(
    () => getPresetMatch(values) || "CUSTOM",
  );

  const handleChange = React.useCallback(
    (newLanguageCodes: string[]) => {
      onChange(newLanguageCodes);
    },
    [onChange],
  );

  const handleSetPreset = React.useCallback(
    (preset: LanguagePreset | "CUSTOM") => {
      setSelectedPreset(preset);
      if (preset !== "CUSTOM") {
        handleChange(Array.from(LANGUAGE_PRESETS[preset].values()));
      }
    },
    [handleChange],
  );

  return (
    <div className={css.container}>
      {label && (
        <label className={css.label} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Radio.Group
          value={selectedPreset}
          onChange={(e) => handleSetPreset(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="ALL">All languages</Radio.Button>
          <Radio.Button value="ENGLISH">English</Radio.Button>
          <Radio.Button value="CUSTOM">Custom selection</Radio.Button>
        </Radio.Group>
      </div>

      {selectedPreset === "CUSTOM" && (
        <LanguagesFilter id={inputId} onChange={handleChange} values={values} />
      )}
    </div>
  );
};

export default LanguagesField;

const LANGUAGE_PRESETS = {
  ALL: new Set<string>([]),
  ENGLISH: new Set(["EN"]),
};
