import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { App } from "antd5";

import * as tracking from "lib/tracking";
import CentredSpinner from "../../../lib/core_components/CentredSpinner";
import { OpportunityUpdateInput } from "../../../lib/generated/app-service-gql/graphql";
import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import { useUpdateOpportunity } from "../../../lib/hooks/api/opportunities/useUpdateOpportunity";
import { EM_DASH } from "../../../lib/utils";
import { Flex, Text } from "../../../styles/utility-components";
import { Currency, FormattedDate } from "../../app_layout/Typography";
import { getFormDirtyValues } from "../../form_components/formUtils";
import OpportunityAssignedSelect from "../opportunity_assigned_select/OpportunityAssignedSelect";
import { OpportunityStageSelect } from "../OpportunityStageSelect";
import { filterOpportunityDefaultValues } from "../utils";

type OpportunityDetailSidebarProps = {
  opportunity: OpportunityDetails;
};

export default function OpportunityDetailSidebar({ opportunity }: OpportunityDetailSidebarProps) {
  const { message } = App.useApp();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<OpportunityUpdateInput>();

  useEffect(() => {
    if (opportunity) {
      const filteredDefaultValues = filterOpportunityDefaultValues(opportunity);
      reset(filteredDefaultValues);
    }
  }, [opportunity, reset]);

  const { mutate: updateOpportunity, isLoading: updateOpportunityLoading } = useUpdateOpportunity({
    onSuccess: () => {
      tracking.logEvent(tracking.EventNames.opportunityUpdated, {});
      message.success("Opportunity updated successfully");
    },
  });

  const onSubmit: SubmitHandler<OpportunityUpdateInput> = (values: OpportunityUpdateInput) => {
    const updatedValues = getFormDirtyValues(dirtyFields, values);
    const filteredPayload = { ...updatedValues, id: values.id };
    updateOpportunity({ opportunity: filteredPayload });
  };

  // Force form to update on change
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  });

  if (updateOpportunityLoading) {
    return (
      <Sidebar flexDirection="column" gap={16}>
        <CentredSpinner />
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <SidebarContentItem>
        <Text h2>Details</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={16}>
            <Flex flexDirection="column" gap={16}>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Stage</SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Assigned</SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>
                  Buyer{opportunity.buyers.length > 1 && "s"}
                </SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Value</SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Close</SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Est. Close</SidebarContentItemKey>
              </Flex>
              <Flex alignItems="center" height={32}>
                <SidebarContentItemKey>Location</SidebarContentItemKey>
              </Flex>
            </Flex>
            {/* TODO: improve styling */}
            <Flex flexDirection="column" gap={16}>
              <Flex alignItems="center" height={32}>
                <OpportunityStageSelect
                  name="stageId"
                  control={control}
                  label=""
                  variant="borderless"
                />
              </Flex>
              <Flex alignItems="center" height={32}>
                <OpportunityAssignedSelect
                  name="assignedToId"
                  label=""
                  control={control}
                  variant="borderless"
                />
              </Flex>
              <Flex alignItems="center" height={32}>
                {/* TODO: confirm this should be here as there could be multiple buyers */}
                {opportunity.buyers.length === 0
                  ? EM_DASH
                  : opportunity.buyers.map((buyer) => <Text key={buyer.id}>{buyer.name}</Text>)}
              </Flex>
              <Flex alignItems="center" height={32}>
                <Currency value={opportunity?.value} />
              </Flex>
              <Flex alignItems="center" height={32}>
                <FormattedDate date={opportunity?.closeDate} />
              </Flex>
              <Flex alignItems="center" height={32}>
                <Text>WIP</Text>
              </Flex>
              <Flex alignItems="center" height={32}>
                <Text>WIP</Text>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </SidebarContentItem>
      <Divider />
      <SidebarContentItem>
        <Text h2>Actions</Text>
      </SidebarContentItem>
    </Sidebar>
  );
}

const Sidebar = styled(Flex)(({ theme }) => ({
  flexDirection: "column",

  width: 480,

  borderLeft: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const SidebarContentItem = styled(Flex)({
  flexDirection: "column",
  gap: 16,

  padding: "20px 24px",
});

const SidebarContentItemKey = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
  whiteSpace: "nowrap",
}));

const Divider = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));
