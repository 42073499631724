import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  OrganisationsQuery,
  OrganisationsQueryVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const organisationsQuery = graphql(`
  query organisations($ids: [String!]!) {
    organisations(ids: $ids) {
      id
      name
      url
      email
      phone
      address
      town
      postalCode
      primaryRole
    }
  }
`);

export function useOrganisations(
  ids: OrganisationsQueryVariables["ids"],
  options?: UseQueryOptions<OrganisationsQuery, unknown, OrganisationsQuery, QueryKey>,
) {
  return useGraphQlQuery(["organisations", ids], organisationsQuery, [{ ids }], {
    ...options,
  });
}
