import React, { useContext, useEffect, useRef, useState } from "react";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import classNames from "classnames";

import css from "./FileViewer.module.scss";

type FileViewerContextProviderProps = {
  children: React.ReactNode;
};

type ViewerContext = {
  instance: WebViewerInstance | null;
  setInstance: React.Dispatch<React.SetStateAction<WebViewerInstance | null>>;
};

type FileViewerProps = {
  documentUrl: string;
  fileExtension?: string;
};

export default function FileViewer({ documentUrl, fileExtension = "pdf" }: FileViewerProps) {
  const viewer = useRef(null);
  const { instance, setInstance } = useFileViewerInstance();

  useEffect(() => {
    if (viewer.current === null) return;

    WebViewer(
      {
        path: "/apryse",
        licenseKey: window.apryseLicenseKey,
        enableAnnotations: false,
        disabledElements: [
          "header",
          "toolsHeader",
          "textPopup",
          "contextMenuPopup",
          "searchPanel",
          "printModal",
          "errorModal",
        ],
      },
      viewer.current,
    )
      .then((instance) => {
        setInstance(instance);

        const { documentViewer } = instance.Core;
        documentViewer.addEventListener("documentLoaded", function () {
          documentViewer.zoomTo(1.0);
        });

        instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.P);
      })
      .catch((error) => console.log(error));
  }, [setInstance]);

  useEffect(() => {
    if (instance) {
      instance.UI.loadDocument(documentUrl, { extension: fileExtension });
    }
  }, [documentUrl, fileExtension, instance]);

  return <div className={classNames("webviewer", css.fileViewer)} ref={viewer}></div>;
}

const FileViewerContext = React.createContext<ViewerContext | undefined>(undefined);

export function FileViewerContextProvider({ children }: FileViewerContextProviderProps) {
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);

  return (
    <FileViewerContext.Provider value={{ instance, setInstance }}>
      {children}
    </FileViewerContext.Provider>
  );
}

export function useFileViewerInstance() {
  const context = useContext(FileViewerContext);

  if (!context) {
    throw new Error("useFileViewerInstance must be used within a FileViewerContextProvider");
  }

  return {
    instance: context.instance,
    setInstance: context.setInstance,
  };
}
