/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AggregateNotices200Response,
  AggregateNoticesRequest,
  AggregationAnalysisResponse,
  AnalyseDocumentSearchRequest,
  AnalyseFrameworkSearchRequest,
  AnalyseNoticeAggregationRequest,
  AnalyseNoticeSearchRequest,
  DocumentSignalsRequest,
  NoticeSignalsRequest,
  NoticeSignalsResponse,
  RuleResultsRequestSchema,
  RuleResultsResponse,
  SearchAnalysisResponse,
  SearchDocumentsRequest,
  SearchFrameworksRequest,
  SearchNoticesRequest,
  SearchResponse,
} from '../models';
import {
    AggregateNotices200ResponseFromJSON,
    AggregateNotices200ResponseToJSON,
    AggregateNoticesRequestFromJSON,
    AggregateNoticesRequestToJSON,
    AggregationAnalysisResponseFromJSON,
    AggregationAnalysisResponseToJSON,
    AnalyseDocumentSearchRequestFromJSON,
    AnalyseDocumentSearchRequestToJSON,
    AnalyseFrameworkSearchRequestFromJSON,
    AnalyseFrameworkSearchRequestToJSON,
    AnalyseNoticeAggregationRequestFromJSON,
    AnalyseNoticeAggregationRequestToJSON,
    AnalyseNoticeSearchRequestFromJSON,
    AnalyseNoticeSearchRequestToJSON,
    DocumentSignalsRequestFromJSON,
    DocumentSignalsRequestToJSON,
    NoticeSignalsRequestFromJSON,
    NoticeSignalsRequestToJSON,
    NoticeSignalsResponseFromJSON,
    NoticeSignalsResponseToJSON,
    RuleResultsRequestSchemaFromJSON,
    RuleResultsRequestSchemaToJSON,
    RuleResultsResponseFromJSON,
    RuleResultsResponseToJSON,
    SearchAnalysisResponseFromJSON,
    SearchAnalysisResponseToJSON,
    SearchDocumentsRequestFromJSON,
    SearchDocumentsRequestToJSON,
    SearchFrameworksRequestFromJSON,
    SearchFrameworksRequestToJSON,
    SearchNoticesRequestFromJSON,
    SearchNoticesRequestToJSON,
    SearchResponseFromJSON,
    SearchResponseToJSON,
} from '../models';

export interface AggregateNoticesOperationRequest {
    aggregateNoticesRequest: AggregateNoticesRequest;
}

export interface AnalyseAggregateNoticesRequest {
    analyseNoticeAggregationRequest: AnalyseNoticeAggregationRequest;
}

export interface AnalyseSearchDocumentsRequest {
    analyseDocumentSearchRequest: AnalyseDocumentSearchRequest;
}

export interface AnalyseSearchFrameworksRequest {
    analyseFrameworkSearchRequest: AnalyseFrameworkSearchRequest;
}

export interface AnalyseSearchNoticesRequest {
    analyseNoticeSearchRequest: AnalyseNoticeSearchRequest;
}

export interface CalculateDocumentSignalsRequest {
    documentSignalsRequest: DocumentSignalsRequest;
}

export interface CalculateNoticeSignalsRequest {
    noticeSignalsRequest: NoticeSignalsRequest;
}

export interface RuleResultsRequest {
    ruleResultsRequestSchema: RuleResultsRequestSchema;
}

export interface SearchDocumentsOperationRequest {
    searchDocumentsRequest: SearchDocumentsRequest;
}

export interface SearchFrameworksOperationRequest {
    searchFrameworksRequest: SearchFrameworksRequest;
}

export interface SearchNoticesOperationRequest {
    searchNoticesRequest: SearchNoticesRequest;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * 
     */
    async aggregateNoticesRaw(requestParameters: AggregateNoticesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AggregateNotices200Response>> {
        if (requestParameters.aggregateNoticesRequest === null || requestParameters.aggregateNoticesRequest === undefined) {
            throw new runtime.RequiredError('aggregateNoticesRequest','Required parameter requestParameters.aggregateNoticesRequest was null or undefined when calling aggregateNotices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/aggregate-notices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AggregateNoticesRequestToJSON(requestParameters.aggregateNoticesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AggregateNotices200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async aggregateNotices(requestParameters: AggregateNoticesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AggregateNotices200Response> {
        const response = await this.aggregateNoticesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async analyseAggregateNoticesRaw(requestParameters: AnalyseAggregateNoticesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AggregationAnalysisResponse>> {
        if (requestParameters.analyseNoticeAggregationRequest === null || requestParameters.analyseNoticeAggregationRequest === undefined) {
            throw new runtime.RequiredError('analyseNoticeAggregationRequest','Required parameter requestParameters.analyseNoticeAggregationRequest was null or undefined when calling analyseAggregateNotices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/analyse-aggregate-notices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyseNoticeAggregationRequestToJSON(requestParameters.analyseNoticeAggregationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AggregationAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async analyseAggregateNotices(requestParameters: AnalyseAggregateNoticesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AggregationAnalysisResponse> {
        const response = await this.analyseAggregateNoticesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async analyseSearchDocumentsRaw(requestParameters: AnalyseSearchDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchAnalysisResponse>> {
        if (requestParameters.analyseDocumentSearchRequest === null || requestParameters.analyseDocumentSearchRequest === undefined) {
            throw new runtime.RequiredError('analyseDocumentSearchRequest','Required parameter requestParameters.analyseDocumentSearchRequest was null or undefined when calling analyseSearchDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/analyse-search-documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyseDocumentSearchRequestToJSON(requestParameters.analyseDocumentSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async analyseSearchDocuments(requestParameters: AnalyseSearchDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchAnalysisResponse> {
        const response = await this.analyseSearchDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async analyseSearchFrameworksRaw(requestParameters: AnalyseSearchFrameworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchAnalysisResponse>> {
        if (requestParameters.analyseFrameworkSearchRequest === null || requestParameters.analyseFrameworkSearchRequest === undefined) {
            throw new runtime.RequiredError('analyseFrameworkSearchRequest','Required parameter requestParameters.analyseFrameworkSearchRequest was null or undefined when calling analyseSearchFrameworks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/analyse-search-frameworks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyseFrameworkSearchRequestToJSON(requestParameters.analyseFrameworkSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async analyseSearchFrameworks(requestParameters: AnalyseSearchFrameworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchAnalysisResponse> {
        const response = await this.analyseSearchFrameworksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async analyseSearchNoticesRaw(requestParameters: AnalyseSearchNoticesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchAnalysisResponse>> {
        if (requestParameters.analyseNoticeSearchRequest === null || requestParameters.analyseNoticeSearchRequest === undefined) {
            throw new runtime.RequiredError('analyseNoticeSearchRequest','Required parameter requestParameters.analyseNoticeSearchRequest was null or undefined when calling analyseSearchNotices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/analyse-search-notices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyseNoticeSearchRequestToJSON(requestParameters.analyseNoticeSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async analyseSearchNotices(requestParameters: AnalyseSearchNoticesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchAnalysisResponse> {
        const response = await this.analyseSearchNoticesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async calculateDocumentSignalsRaw(requestParameters: CalculateDocumentSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeSignalsResponse>> {
        if (requestParameters.documentSignalsRequest === null || requestParameters.documentSignalsRequest === undefined) {
            throw new runtime.RequiredError('documentSignalsRequest','Required parameter requestParameters.documentSignalsRequest was null or undefined when calling calculateDocumentSignals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/calculate-document-signals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentSignalsRequestToJSON(requestParameters.documentSignalsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeSignalsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async calculateDocumentSignals(requestParameters: CalculateDocumentSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeSignalsResponse> {
        const response = await this.calculateDocumentSignalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async calculateNoticeSignalsRaw(requestParameters: CalculateNoticeSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoticeSignalsResponse>> {
        if (requestParameters.noticeSignalsRequest === null || requestParameters.noticeSignalsRequest === undefined) {
            throw new runtime.RequiredError('noticeSignalsRequest','Required parameter requestParameters.noticeSignalsRequest was null or undefined when calling calculateNoticeSignals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/calculate-notice-signals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoticeSignalsRequestToJSON(requestParameters.noticeSignalsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoticeSignalsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async calculateNoticeSignals(requestParameters: CalculateNoticeSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoticeSignalsResponse> {
        const response = await this.calculateNoticeSignalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async ruleResultsRaw(requestParameters: RuleResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleResultsResponse>> {
        if (requestParameters.ruleResultsRequestSchema === null || requestParameters.ruleResultsRequestSchema === undefined) {
            throw new runtime.RequiredError('ruleResultsRequestSchema','Required parameter requestParameters.ruleResultsRequestSchema was null or undefined when calling ruleResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/rule-results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleResultsRequestSchemaToJSON(requestParameters.ruleResultsRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleResultsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async ruleResults(requestParameters: RuleResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleResultsResponse> {
        const response = await this.ruleResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchDocumentsRaw(requestParameters: SearchDocumentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResponse>> {
        if (requestParameters.searchDocumentsRequest === null || requestParameters.searchDocumentsRequest === undefined) {
            throw new runtime.RequiredError('searchDocumentsRequest','Required parameter requestParameters.searchDocumentsRequest was null or undefined when calling searchDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/search-documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchDocumentsRequestToJSON(requestParameters.searchDocumentsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchDocuments(requestParameters: SearchDocumentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResponse> {
        const response = await this.searchDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchFrameworksRaw(requestParameters: SearchFrameworksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResponse>> {
        if (requestParameters.searchFrameworksRequest === null || requestParameters.searchFrameworksRequest === undefined) {
            throw new runtime.RequiredError('searchFrameworksRequest','Required parameter requestParameters.searchFrameworksRequest was null or undefined when calling searchFrameworks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/search-frameworks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchFrameworksRequestToJSON(requestParameters.searchFrameworksRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchFrameworks(requestParameters: SearchFrameworksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResponse> {
        const response = await this.searchFrameworksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchNoticesRaw(requestParameters: SearchNoticesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResponse>> {
        if (requestParameters.searchNoticesRequest === null || requestParameters.searchNoticesRequest === undefined) {
            throw new runtime.RequiredError('searchNoticesRequest','Required parameter requestParameters.searchNoticesRequest was null or undefined when calling searchNotices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/search/search-notices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchNoticesRequestToJSON(requestParameters.searchNoticesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchNotices(requestParameters: SearchNoticesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResponse> {
        const response = await this.searchNoticesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
