import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  GetBuyerStatsSupplierSignalRequest,
  SupplierSignalBuyerResponse,
} from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type Request = GetBuyerStatsSupplierSignalRequest["supplierSignalBuyersRequest"];

export function useBuyerStatsSupplierSignal(
  request: Request,
  options?: UseQueryOptions<
    SupplierSignalBuyerResponse,
    unknown,
    SupplierSignalBuyerResponse,
    (string | Request)[]
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["buyerStatsSupplierSignal", request],
    async () => api.getBuyerStatsSupplierSignal({ supplierSignalBuyersRequest: request }),
    options,
  );
}
