import React from "react";

const TOOLTIP_TITLE_WARNING = "You've added a generic keyword";
const TOOLTIP_TITLE_DEFAULT = "Number of notices found";
const TOOLTIP_DESCRIPTION_WARNING = "Generic keywords can pull irrelevant notices into your feed.";
const TOOLTIP_DESCRIPTION_DEFAULT =
  "This number shows how many notices have been returned with activity in the past six months.";
const TOOLTIP_DESCRIPTION_SUPPLEMENTARY =
  "This timeframe is a useful indicator of the keyword's relevance in your feed, but you will also be able to see historic notices from further back, too.";

type KeywordInputTooltipProps = {
  warning: boolean | 0 | undefined;
};

export default function KeywordInputTooltip({ warning }: KeywordInputTooltipProps) {
  const tooltipTitle = warning ? TOOLTIP_TITLE_WARNING : TOOLTIP_TITLE_DEFAULT;
  const tooltipDescription = warning
    ? `${TOOLTIP_DESCRIPTION_WARNING}\n\n${TOOLTIP_DESCRIPTION_SUPPLEMENTARY}`
    : `${TOOLTIP_DESCRIPTION_DEFAULT}\n\n${TOOLTIP_DESCRIPTION_SUPPLEMENTARY}`;

  return (
    <div>
      <p>{tooltipTitle}</p>
      <p>{tooltipDescription}</p>
    </div>
  );
}
