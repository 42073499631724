import React from "react";

import { Currency } from "components/app_layout/Typography";
import { useSpendExists } from "lib/generated/spend-data-api/spendDataManagementAPI";

type StaticSpendDataCellProps = {
  amount: number | null | undefined;
  buyerId: string;
};

export function StaticSpendDataCell({ amount, buyerId }: StaticSpendDataCellProps) {
  const { data } = useSpendExists(
    { buyerIds: [buyerId] },
    {
      query: {
        enabled: !window.guestUser,
      },
    },
  );

  if (data && !data.exists) {
    return null;
  }

  return <Currency value={amount} />;
}
