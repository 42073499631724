import * as tracking from "lib/tracking";
import { getCSRFToken } from "lib/utils";

const SIGN_OUT_REDIRECT_PATH = "https://www.stotles.com";

export const performLogout = async (): Promise<void> => {
  const csrfToken = getCSRFToken() || "";
  tracking.logout();
  const resp = await fetch("/users/sign_out", {
    method: "DELETE",
    headers: { "X-CSRF-Token": csrfToken },
  });
  const json = await resp.json();
  window.location.href = json.destination || SIGN_OUT_REDIRECT_PATH;
};

export const redirectToSignUp = () => {
  window.location.href = "https://app.stotles.com/get-started";
};
