import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetContactsForBuyerResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";

export function useBuyerContacts(
  buyerId: number,
  options?: UseQueryOptions<
    GetContactsForBuyerResponse,
    unknown,
    GetContactsForBuyerResponse,
    [string, string, number]
  >,
) {
  const api = useStotlesApi();
  return useQuery(["buyers", "contacts", buyerId], () => api.getContactsForBuyer(buyerId), options);
}
