/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes a specific aggregation operation performed on one field.
 *   The aggregation will be identified in the result by 'name'.
 *   The set of allowed field/operation combinations will depend on the data type used.
 *   All data types should allow 'COUNT' operation on 'id' field, which will
 *   return the count of objects in each group (similar to SELECT COUNT(*) FROM ... GROUP BY ...).
 * @export
 * @interface NoticeSearchAggregationQueryAggregationsInner
 */
export interface NoticeSearchAggregationQueryAggregationsInner {
    /**
     * This name will be used in the aggregation query's result.
     * @type {string}
     * @memberof NoticeSearchAggregationQueryAggregationsInner
     */
    name: string;
    /**
     * Field of the data type to use for the aggregation operation.
     *       Depending on the data type, only some fields can be aggregated.
     * @type {string}
     * @memberof NoticeSearchAggregationQueryAggregationsInner
     */
    field: NoticeSearchAggregationQueryAggregationsInnerFieldEnum;
    /**
     * Aggregation operation to perform.
     *       Note that depending on the data type, only some operations can be applied to specific fields.
     * @type {string}
     * @memberof NoticeSearchAggregationQueryAggregationsInner
     */
    operation: NoticeSearchAggregationQueryAggregationsInnerOperationEnum;
}


/**
 * @export
 */
export const NoticeSearchAggregationQueryAggregationsInnerFieldEnum = {
    Id: 'id',
    Value: 'value',
    BuyerId: 'buyer.id',
    SupplierId: 'supplier.id'
} as const;
export type NoticeSearchAggregationQueryAggregationsInnerFieldEnum = typeof NoticeSearchAggregationQueryAggregationsInnerFieldEnum[keyof typeof NoticeSearchAggregationQueryAggregationsInnerFieldEnum];

/**
 * @export
 */
export const NoticeSearchAggregationQueryAggregationsInnerOperationEnum = {
    Count: 'COUNT',
    Sum: 'SUM',
    Average: 'AVERAGE'
} as const;
export type NoticeSearchAggregationQueryAggregationsInnerOperationEnum = typeof NoticeSearchAggregationQueryAggregationsInnerOperationEnum[keyof typeof NoticeSearchAggregationQueryAggregationsInnerOperationEnum];


/**
 * Check if a given object implements the NoticeSearchAggregationQueryAggregationsInner interface.
 */
export function instanceOfNoticeSearchAggregationQueryAggregationsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "field" in value;
    isInstance = isInstance && "operation" in value;

    return isInstance;
}

export function NoticeSearchAggregationQueryAggregationsInnerFromJSON(json: any): NoticeSearchAggregationQueryAggregationsInner {
    return NoticeSearchAggregationQueryAggregationsInnerFromJSONTyped(json, false);
}

export function NoticeSearchAggregationQueryAggregationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchAggregationQueryAggregationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'field': json['field'],
        'operation': json['operation'],
    };
}

export function NoticeSearchAggregationQueryAggregationsInnerToJSON(value?: NoticeSearchAggregationQueryAggregationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'field': value.field,
        'operation': value.operation,
    };
}

