import * as React from "react";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input } from "antd5";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import UserInitials from "components/comments/UserInitials";
import { EditListMenu } from "components/notices/EditListMenu";
import { AddNewListButton } from "components/user_lists/AddNewListButton";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useNoticeLists } from "lib/hooks/api/notices/lists/useNoticeLists";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { generateNoticeSearchUrl } from "./utils";

import css from "./ListsTable.module.scss";

const listColumns: ColumnType<GetRecordListsResponseResult>[] = [
  {
    title: "List name",
    dataIndex: "name",
    key: "name",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
    ellipsis: true,
    render: (_, list) => (
      <div className={css.listName}>
        <EllipsisTooltipText fullText={list.name} />
        <div className={SHOW_ON_HOVER}>
          <EditListMenu list={list} />
        </div>
      </div>
    ),
    sorter: stringSort((list) => list.name),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Notices",
    dataIndex: "recordCount",
    key: "record_count",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
    render: (_, { recordCount }) => recordCount,
    sorter: numberSort((list) => list.recordCount),
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
  },
  // TODO: add once we have this functionality
  // {
  //   title: "Shared with",
  //   dataIndex: "visibility",
  //   key: "visibility",
  //   sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
  // },
  {
    title: "Owner",
    render: (_, { user }) => (
      <div className={css.user}>
        <UserInitials firstName={user.firstName} lastName={user.lastName} /> {user.firstName}
      </div>
    ),
    key: "owner_name",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
  },
];

export function ListsTable() {
  const { isLoading, isError, data } = useNoticeLists();
  const [tab, setTab] = React.useState<"allLists" | "myLists">("myLists");
  const [search, setSearch] = React.useState("");
  const { logEvent } = useTracking();

  const selectedLists = tab === "allLists" ? data?.allLists : data?.userLists;
  const results = selectedLists?.filter((list) =>
    list.name.toLowerCase().includes(search.toLowerCase()),
  );

  const onTabChange = (key: "allLists" | "myLists") => {
    logEvent(EventNames.tabChanged, {
      "Tab selected": key === "allLists" ? "All lists" : "My lists",
    });
    setTab(key);
  };

  const onRow = (record: GetRecordListsResponseResult) => ({
    onClick(e: React.MouseEvent) {
      e.preventDefault();
      logEvent(EventNames.noticeListClicked, {
        "List type": "Notice list",
        "List name": record.name,
        "List ID": record.id,
      });
      window.location.assign(generateNoticeSearchUrl({ noticeLists: [record.id.toString()] }));
    },
  });

  return (
    <TrackingProvider data={{ "Context source": "Notice lists table" }}>
      <DetailsSection
        title={
          <div className={css.title}>
            Notice lists{" "}
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "allLists",
                    label: "All",
                    onClick: () => onTabChange("allLists"),
                  },
                  {
                    key: "myLists",
                    label: "Created by me",
                    onClick: () => onTabChange("myLists"),
                  },
                ],
                selectable: true,
                defaultSelectedKeys: ["myLists"],
              }}
            >
              <Button type="link" className={css.menuLink}>
                {tab === "allLists" ? "all" : "created by me"} <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        }
        action={<AddNewListButton modal />}
        aria-label="Notice lists"
      >
        <div className={css.listTable}>
          <div>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              className={css.search}
            />
          </div>
          <SkeletonTable loading={isLoading} active={!isLoading} pageSize={5} columns={listColumns}>
            <Table
              columns={listColumns}
              dataSource={results}
              pagination={false}
              onRow={onRow}
              rowKey={(record) => record.id.toString()}
              isError={isError}
            />
          </SkeletonTable>{" "}
        </div>
      </DetailsSection>
    </TrackingProvider>
  );
}
