import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  ExportFrameworksMutation,
  ExportFrameworksMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const exportFrameworks = graphql(`
  mutation exportFrameworks(
    $filters: SearchFrameworksRequest!
    $exportFormat: ExportFormatArgs!
    $selectedFrameworks: SelectedFrameworks!
    $selectedFields: [String!]
  ) {
    exportFrameworks(
      SearchFrameworksRequest: $filters
      ExportFormat: $exportFormat
      SelectedFrameworks: $selectedFrameworks
      SelectedFields: $selectedFields
    ) {
      id
    }
  }
`);

type UseFrameworksExport = UseMutationResult<
  ExportFrameworksMutation["exportFrameworks"],
  unknown,
  ExportFrameworksMutationVariables,
  unknown
>;

export function useFrameworksExport(
  options?: UseMutationOptions<
    ExportFrameworksMutation,
    unknown,
    ExportFrameworksMutationVariables,
    unknown
  >,
): UseFrameworksExport {
  const { data, ...rest } = useGraphQlMutation(exportFrameworks, options);
  return { data: data?.exportFrameworks, ...rest } as unknown as UseFrameworksExport;
}
