import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "../../../generated/app-service-gql";
import {
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const UPDATE_OPPORTUNITY_MUTATION = graphql(`
  mutation updateOpportunity($opportunity: OpportunityUpdateInput!) {
    updateOpportunity(opportunity: $opportunity) {
      id
      name
      description
      createdAt
      updatedAt
      value
      closeDate
    }
  }
`);

export function useUpdateOpportunity(
  options: UseMutationOptions<
    UpdateOpportunityMutation,
    unknown,
    UpdateOpportunityMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(UPDATE_OPPORTUNITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["opportunities"]);
      options?.onSuccess?.(data, variables, context);
    },
  });
  return { data, ...rest };
}
