/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewBuyer } from './NewBuyer';
import {
    NewBuyerFromJSON,
    NewBuyerFromJSONTyped,
    NewBuyerToJSON,
} from './NewBuyer';

/**
 * 
 * @export
 * @interface DistinctCandidate
 */
export interface DistinctCandidate {
    /**
     * 
     * @type {string}
     * @memberof DistinctCandidate
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DistinctCandidate
     */
    distinctBuyers?: Array<string>;
    /**
     * 
     * @type {Array<NewBuyer>}
     * @memberof DistinctCandidate
     */
    newDistinctBuyers?: Array<NewBuyer>;
}

/**
 * Check if a given object implements the DistinctCandidate interface.
 */
export function instanceOfDistinctCandidate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DistinctCandidateFromJSON(json: any): DistinctCandidate {
    return DistinctCandidateFromJSONTyped(json, false);
}

export function DistinctCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistinctCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'distinctBuyers': !exists(json, 'distinct_buyers') ? undefined : json['distinct_buyers'],
        'newDistinctBuyers': !exists(json, 'new_distinct_buyers') ? undefined : ((json['new_distinct_buyers'] as Array<any>).map(NewBuyerFromJSON)),
    };
}

export function DistinctCandidateToJSON(value?: DistinctCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'distinct_buyers': value.distinctBuyers,
        'new_distinct_buyers': value.newDistinctBuyers === undefined ? undefined : ((value.newDistinctBuyers as Array<any>).map(NewBuyerToJSON)),
    };
}

