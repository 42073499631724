import React from "react";
import { Control } from "react-hook-form";

import { Checkbox, DateFilter, DateRange } from "../../form_components/Inputs";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface ContractDatesFilterContentProps {
  control: Control<NoticeFilters, unknown>;
  hideNullsWhenSet: (fieldName: "closeDate" | "expiryDate", value: DateFilter) => void;
}

function ContractDatesFilterContent({
  control,
  hideNullsWhenSet,
}: ContractDatesFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Date filters">
      <div>
        <DateRange
          name="closeDate.filter"
          control={control}
          label="Close date"
          onChange={(value) => hideNullsWhenSet("closeDate", value)}
        />
        <Checkbox
          label=""
          name="closeDate.hideNulls"
          control={control}
          fieldLabel="Hide notices without close dates"
        />
      </div>
      <div>
        <DateRange
          name="expiryDate.filter"
          control={control}
          label="Expiry date"
          onChange={(value) => hideNullsWhenSet("expiryDate", value)}
        />
        <Checkbox
          label=""
          name="expiryDate.hideNulls"
          control={control}
          fieldLabel="Hide notices without expiry dates"
        />
      </div>
      <DateRange name="publishDate" control={control} label="Published date" />
    </div>
  );
}

export default ContractDatesFilterContent;
