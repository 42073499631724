import { useMemo } from "react";

import { useAllCpvCodes } from "lib/hooks/api/cpv_codes/useAllCpvCodes";
import { CpvCode } from "./cpv_code_utils";

export type CpvTreeNode = {
  key: string;
  pId: string | null; //parent cpv code
  value: string; //same as id
  isLeaf?: boolean;
  label: string; //label shown in the selector ("CPV name: CPV code")
  title: React.ReactNode; // react node used in the ant tree
  isSignal: boolean;
  children?: CpvTreeNode[] | null;
};

type TreeDataByCode = Record<string, CpvTreeNode>;
type TreeDataByParent = Record<string, CpvTreeNode[]>;

const getParentToChildren = (allCpvCodes: CpvCode[]) => {
  const codeToNode: TreeDataByParent = {};

  allCpvCodes.forEach((p) => {
    const isLeaf = !p.childIds || p.childIds.length === 0;
    if (!p.parentCode) {
      const node = {
        key: p.code,
        pId: p.parentCode ?? null,
        value: p.code,
        title: p.name,
        label: `${p.code}: ${p.name}`,
        isLeaf: isLeaf,
        children: isLeaf ? null : [],
        isSignal: p.isSignal,
      };

      if (!codeToNode["NULL"]) {
        codeToNode["NULL"] = [];
      }
      codeToNode["NULL"].push(node);
      return;
    }
    if (!codeToNode[p.parentCode]) {
      codeToNode[p.parentCode] = [];
    }
    codeToNode[p.parentCode].push({
      key: p.code,
      pId: p.parentCode ?? null,
      value: p.code,
      title: p.name,
      label: `${p.code}: ${p.name}`,
      isLeaf: isLeaf,
      children: isLeaf ? null : [],
      isSignal: p.isSignal,
    });
  });
  return codeToNode;
};

function getTreeData(allCpvCodes: CpvCode[]): CpvTreeNode[] {
  const pCodeToChildren = getParentToChildren(allCpvCodes);

  const populateChildren = (node: CpvTreeNode) => {
    const children = pCodeToChildren[node.key] ?? [];
    if (!children || children.length === 0) {
      return;
    }
    for (const child of children) {
      populateChildren(child);
    }
    node.children = children;
    return;
  };

  const treeData: TreeDataByCode = {};
  const topLevelNodes = pCodeToChildren["NULL"];
  for (const node of topLevelNodes) {
    populateChildren(node);
    treeData[node.key] = node;
  }

  // get nodes, sorted alphabetically
  const nodes = Object.values(treeData).sort((a, b) =>
    (a.key as string).localeCompare(b.key as string),
  );

  return nodes;
}

export function useCpvTree(signalCodeIds: string[]) {
  const { data, ...apiResponse } = useAllCpvCodes();
  const { signalCpvCodes, allCpvCodes } = useMemo(() => {
    if (data) {
      const signalCpvCodes: CpvCode[] = [];
      const allCpvCodes: CpvCode[] = data.cpvCodes.map((cpv) => {
        const isSignal = signalCodeIds.includes(cpv.code);
        if (isSignal) {
          signalCpvCodes.push({
            ...cpv,
            isSignal,
          });
        }
        return {
          ...cpv,
          isSignal,
        };
      });
      return { signalCpvCodes, allCpvCodes };
    }
    return { signalCpvCodes: undefined, allCpvCodes: undefined };
  }, [data, signalCodeIds]);

  const cpvTree = useMemo((): CpvTreeNode[] => {
    if (!allCpvCodes) {
      return [];
    }
    return getTreeData(allCpvCodes);
  }, [allCpvCodes]);

  return { cpvTree, signalCpvCodes, allCpvCodes, ...apiResponse };
}
