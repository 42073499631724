import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { AllBuyersStatsRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

const ExportBuyersRequestFormatEnum = {
  Csv: "csv",
  Xlsx: "xlsx",
} as const;
export type ExportBuyersRequestFormatEnum =
  (typeof ExportBuyersRequestFormatEnum)[keyof typeof ExportBuyersRequestFormatEnum];

type ExportBuyersRequest = AllBuyersStatsRequest & { format: ExportBuyersRequestFormatEnum };

export function useExportBuyers(
  options?: UseMutationOptions<Blob, unknown, ExportBuyersRequest, unknown>,
) {
  const api = useOpenApi();

  return useMutation(async (req) => {
    if (req.format == "xlsx") {
      return await api.allBuyersStatsXlsx({
        allBuyersStatsRequest: req,
      });
    } else {
      return await api.allBuyersStatsCsv({
        allBuyersStatsRequest: req,
      });
    }
  }, options);
}
