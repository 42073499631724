import * as React from "react";
import { RightOutlined } from "@ant-design/icons";
import { message, Switch as AntSwitch } from "antd5";
import { Link } from "wouter";

import { PersonalNotificationSettings } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import SettingsContentContainer from "./SettingsContentContainer";

import css from "./Notifications.module.scss";

export function Index() {
  const api = useStotlesApi();
  const [loading, setLoading] = React.useState(true);
  const [settings, setSettings] = React.useState<PersonalNotificationSettings | null>(null);

  React.useEffect(() => {
    const loadState = async () => {
      try {
        const initialSettings = await api.getPersonalNotificationSettings();
        setSettings(initialSettings);
        setLoading(false);
      } catch (e) {
        void message.error(
          "Unable to get notification settings; please contact us if the problem persists",
        );
      }
    };
    void loadState();
  }, [api]);

  const onMainNotificationSettingsChanged = React.useCallback(
    async (checked) => {
      if (!settings) {
        // should be unreachable; loading settings would have thrown
        return;
      }

      try {
        setLoading(true);
        let newSettings: PersonalNotificationSettings = {
          send_app_activity_notifications: settings.send_app_activity_notifications,
          notifications_enabled: checked,
        };
        setSettings(newSettings);
        newSettings = await api.updateNotificationSettings(newSettings);
        setSettings(newSettings);
        void message.success("Changes saved");
      } catch (e) {
        setSettings(settings);
        void message.error(
          "Unable to update notification settings; please contact us if the problem persists",
        );
      } finally {
        setLoading(false);
      }
    },
    [api, settings],
  );

  return (
    <SettingsContentContainer title="Notifications">
      <div className={css.notifications}>
        <div className={css.notificationTypes}>
          <h2>Your notifications</h2>
          <SettingsLink to="/email">Email</SettingsLink>{" "}
        </div>
        <div className={css.notificationTypes}>
          <h2>Company notifications</h2>
          <SettingsLink to="/teams">Teams</SettingsLink>
          <SettingsLink to="/slack">Slack</SettingsLink>{" "}
        </div>
        <div className={css.allSwitch}>
          All notifications
          <AntSwitch
            loading={loading}
            checked={settings?.notifications_enabled ?? true}
            onChange={onMainNotificationSettingsChanged}
          />
        </div>
      </div>
    </SettingsContentContainer>
  );
}

type SettingsLinkProps = React.PropsWithChildren<{
  to: string;
}>;

function SettingsLink({ to, children }: SettingsLinkProps): JSX.Element {
  return (
    <Link to={to} className={css.settingsLink}>
      <span>{children}</span>
      <RightOutlined />
    </Link>
  );
}
