import React, { ComponentType, useCallback } from "react";

import {
  AiQuestionSection,
  ContactsSection,
  ContactsSectionTypeEnum,
  CpvChartSection,
  CpvChartSectionTypeEnum,
  DocumentReferenceParameters,
  HeaderSection,
  HeaderSectionTypeEnum,
  IframeSection,
  IframeSectionTypeEnum,
  LinkListSection,
  LinkListSectionTypeEnum,
  RecordListSection,
  RecordListSectionTypeEnum,
  RichTextSection,
  RichTextSectionTypeEnum,
  SuppliersSection,
  SuppliersSectionTypeEnum,
} from "lib/generated/admin-api";
import {
  DocumentListSection,
  DocumentListSectionTypeEnum,
} from "lib/generated/admin-api/models/DocumentListSection";
import { ReportSectionType } from "lib/generated/admin-api/models/ReportSectionType";
import { ReportMetadata } from "./ReportSectionBuilder";

type EditableSectionComponentProps = {
  onChange: (section: ReportSectionType) => void;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  hasErrors: boolean;
  setErrors: (hasErrors: boolean) => void;
  addDocumentRef: (ref: DocumentReferenceParameters) => string;
  removeDocumentRef: (id: string) => void;
  updateDocumentRef: (id: string, ref: DocumentReferenceParameters) => void;
  getDocumentRef: (id: string) => DocumentReferenceParameters | undefined;
};

type BaseDispatchComponentProps<S> = {
  section: S;
  metadata: ReportMetadata;
  reportSectionId: string;
};

export type DispatchComponentProps<S> = BaseDispatchComponentProps<S> &
  EditableSectionComponentProps;

type DispatchComponent<S> = ComponentType<DispatchComponentProps<S>>;

export type SectionDispatcher = {
  RichText: DispatchComponent<RichTextSection>;
  Header: DispatchComponent<HeaderSection>;
  RecordList: DispatchComponent<RecordListSection>;
  Contacts: DispatchComponent<ContactsSection>;
  LinkList: DispatchComponent<LinkListSection>;
  DocumentList: DispatchComponent<DocumentListSection>;
  CpvChart: DispatchComponent<CpvChartSection>;
  Suppliers: DispatchComponent<SuppliersSection>;
  Iframe: DispatchComponent<IframeSection>;
  AiQuestion: DispatchComponent<AiQuestionSection>;
};

type ReadOnlySectionProps = BaseDispatchComponentProps<ReportSectionType> & {
  dispatcher: SectionDispatcher;
  getDocumentRef: (id: string) => DocumentReferenceParameters | undefined;
};

type SectionProps = EditableSectionComponentProps & ReadOnlySectionProps;

export const AVAILABLE_SECTIONS: (keyof typeof SECTION_NAMES)[] = [
  "richText",
  "header",
  "recordList",
  "contacts",
  "linkList",
  "cpvChart",
  "suppliers",
  "iframe",
];

export const SECTION_NAMES = {
  richText: "Text",
  header: "Header",
  recordList: "Record list",
  contacts: "Contacts",
  linkList: "Link list",
  cpvChart: "CPV chart",
  suppliers: "Suppliers",
  documentList: "Document list",
  iframe: "Embed an interactive chart",
};

export const SECTION_DEFAULTS = {
  header: {
    type: HeaderSectionTypeEnum.Header,
    generated: {
      description:
        "This custom report was prepared for [COMPANY]\nACCOUNT BRIEFING: to effectively understand, qualify, and approach this public sector buyer. \nPROSPECTING REPORT: to highlight the most relevant public-sector developments in the past week.\n\nYour [feed settings](https://app.stotles.com/account-management/feed-settings) are the basis of this personalised report, and we have manually reviewed this report to ensure relevance.\n\nWe encourage you to [reach out via chat](https://app.stotles.com/#chat) to share your feedback so we can continue to refine our understanding of your needs and improve future reports.",
    },
    parameters: {
      description:
        "This custom report was prepared for [COMPANY]\nACCOUNT BRIEFING: to effectively understand, qualify, and approach this public sector buyer. \nPROSPECTING REPORT: to highlight the most relevant public-sector developments in the past week.\n\nYour [feed settings](https://app.stotles.com/account-management/feed-settings) are the basis of this personalised report, and we have manually reviewed this report to ensure relevance.\n\nWe encourage you to [reach out via chat](https://app.stotles.com/#chat) to share your feedback so we can continue to refine our understanding of your needs and improve future reports.",
    },
  },
  richText: {
    type: RichTextSectionTypeEnum.RichText,
    generated: { value: "" },
    parameters: { value: "" },
  },
  recordList: {
    type: RecordListSectionTypeEnum.RecordList,
    parameters: {
      guids: [],
      title: "Record view name E.g., Upcoming Contract Expiries",
      description:
        "The following records have been hand-selected based on their high relevance to you.\nIn order to see all [VIEW] added to your feed, [click here](URL REQUIRED)",
    },
    generated: {
      title: "Record view name E.g., Upcoming Contract Expiries",
      description:
        "The following records have been hand-selected based on their high relevance to you.\nIn order to see all [VIEW] added to your feed, [click here](URL REQUIRED)",
      records: [],
    },
  },
  contacts: {
    type: ContactsSectionTypeEnum.Contacts,
    parameters: {},
    generated: {
      contacts: [],
      title: "Key decision-maker contacts",
      description:
        "Connect with the contacts most relevant to you. Access full contact list [here](URL REQUIRED).",
    },
  },
  linkList: {
    type: LinkListSectionTypeEnum.LinkList,
    parameters: {
      title: "News and document releases",
      description:
        "The following news, communications, and documents have been hand-selected based on their relevance to you.",
      links: [{ url: "" }],
    },
    generated: {
      title: "News and document releases",
      description:
        "The following news, communications, and documents have been hand-selected based on their relevance to you.",
      linksMetadata: [{ url: "" }],
    },
  },
  documentList: {
    type: DocumentListSectionTypeEnum.DocumentList,
    parameters: {
      title: "Documents",
      description:
        "The following documents have been hand-selected based on their relevance to you.",
      documents: [],
    },
    generated: {
      title: "Documents",
      description:
        "The following documents have been hand-selected based on their relevance to you.",
      documents: [],
    },
  } as DocumentListSection,
  cpvChart: {
    type: CpvChartSectionTypeEnum.CpvChart,
    parameters: {
      title: "[Historical purchasing / Upcoming expiries] by sector - last X years",
      description:
        "Only the most relevant and largest sectors are included here. For a full breakdown, [click here](URL REQUIRED).\n\n Sectors are defined by top-level CPV codes. While CPV codes are not perfect, as buyers can misclassify records, they can be a helpful guide of order-of-magnitude spend.",
    },
    generated: {
      title: "[Historical purchasing / Upcoming expiries] by sector - last X years",
      description:
        "Only the most relevant and largest sectors are included here. For a full breakdown, [click here](URL REQUIRED).\n\n Sectors are defined by top-level CPV codes. While CPV codes are not perfect, as buyers can misclassify records, they can be a helpful guide of order-of-magnitude spend.",
    },
  } as CpvChartSection,
  suppliers: {
    type: SuppliersSectionTypeEnum.Suppliers,
    parameters: {
      title: "Key supplier relationships",
      description:
        "Understand the existing competitor & partner relationships worth knowing about. View full supplier list [here](URL REQUIRED).",
      buyerIds: [],
      excludeSignalCategories: [],
    },
    generated: {
      title: "Key supplier relationships",
      description:
        "Understand the existing competitor & partner relationships worth knowing about. View full supplier list [here](URL REQUIRED).",
      suppliers: {},
    },
  },
  iframe: {
    type: IframeSectionTypeEnum.Iframe,
  },
};

export function ReadOnlySection(props: ReadOnlySectionProps): JSX.Element {
  const noop = useCallback(() => {
    // noop
  }, []);
  const throwError = useCallback(() => {
    throw new Error("This section is read-only");
  }, []);

  return (
    <Section
      {...props}
      onChange={noop}
      moveUp={noop}
      moveDown={noop}
      remove={noop}
      hasErrors={false}
      setErrors={noop}
      addDocumentRef={throwError}
      removeDocumentRef={noop}
      updateDocumentRef={noop}
    />
  );
}

export function Section({
  dispatcher,
  section,
  ...dispatchProps
}: SectionProps): JSX.Element | null {
  if (section.richText) {
    return <dispatcher.RichText section={section.richText} {...dispatchProps} />;
  }
  if (section.header) {
    return <dispatcher.Header section={section.header} {...dispatchProps} />;
  }
  if (section.recordList) {
    return <dispatcher.RecordList section={section.recordList} {...dispatchProps} />;
  }
  if (section.contacts) {
    return <dispatcher.Contacts section={section.contacts} {...dispatchProps} />;
  }
  if (section.linkList) {
    return <dispatcher.LinkList section={section.linkList} {...dispatchProps} />;
  }
  if (section.documentList) {
    return <dispatcher.DocumentList section={section.documentList} {...dispatchProps} />;
  }
  if (section.cpvChart) {
    return <dispatcher.CpvChart section={section.cpvChart} {...dispatchProps} />;
  }
  if (section.suppliers) {
    return <dispatcher.Suppliers section={section.suppliers} {...dispatchProps} />;
  }
  if (section.iframe) {
    return <dispatcher.Iframe section={section.iframe} {...dispatchProps} />;
  }
  if (section.aiQuestion) {
    return <dispatcher.AiQuestion section={section.aiQuestion} {...dispatchProps} />;
  }
  return null;
}
