import * as React from "react";
import { SorterResult } from "antd5/es/table/interface";
import { ProfileTypeContext } from "pages/app/SupplierRelationshipPage";
import shallowEqual from "shallowequal";

import {
  getNoticesColumns,
  NoticeColumns,
  PAGINATION_DEFAULT_CONFIG,
} from "components/relationship_profiles/tableConfigs";
import { PaginationConfig } from "components/relationship_profiles/types";
import { Table } from "lib/core_components/Table";
import { useUserDataProvider } from "lib/data_providers/UserDataProvider";
import { createUseDebounce } from "lib/debounce";
import { useRestrictedRecordRowClick } from "lib/hooks/useRestrictedRowClick";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import CachedSequentialRequestHandler, {
  OutOfOrderResponseError,
} from "lib/search/SequentialRequestHandler";
import { SortState } from "lib/search/types";
import { SearchRecordsParams, SearchResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { RecordDetails } from "lib/types/models";

const useDebounce200 = createUseDebounce(200);
type Props = {
  defaultSort: SortState;
  onRecordLoad: (resp: SearchResponse<RecordDetails>) => void;
  columnNames: NoticeColumns[];
  filters: Partial<SearchRecordsParams>;
};

function NoticesTable({ defaultSort, columnNames, filters, onRecordLoad }: Props): JSX.Element {
  const api = useStotlesApi();
  const [data, setData] = React.useState<RecordDetails[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [sortState, setSortState] = React.useState(defaultSort);
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [paginationState, setPaginationState] =
    React.useState<PaginationConfig>(PAGINATION_DEFAULT_CONFIG);
  const [sequentialRequestHandler] = React.useState(
    () => new CachedSequentialRequestHandler(api.searchRecords.bind(api)),
  );
  const { signalCategory, signalName } = React.useContext(ProfileTypeContext);
  const getRequiredDataTypesForRow = React.useCallback((): ProHelperDataTypes => "SUPPLIERS", []);
  const onRowClick = useRestrictedRecordRowClick(getRequiredDataTypesForRow);

  const { leadWriteMethods, recordWriteMethods } = useUserDataProvider();
  const searchRecords = React.useCallback(async () => {
    // We don't want it to make a call if the signal name and category aren't in the context yet.
    if (!signalCategory || !signalName) return;
    setLoading(true);
    setHasError(false);
    try {
      const offset = ((paginationState.current || 1) - 1) * (paginationState.pageSize || 0);
      const query: SearchRecordsParams = {
        ...filters,
        sort: sortState.field,
        sort_order: sortState.order,
        offset,
        limit: paginationState.pageSize,
      };

      const response = await sequentialRequestHandler.makeRequest(query);
      if (!response) {
        setLoading(false);
        return;
      }
      if (onRecordLoad) {
        onRecordLoad(response);
      }

      setData(response.results);
      setTotalCount(response.paging_info.total_results);
      setLoading(false);
      leadWriteMethods.loadLeadDataFromRecords(response.results);
      recordWriteMethods.loadRecordDataFromRecords(response.results);
    } catch (e) {
      if (e instanceof OutOfOrderResponseError) {
        // Nothing to do, we just discard the results
        return;
      }
      setLoading(false);
      setHasError(true);
      throw e;
    }
  }, [
    filters,
    leadWriteMethods,
    onRecordLoad,
    paginationState,
    recordWriteMethods,
    sequentialRequestHandler,
    sortState,
    signalCategory,
    signalName,
  ]);
  const debouncedSearchRecords = useDebounce200(searchRecords);

  React.useEffect(() => {
    debouncedSearchRecords();
  }, [filters, sortState, paginationState, debouncedSearchRecords]);

  // Handles pagination and sort changes
  const handleChange = React.useCallback(
    (
      newPagination,
      _filters,
      newSorterState: SorterResult<RecordDetails> | SorterResult<RecordDetails>[],
    ) => {
      if (Array.isArray(newSorterState)) {
        newSorterState = newSorterState[0];
      }

      const newSortState: SortState = {
        order: newSorterState.order === "descend" ? "DESC" : "ASC",
        field: newSorterState.columnKey as string,
      };

      if (!shallowEqual(sortState, newSortState)) {
        setSortState(newSortState);
      }

      if (!shallowEqual(paginationState, newPagination)) {
        setPaginationState(newPagination);
      }
    },
    [sortState, paginationState],
  );

  const tableColumns = React.useMemo(
    () =>
      signalCategory && signalName
        ? getNoticesColumns({
            profileType: signalCategory,
            signalName,
            sort: sortState,
            columnNames,
          })
        : [],
    [columnNames, sortState, signalCategory, signalName],
  );

  return (
    <Table
      dataSource={data}
      columns={tableColumns}
      pagination={{ ...paginationState, total: totalCount }}
      loading={loading}
      rowKey="guid"
      onRow={onRowClick}
      onChange={handleChange}
      isError={hasError}
    />
  );
}

export default NoticesTable;
