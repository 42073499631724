import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { App } from "antd5";

import { useStotlesApi } from "lib/stotlesApiContext";

type BriefingType = "account" | "opportunity";
type Props = { buyerId: number; type: BriefingType; url: string };

export function useAccountBriefingRequest(
  options?: UseMutationOptions<unknown, unknown, Props, unknown>,
) {
  const api = useStotlesApi();
  const { message } = App.useApp();
  return useMutation(
    async ({ buyerId, type, url }: Props) => api.createAccountBriefingRequest(buyerId, type, url),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void message.success(
          "Account briefing requested! We will send you your briefing within 5 business days",
        );
        options?.onSuccess?.(data, variables, context);
      },
      onError: (data, variables, context) => {
        void message.error("An error occurred - please try again or get in touch");
        options?.onError?.(data, variables, context);
      },
    },
  );
}
