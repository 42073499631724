import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { NoticeFilters } from "components/notices/utils";
import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";

export function convertBuyerFiltersToNoticeFilters(
  buyerFilters: AllBuyersStatsRequestAllBuyersStatsFilters,
  buyerGuid: string,
): NoticeFilters {
  // Buyer filters are not used as this conversion is used to access the notice table on the filters page where the buyer is hardcoded
  const noticeFilter: NoticeFilters = {
    ...EMPTY_FILTERS,
    buyers: [buyerGuid],
    signals: buyerFilters.signals || [],
    suppliers: buyerFilters.supplierIds || [],
    supplierSme: buyerFilters.supplierSme || "",
  };
  return noticeFilter;
}
