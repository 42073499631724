import * as React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { convertSignalCategoryToFeatureType } from "components/paywall/paywallUtils";
import { SupplierSummary } from "components/supplier_details/SupplierSummary";
import { useConfirmUserDataSubscription } from "lib/providers/Paywall";
import { SignalCategory } from "lib/StotlesApi";

type SupplierListProps = {
  signal_category: SignalCategory;
};

export function SupplierListPage({ signal_category }: SupplierListProps) {
  // Runs once upon load, checks whether user is subscribed to spend data or not, if not, then
  // open the paywall modal and upon close will go back to my feed
  useConfirmUserDataSubscription("SUPPLIERS", convertSignalCategoryToFeatureType(signal_category));
  return <SupplierSummary signalCategory={signal_category} />;
}

export default hot(withAppLayout(SupplierListPage, {}));
