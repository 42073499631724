/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { SupplierDetailsDtoRefOrgInfo } from './SupplierDetailsDtoRefOrgInfo';
import {
    SupplierDetailsDtoRefOrgInfoFromJSON,
    SupplierDetailsDtoRefOrgInfoFromJSONTyped,
    SupplierDetailsDtoRefOrgInfoToJSON,
} from './SupplierDetailsDtoRefOrgInfo';

/**
 * 
 * @export
 * @interface SupplierDetailsDto
 */
export interface SupplierDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SupplierDetailsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDetailsDto
     */
    country: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierDetailsDto
     */
    isSme?: boolean | null;
    /**
     * 
     * @type {Address}
     * @memberof SupplierDetailsDto
     */
    address: Address;
    /**
     * 
     * @type {Array<SupplierDetailsDtoRefOrgInfo>}
     * @memberof SupplierDetailsDto
     */
    referenceOrganisations: Array<SupplierDetailsDtoRefOrgInfo>;
}

/**
 * Check if a given object implements the SupplierDetailsDto interface.
 */
export function instanceOfSupplierDetailsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "referenceOrganisations" in value;

    return isInstance;
}

export function SupplierDetailsDtoFromJSON(json: any): SupplierDetailsDto {
    return SupplierDetailsDtoFromJSONTyped(json, false);
}

export function SupplierDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'name': json['name'],
        'country': json['country'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
        'address': AddressFromJSON(json['address']),
        'referenceOrganisations': ((json['reference_organisations'] as Array<any>).map(SupplierDetailsDtoRefOrgInfoFromJSON)),
    };
}

export function SupplierDetailsDtoToJSON(value?: SupplierDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'name': value.name,
        'country': value.country,
        'is_sme': value.isSme,
        'address': AddressToJSON(value.address),
        'reference_organisations': ((value.referenceOrganisations as Array<any>).map(SupplierDetailsDtoRefOrgInfoToJSON)),
    };
}

