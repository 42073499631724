import * as React from "react";
import { useRoute } from "wouter";

import StatisticsCard, { StatisticsCardProps } from "lib/core_components/StatisticsCard";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { EventData, EventNames, useTracking } from "../../lib/tracking";
import { PrimaryTab, SecondaryTab } from "./supplier_utils";

import css from "./SupplierHeaderCards.module.scss";

type Props = {
  awardsCount: number;
  mentionsCount: number;
  expiriesCount: number;
  keyAccountsCount: number;
  isLoading: boolean;
  handleNavigation: (
    location: PrimaryTab | SecondaryTab,
    eventName: EventNames,
    data: EventData,
  ) => void;
};

function SupplierHeaderCards({
  awardsCount,
  mentionsCount,
  expiriesCount,
  keyAccountsCount,
  isLoading,
  handleNavigation,
}: Props): JSX.Element {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const { trackingData } = useTracking();
  return (
    <div className={css.container}>
      {enableNewSupplierFilter && window.currentUser?.use_supplier_name === false ? (
        <>
          <Card
            value={awardsCount}
            loading={isLoading}
            objectType="Direct award"
            description={`Connected to this supplier`}
            onClick={() =>
              handleNavigation(SecondaryTab.AWARDS, EventNames.highlightClicked, {
                "Highlight type": "Direct awards connected to this supplier",
                "Context source": "Header card",
                ...trackingData,
              })
            }
            activateOnRoute={SecondaryTab.AWARDS}
          />
          <Card
            value={mentionsCount}
            loading={isLoading}
            objectType="Notice mention"
            description={`Connected to this supplier`}
            onClick={() =>
              handleNavigation(SecondaryTab.MENTIONS, EventNames.highlightClicked, {
                "Highlight type": "Notice mentions connected to this supplier",
                "Context source": "Header card",
                ...trackingData,
              })
            }
            activateOnRoute={SecondaryTab.MENTIONS}
          />
          <Card
            value={expiriesCount}
            loading={isLoading}
            objectType="Upcoming expiry"
            pluralObjectType="Upcoming expiries"
            description={"Connected to this supplier"}
            onClick={() =>
              handleNavigation(SecondaryTab.UPCOMING_EXPIRIES, EventNames.highlightClicked, {
                "Highlight type": "Upcoming expiries matching signals",
                "Context source": "Header card",
                ...trackingData,
              })
            }
            activateOnRoute={SecondaryTab.UPCOMING_EXPIRIES}
          />
        </>
      ) : (
        <>
          <Card
            value={awardsCount}
            loading={isLoading}
            objectType="Award"
            description={`Matching my signals`}
            onClick={() =>
              handleNavigation(SecondaryTab.AWARDS, EventNames.highlightClicked, {
                "Highlight type": "Awards matching signals",
                "Context source": "Header card",
                ...trackingData,
              })
            }
            activateOnRoute={SecondaryTab.AWARDS}
          />
          <Card
            value={expiriesCount}
            loading={isLoading}
            objectType="Upcoming expiry"
            pluralObjectType="Upcoming expiries"
            description={"Matching my signals"}
            onClick={() =>
              handleNavigation(SecondaryTab.UPCOMING_EXPIRIES, EventNames.highlightClicked, {
                "Highlight type": "Upcoming expiries matching signals",
                "Context source": "Header card",
                ...trackingData,
              })
            }
            activateOnRoute={SecondaryTab.UPCOMING_EXPIRIES}
          />
        </>
      )}

      <Card
        value={keyAccountsCount}
        loading={isLoading}
        objectType="Saved buyer"
        description={`Connected to this supplier`}
        onClick={() =>
          handleNavigation(SecondaryTab.SAVED_BUYERS, EventNames.highlightClicked, {
            "Highlight type": "Saved buyers connected to this supplier",
            "Context source": "Header card",
            ...trackingData,
          })
        }
        activateOnRoute={SecondaryTab.SAVED_BUYERS}
      />
    </div>
  );
}

type CardProps = {
  activateOnRoute: string;
} & Omit<StatisticsCardProps, "active">;

function Card({ activateOnRoute, ...rest }: CardProps) {
  const [routeActive] = useRoute(activateOnRoute);

  return <StatisticsCard {...rest} active={routeActive} />;
}

export default SupplierHeaderCards;
