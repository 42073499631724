import React from "react";
import { FieldValues } from "react-hook-form";
import { CheckCircleFilled, CrownFilled } from "@ant-design/icons";
import { Select, SelectProps } from "antd5";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import {
  Select as FormSelect,
  SelectProps as FormSelectProps,
} from "components/form_components/Inputs";
import { RecordSearchRequestDtoProcurementStageQualificationsEnum } from "lib/generated/app-api";
import {
  CircleEmpty,
  CircleFull,
  CircleHalfFull,
  CircleThirdFull,
  CircleTwoThirdsFull,
} from "lib/icons/ProgressCircle";
import { yellow600 } from "lib/themes/colors";
import { assert } from "lib/utils";
import { PRE_ENGAGE_QUALIFICATION, TENDER_QUALIFICATION } from "./QualificationStatus";

import css from "./SelectQualification.module.scss";

const ALL_QUALIFICATIONS = { ...PRE_ENGAGE_QUALIFICATION, ...TENDER_QUALIFICATION };

type QualificationsFilterValue = RecordSearchRequestDtoProcurementStageQualificationsEnum;

const tags = {
  unqualified: {
    text: "Unqualified",
    icon: <CircleEmpty width={12} height={12} />,
  },
  not_relevant: {
    text: "Tender: Not relevant",
    icon: <CircleFull fill={DefaultTheme.token?.colorError} width={12} height={12} />,
  },
  in_review: {
    text: "Tender: In review",
    icon: <CircleThirdFull fill={yellow600} width={12} height={12} />,
  },
  bid_prep: {
    text: "Tender: Bid prep",
    icon: <CircleTwoThirdsFull fill={yellow600} width={12} height={12} />,
  },
  bid_submitted: {
    text: "Tender: Bid submitted",
    icon: <CircleFull fill={DefaultTheme.token?.colorSuccess} width={12} height={12} />,
  },
  won: {
    text: "Tender: Won",
    icon: <CrownFilled className={css.greenCircleIcon} />,
  },
  lost: {
    text: "Tender: Lost",
    icon: <CircleFull fill={DefaultTheme.token?.colorError} width={12} height={12} />,
  },
  no_bid: {
    text: "Tender: No bid",
    icon: <CircleFull fill={DefaultTheme.token?.colorError} width={12} height={12} />,
  },
  pre_engage_to_do: {
    text: "Pre-engage: To-do",
    icon: <CircleHalfFull fill={yellow600} width={12} height={12} />,
  },
  pre_engage_done: {
    text: "Pre-engage: Actioned",
    icon: <CheckCircleFilled className={css.greenCircleIcon} />,
  },
  pre_engage_not_relevant: {
    text: "Pre-engage: Not relevant",
    icon: <CircleFull fill={DefaultTheme.token?.colorError} width={12} height={12} />,
  },
};

export function SelectQualification(props: SelectProps<QualificationsFilterValue[]>) {
  const preEngageOptions = (
    <Select.OptGroup label="Pre-engage">
      {Object.keys(PRE_ENGAGE_QUALIFICATION).flatMap((key) => {
        if (key === "unqualified") return [];
        return (
          <Select.Option value={key} key={key}>
            {ALL_QUALIFICATIONS[key]}
          </Select.Option>
        );
      })}
    </Select.OptGroup>
  );

  const tenderOptions = (
    <Select.OptGroup label="Tender">
      {Object.keys(TENDER_QUALIFICATION).map((key) => {
        if (key === "unqualified") return [];
        return (
          <Select.Option value={key} key={key}>
            {ALL_QUALIFICATIONS[key]}
          </Select.Option>
        );
      })}
    </Select.OptGroup>
  );

  return (
    <Select<QualificationsFilterValue[]>
      mode="multiple"
      {...props}
      filterOption={(input, option) => {
        assert(option);
        const key = option.value as QualificationsFilterValue;
        if (tags[key] === undefined) return false;
        const labelText = tags[key].text as string;

        return labelText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      <Select.Option value="unqualified" key="unqualified">
        {ALL_QUALIFICATIONS.unqualified}
      </Select.Option>
      {preEngageOptions}
      {tenderOptions}
    </Select>
  );
}

const options = [
  { value: "unqualified", label: ALL_QUALIFICATIONS["unqualified"] },
  {
    label: "Pre-engage",
    options: Object.keys(PRE_ENGAGE_QUALIFICATION).flatMap((key) =>
      key === "unqualified"
        ? []
        : {
            value: key,
            label: ALL_QUALIFICATIONS[key],
          },
    ),
  },
  {
    label: "Tender",
    options: Object.keys(TENDER_QUALIFICATION).flatMap((key) =>
      key === "unqualified"
        ? []
        : {
            value: key,
            label: ALL_QUALIFICATIONS[key],
          },
    ),
  },
];

type Props<T extends FieldValues> = Omit<FormSelectProps<T>, "options">;
export function SelectQualificationForm<T extends FieldValues>(props: Props<T>) {
  return (
    <FormSelect
      {...props}
      mode="multiple"
      filterOption={(input, option) => {
        if (option && tags[option.value as QualificationsFilterValue]) {
          const labelText = tags[option.value as QualificationsFilterValue].text;
          return labelText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
      options={options}
    />
  );
}
