import { UseMutationOptions } from "@tanstack/react-query";

import { createPromptActionedEvent } from "components/buyer_details/question_panel/tracking";
import { graphql } from "lib/generated/app-service-gql";
import {
  AskQuestionMutation,
  AskQuestionMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useTracking } from "lib/tracking";
import { useGraphQlMutation } from "../useGraphQlClient";

const askQuestion = graphql(`
  mutation askQuestion($input: QuestionRequest!) {
    askQuestion(QuestionRequest: $input) {
      id
      question
      answer
      isComplete
    }
  }
`);

export function useAskQuestion(
  orgName: string,
  options?: UseMutationOptions<AskQuestionMutation, unknown, AskQuestionMutationVariables, unknown>,
) {
  const { logEvents } = useTracking();
  const { data, ...rest } = useGraphQlMutation(askQuestion, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      logEvents(createPromptActionedEvent(data.askQuestion.question, orgName));
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.askQuestion, ...rest };
}
