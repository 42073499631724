import React from "react";

import { Table } from "lib/core_components/Table";
import { SpendDataFilters } from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useSpendTable } from "./hooks/useSpendTable";
import { TransactionTableRow } from "./config";

type Props = {
  filters: SpendDataFilters;
  className?: string;
};

function TransactionsTable({ filters, className }: Props) {
  const { data, isLoading, columns, pagination, isError } = useSpendTable(
    filters,
    "allTransactions",
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      isError={isError}
      pagination={{
        total: pagination?.total,
        onChange: (page, pageSize?) => {
          if (pagination) {
            pagination.onChange(page || 1, pageSize);
          }
        },
        defaultPageSize: 10,
        showSizeChanger: true,
      }}
      rowKey={(r) => (r as TransactionTableRow).id}
      loading={isLoading}
      className={className}
      scroll={{ x: "max-content" }}
    />
  );
}

export default TransactionsTable;
