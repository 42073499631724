import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  NoticesSearchRequest,
  NoticesWithSignalsQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

export type NoticeSearchQueryKey = ["notices", NoticesSearchRequest, boolean | undefined];

// We have two queries - one including signals, the other not including them.... because signals are expensive to calculate for each notice.
// They should nonly be fetches when necessary
const noticesQuery = graphql(`
  query notices($request: NoticesSearchRequest!) {
    notices(NoticesSearchRequest: $request) {
      results {
        score
        guid
        procurementStage {
          id
          stage
        }
        country
        stage
        name
        entityType
        currency
        value
        publishDate
        closeDate
        awardDate
        expiryDate
        url
        buyers {
          id
          guid
          name
          categoryAssignments {
            buyerCategoryID
            source
          }
        }
        qualification
        suppliers {
          id
          name
          guid
          isSme
        }
        lists {
          id
          name
        }
        assignee {
          guid
          firstName
          lastName
        }
        comments {
          id
          content
        }
        seenByUsers {
          firstName
          guid
        }
        relatedFrameworkProcess {
          frameworkId
          processType
        }
      }
      totalResults
    }
  }
`);

const noticesQueryWithSignals = graphql(`
  query noticesWithSignals($request: NoticesSearchRequest!) {
    notices(NoticesSearchRequest: $request) {
      results {
        score
        guid
        procurementStage {
          id
          stage
        }
        signals {
          id
          name
          category
        }
        country
        stage
        name
        entityType
        currency
        value
        publishDate
        closeDate
        awardDate
        expiryDate
        url
        buyers {
          id
          guid
          name
          categoryAssignments {
            buyerCategoryID
            source
          }
        }
        qualification
        suppliers {
          id
          name
          guid
          isSme
        }
        lists {
          id
          name
        }
        assignee {
          guid
          firstName
          lastName
        }
        comments {
          id
          content
        }
        seenByUsers {
          firstName
          guid
        }
        relatedFrameworkProcess {
          frameworkId
          processType
        }
      }
      totalResults
    }
  }
`);
type NoticesSearchResponse = NoticesWithSignalsQuery["notices"];
export type NoticesDto = NoticesSearchResponse["results"][0];

export function useNotices(
  searchNoticesRequest: NoticesSearchRequest,
  includeSignals: boolean,
  options?: UseQueryOptions<NoticesWithSignalsQuery, unknown, NoticesWithSignalsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["notices", searchNoticesRequest, includeSignals],
    includeSignals ? noticesQueryWithSignals : noticesQuery,
    [{ request: searchNoticesRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: {
      ...data,
      results: data?.notices?.results ?? [],
      pagingInfo: { totalResults: data?.notices?.totalResults ?? 0 },
    },
    ...rest,
  };
}
