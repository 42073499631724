import React from "react";
import { Control } from "react-hook-form";

import { SelectBuyerList } from "../../form_components/BuyerListSelect";
import { BuyerSelect } from "../../form_components/BuyerSelect";
import { BuyerTypeSelect } from "../../form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "../../form_components/CountryRegionSelect";
import { Radio } from "../../form_components/Inputs";
import { BUYER_PARTICIPANT_OPTIONS, FrameworkFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface BuyersFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
  isBuyerIdsFilterApplied: boolean;
}

function BuyersFilterContent({ control, isBuyerIdsFilterApplied }: BuyersFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Buyers filters">
      <SelectBuyerList
        name="buyerListIds"
        label="Buyer lists"
        control={control}
        placeholder="Select..."
        mode="multiple"
        allowClear
      />
      <BuyerSelect
        name="buyerIds"
        label="Buyers"
        control={control}
        mode="multiple"
        placeholder="Search buyers..."
        allowClear
      />
      <BuyerTypeSelect
        name="buyerCategories"
        control={control}
        multiple
        label="Buyer type"
        placeholder="Select..."
        allowClear
      />
      <CountryRegionSelect
        name="buyerCountryRegions"
        control={control}
        label="Buyer location"
        placeholder="Select..."
        allowClear
      />
      <Radio
        label="Filter by"
        name={"buyerParticipantType"}
        control={control}
        disabled={!isBuyerIdsFilterApplied}
        defaultValue={""}
        options={BUYER_PARTICIPANT_OPTIONS}
        optionType="default"
        direction="vertical"
      />
    </div>
  );
}

export default BuyersFilterContent;
