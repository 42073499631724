import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { App, Button, Input, InputRef } from "antd5";
import classNames from "classnames";

import { createUseDebounce } from "lib/debounce";
import { useUpdateSignalSettings } from "lib/hooks/api/signals/useUpdateSignalSettings";
import Signal from "lib/icons/Signal";
import { useDialogManager } from "lib/providers/DialogManager";
import { red500 } from "lib/themes/colors";
import { EventNames, logEvent } from "lib/tracking";
import { ALL_KEYWORDS_TOKEN } from "lib/types/models";
import { completeUnclosedQuotes, simpleArrayDedupe } from "lib/utils";
import { useSignalSettingsGQL } from "../../../lib/hooks/api/teams/useSignalSettingsGQL";
import { searchRefraction, slashCircle1 } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { SelectProps } from "../Inputs";
import KeywordDropdownMenu from "./KeywordDropdownMenu";
import SaveKeywordModal, {
  HIDE_SAVE_KEYWORD_MODAL,
  SaveKeywordModalProps,
} from "./SaveKeywordModal";
import SelectedKeywordTags from "./SelectedKeywordTags";

import css from "./KeywordSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  containerClassName?: string;
  type?: "keywords" | "excludeKeywords";
};

function KeywordSelectInner<T extends FieldValues>(
  { type = "keywords", ...props }: FieldProps<T>,
  ref: React.Ref<InputRef>,
) {
  const { message } = App.useApp();
  const dialogManager = useDialogManager();
  const [searchText, setSearchText] = useState<string>("");

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const useDebounce = createUseDebounce(500);

  const delayDropdownOpen = useDebounce(setDropdownOpen);

  const { field } = useController(props);

  const { value, onChange } = field;

  const { data: signals, isLoading } = useSignalSettingsGQL();

  const { mutate: updateSignalSettings, isLoading: isUpdatingSignalSettings } =
    useUpdateSignalSettings({
      onSuccess: (_) => {
        logEvent(EventNames.recordQueryUpdated, {});
        message.success("Keyword signals updated successfully");
      },
      onError: (_) => {
        message.error(
          "Failed to update keyword signals. Please contact an admin if this issue persists.",
        );
      },
    });

  const allKeywords = useMemo((): string[] => {
    if (type === "excludeKeywords") {
      return [];
    }

    return signals?.keywords || [];
  }, [signals?.keywords, type]);

  /**
   * A bit of an extension onto the normal onChange function, this will add or remove the ALL_KEYWORDS_TOKEN
   * from the filter value, depending on whether or not all keywords are selected
   */
  const removeKeywordsToken = (keywords: string[]) =>
    keywords.filter((t: string) => t !== ALL_KEYWORDS_TOKEN);

  const onKeywordsChange = useCallback(
    (newKeywords: string[]) => {
      const containsAllKeywords =
        allKeywords.length > 0 && allKeywords.every((s) => newKeywords.includes(s));

      if (containsAllKeywords) {
        newKeywords.push(ALL_KEYWORDS_TOKEN);
      } else {
        newKeywords = removeKeywordsToken(newKeywords);
      }

      onChange(newKeywords);
    },
    [allKeywords, onChange],
  );

  const selectedKeywords = useMemo((): string[] => {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    // We can safely assume that the value is an array of strings
    const valueArray = value as string[];

    // Just in case, we will make absolutely sure that IF this token is present in the array,
    // keyword signals are added for sure and the token is removed
    if (valueArray.includes(ALL_KEYWORDS_TOKEN)) {
      return simpleArrayDedupe([...valueArray, ...allKeywords]).filter(
        (t: string) => t !== ALL_KEYWORDS_TOKEN,
      );
    }
    return value;
  }, [value, allKeywords]);

  const { containerClassName, label } = props;

  // Replace with a basic check as we're disabling signals icon
  // while we test with users
  // TODO: cleanup once we decide to keep or remove
  /* const showIconForTag = useCallback(
    (keyword: string): boolean => {
      // Always show icon for exclude keyword
      if (type === "excludeKeywords") {
        return true;
      }

      // Only show icon when keyword is saved as a signal
      // return allKeywords.includes(keyword);
      return false
    },
    [allKeywords, type],
  );*/

  const freeTextKeywords = useMemo(
    () => selectedKeywords.filter((k) => !allKeywords.includes(k)),
    [allKeywords, selectedKeywords],
  );

  /**
   * Depending on user's history, either opens an informative modal, or
   *  just saves/unsaves the free text keyword(s) immediately
   * @param type
   * @param savedGuids
   */
  const onSaveFreeTextKewyords = () => {
    // Make sure we maintain the previous keywords and add the new free-text ones
    const keywords = allKeywords.concat(freeTextKeywords);
    if (localStorage.getItem(HIDE_SAVE_KEYWORD_MODAL) === "true") {
      updateSignalSettings({ input: { keywords } });
    } else {
      void dialogManager.openDialog(SaveKeywordModal, {
        onConfirm: () => updateSignalSettings({ input: { keywords } }),
      } as Omit<SaveKeywordModalProps, "isOpen" | "onClose">);
    }
  };

  return (
    <div
      className={classNames(css.container, containerClassName)}
      onBlur={(e) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
          return;
        }
        setDropdownOpen(false);
      }}
    >
      {label && (
        <div className={css.filterLabelDiv} aria-label="filterLabel">
          <span className={css.filterName}>{label}</span>
        </div>
      )}
      <Input
        aria-label="keywordSearchInput"
        className={css.inputField}
        onPressEnter={(e) => {
          e.preventDefault();
          if (!searchText.length) return;
          // Complete any unclosed quotes in the searchText
          const cleanedSearchText = completeUnclosedQuotes(searchText);
          if (selectedKeywords.includes(cleanedSearchText)) {
            onKeywordsChange(selectedKeywords.filter((v: string) => v !== cleanedSearchText));
          } else {
            onKeywordsChange([...selectedKeywords, cleanedSearchText]);
          }

          setSearchText("");
          delayDropdownOpen(false);
        }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        onChange={(e) => {
          const searchText = e.currentTarget.value;

          // if we're typing, open the dropdown
          if (searchText.length > 0 && !dropdownOpen) {
            setDropdownOpen(true);
          }

          setSearchText(searchText);
        }}
        placeholder={type === "keywords" ? "Search keywords" : "Type and press enter"}
        prefix={<UIcon svg={searchRefraction} size={16} />}
        value={searchText}
        ref={ref}
      />
      <KeywordDropdownMenu
        allKeywords={removeKeywordsToken(allKeywords)}
        dropdownOpen={dropdownOpen}
        onChange={onKeywordsChange}
        selectedKeywords={removeKeywordsToken(selectedKeywords)}
        searchText={searchText}
        type={type}
      />
      {selectedKeywords && selectedKeywords.length > 0 && !dropdownOpen && (
        <>
          <div className={css.filterLabelDiv} aria-label="clearDiv">
            <span className={css.filterName}>{`${selectedKeywords.length} selected`}</span>
            <Button
              className={css.clearButton}
              onClick={() => {
                onChange([]);
                delayDropdownOpen(false);
              }}
            >
              Clear
            </Button>
          </div>

          <SelectedKeywordTags
            selectedKeywords={selectedKeywords}
            tooltipTitle={type === "keywords" ? "Keyword signal" : undefined}
            isLoading={isLoading}
            onChange={onChange}
            closeDropdown={() => delayDropdownOpen(false)}
            icon={
              type === "excludeKeywords" && (
                <UIcon className={css.icon} svg={slashCircle1} size={16} color={red500} />
              ) // hide for now while we test with users
              // TODO: cleanup once we decide to keep or remove
              // <Signal className={css.icon} size={16} label="signalIcon" />
            }
            // showIconForTag={showIconForTag}
            showIcon={type === "excludeKeywords" ? true : false}
            tagClassname={type === "excludeKeywords" ? css.excludeTag : undefined}
          />
          {type === "keywords" && freeTextKeywords.length > 0 && (
            <Button
              className={css.saveSignalBtn}
              onClick={onSaveFreeTextKewyords}
              loading={isUpdatingSignalSettings}
            >
              Save as signals
              <Signal className={css.signalIcon} size={16} label="signalIcon" />
            </Button>
          )}
        </>
      )}
    </div>
  );
}

const KeywordSelect = forwardRef(KeywordSelectInner) as <T extends FieldValues>(
  props: FieldProps<T> & { ref?: React.Ref<InputRef> },
) => ReturnType<typeof KeywordSelectInner>;

export { KeywordSelect };
