import React from "react";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useGetLeadSubscriptionContents } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptionContents";
import * as tracking from "lib/tracking";
import { getAlert } from "../alerts";
import { createEditAlertsModalOpened } from "../tracking";
import { EditAlertsModal } from "./EditAlertsModal";

import css from "./AlertButton.module.scss";

type Props = {
  selectedView: SavedViewType;
  hasUserModifiedView: boolean;
};

export function AlertButton({ selectedView, hasUserModifiedView }: Props) {
  const { data, isLoading } = useGetLeadSubscriptionContents();
  const [isEditAlertsModalOpen, setIsEditAlertsModalOpen] = React.useState(false);
  if (isLoading || !data) {
    return null;
  }

  const hasAlertsEnabled = Boolean(
    selectedView.id && getAlert(data.personalSubscriptions, selectedView.id),
  );

  const handleClick = () => {
    tracking.logEvent(
      tracking.EventNames.alertModalOpened,
      createEditAlertsModalOpened(selectedView.name, selectedView.id),
    );
    setIsEditAlertsModalOpen(true);
  };

  let tooltipTitle = "Set an alert for new activity in this view";
  if (hasAlertsEnabled) {
    tooltipTitle = "Edit the alert set for this view";
  } else if (hasUserModifiedView) {
    tooltipTitle = "Save this view to set an alert";
  }

  return (
    <>
      {isEditAlertsModalOpen && (
        <EditAlertsModal
          isOpen={isEditAlertsModalOpen}
          onClose={() => setIsEditAlertsModalOpen(false)}
          selectedView={selectedView}
        />
      )}
      <Tooltip title={tooltipTitle} placement="topLeft">
        <Button
          className={css.alertButton}
          disabled={isLoading || hasUserModifiedView}
          onClick={handleClick}
          icon={
            hasAlertsEnabled ? (
              <BellFilled className={css.blueAlertIcon} />
            ) : (
              <BellOutlined className={css.blueAlertIcon} />
            )
          }
        />
      </Tooltip>
    </>
  );
}
