import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ArrowUpOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Modal } from "antd5";

import { Input } from "components/form_components/Inputs";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import {
  AskQuestionMutation,
  Question,
  QuestionRequest_DataTypeEnum_0,
} from "lib/generated/app-service-gql/graphql";
import { useAskQuestion } from "lib/hooks/api/question/useAskQuestion";
import { useQuestionAnswered } from "lib/hooks/api/question/useQuestionAnswered";
import * as tracking from "lib/tracking";
import ChatResponse from "./ChatResponse";
import LoadingState from "./LoadingState";
import Questions from "./Questions";
import SuggestedPrompts from "./SuggestedPrompts";
import { createNewChatCreatedEvent } from "./tracking";

import css from "./QuestionPanel.module.scss";

import SPARKLES from "../../../../assets/images/icons/sparkles.svg";

export type QuestionDataType = QuestionRequest_DataTypeEnum_0;
export const QuestionDataType = QuestionRequest_DataTypeEnum_0;

type QuestionPanelProps = {
  dataType: QuestionDataType;
  id: string;
  organisationName: string;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
};
export function QuestionPanel(props: QuestionPanelProps) {
  const { control, handleSubmit, reset: resetForm } = useForm<{ input: string }>();
  // using state to store the question as the data on the mutation hook doesn't trigger expected renders
  const [question, setQuestion] = useState<AskQuestionMutation["askQuestion"] | undefined>();
  const {
    isLoading: isAskLoading,
    isError: isAskError,
    mutate: askQuestion,
    reset: resetMutation,
  } = useAskQuestion(props.organisationName, {
    onSuccess: (data) => {
      resetForm();
      setQuestion(data.askQuestion);
    },
  });
  const { data: subscriptionData, isError: isSubError } = useQuestionAnswered(
    props.organisationName,
    question?.id ? question.id : undefined,
    question?.id && !question.isComplete ? true : false,
  );
  const [selectedQuestion, setSelectedQuestion] = useState<Question | undefined>(undefined);

  const data = selectedQuestion || (question?.isComplete ? question : subscriptionData);
  const reportId = selectedQuestion?.reportId;

  const isError = isAskError || isSubError;

  // isLoading if we have asked the question and waiting for the streamed response to start
  const isLoading = isAskLoading || (!data && !isError && !!question?.id);
  if (!FeatureToggles.isEnabled(Feature.QA_POC)) {
    return null;
  }

  const { logEvents } = tracking.useTracking();

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: "#F7F7F8",
            borderRadiusLG: 16,
            borderRadiusSM: 16,
            // For some reason, the padding prop doesn't act as a default for the padding* props
            // Therefore, we need to set each individual viewport padding prop to 16
            padding: 16,
            paddingLG: 16,
            paddingXL: 16,
            paddingSM: 16,
            paddingMD: 16,
            paddingXS: 16,
            paddingXXS: 16,
            // For some even more insane reason, the padding prop only affects the vertical (top and bottom) padding
            // Have to manuall set the horizontal padding to 16 for each viewport
            paddingContentHorizontal: 16,
            paddingContentHorizontalLG: 16,
            paddingContentHorizontalSM: 16,
          },
        },
      }}
    >
      <Modal
        title={null}
        open={props.isOpen}
        footer={null}
        onCancel={() => {
          setQuestion(undefined);
          setSelectedQuestion(undefined);
          resetMutation();
          props.onClose(false);
        }}
        className={css.askAiModal}
        closable={false}
      >
        <form
          onSubmit={handleSubmit(({ input }) =>
            askQuestion({ input: { question: input, dataId: props.id, dataType: props.dataType } }),
          )}
        >
          <div className={css.askAiPanel}>
            <div className={css.historicalQuestionSection}>
              <Questions
                newChat={() => {
                  logEvents(createNewChatCreatedEvent(props.organisationName));
                  setQuestion(undefined);
                  setSelectedQuestion(undefined);
                  resetMutation();
                }}
                onQuestionClick={setSelectedQuestion}
                orgName={props.organisationName}
                orgId={props.id}
              />
            </div>
            <div className={css.newQuestionSection}>
              <Button
                className={css.closeButton}
                onClick={() => {
                  setQuestion(undefined);
                  setSelectedQuestion(undefined);
                  resetMutation();
                  props.onClose(false);
                }}
              >
                <CloseOutlined />
              </Button>
              {!data && !isLoading && !isError && !selectedQuestion && (
                // State for when a user is fresh onto the ai page and ready to ask a question
                <>
                  <div className={css.header}>
                    <img src={SPARKLES} height={40} alt="sparkles" className={css.sparkles} />
                    <div className={css.headerText}>
                      <h1 className={css.headerTitle}>Ask about {props.organisationName}</h1>
                      <p className={css.headerSubtitle}>
                        Find insights about this buyer by asking a question or picking a suggestion.
                      </p>
                    </div>
                  </div>
                </>
              )}
              {isError && (
                // State for when an error has occurred when asking a question
                <>
                  <p>
                    Sorry, there was an error generating your AI summary. Please try again, or if
                    the issue continues, please contact support.
                  </p>
                </>
              )}
              {isLoading && <LoadingState />}
              {data?.answer && !isLoading && (
                // State for when a user has asked a question and the response has been returned
                <ChatResponse
                  question={data.question}
                  questionId={data.id}
                  answer={data.answer}
                  orgName={props.organisationName}
                  orgId={props.id}
                  isComplete={data.isComplete}
                  reportId={reportId}
                />
              )}
              <div className={css.footer}>
                {!isLoading && !data && !isError && !selectedQuestion && (
                  <SuggestedPrompts
                    onPromptClick={(question: string) => {
                      askQuestion({
                        input: {
                          question,
                          dataId: props.id,
                          dataType: props.dataType,
                        },
                      });
                    }}
                    orgName={props.organisationName}
                  />
                )}

                {!question && (
                  <div className={css.inputContainer}>
                    <Input
                      name="input"
                      label={""}
                      control={control}
                      className={css.questionInput}
                      placeholder="Ask me a question..."
                      rules={{
                        required: "Please enter a question",
                        minLength: { value: 2, message: "Please enter a question" },
                      }}
                      disabled={isLoading}
                      suffix={
                        <Button
                          type="primary"
                          htmlType="submit"
                          shape="circle"
                          aria-label="Ask question"
                          icon={<ArrowUpOutlined />}
                        />
                      }
                    />
                    <p className={css.disclaimer}>
                      Stotles AI is currently in beta mode and can make mistakes.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </ConfigProvider>
  );
}
