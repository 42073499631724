import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import produce from "immer";
import { groupBy } from "lodash";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import {
  useBuyerSummary,
  useBuyerSummaryChart,
} from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  BuyerSummaryChartData,
  SpendDataFilters,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { EventNames, TrackingEvent, TrackingProvider, useTracking } from "lib/tracking";
import { SpendDataFilters as UiFilters } from "./hooks/useSpendPageState";
import { DateUnitSelector } from "./DateUnitSelector";
import SpendChart, { Dataset } from "./SpendChart";

type Props = {
  filters: SpendDataFilters;
  setFilters: Dispatch<SetStateAction<UiFilters>>;
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: Dispatch<"month" | "quarter" | "year">;
};

function createChartClickedOnEvent(filterValue: string): TrackingEvent {
  return {
    name: EventNames.chartClickedOn,
    data: {
      "Action type": "Filter applied",
      "Filter name": "Buyer name",
      "Context source": "Buyer spending chart",
      "Filter value": filterValue,
    },
  };
}

function BuyerChart({ filters, setFilters, dateUnit, setDateUnit }: Props) {
  const { logEvents } = useTracking();
  const { data: top10Buyers } = useBuyerSummary({
    filters,
    limit: 10,
    offset: 0,
    orderBy: "total",
    sortOrder: "DESC",
  });
  const { data, isLoading } = useBuyerSummaryChart(
    {
      filters: {
        ...filters,
        buyerIds: top10Buyers?.data.map((b) => b.buyer),
      },
      dateUnit,
    },
    { query: { enabled: !!top10Buyers } },
  );

  // convert to chart data grouped by buyer
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }
    const dates = Array.from(new Set(data.data.map((d) => d.date).filter((d) => d))).sort();

    const grouped =
      data &&
      groupBy(
        data.data.filter((d) => d.date),
        "buyerName",
      );

    return Object.entries(grouped).map(([buyer, data]) => {
      const dateMap = {} as Record<string, BuyerSummaryChartData>;
      for (const d of data) {
        dateMap[d.date] = d;
      }

      return {
        label: buyer,
        id: data[0]?.buyer,
        data: dates.map((date) => {
          const d = dateMap[date] || { date, total: 0 };
          return d;
        }),
      };
    });
  }, [data]);

  const onDatasetsClick = useCallback(
    (datasets: Dataset[]) => {
      if (datasets.length === 0) {
        return;
      }
      const buyers = datasets.map((d) => d.id);
      logEvents(createChartClickedOnEvent(buyers.join(", ")));
      setFilters(
        produce((draft) => {
          draft.buyers = buyers;
        }),
      );
    },
    [logEvents, setFilters],
  );

  return (
    <TrackingProvider data={{ "Context source": "Buyer spending chart" }}>
      <DetailsSection
        title={`Spend over time by ${
          top10Buyers && top10Buyers.total > 10 ? "top 10 buyers" : "buyer"
        }`}
        action={<DateUnitSelector dateUnit={dateUnit} setDateUnit={setDateUnit} />}
      >
        <SpendChart
          data={chartData}
          dateUnit={dateUnit}
          onDatasetsClick={onDatasetsClick}
          isLoading={isLoading}
        />
      </DetailsSection>
    </TrackingProvider>
  );
}

export default BuyerChart;
