import React from "react";

import { EM_DASH, formatDate } from "../../../lib/utils";

type FormattedDateProps = {
  date: string | null | undefined;
  locale?: string;
  format?: string;
  className?: string;
};

export const FormattedDate = ({
  date,
  locale = "en-GB",
  format = "dd LLL, yyyy",
  className,
}: FormattedDateProps) => {
  if (!date || isNaN(new Date(date).getTime())) return <>{EM_DASH}</>;

  const parsedDate = new Date(date);

  return <span className={className}>{formatDate(parsedDate, locale, format)}</span>;
};
