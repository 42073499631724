import React from "react";
import validateCurrencyCode from "validate-currency-code";

export function formatNumber({
  value,
  locale = "en-GB",
  shortFormat,
  currency,
}: {
  value: number | null | undefined;
  locale: string;
  currency?: string;
  shortFormat?: boolean;
}) {
  try {
    if (currency && !validateCurrencyCode(currency)) {
      currency = "GBP";
    }

    // Show fractions for values over 1000 when short format is used (e.g. £1.2k)
    let maximumFractionDigits = 0;
    if (shortFormat && value && value > 1000) {
      maximumFractionDigits = 1;
    }

    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits,
      notation: shortFormat ? "compact" : "standard",
      style: currency ? "currency" : undefined,
      currency,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - trailingZeroDisplay is not in the types but should be when ts is patched
      trailingZeroDisplay: "stripIfInteger",
    });

    // if no value, show £ --
    if (value !== 0 && !value) {
      return formatter.format(0).replace("0", " -- ").trim();
    }

    return formatter.format(value);
  } catch (e) {
    return value?.toString() || "";
  }
}

export const Currency = ({
  value,
  currency = "GBP",
  locale = "en-GB",
  shortFormat = false,
  className,
}: {
  value?: number | null;
  currency?: string;
  locale?: string;
  shortFormat?: boolean;
  className?: string;
}) => {
  return (
    <span className={className}>{formatNumber({ value, locale, shortFormat, currency })}</span>
  );
};

export const Number = ({
  value,
  locale = "en-GB",
  shortFormat = false,
  className,
}: {
  value?: number | null;
  locale?: string;
  shortFormat?: boolean;
  className?: string;
}) => {
  return <span className={className}>{formatNumber({ value, locale, shortFormat })}</span>;
};
