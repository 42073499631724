import React from "react";
import Icon, { PushpinFilled } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "antd5";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { SavedViewType } from "lib/generated/app-api";
import HolderIcon from "lib/icons/Holder";
import { assert } from "lib/utils";
import { useUnpinView } from "../../../lib/hooks/api/notices/views/useUnpinView";
import { useMyFeedPageState } from "../useMyFeedPageState";

import css from "./PinnedViewItem.module.scss";

type Props = {
  view: SavedViewType;
};

function PinnedViewItem({ view }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: view.id || "",
  });
  const { setSelectedView, selectedView } = useMyFeedPageState();
  const isSelected = selectedView?.id === view.id;

  const { mutate: unpinViewMutation, isLoading } = useUnpinView();

  return (
    <div
      key={view.id}
      className={isSelected ? css.savedViewActive : css.savedViewItem}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      {...attributes}
      {...listeners}
      aria-label="pinned view"
      onClick={() => setSelectedView(view.id || "", view.name)}
      role="button"
    >
      <div className={css.holderContainer}>
        <Icon
          aria-label="drag target"
          component={HolderIcon}
          className={css.holderIcon}
          key={view.name}
        />
      </div>
      <EllipsisTooltipText fullText={view.name} containerClassname={css.paneViewTitle} />
      <Button
        icon={<PushpinFilled />}
        type="link"
        className={css.pinnedIcon}
        aria-label="toggle pinned view"
        onClick={(event) => {
          event.stopPropagation();
          assert(view.id);
          unpinViewMutation({ id: view.id, name: view.name });
        }}
        loading={isLoading}
      />
      <div className={css.descriptionContainer}>
        {view.description ? (
          <EllipsisTooltipText
            fullText={view.description}
            containerClassname={css.description}
            tooltipProps={{ placement: "bottom" }}
          />
        ) : (
          <span className={css.placeholderDesc}>Add a description and it will appear here...</span>
        )}
      </div>
    </div>
  );
}

export default PinnedViewItem;
