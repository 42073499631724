import React from "react";
import { hot } from "react-hot-loader/root";
import { Redirect } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableFrameworks } from "components/framework/FilterableFrameworks";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useSubscription } from "lib/providers/Subscription";

import css from "./FrameworkListPage.module.scss";

export function FrameworkListPage() {
  const subscription = useSubscription();
  const hasFrameworksEnabled =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);
  if (!hasFrameworksEnabled) {
    return <Redirect to="/" replace={true} />;
  }

  return (
    <DetailsPage>
      <DetailsHeader>
        <h1 className={css.title}>Frameworks</h1>
      </DetailsHeader>
      <>
        <DetailsContent className={css.content}>
          <FilterableFrameworks />
        </DetailsContent>
      </>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(FrameworkListPage, {
    pageName: "Framework search",
  }),
);
