import qs from "qs";

import { convertSignalArrayToSignalValue } from "components/form_components/SignalSelect";
import { SupplierSearchRequest } from "lib/generated/app-api";
import { parseBooleanValue, parseNumberValue } from "lib/hooks/useURLState";
import { ColumnSetting } from "lib/types/models";

type SortField =
  | "name"
  | "country"
  | "sme"
  | "awards_count"
  | "expiries_count"
  | "signals"
  | "lots"
  | "frameworkLots"
  | "frameworkLastActivity"
  | "frameworkCallOffs";

const validSortfields: SortField[] = [
  "name",
  "country",
  "sme",
  "awards_count",
  "expiries_count",
  "lots",
  "signals",
  "frameworkLots",
  "frameworkLastActivity",
  "frameworkCallOffs",
];

export function isValidSortField(field?: string): SortField | undefined {
  if (field && validSortfields.includes(field as SortField)) {
    return field as SortField;
  }
  return undefined;
}

export type SupplierFilters = {
  signals: string[];
  buyerIds?: string[];
  buyerCategories?: string[];
  buyerLists?: string[];
  suppliers?: number[];
  guids?: string[];
  text: string;
  isSme?: boolean | "";
  lotIds?: string[];
  sort?: {
    field: SortField;
    order: "ASC" | "DESC";
  };
};

export type SupplierColumns =
  | "name"
  | "country"
  | "sme"
  | "notices"
  | "upcomingExpiries"
  | "signals"
  | "spendData"
  | "frameworkLots"
  | "frameworkLastActivity"
  | "frameworkCallOffs";

export const BASELINE_SUPPLIER_COLUMNS: ColumnSetting<SupplierColumns>[] = [
  { title: "Name", field: "name", disabled: true },
  { title: "Signals", field: "signals" },
  { title: "Country", field: "country" },
  { title: "Notices", field: "notices" },
  { title: "Upcoming expiries", field: "upcomingExpiries" },
  { title: "Is SME", field: "sme" },
  { title: "Total spend", field: "spendData" },
];

type Pagination = {
  pageSize: number;
  current: number;
};

export function convertSupplierFiltersToRequest(
  filters: SupplierFilters,
  pagination?: Pagination,
): SupplierSearchRequest {
  let buyerFilters: SupplierSearchRequest["buyerFilters"] = undefined;

  if (filters.buyerIds || filters.buyerCategories || filters.buyerLists) {
    buyerFilters = {
      buyerCategoryIds: filters.buyerCategories,
      buyerListIds: filters.buyerLists,
      buyerId: filters.buyerIds,
    };
  }

  return {
    text: filters.text,
    signals: convertSignalArrayToSignalValue(filters.signals),
    isSme: filters.isSme === "" ? undefined : filters.isSme,
    buyerFilters,
    id: filters.suppliers,
    guid: filters.guids,
    sortOrder: filters.sort?.order || "ASC",
    sort: filters.sort?.field || "name",
    limit: pagination ? pagination.pageSize : undefined,
    offset: pagination ? (pagination.current - 1) * pagination.pageSize : undefined,
  };
}

export const DEFAULT_FILTERS: SupplierFilters = {
  signals: [],
  text: "",
  sort: {
    field: "name",
    order: "ASC",
  },
};

const isDefined = <T>(value: T | undefined): value is T => typeof value !== "undefined";

export function generateSupplierUrl(supplierFilters: Partial<SupplierFilters>, prefix = "") {
  const params = {
    supplierFilters,
  };
  const paramsString = qs.stringify(params, { arrayFormat: "brackets" });
  return `${prefix}?${paramsString}`;
}

export function parseSuppliersUrlState(
  state: unknown,
  defaultFilters = DEFAULT_FILTERS,
): SupplierFilters {
  if (typeof state !== "object" || state === null) {
    return defaultFilters;
  }

  const filters = state as SupplierFilters;

  return {
    ...filters,
    isSme: parseBooleanValue(filters.isSme),
    suppliers: filters.suppliers?.map(parseNumberValue).filter(isDefined),
  };
}
