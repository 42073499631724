import React from "react";
import { hot } from "react-hot-loader/root";
import { Alert, message, Modal, Radio, Select } from "antd5";

import { useDescribeCompany } from "lib/hooks/api/admin/useDescribeCompany";
import { useDescribeUser } from "lib/hooks/api/admin/useDescribeUser";
import { useSwitchCompany } from "lib/hooks/api/admin/useSwitchCompany";
import { useSwitchTeam } from "lib/hooks/api/admin/useSwitchTeam";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import { CompanyDto, ResponseError, TeamDto } from "../../lib/generated/admin-api";
import { extractApiError, useAdminApi } from "../../lib/stotlesAdminApiContext";
import { assert } from "../../lib/utils";
import { WrapAdminComponent } from "./AdminComponent";
import SwitchCompanyByGuid from "./SwitchCompanyByGuid";
import SwitchCompanyRecents from "./SwitchCompanyRecents";
import SwitchCompanySearch from "./SwitchCompanySearch";

import css from "./SwitchCompanyModal.module.scss";

type Props = {
  userGuid: string;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  switchTeams: boolean;
};

const RECENT_COMPANIES_KEY = "recentCompanies";

function SwitchCompanyModal(props: Props) {
  const [selectedCompany, setSelectedCompany] = React.useState<CompanyDto>();
  const [selectedTeam, setSelectedTeam] = React.useState<TeamDto>();
  const [searchMode, setSearchMode] = React.useState<"searchByText" | "searchByGuid" | "recent">(
    "searchByText",
  );
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [recentCompanies, setRecentCompanies] = useLocalStorage<CompanyDto[]>(
    RECENT_COMPANIES_KEY,
    [],
  );

  const adminApi = useAdminApi();

  const { mutateAsync: switchCompany } = useSwitchCompany({
    onSuccess: () => {
      props.onClose();
      return message.success("Company switched successfully!");
    },
    onError: () => message.error("Failed to switch company"),
  });

  const { mutateAsync: switchTeam } = useSwitchTeam({
    onSuccess: () => {
      props.onClose();
      return message.success("Team switched successfully!");
    },
    onError: () => message.error("Failed to switch team"),
  });

  const { data: userData } = useDescribeUser(props.userGuid);

  const { data: currentCompany } = useDescribeCompany(userData?.user.companyGuid || "", {
    enabled: !!userData,
  });

  // Effect to set the selectedCompany when the modal is opened if switchTeams is true
  React.useEffect(() => {
    if (props.switchTeams && currentCompany && !selectedCompany) {
      setSelectedCompany(currentCompany);
    }
  }, [props.switchTeams, currentCompany, selectedCompany]);

  if (!userData) {
    return null;
  }

  const { user } = userData;

  const hasTeams = selectedCompany && selectedCompany.teams.length > 0;
  const canSave = !!(selectedCompany && (selectedTeam || !hasTeams));

  const onChangeSearch = (company: CompanyDto | undefined) => {
    assert(company);
    setSelectedCompany(company);
    if (company.teams.length === 1) {
      setSelectedTeam(company.teams[0]);
    } else {
      setSelectedTeam(undefined);
    }
  };

  const onChangeRecents = async (companyGuid: string | undefined) => {
    if (!companyGuid) {
      setSelectedCompany(undefined);
      return;
    }
    try {
      const { company } = await adminApi.api.describeCompany({
        describeCompanyRequest: { guid: companyGuid },
      });
      assert(company);
      setSelectedCompany(company);
      if (company.teams.length === 1) {
        setSelectedTeam(company.teams[0]);
      } else {
        setSelectedTeam(undefined);
      }
    } catch (error) {
      if (error instanceof ResponseError) {
        setErrorMessage(`Error ${error.response.status}: ${error.message}`);
      } else {
        setErrorMessage("Unknown error occurred.");
      }
    }
  };

  const onChangeGuid = async (companyGuid: string) => {
    if (!companyGuid) {
      setSelectedCompany(undefined);
      return;
    }
    try {
      const { company } = await adminApi.api.describeCompany({
        describeCompanyRequest: { guid: companyGuid },
      });
      assert(company);
      setSelectedCompany(company);
      if (company.teams.length === 1) {
        setSelectedTeam(company.teams[0]);
      }
    } catch (error) {
      if (error instanceof ResponseError) {
        setErrorMessage(`Error ${error.response.status}: ${error.message}`);
      } else {
        setErrorMessage("Unknown error occurred.");
      }
    }
  };

  const updateRecentCompanies = (company: CompanyDto) => {
    const companyAlreadyInRecents = recentCompanies.find(
      (c: CompanyDto) => c.guid === company.guid,
    );
    if (!companyAlreadyInRecents) {
      setRecentCompanies((current: CompanyDto[]) => [...current, company]);
    }
  };

  const onSubmit = async () => {
    assert(selectedCompany);
    assert(userData);
    try {
      if (props.switchTeams) {
        if (!selectedTeam) {
          throw new Error("No team selected");
        }
        await switchTeam({
          userId: props.userId,
          fields: {
            teamId: selectedTeam.id,
            userGuid: userData.user.guid,
          },
        });
      } else {
        await switchCompany({
          userId: props.userId,
          fields: {
            companyGuid: selectedCompany.guid,
            teamId: selectedTeam?.id || null,
            userGuid: userData.user.guid,
          },
        });
      }
      updateRecentCompanies(selectedCompany);
    } catch (error) {
      if (error instanceof ResponseError) {
        const apiError = await extractApiError(error);
        setErrorMessage(`Error ${error.response.status}: ${apiError.message}`);
      } else {
        setErrorMessage("Unknown error occurred.");
      }
    }
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      onOk={onSubmit}
      okText="Save"
      maskClosable={true}
      okButtonProps={{ disabled: !canSave }}
    >
      <div className={css.form}>
        <div>
          <h1>Switch {props.switchTeams ? "team" : "company"}</h1>
          <p>
            You are changing {props.switchTeams ? "team" : "company"} for {user.firstName}{" "}
            {user.lastName} ({user.email}).
          </p>
          <p>Current company: {currentCompany?.name}</p>

          {!user.isAdmin && (
            <Alert
              message="This user is not an admin: proceed only if you want to move a user from a duplicate company."
              type="warning"
            />
          )}
        </div>
        {!props.switchTeams && (
          <>
            <Radio.Group
              value={searchMode}
              onChange={(e) => {
                setSelectedCompany(undefined);
                setSelectedTeam(undefined);
                setSearchMode(e.target.value);
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="searchByText">Search by text</Radio.Button>
              <Radio.Button value="searchByGuid">Search by GUID</Radio.Button>
              {recentCompanies.length > 0 && (
                <Radio.Button value="recent">Recent companies</Radio.Button>
              )}
            </Radio.Group>
            {searchMode === "searchByText" && <SwitchCompanySearch onChange={onChangeSearch} />}
            {searchMode === "searchByGuid" && (
              <SwitchCompanyByGuid onSearch={onChangeGuid} companyName={selectedCompany?.name} />
            )}
            {recentCompanies.length > 0 && searchMode === "recent" && (
              <SwitchCompanyRecents
                companies={recentCompanies}
                onChange={onChangeRecents}
                clearButton={() => setRecentCompanies([])}
              />
            )}
          </>
        )}

        {hasTeams && (
          <Select<string>
            className={css.select}
            placeholder="Select the team"
            onChange={(teamId) => {
              setSelectedTeam(selectedCompany.teams.find((t) => t.id === teamId));
            }}
            value={selectedTeam?.id}
          >
            {selectedCompany.teams.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {errorMessage && <Alert message={errorMessage} type="error" />}
      </div>
    </Modal>
  );
}

export default hot(WrapAdminComponent(SwitchCompanyModal));
