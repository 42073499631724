import * as React from "react";
import classnames from "classnames";

import css from "./Line.module.scss";

type LineProps = {
  vertical?: boolean;
};

export function Line({ vertical }: LineProps): JSX.Element {
  return (
    <div
      className={classnames(css.line, { [css.horizontal]: !vertical, [css.vertical]: vertical })}
    ></div>
  );
}
