/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes
 */
export interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes {
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes
     */
    codes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes
     */
    prefixCodes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes
     */
    includeNull?: boolean;
}

/**
 * Check if a given object implements the NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes interface.
 */
export function instanceOfNoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSON(json: any): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes {
    return NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSONTyped(json, false);
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'prefixCodes': !exists(json, 'prefixCodes') ? undefined : json['prefixCodes'],
        'includeNull': !exists(json, 'includeNull') ? undefined : json['includeNull'],
    };
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodesToJSON(value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerCategoriesCpvCodes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codes': value.codes,
        'prefixCodes': value.prefixCodes,
        'includeNull': value.includeNull,
    };
}

