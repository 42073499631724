import React from "react";
import equal from "fast-deep-equal";

import { removeUndefined } from "./utils";

type BaseParamsType = Record<string, string | undefined>;

type Props<AppParamsType, UrlParamsType extends BaseParamsType> = {
  params: AppParamsType;
  onChange: (newParams: AppParamsType) => void;
  toUrlParams: (params: AppParamsType) => UrlParamsType;
  fromUrlParams: (urlParams: URLSearchParams) => AppParamsType;
  overrideWindow?: Window;
};

function getCurrentParams<AppParamsType>(
  defaults: AppParamsType,
  fromUrlParams: (urlParams: URLSearchParams) => AppParamsType,
  currentWindow?: Window,
): AppParamsType {
  const urlQuery = (currentWindow || window).location.search;
  if (!urlQuery) {
    return defaults;
  }
  const urlParams = new URLSearchParams(urlQuery);
  return fromUrlParams(urlParams);
}

export function convertParamsToUrl<AppParamsType, UrlParamsType extends BaseParamsType>(
  toUrlParams: (params: AppParamsType) => UrlParamsType,
  params: AppParamsType,
): string {
  const urlParams = toUrlParams(params);
  removeUndefined(urlParams);

  return new URLSearchParams(urlParams as Record<string, string>).toString();
}

/**
 * Updates the url params when `params` object changes (with shallow equality).
 * Listens to popstate events and calls `onChange` whenever the history changes.
 *
 * On the first render it extracts params from the url (if there are any) and calls `onChange`
 * this might mean that the parent component will use the initial params in the first render
 * and only after `setState` is processed it will use the params from URL.
 *
 * This might result in multiple API calls.
 * If this is an issue, consider using `getCurrentParams` defined above to get the initial value.
 */
export function useUrlParamsTracker<AppParamsType, UrlParamsType extends BaseParamsType>(
  props: Props<AppParamsType, UrlParamsType>,
): null {
  const { params, onChange, toUrlParams, fromUrlParams, overrideWindow } = props;

  const currentWindow = overrideWindow || window;

  // We don't want run the useEffect that registers listeners every time
  // the params change, so we store the current value in a ref
  // and update it in another `useEffect`
  const currentParams = React.useRef<AppParamsType>(params);

  React.useEffect(() => {
    const handler = () => {
      const newParams = getCurrentParams(currentParams.current, fromUrlParams, currentWindow);
      if (!equal(newParams, currentParams.current)) {
        currentParams.current = newParams;
        onChange(newParams);
      }
    };
    currentWindow.addEventListener("popstate", handler);
    return () => {
      currentWindow.removeEventListener("popstate", handler);
    };
  }, [onChange, fromUrlParams, currentWindow]);

  // run this just once
  React.useEffect(() => {
    const newParams = getCurrentParams(currentParams.current, fromUrlParams, currentWindow);
    if (!equal(newParams, currentParams.current)) {
      currentParams.current = newParams;
      onChange(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (equal(currentParams.current, params)) {
      return;
    }

    const urlQuery = convertParamsToUrl(toUrlParams, params);
    // When urlParams/urlQuery is empty we want to remove the `?...` completely
    // so we just use `currentWindow.location.pathname` to remove it.
    currentWindow.history.pushState(
      null,
      "",
      urlQuery ? `?${urlQuery}` : currentWindow.location.pathname,
    );
    currentParams.current = params;
  }, [params, toUrlParams, currentWindow]);
  return null;
}

// Wrapper over the `useUrlParamsTracker` hook so it can be easily used in class components
export function UrlParamsTracker<AppParamsType, UrlParamsType extends BaseParamsType>(
  props: Props<AppParamsType, UrlParamsType>,
): null {
  useUrlParamsTracker(props);
  return null;
}
