import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { BuyerStatsGqlQuery, NoticesSearchRequest } from "lib/generated/app-service-gql/graphql";
import { NoticesSortOrder, NoticesStages, SupplierMentionType } from "lib/types/graphQLEnums";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const query = graphql(`
  query buyerStatsGql(
    $inputAllNotices: NoticesSearchRequest!
    $inputOpenOpps: NoticesSearchRequest!
    $inputUpcomingExpiries: NoticesSearchRequest!
  ) {
    allNotices: notices(NoticesSearchRequest: $inputAllNotices) {
      totalResults
    }
    openOpps: notices(NoticesSearchRequest: $inputOpenOpps) {
      totalResults
    }
    upcomingExpiries: notices(NoticesSearchRequest: $inputUpcomingExpiries) {
      totalResults
    }
  }
`);

/**
 * Returns quick-fire stats for a buyer
 * @param req
 * @param options
 * @returns
 */
export function useBuyerStatsGql(
  orgId: string,
  options?: UseQueryOptions<BuyerStatsGqlQuery, unknown, BuyerStatsGqlQuery, QueryKey>,
) {
  const DEFAULT_STAT_FILTERS: NoticesSearchRequest = {
    buyerFilters: {
      buyers: [orgId],
    },
    minScore: 1,
    limit: 0,
    page: 1,
    sortOrder: NoticesSortOrder.Asc,
  };

  const allNoticeFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    supplierMentionType: SupplierMentionType.MentionedOrDirectlyAwarded,
  };

  const openOppsFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    stages: [NoticesStages.Open],
  };

  const upcomingExpiryFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    stages: [NoticesStages.Awarded],
    expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },
    supplierMentionType: SupplierMentionType.MentionedOrDirectlyAwarded,
  };

  const { data, ...rest } = useGraphQlQuery(
    ["buyerStatsGql", orgId],
    query,
    [
      {
        inputAllNotices: allNoticeFilters,
        inputOpenOpps: openOppsFilters,
        inputUpcomingExpiries: upcomingExpiryFilters,
      },
    ],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data, ...rest };
}
