import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NoticesSearchRequest, SupplierStatsGqlQuery } from "lib/generated/app-service-gql/graphql";
import { NoticesSortOrder, NoticesStages, SupplierMentionType } from "lib/types/graphQLEnums";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const query = graphql(`
  query supplierStatsGql(
    $inputDirect: NoticesSearchRequest!
    $inputMentioned: NoticesSearchRequest!
    $inputUpcomingExpiries: NoticesSearchRequest!
  ) {
    directAwards: notices(NoticesSearchRequest: $inputDirect) {
      totalResults
    }
    mentionedNotices: notices(NoticesSearchRequest: $inputMentioned) {
      totalResults
    }
    upcomingExpiries: notices(NoticesSearchRequest: $inputUpcomingExpiries) {
      totalResults
    }
  }
`);

/**
 * Returns quick-fire stats for a supplier
 * @param req
 * @param options
 * @returns
 */
export function useSupplierStatsGql(
  orgId: string,
  options?: UseQueryOptions<SupplierStatsGqlQuery, unknown, SupplierStatsGqlQuery, QueryKey>,
) {
  const DEFAULT_STAT_FILTERS: NoticesSearchRequest = {
    suppliers: [orgId],
    limit: 0,
    page: 1,
    sortOrder: NoticesSortOrder.Asc,
  };

  const directAwardFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    supplierMentionType: SupplierMentionType.DirectlyAwarded,
    stages: [NoticesStages.Awarded],
  };

  const mentionedFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    supplierMentionType: SupplierMentionType.MentionedInOnly,
  };

  const upcomingExpiryFilters: NoticesSearchRequest = {
    ...DEFAULT_STAT_FILTERS,
    stages: [NoticesStages.Awarded],
    expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },
    supplierMentionType: SupplierMentionType.MentionedOrDirectlyAwarded,
  };

  const { data, ...rest } = useGraphQlQuery(
    ["supplierStatsGql", orgId],
    query,
    [
      {
        inputDirect: directAwardFilters,
        inputMentioned: mentionedFilters,
        inputUpcomingExpiries: upcomingExpiryFilters,
      },
    ],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data, ...rest };
}
