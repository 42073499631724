import * as React from "react";
import { Collapse, Skeleton, Switch as AntSwitch } from "antd5";

import { CollapseHeader } from "lib/core_components/CollapseHeader";
import { useNotificationSettings } from "lib/providers/NotificationSettings";

import css from "./NotificationEditor.module.scss";

type NotificationEditorProps = {
  selectedViewIds: string[];
  selectedListIds: string[];
  onViewsChange: (keys: string[]) => void;
  onListsChange: (keys: string[]) => void;
};

function NotificationEditor({
  selectedViewIds,
  onViewsChange,
}: NotificationEditorProps): JSX.Element {
  const settings = useNotificationSettings();
  const toggleView = React.useCallback(
    (key: string) => onViewsChange(toggle(key, selectedViewIds)),
    [onViewsChange, selectedViewIds],
  );

  const [expanded, setExpanded] = React.useState<string | string[]>(["views", "lists"]);

  return (
    <div className={css.notificationContentTypes}>
      <h2>What notifications you receive</h2>
      <Collapse
        activeKey={expanded}
        onChange={(e) => setExpanded(e)}
        items={[
          {
            key: "views",
            label: <CollapseHeader title="Views" itemKey="views" activeKeys={expanded} small />,
            showArrow: false,
            children: (
              <div className={css.content}>
                {settings.status === "ready" || settings.status === "reloading" ? (
                  <>
                    <SwitchSet
                      header="Standard"
                      options={settings.savedViews}
                      filter={(v) => v.is_standard_view}
                      toggle={toggleView}
                      isOn={(id) => selectedViewIds.includes(id)}
                      noOptionsMessage="No views available"
                    />
                    <SwitchSet
                      header="Created by me"
                      options={settings.savedViews}
                      filter={(v) => v.owner_id === window.currentUser?.guid}
                      toggle={toggleView}
                      isOn={(id) => selectedViewIds.includes(id)}
                      noOptionsMessage="No views available"
                    />
                    <SwitchSet
                      header="Created by others"
                      options={settings.savedViews}
                      filter={(v) => !v.is_standard_view && v.owner_id !== window.currentUser?.guid}
                      toggle={toggleView}
                      isOn={(id) => selectedViewIds.includes(id)}
                      noOptionsMessage="No views available"
                    />
                  </>
                ) : (
                  <>
                    <Skeleton
                      active={settings.status === "loading"}
                      paragraph={{ rows: 2, width: "100%" }}
                    />
                    <Skeleton
                      active={settings.status === "loading"}
                      paragraph={{ rows: 2, width: "100%" }}
                    />
                    <Skeleton
                      active={settings.status === "loading"}
                      paragraph={{ rows: 2, width: "100%" }}
                    />
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

type SwitchSetRow = {
  guid: string;
  name: string;
};

type SwitchSetProps<T extends SwitchSetRow> = {
  options: T[];
  filter?: (option: T) => boolean;
  toggle: (key: string) => void;
  isOn: (id: string) => boolean;
  header: string;
  noOptionsMessage: string;
};

export function SwitchSet<T extends SwitchSetRow>({
  options,
  filter,
  toggle,
  isOn,
  header,
  noOptionsMessage,
}: SwitchSetProps<T>): JSX.Element {
  if (filter) {
    options = options.filter(filter);
  }
  return (
    <div className={css.switchSet}>
      <h4 className={css.spanRow}>{header}</h4>
      {options.length ? (
        options.map(({ guid, name }) => (
          <React.Fragment key={guid}>
            <div>{name}</div>
            <div>
              <AntSwitch checked={isOn(guid)} onChange={() => toggle(guid)} />
            </div>
          </React.Fragment>
        ))
      ) : (
        <span className={css.noOptionsMessage}>{noOptionsMessage}</span>
      )}
    </div>
  );
}

function toggle<T>(value: T, arr: T[]): T[] {
  if (arr.includes(value)) {
    return arr.filter((v) => v !== value);
  }
  return [...arr, value];
}
export default NotificationEditor;
