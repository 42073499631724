import * as React from "react";
import { Modal as AntModal, ModalProps as AntModalProps } from "antd5";

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  bodyStyle?: React.CSSProperties;
} & Omit<AntModalProps, "closable" | "title" | "footer" | "visible">;

export default function Modal({ isOpen, children, bodyStyle, ...others }: ModalProps): JSX.Element {
  return (
    <AntModal
      {...others}
      open={isOpen}
      title={null}
      closable={false}
      footer={null}
      bodyStyle={bodyStyle}
    >
      {children}
    </AntModal>
  );
}
