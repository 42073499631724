/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentSearchQueryScoreFilterScoresInnerCriteria } from './DocumentSearchQueryScoreFilterScoresInnerCriteria';
import {
    DocumentSearchQueryScoreFilterScoresInnerCriteriaFromJSON,
    DocumentSearchQueryScoreFilterScoresInnerCriteriaFromJSONTyped,
    DocumentSearchQueryScoreFilterScoresInnerCriteriaToJSON,
} from './DocumentSearchQueryScoreFilterScoresInnerCriteria';

/**
 * 
 * @export
 * @interface DocumentSearchQueryScoreFilterScoresInner
 */
export interface DocumentSearchQueryScoreFilterScoresInner {
    /**
     * 
     * @type {DocumentSearchQueryScoreFilterScoresInnerCriteria}
     * @memberof DocumentSearchQueryScoreFilterScoresInner
     */
    criteria: DocumentSearchQueryScoreFilterScoresInnerCriteria;
    /**
     * 
     * @type {number}
     * @memberof DocumentSearchQueryScoreFilterScoresInner
     */
    boost: number;
}

/**
 * Check if a given object implements the DocumentSearchQueryScoreFilterScoresInner interface.
 */
export function instanceOfDocumentSearchQueryScoreFilterScoresInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "criteria" in value;
    isInstance = isInstance && "boost" in value;

    return isInstance;
}

export function DocumentSearchQueryScoreFilterScoresInnerFromJSON(json: any): DocumentSearchQueryScoreFilterScoresInner {
    return DocumentSearchQueryScoreFilterScoresInnerFromJSONTyped(json, false);
}

export function DocumentSearchQueryScoreFilterScoresInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSearchQueryScoreFilterScoresInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': DocumentSearchQueryScoreFilterScoresInnerCriteriaFromJSON(json['criteria']),
        'boost': json['boost'],
    };
}

export function DocumentSearchQueryScoreFilterScoresInnerToJSON(value?: DocumentSearchQueryScoreFilterScoresInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': DocumentSearchQueryScoreFilterScoresInnerCriteriaToJSON(value.criteria),
        'boost': value.boost,
    };
}

