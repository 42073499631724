import React, { useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";

import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import { UseSearchFrameworks } from "lib/hooks/api/frameworks/useSearchFrameworks";
import { useURLState } from "lib/hooks/useURLState";
import { EventDataTypes } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import { FILTER_COLLAPSE_FRAMEWORKS, useVariableValue } from "../../lib/featureFlags";
import { findKeybyValue } from "../../lib/utils";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import ExportFrameworksButton from "./ExportFrameworksButton";
import { FilterForm, FilterFormProps, groupedKeys } from "./FilterForm";
import {
  ALL_FRAMEWORK_COLUMNS,
  DEFAULT_FRAMEWORK_COLUMNS,
  FrameworkColumns,
  FrameworkTable,
} from "./FrameworkTable";
import { QuickFilterBar } from "./QuickFilterBar";
import { useFrameworksTracking } from "./tracking";
import { DEFAULT_FILTERS, FrameworkFilters, parseFrameworksUrlState } from "./utils";

import css from "./FilterableFrameworks.module.scss";

type Props = {
  defaultFilters?: FrameworkFilters;
  allAvailableColumns?: ColumnSetting<FrameworkColumns>[];
  defaultColumns?: ColumnSetting<FrameworkColumns>[];
  hiddenFilterSections?: FilterFormProps["hiddenSections"];
  frameworkProvider?: UseSearchFrameworks;
};
export function FilterableFrameworks({
  defaultFilters = DEFAULT_FILTERS,
  defaultColumns = DEFAULT_FRAMEWORK_COLUMNS,
  allAvailableColumns = ALL_FRAMEWORK_COLUMNS,
  hiddenFilterSections,
  frameworkProvider,
}: Props) {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...defaultColumns]);
  const [filters = defaultFilters, setFilters] = useURLState<FrameworkFilters>(
    "frameworkFilters",
    defaultFilters,
    parseFrameworksUrlState,
  );
  const [frameworkSearch, setFrameworkSearch] = useState(filters.searchText);
  const [selectedFrameworks, setSelectedFrameworks] = useState<string[]>([]);
  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((f: FrameworkFilters) => {
    setFilters(f);
  });

  const [activeGroups, setActiveGroups] = useState<string[]>([]);

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_FRAMEWORKS, false);

  const tracking = useFrameworksTracking();
  const debouncedFreeSearchLog = useDebounce(() => tracking.freeSearchLog());

  return (
    <div className={css.container}>
      <div className={css.actionsContainer}>
        <div className={css.actions}>
          <Input
            placeholder="Search by framework name"
            prefix={<SearchOutlined />}
            value={frameworkSearch}
            onChange={(e) => {
              setFrameworkSearch(e.target.value);
              debouncedSetFilters({ ...filters, searchText: e.target.value });
              debouncedFreeSearchLog();
            }}
            allowClear
            className={css.searchInput}
            data-hj-allow
          />
          <Button
            onClick={() => {
              setIsFilterDrawerOpen(true);
              tracking.filterDrawerOpened();
            }}
          >
            <FilterOutlined className={css.filterIcon} /> Filters
          </Button>
        </div>
        <div className={css.actions}>
          <div className={css.clearSelected}>
            {selectedFrameworks && selectedFrameworks.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedFrameworks.length}
                  onClear={() => setSelectedFrameworks([])}
                />
                :
              </>
            )}
          </div>
          {selectedFrameworks && selectedFrameworks.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={allAvailableColumns}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<FrameworkColumns>[])])
              }
              dataType={EventDataTypes.frameworks}
            />
          )}

          <ExportFrameworksButton filters={filters} selectedFrameworks={selectedFrameworks} />
        </div>
      </div>
      <QuickFilterBar
        filters={filters}
        onClearFilter={(key) => setFilters({ ...filters, [key]: undefined })}
        onFilterClick={(key) => {
          setIsFilterDrawerOpen(true);
          setActiveGroups([findKeybyValue(key, groupedKeys) || ""]);
        }}
        hiddenFilterSections={hiddenFilterSections}
        clearAllFilters={() => setFilters(defaultFilters)}
      />
      <FrameworkTable
        filters={filters}
        onSortChange={(sort) => setFilters({ ...filters, sort })}
        selectedColumns={selectedColumns.map((f) => f.field)}
        selectedRows={selectedFrameworks}
        onSelectedRowsChange={(rows) => setSelectedFrameworks(rows)}
        frameworkProvider={frameworkProvider}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onClose={() => setIsFilterDrawerOpen(false)}
            onChange={debouncedSetFilters}
            filters={filters}
            showTitle={isCollapseEnabled}
            hiddenSections={hiddenFilterSections}
            activeGroups={activeGroups}
            onActiveGroupsChange={setActiveGroups}
          />
        )}
      </FilterDrawer>
    </div>
  );
}
