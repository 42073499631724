import BuyersFilterContent from "./Buyers";
import ContractDatesFilterContent from "./ContractDates";
import ContractValueFilterContent from "./ContractValue";
import CPVCodeFilterContent from "./CPVCode";
import FrameworkFilterContent from "./Framework";
import KeywordsFilterContent from "./Keywords";
import ProcurementStageFilterContent from "./ProcurementStage";
import SignalsFilterContent from "./Signals";
import SuppliersFilterContent from "./Suppliers";

export const frameworkFilterContents = {
  keywords: KeywordsFilterContent,
  signals: SignalsFilterContent,
  procurementStage: ProcurementStageFilterContent,
  framework: FrameworkFilterContent,
  buyers: BuyersFilterContent,
  suppliers: SuppliersFilterContent,
  contractDates: ContractDatesFilterContent,
  contractValue: ContractValueFilterContent,
  cpvCode: CPVCodeFilterContent,
};
