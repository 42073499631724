import React from "react";
import { Empty } from "antd5";

import { TypedColumnProps } from "lib/search/SearchTable";
import { BuyerSearchStats } from "./AllBuyersTable";
import { BuyerListStats, SkeletonBuyerListTable } from "./BuyersTable";

import css from "./FindBuyersCommon.module.scss";

export enum BuyerListRoute {
  RELEVANT_BUYERS = "/relevant-buyers",
  ALL_BUYERS = "/all-buyers",
}

type LoadingFindBuyersProps = {
  pageSize: number;
  columns: TypedColumnProps<BuyerListStats>[] | TypedColumnProps<BuyerSearchStats>[];
};

/**
 * Common loading page for the find buyer pages
 */
export function LoadingFindBuyers({ pageSize, columns }: LoadingFindBuyersProps): JSX.Element {
  return <SkeletonBuyerListTable pageSize={pageSize} columns={columns} />;
}

type EmptyFindBuyersProps = {
  description: string;
};

/**
 * Common empty page for the find buyer pages
 */
export function EmptyFindBuyers({ description }: EmptyFindBuyersProps): JSX.Element {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null}>
      <h2 className={css.errorHeader}>No buyers match your current filters</h2>
      <p className={css.errorDesc}>{description}</p>
    </Empty>
  );
}
