import React from "react";
import { Spin, Tabs, TabsProps } from "antd5";
import { useLocation } from "wouter";

import { FilterableSuppliers } from "components/suppliers/FilterableSuppliers";
import { FilterSections } from "components/suppliers/FilterForm";
import { BASELINE_SUPPLIER_COLUMNS, SupplierFilters } from "components/suppliers/utils";
import { LAYER_CAKE_FILTERS, NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { PartnersCompetitorsRequest } from "lib/generated/app-api";
import { useBuyersPartnersCompetitors } from "lib/hooks/api/buyer/useBuyersPartnersCompetitors";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useSuppliers } from "lib/hooks/api/suppliers/useSuppliers";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { BuyerSummary } from "lib/types/models";
import { DEFAULT_FILTERS, FilterablePartnerCompetitors } from "./FilterablePartnerCompetitors";
import { DEFAULT_PAGE_AND_SORT } from "./PartnerCompetitorTable";
import { SecondaryTab } from "./types";

import css from "./SupplierTab.module.scss";

const partnerRequest: PartnersCompetitorsRequest = {
  kind: "partner",
  ...DEFAULT_FILTERS,
  sort: DEFAULT_PAGE_AND_SORT.sort.field,
  sortOrder: DEFAULT_PAGE_AND_SORT.sort.order,
  offset:
    (DEFAULT_PAGE_AND_SORT.pagination.current - 1) * DEFAULT_PAGE_AND_SORT.pagination.pageSize,
  limit: DEFAULT_PAGE_AND_SORT.pagination.pageSize,
};

const competitorRequest: PartnersCompetitorsRequest = {
  ...partnerRequest,
  kind: "competitor",
};

type Props = { buyer: BuyerSummary; isGuestPass?: boolean };

function getSelectedTab(url: string) {
  switch (url) {
    case SecondaryTab.PARTNERS:
      return SecondaryTab.PARTNERS;
    case SecondaryTab.COMPETITORS:
      return SecondaryTab.COMPETITORS;
    default:
      return SecondaryTab.SUPPLIERS;
  }
}

export function SupplierTab({ buyer, isGuestPass }: Props) {
  const [location, setLocation] = useLocation();

  const DEFAULT_BUYER_SUPPLIER_FILTERS: SupplierFilters = {
    buyerIds: [buyer.guid],
    signals: [],
    text: "",
    sort: { field: "signals", order: "DESC" },
  };

  const hideCompetitorPartnerTabs =
    useVariableValue(NEW_SUPPLIER_FILTER, false) && window.currentUser?.use_supplier_name === false;

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Supplier tab",
  });

  const { data: allSuppliers, isLoading: isLoadingAllSuppliers } = useSuppliers({
    buyerFilters: { buyerId: [buyer.guid] },
    sortOrder: "DESC",
    sort: "signals",
    offset: 0,
    limit: 10,
    signals: { categories: [], ids: [] },
    text: "",
  });

  const { data: partners, isLoading: isLoadingPartners } = useBuyersPartnersCompetitors(
    {
      buyerId: buyer.id,
      request: partnerRequest,
    },
    {
      enabled: !hideCompetitorPartnerTabs,
    },
  );

  const { data: competitors, isLoading: isLoadingCompetitors } = useBuyersPartnersCompetitors(
    {
      buyerId: buyer.id,
      request: competitorRequest,
    },
    {
      enabled: !hideCompetitorPartnerTabs,
    },
  );

  const { data: savedSuppliers } = useSavedSuppliers({
    enabled: hideCompetitorPartnerTabs && !window.guestUser,
  });

  const hiddenSections: FilterSections[] = ["buyers", "lots"];
  if (useVariableValue(LAYER_CAKE_FILTERS, false)) {
    hiddenSections.push("signals");
  }

  // If supplier signals is enabled, we don't want to show the partners and competitors tabs
  if (hideCompetitorPartnerTabs) {
    const currentSelectedTabFilters = (): SupplierFilters => {
      const loc = getSelectedTab(location);

      switch (loc) {
        case SecondaryTab.COMPETITORS: {
          return {
            ...DEFAULT_BUYER_SUPPLIER_FILTERS,
            guids: savedSuppliers?.competitorGuids,
          };
        }

        case SecondaryTab.PARTNERS: {
          return {
            ...DEFAULT_BUYER_SUPPLIER_FILTERS,
            guids: savedSuppliers?.partnerGuids,
          };
        }

        default: {
          return DEFAULT_BUYER_SUPPLIER_FILTERS;
        }
      }
    };
    return (
      <FilterableSuppliers
        defaultFilters={currentSelectedTabFilters()}
        hiddenFilterSections={hiddenSections}
        defaultColumns={BASELINE_SUPPLIER_COLUMNS.filter((c) => c.field !== "upcomingExpiries")}
        linkToBuyerRelationshipPage={{ buyerGuid: buyer.guid }}
      />
    );
  }

  const tabs: TabsProps["items"] = [
    {
      key: SecondaryTab.SUPPLIERS,
      label: (
        <TabLabel
          label="Suppliers"
          totalResults={allSuppliers?.pagingInfo.totalResults || 0}
          isLoading={isLoadingAllSuppliers}
          showPaywall={false}
        />
      ),
      children: (
        <FilterableSuppliers
          defaultFilters={DEFAULT_BUYER_SUPPLIER_FILTERS}
          hiddenFilterSections={hiddenSections}
          defaultColumns={BASELINE_SUPPLIER_COLUMNS.filter((c) => c.field !== "upcomingExpiries")}
          linkToBuyerRelationshipPage={{ buyerGuid: buyer.guid }}
        />
      ),
    },
  ];

  if (!isGuestPass) {
    tabs.push(
      {
        key: SecondaryTab.PARTNERS,
        label: (
          <TabLabel
            label="Partners"
            totalResults={partners?.pagingInfo.totalResults || 0}
            isLoading={isLoadingPartners}
            showPaywall={!hasSuppliers}
          />
        ),
        children: <FilterablePartnerCompetitors type="partner" buyer={buyer} />,
      },
      {
        key: SecondaryTab.COMPETITORS,
        label: (
          <TabLabel
            label="Competitors"
            totalResults={competitors?.pagingInfo.totalResults || 0}
            isLoading={isLoadingCompetitors}
            showPaywall={!hasSuppliers}
          />
        ),
        children: <FilterablePartnerCompetitors type="competitor" buyer={buyer} />,
      },
    );
  }

  return (
    <Tabs items={tabs} activeKey={getSelectedTab(location)} onChange={(t) => setLocation(t)} />
  );
}

function TabLabel({
  label,
  showPaywall,
  isLoading,
  totalResults,
}: {
  label: string;
  isLoading: boolean;
  totalResults: number;
  showPaywall: boolean;
}) {
  if (showPaywall) {
    return (
      <>
        {label} <PaywallStar className={css.paywallIcon} />
      </>
    );
  }

  return (
    <>
      {label} {isLoading ? <Spin /> : `(${totalResults})`}
    </>
  );
}
