import { PersonalSubscriptionContent } from "lib/generated/app-api";

const containsDailyAlert = (alerts: PersonalSubscriptionContent[]) => {
  return alerts.some((alert) => alert.frequency === "DAILY");
};

/* 
    A view should generally have a single email alert set up
    A slight side effect of the previous notification settings UI is that there are now some views that have both daily AND weekly alerts
    Until the database is cleaned up, we only keep the daily frequency alert as workaround 
  */
export const getAlert = (personalAlerts: PersonalSubscriptionContent[], savedViewId: string) => {
  let savedViewAlerts = personalAlerts.filter(
    (alert) => alert.resourceType === "SavedView" && alert.resourceId === savedViewId,
  );

  // If there is more than one email alert for the same resource, it means the alert has been set up for both frequencies
  if (savedViewAlerts.length > 1 && containsDailyAlert(savedViewAlerts)) {
    savedViewAlerts = savedViewAlerts.filter((alert) => alert.frequency === "DAILY");
  }

  return savedViewAlerts.length > 0 ? savedViewAlerts[0] : undefined;
};
