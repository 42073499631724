import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { FormState } from "pages/app/OnboardingPage";

import { useOpenApi } from "lib/openApiContext";
import { useSubscription } from "lib/providers/Subscription";
import { SelfServeQuery } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { Status, TenderStage } from "lib/types/models";

type useSubmitOnboardingParams = {
  companyId: number;
  formState: FormState;
};

export function useSubmitOnboarding(
  options?: UseMutationOptions<string, unknown, useSubmitOnboardingParams, unknown>,
) {
  const api = useStotlesApi();
  const openApi = useOpenApi();

  const subscription = useSubscription();

  const defaultFrom = new Date();
  defaultFrom.setMonth(defaultFrom.getMonth() - 1);
  defaultFrom.setHours(0, 0, 0, 0);

  function formStateToFeedCriteria(formState: FormState, statusFilters: Status[]): SelfServeQuery {
    return {
      countries: formState.countries,
      languages: formState.languages,
      keywords: {
        keywords: formState.keywords,
        from_date: defaultFrom,
        status: statusFilters,
        exclude_keywords: formState.excludeKeywords,
        cpv_codes: formState.keywordsCpvCodes,
        cpv_codes_include_null: formState.keywordsCpvCodesIncludeNull,
      },
    };
  }

  return useMutation(async ({ companyId: company_id, formState }: useSubmitOnboardingParams) => {
    const tenderStatuses = subscription.hasDataTypes("AWARDS")
      ? [TenderStage.PRE_TENDER, TenderStage.OPEN, TenderStage.AWARDED]
      : [TenderStage.PRE_TENDER, TenderStage.OPEN];

    await api.completeOnboarding(company_id);
    const response = await api.saveSelfServeQuery(company_id, {
      query: formStateToFeedCriteria(formState, tenderStatuses),
    });

    await openApi.updatePubSecExperience({
      updatePubSecExperienceRequest: { pubSecExperience: formState.experience },
    });

    tracking.logEvent(tracking.EventNames.recordQueryCreated, {
      Countries: formState.countries,
      Keywords: formState.keywords,
    });

    return response.job_id;
  }, options);
}
