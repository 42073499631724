import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { App, Button } from "antd5";

import * as tracking from "lib/tracking";
import { PageSpinner } from "../../lib/core_components/PageSpinner";
import { useUpdateSignalSettings } from "../../lib/hooks/api/signals/useUpdateSignalSettings";
import { useSignalSettingsGQL } from "../../lib/hooks/api/teams/useSignalSettingsGQL";
import { useUnsavedChangeWarning } from "../../lib/hooks/useUnsavedChangesWarning";
import useWatchFormValues from "../../lib/hooks/useWatchFormValues";
import Signal from "../../lib/icons/Signal";
import { useSubscription } from "../../lib/providers/Subscription";
import { SelfServeQuery, SignalCategory } from "../../lib/StotlesApi";
import {
  createSignalSettingsSchema,
  defaultSignalSettings,
  generatePreviewFeedCriteria,
  getDefaultSignalSettings,
  parseSignalSettings,
  setParsedResultCounts,
  SignalSettingsForm,
  updatePreviewFeedCriteria,
} from "../../lib/utils/signalSettingsUtils";
import { ResultCountsType } from "../onboarding/onboardingUtils";
import { FeedPreviewPageSources } from "../onboarding/SharedOnboarding";
import Collapsible from "../ui/collapsible/Collapsible";
import FeedSettingsPreview from "./feed_settings/Preview";
import SignalSettingsConfirmationModal from "./signal_settings/confirmation_modal/SignalSettingsConfirmationModal";
import CountryInput from "./signal_settings/country_input/CountryInput";
import CPVCodeInput from "./signal_settings/cpv_code_input/CPVCodeInput";
import SignalSettingsDiscardModal from "./signal_settings/discard_modal/SignalSettingsDiscardModal";
import KeywordInput from "./signal_settings/keyword_input/KeywordInput";
import SupplierInput from "./signal_settings/supplier_input/SupplierInput";

import css from "./SignalSettings.module.scss";

type SignalSettingsProps = {
  internationalDataAccess: boolean;
};

export default function SignalSettings({ internationalDataAccess }: SignalSettingsProps) {
  const { message } = App.useApp();
  const subscription = useSubscription();
  const { data: signals, isLoading: isLoadingSignals } = useSignalSettingsGQL();
  const {
    mutate: updateSignalSettings,
    isSuccess: isSuccessUpdateSignalSettings,
    reset: resetUpdateSignalSettings,
  } = useUpdateSignalSettings({
    onSuccess: (_) => {
      tracking.logEvent(tracking.EventNames.recordQueryUpdated, {});
      message.success("Signal settings updated successfully");
    },
    onError: (_) => {
      message.error("Failed to update signal settings");
    },
  });

  const signalSettingsSchema = createSignalSettingsSchema(internationalDataAccess);

  const [previewFeedCriteria, setPreviewFeedCriteria] = useState<SelfServeQuery>(
    generatePreviewFeedCriteria(),
  );
  const [resultCounts, setResultCounts] = useState<ResultCountsType>();
  const [feedPreviewLoading, setFeedPreviewLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    reset: resetForm,
    formState: { isDirty },
  } = useForm<SignalSettingsForm>({
    resolver: zodResolver(signalSettingsSchema),
    defaultValues: signals ? getDefaultSignalSettings(signals) : defaultSignalSettings,
  });

  // Prompt user to confirm if they have unsaved changes
  useUnsavedChangeWarning(isDirty);

  // Watch for changes to the form values and update the preview feed criteria
  const handleFormValuesChange = useCallback(
    (values: SignalSettingsForm) => {
      updatePreviewFeedCriteria(values, subscription, setPreviewFeedCriteria);
    },
    [subscription],
  );
  useWatchFormValues(control, handleFormValuesChange);

  useEffect(() => {
    if (signals) {
      resetForm(getDefaultSignalSettings(signals));
      handleFormValuesChange(getValues());
    }
  }, [signals, resetForm, handleFormValuesChange, getValues]);

  const handleModalSubmit = () => {
    const { countries, languages, keywords, competitors, partners, cpvCodes } = getValues();
    const parsedSignalSettings = parseSignalSettings({
      countries,
      languages,
      keywords,
      competitors,
      partners,
      cpvCodes,
    });
    updateSignalSettings(parsedSignalSettings);
  };

  const handleConfirmationRedirect = () => {
    window.location.href = "/";
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleDiscardModalOpen = () => {
    setIsDiscardModalOpen(true);
  };

  const handleDiscardModalCancel = () => {
    setIsDiscardModalOpen(false);
  };

  const handleDiscardModalSubmit = () => {
    resetForm();
    handleDiscardModalCancel();
  };

  const handleResultCountsChange = (resultCounts: ResultCountsType) => {
    setParsedResultCounts(resultCounts, setResultCounts);
  };

  const onSubmit: SubmitHandler<SignalSettingsForm> = () => {
    setIsModalOpen(true);
  };

  if (isLoadingSignals) {
    return (
      <div className={css.spinnerContainer}>
        <PageSpinner />
      </div>
    );
  }

  const ActionButtons = () => {
    return (
      <>
        <Button onClick={handleDiscardModalOpen}>Discard Changes</Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </>
    );
  };

  return (
    <form className={css.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.header}>
        <div className={css.headerContainer}>
          <div className={css.headerTitle}>
            <Signal size={24} />
            <p className={css.title}>Signal Settings</p>
          </div>
          <div className={css.headerActions}>
            <ActionButtons />
          </div>
        </div>
        <p className={css.subtitle}>
          We use signals to provide more relevant data for you in the app. Customise your signals to
          improve your signal score and find the most relevant information.
        </p>
      </div>
      <div className={css.content}>
        {/* Signal Settings */}
        <div className={css.signalSettingsContainer}>
          <Collapsible
            title="Which countries would you like to cover?"
            subtitle="Find notices from buyers based in the following countries."
            defaultOpen
          >
            <CountryInput
              countryFieldName="countries"
              languageFieldName="languages"
              control={control}
              internationalDataAccess={internationalDataAccess}
            />
          </Collapsible>

          <Collapsible
            title="Track keywords"
            subtitle="Find notices about your products & services by tracking mentions of related keywords."
            defaultOpen
          >
            <KeywordInput
              name="keywords"
              control={control}
              resultCounts={resultCounts && resultCounts[SignalCategory.KEYWORD]}
            />
          </Collapsible>

          <Collapsible
            title="Track Suppliers"
            subtitle="Save suppliers as your competitors & partners to track their activity and find upcoming renewals. Learn more"
            defaultOpen
          >
            <SupplierInput
              competitorFieldName="competitors"
              partnerFieldName="partners"
              control={control}
              competitorCounts={resultCounts && resultCounts[SignalCategory.COMPETITOR]}
              partnerCounts={resultCounts && resultCounts[SignalCategory.PARTNER]}
            />
          </Collapsible>

          <Collapsible
            title="Track CPV codes"
            subtitle="Save CPV codes that are most relevant to your business to source opportunities. Learn more"
            defaultOpen
          >
            <CPVCodeInput name="cpvCodes" control={control} />
          </Collapsible>
        </div>

        <div className={css.noticesContainer}>
          <FeedSettingsPreview
            feedCriteria={previewFeedCriteria}
            pageSource={FeedPreviewPageSources.FEED_SETTINGS}
            onResultCountsChange={handleResultCountsChange}
            setFeedPreviewLoading={setFeedPreviewLoading}
            feedPreviewLoading={feedPreviewLoading}
          />
        </div>
      </div>

      {/* Footer */}
      <div className={css.footer}>
        <ActionButtons />
      </div>

      {/* Modals */}
      <SignalSettingsConfirmationModal
        isOpen={isModalOpen}
        showConfirmation={isSuccessUpdateSignalSettings}
        onSubmit={handleModalSubmit}
        onCancel={handleModalCancel}
        onConfirmationRedirect={handleConfirmationRedirect}
        resetUpdateSignalSettings={resetUpdateSignalSettings}
      />
      <SignalSettingsDiscardModal
        isOpen={isDiscardModalOpen}
        onCancel={handleDiscardModalCancel}
        onSubmit={handleDiscardModalSubmit}
      />
    </form>
  );
}
