import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "antd5";

import { Radio } from "components/form_components/Inputs";

import css from "./SavedViewForm.module.scss";

type FormValues = {
  viewName: string;
  viewDescription: string;
  alertFrequency: "DAILY" | "WEEKLY" | "NEVER";
};

export function AlertForm({
  defaultValues,
  onSubmit,
  onCancel,
  isLoading,
}: {
  defaultValues?: FormValues;
  isStandardView?: boolean;
  onSubmit: (data: FormValues) => void;
  onCancel: () => void;
  isLoading?: boolean;
}) {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  });

  return (
    <form className={css.form} onSubmit={handleSubmit((d) => onSubmit(d))}>
      {defaultValues && (
        <Radio
          label="Email me about new activity in this view"
          name={"alertFrequency"}
          control={control}
          options={[
            { label: "Daily", value: "DAILY" },
            { label: "Weekly", value: "WEEKLY" },
            { label: "Never", value: "NEVER" },
          ]}
          optionType="default"
        />
      )}
      <div className={css.formActions}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </form>
  );
}
