import React from "react";
import { List, Popover } from "antd5";

import css from "./MultipleEntityLinks.module.scss";

export default function MultipleEntityLinks({
  title,
  links,
}: {
  title: string;
  links: JSX.Element[];
}): JSX.Element | null {
  if (links.length > 2) {
    return (
      <>
        {links[0]}, {links[1]} &amp;{" "}
        <Popover
          trigger="hover"
          overlayClassName={css.cellPopover}
          title={<b>{title}</b>}
          placement="left"
          content={
            <List className={css.cellPopoverList}>
              {links.map((s) => (
                <List.Item>{s}</List.Item>
              ))}
            </List>
          }
        >
          <p className={css.popoverTrigger}>{links.slice(2).length} more</p>
        </Popover>
      </>
    );
  } else if (links.length === 2) {
    return (
      <>
        {links[0]}&nbsp;&amp;&nbsp;{links[1]}
      </>
    );
  } else if (links.length === 1) {
    return links[0];
  } else {
    return null;
  }
}
