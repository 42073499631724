import * as React from "react";
import { useForm } from "react-hook-form";
import { SearchOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd5";
import { OscarSearchModal } from "pages/admin/buyer_edit/OscarFormField";

import { Checkbox, Input } from "components/form_components/Inputs";
import { CandidateItem } from "components/organisation_clean/OrgListPanel";
import { ReferenceOrgDto } from "lib/generated/app-api";
import { useDialogManager } from "lib/providers/DialogManager";
import CompaniesHouseSearchModal from "./CompaniesHouseSearchModal";
import { OrgWithStats, UpdatableAttributes } from "./types";

import css from "./ModifyAnchorPage.module.scss";

type ReferenceOrgType = "oscar" | "companies_house";

type Props = {
  anchorOrg: OrgWithStats;
  onAnchorUpdate: (updates: UpdatableAttributes) => void;
  referenceOrgConnectors: ReferenceOrgType[];
  orgPrimaryRole: "Buyer" | "Supplier";
};

function ModifyAnchorPage({
  anchorOrg,
  onAnchorUpdate,
  referenceOrgConnectors,
  orgPrimaryRole,
}: Props) {
  const dialogManager = useDialogManager();
  const [linkedRefOrgs, setLinkedRefOrgs] = React.useState<
    Record<ReferenceOrgType, ReferenceOrgDto | undefined>
  >({
    oscar: undefined,
    companies_house: undefined,
  });

  const { watch, setValue, control } = useForm({
    mode: "onChange",
    values: {
      oscar_id: anchorOrg.oscar_id,
      is_stotles_verified: anchorOrg.is_stotles_verified,
      companies_house_id: anchorOrg.companies_house_id,
    },
  });

  React.useEffect(() => {
    const { unsubscribe } = watch((values, { name: fieldName }) => {
      if (!fieldName) return;

      onAnchorUpdate({ [fieldName]: values[fieldName] });
    });

    return unsubscribe;
  }, [watch, onAnchorUpdate]);

  const linkedRefOrgsAlreadyConnectedWithOtherOrgs = Object.values(linkedRefOrgs).some(
    (refOrg) => refOrg?.organisation && refOrg.organisation.id !== anchorOrg.guid,
  );

  return (
    <div>
      <div>
        <h1>Step 1b. Modify anchor [Optional]</h1>
        {anchorOrg && <CandidateItem org={anchorOrg} orgPrimaryRole={orgPrimaryRole} />}
      </div>
      <div>
        <Checkbox
          label="Verification"
          fieldLabel="Is Stotles verified organisation"
          control={control}
          name="is_stotles_verified"
        />
        {referenceOrgConnectors.includes("companies_house") && (
          <div>
            <Input label="Companies House ID" control={control} name="companies_house_id" />
            <Button
              onClick={() =>
                dialogManager.openDialog(CompaniesHouseSearchModal, {
                  handleChange: (refOrg) => {
                    setValue("companies_house_id", refOrg?.sourceIdentifier);
                    setLinkedRefOrgs((old) => ({ ...old, companies_house: refOrg }));
                  },
                })
              }
              style={{ marginTop: "4px" }}
            >
              <SearchOutlined /> Search Companies House...
            </Button>
          </div>
        )}
        {referenceOrgConnectors.includes("oscar") && (
          <div>
            <Input label="Oscar ID" control={control} name="oscar_id" />
            <Button
              onClick={() =>
                dialogManager.openDialog(OscarSearchModal, {
                  handleChange: (refOrg) => {
                    setValue("oscar_id", refOrg?.sourceIdentifier);
                    setLinkedRefOrgs((old) => ({ ...old, oscar: refOrg }));
                  },
                })
              }
              style={{ marginTop: "4px" }}
            >
              <SearchOutlined /> Search Oscar...
            </Button>
          </div>
        )}
      </div>
      {linkedRefOrgsAlreadyConnectedWithOtherOrgs && (
        <div className={css.errors}>
          <Alert
            showIcon
            type="error"
            message="The following selections are invalid:"
            description={
              <div>
                <ul>
                  {Object.entries(linkedRefOrgs)
                    .filter(([_, refOrg]) => !!refOrg?.organisation)
                    .map(([refOrgSource, rO]) => {
                      const refOrg = rO as ReferenceOrgDto;

                      return (
                        <li key={refOrgSource}>
                          <strong>{refOrgSource}</strong> id{" "}
                          <strong>{refOrg.sourceIdentifier}</strong> is already linked to{" "}
                          <a href={`/buyers/${refOrg.organisation.id}`}>
                            {refOrg.organisation.name}
                          </a>
                          .
                        </li>
                      );
                    })}
                </ul>
                <div>
                  Please confirm that the existing linkages above are correct, or whether the anchor
                  needs to be merged with any of them.
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}

export default ModifyAnchorPage;
