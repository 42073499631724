import * as React from "react";

// source: https://fontawesome.com/icons/share
// licensed under CC BY 4.0 https://creativecommons.org/licenses/by/4.0/
// modification: outline only (hollow)

/* USAGE:
 *
 * import { Icon } from "antd5";
 * ...
 * <Icon component={MegaphoneIcon} />
 *
 **/

export default function MegaphoneIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 12.7491C18.7875 12.7491 18.6094 12.6768 18.4656 12.5322C18.3219 12.3876 18.25 12.2084 18.25 11.9947C18.25 11.7809 18.3219 11.6032 18.4656 11.4616C18.6094 11.3199 18.7875 11.2491 19 11.2491H21.25C21.4625 11.2491 21.6406 11.3214 21.7844 11.4659C21.9281 11.6105 22 11.7897 22 12.0034C22 12.2172 21.9281 12.3949 21.7844 12.5366C21.6406 12.6782 21.4625 12.7491 21.25 12.7491H19ZM18.9 19.5491L17.075 18.1991C16.9083 18.0657 16.8083 17.9005 16.775 17.7034C16.7417 17.5063 16.7917 17.3215 16.925 17.1491C17.0583 16.9824 17.2235 16.8824 17.4206 16.8491C17.6178 16.8157 17.8025 16.8657 17.975 16.9991L19.8 18.3491C19.9667 18.4824 20.0667 18.6476 20.1 18.8447C20.1333 19.0418 20.0833 19.2266 19.95 19.3991C19.8167 19.5657 19.6515 19.6657 19.4544 19.6991C19.2572 19.7324 19.0725 19.6824 18.9 19.5491ZM18.05 6.97406C17.8775 7.1074 17.6927 7.1574 17.4957 7.12406C17.2985 7.09073 17.1333 6.99073 17 6.82406C16.8667 6.6574 16.8167 6.47406 16.85 6.27406C16.8833 6.07406 16.9833 5.9074 17.15 5.77406L18.9 4.44906C19.0725 4.31573 19.2572 4.26573 19.4544 4.29906C19.6515 4.3324 19.8167 4.4324 19.95 4.59906C20.0833 4.76573 20.1333 4.94906 20.1 5.14906C20.0667 5.34906 19.9667 5.51573 19.8 5.64906L18.05 6.97406ZM5.25 18.9991V14.9991H3.5C3.0875 14.9991 2.73438 14.8522 2.44063 14.5584C2.14688 14.2647 2 13.9116 2 13.4991V10.4991C2 10.0866 2.14688 9.73344 2.44063 9.43969C2.73438 9.14594 3.0875 8.99906 3.5 8.99906H8L11.875 6.67406C12.125 6.52406 12.375 6.52353 12.625 6.67246C12.875 6.8214 13 7.0386 13 7.32406V16.6741C13 16.9595 12.875 17.1767 12.625 17.3257C12.375 17.4746 12.125 17.4741 11.875 17.3241L8 14.9991H6.75V18.9991H5.25ZM14 15.3491V8.64906C14.45 9.04906 14.8125 9.53656 15.0875 10.1116C15.3625 10.6866 15.5 11.3157 15.5 11.9991C15.5 12.6824 15.3625 13.3116 15.0875 13.8866C14.8125 14.4616 14.45 14.9491 14 15.3491ZM11.5 15.3491V8.64906L8.4 10.4991H3.5V13.4991H8.4L11.5 15.3491Z"
        fill="white"
      />
    </svg>
  );
}
