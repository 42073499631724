import { OpportunityDetails } from "../../lib/hooks/api/opportunities/useOpportunity";

const keysToKeep = ["id", "name", "description", "value", "closeDate"];

const filterObject = (obj: Record<string, unknown>, keys: string[]) => {
  return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce<Record<string, unknown>>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export const filterOpportunityDefaultValues = (opportunity: OpportunityDetails) => {
  const filteredPayload = filterObject(opportunity, keysToKeep);
  return {
    ...filteredPayload,
    assignedToId: opportunity.assignedTo?.guid,
    stageId: opportunity.stage.id,
  };
};
