import { Key } from "react";

export type SortState = {
  field?: Key;
  order: "ASC" | "DESC";
  signal_sort?: "ASC" | "DESC";
};

export enum PrimaryTab {
  SUMMARY = "/",
  CONTACTS = "/contacts",
  PROCUREMENT_ACTIVITY = "/activity",
  SUPPLIERS = "/suppliers",
  DOCUMENTS = "/documents",
  DOCUMENTS_NEW = "/document", // TODO: rename this when it goes live
  FRAMEWORKS = "/frameworks",
}

export enum SecondaryTab {
  "AWARDS" = "/activity/awards",
  "OPEN_OPPORTUNITIES" = "/activity/open_opportunities",
  "UPCOMING_EXPIRIES" = "/activity/upcoming_expiries",
  "ALL_RECORDS" = "/activity/all",
  "PARTNERS" = "/suppliers/partners",
  "COMPETITORS" = "/suppliers/competitors",
  "SUPPLIERS" = "/suppliers",
  "CONTACTS" = "/contacts",
  "PROCUREMENT" = "/contacts/procurement",
}

export type HandleNavigation = (location: PrimaryTab | SecondaryTab) => void;

export type PaginationConfig = {
  pageSize: number;
  current: number;
};

export type TabConfigType = { primary: PrimaryTab; secondary: SecondaryTab | null };
