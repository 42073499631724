import React from "react";
import { hot } from "react-hot-loader/root";
import { Button } from "antd5";
import { guestUserCustomHeader } from "pages/_shared/GuestUserHeaderActions";

import { withAppLayout } from "components/app_layout/AppLayout";
import { ExternalLink } from "lib/icons/ExternalLink";
import { white } from "lib/themes/colors";

import css from "./ExpiredGuestPass.module.scss";

import TIME_ICON from "../../../assets/images/icons/time.svg";

function ExpiredGuestPass() {
  return (
    <div className={css.container}>
      <img height={150} width={250} src={TIME_ICON} alt="time-icon" />
      <h1>This guest pass has expired</h1>
      <div className={css.textContainer}>
        <p>
          It appears this guest pass has expired. Reach out to our sales team to discuss regaining
          access or booking a demo.
        </p>
      </div>

      <div className={css.btnContainer}>
        <Button
          type="primary"
          className={css.btn}
          href="https://www.stotles.com/request-a-demo"
          target="_blank"
        >
          <ExternalLink fill={white} className={css.link} />
          Get in touch
        </Button>
      </div>
    </div>
  );
}

export default withAppLayout(hot(ExpiredGuestPass), {
  pageName: "Guest pass Expired pass",
  hideMenuItems: true,
  hideUserItems: true,
  disableLogoNav: true,
  displayCustomAction: guestUserCustomHeader(),
});
