import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  NoticeSignalsQuery,
  QueryNoticeArgs,
  SignalDefinition,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const signalsQuery = graphql(`
  query noticeSignals($noticeId: String!) {
    notice(noticeId: $noticeId) {
      signals {
        id
        name
        category
        colour
      }
    }
  }
`);

/**
 * For an entity, (whether that's a notice, framework or organisation) returns the signal score
 * @param req
 * @param options
 * @returns
 */
export function useNoticeSignals(
  noticeId: QueryNoticeArgs["noticeId"],
  options?: UseQueryOptions<NoticeSignalsQuery, unknown, NoticeSignalsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["noticeSignals", noticeId],
    signalsQuery,
    [{ noticeId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  const allSignals: SignalDefinition[] = data?.notice.signals ?? [];

  return {
    data: allSignals,
    ...rest,
  };
}
