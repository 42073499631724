import * as React from "react";
import classnames from "classnames";

import css from "lib/core_components/StyledListWrapper.module.scss";

type StyledListWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

function StyledListWrapper({ children, className }: StyledListWrapperProps): JSX.Element {
  return <div className={classnames(className, css.styledListWrapper)}>{children}</div>;
}

export default StyledListWrapper;
