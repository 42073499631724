import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  DocumentSignalsQuery,
  QueryDocumentArgs,
  SignalDefinition,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const signalsQuery = graphql(`
  query documentSignals($documentId: String!) {
    document(documentId: $documentId) {
      signals {
        id
        name
        category
        colour
      }
    }
  }
`);

/**
 * Returns the matching signals for a given document
 * @param req
 * @param options
 * @returns
 */
export function useDocumentSignals(
  documentId: QueryDocumentArgs["documentId"],
  options?: UseQueryOptions<DocumentSignalsQuery, unknown, DocumentSignalsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["documentSignals", documentId],
    signalsQuery,
    [{ documentId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  const allSignals: SignalDefinition[] = data?.document.signals ?? [];

  return {
    data: allSignals,
    ...rest,
  };
}
