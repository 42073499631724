import React, { useMemo } from "react";

import ProcurementTimeline, {
  TimelineObj,
  TimelineStage,
} from "lib/core_components/ProcurementTimeline";
import { FrameworkTimelineStage } from "lib/generated/app-service-gql/graphql";
import { Stages } from "lib/types/graphQLEnums";

import css from "./FrameworkTimeline.module.scss";

/**
 * Converts framework stages to one of 4 timeline stages, the timeline stages that these will eventually resolve to in
 * the ProcurementTimeline component may change depending on how the date periods relate to the current date
 * (for example if the end date of the award stage is in the past, the framework is expired)
 * @param stage
 * @returns
 */
function frameworkStageToTimelineStage(stage?: string | null): TimelineStage {
  switch (stage) {
    case Stages.Stale:
    case Stages.Upcoming:
      return TimelineStage.PRE_TENDER;
    case Stages.Tendering:
    case Stages.Closed:
      return TimelineStage.TENDER;
    case Stages.Live:
    case Stages.Awarded:
      return TimelineStage.AWARD;
    case Stages.Expired:
      return TimelineStage.EXPIRED;
    case Stages.Cancelled:
      return TimelineStage.CANCELLED;
    default:
      return TimelineStage.UNKNOWN;
  }
}

/**
 * Organises the timelineStages returned from the graphQL API into a common format which the {@link ProcurementTimeline} component
 * can understand
 * @param timelineStages
 * @returns
 */
function serializeTimelineStages(timelineStages: FrameworkTimelineStage[]): TimelineObj[] {
  return (
    timelineStages?.map((timelineStage) => {
      const { stage, notice, externalSourceUrl, startDate, endDate } = timelineStage;
      return {
        title: notice?.title,
        stage: frameworkStageToTimelineStage(stage),
        stotlesUrl: notice?.stotlesUrl,
        externalSourceUrl,
        publishDate: notice?.publishDate ? new Date(notice.publishDate) : undefined,
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
      };
    }) ?? []
  );
}

type Props = {
  timeline?: FrameworkTimelineStage[] | null;
  extensionAvailable?: boolean | null;
  stage: Stages;
  isLoading: boolean;
};
export default function FrameworkTimeline({
  timeline,
  extensionAvailable,
  stage,
  isLoading,
}: Props): JSX.Element {
  const currentStage = frameworkStageToTimelineStage(stage);

  const timelineStages = useMemo(() => serializeTimelineStages(timeline ?? []), [timeline]);
  return (
    <div className={css.frameworkTimeline} aria-label="Framework timeline">
      <h2 className={css.subtitle}>Timeline</h2>
      <ProcurementTimeline
        currentStage={currentStage}
        isLoading={isLoading}
        timelineStages={timelineStages}
        extensionAvailable={extensionAvailable ?? false}
      />
    </div>
  );
}
