import React from "react";
import { useForm } from "react-hook-form";
import { App, Button, Input, Modal } from "antd5";

import { Checkbox, TextArea } from "components/form_components/Inputs";
import { useProvideFeedback } from "lib/hooks/api/question/useProvideFeedback";

import css from "./AiFeedbackModal.module.scss";

type AriFeedbackModalProps = {
  visible: boolean;
  onClose: (feedback?: string, untrue?: boolean, unhelpful?: boolean) => void;
  onSubmit: () => void;
  positiveFeedback: boolean;
  questionId: string;
  question: string;
  answer: string;
  orgName: string;
};

type FeedbackFormFields = {
  feedback?: string;
  untrue?: boolean;
  unhelpful?: boolean;
};

export default function AiFeedbackModal({
  visible,
  onClose,
  onSubmit,
  positiveFeedback,
  questionId,
  question,
  answer,
  orgName,
}: AriFeedbackModalProps) {
  const { control, handleSubmit, getValues } = useForm<FeedbackFormFields>({
    mode: "onSubmit",
    defaultValues: {
      feedback: "",
      untrue: false,
      unhelpful: false,
    },
  });
  const { mutate: provideFeedback, isLoading } = useProvideFeedback({
    onSuccess: () => {
      void message.success("Thank you for your feedback!");
      onSubmit();
    },
  });

  const { message } = App.useApp();

  const negativeFormFields = () => {
    return (
      <>
        <div className={css.checkboxContainer}>
          <Checkbox name={"untrue"} fieldLabel={"This isn't true"} label={""} control={control} />
        </div>
        <div className={css.checkboxContainer}>
          <Checkbox
            name={"unhelpful"}
            fieldLabel={"This isn't helpful"}
            label={""}
            control={control}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      title={"Tell us more..."}
      open={visible}
      onCancel={() => {
        const { feedback, untrue, unhelpful } = getValues();
        onClose(feedback, untrue, unhelpful);
      }}
      footer={false}
    >
      <form
        onSubmit={handleSubmit(async (formOutput: FeedbackFormFields) => {
          provideFeedback({
            question: question,
            answer: answer,
            orgName: orgName,
            isTrue: formOutput.untrue,
            isHelpful: formOutput.unhelpful,
            args: {
              isPositive: positiveFeedback,
              questionId: questionId,
              userDetailedFeedback: formOutput.feedback,
            },
          });
        })}
      >
        <TextArea
          name={"feedback"}
          label={`What did${!positiveFeedback ? "n't" : ""} you like about this answer?`}
          control={control}
          className={css.feedbackInput}
        />
        {!positiveFeedback && negativeFormFields()}
        <div className={css.footer}>
          <Button onClick={() => onClose()} disabled={isLoading}>
            Cancel
          </Button>
          <Input
            className={css.okButton}
            type="submit"
            value="Submit feedback"
            disabled={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
}
