export enum PrimaryTab {
  NOTICES = "/",
  BUYERS = "/buyers",
}

export enum SecondaryTab {
  "ALL_NOTICES" = "/",
  "AWARDS" = "/awards",
  "UPCOMING_EXPIRIES" = "/upcoming_expiries",
  "ALL_BUYERS" = "/buyers",
  "SAVED_BUYERS" = "/buyers/saved_buyers",
}

export type TabConfigType = { primary: PrimaryTab; secondary: SecondaryTab | null };

export type SupplierRelationshipFilter = {
  keywords: string[];
  textSearch?: string;
};

export type HandleNavigation = (location: PrimaryTab | SecondaryTab) => void;

export type PaginationConfig = {
  pageSize: number;
  current: number;
};

export enum ProfileType {
  PARTNER = "partner",
  COMPETITOR = "competitor",
}

// Only the secondary tabs that belong to the notices primary tab
export type NoticeTabsTypes =
  | SecondaryTab.ALL_NOTICES
  | SecondaryTab.AWARDS
  | SecondaryTab.UPCOMING_EXPIRIES;

// Only the secondary tabs that belong to the buyer primary tab
export type BuyerTabTypes = SecondaryTab.ALL_BUYERS | SecondaryTab.SAVED_BUYERS;
