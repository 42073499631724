import * as React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsPage } from "components/app_layout/DetailsLayout";
import { BuyerDetails } from "lib/types/models";
import BuyerRedirectMessage from "../../components/buyer_details/BuyerRedirectMessage";

import css from "./BuyerRedirectPage.module.scss";

type Props = {
  buyer: BuyerDetails;
  resolvedBuyers: BuyerDetails[];
  represented?: { guid: string };
};

function BuyerRedirectPage(props: Props) {
  return (
    <DetailsPage className={css.pageContainer}>
      <BuyerRedirectMessage {...props} />
    </DetailsPage>
  );
}

function AdminBarComp({ represented }: Props) {
  return (
    <a href={`/admin/buyer/${represented?.guid}`} target="_blank">
      Edit in AP
    </a>
  );
}

export default hot(
  withAppLayout(BuyerRedirectPage, (props) => ({
    pageName: "Buyer Profile",
    pageViewProps: { "Buyer name": props.buyer.name, "Buyer ID": props.buyer.id },
    trackingData: { "Buyer name": props.buyer.name },
    AdminBarComp,
  })),
);
