import { SetStateAction, useEffect, useMemo, useReducer } from "react";
import produce from "immer";
import { isEmpty } from "lodash";
import isEqual from "lodash.isequal";

import { SignalDto } from "lib/generated/app-api";
import { SpendDataFilters as Filters } from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useBuyers } from "lib/hooks/api/buyer/useBuyers";
import { AllBuyerLists, useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { useURLState } from "lib/hooks/useURLState";
import { useTracking } from "lib/tracking";
import { BuyerSummary } from "lib/types/models";
import { GroupByKey } from "../config";
import {
  createFilterChangedEvents,
  createGroupByChangedEvent,
  createViewSelectedEvent,
} from "../tracking";

function getAllFromSignals(category: "Partners" | "Competitors", signals?: SignalDto[]) {
  const categorySignals = signals?.filter((signal) => signal.category === category) || [];
  return categorySignals.map((signal) => signal.name);
}

export type SpendDataFilters = {
  buyerLists?: string[];
  buyers?: string[];
  supplier?: string;
  supplierGuids?: string[];
  competitors?: string[] | "ALL";
  partners?: string[] | "ALL";
  dateRange?: { from?: string; to?: string; relativeFrom?: string; relativeTo?: string };
  buyerCategories?: string[];
  // qs doesn't support parsing boolean types
  // so if we store `true` we will get back `"true"`
  // it's easy for things to break with this type mismatch
  // so for now we need to change the type to string explicitely to avoid errors
  includeUncategorisedBuyers?: string;
};

type PageState = {
  filters: SpendDataFilters;
  groupBy?: GroupByKey;
  viewIndex?: number;
  dateUnit?: "month" | "quarter" | "year";
};

type SetFilterAction = {
  type: "SET_FILTER";
  payload: SetStateAction<SpendDataFilters>;
};
type SetGroupByAction = { type: "SET_GROUP_BY"; payload: GroupByKey };
type UrlStateUpdateAction = { type: "URL_STATE_UPDATED"; payload: PageState };
type ClearAllAction = { type: "CLEAR_ALL" };
type ClearFiltersAction = { type: "CLEAR_FILTERS" };
type ChangeViewAction = {
  type: "CHANGE_VIEW";
  payload: { filters: SpendDataFilters; groupBy: GroupByKey; viewIndex: number; viewTitle: string };
};
type SetDateUnitAction = {
  type: "SET_DATE_UNIT";
  payload: "month" | "quarter" | "year";
};

type Actions =
  | SetFilterAction
  | ChangeViewAction
  | SetGroupByAction
  | UrlStateUpdateAction
  | ClearAllAction
  | ClearFiltersAction
  | SetDateUnitAction;

function getSpendDataFiltersForApi(
  values: SpendDataFilters,
  buyerLists?: AllBuyerLists,
  signals?: SignalDto[],
  buyerSearchResult?: BuyerSummary[],
) {
  const filter: Filters = {};

  if (values.buyers) {
    filter.buyerIds = values.buyers;
  }

  if (values.buyerLists && buyerLists) {
    let buyerIds: string[] = filter.buyerIds || [];
    for (const id of values.buyerLists) {
      const list = buyerLists.all.find((list) => list.id === id);
      if (list) {
        buyerIds = [...buyerIds, ...list.entries.map((l) => l.id)];
      }
    }

    filter.buyerIds = [...new Set(buyerIds)];
  }

  if (values.buyerCategories && buyerSearchResult) {
    let buyerIds = buyerSearchResult.map((buyer) => buyer.guid);
    if (filter.buyerIds) {
      buyerIds = buyerIds.concat(filter.buyerIds);
    }
    filter.buyerIds = [...new Set(buyerIds)];
  }

  filter.supplierNames = [];

  if (values.supplier) {
    const match = values.supplier.match(/^"(.*)"$/);
    if (match) {
      filter.supplierNamesExact = [match[1]];
    } else {
      filter.supplierNames.push(values.supplier);
    }
  }

  if (values.supplierGuids) {
    filter.supplierGuids = values.supplierGuids;
  }

  if (values.competitors) {
    if (values.competitors === "ALL") {
      const competitors = signals?.filter((signal) => signal.category === "Competitors") || [];
      values.competitors = competitors.map((signal) => signal.name);
    }
    filter.supplierNames = filter.supplierNames.concat(values.competitors);
  }

  if (values.partners) {
    if (values.partners === "ALL") {
      const partners = signals?.filter((signal) => signal.category === "Partners") || [];
      values.partners = partners.map((signal) => signal.name);
    }
    filter.supplierNames = filter.supplierNames.concat(values.partners);
  }

  if (values.dateRange) {
    filter.dateFrom = values.dateRange.from;
    filter.dateTo = values.dateRange.to;
  }

  return filter;
}

function isPageState(val: unknown): val is PageState {
  if (val && typeof val === "object") {
    return true;
  }
  return false;
}

type State = {
  filters: SpendDataFilters;
  groupBy?: GroupByKey;
  viewIndex?: number;
  dateUnit?: "month" | "quarter" | "year";
};

export function useSpendPageState() {
  const [urlState = { filters: {} }, setURLState] = useURLState<State>(
    "state",
    { filters: {} },
    (val) => {
      if (isPageState(val)) {
        return {
          ...val,
          viewIndex: val.viewIndex ? Number(val.viewIndex) : undefined,
          // when filters are cleared this object can be wiped from the url state
          filters: val.filters || {},
        };
      }
      return { filters: {} };
    },
  );
  const { data: buyerLists } = useBuyerLists();
  const { data: signalSettings } = useSignalSettings();
  const { data: buyerSearch } = useBuyers(
    {
      category_id: urlState.filters?.buyerCategories,
      category_nulls: urlState.filters?.includeUncategorisedBuyers === "true",
    },
    { enabled: !!urlState.filters?.buyerCategories },
  );
  const { logEvents } = useTracking();
  const reducer = (state: PageState, action: Actions): PageState => {
    let groupByUpdate: GroupByKey | undefined = undefined;
    switch (action.type) {
      case "SET_FILTER":
        if (typeof action.payload === "function") {
          action.payload = action.payload(state.filters);
        }
        // Set a default group by if we have filters but not a group by or view
        if (!isEmpty(action.payload)) {
          if (state.viewIndex === undefined && state.groupBy === undefined) {
            groupByUpdate = "buyers";
          }
        }
        // set a groupby if we don't have any filters for that groupBy
        if (
          state.groupBy === "competitors" &&
          (!action.payload.competitors || action.payload.competitors?.length < 1)
        ) {
          groupByUpdate = "buyers";
        }
        if (
          state.groupBy === "partners" &&
          (!action.payload.partners || action.payload.partners?.length < 1)
        ) {
          groupByUpdate = "buyers";
        }
        logEvents(...createFilterChangedEvents(state.filters, action.payload));
        setURLState({
          ...state,
          filters: action.payload,
          groupBy: groupByUpdate || state.groupBy,
        });
        return { ...state, filters: action.payload };
      case "CHANGE_VIEW":
        if (action.payload.filters.competitors === "ALL") {
          action.payload.filters.competitors = getAllFromSignals(
            "Competitors",
            signalSettings?.signals,
          );
        }
        if (action.payload.filters.partners === "ALL") {
          action.payload.filters.partners = getAllFromSignals("Partners", signalSettings?.signals);
        }
        logEvents(createViewSelectedEvent(action.payload.viewTitle));
        setURLState({ ...state, ...action.payload });
        return { ...state, ...action.payload };
      case "SET_GROUP_BY":
        logEvents(createGroupByChangedEvent(action.payload));
        setURLState({ ...state, groupBy: action.payload });
        return { ...state, groupBy: action.payload };
      case "URL_STATE_UPDATED":
        return action.payload;
      case "CLEAR_FILTERS":
        setURLState({ ...state, filters: {} });
        return { ...state, filters: {} };
      case "CLEAR_ALL":
        setURLState({ filters: {} });
        return { filters: {} };
      case "SET_DATE_UNIT": {
        setURLState(
          produce((draft) => {
            draft.dateUnit = action.payload;
          }),
        );
        return produce(state, (draft) => {
          draft.dateUnit = action.payload;
        });
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, { filters: {} });

  useEffect(() => {
    if (!isEqual(state, urlState)) {
      // check for filters as if they are empty they can be stripped from the url state object
      if (urlState.filters) {
        dispatch({
          type: "URL_STATE_UPDATED",
          payload: { ...urlState, filters: urlState.filters },
        });
      }
    }
  }, [urlState, setURLState, state]);

  const apiFilters = useMemo(
    () =>
      getSpendDataFiltersForApi(
        urlState.filters,
        buyerLists,
        signalSettings?.signals,
        buyerSearch?.results,
      ),
    [buyerLists, buyerSearch?.results, urlState.filters, signalSettings],
  );

  return {
    // merege the 2 states to cover the case where the url state is missing filters when they're empty
    state: { ...state, ...urlState },
    dispatch,
    apiFilters,
  };
}
