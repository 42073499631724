import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ReportDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useCompanyReports(
  companyGuid: string,
  options?: UseQueryOptions<ReportDto[], unknown, ReportDto[], string[]>,
) {
  const api = useOpenApi();
  return useQuery(
    ["reports", companyGuid],
    async () => {
      const response = await api.getReports({ companyGuid });
      return response.reports;
    },
    options,
  );
}
