import React, { useEffect, useRef } from "react";
import { DeleteOutlined, FileAddTwoTone } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";
import classNames from "classnames";

import css from "./FileInput.module.scss";

type Props = {
  onChange: (files: File[]) => void;
  value: File[];
  isError?: boolean;
  errorMessage?: string;
  acceptedFileTypes?: string;
  multiple?: boolean;
  iconClassName?: string;
};

// type Props<T extends FieldValues> = UseControllerProps<T> & InputProps<T>;

function handleDragover(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export function FileInput({
  onChange,
  value: files,
  isError,
  errorMessage,
  acceptedFileTypes,
  multiple = true,
  iconClassName,
}: Props) {
  const dropContainer = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (event.dataTransfer?.files) {
        const newFiles = Array.from(event.dataTransfer.files);
        const updatedFiles = multiple ? [...files, ...newFiles] : [newFiles[0]];
        onChange(updatedFiles);
      }
    }
    const container = dropContainer.current;

    if (container) {
      container.addEventListener("dragover", handleDragover);
      container.addEventListener("drop", handleDrop);
    }

    return () => {
      container?.removeEventListener("dragover", handleDragover);
      container?.removeEventListener("drop", handleDrop);
    };
  }, [files, onChange, multiple]);

  return (
    <div
      ref={dropContainer}
      onClick={() => fileInput.current && fileInput.current.click()}
      className={isError ? css.containerError : css.container}
    >
      <input
        type="file"
        multiple={multiple}
        onChange={(e) => {
          if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            const updatedFiles = multiple ? [...files, ...selectedFiles] : [selectedFiles[0]];
            onChange(updatedFiles);
          }
        }}
        accept={acceptedFileTypes}
        style={{ display: "none" }}
        ref={fileInput}
      />
      {errorMessage && isError ? (
        <p className={css.errorText}>{errorMessage}</p>
      ) : (
        <p className={css.instructionText}>Click or drag file to this area to upload</p>
      )}
      {!files.length && <FileAddTwoTone className={classNames(css.icon, iconClassName)} />}
      {files.map((file, index) => (
        <div key={file.name + index} className={css.file}>
          {file.name}{" "}
          <Tooltip title="Delete">
            <Button
              type="text"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(evt) => {
                evt.stopPropagation();
                const updated = [...files];
                updated.splice(index, 1);
                onChange(updated);
              }}
            />
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default FileInput;
