import React from "react";
import { Button } from "antd5";

import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { ExternalLink } from "lib/icons/ExternalLink";

import css from "./GuestPassBanner.module.scss";

import ticket from "../../../../assets/images/icons/ticket.svg";

export function GuestPassBanner() {
  const { mutate } = usePqlActioned();

  return (
    <div className={css.guestPassHeader}>
      <div className={css.guestPassInfo}>
        <img src={ticket} height={20} alt="ticket" />
        Guest pass
      </div>
      <div className={css.info}>
        This guest pass offers a sneak peak into only part of the value our paid packages can
        provide. Want to see more? Get in touch with our team for a demo.
      </div>
      <div className={css.btnContainer}>
        <Button
          className={css.btn}
          href="https://www.stotles.com/request-a-demo"
          target="_blank"
          onClick={() => {
            mutate({
              actionType: "Book a demo",
              dataType: "GUEST_ACCESS_UPGRADE",
              pageUrl: window.location.href,
            });
          }}
        >
          <ExternalLink className={css.link} />
          Book a demo
        </Button>
      </div>
    </div>
  );
}
