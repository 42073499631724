/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameworkSearchQueryFilters } from './FrameworkSearchQueryFilters';
import {
    FrameworkSearchQueryFiltersFromJSON,
    FrameworkSearchQueryFiltersFromJSONTyped,
    FrameworkSearchQueryFiltersToJSON,
} from './FrameworkSearchQueryFilters';
import type { FrameworkSearchQueryScoreFilter } from './FrameworkSearchQueryScoreFilter';
import {
    FrameworkSearchQueryScoreFilterFromJSON,
    FrameworkSearchQueryScoreFilterFromJSONTyped,
    FrameworkSearchQueryScoreFilterToJSON,
} from './FrameworkSearchQueryScoreFilter';

/**
 * 
 * @export
 * @interface FrameworkSearchQuery
 */
export interface FrameworkSearchQuery {
    /**
     * The page number to return, starting from 0
     * @type {number}
     * @memberof FrameworkSearchQuery
     */
    page: number;
    /**
     * The number of results to return per page
     * @type {number}
     * @memberof FrameworkSearchQuery
     */
    size: number;
    /**
     * The field to sort the results by
     * @type {string}
     * @memberof FrameworkSearchQuery
     */
    sortBy: FrameworkSearchQuerySortByEnum;
    /**
     * The order to sort the results by
     * @type {string}
     * @memberof FrameworkSearchQuery
     */
    sortOrder: FrameworkSearchQuerySortOrderEnum;
    /**
     * The minimum score to return. In the range of 10 - 30 at the moment
     * @type {number}
     * @memberof FrameworkSearchQuery
     */
    minScore?: number;
    /**
     * The language codes to be used for the search. 
     *         Internally we currently support en, de, es, fr, and nl. 
     *         Other languages will fall back to the default analyzer.
     * @type {Array<string>}
     * @memberof FrameworkSearchQuery
     */
    languages: Array<string>;
    /**
     * Query identifier, used for logging & debugging
     * @type {string}
     * @memberof FrameworkSearchQuery
     */
    id: string;
    /**
     * 
     * @type {FrameworkSearchQueryScoreFilter}
     * @memberof FrameworkSearchQuery
     */
    scoreFilter?: FrameworkSearchQueryScoreFilter;
    /**
     * 
     * @type {FrameworkSearchQueryFilters}
     * @memberof FrameworkSearchQuery
     */
    filters: FrameworkSearchQueryFilters;
}


/**
 * @export
 */
export const FrameworkSearchQuerySortByEnum = {
    Score: 'score',
    Title: 'title',
    StartDate: 'startDate',
    Stage: 'stage',
    AwardType: 'awardType',
    ValueAmount: 'valueAmount',
    CloseDate: 'closeDate',
    ProcedureType: 'procedureType',
    SupplierCount: 'supplierCount',
    CallOffCount: 'callOffCount'
} as const;
export type FrameworkSearchQuerySortByEnum = typeof FrameworkSearchQuerySortByEnum[keyof typeof FrameworkSearchQuerySortByEnum];

/**
 * @export
 */
export const FrameworkSearchQuerySortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type FrameworkSearchQuerySortOrderEnum = typeof FrameworkSearchQuerySortOrderEnum[keyof typeof FrameworkSearchQuerySortOrderEnum];


/**
 * Check if a given object implements the FrameworkSearchQuery interface.
 */
export function instanceOfFrameworkSearchQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sortBy" in value;
    isInstance = isInstance && "sortOrder" in value;
    isInstance = isInstance && "languages" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "filters" in value;

    return isInstance;
}

export function FrameworkSearchQueryFromJSON(json: any): FrameworkSearchQuery {
    return FrameworkSearchQueryFromJSONTyped(json, false);
}

export function FrameworkSearchQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSearchQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'sortBy': json['sortBy'],
        'sortOrder': json['sortOrder'],
        'minScore': !exists(json, 'minScore') ? undefined : json['minScore'],
        'languages': json['languages'],
        'id': json['id'],
        'scoreFilter': !exists(json, 'scoreFilter') ? undefined : FrameworkSearchQueryScoreFilterFromJSON(json['scoreFilter']),
        'filters': FrameworkSearchQueryFiltersFromJSON(json['filters']),
    };
}

export function FrameworkSearchQueryToJSON(value?: FrameworkSearchQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'sortBy': value.sortBy,
        'sortOrder': value.sortOrder,
        'minScore': value.minScore,
        'languages': value.languages,
        'id': value.id,
        'scoreFilter': FrameworkSearchQueryScoreFilterToJSON(value.scoreFilter),
        'filters': FrameworkSearchQueryFiltersToJSON(value.filters),
    };
}

