import React, { Key } from "react";
import { Empty, Skeleton, Tree } from "antd5";
import { CheckInfo } from "rc-tree/lib/Tree";

import Signal from "lib/icons/Signal";
import { onlyShowCpvParents } from "./cpv_code_utils";
import { CpvTreeNode } from "./useCpvTree";

import css from "./CpvTreeSelect.module.scss";

type Props = {
  value: string[];
  signalCodeIds?: string[];
  onChange: (newValue: string[]) => void;
  cpvTree: CpvTreeNode[];
  isLoading: boolean;
  expand?: boolean;
};

function CpvTreeSelect({
  cpvTree,
  value,
  isLoading,
  onChange,
  signalCodeIds = [],
  expand = false,
}: Props) {
  const onCheck = (
    _checkedKeysValue: Key[] | { checked: Key[]; halfChecked: Key[] },
    info: CheckInfo<CpvTreeNode>,
  ) => {
    const newSelection: string[] = onlyShowCpvParents(info.checkedNodes.map((n) => n.value));
    onChange(newSelection);
  };

  if (isLoading) {
    return <Skeleton active style={{ paddingTop: "8px" }} />;
  }

  if (cpvTree.length === 0) {
    return <Empty className={css.emptyState} />;
  }

  return (
    <div className={css.treeContainer}>
      <Tree
        checkedKeys={value}
        treeData={cpvTree}
        onCheck={onCheck}
        defaultExpandedKeys={expand ? cpvTree.map((node) => node.value) : []}
        expandAction="click"
        checkable
        className={css.optionsContainer}
        style={{ width: "100%" }}
        titleRender={(node: CpvTreeNode) => (
          <div className={css.optionLabel}>
            <span>{node.label}</span>
            {signalCodeIds.includes(node.value) && <Signal className={css.signalIcon} />}
          </div>
        )}
      />
    </div>
  );
}

export default CpvTreeSelect;
