import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Progress } from "antd5";

import { Input } from "components/form_components/Inputs";
import { assertCurrentUser } from "lib/currentUser";
import { useUpdateTeam } from "lib/hooks/api/teams/useUpdateTeam";
import * as tracking from "lib/tracking";
import { fieldInteractionEvent } from "./tracking";

import css from "./CreateTeamPage.module.scss";

type TeamPageProps = {
  onSubmit: () => void;
  companyName?: string | null;
  onBack?: () => void;
};

function CreateTeamPage({ onSubmit, companyName, onBack }: TeamPageProps) {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<{ teamName: string; isPublicTeam: boolean }>({
    defaultValues: { isPublicTeam: true },
  });
  const { mutate: updateTeam } = useUpdateTeam({
    onSuccess: () => {
      onSubmit();
    },
  });

  const previousFormValue = useRef<Partial<{ teamName: string; isPublicTeam: boolean }>>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        // if the field was empty and now it's not
        if (
          (!previousFormValue.current || !previousFormValue.current[name]) &&
          value[name] !== undefined
        ) {
          fieldInteractionEvent(value[name]?.toString() || "", name);
        }
      }
      previousFormValue.current = value;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const currentUser = assertCurrentUser();
  return (
    <div>
      <div className={css.progressBar}>
        <Progress percent={75} showInfo={false} />
      </div>
      <div className={css.header}>
        <h1 className={css.title}>Create a team for {companyName}</h1>
        <p className={css.subTitle}>
          Collaborate with your team members in one platform with Stotles. Give your team a name
          that's clear to understand for people joining for the first time
        </p>
      </div>

      <form
        onSubmit={handleSubmit((value) => {
          tracking.logEvent(tracking.EventNames.newTeamCreated, {
            "Page source": "Onboarding - Team - New Team",
            "Domain joining": value.isPublicTeam ? "On" : "Off",
            "Team name": value.teamName,
            "Context source": "On page",
          });
          updateTeam({
            input: {
              id: currentUser.team.id,
              name: value.teamName,
              isPrivate: !value.isPublicTeam,
            },
          });
        })}
      >
        <Input
          control={control}
          name={"teamName"}
          label={"Team name"}
          className={css.teamNameInput}
          rules={{ required: true }}
        />
        <Button type="primary" htmlType="submit" disabled={!isValid}>
          Create team and continue
        </Button>
      </form>
      {onBack && (
        <Button
          type="text"
          onClick={() => {
            tracking.logEvent(tracking.EventNames.joinExistingTeamClicked, {
              "Page source": "Onboarding - Team - New Team",
              "Context source": "On page",
            });
            onBack();
          }}
          className={css.backButton}
        >
          Join an existing team instead
        </Button>
      )}
    </div>
  );
}

export default CreateTeamPage;
