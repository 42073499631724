import * as React from "react";
import { Divider } from "antd5";
import classnames from "classnames";

import css from "./Property.module.scss";

type Props = {
  title: React.ReactNode;
  value: React.ReactNode;
  className?: string;
  titleClassName?: string;
  valueClassName?: string;
  borderBottom?: boolean;
};

/**
 * Based on Ant's Statistic component, but we make no assumptions or requirements about the type of
 * the property being displayed; Ant are strict about only allowing number and string values in
 * their Statistic component.
 */
export default function Property({ title, value, className }: Props): JSX.Element | null {
  return value ? (
    <div className={classnames(className, css.property)}>
      <small className={css.title}>{title}</small>
      <div>{value}</div>
    </div>
  ) : null;
}

/**
 * Displays the title and value of a property, if it exists.
 * How it is displayed will depend on the parent container
 * @param param0
 * @returns
 */
export function PropertyInline({
  title,
  value,
  className,
  titleClassName,
  valueClassName,
  borderBottom,
}: Props): JSX.Element {
  return (
    <>
      <div className={classnames(className, css.propertyInline)}>
        <span className={classnames(titleClassName, css.title)}>{title}</span>
        <span className={valueClassName}>{value || "--"}</span>
      </div>
      {borderBottom && <Divider className={css.divider} />}
    </>
  );
}
