import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "wouter";

import OpportunityDetailSidebar from "../../components/opportunities/opportunity_detail_sidebar/OpportunityDetailSidebar";
import CentredSpinner from "../../lib/core_components/CentredSpinner";
import { useOpportunity } from "../../lib/hooks/api/opportunities/useOpportunity";
import { layoutRight } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../styles/utility-components";

type OpportunityPageProps = {
  id: string;
};

export default function OpportunityPage({ id }: OpportunityPageProps) {
  const theme = useTheme();

  const { data: opportunity, isLoading: opportunityLoading } = useOpportunity(id);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (opportunityLoading) {
    return <CentredSpinner />;
  }

  return (
    <Flex flexDirection="column" flexGrow={1} width={480}>
      <Header>
        <Text color={theme.colors.sysTextSecondary}>
          <Link href="/">Opportunities</Link> / {opportunity?.name}
        </Text>
        <HeaderButton onClick={toggleSidebar} aria-label="Toggle sidebar">
          <UIcon color={theme.colors.sysTextSecondary} svg={layoutRight} />
        </HeaderButton>
      </Header>
      <Flex width="100%" height="100vh">
        <Content>
          <Text h2 color={theme.colors.sysPrimaryDefault}>
            {opportunity?.name}
          </Text>
          <Text>{opportunity?.description}</Text>
        </Content>
        {isSidebarOpen && opportunity && <OpportunityDetailSidebar opportunity={opportunity} />}
      </Flex>
    </Flex>
  );
}

const Header = styled(Flex)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  gap: 24,

  padding: "20px 24px",

  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const HeaderButton = styled.button({
  all: "unset",
  cursor: "pointer",
});

const Content = styled(Flex)({
  flexDirection: "column",
  gap: 16,

  width: "100%",

  padding: 48,
});
