import React from "react";
import { useForm } from "react-hook-form";
import { App, Button, Modal } from "antd5";

import { useAdminCreateBuyerList } from "lib/hooks/api/buyer_lists/admin";
import {
  BuyerContextSource,
  useCreateBuyerList,
} from "lib/hooks/api/buyer_lists/useCreateBuyerList";
import { Input, Radio } from "../form_components/Inputs";

import css from "./CreateBuyerListModal.module.scss";

export type UserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  contextSource: BuyerContextSource;
};

export function UserCreateBuyerListModal(props: UserModalProps) {
  const { message } = App.useApp();
  const { isLoading, mutate } = useCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
    contextSource: props.contextSource,
  });

  return <CreateBuyerListModal {...props} onSubmit={mutate} isLoading={isLoading} />;
}

type AdminProps = {
  isOpen: boolean;
  onClose: () => void;
  userGuid: string;
};

export function AdminCreateBuyerListModal(props: AdminProps) {
  const { message } = App.useApp();
  const { isLoading, mutate } = useAdminCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
  });

  return (
    <CreateBuyerListModal
      {...props}
      buyerGuids={[]}
      onSubmit={(values) => mutate({ ...values, userGuid: props.userGuid })}
      isLoading={isLoading}
    />
  );
}

type FormValues = {
  name: string;
  sharingType: "PRIVATE" | "TEAM";
  buyerGuids?: string[];
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  onSubmit: (values: FormValues) => void;
  isLoading: boolean;
};

function CreateBuyerListModal({ isOpen, onClose, buyerGuids, onSubmit, isLoading }: ModalProps) {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { sharingType: "PRIVATE", buyerGuids, name: "" },
  });

  return (
    <Modal title="Create new buyer list" open={isOpen} onCancel={onClose} footer={null}>
      <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
        <Input
          label="List name"
          control={control}
          name="name"
          rules={{ required: "List name is required" }}
        />
        <Radio
          name="sharingType"
          control={control}
          label="Shared with"
          optionType="default"
          options={[
            { label: "Private", value: "PRIVATE" },
            { label: "Team", value: "TEAM" },
          ]}
        />
        <div className={css.footer}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button htmlType="submit" loading={isLoading} type="primary">
            {isLoading ? "Creating..." : "Create list"}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
