import * as React from "react";
import { IdcardOutlined, RightOutlined } from "@ant-design/icons";

import { BuyerDetails } from "lib/types/models";
import { TextLink } from "../actions/Links";

import css from "./BuyerLinkWithLogo.module.scss";

export type Props = {
  buyer: BuyerDetails;
};

function BuyerLinkWithLogo({ buyer }: Props): JSX.Element {
  const { metadata } = buyer;
  return (
    <div className={css.container}>
      <div className={css.logo}>
        {metadata?.logo_url ? <img src={metadata.logo_url} alt={buyer.name} /> : <IdcardOutlined />}
      </div>
      <div className={css.buyerName} title={buyer.name}>
        {buyer.name}
      </div>
      <TextLink className={css.profileLink} to={`/buyers/${buyer.id}`}>
        Visit profile <RightOutlined />
      </TextLink>
    </div>
  );
}

export default BuyerLinkWithLogo;
