import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd5";
import classNames from "classnames";

import FileViewer from "../../lib/core_components/FileViewer";

import css from "./PdfViewerContainer.module.scss";

import FOLDER from "../../../assets/images/folder.svg";

export function PdfViewerContainer({
  documentUrl,
  downloadUrl,
  isLoading,
  previewSupported,
  hasKeywordsFilter,
  containerClassName,
  inDrawer = false,
}: {
  documentUrl: string;
  downloadUrl: string;
  isLoading?: boolean;
  previewSupported: boolean;
  hasKeywordsFilter: boolean;
  containerClassName?: string;
  inDrawer?: boolean;
}) {
  const cannotPreview = !documentUrl || !previewSupported;

  const unsupportedDocument = (
    <>
      <div className={classNames(css.container, containerClassName)}>
        {hasKeywordsFilter ? (
          <>
            <img src={FOLDER} alt="Folder" className={css.folderImg} />
            <h2>We are unable to preview this document</h2>
            <p className={css.info}>
              This document matches your search but we can't show a preview of this file type.
              Download the document to view its content.
            </p>
          </>
        ) : (
          <>
            <img src={FOLDER} alt="Folder" className={css.folderImg} />
            <h2>Unsupported document type</h2>
            <p className={css.info}>
              We are unable to display this document type in app. Please download the document to
              view it.
            </p>
          </>
        )}
        <Button
          download
          type="primary"
          href={downloadUrl}
          icon={<DownloadOutlined />}
          className={css.download}
        >
          Download to view
        </Button>
      </div>
    </>
  );

  if (!inDrawer && isLoading) {
    return <Spin className={css.container} />;
  }

  if (!inDrawer && cannotPreview) {
    return unsupportedDocument;
  }

  return (
    <>
      {(isLoading || cannotPreview) && (
        <div className={css.overlay}>
          {isLoading && <Spin className={css.container} />}
          {!isLoading && cannotPreview && unsupportedDocument}
        </div>
      )}
      <FileViewer documentUrl={documentUrl} />
    </>
  );
}
