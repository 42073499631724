import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Spin } from "antd5";

import TextButton from "components/actions/TextButton";
import { CpvSummary } from "components/cpv_summary/CpvSummary";
import { Line } from "lib/core_components/Line";
import { EventNames, useTracking } from "lib/tracking";
import { useBuyerCpvStats } from "../../lib/hooks/api/buyer/useBuyerCpvStats";

import css from "./SummaryTab.module.scss";

type Props = {
  buyerId: number;
  buyerGuid: string;
  showMatchingExpiries: () => void;
};

const CpvLookbackDates: Record<string, string> = {
  P1Y: "1 year",
  P2Y: "2 years",
  P3Y: "3 years",
  P4Y: "4 years",
  P5Y: "5 years",
};

function SummarySection({ buyerId, buyerGuid, showMatchingExpiries }: Props): JSX.Element {
  const { logEvent } = useTracking();
  const [upcomingExpiries, setUpcomingExpiries] = React.useState(false);
  const [cpvLookback, setCpvLookback] = React.useState("P5Y");
  const { data } = useBuyerCpvStats({ buyerId, upcomingExpiries, period: cpvLookback });

  const onShowMatchingExpiriesClicked = React.useCallback(() => {
    logEvent(EventNames.seeMatchingExpiriesClicked);
    showMatchingExpiries();
  }, [logEvent, showMatchingExpiries]);

  return (
    <>
      <div className={css.header}>
        <Dropdown
          menu={{
            items: [
              { label: "Upcoming contract expiries", key: "Upcoming contract expiries" },
              { label: "Historical awards", key: "Historical awards" },
            ],
            onClick: (item) => {
              const isAwards = item.key === "Upcoming contract expiries";
              setUpcomingExpiries(isAwards);
              logEvent(EventNames.summaryViewChanged, {
                "View selected": item.key,
              });
            },
          }}
          trigger={["click"]}
        >
          <TextButton>
            {upcomingExpiries ? "Upcoming contract expiries" : "Historical awards"} <DownOutlined />
          </TextButton>
        </Dropdown>{" "}
        <div>{upcomingExpiries ? "In the next" : "From the past"}</div>{" "}
        <Dropdown
          menu={{
            items: Object.keys(CpvLookbackDates).map((d) => ({
              label: CpvLookbackDates[d],
              key: d,
            })),
            onClick: ({ key }) => {
              logEvent(EventNames.summaryTimelineChanged, {
                "View selected": CpvLookbackDates[key],
              });
              setCpvLookback(key);
            },
          }}
          trigger={["click"]}
        >
          <TextButton>
            {CpvLookbackDates[cpvLookback]} <DownOutlined />
          </TextButton>
        </Dropdown>{" "}
        {upcomingExpiries && (
          <>
            <Line vertical />
            <TextButton onClick={onShowMatchingExpiriesClicked}>
              See expiries matching my settings
            </TextButton>
          </>
        )}
      </div>
      <Line />
      <div className={css.summary}>
        {data ? (
          <CpvSummary
            requiredSlideoutType="BUYERS"
            overallStats={data.overall_stats}
            perCpvStats={data.per_cpv_stats}
            buyerGuid={buyerGuid}
            dateFilter={
              upcomingExpiries
                ? { expiryDate: { filter: { relativeTo: cpvLookback, relativeFrom: "PT0S" } } }
                : {
                    publishDate: {
                      // the lookback date must be negated
                      relativeFrom: cpvLookback.replace(/P/, "P-"),
                      relativeTo: "PT0S",
                    },
                  }
            }
          />
        ) : (
          <div className={css.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </>
  );
}

export default SummarySection;
