import React from "react";
import { DateTime } from "luxon";

import css from "./AiHeader.module.scss";

import geo from "../../../../../assets/images/geometric.svg";

function ordinal(day: number) {
  const remainder10 = day % 10,
    remainder100 = day % 100;
  if (remainder10 == 1 && remainder100 != 11) {
    return day + "st";
  }
  if (remainder10 == 2 && remainder100 != 12) {
    return day + "nd";
  }
  if (remainder10 == 3 && remainder100 != 13) {
    return day + "rd";
  }
  return day + "th";
}

type Props = {
  title: string;
  date?: Date | null;
  organisationName?: string;
};

export function AiHeader({ title, date, organisationName }: Props) {
  const publishedAt = date ? new Date(date) : new Date();
  const lxDate = DateTime.fromJSDate(publishedAt);
  const formattedDate = `${ordinal(lxDate.day)} ${lxDate.monthLong} ${lxDate.year}`;
  return (
    <div className={css.container}>
      <div className={css.body}>
        <div className={css.headerText}>
          <h2>AI REPORT</h2>
          <h1 className={css.title}>{title}</h1>
          <span className={css.reportDetails}>
            {formattedDate}
            {organisationName && (
              <>
                <span className={css.separator}>·</span> {organisationName}
              </>
            )}
          </span>
        </div>
        <img src={geo} alt="stotles geometrics" className={css.geoImage} />
      </div>
    </div>
  );
}
