import React, { useCallback, useState } from "react";

import { BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters as RequestFilters } from "lib/generated/app-api/models/BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters";
import { useBuyerSignalStats } from "lib/hooks/api/useBuyerSignalStats";
import { useOpenApi } from "lib/openApiContext";
import { PagingState, SortState } from "lib/search/types";
import { BuyerColumn, TableSettings } from "lib/types/models";
import { useUrlParamsTracker } from "lib/urlParamsTracker";
import { TrackingProvider } from "../../lib/tracking";
import BuyerFilters, { BuyerFilter, BuyerSignalFilters } from "./BuyerFilters";
import { buyerListColumns } from "./BuyerList";
import { BuyerListStats, BuyersTable, getBuyerListColumns } from "./BuyersTable";
import { EmptyFindBuyers, LoadingFindBuyers } from "./FindBuyersCommon";
import { fromUrlParams, toUrlParams } from "./paramUtils";

const DEFAULT_PAGE_SIZE = 20;

type RelevantBuyersProps = {
  currentFilters: BuyerFilter;
  onCurrentFiltersChange: React.Dispatch<React.SetStateAction<BuyerFilter>>;
};
export function RelevantBuyers({
  currentFilters,
  onCurrentFiltersChange,
}: RelevantBuyersProps): JSX.Element {
  const openApi = useOpenApi();

  const [sortState, setSortState] = useState<SortState>({
    field: "matching_records",
    order: "DESC",
  });

  useUrlParamsTracker({
    params: currentFilters,
    onChange: onCurrentFiltersChange,
    toUrlParams,
    fromUrlParams,
  });

  const [paginationState, setPaginationState] = React.useState<PagingState>({
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRowGuids, setSelectedRowGuids] = React.useState<string[]>([]);

  // This state is to keep track of column width and position which can be configured
  // with the edit column button in BuyerFilters component
  const [tableSettings, setTableSettings] = React.useState<TableSettings<BuyerColumn>>({
    columns: buyerListColumns,
  });

  const handleSortChange = useCallback((sortState: SortState) => {
    setSortState(sortState);
    setPaginationState((oldState) => ({ ...oldState, currentPage: 1 }));
  }, []);

  const requestFilters = React.useMemo((): RequestFilters => {
    return {
      buyerName: currentFilters.buyerName || undefined,
      signals: currentFilters.signals,
      categories: {
        categoryIds: currentFilters.categoryIds,
        includeUncategorised: currentFilters.includeUncategorisedBuyers,
      },
      countryCodes: currentFilters.countryCodes,
      regionIds: currentFilters.regionIds,
    };
  }, [currentFilters]);

  const {
    data: statsResponse,
    isLoading: isLoadingStats,
    isLoadingError: isLoadingStatsError,
    // For when we already have some data but are loading a different page, sort, etc.
    isPreviousData,
  } = useBuyerSignalStats(
    {
      sort: sortState.field,
      sortOrder: sortState.order,
      offset: (paginationState.currentPage - 1) * paginationState.pageSize,
      limit: paginationState.pageSize,
      onlyRelevantBuyers: true,
      filters: requestFilters,
    },
    { enabled: true, keepPreviousData: true },
  );

  const columns = React.useMemo(
    () =>
      getBuyerListColumns(
        sortState,
        tableSettings.columns,
        requestFilters.signals as BuyerSignalFilters,
      ),
    [requestFilters.signals, sortState, tableSettings.columns],
  );

  const exportAction = React.useCallback(
    async (format: "xlsx" | "csv"): Promise<[string, Blob]> => {
      switch (format) {
        case "csv": {
          const response = await openApi.buyersRelationshipStatsCsv({
            buyersRelationshipStatsRequest: {
              onlyRelevantBuyers: true,
              filters: requestFilters,
              buyerGuids: selectedRowGuids,
              offset: 0,
            },
          });
          return ["buyers_export", new Blob([response])];
        }
        case "xlsx": {
          const response = await openApi.buyersRelationshipStatsXlsx({
            buyersRelationshipStatsRequest: {
              onlyRelevantBuyers: true,
              filters: requestFilters,
              buyerGuids: selectedRowGuids,
              offset: 0,
            },
          });
          return ["buyers_export", new Blob([response])];
        }
      }
    },
    [openApi, requestFilters, selectedRowGuids],
  );

  let table;

  if (isLoadingStats && !statsResponse) {
    table = <LoadingFindBuyers pageSize={paginationState.pageSize} columns={columns} />;
  } else {
    table = (
      <TrackingProvider data={{ "Context source": "In-row" }}>
        <BuyersTable
          onSortChange={handleSortChange}
          isLoading={isPreviousData}
          isError={isLoadingStatsError}
          stats={(statsResponse?.signalStats || []) as BuyerListStats[]}
          resultsCount={statsResponse?.totalCount || 0}
          paginationState={paginationState}
          onPaginationStateChange={setPaginationState}
          columns={columns}
          selectedRowGuids={selectedRowGuids}
          onSelectedRowsChange={setSelectedRowGuids}
          emptyContent={
            <EmptyFindBuyers
              description={"Try switching over to all buyers or remove some filters."}
            />
          }
        />
      </TrackingProvider>
    );
  }

  return (
    <>
      <BuyerFilters
        currentFilters={currentFilters}
        setFilters={onCurrentFiltersChange}
        tableSettings={tableSettings}
        onChangeTableSettings={setTableSettings}
        allAvailableColumns={buyerListColumns}
        exportAction={exportAction}
        selectedRowGuids={selectedRowGuids}
        totalCount={statsResponse?.totalCount || 0}
      />
      {table}
    </>
  );
}
