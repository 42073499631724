import * as React from "react";

import TreeDropdownList, { Options, SelectedOptions } from "lib/core_components/TreeDropdownList";
import { IBuyerDataProvider } from "lib/data_providers/BuyerDataProvider";
import { useStotlesData } from "lib/providers/StotlesData";
import { BuyerCategory } from "lib/StotlesApi";
import { filterIterable } from "lib/utils";

const UNCATEGORISED_ID = "uncategorised";

type Props = {
  // Prop to be able to provide a limited set of categories, if not provided, we use the BuyerDataProvider
  // and get all.
  buyerCategoryOptions?: string[];
  selectedIds: string[];
  onChange: (newIds: string[], showUncategorised?: boolean) => void;
  showUncategorised?: boolean;
  searchable?: boolean;
  trackingEvent?: (field: string, selected: boolean) => void;
  dropdownOverlayClassName?: string;
};

function extractBuyerCategoriesFromIds(ids: string[], categories: Map<string, BuyerCategory>) {
  const options = new Map();
  for (const id of ids) {
    const cat = categories.get(id);
    if (cat) {
      options.set(cat.id, cat);
      const parentCat = cat.parent_category_id && categories.get(cat.parent_category_id);
      // If the category is a child and the parent isn't saved in options yet, we add it
      // otherwise it can't be displayed.
      if (parentCat && !options.has(parentCat.id)) {
        options.set(parentCat.id, parentCat);
      }
    }
  }
  return options;
}

function getCategoryChildren(pId: string, buyerCategories: IterableIterator<BuyerCategory>) {
  return filterIterable(buyerCategories, (child: BuyerCategory) => child.parent_category_id === pId)
    .map((c) => ({ title: c.name, value: c.id }))
    .sort((a, b) => a.title.localeCompare(b.title));
}

function BuyerCategoyTreeSelectDropdown({
  buyerCategoryOptions,
  selectedIds,
  onChange,
  searchable,
  showUncategorised,
  trackingEvent,
  dropdownOverlayClassName,
}: Props) {
  const { value: allBuyerCategories } = useStotlesData(
    IBuyerDataProvider,
    (provider) => provider.getBuyerCategories(),
    [],
  );
  // Either use provided categories or categories from provider
  // provided options can either be an array of ids or a map of id and category
  const bCats: Map<string, BuyerCategory> | undefined = React.useMemo(() => {
    if (buyerCategoryOptions) {
      if (!allBuyerCategories) return undefined;
      return extractBuyerCategoriesFromIds(buyerCategoryOptions, allBuyerCategories);
    } else {
      return allBuyerCategories;
    }
  }, [allBuyerCategories, buyerCategoryOptions]);

  const options = React.useMemo(() => {
    const o: Options = {};
    if (!bCats) return o;
    for (const [catId, cat] of bCats) {
      const isParent = !cat.parent_category_id;
      if (isParent) {
        o[catId] = {
          title: cat.name,
          value: catId,
          children: getCategoryChildren(catId, bCats.values()),
        };
      }
    }
    o[UNCATEGORISED_ID] = {
      title: "Uncategorised",
      value: UNCATEGORISED_ID,
    };
    return o;
  }, [bCats]);

  const selectedOptions = React.useMemo(() => {
    const s: SelectedOptions = {};
    if (!bCats) return s;
    for (const [catId, cat] of bCats) {
      const isParent = !cat.parent_category_id;
      if (isParent && selectedIds.includes(catId)) {
        s[catId] = { includeAll: true };
      } else if (isParent) {
        const selectedChildIds: string[] = filterIterable(
          bCats.values(),
          (child: BuyerCategory) =>
            child.parent_category_id === catId && selectedIds.includes(child.id),
        ).map((c) => c.id);
        s[catId] = { names: selectedChildIds };
      }
    }
    if (showUncategorised) {
      s[UNCATEGORISED_ID] = { includeAll: true };
    }
    return s;
  }, [bCats, selectedIds, showUncategorised]);

  const onSelectedChange = React.useCallback(
    (selectedCategories: SelectedOptions) => {
      let showUncategorised = undefined;
      const newSelectedIds: string[] = [];
      for (const [catId, selectedValues] of Object.entries(selectedCategories)) {
        // If the parent id is the uncategoried special id we add that and that's it for the category
        if (catId === UNCATEGORISED_ID) {
          if (selectedValues.includeAll) {
            showUncategorised = true;
          } else if (selectedValues.includeAll === false) {
            // We only set uncategorised to false if it's explicitely set to false from the select
            showUncategorised = false;
          }
          continue;
        }

        // If includeAll is selected we push the top level buyer category id,
        // so in the rare case of buyers being tagged with only top level get returned too
        if (selectedValues.includeAll) {
          const allChildIds = options[catId]?.children?.map((v) => v.value);
          newSelectedIds.push(catId);
          // since top level is selected we push all of that categories' sub cats
          if (allChildIds) {
            newSelectedIds.push(...allChildIds);
          }
        } else {
          newSelectedIds.push(...selectedValues.names);
        }
      }
      onChange(newSelectedIds, showUncategorised);
    },
    [onChange, options],
  );

  return (
    <TreeDropdownList
      options={options}
      title="Buyer types"
      searchable={searchable}
      selectedOptions={selectedOptions}
      onSelectedChange={onSelectedChange}
      trackingEvent={trackingEvent}
      dropdownOverlayClassName={dropdownOverlayClassName}
    />
  );
}

export default BuyerCategoyTreeSelectDropdown;
