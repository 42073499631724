import * as React from "react";

import Signal, { SignalPaywallConfig } from "components/tags/Signal";
import { GroupSelectValue } from "lib/core_components/TreeSelect";
import { useSubscription } from "lib/providers/Subscription";
import { SignalCategory } from "lib/StotlesApi";
import TreeDropdownList, {
  Options,
  SelectedOptions,
} from "../../../lib/core_components/TreeDropdownList";

/**
 * Signals rendered in a checkable tree select inside a dropdown list with a trigger button
 * Props fed to this component follow the new filter convention for filters
 */

type SignalFilter =
  | {
      includeAll: true;
    }
  | {
      includeAll?: false;
      names: string[];
    };

type SignalCategoryKeys = "competitors" | "partners" | "cpvCodes" | "keywords";

type SignalFilters = { [K in SignalCategoryKeys]?: SignalFilter };

type SignalOptions = { [K in SignalCategoryKeys]?: string[] };

type Props = {
  selectedSignals: SignalFilters;
  signalOptions: SignalOptions;
  onChange: (s: SignalFilters) => void;
  searchable?: boolean;
  trackingEvent?: (field: string, selected: boolean) => void;
  paywallConfig?: SignalPaywallConfig;
  dropdownOverlayClassName?: string;
};

const categoryConversion = {
  competitors: SignalCategory.COMPETITOR,
  partners: SignalCategory.PARTNER,
  keywords: SignalCategory.KEYWORD,
  cpvCodes: SignalCategory.CPV_CODE,
};

function SignalsTreeSelectDropdown({
  selectedSignals,
  signalOptions,
  onChange,
  searchable,
  trackingEvent,
  paywallConfig,
  dropdownOverlayClassName,
}: Props) {
  const subscription = useSubscription();

  // Convert all signal types to the options that TreeDropdownList expects
  const options = React.useMemo(() => {
    const o: Options = {};
    const hasBuyerAccess = subscription.hasDataTypes("BUYERS");
    for (const signalCategory of Object.keys(signalOptions) as SignalCategoryKeys[]) {
      const names = signalOptions[signalCategory];
      const category = categoryConversion[signalCategory];
      // This is a paywall, we don't allow clicking on partner/competitor tags if they don't have access
      const disableClicks =
        (category === SignalCategory.COMPETITOR || category === SignalCategory.PARTNER) &&
        !hasBuyerAccess;
      if (names && names.length > 0) {
        o[signalCategory] = {
          title: category,
          value: signalCategory,
          children: names.map((n) => ({ title: n, value: n, disabled: disableClicks })),
          disabled: disableClicks,
          renderOption: (v) => (
            <Signal signal={{ name: v, category }} paywallConfig={paywallConfig} />
          ),
        };
      }
    }
    return o;
  }, [signalOptions, paywallConfig, subscription]);

  // Convert selected options to fit the TreeDropdownList
  const selected: SelectedOptions = React.useMemo(() => {
    const s: SelectedOptions = {};
    for (const signalCategory of Object.keys(selectedSignals) as SignalCategoryKeys[]) {
      const signalFilter = selectedSignals[signalCategory];
      if (!signalFilter) continue;
      s[signalCategory] = signalFilter as GroupSelectValue;
    }
    return s;
  }, [selectedSignals]);

  const onSelect = React.useCallback(
    (s: SignalFilters) => {
      const selectedValues: SignalFilters = {};
      // This filters out any categories that don't have any selected items
      for (const [category, values] of Object.entries(s)) {
        if (values && (values.includeAll || ("names" in values && values.names.length > 0))) {
          selectedValues[category as keyof SignalFilters] = values;
        }
      }
      onChange(selectedValues);
    },
    [onChange],
  );

  return (
    <TreeDropdownList
      options={options}
      title="Signals"
      searchable={searchable}
      selectedOptions={selected}
      onSelectedChange={onSelect}
      trackingEvent={trackingEvent}
      dropdownOverlayClassName={dropdownOverlayClassName}
    />
  );
}

export default SignalsTreeSelectDropdown;
