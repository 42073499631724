import React, { CSSProperties } from "react";
import styled, { CSSObject } from "@emotion/styled";

/**
 * A type alias that represents a subset of CSS properties.
 * This type is created by picking properties from the `CSSObject` type that are also present in `CSSProperties`.
 * It is useful for defining CSS-in-JS styles with a limited set of CSS properties.
 */
export type CSSObj = Pick<CSSObject, keyof CSSProperties>;

// All new text in the app will be wrapped in this component.
// TODO: add truncation
// TODO: add link styles
type TextProps = {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  body?: boolean;
  description?: boolean;
  bold?: boolean;
  white?: boolean;
  color?: string;
  fontWeight?: "400" | "500" | "600" | "700" | "800";
  removeLineHeight?: boolean;
  center?: boolean;
  children: React.ReactNode;
  className?: string;
};

/**
 * A versatile text component that renders different HTML elements based on the provided props.
 *
 * @example
 * ```tsx
 * <Text h1 bold color="blue">
 *   This is a blue bold heading
 * </Text>
 * <Text body>
 *   This is body text
 * </Text>
 * ```
 */
export function Text({
  h1,
  h2,
  h3,
  body,
  description,
  bold,
  white,
  color,
  fontWeight,
  removeLineHeight,
  center,
  children,
  className,
}: TextProps) {
  const getTextType = () => {
    if (h1) return "h1";
    if (h2) return "h2";
    if (h3) return "h3";
    return "p";
  };

  return (
    <StyledText
      className={className}
      as={getTextType()}
      h1={h1}
      h2={h2}
      h3={h3}
      body={body}
      description={description}
      bold={bold}
      white={white}
      color={color}
      fontWeight={fontWeight}
      removeLineHeight={removeLineHeight}
      center={center}
    >
      {children}
    </StyledText>
  );
}

const StyledText = styled.div<TextProps>(
  ({
    theme,
    h1,
    h2,
    h3,
    description,
    bold,
    white,
    color,
    fontWeight,
    removeLineHeight,
    center,
  }) => ({
    color: color || (white ? theme.colors.sysTextWhite : theme.colors.sysTextDefault),
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    fontWeight: fontWeight ? fontWeight : bold ? 600 : 400,
    lineHeight: "157%",
    margin: 0,
    padding: 0,
    ...(h1 && {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "133%",
    }),
    ...(h2 && {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "140%",
    }),
    ...(h3 && {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "150%",
    }),
    ...(description && {
      fontSize: 12,
      lineHeight: "166%",
    }),
    ...(removeLineHeight && { lineHeight: "0" }),
    ...(center && { textAlign: "center" }),
  }),
);

type FlexProps = {
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  flexDirection?: CSSProperties["flexDirection"];
  flexWrap?: CSSProperties["flexWrap"];
  flexGrow?: CSSProperties["flexGrow"];
  alignSelf?: CSSProperties["alignSelf"];
  gap?: CSSProperties["gap"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  column?: boolean;
};

/**
 * A styled `div` component that provides flexible box layout properties.
 *
 * @example
 * ```tsx
 * <Flex justifyContent="center" alignItems="center" flexDirection="column">
 *   <ChildComponent />
 * </Flex>
 * ```
 */
export const Flex = styled.div<FlexProps>((props) => ({
  display: "flex",
  justifyContent: props.justifyContent || "",
  alignItems: props.alignItems || "",
  flexDirection: props.column ? "column" : props.flexDirection || "row",
  flexWrap: props.flexWrap || "nowrap",
  gap: props.gap,
  alignSelf: props.alignSelf || "auto",
  flexGrow: props.flexGrow || 0,
  width: props.width,
  height: props.height,
}));

type SpacerProps = {
  horizontal?: number | string;
  vertical?: number | string;
};
/**
 * A styled component that creates a spacer element with customizable
 * height and width. The spacer can be used to add vertical or horizontal
 * space between elements. Use flex or other layout where possible
 *
 * @example
 * // Usage example:
 * <Spacer vertical="10px" horizontal="20px" />
 *
 * @property {string | number} [vertical] - The height of the spacer.
 * @property {string | number} [horizontal] - The width of the spacer.
 *
 */
export const Spacer = styled.div<SpacerProps>((props) => ({
  height: props.vertical || 0,
  width: props.horizontal || 0,
}));
