import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import { Button } from "antd5";
import { Route, Router, Switch } from "wouter";

import { withAppLayout } from "../../components/app_layout/AppLayout";
import { DetailsContent } from "../../components/app_layout/DetailsLayout";
import { CreateOpportunityModal } from "../../components/opportunities/create_opportunity/CreateOpportunityModal";
import OpportunitiesTable from "../../components/opportunities/opportunities_table/OpportunitiesTable";
import {
  SearchOpportunitiesRequest_OrderByEnum_1,
  SearchOpportunitiesRequest_OrderEnum_1,
} from "../../lib/generated/app-service-gql/graphql";
import { useOpportunities } from "../../lib/hooks/api/opportunities/useOpportunities";
import { Flex, Text } from "../../styles/utility-components";
import OpportunityPage from "./OpportunityPage";

import css from "./OpportunitiesPage.module.scss";

function OpportunitiesPageWrapper() {
  return (
    <Router base={`/opportunities`}>
      <Switch>
        <Route path="/:id">{(params) => <OpportunityPage id={params.id} />}</Route>
        <Route path="/" component={OpportunitiesPage} />
      </Switch>
    </Router>
  );
}

export function OpportunitiesPage() {
  const {
    data: opportunities,
    isLoading,
    isError,
  } = useOpportunities(
    {
      orderBy: SearchOpportunitiesRequest_OrderByEnum_1.CreatedAt,
      order: SearchOpportunitiesRequest_OrderEnum_1.Desc,
    },
    { pageSize: 10, page: 1 },
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  return (
    <DetailsContent className={css.container}>
      <Flex column gap={16}>
        <Flex justifyContent="space-between">
          <Text h1>Opportunities</Text>
          <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
            Create Opportunity
          </Button>
        </Flex>
        <Text>Total: {opportunities?.total}</Text>
        <OpportunitiesTable
          opportunities={opportunities?.results}
          isLoading={isLoading}
          isError={isError}
        />
        <CreateOpportunityModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      </Flex>
    </DetailsContent>
  );
}

export default hot(withAppLayout(OpportunitiesPageWrapper, {}));
