import React from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd5";

import { useGenerateKeywordsRecommendation } from "lib/hooks/api/keywords/useGenerateKeywordsRecommendation";
import { useKeywordsRecommendation } from "lib/hooks/api/keywords/useKeywordsRecommendation";
import * as tracking from "lib/tracking";

import css from "./SuggestedKeywords.module.scss";

type SuggestedKeywordsProps = {
  onClick: (keyword: string) => void;
  existingKeywords: string[];
  dismissedKeywords: string[];
  description?: string;
  forSignalSettings?: boolean;
};

const LoadingButton = () => {
  return (
    <div className={css.generatingButton}>
      <Spin indicator={<LoadingOutlined spin />} size="small" className={css.spinner} /> Generating
    </div>
  );
};

function SuggestedKeywords({
  onClick,
  description,
  existingKeywords,
  dismissedKeywords,
}: SuggestedKeywordsProps) {
  const [suggestions, setSuggestions] = React.useState<string[]>([]);

  const { data: initialRecommendations, isFetching: initialRecommendationsLoading } =
    useKeywordsRecommendation(
      {
        existingKeywords: existingKeywords,
        dismissedKeywords: dismissedKeywords,
      },
      { enabled: suggestions.length === 0 },
    );

  const { mutate, isLoading } = useGenerateKeywordsRecommendation({
    onSuccess: (data) => {
      if (suggestions.length === 2) {
        //scenario: a user has selected a keyword and we're generating one new one.
        const initialKeyword = data.keywordsRecommendations.keywords[0];
        const combinedSuggestions = [...suggestions, ...[initialKeyword]];
        const uniqueSuggestions = Array.from(new Set(combinedSuggestions));

        setSuggestions(uniqueSuggestions);
      } else {
        // scenario: a user has clicked refresh and we're generating 3 new keywords.
        setSuggestions(Array.from(new Set(data.keywordsRecommendations.keywords)).splice(0, 3));
      }
    },
  });

  React.useEffect(() => {
    if (initialRecommendations && suggestions.length === 0) {
      setSuggestions(initialRecommendations.keywords.slice(0, 3));
    }
  }, [initialRecommendations, suggestions]);

  const mappedKeywords = () => {
    return suggestions.slice(0, 3).map((recommendation: string) => (
      <Button
        className={css.keywordSuggestion}
        key={recommendation}
        onClick={() => {
          setSuggestions(suggestions.filter((keyword) => keyword !== recommendation));
          mutate({
            args: {
              dismissedKeywords,
              existingKeywords,
              // unfortunately, we can't guarantee 3 results from openai, so we're just going to request 5 and take the first 3.
              numberOfKeywords: 5,
              currentlySuggestedKeywords: suggestions,
            },
          });

          tracking.logEvent(tracking.EventNames.signalsEdited, {
            "Page source": "Account Management - Feed Settings",
            "Signal type": "Keywords",
            "Context source": "Suggested",
            "Action type": "Added",
            "Signal name(s)": "--",
          });
          onClick(recommendation);
        }}
        disabled={isLoading}
      >
        {recommendation} <PlusOutlined />
      </Button>
    ));
  };

  return (
    <div className={css.suggestedSection}>
      <div className={css.headerRow}>
        <div>
          <h2 className={css.suggestedLabel}>Select from recommended keywords</h2>
          {description && <p>{description}</p>}
        </div>
        <div className={css.refreshSection}>
          <Button
            onClick={() => {
              setSuggestions([]);
              mutate({
                // unfortunately, we can't guarantee 3 results from openai, so we're just going to request 5 and take the first 3.
                args: { dismissedKeywords, existingKeywords, numberOfKeywords: 5 },
              });
              tracking.logEvent(tracking.EventNames.suggestedSignalRefresh, {
                "Page source": "Account Management - Feed Settings",
                "Signal type": "Keywords",
                "Context source": "Refresh Button",
              });
            }}
            disabled={isLoading}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className={css.buttonsRow}>
        <>{!isLoading && !initialRecommendationsLoading && mappedKeywords()}</>
        {isLoading && suggestions.length > 0 && (
          <>
            <LoadingButton />
          </>
        )}
        {(isLoading && suggestions.length === 0) ||
          ((initialRecommendationsLoading && suggestions.length) === 0 && (
            <>
              <LoadingButton />
              <LoadingButton />
              <LoadingButton />
            </>
          ))}
      </div>
    </div>
  );
}

export default SuggestedKeywords;
