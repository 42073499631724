import React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Drawer, Tabs } from "antd5";
import classNames from "classnames";

import { PdfViewerContainer } from "components/document_details/PdfViewerContainer";
import { DocumentFilters } from "components/documents/utils";
import { useDocument } from "lib/hooks/api/documents/useDocument";
import { TrackingProvider } from "lib/tracking";
import { FileViewerContextProvider } from "../../lib/core_components/FileViewer";
import DocumentHeader from "./DocumentHeader";
import { DocumentViewerFilters } from "./FilterForm";
import { createTabItems, isMimeTypeSupported, TAB_KEYS, TabsConfig } from "./utils";

import css from "./DocumentDrawer.module.scss";
import tabCss from "./utils.module.scss";

type DocumentDrawerProps = {
  documentId?: string;
  filters: DocumentFilters;
  onClose: () => void;
  open: boolean;
};

type DocumentViewerProps = {
  documentId: string;
  filters: DocumentFilters;
};

export default function DocumentDrawer({
  documentId,
  filters,
  open,
  onClose,
}: DocumentDrawerProps) {
  const { data, isError } = useDocument(documentId ?? "", {
    enabled: !!documentId,
  });

  return (
    <TrackingProvider
      data={{ "Context source": "Document preview", "Document type": data?.category ?? "-" }}
    >
      <Drawer
        open={open}
        onClose={onClose}
        styles={{
          body: { padding: 0 },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: this property does exist in ant but types are not right
        width={1200}
        closeIcon={null}
        forceRender
      >
        <div className={css.previewWrapper}>
          <div className={css.controls}>
            <Button
              title="Close"
              icon={<DoubleRightOutlined className={css.closeIcon} />}
              onClick={onClose}
              className={css.controlsBtn}
            />
          </div>
          {isError ? (
            <div className={css.error}>Sorry, something has gone wrong.</div>
          ) : (
            <div className={css.previewContent}>
              <DocumentHeader
                documentId={documentId ?? ""}
                title={data?.title ?? ""}
                downloadUrl={data?.downloadUrl ?? ""}
              />
              {documentId && <DocumentViewer documentId={documentId} filters={filters} />}
            </div>
          )}
        </div>
      </Drawer>
    </TrackingProvider>
  );
}

function DocumentViewer({ documentId, filters }: DocumentViewerProps) {
  const { data, isLoading, isFetching } = useDocument(documentId);
  const [activeTab, setActiveTab] = React.useState(TAB_KEYS.search);

  const defaultFilters: DocumentViewerFilters = {
    keywords: filters.keywords ?? [],
  };

  const documentTypeSupported = isMimeTypeSupported(data?.originalMimeType ?? "");

  const isSearchDisabled = !isLoading && (!data?.pdfDocumentUrl || !documentTypeSupported);

  const tabItems = createTabItems(documentId, isLoading, isSearchDisabled, defaultFilters, data);

  React.useEffect(() => {
    if (isSearchDisabled) {
      setActiveTab(TAB_KEYS.info);
    } else {
      setActiveTab(TAB_KEYS.search);
    }
  }, [documentId, isSearchDisabled]);

  return (
    <FileViewerContextProvider>
      <div className={css.container}>
        <div className={css.columns}>
          <PdfViewerContainer
            documentUrl={data?.pdfDocumentUrl ?? ""}
            downloadUrl={data?.downloadUrl ?? ""}
            previewSupported={documentTypeSupported}
            hasKeywordsFilter={!!defaultFilters.keywords.length}
            isLoading={isFetching}
            containerClassName={css.viewerContainer}
            inDrawer
          />
          <ConfigProvider theme={TabsConfig}>
            <Tabs
              activeKey={activeTab}
              size="large"
              items={tabItems}
              onChange={(key: string) => setActiveTab(key)}
              className={classNames(tabCss.toolbar, css.toolbarContainer)}
            />
          </ConfigProvider>
        </div>
      </div>
    </FileViewerContextProvider>
  );
}
