import React from "react";
import { CaretDownOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Dropdown } from "antd5";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import { useDeleteRecordQualification } from "lib/hooks/api/records/useDeleteRecordQualification";
import { useUpdateRecordQualification } from "lib/hooks/api/records/useUpdateRecordQualification";
import {
  CircleEmpty,
  CircleFull,
  CircleHalfFull,
  CircleThirdFull,
  CircleTwoThirdsFull,
  TrophyCircle,
} from "lib/icons/ProgressCircle";
import { yellow600 } from "lib/themes/colors";

import css from "./QualificationStatus.module.scss";

export const PRE_ENGAGE_QUALIFICATION: Record<string, React.ReactNode> = {
  unqualified: (
    <div className={css.qualificationContainer}>
      <CircleEmpty marginTop="2px" /> Unqualified
    </div>
  ),
  pre_engage_to_do: (
    <div className={css.qualificationContainer}>
      <CircleHalfFull fill={yellow600} marginTop="2px" /> Pre-engage: To do
    </div>
  ),
  pre_engage_done: (
    <div className={css.qualificationContainer}>
      <CheckCircleFilled className={css.greenCircleIcon} style={{ marginTop: "2px" }} />
      Pre-engage: Actioned
    </div>
  ),
  pre_engage_not_relevant: (
    <div className={css.qualificationContainer}>
      <CircleFull fill={DefaultTheme.token?.colorError} marginTop="2px" />
      Pre-engage: Not relevant
    </div>
  ),
};

export const TENDER_QUALIFICATION: Record<string, React.ReactNode> = {
  unqualified: (
    <div className={css.qualificationContainer}>
      <CircleEmpty marginTop="2px" /> Unqualified
    </div>
  ),
  in_review: (
    <div className={css.qualificationContainer}>
      <CircleThirdFull fill={yellow600} width={20} height={"auto"} marginTop="2px" /> Tender: In
      review
    </div>
  ),
  bid_prep: (
    <div className={css.qualificationContainer}>
      <CircleTwoThirdsFull fill={yellow600} marginTop="2px" /> Tender: Bid prep
    </div>
  ),
  bid_submitted: (
    <div className={css.qualificationContainer}>
      <CircleFull fill={DefaultTheme.token?.colorSuccess} marginTop="2px" /> Tender: Bid submitted
    </div>
  ),
  won: (
    <div className={css.qualificationContainer}>
      <TrophyCircle fill={DefaultTheme.token?.colorSuccess} marginTop="2px" /> Tender: Won
    </div>
  ),
  not_relevant: (
    <div className={css.qualificationContainer}>
      <CircleFull fill={DefaultTheme.token?.colorError} marginTop="2px" />
      Tender: Not relevant
    </div>
  ),
  no_bid: (
    <div className={css.qualificationContainer}>
      <CircleFull fill={DefaultTheme.token?.colorError} marginTop="2px" />
      Tender: No bid
    </div>
  ),
  lost: (
    <div className={css.qualificationContainer}>
      <CircleFull fill={DefaultTheme.token?.colorError} marginTop="2px" />
      Tender: Lost
    </div>
  ),
};

export const QualificationEnum = {
  NotRelevant: "not_relevant",
  InReview: "in_review",
  BidPrep: "bid_prep",
  BidSubmitted: "bid_submitted",
  NoBid: "no_bid",
  Won: "won",
  Lost: "lost",
  PreEngageToDo: "pre_engage_to_do",
  PreEngageDone: "pre_engage_done",
  PreEngageNotRelevant: "pre_engage_not_relevant",
} as const;
export type QualificationEnumType = (typeof QualificationEnum)[keyof typeof QualificationEnum];

export type Qualification = QualificationEnumType | "unqualified";

function QualificationSelect({
  recordGuid,
  procurementStage,
  score,
  signalTypes,
}: {
  recordGuid: string;
  procurementStage: { id: string; stage: string; qualification: Qualification };
  score: number | null | undefined;
  signalTypes: Record<string, string[]> | undefined;
}) {
  const { mutate } = useUpdateRecordQualification();
  const { mutate: unQualify } = useDeleteRecordQualification();

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  const selectedQualifcation = isPreEngage
    ? PRE_ENGAGE_QUALIFICATION[procurementStage.qualification]
    : TENDER_QUALIFICATION[procurementStage.qualification];

  const items = Object.entries(isPreEngage ? PRE_ENGAGE_QUALIFICATION : TENDER_QUALIFICATION).map(
    ([key, value]) =>
      key === procurementStage.qualification
        ? { key, label: value, disabled: true }
        : { key, label: value },
  );

  return (
    <Dropdown
      menu={{
        items,
        onClick: (info) => {
          const key = info.key as Qualification;
          if (key === "unqualified") {
            unQualify({
              procurementStage: procurementStage,
              recordGuid: recordGuid,
              score: score,
              signalTypes: signalTypes,
              contextSource: "Record qualification dropdown",
            });
            return;
          }
          mutate({
            qualification: key,
            recordGuid: recordGuid,
            score: score,
            signalTypes: signalTypes,
            procurementStage: { id: procurementStage.id, stage: procurementStage.stage },
            contextSource: "Record qualification dropdown",
          });
        },
      }}
      trigger={["click"]}
    >
      <div className={css.selectContainer}>
        {selectedQualifcation} <CaretDownOutlined />
      </div>
    </Dropdown>
  );
}

export function QualificationStatus({
  procurementStage,
  noticeGuid,
  score,
  signalTypes,
  enableUpdate,
}: {
  procurementStage: { id: string; stage: string; qualification?: Qualification };
  noticeGuid: string;
  score: number | null | undefined;
  signalTypes?: Record<string, string[]>;
  enableUpdate?: boolean;
}) {
  const qualification: Qualification = procurementStage.qualification || "unqualified";

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  if (enableUpdate) {
    return (
      <QualificationSelect
        procurementStage={{ ...procurementStage, qualification: qualification }}
        recordGuid={noticeGuid}
        score={score}
        signalTypes={signalTypes}
      />
    );
  }

  if (isPreEngage) {
    return <>{PRE_ENGAGE_QUALIFICATION[qualification]}</>;
  }

  // check is just in case there's an error to prevent a crash
  if (TENDER_QUALIFICATION[qualification]) {
    return <>{TENDER_QUALIFICATION[qualification]}</>;
  }

  return null;
}
