import { SetStateAction, useCallback, useMemo } from "react";
import isEqual from "lodash.isequal";
import qs from "qs";
// TODO remove this when upgraded react to v18+
import { useSyncExternalStore } from "use-sync-external-store/shim";

const events = ["popstate"];
function subscribe(callback: () => void) {
  for (const event of events) {
    window.addEventListener(event, callback);
  }
  return () => {
    for (const event of events) {
      window.removeEventListener(event, callback);
    }
  };
}

function getParams<T>(key: string, parseValue?: (value: unknown) => T) {
  const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (parseValue) {
    return parseValue(values[key] as unknown);
  }
  return values[key] as unknown as T;
}

function setParams<T>(key: string, value: T, setParamsOptions?: qs.IStringifyOptions) {
  const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const newQsValue = qs.stringify(
    { ...values, [key]: value },
    setParamsOptions || { arrayFormat: "brackets" },
  );
  window.history.pushState({}, "", `${window.location.pathname}?${newQsValue}`);
  // manually trigger a popstate event to update the state in useSyncExternalStore
  dispatchEvent(new PopStateEvent("popstate", { state: {} }));
}

function getParamsValue<T>(key: string, parseValue?: (value: unknown) => T) {
  const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const value = values[key];

  if (value) {
    return JSON.stringify(parseValue ? parseValue(value) : value);
  }
  return undefined;
}

export function useURLState<T>(
  key: string,
  initialValue: T,
  parseValue?: (value: unknown) => T,
  setParamsOptions?: qs.IStringifyOptions,
): [T | undefined, (newValue: SetStateAction<T>) => void] {
  const queryValue = useSyncExternalStore(
    subscribe,
    () => getParamsValue<T>(key, parseValue),
    () => {
      const val = getParamsValue<T>(key, parseValue);
      if (typeof val !== "undefined") {
        return val;
      }
      return qs.stringify(initialValue, { arrayFormat: "brackets" });
    },
  );

  const setURLState = useCallback(
    (newValue: SetStateAction<T>) => {
      const previousValue = getParams(key, parseValue);
      if (typeof newValue === "function") {
        newValue = (newValue as (prevState: T) => T)(previousValue);
      }
      // only update if the value has changed
      if (!isEqual(newValue, previousValue)) {
        setParams(key, newValue, setParamsOptions);
      }
    },
    [key, parseValue, setParamsOptions],
  );

  // memoize the value for referential equality
  const value = useMemo<T | undefined>(() => {
    return queryValue ? JSON.parse(queryValue) : undefined;
  }, [queryValue]);

  return [value, setURLState];
}

export function parseNumberValue(value: unknown) {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string" && value && !Number.isNaN(Number(value))) {
    return Number(value);
  }
  return undefined;
}

export function parseBooleanValue(value: unknown) {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "string" && value) {
    return value === "true";
  }
  return undefined;
}
