import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useIntegrationApi } from "lib/integrationApiContext";

type UninstallParams = { providerId: string };
export function useUninstallProvider(
  options?: UseMutationOptions<string, unknown, UninstallParams, unknown>,
) {
  const api = useIntegrationApi();
  const queryClient = useQueryClient();
  return useMutation(({ providerId }: UninstallParams) => api.uninstall({ providerId }), {
    ...options,
    onSuccess: () => {
      void queryClient.invalidateQueries(["integrationProvider"]);
      void queryClient.invalidateQueries(["providerList"]);
    },
  });
}
