/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Search queries can limit the result set for a given set of organisations.
 *   These include suppliers and buyers.
 *   There are a number of fields that may not be present on all organisations
 *   depending on it they are buyers or suppliers
 *   A large number of fields have been demoralised for performance reasons
 *   such as categoriesPublicSectorOrgType. Rather than creating nested 'categories' property
 *   we simply list all the matching guids in a flat array
 * @export
 * @interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
 */
export interface NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers {
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    names?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    regionIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    countryCodes?: Array<string>;
    /**
     * if true only include SMEs, if false only include non-SMEs, if undefined include all
     * @type {boolean}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    isSME?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers
     */
    categoriesPublicSectorOrgType?: Array<string>;
}

/**
 * Check if a given object implements the NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers interface.
 */
export function instanceOfNoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json: any): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers {
    return NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSONTyped(json, false);
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'names': !exists(json, 'names') ? undefined : json['names'],
        'regionIds': !exists(json, 'regionIds') ? undefined : json['regionIds'],
        'countryCodes': !exists(json, 'countryCodes') ? undefined : json['countryCodes'],
        'isSME': !exists(json, 'isSME') ? undefined : json['isSME'],
        'categoriesPublicSectorOrgType': !exists(json, 'categoriesPublicSectorOrgType') ? undefined : json['categoriesPublicSectorOrgType'],
    };
}

export function NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'names': value.names,
        'regionIds': value.regionIds,
        'countryCodes': value.countryCodes,
        'isSME': value.isSME,
        'categoriesPublicSectorOrgType': value.categoriesPublicSectorOrgType,
    };
}

