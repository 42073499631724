import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { Report } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function usePublicReports(options?: UseQueryOptions<Report[], unknown, Report[], string[]>) {
  const api = useOpenApi();
  return useQuery(
    ["reports", "public"],
    async () => {
      const response = await api.getPublicReports();
      return response.reports;
    },
    options,
  );
}
