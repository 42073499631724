/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameworkSearchQuery } from './FrameworkSearchQuery';
import {
    FrameworkSearchQueryFromJSON,
    FrameworkSearchQueryFromJSONTyped,
    FrameworkSearchQueryToJSON,
} from './FrameworkSearchQuery';

/**
 * 
 * @export
 * @interface SearchFrameworksRequest
 */
export interface SearchFrameworksRequest {
    /**
     * 
     * @type {FrameworkSearchQuery}
     * @memberof SearchFrameworksRequest
     */
    query: FrameworkSearchQuery;
}

/**
 * Check if a given object implements the SearchFrameworksRequest interface.
 */
export function instanceOfSearchFrameworksRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "query" in value;

    return isInstance;
}

export function SearchFrameworksRequestFromJSON(json: any): SearchFrameworksRequest {
    return SearchFrameworksRequestFromJSONTyped(json, false);
}

export function SearchFrameworksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': FrameworkSearchQueryFromJSON(json['query']),
    };
}

export function SearchFrameworksRequestToJSON(value?: SearchFrameworksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': FrameworkSearchQueryToJSON(value.query),
    };
}

