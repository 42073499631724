import React from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd5";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import css from "./InfoButton.module.scss";

type InfoButtonProps = {
  note: React.ReactNode;
  className?: string;
  tooltipOverlayStyle?: TooltipProps["overlayStyle"];
};

export type InfoItemProps = {
  label: string | JSX.Element;
} & InfoButtonProps;

export function InfoButton({ className, note, tooltipOverlayStyle }: InfoButtonProps): JSX.Element {
  return (
    <Tooltip title={note} arrow={{ pointAtCenter: true }} overlayStyle={tooltipOverlayStyle}>
      <InfoCircleTwoTone
        className={classnames(css.infoIcon, className)}
        twoToneColor={css.lightBlue}
      />
    </Tooltip>
  );
}

export function InfoItem({ note, label, className }: InfoItemProps): JSX.Element {
  return (
    <div className={className}>
      <span className={css.label}>{label}</span>
      <InfoButton note={note} />
    </div>
  );
}
