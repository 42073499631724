import React from "react";
import { App, Modal } from "antd5";

import { SavedViewType } from "lib/generated/app-api";
import { useGetLeadSubscriptionContents } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptionContents";
import { useUpdateAlerts } from "lib/hooks/api/notices/views/useUpdateAlerts";
import { assertDefined } from "lib/utils";
import { getAlert } from "../alerts";
import { AlertForm } from "./AlertForm";

import css from "./EditAlertsModal.module.scss";

type EditAlertsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedView: SavedViewType;
};

export function EditAlertsModal({ isOpen, onClose, selectedView }: EditAlertsModalProps) {
  assertDefined(selectedView.id);
  const { message } = App.useApp();
  const { mutate: mutateView, isLoading } = useUpdateAlerts({
    onSuccess: async (_data, vars) => {
      onClose();
      void message.success(`Alerts for ${vars.view.name} saved`);
    },
    onError: async () => {
      onClose();
      void message.error("Failed to save alerts");
    },
  });

  const { data } = useGetLeadSubscriptionContents();
  const personalSavedViewAlert = getAlert(data?.personalSubscriptions ?? [], selectedView.id);
  return (
    <Modal
      className={css.modal}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={`Edit alerts for "${selectedView.name}"`}
    >
      <AlertForm
        defaultValues={{
          viewName: selectedView.name,
          viewDescription: selectedView.description ? selectedView.description : "",
          alertFrequency: personalSavedViewAlert?.id ? personalSavedViewAlert.frequency : "NEVER",
        }}
        isStandardView={selectedView.isStandardView}
        onSubmit={(data) =>
          mutateView({
            view: selectedView,
            alertId: personalSavedViewAlert?.id,
            alertFrequency: data.alertFrequency,
          })
        }
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Modal>
  );
}
