import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { DocumentsQuery, DocumentsSearchRequest } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const searchDocumentsQuery = graphql(`
  query documents($request: DocumentsSearchRequest!) {
    documents(DocumentsSearchRequest: $request) {
      results {
        id
        signalScore
        title
        category
        publishedAt
        buyers {
          id
          name
        }
      }
      totalResults
    }
  }
`);

export type DocumentsSearchResponse = DocumentsQuery["documents"];
export type DocumentsDto = DocumentsSearchResponse["results"][0];

export function useDocuments(
  searchDocumentsRequest: DocumentsSearchRequest,
  options?: UseQueryOptions<DocumentsQuery, unknown, DocumentsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["documents", searchDocumentsRequest],
    searchDocumentsQuery,
    [{ request: searchDocumentsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.documents, ...rest };
}

export type UseSearchDocuments = typeof useDocuments;
