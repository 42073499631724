import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Alert, Button, Tooltip } from "antd5";
import classnames from "classnames";

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import OrgInfoMenu from "components/organisation_clean/OrgInfoMenu";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import StotlesVerifiedIcon from "lib/core_components/StotlesVerifiedIcon";
import { ReferenceOrgDto } from "lib/generated/app-api";
import { isDefined, pluralise } from "lib/utils";
import { OrgWithStats } from "./types";

import css from "./OrgListPanel.module.scss";

// These are the attributes passed to the buyer list before exact config per item is generated
type ListButtonConfig = {
  label: string;
  selectedLabel: string;
  onSelect: (b: OrgWithStats) => void;
};

type DisabledOrgConfig = {
  guid: string;
  label: string;
};

type ListButtonProps = {
  label: string;
  type: "default" | "primary";
  disabled: boolean;
  onSelect: (b: OrgWithStats) => void;
};

type CoreListProps = {
  orgs: OrgWithStats[];
  orgPrimaryRole?: "Buyer" | "Supplier";
  expandedByDefault: boolean;
  renderAdditionalContent?: (b: OrgWithStats) => JSX.Element;
  renderCTA?: (b: OrgWithStats) => JSX.Element;
  className?: string;
};

type AdvancedListProps = {
  countLabel: string;
  buttonConfig: ListButtonConfig;
  selectedGuids: string[];
  disableSelected: boolean;
  // This prop is used to disable a particular buyer in the list
  // Mainly used for the anchor in the duplicates lists
  disabledOrgConfig?: DisabledOrgConfig;
  CTA?: JSX.Element;
} & CoreListProps;

type ItemProps = {
  org: OrgWithStats;
  orgPrimaryRole?: "Buyer" | "Supplier";
  expandedByDefault: boolean;
  buttonProps?: ListButtonProps;
  renderAdditionalContent?: (b: OrgWithStats) => JSX.Element;
  renderCTA?: (b: OrgWithStats) => JSX.Element;
  className?: string;
};

function RecordCountBullet({
  count,
  className,
}: {
  count: number;
  className?: string;
}): JSX.Element {
  return (
    <span className={classnames(css.countBullet, className)}>{pluralise(count, "record")}</span>
  );
}

function OrgItem({
  org,
  orgPrimaryRole,
  buttonProps,
  expandedByDefault,
  renderAdditionalContent,
  renderCTA,
  className,
}: ItemProps): JSX.Element {
  const [expanded, setExpanded] = React.useState(expandedByDefault);

  const cta = React.useMemo(() => {
    if (buttonProps) {
      return (
        <Button
          type={buttonProps.type}
          className={css.selectButton}
          onClick={() => buttonProps.onSelect(org)}
          disabled={buttonProps.disabled}
        >
          {buttonProps.label}
        </Button>
      );
    } else if (renderCTA) {
      return renderCTA(org);
    } else return null;
  }, [buttonProps, org, renderCTA]);

  return (
    <li key={org.guid} className={classnames(css.buyerItem, className)}>
      <h2>
        {org.name}
        {org.is_stotles_verified && <StotlesVerifiedIcon />}
      </h2>
      {renderAdditionalContent && (
        <div className={css.additionalCTAsContainer}>{renderAdditionalContent(org)}</div>
      )}

      {cta}
      <div>
        <TextButton
          bold
          className={classnames(
            css.readMore,
            renderAdditionalContent ? css.thirdRow : css.secondRow,
          )}
          onClick={() => setExpanded((o) => !o)}
        >
          Read {expanded ? "less" : "more"}
          <DownOutlined className={css.arrowIcon} rotate={expanded ? 180 : 0} />
        </TextButton>
      </div>
      {org.stats?.record_count && (
        <RecordCountBullet
          count={org.stats?.record_count}
          className={renderAdditionalContent ? css.thirdRow : css.secondRow}
        />
      )}

      {expanded && (
        <div className={css.extraContent}>
          <div className={css.infoBox}>
            <h3>Town</h3>
            <p>{org.town || "N/A"}</p>
          </div>

          <div className={css.infoBox}>
            <h3>Country</h3>
            <p>{org.country || "N/A"}</p>
          </div>
          <div className={css.infoBox}>
            <h3>Oscar URN</h3>
            <p>{org.oscar_id || "N/A"}</p>
          </div>
          <div className={css.infoBox}>
            <h3>Latest activity</h3>
            <p>{org.stats?.latest_activity || "N/A"}</p>
          </div>
          <div className={css.infoBox}>
            <div>
              <OrgInfoMenu org={org} orgPrimaryRole={orgPrimaryRole} />
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

type GetAttributeProps = {
  guid: string;
  disableSelected: boolean;
  buttonConfig: ListButtonConfig;
  selectedGuids: string[];
  disabledOrgConfig?: DisabledOrgConfig;
};

function getButtonAttributes({
  guid,
  disableSelected,
  buttonConfig,
  selectedGuids,
  disabledOrgConfig,
}: GetAttributeProps): ListButtonProps {
  if (disabledOrgConfig?.guid === guid) {
    return {
      label: disabledOrgConfig.label,
      type: "default",
      disabled: true,
      onSelect: buttonConfig.onSelect,
    };
  } else if (selectedGuids.includes(guid)) {
    return {
      label: buttonConfig.selectedLabel,
      type: "primary",
      disabled: disableSelected ? true : false,
      onSelect: buttonConfig.onSelect,
    };
  } else
    return {
      label: buttonConfig.label,
      type: "default",
      disabled: false,
      onSelect: buttonConfig.onSelect,
    };
}

function OrgListPanel({
  countLabel,
  orgs,
  orgPrimaryRole,
  buttonConfig,
  selectedGuids,
  disableSelected,
  expandedByDefault,
  disabledOrgConfig,
  CTA,
  renderAdditionalContent,
}: AdvancedListProps): JSX.Element {
  const results = React.useMemo(() => {
    if (orgs.length === 0) {
      return <li>No results</li>;
    } else
      return orgs.map((o) => (
        <OrgItem
          key={o.guid}
          org={o}
          orgPrimaryRole={orgPrimaryRole}
          expandedByDefault={expandedByDefault}
          renderAdditionalContent={renderAdditionalContent}
          buttonProps={getButtonAttributes({
            guid: o.guid,
            disableSelected,
            buttonConfig,
            selectedGuids,
            disabledOrgConfig,
          })}
        />
      ));
  }, [
    buttonConfig,
    expandedByDefault,
    selectedGuids,
    disableSelected,
    orgs,
    orgPrimaryRole,
    disabledOrgConfig,
    renderAdditionalContent,
  ]);

  return (
    <div className={css.panel}>
      <div className={css.panelHeader}>
        {CTA}
        <p className={css.countLabel}>{countLabel}</p>
      </div>
      <div className={css.listContainer}>
        <ul>{results}</ul>
      </div>
    </div>
  );
}

export function OrgListSimple({
  orgs,
  orgPrimaryRole,
  expandedByDefault,
  renderAdditionalContent,
  renderCTA,
  className,
}: CoreListProps): JSX.Element {
  const results = React.useMemo(() => {
    if (orgs.length === 0) {
      return <li>No results</li>;
    } else
      return orgs.map((o) => (
        <OrgItem
          key={o.guid}
          org={o}
          orgPrimaryRole={orgPrimaryRole}
          expandedByDefault={expandedByDefault}
          renderAdditionalContent={renderAdditionalContent}
          renderCTA={renderCTA}
        />
      ));
  }, [orgs, orgPrimaryRole, expandedByDefault, renderAdditionalContent, renderCTA]);

  return (
    <div className={classnames(css.panel, className)}>
      <div className={css.listContainer}>
        <ul>{results}</ul>
      </div>
    </div>
  );
}

export default OrgListPanel;

type ReferenceOrgItemProps = {
  org: ReferenceOrgDto;
  onSelect: (ob: ReferenceOrgDto) => void;
};

function ReferenceOrgItem({ org, onSelect }: ReferenceOrgItemProps) {
  const [expanded, setExpanded] = React.useState(false);
  const primaryRole = org.organisation.primaryRole === "Buyer" ? "buyers" : "suppliers";

  return (
    <li key={org.id}>
      <h2>
        {org.name}{" "}
        {org.organisation && (
          <small className={css.connectedOrg}>
            linked with{" "}
            <a href={`/${primaryRole}/${org.organisation.id}`}>{org.organisation.name}</a>
          </small>
        )}
      </h2>

      <Button type="primary" className={css.selectButton} onClick={() => onSelect(org)}>
        Select
      </Button>

      <button className={css.readMore} onClick={() => setExpanded((o) => !o)}>
        Read {expanded ? "less" : "more"}
        <DownOutlined className={css.arrowIcon} rotate={expanded ? 180 : 0} />
      </button>

      {expanded && (
        <div className={css.extraContent}>
          <div className={css.infoBox}>
            <h3>Address</h3>
            <p>
              {[
                org.address.address,
                org.address.address2,
                org.address.address3,
                org.address.town,
                org.address.postCode,
              ]
                .filter(isDefined)
                .join(", ") || "N/A"}
            </p>
          </div>
          <div className={css.infoBox}>
            <h3>Source</h3>
            <p>{org.source}</p>
          </div>
          <div className={css.infoBox}>
            <h3>Source URN</h3>
            <p>{org.sourceIdentifier}</p>
          </div>
          <div className={css.infoBox}>
            <h3>Data types</h3>
            <p>{(org.additionalData as Record<string, string>).DataTypes || "N/A"}</p>
          </div>
          <div className={css.infoBox}>
            <h3>Org types</h3>
            <p>{(org.additionalData as Record<string, string>).OrgTypes || "N/A"}</p>
          </div>
          {org.basicContactInfo.websiteUrl && (
            <div className={css.infoBox}>
              <h3>Website</h3>
              <Tooltip title={org.basicContactInfo.websiteUrl}>
                <TextLink targetType="external" to={org.basicContactInfo.websiteUrl}>
                  Visit website
                </TextLink>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </li>
  );
}

type ReferenceOrgListProps = {
  orgs: ReferenceOrgDto[];
  onSelect: (ob: ReferenceOrgDto) => void;
};

export function ReferenceOrgList({ orgs, onSelect }: ReferenceOrgListProps): JSX.Element {
  return (
    <div className={css.panel}>
      <div className={css.panelHeader}>
        <p className={css.countLabel}>{orgs.length} results</p>
      </div>
      <div className={css.listContainer}>
        <ul>
          {orgs.length > 0 ? (
            orgs.map((b) => <ReferenceOrgItem key={b.id} org={b} onSelect={onSelect} />)
          ) : (
            <li>No results</li>
          )}
        </ul>
      </div>
    </div>
  );
}

type CandidateItemProps = {
  org: OrgWithStats;
  orgPrimaryRole?: "Buyer" | "Supplier";
  className?: string;
  buttonProps?: ListButtonProps;
  renderAdditionalContent?: (b: OrgWithStats) => JSX.Element;
  renderCTA?: (b: OrgWithStats) => JSX.Element;
};
// This item is used to display buyers in the cleaning journey, which non-admins have access to
export function CandidateItem({
  org,
  orgPrimaryRole,
  className,
  renderAdditionalContent,
  renderCTA,
  buttonProps,
}: CandidateItemProps): JSX.Element {
  const cta = React.useMemo(() => {
    if (buttonProps) {
      return (
        <Button
          type={buttonProps.type}
          className={css.selectButton}
          onClick={() => buttonProps.onSelect(org)}
          disabled={buttonProps.disabled}
        >
          {buttonProps.label}
        </Button>
      );
    } else if (renderCTA) {
      return renderCTA(org);
    } else return null;
  }, [buttonProps, org, renderCTA]);

  return (
    <li key={org.guid} className={classnames(css.buyerItem, className)}>
      <h2>
        <span>{org.name}</span>
        {org.is_stotles_verified && <StotlesVerifiedIcon />}
      </h2>
      {renderAdditionalContent && (
        <div className={css.additionalCTAsContainer}>{renderAdditionalContent(org)}</div>
      )}

      {cta}

      <div className={css.buyerDetails}>
        <div className={css.infoBox}>
          <h3>Town</h3>
          <p>{org.town || "N/A"}</p>
        </div>
        <div className={css.infoBox}>
          <h3>Address</h3>
          <p>{org.address || "N/A"}</p>
        </div>
        <div className={classnames(css.infoBox, css.urlBox)}>
          <h3>Website</h3>
          {org.url ? (
            <EllipsisTooltipTextLink
              fullText={org.url}
              linkProps={{ to: org.url }}
              linkText={org.url}
            />
          ) : (
            <p>N/A</p>
          )}
        </div>
        <div className={css.infoBox}>
          <h3>Country</h3>
          <p>{org.country || "N/A"}</p>
        </div>

        <div className={classnames(css.infoBox, css.lastBox)}>
          {org.stats?.record_count && <RecordCountBullet count={org.stats?.record_count} />}
          <OrgInfoMenu org={org} reduced orgPrimaryRole={orgPrimaryRole} />
        </div>
      </div>
      {org.is_deleted && (
        <Alert
          message="Error"
          description="This Buyer has been deleted"
          type="error"
          showIcon
          className={css.buyerDeletedAlert}
        />
      )}
    </li>
  );
}

type CandidateListProps = {
  buttonConfig?: ListButtonConfig;
  selectedGuids?: string[];
  disableSelected?: boolean;
  // This prop is used to disable a particular buyer in the list
  // Mainly used for the anchor in the duplicates lists
  disabledOrgConfig?: DisabledOrgConfig;
  CTA?: JSX.Element;
  orgs: OrgWithStats[];
  orgPrimaryRole?: "Buyer" | "Supplier";
  renderAdditionalContent?: (b: OrgWithStats) => JSX.Element;
  renderCTA?: (b: OrgWithStats) => JSX.Element;
  className?: string;
  hideCount?: boolean;
};

export function CandidateListPanel({
  orgs,
  orgPrimaryRole,
  buttonConfig,
  selectedGuids,
  disableSelected,
  disabledOrgConfig,
  renderAdditionalContent,
  renderCTA,
  className,
  hideCount,
}: CandidateListProps): JSX.Element {
  const ctaProps: Omit<GetAttributeProps, "guid"> | undefined = React.useMemo(() => {
    if (disableSelected && buttonConfig && selectedGuids) {
      return { disableSelected, buttonConfig, selectedGuids };
    } else return undefined;
  }, [disableSelected, buttonConfig, selectedGuids]);

  const results = React.useMemo(() => {
    if (orgs.length === 0) {
      return <li>No results</li>;
    } else
      return orgs.map((o) => (
        <CandidateItem
          renderCTA={renderCTA}
          key={o.guid}
          org={o}
          orgPrimaryRole={orgPrimaryRole}
          renderAdditionalContent={renderAdditionalContent}
          buttonProps={
            ctaProps && getButtonAttributes({ guid: o.guid, ...ctaProps, disabledOrgConfig })
          }
        />
      ));
  }, [orgs, orgPrimaryRole, renderAdditionalContent, ctaProps, renderCTA, disabledOrgConfig]);

  return (
    <div className={classnames(css.panel, className)}>
      {!hideCount && (
        <div className={css.panelHeader}>
          <p className={css.countLabel}>{pluralise(orgs.length, "result")}</p>
        </div>
      )}
      <div className={css.listContainer}>
        <ul>{results}</ul>
      </div>
    </div>
  );
}
