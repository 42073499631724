import React from "react";
import { Control } from "react-hook-form";

import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { NewSupplierSelect } from "../../form_components/NewSupplierSelect";
import { SupplierSelect } from "../../form_components/SupplierSelect";
import { FrameworkFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface SuppliersFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
}

function SuppliersFilterContent({ control }: SuppliersFilterContentProps) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  return (
    <div className={css.filterSection} aria-label="Supplier filters">
      {enableNewSupplierFilter ? (
        <NewSupplierSelect name="supplierIds" label="Supplier Guids" control={control} />
      ) : (
        <SupplierSelect
          name="supplierIds"
          label=""
          control={control}
          mode="multiple"
          placeholder="Search suppliers..."
          allowClear
          idType="guid"
        />
      )}
    </div>
  );
}

export default SuppliersFilterContent;
