import React from "react";
import { Control } from "react-hook-form";
import { useVariableValue } from "@devcycle/react-client-sdk";

import { cpvDimensionSelectOptions } from "../../../lib/data/optionItems";
import { CPV_CODES_FILTER } from "../../../lib/featureFlags";
import CpvCodeSelect from "../../form_components/CpvCodeSelect";
import { Select } from "../../form_components/Inputs";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface CPVCodeFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function CPVCodeFilterContent({ control }: CPVCodeFilterContentProps) {
  const cpvCodesFilter = useVariableValue(CPV_CODES_FILTER, false);

  return (
    <div className={css.filterSection} aria-label="CPV filters">
      {cpvCodesFilter ? (
        <CpvCodeSelect hideLabel hideClear name="cpvCodes" label="CPV Codes" control={control} />
      ) : (
        <Select
          name="cpvDimensions"
          label="Sector"
          control={control}
          options={cpvDimensionSelectOptions}
          optionFilterProp="label"
          mode="multiple"
          placeholder="Select"
          allowClear
        />
      )}
    </div>
  );
}

export default CPVCodeFilterContent;
