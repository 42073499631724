import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { AsyncRecordExportResponse, RecordSearchRequestDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type Params = { format: "csv" | "xlsx"; filters: RecordSearchRequestDto };
export function useExportNoticesAsync(
  options?: UseMutationOptions<AsyncRecordExportResponse, unknown, Params, unknown>,
) {
  const api = useOpenApi();

  return useMutation(async ({ format, filters }) => {
    return api.asyncRecordExport({
      asyncRecordExportRequest: { searchRequest: filters, format },
    });
  }, options);
}
