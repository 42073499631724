/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeConfigBuilderResponse
 */
export interface NoticeConfigBuilderResponse {
    /**
     * 
     * @type {string}
     * @memberof NoticeConfigBuilderResponse
     */
    status: NoticeConfigBuilderResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NoticeConfigBuilderResponse
     */
    message?: string;
}


/**
 * @export
 */
export const NoticeConfigBuilderResponseStatusEnum = {
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;
export type NoticeConfigBuilderResponseStatusEnum = typeof NoticeConfigBuilderResponseStatusEnum[keyof typeof NoticeConfigBuilderResponseStatusEnum];


/**
 * Check if a given object implements the NoticeConfigBuilderResponse interface.
 */
export function instanceOfNoticeConfigBuilderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function NoticeConfigBuilderResponseFromJSON(json: any): NoticeConfigBuilderResponse {
    return NoticeConfigBuilderResponseFromJSONTyped(json, false);
}

export function NoticeConfigBuilderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeConfigBuilderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function NoticeConfigBuilderResponseToJSON(value?: NoticeConfigBuilderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
    };
}

