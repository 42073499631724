export enum DocumentType {
  "Key Corporate reports",
  "Other Corporate reports",
  "Transparency",
  "Policy & engagement",
  "Guidance & regulation",
  "Research & statistics",
  "Other",
}

export function documentTypeDisplay(documentTypeRaw: string | undefined): string {
  if (!documentTypeRaw) {
    return "";
  }

  let documentType = documentTypeRaw.substring(documentTypeRaw.indexOf(":") + 1); // doc scraper adds the scraper location so we need to drop that e.g. "gov_uk:policy_paper"

  // upper case the first letter
  documentType = documentType.charAt(0).toUpperCase() + documentType.slice(1);

  // strip out the dashes + if specific words appear then uppercase the whole word.
  return documentType.replace(/-|_/, " ").replace(/\b(foi|po)\b/i, function (v) {
    return v.toUpperCase();
  });
}

/**
 * Convert buyer document types into our own classification
 */
export function documentTypeMapper(documentType: string): DocumentType {
  const docType = documentType.toLowerCase();
  switch (true) {
    case /delivery.*plans|budget/.test(docType):
      return DocumentType["Key Corporate reports"];
    case /corporate.*report/.test(docType):
      return DocumentType["Other Corporate reports"];
    case /consultation|policy/.test(docType):
      return DocumentType["Policy & engagement"];
    case /transparency|meeting/.test(docType) || /\w*(foi)\w+/.test(documentType):
      return DocumentType["Transparency"];
    case /guidance|regulation/.test(docType):
      return DocumentType["Guidance & regulation"];
    case /research|statistics/.test(docType):
      return DocumentType["Research & statistics"];
    default:
      return DocumentType["Other"];
  }
}
