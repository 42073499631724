export function arrayToParams<T>(filters: T[] | undefined | null): string | undefined {
  return filters && filters.length ? filters.map((v) => `${v}`).join(",") : undefined;
}

export function urlParamToArray(
  urlParams: URLSearchParams,
  paramName: string,
): string[] | undefined {
  const param = urlParams.get(paramName);
  if (param === null) {
    return undefined;
  }
  if (param === "") {
    return [];
  }
  return param.split(",");
}

export function urlParamToTypedArray<T>(
  urlParams: URLSearchParams,
  paramName: string,
  converter: (p: string) => T,
): T[] | undefined {
  const items = urlParamToArray(urlParams, paramName);
  if (items === undefined) {
    return undefined;
  }
  return items.map(converter);
}

export function booleanToUrlParam(value: boolean | undefined): string | undefined {
  return value == null ? undefined : value.toString();
}
